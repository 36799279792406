<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Gruppe</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        
        <div class="row">
          <div class="col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input [(ngModel)]="groupInfo.name" matInput>
            </mat-form-field><br />
          </div>
        </div>

        <mat-accordion class="expansion-header" [multi]="false" [hidden]="addGroup">
          <mat-expansion-panel [expanded]="true" style="margin-bottom: 15px;">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h6>Berechtigungen</h6>
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>security</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <ng-container>
                <mat-form-field class="input-full-width" appearance="outline">
                  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche">
                </mat-form-field>
                <div class="table-responsive">
                  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="name"
                    matSortDirection="asc" matSortDisableClear>
                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="description">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Beschreibung</th>
                      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                    </ng-container>

                    <!-- Assign Column -->
                    <ng-container matColumnDef="assign">
                      <th mat-header-cell *matHeaderCellDef> </th>
                      <td mat-cell *matCellDef="let element">
                        <mat-slide-toggle [checked]="element.assigned===1" (change)="setValue(element.id, $event)">
                        </mat-slide-toggle>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
                <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons #paginator>
                </mat-paginator>
              </ng-container>
            </ng-template>
          </mat-expansion-panel>
          <mat-expansion-panel style="margin-bottom: 15px;">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h6>Welche Gruppen darf diese verwalten ({{ groupInfo?.maintainGroups?.length ?? 0 }})</h6>
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>manage_accounts</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Gruppen</mat-label>
                <mat-select multiple [(ngModel)]="groupInfo.maintainGroups">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="groupFilterCtrl" [showToggleAllCheckbox]="true"
                      [toggleAllCheckboxChecked]="groupInfo?.maintainGroups?.length === groupList?.length"
                      (toggleAll)="toggleAllSelection($event)"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let group of groupFilter | async" [value]="group.id">{{group.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </mat-expansion-panel>
          <mat-expansion-panel style="margin-bottom: 15px;" *ngIf="!addGroup">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h6>Personen {{ userlist && userlist.length > 0 ? '(' + userlist.length + ')' : '' }}</h6>
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>group</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <div *ngIf="!userlist">
                <mat-progress-bar mode="query"></mat-progress-bar>
              </div>
              <div *ngIf="userlist && userlist.length===0">
                <span>Keine Personen in dieser Gruppe.</span>
              </div>
              <div *ngIf="userlist && userlist.length!==0" class="row">
                <div *ngFor="let user of userlist" class="col-12 col-md-6 col-lg-4">
                  <mat-card class="text-center m-3 ">
                    <mat-card-content>
                      <b class="pointer" routerLink="/admin/user/edit/{{user.userID}}/masterdata">{{user.firstname}}
                        {{user.lastname}}</b>
                      <mat-icon class="hoverVisible pointer" color="warn"
                        (click)="removeUser(user.userID)">close</mat-icon>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <button mat-raised-button class="float-right" color="primary" (click)="openAddUser()">Person hinzufügen
                <mat-icon>person_add</mat-icon></button>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>

        <div class="clearfix">
          <button mat-raised-button class="float-right" color="success" (click)="saveData();">Speichern <mat-icon>save
            </mat-icon></button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #addUserDialog>
  <h1 mat-dialog-title>Person der Gruppe {{groupInfo.name}} hinzufügen</h1>
  <div mat-dialog-content>
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Person</mat-label>
      <input type="text" matInput [formControl]="addPersonControl" [matAutocomplete]="auto"
        (keyup.enter)="savePerson(addPersonControl.value.userID)">
      <mat-icon matSuffix (click)="addPersonControl.setValue('')" [class.inVisible]="addPersonControl.value === ''">
        close</mat-icon>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
        <mat-option *ngFor="let user of filterdUsers | async" [value]="user">
          {{user.userID}} - {{user.firstname}} {{user.lastname}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" class="float-right mx-2" [disabled]="!addPersonControl.value.userID"
      (click)="savePerson(addPersonControl.value.userID)">hinzufügen</button>
    <button mat-raised-button color="accent" class="float-left mx-2"
      mat-dialog-close>schließen<mat-icon>close</mat-icon>
    </button>
  </div>
</ng-template>