import { Component, OnInit } from '@angular/core';
import { ConfigModule, getHostUrl } from '../../../../util/config';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../../../util/login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AddressDialogComponent } from 'src/app/util/dialogs/address-dialog/address-dialog.component';
import { UntypedFormArray, UntypedFormControl, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EventAdmin, EventLink, Group } from '@ffo/mitgliederbereich-types';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';
import { ReplaySubject, Subject, firstValueFrom, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapterService } from 'src/app/util/customDateAdapter';
@Component({
  selector: 'app-admin-event-detail',
  templateUrl: './admin-event-detail.component.html',
  styleUrls: ['./admin-event-detail.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService }],
})
export class AdminEventDetailComponent implements OnInit {

  env = environment;
  group: UntypedFormGroup;
  today: Date;

  groupsAdmin: Group[];
  groupsRequired: Group[];
  groupsOptional: Group[];
  event: EventAdmin;

  adminFilterCtrl: UntypedFormControl = new UntypedFormControl();
  requiredFilterCtrl: UntypedFormControl = new UntypedFormControl();
  optionalFilterCtrl: UntypedFormControl = new UntypedFormControl();

  adminFilter: ReplaySubject<Group[]> = new ReplaySubject<Group[]>();
  requiredFilter: ReplaySubject<Group[]> = new ReplaySubject<Group[]>();
  optionalFilter: ReplaySubject<Group[]> = new ReplaySubject<Group[]>();

  filteredDressCodes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  dressCodeList = [];
  dressCodeFilterCtrl: FormControl<string> = new FormControl<string>('');

  newEvent: boolean = false;

  protected _onDestroy = new Subject<void>();
  private limitStart: Subscription;
  private limitEnd: Subscription;

  constructor(
    private _config: ConfigModule,
    private _http: HttpClient,
    public _login: Login,
    private _router: Router,
    private _route: ActivatedRoute,
    private _spinner: NgxSpinnerService,
    private _dialog: MatDialog,
  ) { }

  ngOnInit() {
    this._config.setTitle('Termin anlegen');
    this.newEvent = this._route.snapshot.queryParams.new !== null && this._route.snapshot.queryParams.new !== undefined;
    this._spinner.show();
    this.today = new Date();
    this.group = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(''),
      allDay: new UntypedFormControl(false, Validators.required),
      status: new UntypedFormControl('sichtbar', Validators.required),
      visibility: new UntypedFormControl(true, Validators.required),
      start: new UntypedFormGroup({
        date: new UntypedFormControl(new Date(), Validators.required),
        time: new UntypedFormControl('19:00'),
      }),
      end: new UntypedFormGroup({
        date: new UntypedFormControl(new Date(), Validators.required),
        time: new UntypedFormControl('22:00'),
      }),
      limitResponse: new UntypedFormGroup({
        active: new UntypedFormControl(false, Validators.required),
        start: new UntypedFormGroup({
          date: new UntypedFormControl(null),
          time: new UntypedFormControl(null),
        }),
        end: new UntypedFormGroup({
          date: new UntypedFormControl(null),
          time: new UntypedFormControl(null),
        }),
      }),
      triggerReminder: new UntypedFormGroup({
        active: new UntypedFormControl(false, Validators.required),
        start: new UntypedFormGroup({
          date: new UntypedFormControl(null),
        }),
      }),
      type: new UntypedFormControl('local', Validators.required),
      orga: new UntypedFormControl('technic', Validators.required),
      location: new UntypedFormGroup({
        street: new UntypedFormControl({ value: '', readonly: true }),
        appartment: new UntypedFormControl(''),
        zipcode: new UntypedFormControl({ value: '', readonly: true }),
        city: new UntypedFormControl({ value: '', readonly: true }),
        country: new UntypedFormControl({ value: '', disabled: true }),
        mapsUrl: new UntypedFormControl({ value: '', disabled: true }),
      }),
      meetingUrl: new UntypedFormControl('', Validators.pattern('(http:\/\/|https:\/\/)([a-zA-Z]*(.[a-zA-Z]*)*)')),
      attendees: new UntypedFormGroup({
        admin: new UntypedFormControl([], Validators.required),
        required: new UntypedFormControl([], Validators.required),
        optional: new UntypedFormControl([]),
        count: new UntypedFormControl('', [Validators.min(1)]),
      }),
      dressCode: new UntypedFormControl(null),
      eventDescription: new UntypedFormControl('0', Validators.required),
      resourceManagement: new UntypedFormControl('0', Validators.required),
      flag: new UntypedFormControl(false, Validators.required),
      links: new UntypedFormArray([]),
      id: new UntypedFormControl(''),
    });

    this.group.get('start').valueChanges.subscribe(() => {
      this.setDateValues();
    });
    this.group.get('end').valueChanges.subscribe(() => {
      this.setDateValues();
    });
    this.group.get('limitResponse').get('active').valueChanges.subscribe(() => {
      if (this.group.get('limitResponse').get('active').value) {
        if (this.limitStart)
          this.limitStart.unsubscribe();
        if (this.limitEnd)
          this.limitEnd.unsubscribe();
        this.group.get('limitResponse').get('start').get('date').setValue(new Date());
        this.group.get('limitResponse').get('start').get('time').setValue('00:00');
        this.group.get('limitResponse').get('end').get('date').setValue(this.group.get('end').get('date').value);
        this.group.get('limitResponse').get('end').get('time').setValue(this.group.get('end').get('time').value);
        this.limitStart = this.group.get('limitResponse').get('start').valueChanges.subscribe(() => {
          this.setResponseTimeValues();
        });
        this.limitEnd = this.group.get('limitResponse').get('end').valueChanges.subscribe(() => {
          this.setResponseTimeValues();
        });
      }
    });
    this.group.get('attendees').get('optional').valueChanges.subscribe(() => {
      this.filterAdminTemplate();
      this.filterRequiredTemplate();
    });
    this.group.get('attendees').get('required').valueChanges.subscribe(() => {
      this.filterAdminTemplate();
      this.filterOptionalTemplate();
    });
    this.group.get('attendees').get('admin').valueChanges.subscribe(() => {
      this.filterRequiredTemplate();
      this.filterOptionalTemplate();
    });
    this.group.get('location').get('appartment').valueChanges.subscribe(() => {
      this.group.get('location').get('mapsUrl').setValue(`https://www.google.com/maps/search/?api=1&query=${this.group.get('location').get('zipcode').value}%20${this.group.get('location').get('city').value}%20${this.group.get('location').get('street').value}%20${this.group.get('location').get('appartment').value}`);
    });

    this.adminFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAdminTemplate();
      });

    this.requiredFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRequiredTemplate();
      });

    this.optionalFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOptionalTemplate();
      });

      this.dressCodeFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterDressCode();
      });

    Promise.all([
      this.fetchHomeLocation(),
      this.fetchGroups(),
      this.loadDressCode(),
    ]).then(() => this.load());
  }

  load(): void {
    if (this._route.snapshot.params.eventId) {
      this._config.setTitle('Termin bearbeiten');
      this.fetchEvent(this._route.snapshot.params.eventId);
    } else if (this._route.snapshot.queryParams.copyFrom) {
      this.fetchEvent(this._route.snapshot.queryParams.copyFrom, true);
    } else {
      this.group.get('attendees').get('admin').setValue([8]);
      this._spinner.hide();
    }
  }

  fetchEvent(eventId: number, copy: boolean = false) {
    this._http.get<EventAdmin>(`${getHostUrl()}admin/events?eventId=${eventId}`).subscribe((event) => {
      if (copy) {
        event.id = null;
        event.title += ' Kopie';
      }
      this.event = event;
      this.group.patchValue(event);
      this.event.links.forEach((link: EventLink) => {
        (this.group.get('links') as UntypedFormArray).push(new UntypedFormGroup({
          name: new UntypedFormControl(link.name),
          url: new UntypedFormControl(link.url, Validators.pattern('(http:\/\/|https:\/\/)([a-zA-Z]*(.[a-zA-Z]*)*)')),
          admin: new UntypedFormControl(link.admin),
        }));
      });
      this.group.get('start').get('date').setValue(new Date(this.event.start.date));
      this.group.get('end').get('date').setValue(new Date(this.event.end.date));
      if (this.event.limitResponse.active) {
        this.group.get('limitResponse').get('start').get('date').setValue(this.event.limitResponse.start.date);
        this.group.get('limitResponse').get('start').get('time').setValue(this.event.limitResponse.start.time);
        this.group.get('limitResponse').get('end').get('date').setValue(this.event.limitResponse.end.date);
        this.group.get('limitResponse').get('end').get('time').setValue(this.event.limitResponse.end.time);
      }
      this.group.updateValueAndValidity();
      this._spinner.hide();
    });
  }

  fetchGroups(): Promise<void> {
    return new Promise<void>((resolve) => {
      this._http.get<Group[]>(`${getHostUrl()}datainfo/groups`).subscribe((data) => {
        this.groupsAdmin = data;
        this.groupsRequired = data;
        this.groupsOptional = data;
        this.adminFilterCtrl.updateValueAndValidity();
        this.optionalFilterCtrl.updateValueAndValidity();
        this.requiredFilterCtrl.updateValueAndValidity();
        resolve();
      });
    });
  }

  async loadDressCode() {
    this.dressCodeList = await firstValueFrom(this._http.get<any>(`${getHostUrl()}datainfo/dressCode`));
    this.filterDressCode();
  }

  protected filterDressCode() {
    if (!this.dressCodeList) {
      return;
    }
    let search = this.dressCodeFilterCtrl.value;
    if (!search) {
      this.filteredDressCodes.next(this.dressCodeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredDressCodes.next(
      this.dressCodeList.filter(dressCode => dressCode.name.toLowerCase().indexOf(search) > -1)
    );
  }

  getLinksArray(): UntypedFormArray {
    return this.group.get('links') as UntypedFormArray;
  }

  addEventLink() {
    (this.group.get('links') as UntypedFormArray).push(new UntypedFormGroup({
      name: new UntypedFormControl(''),
      url: new UntypedFormControl('', Validators.pattern('(http:\/\/|https:\/\/)([a-zA-Z]*(.[a-zA-Z]*)*)')),
      admin: new UntypedFormControl(false),
    }));
  }

  removeEventLink(index: number) {
    (this.group.get('links') as UntypedFormArray).removeAt(index);
  }

  saveEvent() {
    this._http.post<any>(`${getHostUrl()}admin/events`, { event: this.group.getRawValue() }).subscribe({
      next: (data) => {
        if (data && data.eventId) {
          this._config.showSnackbar('Termin angelegt', 2500, 'success');
          this._router.navigate([`/admin/event/edit/${data.eventId}`], { queryParams: { 'new': true } });
        } else {
          this._config.showSnackbar('Terminänderung gespeichert', 2500, 'success');
        }
      },
      error: (error) => {
        this._config.showSnackbar('Fehler beim Speichern', 2500, 'error');
      }
    });
  }

  deleteEvent() {
    this._http.delete(`${getHostUrl()}admin/events?eventId=${this.event.id}`).subscribe(() => {
      this._router.navigate(['/event/list']);
    }, () => {
      this._config.showSnackbar('Fehler beim Löschen', 2500, 'error');
    });
  }

  cancelEvent() {
    this._http.delete(`${getHostUrl()}admin/events/cancel?eventId=${this.event.id}`).subscribe(() => {
      this._router.navigate(['/event/list']);
    }, () => {
      this._config.showSnackbar('Fehler beim Absagen', 2500, 'error');
    });
  }

  copyEvent() {
    this._router.navigate(['/admin/event/new'], { queryParams: { copyFrom: this.group.get('id').value } });
  }

  fetchHomeLocation() {
    return new Promise<void>((resolve) => {
      this._http.get<any>(`${getHostUrl()}datainfo/homeLocation`).subscribe((data) => {
        this.group.get('location').get('street').setValue(data.home_location_street);
        this.group.get('location').get('appartment').setValue(data.home_location_appartment);
        this.group.get('location').get('zipcode').setValue(data.home_location_zipcode);
        this.group.get('location').get('city').setValue(data.home_location_city);
        this.group.get('location').get('country').setValue(data.home_location_country);
        this.group.get('location').get('mapsUrl').setValue(`https://www.google.com/maps/search/?api=1&query=${data.home_location_zipcode}%20${data.home_location_city}%20${data.home_location_street}%20${data.home_location_appartment}`);
        resolve();
      });
    });
  }

  setDateValues() {
    const start = DateTime.fromJSDate(this.group.get('start').get('date').value);
    const end = DateTime.fromJSDate(this.group.get('end').get('date').value);
    if (start > end) {
      this.group.get('end').get('date').setValue(this.group.get('start').get('date').value);
    }

    if (!this.group.get('allDay').value) {
      const startTime = DateTime.fromFormat(this.group.get('start').get('time').value, 'HH:mm');
      const endTime = DateTime.fromFormat(this.group.get('end').get('time').value, 'HH:mm');
      if (startTime >= endTime && this.checkDateValid().sameDay) {
        let value = DateTime.fromFormat(this.group.get('start').get('time').value, 'HH:mm').plus({ hours: 3 }).toFormat('HH:mm');
        if (value.startsWith('0')) {
          value = '23:59';
        }
        this.group.get('end').get('time').setValue(value);
      }
    }
  }

  setResponseTimeValues() {
    const start = DateTime.fromJSDate(this.group.get('limitResponse').get('start').get('date').value);
    const end = DateTime.fromJSDate(this.group.get('limitResponse').get('end').get('date').value);
    if (start > end) {
      this.group.get('limitResponse').get('end').get('date').setValue(this.group.get('limitResponse').get('start').get('date').value);
    }

    const startTime = DateTime.fromFormat(this.group.get('limitResponse').get('start').get('time').value, 'HH:mm');
    const endTime = DateTime.fromFormat(this.group.get('limitResponse').get('end').get('time').value, 'HH:mm');
    if (startTime >= endTime && this.checkResponseTimeValid().sameDay) {
      let value = DateTime.fromFormat(this.group.get('limitResponse').get('start').get('time').value, 'HH:mm').plus({ hours: 3 }).toFormat('HH:mm');
      if (value.startsWith('0')) {
        value = '23:59';
      }
      this.group.get('limitResponse').get('end').get('time').setValue(value);
    }
  }

  checkDateValid() {
    const startDate = DateTime.fromJSDate(this.group.get('start').get('date').value);
    const endDate = DateTime.fromJSDate(this.group.get('end').get('date').value);
    const startTime = DateTime.fromFormat(this.group.get('start').get('time').value, 'HH:mm');
    const endTime = DateTime.fromFormat(this.group.get('end').get('time').value, 'HH:mm');
    const start = startDate.set({ hour: startTime.hour, minute: startTime.minute });
    const end = endDate.set({ hour: endTime.hour, minute: endTime.minute });

    return {
      sameDay: startDate.toISODate() === endDate.toISODate(),
      valid: this.group.get('allDay').value ? startDate <= endDate : start < end,
    };
  }

  checkResponseTimeValid() {
    const startDate = DateTime.fromJSDate(this.group.get('limitResponse').get('start').get('date').value);
    const endDate = DateTime.fromJSDate(this.group.get('limitResponse').get('end').get('date').value);
    const startTime = DateTime.fromFormat(this.group.get('limitResponse').get('start').get('time').value, 'HH:mm');
    const endTime = DateTime.fromFormat(this.group.get('limitResponse').get('end').get('time').value, 'HH:mm');
    const start = startDate.set({ hour: startTime.hour, minute: startTime.minute });
    const end = endDate.set({ hour: endTime.hour, minute: endTime.minute });

    const eventDate = DateTime.fromJSDate(this.group.get('end').get('date').value);
    const eventTime = DateTime.fromFormat(this.group.get('end').get('time').value, 'HH:mm');
    const eventEnd = eventDate.set({ hour: eventTime.hour, minute: eventTime.minute });

    return {
      sameDay: endDate.toISODate() === eventDate.toISODate(),
      valid: start < end && end <= eventEnd,
    };
  }

  findAddress(event?: PointerEvent) {
    if ((event.target as HTMLElement).tagName === 'MAT-ICON')
      return;
    const dialogRef = this._dialog.open(AddressDialogComponent, {
      width: '500px',
      data: {
        cities: false,
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        return;
      }
      this.group.get('location').get('street').setValue(data.street);
      this.group.get('location').get('zipcode').setValue(data.zipcode);
      this.group.get('location').get('city').setValue(data.city);
      this.group.get('location').get('country').setValue(data.country);
      this.group.get('location').get('mapsUrl').setValue(`https://www.google.com/maps/search/?api=1&query=${data.zipcode}%20${data.city}%20${data.street}%20${this.group.get('location').get('appartment').value}`);
    });
  }

  protected filterAdminTemplate() {
    if (!this.groupsAdmin) {
      return;
    }

    let search = this.adminFilterCtrl.value;
    if (!search) {
      this.adminFilter.next(this.groupsAdmin.filter((groupObj: Group) => {
        if ((this.group.get('attendees').get('optional').value as number[]).includes(groupObj.id)) {
          return false;
        }
        if ((this.group.get('attendees').get('required').value as number[]).includes(groupObj.id)) {
          return false;
        }
        return true;
      }, this));
      return;
    }

    this.adminFilter.next(this.groupsAdmin.filter((groupObj: Group) => {
      if ((this.group.get('attendees').get('optional').value as number[]).includes(groupObj.id)) {
        return false;
      } else if ((this.group.get('attendees').get('required').value as number[]).includes(groupObj.id)) {
        return false;
      } else if (groupObj.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
        return false;
      }
      return true;
    }, this));
  }

  protected filterRequiredTemplate() {
    if (!this.groupsRequired) {
      return;
    }

    let search = this.requiredFilterCtrl.value;
    if (!search) {
      this.requiredFilter.next(this.groupsAdmin.filter((groupObj: Group) => {
        if ((this.group.get('attendees').get('admin').value as number[]).includes(groupObj.id)) {
          return false;
        }
        if ((this.group.get('attendees').get('optional').value as number[]).includes(groupObj.id)) {
          return false;
        }
        return true;
      }, this));
      return;
    }

    this.requiredFilter.next(this.groupsAdmin.filter((groupObj: Group) => {
      if ((this.group.get('attendees').get('admin').value as number[]).includes(groupObj.id)) {
        return false;
      } else if ((this.group.get('attendees').get('optional').value as number[]).includes(groupObj.id)) {
        return false;
      } else if (groupObj.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
        return false;
      }
      return true;
    }, this));
  }

  protected filterOptionalTemplate() {
    if (!this.groupsOptional) {
      return;
    }

    let search = this.optionalFilterCtrl.value;
    if (!search) {
      this.optionalFilter.next(this.groupsAdmin.filter((groupObj: Group) => {
        if ((this.group.get('attendees').get('admin').value as number[]).includes(groupObj.id)) {
          return false;
        }
        if ((this.group.get('attendees').get('required').value as number[]).includes(groupObj.id)) {
          return false;
        }
        return true;
      }, this));
      return;
    }

    this.optionalFilter.next(this.groupsAdmin.filter((groupObj: Group) => {
      if ((this.group.get('attendees').get('admin').value as number[]).includes(groupObj.id)) {
        return false;
      } else if ((this.group.get('attendees').get('required').value as number[]).includes(groupObj.id)) {
        return false;
      } else if (groupObj.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
        return false;
      }
      return true;
    }, this));
  }
}
