<div class="row mt-4">
  <div class="col-lg-4 col-md-4 col-10 mb-2">
    <mat-form-field class="input-full-width">
      <mat-label>Gefahrene Kilometer</mat-label>
      <input matInput type="number" [(ngModel)]="_host._data.data.distance" (keyup)="checkError()" [disabled]="this._host._data.disabledElements" />
      <span matSuffix>km</span>
      <mat-hint class="mat-error" *ngIf="!_host._data.data.distance">
        Bitte gib die gefahrene Distanz ein!
      </mat-hint>
    </mat-form-field>  
  </div>
  <div class="col-1">
    <button mat-mini-fab color="accent" style="margin-top: 10px; margin-bottom: 10px" matTooltip="Distanz berechnen" (click)="calculateDistance()" [disabled]="this._host._data.disabledElements">
      <mat-icon [ngClass]="{'md-spin' : showDistanceLoader }">{{ !showDistanceLoader ? 'explore' : 'refresh'}}</mat-icon>
    </button>  
  </div>
  <div class="col-12" [ngClass]="_host._data.host._host.statusCategory === 'invoice' ? 'col-lg-3 col-md-3' : 'offset-lg-3 offset-md-3 col-lg-4 col-md-4'">
    <mat-form-field class="input-full-width">
      <mat-label>Fahrzeug eingesetzt</mat-label>
      <mat-select [(ngModel)]="_host._data.data.used" [disabled]="_host._data.data.functionsUsed.length > 0 || _host._data.materials.length > 0 || this._host._data.disabledElements"
        (selectionChange)="_host._data.data.calculated = _host._data.data.used">
        <mat-option [value]="true">Ja</mat-option>
        <mat-option [value]="false">Nein</mat-option>
      </mat-select>
    </mat-form-field>  
  </div>
  <div class="offset-lg-1 offset-md-1 col-lg-3 col-md-3 col-12" *ngIf="_host._data.host._host.statusCategory === 'invoice'">
    <mat-form-field class="input-full-width">
      <mat-label>Fahrzeug abgerechnet</mat-label>
      <mat-select [(ngModel)]="_host._data.data.calculated" [disabled]="_host._data.disabledElements" [disabled]="!_host._data.data.used || this._host._data.disabledElements">
        <mat-option [value]="true">Ja</mat-option>
        <mat-option [value]="false">Nein</mat-option>
      </mat-select>
    </mat-form-field>  
  </div>
  <div class="col-12" *ngIf="vehicleFunctions.length > 0">
    <mat-form-field class="input-full-width">
      <mat-label>Fahrzeuggeräte eingesetzt</mat-label>
      <mat-select [(ngModel)]="_host._data.data.functionsUsed" multiple (selectionChange)="selectedFunctions($event)" [disabled]="this._host._data.disabledElements">
        <mat-option *ngFor="let function of vehicleFunctions" [value]="function.id">{{function.name}}</mat-option>
      </mat-select>
    </mat-form-field>  
  </div>
</div>