import { Component, Host, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AlarmsComponent } from '../alarms-edit.component';
import { ConfigModule } from '../../../../util/config';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSelect as MatSelect } from '@angular/material/select';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AlarmsVehiclesDialogComponent } from './alarms-vehicles-dialog/alarms-vehicles-dialog.component';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { PreventCloseGuard } from 'src/app/util/guards/prevent-close.guard';
import { getHostUrl } from 'src/app/util/config';
import Swal from 'sweetalert2';


interface User {
  type: number;
  fullname: string;
  userId: number;
  used: number;
  function: number[];
  required: boolean;
  focused: boolean;
}
interface Vehicle {
  used: boolean;
  seats: User[];
  vehicle: {
    id: number;
    name: string;
    status: number;
    seats: number;
    type: number;
    disabled: boolean;
  },
  material: []
}
@Component({
  selector: 'app-alarms-vehicles',
  templateUrl: './alarms-vehicles.component.html',
  styleUrls: ['./alarms-vehicles.component.css'],
})
export class AlarmsVehiclesComponent implements OnInit, OnDestroy, PreventCloseGuard {

  opened: boolean = false;
  hasError: boolean = true;

  vehiclesList = [];

  vehicleDataList = [];

  initialValue = [];

  disabledElements = false;

  vehicleData: MatTableDataSource<Vehicle>;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  userList = [];

  displayedColumns: string[] = ['vehicle', 'actions'];

  public vehicleFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public vehicleCtrl: UntypedFormControl = new UntypedFormControl();

  public vehicleFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  constructor(private _http: HttpClient,
    @Host() public _host: AlarmsComponent,
    private _config: ConfigModule,
    private _dialog: MatDialog) { }

  onCloseValid(): boolean {
    return JSON.stringify(this.filterRequestVehicles()) === JSON.stringify(this.initialValue);
  }

  ngOnInit(): void {
    this._host.showLoader = true;
    this.vehicleData = new MatTableDataSource<Vehicle>();
    this.vehicleData.sort = this.sort;
    this.fetchDataInfo().then(() => {
      if (this._host.alarmID) {
        this.loadVehicleData();
      } else
        this._host.showLoader = false;
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterVehicles() {
    if (!this.vehiclesList) {
      return;
    }
    let search = this.vehicleFilterCtrl.value;
    this.vehiclesList.forEach(vehicle => {
      vehicle.disabled = this.hasConfiguration(vehicle) !== null;
    });
    if (!search) {
      this.vehicleFilter.next(
        this.vehiclesList.filter(vehicle => vehicle.disabled === false),
      );
      return;
    } else {
      search = search.toLowerCase();
      this.vehicleFilter.next(
        this.vehiclesList.filter(vehicle => vehicle.name.toLowerCase().indexOf(search) > -1 && vehicle.disabled === false),
      );
    }
  }

  fetchDataInfo() {
    return new Promise((resolve, reject) => {
      this._host.showLoader = true;
      Promise.all([
        this.loadVehicles(),
        this.loadUsers(),
      ]).then((data: any) => {
        this._host.showLoader = false;
        this.vehiclesList = data[0];
        this.userList = data[1].filter(u => u.groups.includes(2));

        this.vehicleFilter.next(this.vehiclesList.slice());
        this.vehicleFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterVehicles();
          });
        resolve(true);
      }).catch(error => {
        this._host.showLoader = false;
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
        reject();
      }).catch(reject);
    });
  }

  loadVehicles() {
    return this._http.get<any>(`${getHostUrl()}vehicles/list`)
      .pipe(takeUntil(this._onDestroy))
      .toPromise();
  }

  loadUsers() {
    return this._http.get(`${getHostUrl()}user/all`)
      .pipe(takeUntil(this._onDestroy))
      .toPromise();
  }

  userAlreadyTaken(userId, vehicleId) {
    let found = false;
    this.vehicleDataList.forEach(vehicle => {
      vehicle.seats.forEach(seat => {
        if (seat.userId === userId && vehicle.vehicle.id !== vehicleId) {
          found = true;
        }
      });
    });
    return found;
  }

  saveAlarmVehicles() {
    return new Promise((resolve, reject) => {
      const filteredList = this.filterRequestVehicles();
      this._host.showLoader = true;
      this._http.post(`${getHostUrl()}alarms/vehicles`, {
        alarmID: this._host.alarmID,
        vehicleList: filteredList,
      })
        .pipe(takeUntil(this._onDestroy))
        .subscribe(result => {
          this.initialValue = filteredList;
          setTimeout(() => {
            this._host.showLoader = false;
          }, 1300);
          resolve(true);
        }, error => reject);
    });
  }

  loadVehicleData() {
    this._host.showLoader = true;
    return this._http.get<any>(`${getHostUrl()}alarms/vehicles?alarmID=${this._host.alarmID}`)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(result => {
        this.vehicleDataList = result;
        this.initialValue = result;
        this.vehicleData.data = this.vehicleDataList;
        this._host.showLoader = false;
        this.filterVehicles();
        this.checkError();
      }, error => {
        this._host.showLoader = false;
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      });
  }

  filterRequestVehicles() {
    const filteredList = JSON.parse(JSON.stringify(this.vehicleDataList));
    filteredList.forEach(vehicle => {
      vehicle.seats.forEach((elem, index) => {
        if (elem.userId === '' && elem.type !== 1) {
          vehicle.seats.splice(index, 1);
        }
      });
    });
    return filteredList;
  }

  newVehicle(event = null, vehicleData = null) {
    const data: any = {
      alarmId: this._host.alarmID,
      userList: this.userList,
      materials: [],
      seats: null,
      host: this,
      data: {
        distance: null,
        used: false,
        functionsUsed: [],
        transport: false,
        calculated: false,
      },
    };

    if (event !== null) {
      const matSelect: MatSelect = event.source;
      matSelect.writeValue(null);
      data.vehicle = event.value;
    }

    if (vehicleData !== null) {
      data.seats = JSON.parse(JSON.stringify(vehicleData.seats));
      data.vehicle = vehicleData.vehicle;
      data.materials = JSON.parse(JSON.stringify(vehicleData.materials));
      data.data = JSON.parse(JSON.stringify(vehicleData.data));
    }

    data.disabledElements = this.disabledElements;
    this.vehicleCtrl.reset();

    const dialogRef = this._dialog.open(AlarmsVehiclesDialogComponent, {
      data: data,
      disableClose: true,
      width: '98%',
      position: {
        left: '1%',
        right: '1%',
      },
      maxWidth: 'none',
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(result => {
        if (result && result.data) {
          this.addOrUpdate(data.vehicle, result.data);
          this.saveAlarmVehicles();
        }
      });
  }

  countUser(vehicle) {
    let count = 0;
    vehicle.seats.forEach(seat => {
      if (seat.userId !== '') {
        count++;
      }
    });
    return count;
  }

  checkError() {
    this.hasError = true;

    this.vehicleDataList.forEach(elem => {
      if (elem.seats && elem.seats.length > 0) {
        this.hasError = false;
      }
    });
  }

  hasConfiguration(vehicle) {
    let matchedVehicle = null;
    if (this.vehicleDataList.length > 0) {
      this.vehicleDataList.forEach(vehicleData => {
        if (vehicleData.vehicle.id === vehicle.id) {
          matchedVehicle = vehicleData;
        }
      });
    }
    return matchedVehicle;
  }

  addOrUpdate(vehicle, data) {
    const vehicleData = this.hasConfiguration(vehicle);
    if (vehicleData !== null) {
      vehicleData.seats = data.seats;
      vehicleData.materials = data.materials;
      vehicleData.data = data.data;
    } else {
      this.vehicleDataList.push({
        vehicle: vehicle,
        seats: data.seats,
        materials: data.materials,
        data: data.data,
      });
    }
    this.vehicleData.data = this.vehicleDataList;
    this.filterVehicles();
    this.checkError();
  }

  removeVehicle(vehicle: Vehicle) {
    const swalObject = Swal.fire({
      title: `${vehicle.vehicle.name} wirklich löschen?`,
      icon: 'warning',
      showConfirmButton: true,
      showDenyButton: true,
      reverseButtons: true,
      denyButtonText: 'Abbrechen',
      confirmButtonText: 'Löschen',
    });
    swalObject.then(result => {
      if (result.isConfirmed) {
        const index = this.vehicleDataList.findIndex(v => v === vehicle);
        this.vehicleDataList.splice(index, 1);
        this.vehicleData.data = this.vehicleDataList;
        this.checkError();
        this.filterVehicles();
        this.saveAlarmVehicles();
      }
    });
  }

  getIconByNumber(status) {
    switch (parseInt(status, 10)) {
      case 1:
        return '1️⃣';
      case 2:
        return '2️⃣';
      case 3:
        return '3️⃣';
      case 4:
        return '4️⃣';
      case 5:
        return '5️⃣';
      case 6:
        return '6️⃣';
      case 7:
        return '7️⃣';
      case 8:
        return '8️⃣';
      case 9:
        return '9️⃣';
      default:
        return '0️⃣';
    }
  }
}
