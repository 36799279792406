<p>Hier sind alle wichtigen Informationen von und über dich zu sehen. Diese Seite ist nur für dich persönlich sichtbar.
</p>
<ul>
    <li><b>Stammdaten</b><br />hier kannst du all deine persönlichen Daten sehen. Einige musst du bitte selbständig
        ändern, z.B. bei Umzug. Es ist wichtig, dass wir immer die aktuellsten Daten von dir im System haben.</li>
    <li><b>Feuerwehr Daten</b><br />hier sind all deine allgemeinen
        Feuerwehrdaten von dir zu sehen. Diese werden von den Schriftführern angepasst. Du kannst kontrollieren, ob alle
        Daten stimmen.</li>
    <li><b>Atemschutz</b><br />hier kannst du deine G26/3 aktualisieren.<br />Alle weiteren Daten werden von der
        Atemschutzwerkstatt gepflegt.</li>
    <li><b>Maschinisten</b><br />hier musst du bitte deine allgemeinen Führerscheindaten pflegen. <b>Vor jeder Änderung
            muss erst ein aktuelles Führerscheinbild hochgeladen werden.</b><br />
        Die Fahrerlaubnisklassen und –berechtigungen werden durch die Maschinistenausbilder geführt, du kannst die Daten
        kontrollieren.<br />Der Führerschein muss mindestens alle 6 Monate kontrolliert werden. Dies geschieht durch
        auflegen des RFID-Chips auf den Scanner am Alarmmonitor oder durch einen Maschinistenausbilder.<br />Das letzte Kontrolldatum wird in deinem Profil
        angezeigt.
        <div style="margin-left: 25px; margin-top: 5px;" class="row">
            <div class="col-lg-6 col-12">
                <b>Legende Führerscheinfelder</b>
                <p>Ausstellungsdatum: <b>4a</b>
                    <br />Ablaufdatum: <b>4b</b>
                    <br />Ausstellungsbehörde: <b>4c</b>
                    <br />Führerscheinnummer: <b>5</b>
                </p>
            </div>
        </div>
    </li>
    <li><b>Notfallkontakte</b><br />hier bitte deine Notfallkontakte eintragen. Diese können nur von den Dienstgraden
        eingesehen werden und können im Notfall kontaktiert werden. Auch diese Daten sind wichtig und müssen immer
        aktuell sein.</li>
</ul>