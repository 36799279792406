<div class="scroll" style="max-height: 80vh">
  <mat-form-field class="input-full-width" appearance="outline">
    <mat-label>Vorlage auswählen</mat-label>
    <mat-select (selectionChange)="selectedItem($event)" [formControl]="templateCtrl">
        <mat-option>
            <ngx-mat-select-search [formControl]="templateFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let template of templateFilter | async"
          [value]="template">{{ template.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
