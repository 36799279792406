<div class="mt-2">
  <div class="row mb-3" style="overflow: hidden;">
    <div class="col-lg-3 col-md-8 col-12 offset-lg-1 mb-1">
      <mat-checkbox *ngIf="isVehicle" (change)="toggleTransport()" [checked]="transportVehicle"
        style="margin-top: 20px; float: left" color="primary" [disabled]="this._host._data.disabledElements">
        Zubringer Fahrzeug (unbegrenzt Sitzplätze)</mat-checkbox>
    </div>
    <div class="col-lg-3 col-md-8 col-12 offset-lg-4 mb-1">
      <mat-checkbox *ngIf="isVehicle" style="margin-top: 20px; float: left" id="checkAllUsed" color="primary"
        [checked]="allUsed" [disabled]="this._host._data.disabledElements" (change)="setAllPersonsUsed($event.checked)">
        Alle Eingesetzt</mat-checkbox>
    </div>
    <hr />
  </div>
  <div class="row" *ngFor="let seat of vehicleSeats; let i = index">
    <div [ngClass]="_data.vehicle.name !== 'Bereitschaft' ? 'col-lg-6 col-md-6' : 'col-lg-12 col-md-12'" class="col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Name oder Stammnummer</mat-label>
        <input matInput id="{{ 'input-' + i }}" [(ngModel)]="seat.fullname" (keyup)="filterUser(seat, $event)"
          (focus)="focusField(seat)" (keyup)="checkIfValid()" [disabled]="this._host._data.disabledElements"
          name="input-{{i}}" [matAutocomplete]="auto" />
        <span matPrefix class="ms-3">{{seat.userId}}</span>
        <button mat-icon-button matSuffix (click)="removeLine(i, seat)" *ngIf="!this._host._data.disabledElements">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-6 col-12" *ngIf="isVehicle">
      <mat-form-field class="input-full-width noPaddingBottom" appearance="outline">
        <mat-label>Tätigkeit</mat-label>
        <mat-select [(ngModel)]="seat.functions" [disabled]="seat.userId===0"
          (selectionChange)="checkIfValid(); checkIfAllUsed()" multiple [disabled]="this._host._data.disabledElements">
          <mat-option *ngFor="let function of seatFunctions" [value]="function.id"
            [disabled]="usedFunctions.includes(function.id) && !seat.functions.includes(function.id)">
            {{function.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedUser($event.option.value)"
  autoActiveFirstOption="true">
  <mat-option *ngFor="let user of filteredUser" [value]="user" (keydown)="$event.stopPropagation()">
    {{ user.id }} - {{ user.name }}
  </mat-option>
</mat-autocomplete>