<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">{{firstname}} {{lastname}}</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>
        <div class="row">
          <div class="col-lg-2 col-md-3">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Stammnummer</mat-label>
              <input type="number" [(ngModel)]="userInfo.id" matInput readonly>
            </mat-form-field>
          </div>
          <div class="col-lg-10 col-md-9">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Dienstrang</mat-label>
              <input type="text" [(ngModel)]="userInfo.officialRank" matInput readonly>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Ehrenamtskartentyp</mat-label>
              <mat-select [(ngModel)]="userInfo.voluntaryCardType" required
                (selectionChange)="voluntaryCardChange($event)"
                [disabled]="!_login.hasPermission('admin-user-firedata-edit')">
                <mat-option value="0">--</mat-option>
                <mat-option value="1">Blau</mat-option>
                <mat-option value="2">Gold</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-12">
            <app-month-yearpicker [label]="userInfo.voluntaryCardType === '2' ? 'Ausstellungsdatum' : 'Ablaufdatum'"
              [inputValue]="userInfo.voluntaryCardDate" (value)="onDatepickerChange($event)"
              [required]="userInfo.voluntaryCardType!=='0'"
              [disabled]="!_login.hasPermission('admin-user-firedata-edit') || userInfo.voluntaryCardType==='0'"
              [min]="startDate" [max]="endDate">
            </app-month-yearpicker>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Spindreihe</mat-label>
              <input [(ngModel)]="userInfo.lockerRow" matInput>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Spind</mat-label>
              <input [(ngModel)]="userInfo.locker" matInput>
            </mat-form-field>
          </div>
        </div>
        <mat-checkbox [checked]="userInfo.dsgvo" [(ngModel)]="userInfo.dsgvo"
          [disabled]="!_login.hasPermission('admin-user-firedata-edit')" color="primary">Unterschrift
          DSGVO</mat-checkbox>
        <br />
        <mat-checkbox [checked]="userInfo.personalPhotos" [(ngModel)]="userInfo.personalPhotos"
          [disabled]="!_login.hasPermission('admin-user-firedata-edit')" color="primary">Unterschrift Veröffentlichung
          Bilder</mat-checkbox>
        <br />
        <div class="clearfix" *ngIf="_login.hasPermission('admin-user-firedata-edit')">
          <button mat-raised-button color="success" class="float-right" (click)="saveFeuerwehrData()"
            [disabled]="userInfo.voluntaryCardType!=='0' && userInfo.voluntaryCardDate===null">Speichern
            <mat-icon>save</mat-icon></button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>