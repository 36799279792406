<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Sicherheitswachen</h3>
    <mat-card>
      <mat-card-content>
        <a mat-mini-fab color="primary" class="pageBack" onClick="javascript:window.history.back()">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a mat-mini-fab color="primary" class="pageNext" routerLink="new">
          <mat-icon>domain_add</mat-icon>
        </a>
        <div class="row">
          <div class="col-lg-8 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applySearch($event.target.value)" placeholder="Suche">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Wachstatus</mat-label>
              <mat-select multiple (selectionChange)="applyStatusFilter($event)" [(ngModel)]="statusFilter">
                <mat-option value="angelegt">angelegt</mat-option>
                <mat-option value="in Einteilung">in Einteilung</mat-option>
                <mat-option value="eingeteilt">eingeteilt</mat-option>
                <mat-option value="in Abrechnung">in Abrechnung</mat-option>
                <mat-option value="abgerechnet">abgerechnet</mat-option>
                <mat-option value="abgesagt">abgesagt</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <div class="table-responsive">
              <div *ngIf="loading">
                <mat-progress-bar mode="query"></mat-progress-bar>
              </div>
              <table mat-table [dataSource]="guards" matSort matSortActive="date"
                matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                  <td mat-cell *matCellDef="let guard">{{guard.name}} <mat-icon *ngIf="guard.verified" matTooltip="Veranstaltung bestätigt">verified</mat-icon></td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum</th>
                  <td mat-cell *matCellDef="let guard">
                    <div>
                      {{ guard.date | date:'dd.MM.YYYY' }}
                    </div>
                    <div style="margin-left: 10px;">
                      {{ guard.guardStart }} Uhr
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                  <td mat-cell *matCellDef="let guard" [ngSwitch]="guard.status">
                    <span *ngSwitchCase="'angelegt'">angelegt</span>
                    <span *ngSwitchCase="'in Einteilung'">in Einteilung</span>
                    <span *ngSwitchCase="'eingeteilt'">eingeteilt</span>
                    <span *ngSwitchCase="'in Abrechnung'">in Abrechnung</span>
                    <span *ngSwitchCase="'abgerechnet'">abgerechnet</span>
                    <span *ngSwitchCase="'abgesagt'">abgesagt</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="location">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Ort</th>
                  <td mat-cell *matCellDef="let guard">
                    <span>{{(guard.location | guardLocation)?.name}}
                      <a target="_blank" [href]="(guard.location | guardLocation)?.location.mapsUrl"
                        style="margin-left: 10px;">
                        <mat-icon matTooltip="in Maps öffnen" matTooltipPosition="above">place</mat-icon>
                      </a>
                    </span>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="viewColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: viewColumns" [routerLink]="['details', row.id]"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>