<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header" *ngIf="userInfo!==undefined">{{userInfo.firstname}}
      {{userInfo.lastname}}</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>
        <div class="row">
          <div class="col-lg-2 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Anrede</mat-label>
              <mat-select [(ngModel)]="userInfo.sex" required [disabled]="!_login.hasPermission('admin-user-edit')">
                <mat-option value="w">Frau</mat-option>
                <mat-option value="m">Herr</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-2 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Titel</mat-label>
              <mat-select [(ngModel)]="userInfo.title" required [disabled]="!_login.hasPermission('admin-user-edit')">
                <mat-option value="0">--</mat-option>
                <mat-option value="1">Dr.</mat-option>
                <mat-option value="2">Prof. Dr.</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Vorname</mat-label>
              <input [(ngModel)]="userInfo.firstname" matInput required
                [disabled]="!_login.hasPermission('admin-user-edit')">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Nachname</mat-label>
              <input [(ngModel)]="userInfo.lastname" matInput required
                [disabled]="!_login.hasPermission('admin-user-edit')">
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>E-Mail</mat-label>
              <input matInput required [formControl]="mailFormControl">
              <mat-error *ngIf="mailFormControl.hasError('required')">
                E-Mailadresse erforderlich
              </mat-error>
              <mat-error *ngIf="mailFormControl.hasError('email')">
                keine gültige E-Mailadresse
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Interne E-Mail</mat-label>
              <input matInput [formControl]="ffoMailFormControl">
              <mat-error *ngIf="ffoMailFormControl.hasError('email')">
                keine gültige E-Mailadresse
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Telefon (Mobil)</mat-label>
              <input type="tel" matInput placeholder="+491712345678" [formControl]="phoneFormControl">
              <mat-error *ngIf="phoneFormControl.hasError('pattern')">
                benötigtes Format: +491712345678
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Telefon (Privat)</mat-label>
              <input type="tel" matInput placeholder="+4989512563" [formControl]="homephoneFormControl">
              <mat-error *ngIf="homephoneFormControl.hasError('pattern')">
                benötigtes Format: +4989512563
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-10 col-12">
            <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
              <mat-label>Straße</mat-label>
              <input matInput required readonly [formControl]="streetFormControl">
              <mat-error *ngIf="streetFormControl.hasError('required')">
                Adresse erforderlich
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-2 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Hausnummer</mat-label>
              <input [(ngModel)]="userInfo.appartment" matInput required
                [disabled]="!_login.hasPermission('admin-user-edit')">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-6">
            <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
              <mat-label>Postleitzahl</mat-label>
              <input type="number" [(ngModel)]="userInfo.zipcode" matInput required disabled>
            </mat-form-field>
          </div>
          <div class="col-lg-8 col-12">
            <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
              <mat-label>Ort</mat-label>
              <input [(ngModel)]="userInfo.city" matInput required disabled>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <mat-form-field id="birthdate" class="input-full-width" appearance="outline">
              <mat-label>Geburtsdatum</mat-label>
              <input matInput [matDatepicker]="birthdate" placeholder="TT.MM.JJJJ" [max]="today" [formControl]="birthdateFormControl"
                (dateChange)="onDatepickerChange($event)" (change)="onDateChange($event)" (focus)="birthdate.open()"
                style="cursor: pointer;">
              <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
              <mat-datepicker #birthdate [touchUi]="env.mobile" [startAt]="today"
                startView="multi-year"></mat-datepicker>
              <mat-error *ngIf="birthdateFormControl.hasError('matDatepickerParse')">Geburtsdatum muss im Format
                TT.MM.JJJJ
                sein.</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Geburtsort</mat-label>
              <input matInput [formControl]="birthplaceFormControl" readonly (click)="findBirthPlace()">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Staatsangehörigkeit</mat-label>
              <mat-select multiple [(ngModel)]="userInfo.nationality"
                [disabled]="!_login.hasPermission('admin-user-edit')">
                <mat-option *ngFor="let country of countryList" [value]="country.id.toString()">
                  {{ country.nationality }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="clearfix">
          <div class="float-right">
            <button mat-raised-button color="warn" class="me-2" (click)="disableUser()" *ngIf="_login.hasPermission('admin-user-delete')"
              [disabled]="userInfo.isDisabled">
              <mat-icon>logout</mat-icon>
              Benutzer als ausgetreten markieren
            </button>
            <button mat-raised-button color="success" (click)="saveUserData()" *ngIf="_login.hasPermission('admin-user-edit')"
              [disabled]="dataFormGroup.invalid || userInfo.city === '' || userInfo.appartment === ''">Speichern
              <mat-icon>save</mat-icon>
            </button>
          </div>
          <mat-error *ngIf="dataFormGroup.invalid || userInfo.city === '' || userInfo.appartment === ''"
            style="padding-left:10px;">Es müssen alle Felder korrekt ausgefüllt sein.</mat-error>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>