import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { CustomDateAdapterService } from '../../../customDateAdapter';

@Component({
  selector: 'app-yearpicker',
  templateUrl: './yearpicker.component.html',
  styleUrls: ['./yearpicker.component.css'],
  providers: [{
    provide: DateAdapter,
    useClass: CustomDateAdapterService,
  },
  {
    provide: MAT_DATE_FORMATS, useValue: { display: { dateInput: 'yearLabel' } },
  }],
})
export class YearpickerComponent implements OnInit {

  @Output() year = new EventEmitter<number>();
  @Input() label: string = 'Jahr';
  @Input() required: boolean = false;

  date = new UntypedFormControl(null);

  today = new Date();

  ngOnInit(): void {
    if (this.required) {
      this.date.setValue(new Date());
      this.year.emit(this.date.value.getFullYear());
    }
  }

  open(datepicker: MatDatepicker<Date>): void {
    if (!this.required && !this.date.value) {
      this.date.setValue(new Date());
    }
    datepicker.open();
  }

  chosenYearHandler(normalizedYear: Date, datepicker: MatDatepicker<Date>) {
    const ctrlValue: Date = this.date.value;
    ctrlValue.setFullYear(normalizedYear.getFullYear());
    this.date.setValue(ctrlValue);
    this.year.emit(ctrlValue.getFullYear());
    datepicker.close();
  }
}
