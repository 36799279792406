import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { AlarmsReportComponent } from '../alarms-report/alarms-report.component';

@Component({
  selector: 'app-alarms-report-templates',
  templateUrl: './alarms-report-templates.component.html',
  styleUrls: ['./alarms-report-templates.component.css'],
})
export class AlarmsReportTemplatesComponent implements OnInit, OnDestroy {

  templateList = [];

  public templateCtrl: UntypedFormControl = new UntypedFormControl();

  public templateFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public templateFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  @Input() report: AlarmsReportComponent;

  constructor(private _http: HttpClient,
    private _login: Login) { }

  ngOnInit(): void {
    this.templateFilter.next(this.templateList.slice());
    this.templateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTemplate();
      });
    this.loadTemplates();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  selectedItem($event) {
    const value = $event.value;
    this.templateCtrl.reset();
    this.report.group.get('report').setValue(value.text);
  }

  loadTemplates() {
    this._http.get<any>(`${getHostUrl()}datainfo/report/templates`).subscribe(data => {
      this.templateList = data;
      this.templateFilter.next(this.templateList.slice());
    });
  }

  protected filterTemplate() {
    if (!this.templateList) {
      return;
    }

    // get the search templates
    let search = this.templateFilterCtrl.value;
    if (!search) {
      this.templateFilter.next(this.templateList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the templates
    this.templateFilter.next(
      this.templateList.filter(template => template.name.toLowerCase().indexOf(search) > -1));
  }

}
