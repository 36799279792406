<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">{{firstname}}
      {{lastname}}</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>
        <div class="row" *ngFor="let contact of emergencyContactList; let i = index">
          <div class="col-lg-6 col-7">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Vorname Nachname</mat-label>
              <input matInput required (change)="onNameChanged(i)" [formControl]="emergenyNameFormControl[i]" />
              <mat-error *ngIf="emergenyNameFormControl[i].hasError('required')">
                Vorname & Nachnamen erforderlich
              </mat-error>
              <mat-error *ngIf="emergenyNameFormControl[i].hasError('pattern')">
                Format: Vorname Nachname
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-5">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Beziehung</mat-label>
              <mat-select [value]="contact.relationship" required (selectionChange)="onRelationshipChanged(i, $event)"
                [disabled]="!_login.hasPermission('admin-emergencycontact-edit')">
                <mat-option *ngFor="let rel of relationshipList" [value]="rel">
                  {{rel}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Telefonnummer</mat-label>
              <input type="tel" matInput required (change)="onPhoneChanged(i)"
                [formControl]="emergenyPhoneFormControl[i]" />
              <mat-error *ngIf="emergenyPhoneFormControl[i].hasError('required')">
                Telefonnummer erforderlich
              </mat-error>
              <mat-error *ngIf="emergenyPhoneFormControl[i].hasError('pattern')">
                benötigtes Format: +491712345678
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>E-Mail</mat-label>
              <input type="email" matInput (change)="onEmailChanged(i)" [formControl]="emergenyEmailFormControl[i]" />
              <mat-error *ngIf="emergenyEmailFormControl[i].hasError('email')">
                keine gültige E-Mailadresse
              </mat-error>
              <mat-icon matSuffix style="cursor: pointer;" (click)="removeContactField(i)"
                *ngIf="_login.hasPermission('admin-emergencycontact-edit')">remove_circle</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf="i+1 < emergencyContactList.length">
            <hr />
          </div>
        </div>
        <div class="clearfix" *ngIf="_login.hasPermission('admin-emergencycontact-edit')">
          <button mat-raised-button color="accent" class="float-left" (click)="addContactField()">hinzufügen
            <mat-icon>library_add</mat-icon></button>
          <button mat-raised-button color="success" class="float-right" (click)="saveContactsData()"
            [disabled]="!contactsvalid"
            *ngIf="emergencyContactList.length!==0 || removedContactList.length!==0">Speichern
            <mat-icon>save</mat-icon></button>
          <mat-error *ngIf="!contactsvalid" class="float-right" style="padding-top:10px;">Es müssen alle Felder korrekt
            ausgefüllt sein.</mat-error>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>