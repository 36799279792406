import { Component, Host, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ConfigModule } from 'src/app/util/config';
import Swal from 'sweetalert2';
import { AlarmsVehiclesDialogComponent } from '../alarms-vehicles-dialog.component';

@Component({
  selector: 'app-alarms-vehicle-dialog-persons',
  templateUrl: './alarms-vehicle-dialog-persons.component.html',
  styleUrls: ['./alarms-vehicle-dialog-persons.component.css'],
})
export class AlarmsVehicleDialogPersonsComponent implements OnInit {

  _data: any;

  hasError = true;
  errorMessage = 'Daten fehlerhaft';

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  vehicleSeats = [];

  isVehicle = false;

  seatFunctions = [
    { id: 10, name: 'Eingesetzt' },
    { id: 1, name: 'Maschinist' },
    { id: 2, name: 'Fahrzeugführer' },
    { id: 3, name: 'Filter' },
    { id: 4, name: 'Pressluftatmer' },
    { id: 5, name: 'Twin-Pack' },
    { id: 6, name: 'CSA' },
  ];

  filteredUser = [];

  transportVehicle = false;

  allUsed = false;

  usedFunctions = [];

  constructor(private _config: ConfigModule,
    @Host() private _host: AlarmsVehiclesDialogComponent) {
  }

  ngOnInit(): void {
    this._data = this._host._data;
    this.prepareVehicleUser();
    const seats = this._data.seats;
    this.transportVehicle = this._data.data.transport;
    if (seats) {
      this.vehicleSeats = seats;
      if (!this._host._data.disabledElements && (this.vehicleSeats.length < this._data.vehicle.seats || this.transportVehicle))
        this.addSeat(null, false);
    }
    this.checkIfAllUsed();
    this.checkIfValid();
  }

  newLine(seat) {
    if ((this.vehicleSeats.findIndex(s => s === seat) + 1) === this.vehicleSeats.length || this.transportVehicle) {
      const seats = this._data.vehicle.seats - this.vehicleSeats.length;
      if (seats > 0 || this.transportVehicle) {
        this.addSeat(2, false);
      }
    }
  }

  removeLine(index, seat) {
    const temp = JSON.parse(JSON.stringify(this.vehicleSeats));
    temp.splice(index, 1);
    this.vehicleSeats = temp;
    this.checkIfValid();
    this.checkIfAllUsed();
    if (this.vehicleSeats[this.vehicleSeats.length - 1].fullname !== '')
      this.addSeat(null, false);
  }

  toggleTransport() {
    if (this.transportVehicle && this._data.vehicle.seats < this.vehicleSeats) {
      this.transportVehicle = false;
      Swal.fire('Zu viele Sitzplätze!', 'Dieses Fahrzeug enthält zu viele Sitzplätze um den Zubringermodus zu deaktivieren. Bitte lösche diese vorher!');
      setTimeout(() => {
        this.transportVehicle = true;
      }, 100);
    } else {
      this.transportVehicle = !this.transportVehicle;
    }
  }

  userAlreadyTaken(userId) {
    let found = false;
    this.vehicleSeats.forEach(seat => {
      if (seat.userId === userId) {
        found = true;
      }
    });

    if (found === false) {
      found = this._data.host.userAlreadyTaken(userId, this._data.vehicle.id);
    }
    return found;
  }

  prepareVehicleUser() {
    if (this._data.vehicle.type !== 20) {
      this.addSeat(1, true);
      this.isVehicle = true;
    } else {
      this.addSeat(3, true);
    }
  }

  setAllPersonsUsed(status) {
    const temp = JSON.parse(JSON.stringify(this.vehicleSeats));
    temp.forEach(elem => {
      if (elem.userId) {
        if (status) {
          if (!elem.functions.includes(10)) elem.functions.push(10);
        } else {
          elem.functions.splice(elem.functions.indexOf(10), 1);
        }
      }
    });
    this.vehicleSeats = temp;
  }

  findUser(userId) {
    try {
      const search = this._data.userList.find(u => u.id === parseInt(userId, 10));
      if (search !== null) {
        return search;
      } else {
        this._config.showSnackbar('Fehler, Benutzer nicht gefunden!', 1500, 'error');
      }
    } catch { }
    return null;
  }

  filterUser(seat, event) {
    if (event.key.toLowerCase() === 'enter') {
      return;
    }

    seat.userId = '';
    this.filteredUser = this._data.userList.filter(u =>
      (u.name.toLowerCase().indexOf(seat.fullname.toLowerCase()) !== -1
        || u.id.toString().indexOf(seat.fullname) !== -1) && this.userAlreadyTaken(u.id) === false);
    this.filteredUser = this.filteredUser.sort((a, b) => a.id - b.id);
  }

  focusField(element) {
    const focusedFields = this.vehicleSeats.filter(s => s.focused === true);
    if (focusedFields.length !== 0) {
      focusedFields.forEach(elem => {
        elem.focused = false;
      });
    }
    element.focused = true;
  }

  selectedUser(user) {
    const seat = this.vehicleSeats.find(s => s.focused === true);
    if (seat.userId !== user.id) {
      seat.fullname = user.name;
      seat.userId = user.id;
      this.filteredUser = [];
      this.checkIfAllUsed();
      this.newLine(seat);
      setTimeout(() => {
        this.autocomplete.closePanel();
        this.switchLine(seat);
        this.checkIfValid();
        this.checkIfAllUsed();
      }, 100);
    }
  }

  switchLine(seat) {
    const index = this.vehicleSeats.findIndex(s => s === seat) + 1;
    if (index < this._data.vehicle.seats || this.transportVehicle) {
      this.focusField(this.vehicleSeats[index]);
      $('#input-' + index).trigger('focus');
    }
  }

  addSeat(type, required) {
    let functions = [];
    if (type === 1) {
      functions = [1];
    }
    this.vehicleSeats.push({
      fullname: '',
      userId: '',
      functions: functions,
      required: required,
      focused: false,
    });
  }

  checkIfAllUsed() {
    let status = true, count = 0;
    this.vehicleSeats.forEach(elem => {
      if (elem.userId) {
        count++;
        if (!elem.functions.includes(10)) {
          status = false;
        }
      }
    });
    if (count === 0) {
      status = false;
    }

    this.allUsed = status;
  }

  checkIfValid() {
    this.usedFunctions = [];
    let valid = false;
    this.vehicleSeats.forEach(elem => {
      if (elem.fullname && elem.userId) {
        if (elem.functions.includes(1)) {
          this.usedFunctions.push(1);
        }
        if (elem.functions.includes(2)) {
          this.usedFunctions.push(2);
        }
      }
    });
    if ((this.usedFunctions.includes(1) && this.usedFunctions.includes(2)) || !this.isVehicle) {
      valid = true;
    } else {
      if (!this.usedFunctions.includes(1) && !this.usedFunctions.includes(2)) {
        this.errorMessage = 'Fahrzeugführer und Maschinist fehlt';
      } else if (!this.usedFunctions.includes(1)) {
        this.errorMessage = 'Maschinist fehlt';
      } else if (!this.usedFunctions.includes(2)) {
        this.errorMessage = 'Fahrzeugführer fehlt';
      }
    }
    this.hasError = !valid;
  }
}
