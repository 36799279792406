import { Component, Input } from '@angular/core';
import { HelpComponent } from '../help.component';

@Component({
  selector: 'app-help-responder',
  templateUrl: './help-responder.component.html',
  styleUrls: ['./help-responder.component.css'],
})
export class HelpResponderComponent {

  @Input() parent: HelpComponent;

  constructor() { }
}
