import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Login } from 'src/app/util/login';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdminUserMenusheetComponent } from '../admin-user-menusheet/admin-user-menusheet.component';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { Courses } from '@ffo/mitgliederbereich-types';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import dayjs from 'dayjs';
import { UserNewCoursedataDialogComponent, CourseCategory } from '../../../../../util/dialogs/user-new-coursedata-dialog/user-new-coursedata-dialog.component';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

interface CourseExtend extends Courses {
  category: string;
}

@Component({
  selector: 'app-admin-user-courses',
  templateUrl: './admin-user-courses.component.html',
  styleUrls: ['./admin-user-courses.component.css'],
})
export class AdminUserCoursesComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['name', 'date', 'attachments', 'actions'];
  dataSource = new MatTableDataSource();
  courses: any[] = [];
  categoryList: any[] = [];
  categoryFilter: number[];
  searchFilter: string = '';
  dayjs = dayjs;
  isLoading = true;
  courseList: CourseCategory[] = [];
  showLoader = true;
  baseURL = getHostUrl();
  isPWA = false;

  env = environment;
  constructor(private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    public _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet) { }

  userInfo: any = {};
  firstname: string;
  lastname: string;
  userID: number;

  ngOnInit() {
    this.isPWA = window.matchMedia('(display-mode: standalone)').matches;

    this.userID = this._route.snapshot.params.userId;
    if (this.userID) {
      this.setTitle(this.userID);
      this.refreshData(this.userID);
      this.fetchDataInfo();
      this.loadCourses();
      this._config.setTitle('Verwaltung | Lädt...');
    }
  }

  async openCourse(course: Courses = null) {
    if (!this._login.hasPermission('admin-user-course-view'))
      return;

    this._bottomSheet.open(UserNewCoursedataDialogComponent, {
      panelClass: 'course-panel',
      data: {
        courses: this.courseList,
        userId: this.userID,
        course
      }
    }).afterDismissed().subscribe(result => {

      if (result) {
        this.refreshData(this.userID);
      }
    });
  }

  async deleteCourse(course: Courses) {
    const popup = await Swal.fire({
      title: 'Löschen?',
      text: 'Soll dieses Ereignis wirklich gelöscht werden?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ja, löschen',
      denyButtonText: 'Abbrechen',
      reverseButtons: true,
      icon: 'question'
    });
    if (!popup.isConfirmed) {
      return;
    }

    try {
      const result = await this._http.delete<any>(`${getHostUrl()}admin/user/course/${this.userID}/${course.id}`).toPromise();
      this._config.showSnackbar('Ereignis wurde gelöscht!', 3000, 'success');
      this.refreshData(this.userID);
    } catch (error) {
      this._config.showSnackbar('Konnte Ereignis nicht löschen', 3000, 'warning');
    }
  }

  async acceptCourse(course: Courses) {
    const popup = await Swal.fire({
      title: 'Übernehmen?',
      text: 'Soll dieses vom Benutzer hochgeladene Ereignis in seine Laufbahn übernommen werden?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ja, übernehmen',
      denyButtonText: 'Abbrechen',
      reverseButtons: true,
      icon: 'question'
    });
    if (!popup.isConfirmed) {
      return;
    }

    try {
      const result = await this._http.post<any>(`${getHostUrl()}admin/user/course/${this.userID}/${course.id}/accept`, {}).toPromise();
      this._config.showSnackbar('Ereignis wurde dem Lebenslauf hinzugefügt!', 3000, 'success');
      this.refreshData(this.userID);
    } catch (error) {
      this._config.showSnackbar('Konnte Ereignis nicht ins System übernehmen.', 3000, 'warning');
    }
  }

  setTitle(userID: number) {
    this._http.get<any>(getHostUrl() + 'admin/user/info?userID=' + userID).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this._config.setTitle(`${data.firstname + ' ' + data.lastname}`);
    });
  }

  async refreshData(userID: number) {
    const data = await this._http.get<any[]>(`${getHostUrl()}admin/user/course/${userID}`).toPromise();
    this.courses = data;

    this.dataSource = new MatTableDataSource<any>();
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'date':
          return dayjs(item.date, 'dd.MM.YYYY').toDate();
        default: return item[property];
      }
    };
    this.isLoading = false;
    this.dataSource.filterPredicate = this.filter();

    await this.loadCourses();
    this.showLoader = false;
  }

  async fetchDataInfo() {
    this.categoryList = await this._http.get<any[]>(`${getHostUrl()}datainfo/categoryCourses`).toPromise();
  }

  async loadCourses() {
    try {
      const courses = await this._http.get<CourseExtend[]>(`${getHostUrl()}datainfo/courses`).toPromise();
      this.courseList = this.buildData(courses);
    } catch (e) {
      this._config.showSnackbar('Konnte Laufbahnereignis nicht abrufen!', 3000, 'error');
    }
  }

  buildData(data: CourseExtend[]) {
    const categories = this.buildCategory(data);
    categories.forEach(elem => {
      elem.data = data.filter(s => s.category == elem.name);
    });
    return categories;
  }

  buildCategory(data: CourseExtend[]) {
    const categories: CourseCategory[] = [];
    data.forEach(elem => {
      if (!categories.find(c => c.name == elem.category))
        categories.push({
          name: elem.category,
          data: [],
        });
    });
    return categories;
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, category: this.categoryFilter });
  }

  applyGroupFilter($event: MatSelectChange) {
    this.categoryFilter = $event.value;
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, category: this.categoryFilter });
  }

  filter(): (data: Courses, filter: string) => boolean {
    let filterFunction = function (data: Courses, filter): boolean {
      let searchTerms: { search: string; } = JSON.parse(filter);
      if (searchTerms.search !== '' && data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1) { return false; }
      return true;
    };
    return filterFunction;
  }


  /**
 * Menu Bottom Sheet
 */
  openMenu() {
    const bottomSheetRef = this._bottomSheet.open(AdminUserMenusheetComponent, { data: { userID: this.userID, name: this.firstname + ' ' + this.lastname } });
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }
}
