<div class="text-center">
  <button mat-mini-fab class="float-left" *ngIf="events.length > 1" [disabled]="index===0" (click)="setIndex(-1)"
    color="primary">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-mini-fab class="float-right" *ngIf="events.length > 1" [disabled]="index===(events.length - 1)"
    (click)="setIndex(+1)" color="primary">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <br />
  <h3>{{ getEvent().title }}</h3>
  <div *ngIf="getEvent().allDay && sameDay(getEvent().start, getEvent().end)">
    <p>am <b>{{ formatDate(getEvent().start) }}</b></p>
  </div>
  <div *ngIf="getEvent().allDay && !sameDay(getEvent().start, getEvent().end)">
    <p><b>{{ formatDate(getEvent().start) }}</b> bis <b>{{ formatDate(getEvent().end) }}</b></p>
  </div>
  <div *ngIf="!getEvent().allDay && sameDay(getEvent().start, getEvent().end)">
    <p>am <b>{{ formatDate(getEvent().start) }}</b> von <b>{{ formatTime(getEvent().start) }}</b> bis
      <b>{{formatTime(getEvent().end)}}</b> Uhr
    </p>
  </div>
  <div *ngIf="!getEvent().allDay && !sameDay(getEvent().start, getEvent().end)">
    <p>von <b>{{ formatDateTime(getEvent().start) }}</b> Uhr</p>
    <p>bis <b>{{ formatDateTime(getEvent().end) }}</b> Uhr</p>
  </div>

  <div *ngIf="!['online', 'holiday'].includes(getEvent().type)">
    <h6>Adresse:</h6>
    <p>
      {{ getEvent().street }} {{ getEvent().appartment }}<br />
      {{ getEvent().zipcode }} {{ getEvent().city }}
    </p>
    <a target="_blank"
      href="https://www.google.com/maps/search/?api=1&query={{ getEvent().zipcode + ' ' + getEvent().city + ' ' + getEvent().street + ' ' + getEvent().appartment }}">
      <mat-icon>place</mat-icon> In Maps öffnen
    </a><br /><br />
  </div>
  <div *ngIf="!['local', 'holiday'].includes(getEvent().type)">
    <a target="_blank" [href]="getEvent().meetingUrl">
      <mat-icon>open_in_browser</mat-icon> Online Termin öffnen
    </a><br /><br />
  </div>

  <a [routerLink]="[getEvent().pageLink]" (click)="closeView()" mat-raised-button color="primary" *ngIf="!['holiday'].includes(getEvent().type)">Weitere Infos
    <mat-icon>visibility</mat-icon>
  </a>
</div>