import { HttpClient } from '@angular/common/http';
import { Component, Host, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { AlarmsVehiclesDialogComponent } from '../alarms-vehicles-dialog.component';
import { WebConfigService } from 'src/app/util/services/webconfig/webconfig.service';

@Component({
  selector: 'app-alarms-vehicle-dialog-material',
  templateUrl: './alarms-vehicle-dialog-material.component.html',
  styleUrls: ['./alarms-vehicle-dialog-material.component.css'],
})
export class AlarmsVehicleDialogMaterialComponent implements OnInit, OnDestroy {

  public materialSelectCtrl: UntypedFormControl = new UntypedFormControl();

  public materialFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public materialSourceCtrl: UntypedFormControl = new UntypedFormControl();

  public materials = [];

  public materialFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  materialList = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['name', 'count', 'action'];

  dataSource = new MatTableDataSource();

  constructor(private _http: HttpClient,
    private _config: ConfigModule,
    private _configService: WebConfigService,
    @Host() public _host: AlarmsVehiclesDialogComponent) { }

  ngOnInit(): void {
    this.loadMaterial();
    this.materialFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMaterial();
      });

    this.materialSourceCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.loadMaterial();
      });

    this._configService.getConfig().subscribe(result => {
      this.materialSourceCtrl.setValue(this._configService.getConfigItem(result, 'material_alarm', 'fahrzeugbezogen') === 'fahrzeugbezogen');
    });
  }

  loadMaterial(): void {
    this._http.get<any>(`${getHostUrl()}alarms/material?vehicleId=${this._host._data.vehicle.id}&showVehicle=${this.materialSourceCtrl.value ? '1' : '0'}`)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(result => {
        this.materials = result;
        this.materialList = this._host._data.materials;
        this.filterMaterial();
        this.dataSource.data = this.materialList;
      }, error => {
        this._config.showSnackbar('Fehler beim Abrufen der Materialliste!', 3000, 'error');
      });
  }

  selectItem($event) {
    this.materialList.push({
      id: $event.value.id,
      name: $event.value.name,
      qty: 1,
      unit: $event.value.unit,
    });
    this._host._data.data.used = true;
    this.materialFilterCtrl.reset();
    this.materialSelectCtrl.reset();
    this.filterMaterial();
    this.dataSource.data = this.materialList;
  }

  deleteItem(item) {
    const index = this.materialList.findIndex(i => i.id === item.id);
    if (index === -1)
      return;
    this.materialList.splice(index, 1);
    this.filterMaterial();
    this.dataSource.data = this.materialList;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterMaterial() {
    // get the search material
    let search = this.materialFilterCtrl.value;
    const list = [];
    this.materials.forEach(material => {
      if (this.materialList.findIndex(m => m.id === material.id) === -1)
        list.push(material);
    });
    if (!search) {
      this.materialFilter.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the material
    this.materialFilter.next(
      list.filter(material => material.name.toLowerCase().indexOf(search) > -1));
  }

  maxMaterialCount(id: number) {
    const mat = this.materials.find((m => m.id = id));
    return mat?.max || null;
  }
}
