<div class="container">
    <mat-card>
      <mat-card-content>
        <div class="row">
          <!-- <div class="col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput [formControl]="filterCtrl" placeholder="Suche">
            </mat-form-field>
          </div> -->
          <div class="col-lg-12 col-12">
            <div class="table-responsive">
              <div *ngIf="isLoading">
                <mat-progress-bar mode="query"></mat-progress-bar>
              </div>
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 input-full-width"
                matSortActive="category" matSortDirection="asc" matSortDisableClear>
                <!-- Name Column -->
                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Bezeichnung</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.category}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="option">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Art</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.option}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="ean">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>EAN</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.ean}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="serialNumber">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Seriennummer</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.serialNumber}}</td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>