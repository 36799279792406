<mat-spinner *ngIf="isLoading" color="accent" class="loading-spinner-center" [diameter]="200"></mat-spinner>

<div class="container">
  <div class="col-lg-10 col-12 offset-lg-1" *ngIf="!isLoading">
    <h3 class="section-header">Prüfzyklus
      <span *ngIf="!checkId" class="ms-2">erstellen</span>
      <span *ngIf="checkId" class="ms-2">bearbeiten</span>
    </h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="../../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>

        <form [formGroup]="group">
          <mat-form-field appearance="outline" class="input-full-width">
            <mat-label>Name</mat-label>
            <input matInput type="text" formControlName="name" required="true" />
            <mat-error *ngIf="group.get('name').invalid">Prüfzyklus Name ist ein Pflichtfeld!</mat-error>
          </mat-form-field>

          <div class="row">
            <div class="col-4">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Tag(e)</mat-label>
                <mat-select formControlName="days">
                  <mat-option *ngFor="let _ of [].constructor(31); let i = index" [value]="i">{{i}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Monat(e)</mat-label>
                <mat-select formControlName="months">
                  <mat-option *ngFor="let _ of [].constructor(12); let i = index" [value]="i">{{i}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Jahr(e)</mat-label>
                <mat-select formControlName="years">
                  <mat-option *ngFor="let _ of [].constructor(21); let i = index" [value]="i">{{i}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12">
              <span>Beschreibung:</span>
              <quill-editor formControlName="description" type="divarea"></quill-editor>
            </div>
          </div>
        </form>

        <div class="clearfix mt-3">
          <button mat-raised-button class="float-right" color="accent" [disabled]="group.invalid" (click)="saveData()">
            <div class="clearfix submit-button">
              <mat-icon class="me-3 mt-1 float-left" [hidden]="!isSaving">
                <mat-spinner diameter="20" class="light"></mat-spinner>
              </mat-icon>
              <span class="me-1 float-left">Speichern</span>
              <mat-icon class="float-right">save</mat-icon>
            </div>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>