import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigModule, getHostUrl } from '../../../../util/config';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as dayjs from 'dayjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Login } from 'src/app/util/login';

export interface Notification {
  id: number;
  title: string;
  message: any;
  date: string;
  read: number;
  firstname: string;
  lastname: string;
  from: number;
  avatar: string;
}

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.css'],
})
export class NotificationDetailComponent implements OnInit {
  notificationId: number;

  notificationData: Notification;

  constructor(
    private _config: ConfigModule,
    public _http: HttpClient,
    private _login: Login,
    private _spinner: NgxSpinnerService,
    private _activatedRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this._config.setTitle('Benachrichtigung');
    this._spinner.show();
    this.notificationId = parseInt(
      this._activatedRoute.snapshot.paramMap.get('notificationId'),
    );
    this.loadData();
  }

  loadData() {
    this._http
      .get<Notification>(
        `${getHostUrl()}user/notifications?notificationId=${this.notificationId
        }`,
      )
      .subscribe((data) => {
        if (data) {
          this.notificationData = data;
          this.notificationData.message = this._sanitizer.bypassSecurityTrustHtml(
            this.notificationData.message,
          );
          this.notificationData.avatar = data.avatar;
          if (this.notificationData.from === -1) {
            this.notificationData.avatar = 'assets/system.png';
            this.notificationData.firstname = 'System';
            this.notificationData.lastname = '';
          }
        }
        this._spinner.hide();
      }, (error) => {
        this._spinner.hide();
      });
  }

  getFormattedDateTime(dateValue: any) {
    if (dateValue === null || dateValue.toString() === '0000-00-00' || dateValue === '')
      return ('');
    return dayjs(dateValue).format('DD.MM.YYYY HH:mm');
  }
}
