const streetAPIApiToken: string = 'dk_nIO9xCDAsKaCzIuzASnAntwaok72tfnoW89VtxUA';
export const environment = {
  serviceWorker: true,
  deploymentEnv: 'development',
  debugging: false,
  userLoggedIn: false,
  userInfo: null,
  canShowPopup: false,
  userID: null,
  pageTitle: 'DataBOS',
  streetAPI: `https://autocomplete.search.hereapi.com/v1/autocomplete?apiKey=${streetAPIApiToken}&lang=de-DE&at=48.0632246%2c11.6603129&q=`,
  mobile: false,
  safari: false,
  sessionKey: '',
  adobeApiKey: '44c0708a780e40f9be4c60d8111f3974',
  version: '5.2.0',
};
