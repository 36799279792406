import { Component, Input } from '@angular/core';
import { Login } from 'src/app/util/login';
import { HelpComponent } from '../help.component';

@Component({
  selector: 'app-help-profile',
  templateUrl: './help-profile.component.html',
  styleUrls: ['./help-profile.component.css'],
})
export class HelpProfileComponent {

  @Input() parent: HelpComponent;

  constructor(public _login: Login) { }
}
