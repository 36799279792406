import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapter } from 'angular-calendar';
import dayjs from 'dayjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';

@Component({
  selector: 'app-profile-mass-export-alarms',
  templateUrl: './profile-mass-export-alarms.component.html',
  styleUrls: ['./profile-mass-export-alarms.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class ProfileMassExportAlarmsComponent implements OnInit {

  group: UntypedFormGroup;

  timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  toDay = dayjs().toDate();

  matcher = new ProfileMassExportErrorStateMatcher();

  showLoader = false;

  constructor(private _formBuilder: UntypedFormBuilder,
    private _http: HttpClient,
    private _config: ConfigModule) { }

  ngOnInit(): void {
    this.group = this._formBuilder.group({
      dateFrom: new UntypedFormControl('', Validators.required),
      dateTo: new UntypedFormControl('', Validators.required),
      timeFrom: new UntypedFormControl('07:00', Validators.required),
      timeTo: new UntypedFormControl('16:00', Validators.required),
      weekdays: new UntypedFormControl(['0', '1', '2', '3', '4'], Validators.required),
    }, { validators: this.checkDates });
    this.group.markAllAsTouched();
  }

  startExport() {
    this.showLoader = true;
    const value = this.group.value;
    value.dateFrom = dayjs(value.dateFrom).format('YYYY-MM-DD');
    value.dateTo = dayjs(value.dateTo).format('YYYY-MM-DD');
    this._http.post(`${getHostUrl()}profile/alarms/export`, value).subscribe(result => {
      this._config.showSnackbar('Export wurde gestartet, du erhälst hierzu in Kürze eine E-Mail. Je nach Einsatzanzahl kann dies einige Zeit in Anspruch nehmen!', 3000, 'success');
      this.showLoader = false;
    }, error => {
      this._config.showSnackbar(error.error?.message, 3000, 'error');
      this.showLoader = false;
    });
  }

  checkDates: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let dateFrom = group.get('dateFrom').value;
    let dateTo = group.get('dateTo').value;
    return dateFrom <= dateTo ? null : { smallerThan: true };
  };

  parseTimeField($event, fieldName, nextField = null, previousField = null) {
    if ($event.keyCode === 8 || $event.keyCode === 46 || $event.keyCode === 9 || $event.keyCode === 16) {
      return;
    }
    const value: string = this.group.get(fieldName).value;

    if (value.match(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#´]/)) {
      this.group.get(fieldName).setValue(value.replace(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#]/g, ''));
    }

    if (value.length > 5) {
      this.group.get(fieldName).setValue(value.substring(0, 5));
      return;
    }
    if (value.match(this.timePattern) && nextField) {
      $('#' + nextField).trigger('focus');
      return;
    }
    if (!value.includes(':')) {
      if (value.length === 2) {
        this.group.get(fieldName).setValue(value + ':');
      }
    }
    return true;
  }
}

export class ProfileMassExportErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(control?.parent?.invalid && control?.parent?.dirty);

    return invalidCtrl || invalidParent;
  }
}
