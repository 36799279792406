import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { getHostUrl } from '../../config';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-grouppicker',
  templateUrl: './grouppicker.component.html',
  styleUrls: ['./grouppicker.component.css']
})
export class GrouppickerComponent implements OnInit {

  constructor(private _http: HttpClient) { }

  @Output() group = new EventEmitter<number | number[]>();
  @Input() label: string = 'Monat';
  @Input() required: boolean = false;
  @Input() multiple: boolean = false;

  groups: any;
  currentGroups: number | number[] = [];
  public groupsFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public groupsFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this._http.get<any[]>(`${getHostUrl()}datainfo/groups?type=user`).subscribe(result => {
      this.groups = result;
      this.groupsFilter.next(this.groups.slice());
      this.groupsFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterGroups();
        });
      this.groupsFilterCtrl.updateValueAndValidity();
    });
  }

  selected($event: MatSelectChange): void {
    if (this.multiple) {
      const groups: number[] = ($event.value as number[]);
      this.group.emit(groups);
    } else {
      this.group.emit($event.value + 1);
    }
  }

  protected filterGroups() {
    if (!this.groups) {
      return;
    }
    let search = this.groupsFilterCtrl.value;
    if (!search) {
      this.groupsFilter.next(this.groups.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.groupsFilter.next(
      this.groups.filter(group => group.name.toLowerCase().indexOf(search) > -1 ||
        group.name.toLowerCase().indexOf(search) > -1),
    );
  }
}
