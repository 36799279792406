import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { DaysShort, Guard, GuardAssignmentInfoList, GuardBilling, GuardStatus } from '@ffo/mitgliederbereich-types';
import { DateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { CustomDateAdapterService } from 'src/app/util/customDateAdapter';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertResult } from 'sweetalert2';

const days: DaysShort[] = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
const position: string[] = ['1. WL', '2. WL', '3. WL', '1. WM', '2. WM', '3. WM'];

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService }],
})
export class AdminGuardsAssignmentComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  viewColumns: string[] = ['name', 'date'];
  guardList: Guard[] | GuardBilling[] = [];
  guards: MatTableDataSource<Guard | GuardBilling>;

  assignmentInfoList: GuardAssignmentInfoList[] = [];
  activeGuard: Guard | GuardBilling;

  assignment: Map<string, number> = new Map();

  searchFilter: string = '';
  statusFilter: GuardStatus[] = ['angelegt', 'eingeteilt'];
  loading: boolean = true;
  saving: boolean = true;
  viewColumnsAssignment: string[] = ['name', 'state', 'count', 'last', 'lastGuards', 'assigned'];

  checkDate: Date = new Date();
  today: Date = new Date();
  position = position;
  env = environment;
  constructor(private _http: HttpClient, private _config: ConfigModule) { }

  ngOnInit(): void {
    this._config.setTitle('Sicherheitswachen | Wachen');
    this.loadGuards();
    this.loadCheckDate();
  }

  loadGuards(): void {
    this.loading = true;
    this._http.get<Guard[] | GuardBilling[]>(getHostUrl() + 'admin/fields/guards/guards')
      .subscribe(data => {
        this.guardList = data.filter((guard: Guard | GuardBilling) => {
          return guard.status === 'angelegt';
        }).map((guard: Guard | GuardBilling) => {
          guard.date = new Date(guard.date);
          return guard;
        });
        this.activeGuard = this.guardList[0] || null;
        this.guards = new MatTableDataSource<Guard | GuardBilling>(this.guardList);
        this.guards.sortingDataAccessor = (item: Guard | GuardBilling, property) => {
          switch (property) {
            case 'date':
              const date = DateTime.fromJSDate(item.date).setZone('Europe/Berlin');
              const time = DateTime.fromFormat(item.guardStart, 'HH:mm').setZone('Europe/Berlin');
              return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toJSDate();
            default: return item[property];
          }
        };
        this.guards.paginator = this.paginator;
        this.guards.filterPredicate = this.filter();
        this.applySearch('');
        this.loading = false;
        this.loadAssignment();
      });
  }

  loadAssignment(): void {
    this.accordion.closeAll();
    this.assignmentInfoList = [];
    this.assignment = new Map();
    this.saving = false;
    this._http.get<GuardAssignmentInfoList[]>(getHostUrl() + 'admin/fields/guards/assignment/info')
      .subscribe(data => {
        this.assignmentInfoList = data.map(guard => {
          return {
            ...guard,
            last: guard.last ? new Date(guard.last) : null,
            assignment: guard.assignment.map(assignment => {
              return {
                ...assignment,
                last: assignment.last ? new Date(assignment.last) : null
              }
            })
          }
        }).filter((guard: GuardAssignmentInfoList) => {
          return guard.days.includes(days[this.activeGuard.date.getDay()]);
        });
      });
  }

  loadCheckDate(): void {
    this._http.get<Date>(getHostUrl() + 'admin/fields/guards/assignment/checkDate')
      .subscribe(data => {
        this.checkDate = new Date(data);
      });
  }

  setCheckDate(): void {
    this._http.post(getHostUrl() + 'admin/fields/guards/assignment/checkDate', { checkDate: DateTime.fromJSDate(this.checkDate).toSQLDate() })
      .subscribe({ next: (data) => { this.loadAssignment(); }, error: (err) => { this._config.showSnackbar("Referenzdatum konnte nicht aktualisiert werden", 3000, 'error') } })
  }

  getDateDiff(begin: Date, end: Date) {
    return dayjs(begin).diff(dayjs(end), 'days');
  }

  getGuardBackgroundColor(assignment: Date) {
    return Math.abs(this.getDateDiff(this.activeGuard.date, assignment)) <= 7 ? 'redGuard' : Math.abs(this.getDateDiff(this.activeGuard.date, assignment)) <= 30 ? 'orangeGuard' : '';
  }

  applySearch(searchValue: string) {
    this.searchFilter = searchValue.toLowerCase();
    this.guards.filter = JSON.stringify({ search: this.searchFilter });
  }

  filter(): (data: Guard | GuardBilling, filter: string) => boolean {
    let filterFunction = function (data: Guard | GuardBilling, filter: string): boolean {
      if (this.loading) return false;
      let searchTerms: { search: string } = JSON.parse(filter);
      return data.name.toLowerCase().indexOf(searchTerms.search) !== -1;
    };
    return filterFunction;
  }

  setGuard(guard: Guard | GuardBilling) {
    this.activeGuard = guard;
    this.loadAssignment();
  }

  nextGuard(): void {
    this.activeGuard = this.guardList[this.getNextGuardIndex()];
    this.loadAssignment();
  }

  setAssignment($event: MatSelectChange, userID: number) {
    const position: string = $event.value;
    if (position) {
      this.assignment.set(position, userID);
    } else {
      this.assignment.delete(getByValue(this.assignment, userID));
    }
  }

  validAssignment(): boolean {
    return this.assignment.has('1') && this.assignment.has('4');
  }

  assign() {
    if (this.assignment.size !== 6) {
      Swal.fire({
        title: "unbesetzte Positionen!",
        icon: 'warning',
        showDenyButton: true,
        showConfirmButton: true,
        html: "Es sind noch nicht alle Positionen besetzt.</br> Trotzdem speichern?",
        denyButtonText: "zurück",
        confirmButtonText: "speichern",
        reverseButtons: true
      }).then((result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.assigning();
        }
      });
    } else {
      this.assigning();
    }
  }

  private assigning() {
    this.saving = true;
    const map = [];
    this.assignment.forEach((value: number, key: string) => {
      map.push([this.activeGuard.id, key, value]);
    });
    this._http.post(getHostUrl() + 'admin/fields/guards/assignment/assign', { assignment: map, guard: this.activeGuard.id })
      .subscribe({
        next: (data) => {
          this._config.showSnackbar("Personen wurden eingeteilt", 3000, 'success');
          this.loadGuards();
        }, error: (err) => {
          this._config.showSnackbar("Personen konnten nicht eingeteilt werden", 3000, 'error');
          this.saving = false;
        }
      });
  }

  private getNextGuardIndex(): number {
    const index = this.guardList.findIndex((v, i) => {
      return v.id === this.activeGuard.id
    });
    if (index + 1 === this.guardList.length) return 0;
    return index + 1;
  }
}

function getByValue(map, searchValue) {
  for (let [key, value] of map.entries()) {
    if (value === searchValue)
      return key;
  }
}