import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import * as $ from 'jquery';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    if ($(window).width() < 700)
      return new DatePipe(locale).transform(date, 'EEEEEE', locale);
    else
      return new DatePipe(locale).transform(date, 'EEEE', locale);
  }

  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'MMMM y', locale);
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    if ($(window).width() < 700)
      return new DatePipe(locale).transform(date, 'EEEEE', locale);
    else
      return new DatePipe(locale).transform(date, 'EEEE', locale);
  }

  public weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
    if ($(window).width() < 700)
      return new DatePipe(locale).transform(date, 'dd', locale);
    else
      return new DatePipe(locale).transform(date, 'dd.MM', locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    if ($(window).width() < 700)
      return new DatePipe(locale).transform(date, 'HH', locale);
    else
      return new DatePipe(locale).transform(date, 'HH:mm', locale);
  }

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'HH:mm', locale);
  }

  public dayViewTitle({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'dd.MM.y', locale);
  }

  public weekViewTitle({ date, locale, weekStartsOn, excludeDays, daysInWeek }: DateFormatterParams): string {
    return 'Kalenderwoche ' + new DatePipe(locale).transform(date, 'ww', locale);
  }
}
