<form [formGroup]="group">
  <div class="row mt-2">
    <div class="col-lg-4 col-md-4 col-12">
      <mat-form-field floatLabel="always" class="input-full-width" [class.mat-form-field-invalid]="
          group.get('alarmReference').invalid ||
          group.get('alarmReferencePrefix').invalid
        " appearance="outline">
        <input matInput style="display: none" />
        <mat-label>Einsatznummer *</mat-label>
        <mat-select formControlName="alarmReferencePrefix" required style="width: 20%">
          <mat-option [value]="reference.name" *ngFor="let reference of referencePrefixList">{{ reference.name
            }}</mat-option>
        </mat-select>
        <input type="number" matInput required formControlName="alarmReference" inputmode="numeric" min="0" max="999999999"
          oninput="validity.valid||(value='');" style="width: 75%; margin-left: 5%" />
        <mat-error *ngIf="
            group.get('alarmReference').invalid ||
            group.get('alarmReferencePrefix').invalid
          ">
          Einsatznummer muss ausgefüllt sein!</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Einsatzdatum</mat-label>
        <input matInput formControlName="date" [matDatepicker]="picker" [max]="group.get('enddate').value || todaysDate"
          readonly="true" required (focus)="picker.open()" style="cursor: pointer" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="!group.get('date').valid">
          Einsatzdatum muss angegeben werden
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Einsatzende</mat-label>
        <input matInput formControlName="enddate" [matDatepicker]="pickerEnd" [max]="todaysDate"
          [min]="group.get('date').value" readonly="true" required (focus)="pickerEnd.open()" style="cursor: pointer" />
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
        <mat-error *ngIf="!group.get('enddate').valid">
          Einsatzenddatum ist ungültig
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Alarmzeit</mat-label>
        <input matInput required formControlName="alarmTime" inputmode="numeric"
          (keyup)="parseTimeField($event, 'alarmTime', 'outTime')" />
        <mat-error *ngIf="!group.get('alarmTime').valid">Alarm-Zeit ist ungültig!</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Aus</mat-label>
        <input matInput formControlName="outTime" inputmode="numeric" id="outTime"
          (keyup)="parseTimeField($event, 'outTime', 'onTime', 'alarmTime')" />
        <mat-error *ngIf="group.get('outTime').hasError('smallerTime')">Aus-Zeit darf nicht kleiner als Alarm-Zeit
          sein!</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>An</mat-label>
        <input matInput formControlName="onTime" inputmode="numeric" id="onTime"
          (keyup)="parseTimeField($event, 'onTime', 'offTime', 'outTime')" />
        <mat-error *ngIf="group.get('onTime').hasError('smallerTime')">An-Zeit darf nicht kleiner als Aus-Zeit
          sein!</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Ab</mat-label>
        <input matInput formControlName="offTime" inputmode="numeric" id="offTime"
          (keyup)="parseTimeField($event, 'offTime', 'inTime', 'offTime')" />
        <mat-error *ngIf="group.get('offTime').hasError('smallerTime')">Ab-Zeit darf nicht kleiner als An-Zeit
          sein!</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Einsatzende</mat-label>
        <input matInput required formControlName="inTime" inputmode="numeric" id="inTime"
          (keyup)="parseTimeField($event, 'inTime', 'confTime', 'inTime')" />
        <mat-error *ngIf="!group.get('inTime').value">Einsatzende-Zeit darf nicht leer sein!</mat-error>
        <mat-error *ngIf="group.get('inTime').hasError('smallerTime')">Einsatzende-Zeit darf nicht kleiner als Ab-Zeit
          sein!</mat-error>
        <mat-error *ngIf="group.get('inTime').hasError('smallerTimeOut')">Einsatzende-Zeit darf nicht kleiner als
          Alarm-Zeit sein!</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Uhrzeit Bestätigung</mat-label>
        <input matInput required formControlName="confTime" inputmode="numeric" id="confTime"
          (keyup)="parseTimeField($event, 'confTime')" />
        <mat-error *ngIf="!group.get('confTime').value">Bestätigungs-Zeit darf nicht leer sein!</mat-error>
        <mat-error *ngIf="group.get('confTime').hasError('smallerTime')">Bestätigungs-Zeit darf nicht kleiner als
          Ein-Zeit sein!</mat-error>
      </mat-form-field>
    </div>
    <div
      *ngIf="(_host.statusCategory === 'invoice' || _host.statusCategory === 'report') && _host._login.hasPermission('alarm-zen')"
      class="col-12">
      <mat-slide-toggle formControlName="zen">Einsatz im zEN geprüft</mat-slide-toggle>
    </div>
  </div>
</form>