<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Alarmschleifen</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>

        <mat-form-field class="input-full-width" appearance="outline">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
        <div class="table-responsive">
          <mat-progress-bar mode="query" *ngIf="showLoader"></mat-progress-bar>
          <table mat-table [dataSource]="dataSource" class="input-full-width" cdkDropList
            (cdkDropListDropped)="drop($event)">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef style="min-width: 250px; padding-right: 15px">
                Name
              </th>
              <td mat-cell *matCellDef="let element">
                <span matTooltip="Letzte Alarmierung: {{ formatDayjs(element.lastalarm, 'DD.MM.YYYY HH:mm:ss') }}"
                  [matTooltipDisabled]="element.pager!==1" matTooltipPosition="right"
                  matTooltipClass="pagerLastAlarm">{{element.name}}</span>
              </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="fivetone">
              <th mat-header-cell *matHeaderCellDef>
                5 Tonfolge
              </th>
              <td mat-cell *matCellDef="let element"> {{element.fivetone}} </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <a (click)="editAlarmline(element)" mat-icon-button [disabled]="showLoader" class="float-right">
                  <mat-icon>edit</mat-icon>
                </a>
                <a (click)="deleteAlarmLine(element)" mat-icon-button color="warn" [disabled]="showLoader"
                  class="float-right">
                  <mat-icon>delete</mat-icon>
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>