import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigModule, getHostUrl } from '../../util/config';
import { Login } from '../../util/login';
import { RouteGuard } from '../../util/guards/route.guard';
import { DateTime } from 'luxon';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapterService, MonthDateHeaderComponent } from '../../util/customDateAdapter';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';

interface Shift {
  id: number;
  date: string;
  shift: 'day' | 'night';
  car: '791' | '792'
  comments: string;
  user: User;
  coDriver: User;
}

interface User {
  userID: number;
  firstname: string;
  lastname: string;
  name: string;
  fullname: string;
}

@Component({
  selector: 'app-responder',
  templateUrl: './responder.component.html',
  styleUrls: ['./responder.component.css'],
  providers: [RouteGuard],
})
export class ResponderComponent implements OnInit, OnDestroy, AfterViewInit {

  scheduleArray: Shift[];

  userID: number;

  usersFRS: User[];

  users: User[];

  SHIFTCOUNT: number = 21;
  STARTDATE: dayjs.Dayjs = dayjs();

  checkSchedule: any;

  drawerPinned: any;

  shiftId: number;

  @ViewChildren('ResponderList') ResponderList: QueryList<Shift[]>;

  protected _onDestroy = new Subject<void>();

  constructor(private _configService: ConfigModule,
    private _http: HttpClient,
    private _login: Login,
    private _route: ActivatedRoute,
    private _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this._configService.setTitle('FRS Dienstplan');
    this.userID = environment.userInfo.id;
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.drawerPinned = localStorage.getItem('drawerPinned');
    $('.mat-sidenav-content').on('scroll', this.scrollHandler);
    this.shiftId = this._route.snapshot.params.shiftId;
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    $('.mat-sidenav-content').off('scroll', this.scrollHandler);
    this._bottomSheet.dismiss();
    clearInterval(this.checkSchedule);
  }

  loadData(): void {
    Promise.all([
      this._http.get<User[]>(`${getHostUrl()}responder/list`).toPromise(),
      this._http.get<User[]>(`${getHostUrl()}responder/list?queryAll=1`).toPromise(),
      this.loadSchedule(),
    ]).then(results => {
      this.usersFRS = results[0] || [];
      this.users = results[1] || [];
      this.scheduleArray = results[2] || [];
      this.checkSchedule = setInterval(() => {
        this.loadSchedule().then((schedule: Shift[]) => {
          this.scheduleArray = schedule;
        });
      }, 2000);
      if (this.shiftId)
        this.scroll(this.shiftId);
    });
  }

  loadSchedule(): Promise<Shift[]> {
    return this._http.get<Shift[]>(`${getHostUrl()}responder/schedule?startDate=${this.STARTDATE.format('YYYY.MM.DD')}`).toPromise();
  }

  scroll(el: number, waiting?: boolean) {
    if (waiting) {
      setTimeout(function () {
        const element = $('#' + el).parent();
        element.addClass('highlighted');
        const y = element[0].getBoundingClientRect().top + window.pageYOffset - 150;

        $('.mat-sidenav-content').stop().animate({ scrollTop: y }, 400);
        setTimeout(function () {
          element.removeClass('highlighted');
        }, 1200);
      }, 1500);
      return;
    }
    setTimeout(function () {
      const element = $('#' + el).parent();
      element.addClass('highlighted');

      if (element[0]) {
        const y = element[0].getBoundingClientRect().top + window.pageYOffset - 150;
        $('.mat-sidenav-content').stop().animate({ scrollTop: y }, 400);
      }

      setTimeout(function () {
        element.removeClass('highlighted');
      }, 1200);
    }, 100);
  }

  scrollHandler = (event): void => {
    this.drawerPinned = localStorage.getItem('drawerPinned');
    var widthDate = $('#dateHeader').width();
    var width791 = $('#791Header').width();
    var width792 = $('#792Header').width();
    var offsetTop = $('mat-toolbar')[0].offsetHeight;
    if (event.target.scrollTop > (offsetTop + $('h3').height())) {
      $('#dateHeader').width(widthDate);
      $('#791Header').width(width791);
      $('#792Header').width(width792);
      $('.dateBody').width(widthDate);
      $('.791Body').width(width791);
      $('.792Body').width(width792);
      $('#tableHeader').addClass('sticky');
      $('#tableHeader').offset({ top: offsetTop });
      $('#spacer').height($('button').height() + 5);
    } else {
      $('#tableHeader').removeClass('sticky');
      $('#tableHeader').removeAttr('style');
      $('#spacer').height('0px');
    }
  };

  //Shift Control
  hasShift(_date: string, _shift: 'day' | 'night', _car: '791' | '792'): boolean {
    var result: boolean = false;
    if (!this.scheduleArray) return result;
    this.scheduleArray.forEach((shift: Shift) => {
      if (shift.shift === _shift && _date === shift.date && _car === shift.car) {
        result = true;
        return;
      }
    });
    return result;
  }

  getShift(_date: string, _shift: 'day' | 'night', _car: '791' | '792'): Shift {
    var result: Shift;
    this.scheduleArray.forEach((shift: Shift) => {
      if (shift.shift === _shift && _date === shift.date && _car === shift.car) {
        result = shift;
        return;
      }
    });
    return result;
  }

  hasShiftOnCar(_date: string, _shift: 'day' | 'night', _car: '791' | '792'): boolean {
    const isShift: boolean = this.hasShift(_date, _shift, _car);
    if (!isShift)
      return false;
    const shift: Shift = this.getShift(_date, _shift, _car);
    if (shift.user.userID === this.userID)
      return true;
    return false;
  }

  openShift(_shift: Shift): void {
    const popup: MatBottomSheetRef<BottomSheetResponderShiftPopupComponent> = this._bottomSheet.open(BottomSheetResponderShiftPopupComponent, { data: { _shift: { ..._shift }, _users: this.users, _usersFRS: this.usersFRS, _scheduleArray: this.scheduleArray, _scroll: this.scroll }, disableClose: true });
    popup.keydownEvents().pipe(filter((e: KeyboardEvent) => e.code === 'Escape')).subscribe(() => popup.dismiss());
    clearInterval(this.checkSchedule);
    popup.afterDismissed().pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.loadSchedule().then((_scheduleArray: Shift[]) => {
        this.scheduleArray = _scheduleArray;
      });
      this.checkSchedule = setInterval(() => {
        this.loadSchedule().then((schedule: Shift[]) => {
          this.scheduleArray = schedule;
        });
      }, 2000);
    });
  }

  addShift(_date: string, _shift: 'day' | 'night', _car: '791' | '792'): void {
    const shift: Shift = {
      id: null,
      date: _date,
      shift: _shift,
      car: _car,
      comments: null,
      user: null,
      coDriver: null,
    };
    this.openShift(shift);
  }

  // Helper Functions
  getArray(_i: number): any[] {
    return Array(_i);
  }

  calculateDate(_i: number): string {
    return DateTime.fromJSDate(this.STARTDATE.toDate()).plus({ 'days': _i }).toFormat('yyyy-MM-dd');
  }

  getDay(_d: string): string {
    return DateTime.fromSQL(_d).toFormat('dd.MM.');
  }

  getDayText(_d: string): string {
    return DateTime.fromSQL(_d).setLocale('DE-de').toFormat('ccc');
  }

  showMore() {
    if (this.SHIFTCOUNT >= 62) {
      return;
    }
    this.SHIFTCOUNT += 7;
    this.loadData();
  }

  showPrevious() {
    this.STARTDATE = this.STARTDATE.add(-7, 'days');
    this.loadData();
  }

  isFRS(): boolean {
    return this._login.hasPermission('responder');
  }

  isFRSAdmin(): boolean {
    return this._login.hasPermission('admin-responder');
  }
}

const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM',
  },
  display: {
    dateInput: 'dayMonthLabelLong',
    monthYearLabel: 'monthLabelLong',
    dateA11yLabel: 'dayLabel',
    monthYearA11yLabel: 'monthYearLabelLong',
  },
};

function validUser(control: AbstractControl) {
  const user = control.value as User;
  if (!user)
    return null;
  if (typeof (user) === 'string')
    return {
      user: {
        valid: false,
      },
    };
  return null;
}

interface ScheduleData {
  scheduleList: Shift[];
  targetID: number;
  comments: string;
}

@Component({
  selector: 'app-responder-shift-popup',
  templateUrl: 'shiftpopup.html',
  styleUrls: ['./responder.component.css'],
  providers: [{
    provide: DateAdapter,
    useClass: CustomDateAdapterService,
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetResponderShiftPopupComponent implements OnInit, AfterViewInit, OnDestroy {

  filteredUsers: User[];

  usersFRS: User[];

  users: User[];

  user: User;

  userID: number;
  frs: boolean = false;

  shift: Shift;

  scheduleArray: Shift[];

  _overwrite: 0 | 1 | 2 = 0;

  mobile: boolean;

  today: Date;

  startDate: Date;

  maxDate: Date;

  shiftForm = new UntypedFormGroup({
    frs: new UntypedFormControl('', [Validators.required, validUser]),
    coDriver: new UntypedFormControl('', [validUser]),
    startdate: new UntypedFormControl('', Validators.required),
    enddate: new UntypedFormControl('', Validators.required),
    startshift: new UntypedFormControl('', Validators.required),
    endshift: new UntypedFormControl('', Validators.required),
    comments: new UntypedFormControl(''),
  });

  _MonthDateHeader = MonthDateHeaderComponent;

  env = environment;

  protected _onDestroy = new Subject<void>();

  checkSchedule: any;

  scroll: Function;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) private SHEET_DATA: any,
    private _http: HttpClient,
    private _login: Login,
    private _config: ConfigModule,
    public _bottomSheet: MatBottomSheet) {
    this.shift = SHEET_DATA._shift;
    this.users = SHEET_DATA._users;
    this.usersFRS = SHEET_DATA._usersFRS;
    this.scheduleArray = SHEET_DATA._scheduleArray;
    this.scroll = SHEET_DATA._scroll;
  }

  ngOnInit(): void {
    this.userID = environment.userID;
    this.user = this.getUserFromID(this.userID);
    this.mobile = environment.mobile;
    this.today = new Date();
    this.startDate = new Date(this.shift.date);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 62);
  }

  ngAfterViewInit(): void {
    this.frs = this.usersFRS.findIndex(u => u.userID === this.userID) !== -1;
    if (this.shift.user !== null) {
      this.shiftForm.get('frs').setValue(this.shift.user);
      if (this.shift.coDriver !== null)
        this.shiftForm.get('coDriver').setValue(this.shift.coDriver);
    } else if (this.frs === true) {
      this.shift.user = this.user;
      this.applyUser(this.user);
    } else {
      this._config.showSnackbar('Du hast keine Blaufahrberechtigung für den Responder.', 3000, 'warning');
    }
    this.shiftForm.get('startdate').setValue(new Date(this.shift.date));
    this.shiftForm.get('enddate').setValue(new Date(this.shift.date));
    this.shiftForm.get('startshift').setValue(this.shift.shift);
    this.shiftForm.get('endshift').setValue(this.shift.shift);
    this.shiftForm.get('comments').setValue(this.shift.comments);

    this.shiftForm.get('startshift').valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((startShift: 'day' | 'night') => {
      const startDate: Date = this.shiftForm.get('startdate').value;
      const endDate: Date = this.shiftForm.get('enddate').value;
      if (startShift === 'night' && startDate.toString() === endDate.toString())
        this.shiftForm.get('endshift').setValue(startShift);
      this._overwrite = 0;
    });
    this.shiftForm.get('endshift').valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((endshift: 'day' | 'night') => {
      const startDate: Date = this.shiftForm.get('startdate').value;
      const endDate: Date = this.shiftForm.get('enddate').value;
      if (endshift === 'day' && startDate.toString() === endDate.toString())
        this.shiftForm.get('startshift').setValue(endshift);
      this._overwrite = 0;
    });
    this.shiftForm.get('startdate').valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this._overwrite = 0;
    });
    this.shiftForm.get('enddate').valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this._overwrite = 0;
    });
    this.shiftForm.get('frs').valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this._overwrite = 0;
    });

    this.shiftForm.disable();
    if (this.isAdmin() || this.shift.user && this.shift.user.userID === this.userID)
      this.shiftForm.enable();
    if (this.shift.coDriver && this.shift.coDriver.userID === this.userID)
      this.shiftForm.get('coDriver').enable();

    this.checkSchedule = setInterval(() => {
      this.loadSchedule().then((schedule: Shift[]) => {
        this.scheduleArray = schedule;
      });
    }, 2000);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._bottomSheet.dismiss();
  }

  update(): void {
    clearInterval(this.checkSchedule);
    if (this._overwrite === 1 && this.isAdmin())
      this._overwrite = 2;
    this.loadSchedule().then((schedule: Shift[]) => {
      this.scheduleArray = schedule;
      const scheduleData: ScheduleData = this.validateSchedule(this.generateSchedule());
      if (this._overwrite === 1 && this.isAdmin()) {
        this._config.showSnackbar('Um die Schicht zu überschreiben, speichere erneut!', 2000, 'warning');
        return;
      }
      if (scheduleData.scheduleList.length === 0) {
        this._config.showSnackbar('Die Schicht kann nicht aktualisiert werden!', 2000, 'error');
        return;
      }
      this._http.post(`${getHostUrl()}responder/update`, {

        scheduleList: scheduleData.scheduleList,
        targetID: scheduleData.targetID,
        comments: scheduleData.comments,
      }).subscribe((data) => {
        this._bottomSheet.dismiss();
        this.scroll(data, true);
        this._config.showSnackbar('Schicht wurde aktualisiert!', 2000, 'success');
      }, (error) => {
        if (error.error === 5001)
          this._config.showSnackbar('Der Fahrer ist bereits auf der anderen Schicht eingetragen!', 2000, 'error');
        else if (error.error === 5002)
          this._config.showSnackbar('Der Beifahrer ist bereits auf der anderen Schicht eingetragen!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      });
    });
  }

  codriver(): void {
    clearInterval(this.checkSchedule);
    this.loadSchedule().then((schedule: Shift[]) => {
      this.scheduleArray = schedule;
      const scheduleData: ScheduleData = this.validateSchedule(this.generateSchedule());
      if (scheduleData.scheduleList.length === 0) {
        this._config.showSnackbar('Die Schicht kann nicht aktualisiert werden!', 2000, 'error');
        return;
      }
      this._http.post(`${getHostUrl()}responder/codriver`, {

        scheduleList: scheduleData.scheduleList,
      }).subscribe((data) => {
        this._bottomSheet.dismiss();
        this.scroll(data, true);
        this._config.showSnackbar('Schicht wurde aktualisiert!', 2000, 'success');
      }, (error) => {
        if (error.error === 5002)
          this._config.showSnackbar('Der Beifahrer ist bereits auf der anderen Schicht eingetragen!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      });
    });
  }

  remove(): void {
    clearInterval(this.checkSchedule);
    if (this._overwrite === 1 && this.isAdmin())
      this._overwrite = 2;
    this.loadSchedule().then((schedule: Shift[]) => {
      this.scheduleArray = schedule;
      const scheduleData: ScheduleData = this.validateSchedule(this.generateSchedule());
      if (scheduleData.scheduleList.length === 0) {
        this._config.showSnackbar('Die Schicht kann nicht gelöscht werden!', 2000, 'error');
        return;
      }
      if (this._overwrite === 1 && this.isAdmin()) {
        this._config.showSnackbar('Um die Schicht zu überschreiben, speichere erneut!', 2000, 'warning');
        return;
      }
      this._http.post(`${getHostUrl()}responder/remove`, {

        scheduleList: scheduleData.scheduleList,
      }).subscribe((data) => {
        this._bottomSheet.dismiss();
        this._config.showSnackbar('Schicht wurde gelöscht!', 2000, 'success');
      }, (error) => {
        this._bottomSheet.dismiss();
        if (error.error === 5001)
          return this._config.showSnackbar('Um dich aus der Schicht auszutragen, wende dich bitte an einen FRS Verantwortlichen!', 2000, 'warning');
        if (error.error === 5002)
          return this._config.showSnackbar('Fehler beim Löschen der Schicht!', 2000, 'error');
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      });
    });
  }

  add(): void {
    clearInterval(this.checkSchedule);
    if (this._overwrite === 1 && this.isAdmin())
      this._overwrite = 2;
    this.loadSchedule().then((schedule: Shift[]) => {
      this.scheduleArray = schedule;
      const scheduleData: ScheduleData = this.validateSchedule(this.generateSchedule());
      if (scheduleData.scheduleList.length === 0) {
        this._config.showSnackbar('Die Schicht kann nicht hinzugefügt werden!', 2000, 'error');
        return;
      }
      if (this._overwrite === 1 && this.isAdmin()) {
        this._config.showSnackbar('Um die Schicht zu überschreiben, speichere erneut!', 2000, 'warning');
        return;
      }
      this._http.post(`${getHostUrl()}responder/add`, {

        scheduleList: scheduleData.scheduleList,
        targetID: scheduleData.targetID,
        comments: scheduleData.comments,
      }).subscribe((data) => {
        this._bottomSheet.dismiss();
        this.scroll(data, true);
        this._config.showSnackbar('Schicht wurde hinzugefügt!', 2000, 'success');
      }, (error) => {
        if (error.error === 5001)
          this._config.showSnackbar('Der Fahrer ist bereits auf der anderen Schicht eingetragen!', 2000, 'error');
        else if (error.error === 5002)
          this._config.showSnackbar('Der Beifahrer ist bereits auf der anderen Schicht eingetragen!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      });
    });
  }

  generateSchedule(): ScheduleData {
    const frs: User = this.shiftForm.get('frs').value;
    const coDriver: User = this.shiftForm.get('coDriver').value;
    let startDate: Date = new Date(this.shiftForm.get('startdate').value);
    const endDate: Date = this.shiftForm.get('enddate').value;
    const startShift: 'day' | 'night' = this.shiftForm.get('startshift').value;
    const endShift: 'day' | 'night' = this.shiftForm.get('endshift').value;
    const comments: string = this.shiftForm.get('comments').value;
    const schedule: Shift[] = [];

    if (this.generateDateString(new Date(startDate)) === this.generateDateString(new Date(endDate)) && startShift === endShift && startShift === this.shift.shift) {
      const date = this.generateDateString(new Date(startDate));
      const shiftObj: Shift = this.getShift(date, startShift, this.shift.car);
      if (shiftObj && shiftObj.user.userID === this.user.userID || shiftObj && this.isAdmin() || shiftObj === null || shiftObj && (this.shiftForm.get('coDriver').value.userID === this.user.userID || shiftObj.coDriver && shiftObj.coDriver.userID === this.userID)) {
        let shift: Shift = { ...shiftObj };
        shift.comments = comments;
        shift.date = date;
        shift.shift = startShift;
        shift.car = this.shift.car;
        shift.user = this.shift.user;
        shift.coDriver = coDriver;
        if (this.shift && this._overwrite === 0 && this.shift.user.userID !== this.user.userID)
          this._overwrite = 1;
        return {
          scheduleList: [shift],
          targetID: frs.userID,
          comments: comments,
        };
      }
    } else if (this.generateDateString(new Date(startDate)) === this.generateDateString(new Date(endDate)) && startShift === endShift && startShift !== this.shift.shift) {
      const date = this.generateDateString(new Date(startDate));
      const shiftObj: Shift = this.getShift(date, startShift, this.shift.car);
      if (shiftObj && shiftObj.user.userID === this.user.userID || shiftObj && this.isAdmin() || shiftObj === null) {
        let shift: Shift = { ...shiftObj };
        shift.comments = comments;
        shift.user = frs;
        shift.date = date;
        shift.shift = startShift;
        shift.car = this.shift.car;
        shift.coDriver = coDriver;
        if (shiftObj)
          shift.user = shiftObj.user;
        if (shiftObj && this._overwrite === 0 && shiftObj.user.userID !== this.user.userID)
          this._overwrite = 1;
        return {
          scheduleList: [shift],
          targetID: frs.userID,
          comments: comments,
        };
      }
    } else if (this.generateDateString(new Date(startDate)) === this.generateDateString(new Date(endDate)) && startShift !== endShift) {
      const date = this.generateDateString(new Date(startDate));
      const day: Shift = this.getShift(date, 'day', this.shift.car);
      const night: Shift = this.getShift(date, 'night', this.shift.car);
      if (day && day.user.userID === this.user.userID || day && this.isAdmin() || day === null) {
        let shift: Shift = { ...day };
        shift.comments = comments;
        shift.user = frs;
        shift.date = date;
        shift.car = this.shift.car;
        shift.shift = 'day';
        shift.coDriver = coDriver;
        if (day)
          shift.user = day.user;
        schedule.push(shift);
        if (day && this._overwrite === 0 && day.user.userID !== this.user.userID)
          this._overwrite = 1;
      }
      if (night && night.user.userID === this.user.userID || night && this.isAdmin() || night === null) {
        let shift: Shift = { ...night };
        shift.comments = comments;
        shift.user = frs;
        shift.date = date;
        shift.car = this.shift.car;
        shift.shift = 'night';
        shift.coDriver = coDriver;
        if (night)
          shift.user = night.user;
        schedule.push(shift);
        if (night && this._overwrite === 0 && night.user.userID !== this.user.userID)
          this._overwrite = 1;
      }
      return {
        scheduleList: schedule,
        targetID: frs.userID,
        comments: comments,
      };
    }
    for (let i = 0; startDate <= endDate; i++) {
      const date = this.generateDateString(new Date(startDate));
      const enddate = this.generateDateString(new Date(endDate));
      if (i === 0 && date !== enddate && startShift === 'day' || i !== 0 && date !== enddate || i !== 0 && date === enddate && endShift === 'night') {
        const day: Shift = this.getShift(date, 'day', this.shift.car);
        const night: Shift = this.getShift(date, 'night', this.shift.car);
        if (day && day.user.userID === this.user.userID || day && this.isAdmin() || day === null) {
          let shift: Shift = { ...day };
          shift.comments = comments;
          shift.user = frs;
          shift.date = date;
          shift.car = this.shift.car;
          shift.shift = 'day';
          shift.coDriver = coDriver;
          if (day)
            shift.user = day.user;
          schedule.push(shift);
          if (day && this._overwrite === 0 && day.user.userID !== this.user.userID)
            this._overwrite = 1;
        }
        if (night && night.user.userID === this.user.userID || night && this.isAdmin() || night === null) {
          let shift: Shift = { ...night };
          shift.comments = comments;
          shift.user = frs;
          shift.date = date;
          shift.car = this.shift.car;
          shift.shift = 'night';
          shift.coDriver = coDriver;
          if (night)
            shift.user = night.user;
          schedule.push(shift);
          if (night && this._overwrite === 0 && night.user.userID !== this.user.userID)
            this._overwrite = 1;
        }
      } else if (i === 0 && startShift === 'night') {
        const shiftObj: Shift = this.getShift(date, 'night', this.shift.car);
        if (shiftObj && shiftObj.user.userID === this.user.userID || shiftObj && this.isAdmin() || shiftObj === null) {
          let shift: Shift = { ...shiftObj };
          shift.comments = comments;
          shift.user = frs;
          shift.date = date;
          shift.car = this.shift.car;
          shift.shift = 'night';
          shift.coDriver = coDriver;
          if (shiftObj)
            shift.user = shiftObj.user;
          schedule.push(shift);
          if (shiftObj && this._overwrite === 0 && shiftObj.user.userID !== this.user.userID)
            this._overwrite = 1;
        }
      } else if (i !== 0 && date === enddate && endShift === 'day') {
        const shiftObj: Shift = this.getShift(date, 'day', this.shift.car);

        if (shiftObj && shiftObj.user.userID === this.user.userID || shiftObj && this.isAdmin() || shiftObj === null) {
          let shift: Shift = { ...shiftObj };
          shift.comments = comments;
          shift.user = frs;
          shift.date = date;
          shift.car = this.shift.car;
          shift.shift = 'day';
          shift.coDriver = coDriver;
          if (shiftObj)
            shift.user = shiftObj.user;
          schedule.push(shift);
          if (shiftObj && this._overwrite === 0 && shiftObj.user.userID !== this.user.userID)
            this._overwrite = 1;
        }
      }
      startDate = new Date(startDate.setDate(startDate.getDate() + 1));
      startDate.setHours(0, 0);
    }
    return {
      scheduleList: schedule,
      targetID: frs.userID,
      comments: comments,
    };
  }

  validateSchedule(scheduleData: ScheduleData): ScheduleData {
    var scheduleList: Shift[] = [];
    scheduleData.scheduleList.forEach(shift => {
      if (shift.user && shift.user.name !== '')
        scheduleList.push(shift);
    });
    scheduleData.scheduleList = scheduleList;
    return scheduleData;
  }

  //Shift PopUp
  applyUser(_user: User): void {
    if (this.shiftForm.get('coDriver').value.userID === _user.userID) {
      this._config.showSnackbar('Fahrer und Beifahrer können nicht identisch sein!', 2000, 'warning');
      this.clearUser();
      return;
    }
    this.shiftForm.get('frs').setValue(_user);
  }

  clearUser(): void {
    this.shiftForm.get('frs').setValue('');
  }

  applyCoDriver(_user: User): void {
    if (this.shiftForm.get('frs').value.userID === _user.userID) {
      this._config.showSnackbar('Fahrer und Beifahrer können nicht identisch sein!', 2000, 'warning');
      this.clearCoDriver();
      return;
    }
    this.shiftForm.get('coDriver').setValue(_user);
  }

  clearCoDriver(): void {
    this.shiftForm.get('coDriver').setValue('');
  }

  deleteShift(): boolean {
    if (this.shift.id !== null && this.shiftForm.get('frs').value === '' && this.shiftForm.get('startdate').valid && this.shiftForm.get('enddate').valid && this.shiftForm.get('startshift').valid && this.shiftForm.get('endshift').valid)
      return true;
    return false;
  }

  //Helper Functions
  getUserFromID(_userID: number): User {
    let result: User;
    this.users.forEach((user: User) => {
      if (user.userID === _userID) {
        result = user;
        return;
      }
    });
    return result;
  }

  isAdmin(): boolean {
    return this._login.hasPermission('admin-responder');
  }

  loadSchedule(): Promise<Shift[]> {
    return this._http.get<Shift[]>(`${getHostUrl()}responder/schedule`).toPromise();
  }

  controlsEditAble(): boolean {
    return (this.shift && this.shift.user && (this.user && this.shift.user.userID === this.user.userID || this.isAdmin()));
  }

  controlsCoDriverEditAble(): boolean {
    if (this.shift && this.shift.coDriver === null && this.controlsEditAble() === false)
      return true;
    else if (this.shift && this.shift.coDriver && this.shift.coDriver.userID === this.userID)
      return true;
    return false;
  }

  formEditAble(): boolean {
    return (this.shift && (this.shift.user && this.user && this.shiftForm.get('frs').value.userID === this.user.userID || this.isAdmin()));
  }

  coDriverEditable(): boolean {
    return (this.shift && (this.shift.user && this.user && this.shiftForm.get('frs').value.userID === this.user.userID || this.isAdmin() || this.shiftForm.get('coDriver').value.userID === this.user.userID || !this.shift.coDriver));
  }

  filterUsers(value: string, frs: boolean): void {
    let data = this.users;
    let regex = new RegExp('[1-9]{1,4}');
    if (frs)
      data = this.usersFRS;

    if (regex.test(value))
      this.filteredUsers = data.filter((user: User) => user.userID.toString().startsWith(value));
    else
      this.filteredUsers = data.filter((user: User) => user.firstname.toLowerCase().startsWith(value.toLowerCase()) || user.lastname.toLowerCase().startsWith(value.toLowerCase()));
  }

  getShift(_date: string, _shift: 'day' | 'night', _car: '791' | '792'): Shift | null {
    var result: Shift = null;
    this.scheduleArray.forEach((shift: Shift) => {
      if (shift.shift === _shift && _date === this.generateDateString(new Date(shift.date)) && shift.car === _car) {
        result = shift;
        return;
      }
    });
    return result;
  }

  displayUser(_user: User): string {
    if (_user)
      return _user.fullname;
    else
      return '';
  }

  generateDateString(_date: Date): string {
    const day = _date.getDate();
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const date = `${year}-${month}-${day}`;
    return date;
  }


  getCarName(): string {
    return (this.shift.car === '791' ? '79/1' : '79/2');
  }
}