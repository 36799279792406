<div class="clearfix">
  <button class="float-end me-2 mt-2" mat-icon-button (click)="closeDialog(false)">
    <mat-icon>close</mat-icon>
  </button>
  <h2 class="float-start" mat-dialog-title>{{ _data.title || 'Hier unterschreiben' }}</h2>
</div>
<mat-dialog-content class="mat-typography">
  <signature-pad #signature [options]="signaturePadOptions" (drawEnd)="drawComplete()"></signature-pad>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" (click)="clearSignature()">Löschen</button>
  <button mat-raised-button (click)="closeDialog(true)" color="success" [disabled]="!hasSignature">Abschließen</button>
</mat-dialog-actions>