import { HttpClient } from '@angular/common/http';
import { Component, Host, OnInit } from '@angular/core';
import { getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import Swal from 'sweetalert2';
import { AlarmsComponent } from '../alarms-edit.component';

@Component({
  selector: 'app-alarms-final-overview',
  templateUrl: './alarms-final-overview.component.html',
  styleUrls: ['./alarms-final-overview.component.css'],
})
export class AlarmsFinalOverviewComponent implements OnInit {

  constructor(private _http: HttpClient,
    private _login: Login,
    @Host() public _host: AlarmsComponent) { }

  data: any = {};

  showLoader = true;

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.showLoader = true;
    this._http.get<any>(`${getHostUrl()}alarms/fez/overview?alarmId=${this._host.alarmID}`).subscribe(data => {
      this.data = data;
      this.showLoader = false;
      this.patchInvalidstatus();
    });
  }

  setStatusTwo(elem) {
    Swal.fire({
      title: 'Status 2?',
      html: `Soll das Fahrzeug <b>${elem.name}</b> auf Status 2 gesetzt werden?<br />
      <div class="alert alert-info">Das Fahrzeug wird nur innerhalb des Mitgliederbereichs auf Status 2 gesetzt nicht innerhalb des Funksystems!</div>`,
      reverseButtons: true,
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: 'Ja',
      denyButtonText: 'Nein',
    }).then(swalResult => {
      if (swalResult.isConfirmed) {
        this._http.get(`${getHostUrl()}alarms/status/two/${elem.id}`).subscribe(result => {
          this.loadData();
          this._host.vehicles.loadVehicles();
          this._host.vehicles.loadVehicleData();
        });
      }
    });
  }

  patchInvalidstatus() {
    const list = [];
    this.data.invalidStatus.forEach(elem => {
      list.push({
        id: elem.id,
        name: elem.name,
      });
    });
    this.data.invalidStatus = list;
  }

}
