import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Group, StatisticSettings } from '@ffo/mitgliederbereich-types';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-admin-settings-statistics-modal',
  templateUrl: './admin-settings-statistics-modal.component.html',
  styleUrls: ['./admin-settings-statistics-modal.component.css'],
})
export class AdminSettingsStatisticsModalComponent implements OnInit, OnDestroy {

  groupList: Group[] = [];

  groupFilterCtrl: UntypedFormControl = new UntypedFormControl();
  groupFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  constructor(private _http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public _data: StatisticSettings,
    private _config: ConfigModule) { }

  ngOnInit(): void {
    this.loadGroups();
    this.groupFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTemplate();
      });
  }

  ngOnDestroy() {
    this.save();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  async loadGroups() {
    this.groupList = await this._http.get<Group[]>(`${getHostUrl()}datainfo/groups?type=user`).toPromise();
    this.groupFilterCtrl.updateValueAndValidity();
  }

  protected filterTemplate() {
    if (!this.groupList) {
      return;
    }

    // get the search templates
    let search = this.groupFilterCtrl.value;
    if (!search) {
      this.groupFilter.next(this.groupList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the templates
    this.groupFilter.next(
      this.groupList.filter(template => template.name.toLowerCase().indexOf(search) > -1));
  }

  save() {
    this._http.post<any>(`${getHostUrl()}admin/settings/general/statistics/${this._data.id}`, this._data.groups).subscribe(result => {
      this._config.showSnackbar('Auswertung gespeichert', 2500, 'success');
    }, error => {
      this._config.showSnackbar('Auswertung wurde nicht gespeichert', 2500, 'error');
    });
  }
}
