<mat-dialog-header>
    <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
        <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Führerscheindaten</h2>
    </div>
  </mat-dialog-header>
<mat-dialog-content>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
            <img [src]="licencePicture" style="width: 100%"
                *ngIf="!licencePicture?.endsWith('undefined') && !licencePicture?.endsWith('=')">
            <span *ngIf="!licencePicture || licencePicture?.endsWith('undefined') || licencePicture?.endsWith('=')">Kein Bild
                hochgeladen</span>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
            <div class="row">
                <div class="col-12">
                    <mat-form-field id="dateOfIssue" *ngIf="!env.mobile" class="input-full-width" appearance="outline">
                        <mat-label>Ausstellungsdatum (4a)</mat-label>
                        <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
                            [value]="parent.userInfo.dateOfIssue" (dateChange)="ondateOfIssueChange($event)"
                            [max]="parent.today" [min]="parent.age18Date" (change)="onissueDateChange($event)"
                            (focus)="dateOfIssue.open()" required>
                        <mat-datepicker-toggle matSuffix [for]="dateOfIssue"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfIssue [startAt]="startDate" startView="multi-year"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field id="dateOfIssue" *ngIf="env.mobile" class="input-full-width" appearance="outline">
                        <mat-label>Ausstellungsdatum (4a)</mat-label>
                        <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
                            [value]="parent.userInfo.dateOfIssue" (dateChange)="ondateOfIssueChange($event)"
                            [max]="parent.today" [min]="parent.age18Date" (change)="onissueDateChange($event)"
                            (focus)="dateOfIssue.open()" required>
                        <mat-datepicker-toggle matSuffix [for]="dateOfIssue"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfIssue touchUi [startAt]="startDate" startView="multi-year">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field id="dateOfExpiry" *ngIf="!env.mobile" class="input-full-width" appearance="outline">
                        <mat-label>Ablaufdatum (4b)</mat-label>
                        <input matInput [matDatepicker]="dateOfExpiry" [min]="parent.userInfo.dateOfIssue"
                            [max]="parent.expiryLicence" placeholder="Ablaufdatum Führerschein"
                            [value]="parent.userInfo.dateOfExpiry" (dateChange)="ondateOfExpiryChange($event)"
                            (change)="onexpiryDateChange($event)" (focus)="dateOfExpiry.open()" required>
                        <mat-datepicker-toggle matSuffix [for]="dateOfExpiry"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfExpiry [startAt]="startDate" startView="multi-year"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field id="dateOfExpiry" *ngIf="env.mobile" class="input-full-width" appearance="outline">
                        <mat-label>Ablaufdatum (4b)</mat-label>
                        <input matInput [matDatepicker]="dateOfExpiry" [min]="parent.userInfo.dateOfIssue"
                            [max]="parent.expiryLicence" placeholder="Ablaufdatum Führerschein"
                            [value]="parent.userInfo.dateOfExpiry" (dateChange)="ondateOfExpiryChange($event)"
                            (change)="onexpiryDateChange($event)" (focus)="dateOfExpiry.open()" required>
                        <mat-datepicker-toggle matSuffix [for]="dateOfExpiry"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfExpiry touchUi [startAt]="startDate" startView="multi-year">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="input-full-width" appearance="outline">
                        <mat-label>Ausstellungsbehörde (4c)</mat-label>
                        <input matInput [(ngModel)]="parent.userInfo.issuingAuthority" [matAutocomplete]="auto"
                            required>
                    </mat-form-field>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option value="Landratsamt München">Landratsamt München</mat-option>
                        <mat-option value="Landeshauptstadt München">Landeshauptstadt München</mat-option>
                    </mat-autocomplete>
                </div>
                <div class="col-12">
                    <mat-form-field class="input-full-width" appearance="outline">
                        <mat-label>Führerscheinnummer (5)</mat-label>
                        <input matInput [(ngModel)]="parent.userInfo.dlNumber" required>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end;">
        <button mat-raised-button mat-dialog-close cdkFocusInitial color="success"
            (click)="saveDriving()"
            [disabled]="parent.userInfo.dateOfIssue===null || parent.userInfo.issuingAuthority === '' || parent.userInfo.dlNumber === ''">Speichern
            <mat-icon>save</mat-icon>
        </button>
</mat-dialog-actions>