import { ViewChild, TemplateRef, Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { HttpClient } from '@angular/common/http';

import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { getHostUrl, ConfigModule } from '../../../util/config';
import { ActivatedRoute, Router } from '@angular/router';

import { RouteGuard } from '../../../util/guards/route.guard';
import { environment } from 'src/environments/environment';
import { SHA1 } from 'crypto-js';
import { filter } from 'rxjs';

@Component({
  selector: 'app-pwforgot',
  templateUrl: './pwforgot.component.html',
  styleUrls: ['./pwforgot.component.css'],
  providers: [RouteGuard],
})
export class PwforgotComponent implements OnInit, OnDestroy, AfterViewInit {
  emailFormControl: UntypedFormControl;

  loginForm: UntypedFormGroup;

  showSpinner: Boolean;

  @ViewChild('newPassword', { static: false }) newPassword: TemplateRef<any>;

  // Variables passed when user clicks email
  email: string;

  code: string;

  constructor(private _formBuilder: UntypedFormBuilder,
    private _bottomSheet: MatBottomSheet,
    private _http: HttpClient,
    private _config: ConfigModule,
    private _router: Router,
    private _route: ActivatedRoute) {
    this.email = this._route.snapshot.params.email;

  }

  ngAfterViewInit() {
    if (environment.userLoggedIn) {
      this._router.navigate(['/home']);
      return;
    }
  }

  ngOnInit() {
    this.emailFormControl = new UntypedFormControl('', [Validators.required]);
    this.loginForm = this._formBuilder.group({
      email: this.emailFormControl,
    });

    this.code = this._route.snapshot.params.code;

    if (this.email && this.code) {
      this.validateCode();
    }
  }

  ngOnDestroy() {
    this._bottomSheet.dismiss();
  }

  validateCode() {
    this._http.post(`${getHostUrl()}passwordchange`, {
      code: this.code,
      email: this.email,
    }).subscribe((data) => {
      if (data === 200) {
        const sheet = this._bottomSheet.open(BottomSheetPWForgotComponent, {
          data: {
            email: this.email,
            code: this.code,
          },
          disableClose: true,
        });

        sheet.keydownEvents()
          .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
          .subscribe(() => sheet.dismiss());
      } else {
        this._config.showSnackbar('Der angegebene Code ist ungültig.', 2000, 'error');
      }
    });
  }

  requestPassword() {
    this.showSpinner = true;
    this._http.post(`${getHostUrl()}passwordforgot`, {
      email: this.emailFormControl.value,
    }).subscribe((response: any) => {
      this.showSpinner = false;
      if (response === 404) {
        this._config.showSnackbar('Ein Konto mit dieser E-Mail konnte nicht gefunden werden.', 2000, 'error');
      } else if (response === 200) {
        this._config.showSnackbar('Eine Bestätigungsmail wurde versendet!', 2000, 'success');
        this.emailFormControl.disable();
      }
    }, (error) => {
      console.error(error);
      this.showSpinner = false;
      this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
    });
  }

}

@Component({
  selector: 'app-pwforgot',
  templateUrl: 'pwchange.html',
  styleUrls: ['pwchange.css'],
})
export class BottomSheetPWForgotComponent {
  passwordFormControl: UntypedFormControl;

  passwordRFormControl: UntypedFormControl;

  passwordCFormControl: UntypedFormControl = null;

  loginForm: UntypedFormGroup;

  strength: number;

  data: any;

  translate: any = {
    'LOWER_CASE_CRITERIA_MSG': 'enthält mindestens ein Kleinbuchstaben',
    'UPPER_CASE_CRITERIA_MSG': 'enthält mindestens ein Großbuchstaben Zeichen',
    'DIGITS_CRITERIA_MSG': 'enthält mindestens eine Ziffer',
    'SPECIAL_CHARS_CRITERIA_MSG': 'enhält mindestens ein Sonderzeichen',
    'MIN_CHARS_CRITERIA_MSG': 'enthält mindestens 6 Zeichen',
  };

  env = environment;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public matData: any,
    private _formBuilder: UntypedFormBuilder,
    private _http: HttpClient,
    private _config: ConfigModule,
    public _bottomSheet: MatBottomSheet) {
    this.passwordFormControl = new UntypedFormControl('', [Validators.required]);
    this.passwordRFormControl = new UntypedFormControl('', [Validators.required]);
    this.loginForm = this._formBuilder.group({
      password: this.passwordFormControl,
      passwordR: this.passwordRFormControl,
    });
    this.data = matData;
  }

  onStrengthChanged($event) {
    this.strength = $event;
  }

  isValid() {
    if (this.passwordFormControl.value === this.passwordRFormControl.value) {
      if (this.strength === 100)
        return true;
    }
    return false;
  }

  changePassword() {
    if (this.passwordFormControl.value !== this.passwordRFormControl.value) {
      this._config.showSnackbar('Die Passwörter stimmen nicht überein!', 2000, 'error');
    } else {
      this._http.post(`${getHostUrl()}passwordchange`, {
        code: this.data.code,
        email: this.data.email,
        password: SHA1(this.passwordFormControl.value).toString(),
      }).subscribe((data) => {
        if (data === 201) {
          this._bottomSheet.dismiss();
          window.location.href = '/login';
          this._config.showSnackbar('Dein Passwort wurde geändert!', 2000, 'success');
        } else if (data === '404') {
          this._config.showSnackbar('Der angegebene Code ist ungültig.', 2000, 'error');
        } else {
          this._config.showSnackbar('Es ist ein Fehler aufgetreten.', 2000, 'error');
        } 
      });
    }
  }
}
