<div class="text-center">
  <br />
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
    <mat-icon>close</mat-icon>
  </a>
  <h3>Responder Schicht {{ getCarName() }}</h3><br />

  <form [formGroup]="shiftForm">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Fahrer</mat-label>
      <input #responder formControlName="frs" (keyup)="filterUsers(responder.value, true)" matInput
        [matAutocomplete]="autoResponder">
      <button mat-icon-button matSuffix (click)="clearUser()"
        *ngIf="formEditAble() && shiftForm.get('frs').value !== ''">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-icon-button matSuffix (click)="applyUser(user)"
        *ngIf="shiftForm.get('frs').value === '' && shiftForm.get('coDriver').value !== user && frs">
        <mat-icon>person</mat-icon>
      </button>
    </mat-form-field><br />
    <mat-autocomplete #autoResponder="matAutocomplete" (optionSelected)="applyUser($event.option.value)"
      [displayWith]="displayUser">
      <mat-option *ngFor="let user of filteredUsers" [value]="user">
        {{user.fullname}} - {{ user.userID }}
      </mat-option>
    </mat-autocomplete>
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Zeitraum</mat-label>
      <mat-date-range-input [rangePicker]="picker" separator="bis" [min]="today" [max]="maxDate">
        <input matStartDate formControlName="startdate" placeholder="Schichtstart" (focus)="picker.open()"
          style="cursor: pointer;" #startdate>
        <input matEndDate formControlName="enddate" placeholder="Schichtende" (focus)="picker.open()"
          style="cursor: pointer;">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="formEditAble()"></mat-datepicker-toggle>
      <mat-date-range-picker #picker [calendarHeaderComponent]="_MonthDateHeader"
        [startAt]="startdate.value || startDate" [touchUi]="env.mobile"></mat-date-range-picker>
    </mat-form-field>
    <div class="row shiftPicker">
      <div class="col-lg-6 col-md-6 col-6">
        <mat-button-toggle-group formControlName="startshift" aria-label="Schichtstart" appearance="outline">
          <mat-label *ngIf="!env.mobile">Schichtstart</mat-label>
          <mat-label *ngIf="env.mobile">Start</mat-label>
          <mat-button-toggle mat-button value="day" class="shiftIcon">
            <mat-icon>sunny</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle mat-button value="night" class="shiftIcon">
            <mat-icon>dark_mode</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="col-lg-6 col-md-6 col-6">
        <mat-button-toggle-group formControlName="endshift" aria-label="Schichtende" appearance="outline">
          <mat-label *ngIf="!env.mobile">Schichtende</mat-label>
          <mat-label *ngIf="env.mobile">Ende</mat-label>
          <mat-button-toggle mat-button value="day" class="shiftIcon">
            <mat-icon>sunny</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle mat-button value="night" class="shiftIcon">
            <mat-icon>dark_mode</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Beifahrer</mat-label>
      <input #coDriver formControlName="coDriver" (keyup)="filterUsers(coDriver.value, false)" matInput
        [matAutocomplete]="autoCoDriver">
      <button mat-icon-button matSuffix (click)="clearCoDriver()"
        *ngIf="coDriverEditable() && shiftForm.get('coDriver').value !== ''">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-icon-button matSuffix (click)="applyCoDriver(user)"
        *ngIf="shiftForm.get('coDriver').value === '' && shiftForm.get('frs').value !== user">
        <mat-icon>person</mat-icon>
      </button>
    </mat-form-field><br />
    <mat-autocomplete #autoCoDriver="matAutocomplete" (optionSelected)="applyCoDriver($event.option.value)"
      [displayWith]="displayUser">
      <mat-option *ngFor="let user of filteredUsers" [value]="user">
        {{user.fullname}} - {{ user.userID }}
      </mat-option>
    </mat-autocomplete>
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Kommentar:</mat-label>
      <textarea #comments formControlName="comments" matInput style="resize: none; min-height: 40px;"
        maxlength="100"></textarea>
      <mat-hint align="end">{{comments.value.length}}/100</mat-hint>
    </mat-form-field>
  </form>

  <div class="clearfix">
    <button [routerLink]="['/user', shift.user.userID]" (click)="_bottomSheet.dismiss()" mat-raised-button
      class="float-left" color="primary" *ngIf="shift && shift.user">Zum Benutzer <mat-icon>person</mat-icon>
    </button>
    <div *ngIf="controlsEditAble()">
      <button mat-raised-button class="float-right" color="success" (click)="update()" [disabled]="!shiftForm.valid"
        *ngIf="!deleteShift() && shift.id">Speichern
        <mat-icon>save</mat-icon>
      </button>
      <button mat-raised-button class="float-right" color="success" (click)="add()" [disabled]="!shiftForm.valid"
        *ngIf="!deleteShift() && !shift.id">Hinzufügen
        <mat-icon>add</mat-icon>
      </button>
      <button mat-raised-button class="float-right" color="warn" (click)="remove()" *ngIf="deleteShift()">Löschen
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div *ngIf="controlsCoDriverEditAble() && !controlsEditAble()">
      <button mat-raised-button class="float-right" color="success" (click)="codriver()"
        [disabled]="!(shift.coDriver === null && shiftForm.get('coDriver').value.userID === user.userID || shift.coDriver && shiftForm.get('coDriver').value === '')">Speichern
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
</div>