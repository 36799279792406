import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-material-checks-detail',
  templateUrl: './material-checks-detail.component.html',
  styleUrls: ['./material-checks-detail.component.css']
})
export class MaterialChecksDetailComponent {
  constructor(private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _config: ConfigModule,
    private _builder: FormBuilder) { }

  checkId = null;

  isSaving = false;
  isLoading = true;

  group: FormGroup = new FormGroup({});

  ngOnInit(): void {
    this.group = this._builder.group({
      name: ['', Validators.required],
      days: [0, Validators.required],
      months: [0, Validators.required],
      years: [0, Validators.required],
      description: ['']
    });

    this.checkId = this._route.snapshot.params?.checkId;
    if (this.checkId) {
      this._config.setTitle('Prüfzyklus bearbeiten');
      this.loadCheck();
    } else {
      this._config.setTitle('Prüfzyklus erstellen');
      this.isLoading = false;
    }
  }

  async saveData() {
    this.group.disable();
    this.isSaving = true;
    try {
      if (this.checkId) {
        await firstValueFrom(this._http.post(`${getHostUrl()}admin/material/checks/${this.checkId}`, this.group.value));
      } else {
        const result = await firstValueFrom(this._http.put<any>(`${getHostUrl()}admin/material/checks`, this.group.value));
        this._router.navigate(['material/checks/details', result.checkId]);
      }
      this._config.showSnackbar("Prüfzyklus wurde gespeichert!", 3000, 'success');
    } catch (e) {
      this.isSaving = false;
      this.group.enable();
      if (e?.status == 409) {
        return this._config.showSnackbar("Ein Prüfzyklus mit diesem Namen existiert bereits!", 3000, 'error');
      }
      this._config.showSnackbar("Konnte Prüfzyklus nicht speichern", 3000, 'error');
    }
    this.isSaving = false;
    this.group.enable();
  }

  async loadCheck() {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/checks/${this.checkId}`));
      this.group.setValue(result);
      this.isLoading = false;
    } catch (e) {
      if (e.status == 404) {
        this._config.showSnackbar('Prüfzyklus wurde nicht gefunden!', 3000, 'error');
        this._router.navigate(['material/checks']);
      } else {
        this._config.showSnackbar('Ein Fehler ist aufgetreten, bitte versuche es erneut!', 3000, 'error');
      }
    }
  }
}
