<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">{{group.get('title').value}}</h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="/admin/fields/secretary/courses" mat-mini-fab color="primary" class="pageBack" *ngIf="newCourse">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack" *ngIf="!newCourse">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <form [formGroup]="group">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Laufbahnname</mat-label>
                <input matInput formControlName="title" required />
              </mat-form-field>
            </div>
            <div class="col-lg-9 col-md-8 col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Kategorie</mat-label>
                <mat-select formControlName="category" required>
                  <mat-option *ngFor="let category of categoryList" [value]="category.id">
                    {{ category.category }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-4 col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Dauer in h</mat-label>
                <mat-icon matPrefix>hourglass_bottom</mat-icon>
                <input matInput formControlName="duration" type="number">
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Ort</mat-label>
                <mat-select formControlName="location">
                  <mat-option value="intern">intern</mat-option>
                  <mat-option value="extern">extern</mat-option>
                  <mat-option value="Landkreis">Landkreis</mat-option>
                  <mat-option value="FW Schule">FW Schule</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Rechtegruppen hinzufügen</mat-label>
                <mat-select formControlName="groupsAdd" multiple (selectionChange)="filterRemoveGroups()">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="groupsAddFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let group of groupsAddFilter | async"
                    [value]="group.id">{{group.name}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Rechtegruppen entfernen</mat-label>
                <mat-select formControlName="groupsRemove" multiple (selectionChange)="filterAddGroups()">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="groupsRemoveFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let group of groupsRemoveFilter | async"
                    [value]="group.id">{{group.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12">
              <button mat-raised-button color="success" class="float-right" (click)="saveData()"
                [disabled]="!group.valid">speichern <mat-icon>save
                </mat-icon></button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>