<mat-card>
  <mat-card-content>
    <mat-grid-list cols="1" rowHeight="40px">
      <mat-grid-tile *ngIf="!pushAvailable">
        <button mat-raised-button color="warn" class="defaultCursor input-full-width">Push Benachrichtigungen nicht
          verfügbar</button>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="pushAvailable && notificationState === 'unkown'">
        <button mat-raised-button color="primary" class="input-full-width" (click)="registerSubscription()" [disabled]="serverPublicKey === undefined" matTooltip="Freischalten evtl. möglich">Status Push Benachrichtigungen unbekannt</button>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="pushAvailable && notificationState === 'granted'">
        <button mat-raised-button color="success" class="defaultCursor input-full-width">Push Benachrichtigungen
          aktiviert</button>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="pushAvailable && notificationState === 'denied'">
        <button mat-raised-button color="warn" class="defaultCursor input-full-width">Push Benachrichtigungen
          deaktiviert</button>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="pushAvailable && notificationState === 'default'">
        <button mat-raised-button color="primary" class="input-full-width" (click)="registerSubscription()" [disabled]="serverPublicKey === undefined">Push
          Benachrichtigungen freischalten</button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>