<div>
    <div class="row" *ngIf="showLoader">
        <div class="col-12">
            <ngx-skeleton-loader count="1" [theme]="{ 
                height: '60px'
              }"></ngx-skeleton-loader>
        </div>
        <div class="col-3">
            <ngx-skeleton-loader count="5" [theme]="{ 
                height: '100px'
              }"></ngx-skeleton-loader>
        </div>
        <div class="col-3">
            <ngx-skeleton-loader count="5" [theme]="{ 
                height: '100px'
              }"></ngx-skeleton-loader>
        </div>
        <div class="col-3">
            <ngx-skeleton-loader count="5" [theme]="{ 
                height: '100px'
              }"></ngx-skeleton-loader>
        </div>
        <div class="col-3">
            <ngx-skeleton-loader count="5" [theme]="{ 
                height: '100px'
              }"></ngx-skeleton-loader>
        </div>
    </div>
    <div class="row text-center" [hidden]="showLoader">
        <div class="col-md-4">
            <mat-button-toggle-group>
                <mat-button-toggle mat-raised-button class="previous" mwlCalendarPreviousView [view]="view"
                    (click)="setHashLocation()" [(viewDate)]="viewDate">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle mat-stroked-button class="today" mwlCalendarToday [(viewDate)]="viewDate"
                    (click)="setHashLocation()">
                    <mat-icon>today</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle mat-raised-button class="next" mwlCalendarNextView [view]="view"
                    [(viewDate)]="viewDate" (click)="setHashLocation()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-md-4">
            <h3 class="mt-3 mb-2">{{ viewDate | calendarDate:view + 'ViewTitle':'de' }}</h3>
        </div>
    </div>
    <br />
    <div [ngSwitch]="view" class="calendarView" [hidden]="showLoader">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" weekStartsOn="1" [viewDate]="viewDate"
            [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day.events)">
            <!-- [cellTemplate]="env.mobile ? null : customCellTemplate"> -->
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" weekStartsOn="1" [viewDate]="viewDate"
            [events]="events" [dayStartHour]="8" [dayEndHour]="23" [refresh]="refresh"
            (eventClicked)="eventClicked($event.event)">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
            [dayStartHour]="8" [dayEndHour]="23" [refresh]="refresh" (eventClicked)="eventClicked($event.event)">
        </mwl-calendar-day-view>
    </div>
</div>

<ng-template #customCellTemplate let-day="day" let-openDay="openDay" let-locale="locale"
    let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
    let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody">
    <div class="cal-cell-top">
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
    <div class="cal-cell-bottom" *ngIf="day.events.length > 0">
        <div class="eventText" *ngFor="let event of day.events; let i = index"
            [style.backgroundColor]="event.color.primary" [ngClass]="getMultiDayCssClasses(day, event)"
            (mouseenter)="highlightDay.emit({event: event})" (mouseleave)="unhighlightDay.emit({event: event})">
            {{event.title}}
        </div>
    </div>
</ng-template>