<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">
      {{guardID ? 'Wache ' + group.get('name').value + ' bearbeiten' : 'Sicherheitswache anlegen'}}</h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="/admin/fields/guards/guards" mat-mini-fab color="primary" class="pageBack" *ngIf="newGuard">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack" *ngIf="!newGuard">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <form [formGroup]="group">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Veranstaltungsname</mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Datum</mat-label>
                <input matInput formControlName="date" readonly [matDatepicker]="date" (focus)="date.open()"
                  class="pointer" [class.mobile]="env.mobile" />
                <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date [touchUi]="env.mobile">
                </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Beginn</mat-label>
                <input matInput required formControlName="guardStart" inputmode="numeric" id="guardStart" (keyup)="parseTimeField($event, 'guardStart', 'guardEnd')" />
                <mat-error *ngIf="!group.get('guardStart').value">Beginn darf nicht leer sein</mat-error>
                <mat-error *ngIf="group.get('guardStart').invalid">keine gültige Uhrzeit</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Voraussichtliches Ende</mat-label>
                <input matInput required formControlName="guardEnd" inputmode="numeric" id="guardEnd" (keyup)="parseTimeField($event, 'guardEnd')" />
                <mat-error *ngIf="!group.get('guardEnd').value">Ende darf nicht leer sein</mat-error>
                <mat-error *ngIf="group.get('guardEnd').invalid">keine gültige Uhrzeit</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="input-full-width" appearance="fill">
                <mat-label>Veranstaltungsort</mat-label>
                <mat-select formControlName="location">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="locationFilterCtrl"
                      noEntriesFoundLabel="Veranstaltungsort nicht gefunden"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let loc of locationFilter | async" [value]="loc.id">
                    {{loc.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="input-full-width" appearance="fill">
                <mat-label>Kommentar</mat-label>
                <textarea matInput style="min-height: 75px;" formControlName="comment"></textarea>
              </mat-form-field>
            </div>
            <div *ngIf="guard && guard.status !== 'angelegt' && guard.status !== 'abgesagt'" class="col-12">
              <table class="table table-bordered">
                <thead style="text-align: center;">
                  <th style="width: 300px; border-width: 1px;"></th>
                  <th style="border-width: 1px;">Wachleiter</th>
                  <th style="border-width: 1px;">Wachmann</th>
                </thead>
                <tbody>
                  <tr>
                    <td style="font-weight: bold;">Eingeteilt</td>
                    <td><span [class.changed]="getPositionReplacement(1) !== ''">{{getPosition(1)}}</span>
                      {{getPositionReplacement(1)}}<mat-icon class="float-right pointer"
                        *ngIf="guard.status !== 'in Abrechnung' && guard.status !== 'abgerechnet'"
                        (click)="replacePosition(1)">find_replace</mat-icon></td>
                    <td><span [class.changed]="getPositionReplacement(4) !== ''">{{getPosition(4)}}</span>
                      {{getPositionReplacement(4)}}<mat-icon class="float-right pointer"
                        *ngIf="guard.status !== 'in Abrechnung' && guard.status !== 'abgerechnet'"
                        (click)="replacePosition(4)">find_replace</mat-icon></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">1. Reserve</td>
                    <td><span [class.changed]="getPositionReplacement(2) !== ''">{{getPosition(2)}}</span>
                      {{getPositionReplacement(2)}}<mat-icon class="float-right pointer"
                        *ngIf="guard.status !== 'in Abrechnung' && guard.status !== 'abgerechnet'"
                        (click)="replacePosition(2)">find_replace</mat-icon></td>
                    <td><span [class.changed]="getPositionReplacement(5) !== ''">{{getPosition(5)}}</span>
                      {{getPositionReplacement(5)}}<mat-icon class="float-right pointer"
                        *ngIf="guard.status !== 'in Abrechnung' && guard.status !== 'abgerechnet'"
                        (click)="replacePosition(5)">find_replace</mat-icon></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">2. Reserve</td>
                    <td><span [class.changed]="getPositionReplacement(3) !== ''">{{getPosition(3)}}</span>
                      {{getPositionReplacement(3)}}<mat-icon class="float-right pointer"
                        *ngIf="guard.status !== 'in Abrechnung' && guard.status !== 'abgerechnet'"
                        (click)="replacePosition(3)">find_replace</mat-icon></td>
                    <td><span [class.changed]="getPositionReplacement(6) !== ''">{{getPosition(6)}}</span>
                      {{getPositionReplacement(6)}}<mat-icon class="float-right pointer"
                        *ngIf="guard.status !== 'in Abrechnung' && guard.status !== 'abgerechnet'"
                        (click)="replacePosition(6)">find_replace</mat-icon></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions style="justify-content: space-between;" *ngIf="!disabled">
        <button mat-raised-button color="warn" (click)="cancel()" *ngIf="guardID">absagen
          <mat-icon>event_busy</mat-icon>
        </button>
        <button mat-raised-button color="success" (click)="save(true)" *ngIf="!guardID" [disabled]="!group.valid">anlegen & neu
          <mat-icon>save_as</mat-icon>
        </button>
        <button mat-raised-button color="success" class="float-right" (click)="save(false)" [disabled]="!group.valid">
          {{guardID ? 'speichern' : 'anlegen'}} <mat-icon>{{guardID ? 'save' : 'add'}}</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #switchGuardDialog>
  <h1 mat-dialog-title>Einteilung ändern</h1>
  <div mat-dialog-content>
    <b>{{switchGuard.name}}</b> als {{switchGuard.position}} ersetzen durch
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>{{switchGuard.position}}</mat-label>
      <input type="text" matInput [formControl]="guardReplacementControl" [matAutocomplete]="auto">
      <mat-icon matSuffix (click)="guardReplacementControl.setValue('')"
        [class.inVisible]="guardReplacementControl.value === ''">
        close</mat-icon>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
        <mat-option *ngFor="let user of filterdGuardList | async" [value]="user">
          {{user.state}} - {{user.firstname}} {{user.lastname}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div mat-dialog-actions style="justify-content: end;">
    <button mat-raised-button mat-dialog-close color="success"
      [disabled]="!guardReplacementControl.value" (click)="updateGuardAssignment()"><mat-icon>save</mat-icon>ändern</button>
  </div>
</ng-template>