<div class="row">
  <div class="col-12 text-center">
    <img src="assets/img/404-error.png" />

    <div class="row">
      <div class="col-lg-4 offset-md-12 offset-lg-4">
        <div class="speech-bubble">
          <h2>404</h2>
          <span
            >Die angeforderte Seite konnte nicht gefunden werden. <br />Kehre
            zur <a routerLink="/home">Startseite</a> zurück.</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
