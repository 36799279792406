<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">alle Termine</h3>
        <mat-card>
            <mat-card-content>
                <a (click)="openMenu()" mat-mini-fab class="pageNext" color="primary">
                    <mat-icon>apps</mat-icon>
                </a>
                <div *ngIf="viewMode ==='list';">
                    <app-event-list></app-event-list>
                </div>
                <div *ngIf="viewMode ==='calendar';">
                    <app-event-calendar url="events/calendar"></app-event-calendar>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #menu>
    <br>
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
        <mat-icon>close</mat-icon>
    </a>
    <mat-nav-list *ngIf="menuMode === 'menu'" class="mt-2">
        <mat-list-item (click)="switchView()">
            <h3 matListItemLine>Ansicht wechseln</h3>
            <div matListItemMeta>
                <mat-icon>swap_horiz</mat-icon>
            </div>
        </mat-list-item>
        <mat-list-item (click)="enableDownload()">
            <h3 matListItemLine>Übersichtskalender herunterladen</h3>
            <div matListItemMeta>
                <mat-icon>download</mat-icon>
            </div>
        </mat-list-item>
        <mat-list-item routerLink="/admin/event/new" *ngIf="_login.hasPermission('admin-event-edit')"
            (click)="_bottomSheet.dismiss()">
            <h3 matListItemLine>Termin erstellen</h3>
            <div matListItemMeta>
                <mat-icon>create_new_folder</mat-icon>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="_login.hasPermission('admin-event-change-status')" (click)="openChangeStatus()"
            (click)="_bottomSheet.dismiss()">
            <h3 matListItemLine>Termine veröffentlichen</h3>
            <div matListItemMeta>
                <mat-icon>preview</mat-icon>
            </div>
        </mat-list-item>
        <a [href]="resourceLink" target="_blank">
            <mat-list-item *ngIf="_login.hasPermission('admin-resource-list') && resourceLink">
                <h3 matListItemLine>Resourcenmanagement</h3>
                <div matListItemMeta>
                    <mat-icon>manage_search</mat-icon>
                </div>
            </mat-list-item>
        </a>
    </mat-nav-list>
    <div *ngIf="menuMode === 'download'">
        <div> Kalender für Jahr
            <mat-button-toggle-group style="margin-left: 10px;" [(ngModel)]="download.year">
                <mat-button-toggle value="{{today.getFullYear()}}">{{today.getFullYear()}}</mat-button-toggle>
                <mat-button-toggle value="{{today.getFullYear() + 1}}">{{today.getFullYear() + 1}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div style="margin-top: 1rem;" *ngIf="_login.hasPermission('admin-event-view-all')"> sichtbar für
            <mat-button-toggle-group style="margin-left: 10px;" [(ngModel)]="download.all">
                <mat-button-toggle value="1">alle <mat-icon>visibility</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="0">Teilnehmer <mat-icon>lock</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div style="margin-top: 1rem;" *ngIf="_login.hasPermission('admin-event-check')"> Terminarten
            <mat-button-toggle-group style="margin-left: 10px;" [(ngModel)]="download.status">
                <mat-button-toggle value="sichtbar">sichtbar <mat-icon>verified</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="angelegt">in Prüfung <mat-icon>preview</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <br>
        <a mat-raised-button class="input-full-width" color="success" (click)="_bottomSheet.dismiss()" target="_blank"
            href="{{getHostUrl()}}admin/events/calendar/{{_login.getSessionToken()}}/{{download.year}}?all={{download.all}}&status={{download.status}}">
            herunterladen <mat-icon>download</mat-icon>
        </a>
    </div>
</ng-template>