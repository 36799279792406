import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog as MatDialog, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { ActivatedRoute } from '@angular/router';
import { Login } from 'src/app/util/login';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../../../environments/environment';
import { AdminUserMenusheetComponent } from '../admin-user-menusheet/admin-user-menusheet.component';
import { firstValueFrom, Observable, of } from 'rxjs';
import { ThemingService } from 'src/app/util/services/theming.service';
import * as dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { filter } from 'rxjs/operators';
import { AppCommunicationService } from 'src/app/util/services/app-communication/app-communication.service';
import { NfcScannerDialogComponent } from 'src/app/util/dialogs/nfc-scanner-dialog/nfc-scanner-dialog.component';

interface Licence {
  id: number,
  licenceClassID: number,
  licenceClass: string,
  expiryDate: Date,
  briefingDate: Date,
  briefingDateBlue: Date,
  recertification: Date,
  lastChecked: Date,
  permission: number[],
  permissionBlue: number[],
  drivingperms: Perm[],
  trailer: boolean | number
}

interface Car {
  id: number,
  licenceClass: string,
  type: string
}

interface Perm {
  id: number,
  briefingDate: Date,
  briefingDateBlue: Date,
  recertification: Date
}

@Component({
  selector: 'app-admin-user-driving',
  templateUrl: './admin-user-driving.component.html',
  styleUrls: ['./admin-user-driving.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class AdminUserDrivingComponent implements OnInit, OnDestroy {

  minDate: Date;
  endDate: Date;
  today: Date = new Date();
  expiryLicence: Date = dayjs(this.today).add(15, 'years').toDate();
  dienstfahrerlaubnis: string = '';
  userInfo: any = {};
  firstname: string;
  lastname: string;
  birthdate: Date;
  age18: boolean = true;
  age18Date: Date;
  userID: number;
  carTypesList: Car[] = [];
  displayedColumns: string[] = ['licenceClass', 'date'];
  existingLicence: boolean = false;
  allLicences: boolean = false;
  unlimited: boolean = false;
  unlimitedClassC1: boolean = false;
  unlimitedClassB: boolean = false;
  classesExisting: boolean = false;
  FFFExisting: boolean = false;
  env = environment;

  constructor(private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    private _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _bottomSheetMenu: MatBottomSheet,
    private _bottomSheet: MatBottomSheet,
    private _dialog: MatDialog,
    private _com: AppCommunicationService) { }

  ngOnInit() {
    this.userID = this._route.snapshot.params.userId;
    if (this.userID) {
      this.fetchDriving(this.userID);
      this.fetchDataInfo(this.userID);
      this._config.setTitle('Verwaltung | Lädt...');
    }
  }

  fetchDriving(userID: number) {
    this.classesExisting = false;
    this.FFFExisting = false;
    this.userInfo = {};
    this._http.get<any>(getHostUrl() + 'admin/user/driving?userID=' + userID).subscribe(data => {
      this.userInfo = data;
      this.userInfo.licences.sort((a: Licence, b: Licence) => this.sortLicences(a, b));
      if (this.userInfo.licences.length === 4)
        this.allLicences = true;
      this.userInfo.licences.forEach((licence: Licence) => {
        var licenceClass = licence.licenceClass;
        if (licenceClass === 'Klasse FFF')
          this.FFFExisting = true;
        else
          this.classesExisting = true;
        if (licenceClass.endsWith('E'))
          licenceClass = licenceClass.substring(0, licenceClass.length - 1);
        if ((licenceClass === 'Klasse C1' || licenceClass === 'Klasse C1E') && (licence.expiryDate === null || dayjs(licence.expiryDate).isSame(dayjs('01.01.1901')) || dayjs(licence.expiryDate).isSame(dayjs('1900-12-31T23:00:00.000Z')) || licence.expiryDate.toString() === '1900-12-31'))
          this.unlimitedClassC1 = true;
        if ((licenceClass === 'Klasse B' || licenceClass === 'Klasse BE' || licenceClass === 'FFF') && (licence.expiryDate === null || dayjs(licence.expiryDate).isSame(dayjs('01.01.1901')) || dayjs(licence.expiryDate).isSame(dayjs('1900-12-31T23:00:00.000Z')) || licence.expiryDate.toString() === '1900-12-31'))
          this.unlimitedClassB = true;
        this._http.get<any>(getHostUrl() + 'admin/user/drivingperms?userID=' + userID + '&licenceClass=' + licenceClass).subscribe(permData => {
          licence.permission = permData.permission;
          licence.permissionBlue = permData.permissionBlue;
          licence.drivingperms = permData.drivingperms;
          this._spinner.hide();
        });
      });
      if (this.userInfo.dateOfIssue === '0000-00-00')
        this.userInfo.dateOfIssue = null;
      else
        this.expiryLicence = dayjs(this.userInfo.dateOfIssue).add(15, 'years').toDate();
      if (this.userInfo.dateOfExpiry === null || dayjs(this.userInfo.expiryDate).isSame(dayjs('01.01.1901')) || dayjs(this.userInfo.expiryDate).isSame(dayjs('1900-12-31T23:00:00.000Z')))
        this.unlimited = true;
      if (this.userInfo.dateOfIssue !== null && this.userInfo.issuingAuthority !== '' && this.userInfo.dlNumber !== '')
        this.existingLicence = true;
    });
  }

  async fetchDataInfo(userID: number) {
    this._http.get<any>(getHostUrl() + 'admin/user/info?userID=' + userID).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.birthdate = data.birthdate;
      this.age18 = dayjs(this.today).add(-17, 'years').isAfter(dayjs(data.birthdate));
      this.age18Date = dayjs(data.birthdate).add(17, 'years').toDate();
      this._config.setTitle(`${data.firstname + ' ' + data.lastname}`);
    });
    this.carTypesList = await this._http.get<Car[]>(`${getHostUrl()}datainfo/carTypes`).toPromise();
  }

  getCarList(licenceClass: string, trailer: number | boolean): Car[] {
    var returnList: Car[] = [];
    if (licenceClass.endsWith('E'))
      licenceClass = licenceClass.substring(0, licenceClass.length - 1);
    this.carTypesList.forEach(element => {
      if (element.licenceClass === licenceClass || (element.licenceClass === licenceClass + 'E' && (trailer === true || trailer === 1)))
        returnList.push(element);
    });
    return returnList;
  }

  getFormattedDate(expiryDate: string) {
    if (expiryDate === undefined)
      return '';
    if (expiryDate === '0000-00-00')
      return '-';
    return dayjs(expiryDate).format('DD.MM.YYYY');
  }

  sortLicences(first: Licence, second: Licence) {
    if (first.licenceClass === 'Klasse B')
      return -1;
    else if (second.licenceClass === 'Klasse B')
      return 1;
    if (first.licenceClass === 'Klasse BE')
      return -1;
    else if (second.licenceClass === 'Klasse BE')
      return 1;
    if (first.licenceClass === 'Klasse C1')
      return -1;
    else if (second.licenceClass === 'Klasse C1')
      return 1;
    if (first.licenceClass === 'Klasse C1E')
      return -1;
    else if (second.licenceClass === 'Klasse C1E')
      return 1;
    if (first.licenceClass === 'Klasse C')
      return -1;
    else if (second.licenceClass === 'Klasse C')
      return 1;
    if (first.licenceClass === 'Klasse CE')
      return -1;
    else if (second.licenceClass === 'Klasse CE')
      return 1;
    else return 0;
  }

  controlDateValid(): 0 | 1 | 2 {
    const now: DateTime = DateTime.now();
    const month: DateTime = now.minus({ month: 5 });
    const ablauf: DateTime = now.minus({ month: 6 });
    const control: DateTime = DateTime.fromSQL(this.userInfo.lastChecked);
    if (control < ablauf)
      return 2;
    if (control < month)
      return 1;
    return 0;
  }

  getCheckedExpiry(): string {
    const checked: DateTime = DateTime.fromSQL(this.userInfo.lastChecked);
    const expiry: DateTime = checked.plus({ months: 6 });
    if (DateTime.now() < expiry)
      return expiry.setLocale('de-DE').toFormat("'läuft am' dd'.' MMMM yyyy 'ab'");
    return expiry.setLocale('de-DE').toFormat("'ist am' dd'.' MMMM yyyy 'abgelaufen'");
  }

  /**
  * Bottom Sheet and Dialog
  */
  openMenu() {
    this._bottomSheetMenu.open(AdminUserMenusheetComponent, { data: { userID: this.userID, name: this.firstname + ' ' + this.lastname } });
  }

  openLicenceMenu() {
    this._bottomSheet.open(AdminUserDrivingSheetComponent, { data: this });
  }

  openLicenceInfo() {
    const infoDialog = this._dialog.open(AdminUserLicenceInfoDialogComponent, { data: this, disableClose: true });
    infoDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => infoDialog.close());
  }

  openLicenceClassInfo(licence: Licence) {
    const licenceClassDialog = this._dialog.open(AdminUserLicenceClassDialogComponent, { data: { licence: licence, minDateExpiry: this.userInfo.dateOfIssue, carList: this.getCarList(licence.licenceClass, licence.trailer), parent: this }, disableClose: true });
    licenceClassDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => licenceClassDialog.close());
  }

  newLicence(licenceClassID: number, licenceClass: string) {
    let licence: Licence = { id: null, licenceClassID: licenceClassID, licenceClass: licenceClass, expiryDate: null, briefingDate: null, lastChecked: null, briefingDateBlue: null, recertification: null, permission: [], permissionBlue: [], drivingperms: [], trailer: false };
    this.openLicenceClassInfo(licence);
  }

  filterLicence(licenceClassID: number, licenceClass: string): Licence {
    let licence: Licence = null;
    this.userInfo.licences.forEach((l: Licence) => {
      if ((l.licenceClassID === licenceClassID && l.licenceClass === licenceClass) || (l.licenceClassID === licenceClassID + 1 && l.licenceClass === licenceClass + 'E')) {
        licence = l;
      }
    });
    return licence;
  }

  getDisabled(licenceClassID: number): boolean {
    let returnValue: boolean = false;
    this.userInfo.licences.forEach(licence => {
      if (licenceClassID === licence.licenceClassID || licenceClassID + 1 === licence.licenceClassID) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  ngOnDestroy(): void {
    this._bottomSheetMenu.dismiss();
    this._bottomSheet.dismiss();
    this._dialog.closeAll();
  }

}

@Component({
  selector: 'app-licence-info',
  templateUrl: 'licenceInfoDialog.html',
  styleUrls: ['./admin-user-driving.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class AdminUserLicenceInfoDialogComponent implements OnInit {

  parent: AdminUserDrivingComponent;
  licencePicture: string;
  env = environment;

  constructor(public _login: Login, private _config: ConfigModule, private _http: HttpClient, private _spinner: NgxSpinnerService, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.parent = data;
  }

  ngOnInit(): void {
    this.licencePicture = this.parent.userInfo.front;
  }

  ondateOfIssueChange($event: any) {
    this.parent.userInfo.dateOfIssue = $event.value;
    console.log($event.value);
    this.parent.expiryLicence = dayjs(this.parent.userInfo.dateOfIssue).add(15, 'years').toDate();
  }

  ondateOfExpiryChange($event: any) {
    this.parent.userInfo.dateOfExpiry = $event.value;
  }

  onissueDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.parent.userInfo.dateOfIssue = new Date(dateString);
    this.parent.expiryLicence = dayjs(this.parent.userInfo.dateOfIssue).add(15, 'years').toDate();
  }

  onexpiryDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.parent.userInfo.dateOfExpiry = new Date(dateString);
  }

  saveDriving() {
    if (this.parent.userID) {

      this.parent.userInfo.dateOfIssue = dayjs(this.parent.userInfo.dateOfIssue).format('YYYY-MM-DD');
      this.parent.userInfo.dateOfExpiry = dayjs(this.parent.userInfo.dateOfExpiry).format('YYYY-MM-DD');
      this._http.post(getHostUrl() + 'admin/user/driving', { userID: this.parent.userID, userObj: this.parent.userInfo }).subscribe(resp => {
        this._spinner.hide();
        this._config.showSnackbar('Fahrerlaubnisdaten gespeichert!', 2000, 'success');
        this.parent.fetchDriving(this.parent.userID);
      }, error => {
        this._spinner.hide();
        if (error.error === 5001)
          this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
        this.parent.fetchDriving(this.parent.userID);
      });
    }
  }
}

@Component({
  selector: 'app-licence-add',
  templateUrl: 'licenceAddSheet.html',
  styleUrls: ['./admin-user-driving.component.css'],
})
export class AdminUserDrivingSheetComponent {

  parent: AdminUserDrivingComponent;

  constructor(public _bottomSheet: MatBottomSheet,
    private _theme: ThemingService, private _config: ConfigModule,
    @Inject(MAT_BOTTOM_SHEET_DATA) data: any,
    public _login: Login,
    private _dialog: MatDialog,
    private _http: HttpClient) {
    this.parent = data;
  }

  async rfid() {
    const result = await firstValueFrom(this._dialog.open(NfcScannerDialogComponent, {
      width: '250px'
    }).afterClosed());

    if (result) {
      try {
        this._http.post(getHostUrl() + 'admin/user/registerRFID', { userID: this.parent.userID, rfidNumber: result?.uid ?? result }).subscribe({
          next: (resp) => {
            this._config.showSnackbar('RFID Tag gespeichert!', 2000, 'success');
          }, error: error => {
            if (error.error === 5001)
              this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
            else if (error.error === 5002)
              this._config.showSnackbar('RFID-Code bereits registriert!', 2000, 'error');
            else
              this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
          }
        });
      } catch (error) {
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      }
    }
  }

  licence = { id: 0, name: 'Führerscheindaten', icon: { light: '/assets/cars/paper_b.png', dark: '/assets/cars/paper_w.png' } };

  menuEntries = [{ id: 1, name: 'Klasse B(E)', class: 'Klasse B', icon: { light: '/assets/cars/b_b.png', dark: '/assets/cars/b_w.png' } },
  { id: 3, name: 'Klasse C1(E)', class: 'Klasse C1', icon: { light: '/assets/cars/c1_b.png', dark: '/assets/cars/c1_w.png' } },
  { id: 5, name: 'Klasse C(E)', class: 'Klasse C', icon: { light: '/assets/cars/c_b.png', dark: '/assets/cars/c_w.png' } },
  { id: 7, name: 'Klasse FFF', class: 'Klasse FFF', icon: { light: '/assets/cars/fff_b.png', dark: '/assets/cars/fff_w.png' } }];

  open(id: number, name: string) {
    if (id !== 0) {
      if (!this.parent.getDisabled(id))
        this.parent.newLicence(id, name);
      else {
        let licence: Licence = this.parent.filterLicence(id, name);
        if (licence !== null)
          this.parent.openLicenceClassInfo(licence);
        else
          this._config.showSnackbar('Es ist ein Fehler aufgetreten!', 2000, 'error');
      }
    } else
      this.parent.openLicenceInfo();
    this._bottomSheet.dismiss();
  }

  getIcon(name: string): string {
    var path: string = '/assets/cars/';
    var theme = this._theme.theme.value;
    if (name === 'Klasse B') {
      if (theme === 'standard-theme') {
        return (path + 'b_b.png');
      } else {
        return (path + 'b_w.png');
      }
    } else if (name === 'Klasse C1') {
      if (theme === 'standard-theme') {
        return (path + 'c1_b.png');
      } else {
        return (path + 'c1_w.png');
      }
    } else if (name === 'Klasse C') {
      if (theme === 'standard-theme') {
        return (path + 'c_b.png');
      } else {
        return (path + 'c_w.png');
      }
    } else if (name === 'Klasse FFF') {
      if (theme === 'standard-theme') {
        return (path + 'fff_b.png');
      } else {
        return (path + 'fff_w.png');
      }
    } else if (name === 'Führerscheindaten') {
      if (theme === 'standard-theme') {
        return (path + 'paper_b.png');
      } else {
        return (path + 'paper_w.png');
      }
    }
    return '';
  }

  getLicenceValid(id: number): boolean {
    if (id === 0)
      return true;
    else
      return this.parent.existingLicence;
  }
}

@Component({
  selector: 'app-license-class-info',
  templateUrl: 'licenceClassDialog.html',
  styleUrls: ['./admin-user-driving.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class AdminUserLicenceClassDialogComponent implements OnInit {

  parent: AdminUserDrivingComponent;

  licence: Licence;

  original: Licence;

  carList: Car[] = [];

  missingCars: Observable<Car[]> = of([]);

  minDateExpiry: Date;

  startDate: Date = new Date();

  expiryDate: String;

  licencePicture: String;

  expiryClass: Date;

  trailerRemove: number = -1;

  unlimited: boolean = false;

  env = environment;

  constructor(public _dialog: MatDialog,
    public _login: Login,
    private _http: HttpClient,
    private _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _theme: ThemingService,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.licence = JSON.parse(JSON.stringify(data.licence));
    this.original = JSON.parse(JSON.stringify(data.licence));
    this.minDateExpiry = data.minDateExpiry;
    this.carList = data.carList;
    this.parent = data.parent;
  }

  ngOnInit() {
    this.expiryDate = dayjs(this.parent.userInfo.dateOfExpiry).format('DD.MM.YYYY');
    this.expiryClass = dayjs(this.parent.userInfo.dateOfIssue).add(5, 'years').toDate();
    this.setMissingCars();
    if (this.licence.licenceClass === 'Klasse B' || this.licence.licenceClass === 'Klasse FFF')
      this.licence.expiryDate = this.parent.userInfo.dateOfExpiry;
    if (dayjs(this.licence.expiryDate).isSame(dayjs('01.01.1901')) || dayjs(this.licence.expiryDate).isSame(dayjs('1900-12-31')) || (this.licence.expiryDate === null && (this.licence.licenceClass === 'Klasse B' || this.licence.licenceClass === 'Klasse FFF')))
      this.unlimited = true;
    if (this.licence.licenceClass !== 'Klasse FFF')
      this.licencePicture = this.parent.userInfo.back;
    else
      this.licencePicture = this.parent.userInfo.FFFfront;
  }

  save(deleteing: boolean) {
    if (this.parent.userID) {

      if (this.licence.trailer === true)
        this.licence.trailer = 1;
      else
        this.licence.trailer = 0;
      this._http.post(getHostUrl() + 'admin/user/drivingperms', { userID: this.parent.userID, licence: this.licence, original: this.original, deleteing: deleteing }).subscribe(resp => {
        this._spinner.hide();
        this._config.showSnackbar('Fahrerlaubnisdaten gespeichert!', 2000, 'success');
        this.parent.fetchDriving(this.parent.userID);
      }, error => {
        this._spinner.hide();
        if (error.error === 5001)
          this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
        this.parent.fetchDriving(this.parent.userID);
      });
    }
  }

  getCarName(id: number): string {
    var res = '-';
    this.carList.forEach(car => {
      if (car.id === id) {
        res = car.type;
        return;
      }
    });
    return res;
  }

  setWithAddMenu() {
    $('#menuWith').width($('#carTypeAddWith').width());
  }

  setMissingCars() {
    let temp = [];
    this.licence.drivingperms.forEach(perm => {
      temp.push(perm.id);
    });
    let tempMissing = [];
    this.carList.forEach(car => {
      if (!temp.includes(car.id))
        tempMissing.push(car);
    });
    this.missingCars = of(tempMissing);
  }

  addPerm(car: Car) {
    let perm: Perm = {
      'id': car.id,
      'briefingDate': new Date(),
      'briefingDateBlue': null,
      'recertification': null,
    };

    this.licence.drivingperms.push(perm);
    this.setMissingCars();
  }

  deletePerm(perm: Perm) {
    this.licence.drivingperms.splice(this.licence.drivingperms.indexOf(perm), 1);
    this.setMissingCars();
  }

  licencePermBlueChanged($event: MatSelectChange) {
    this.licence.permissionBlue = $event.value;
  }

  onexpiryDateChange($event: any) {
    if ($event.value === null) {
      return;
    }
    this.licence.expiryDate = $event.value;
  }

  onbriefingDateChange($event: any, perm: Perm) {
    let index = this.licence.drivingperms.indexOf(perm);
    if ($event.value === null) {
      this.licence.drivingperms[index].briefingDate = new Date();
      return;
    }
    this.licence.drivingperms[index].briefingDate = $event.value;
  }

  onbriefingDateBlueChange($event: any, perm: Perm) {
    let index = this.licence.drivingperms.indexOf(perm);
    this.licence.drivingperms[index].briefingDateBlue = $event.value;
  }

  onrecertificationDateChange($event: any, perm: Perm) {
    let index = this.licence.drivingperms.indexOf(perm);
    this.licence.drivingperms[index].recertification = $event.value;
  }

  onexpiryChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    if (dateString === '' || dateString === 'undefined-undefined-' || new Date(dateString) === null) {
      this._config.showSnackbar('Ablaufdatum darf nicht leer sein!', 2000, 'error');
      this.licence.expiryDate = null;
      return;
    }
    this.licence.expiryDate = new Date(dateString);
  }

  onbriefingChange($event: any, perm: Perm) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    let index = this.licence.drivingperms.indexOf(perm);
    if (dateString === '' || dateString === 'undefined-undefined-' || new Date(dateString) === null) {
      this._config.showSnackbar('Einweisungsdatum darf nicht leer sein!', 2000, 'error');
      this.licence.drivingperms[index].briefingDate = new Date();
      return;
    }
    this.licence.drivingperms[index].briefingDate = new Date(dateString);
  }

  onbriefingBlueChange($event: any, perm: Perm) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    let index = this.licence.drivingperms.indexOf(perm);
    this.licence.drivingperms[index].briefingDateBlue = new Date(dateString);
  }

  onrecertificationChange($event: any, perm: Perm) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    let index = this.licence.drivingperms.indexOf(perm);
    this.licence.drivingperms[index].recertification = new Date(dateString);
  }

  getLicenceTrailerIcon(licenceClass: string): string {
    var path: string = '/assets/cars/';
    var theme = this._theme.theme.value;
    var trailer = '';
    if (this.licence.trailer === false)
      trailer = '_false';
    if (licenceClass === 'Klasse B' || licenceClass === 'Klasse BE') {
      if (theme === 'standard-theme') {
        return (path + 'be_b' + trailer + '.png');
      } else {
        return (path + 'be_w' + trailer + '.png');
      }
    } else if (licenceClass === 'Klasse C1' || licenceClass === 'Klasse C1E') {
      if (theme === 'standard-theme') {
        return (path + 'c1e_b' + trailer + '.png');
      } else {
        return (path + 'c1e_w' + trailer + '.png');
      }
    } else if (licenceClass === 'Klasse C' || licenceClass === 'Klasse CE') {
      if (theme === 'standard-theme') {
        return (path + 'ce_b' + trailer + '.png');
      } else {
        return (path + 'ce_w' + trailer + '.png');
      }
    }
    return '';
  }

  changeTrailer(licence) {
    if (licence.trailer === true) {
      licence.licenceClassID--;
      licence.trailerRemove = -1;
      licence.licenceClass = licence.licenceClass.slice(0, -1);
    } else {
      licence.licenceClassID++;
      licence.trailerRemove = 1;
      licence.licenceClass += 'E';
    }
    this.carList = this.parent.getCarList(licence.licenceClass, !licence.trailer);
    this.setMissingCars();
  }

  setUnlimited(val: boolean) {
    this.unlimited = val;
    if (val)
      this.licence.expiryDate = new Date('01.01.1901');
    else
      this.licence.expiryDate = null;
  }
}