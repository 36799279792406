import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Login } from '../login';


@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private _login: Login) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._login.getSessionToken() !== '') {
      return next.handle(request.clone({
        headers: new HttpHeaders({
          'Authorization': this._login.getSessionToken(),
        }),
      })).pipe(map(event => {
        if (event instanceof HttpResponse) {
          if (event.status === 403) {
            console.error('Received 403 error, logging user out of system!');
            console.error(event);
            this._login.logout(); 
          }
        }         
        return event;
      }));
    } else {
      return next.handle(request);
    }
  }
}
