<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Benachrichtigungen</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <div class="row">
          <div class="col-lg-12 col-12">
            <div *ngIf="notificationsList.length===0">
              <mat-progress-bar mode="query"></mat-progress-bar>
            </div>
            <div class="table-responsive">
              <table mat-table [dataSource]="notifications" class="mat-elevation-z8" matSort matSortActive="date"
                matSortDirection="desc" matSortDisableClear>
                <!-- Message Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachricht</th>
                  <td mat-cell *matCellDef="let element" (click)="openNotification(element)">
                    {{element.title}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="vertical-align: middle;">
                    Name</th>
                  <td mat-cell *matCellDef="let element" (click)="openNotification(element)">
                    {{element.name}} </td>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="vertical-align: middle;">
                    Datum</th>
                  <td mat-cell *matCellDef="let element" (click)="openNotification(element)">
                    {{formatDate(element.date)}} </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                  <td mat-cell *matCellDef="let element">
                    <button type="button" mat-icon-button color="green" *ngIf="!element.read"
                      (click)="readSpecific(element.id)">
                      <mat-icon>done</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="notificationsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: notificationsColumns;"></tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons>
            </mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>