import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { Guard, GuardBilling, GuardStatus } from '@ffo/mitgliederbereich-types';
import { DateTime } from 'luxon';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-guards-control',
  templateUrl: './guards-control.component.html',
  styleUrls: ['./guards-control.component.css'],
})
export class AdminGuardsControlComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  viewColumns: string[] = ['name', 'date', 'status', 'location'];
  guardList: Guard[] | GuardBilling[] = [];
  guards: MatTableDataSource<Guard | GuardBilling>;

  searchFilter: string = '';
  statusFilter: GuardStatus[] = ['angelegt', 'in Einteilung', 'eingeteilt'];
  loading: boolean = true;

  env = environment;
  constructor(private _http: HttpClient, private _config: ConfigModule) { }  

  ngOnInit(): void {
    this._config.setTitle('Sicherheitswachen | Wachen');
    this.loadGuards();
  }

  loadGuards(): void {
    this.loading = true;
    this._http.get<Guard[] | GuardBilling[]>(getHostUrl() + 'admin/fields/guards/guards')
      .subscribe(data => {
        this.guardList = data.map((guard: Guard | GuardBilling) => {
          guard.date = new Date(guard.date);
          return guard;
        });
        this.guards = new MatTableDataSource<Guard | GuardBilling>(this.guardList);
        this.guards.sortingDataAccessor = (item: Guard | GuardBilling, property) => {
          switch (property) {
            case 'date':
              const date = DateTime.fromJSDate(item.date).setZone('Europe/Berlin');
              const time = DateTime.fromFormat(item.guardStart, 'HH:mm').setZone('Europe/Berlin');
              return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toJSDate();
            default: return item[property];
          }
        };
        this.guards.sort = this.sort;
        this.guards.paginator = this.paginator;
        this.guards.filterPredicate = this.filter();
        this.applySearch('');
        this.loading = false;
      });
  }

  applySearch(searchValue: string) {
    this.searchFilter = searchValue.toLowerCase();
    this.guards.filter = JSON.stringify({ search: this.searchFilter, status: this.statusFilter });
  }

  applyStatusFilter($event: MatSelectChange) {
    this.statusFilter = $event.value;
    this.guards.filter = JSON.stringify({ search: this.searchFilter, status: this.statusFilter });
  }

  filter(): (data: Guard | GuardBilling, filter: string) => boolean {
    let filterFunction = function (data: Guard | GuardBilling, filter: string): boolean {
      if (this.loading) return false;
      let searchTerms: { search: string; status: GuardStatus[] } = JSON.parse(filter);
      if (searchTerms.status.length !== 0) {
        return data.name.toLowerCase().indexOf(searchTerms.search) !== -1 && searchTerms.status.includes(data.status);
      } else {
        return data.name.toLowerCase().indexOf(searchTerms.search) !== -1;
      }
    };
    return filterFunction;
  }
}
