import { HttpClient } from '@angular/common/http';
import { Component, Host, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import * as $ from 'jquery';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getHostUrl } from 'src/app/util/config';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { PreventCloseGuard } from 'src/app/util/guards/prevent-close.guard';
import { AlarmsComponent } from '../alarms-edit.component';

@Component({
  selector: 'app-alarms-fez',
  templateUrl: './alarms-fez.component.html',
  styleUrls: ['./alarms-fez.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class AlarmsFezComponent implements OnInit, PreventCloseGuard {

  timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  group = new UntypedFormGroup({});
  todaysDate = new Date();
  referencePrefixList = [];
  disabledElements = false;
  initialValue: any = null;

  constructor(private _formBuilder: UntypedFormBuilder,
    private _http: HttpClient,
    @Host() public _host: AlarmsComponent) {
      dayjs.extend(customParseFormat);
    }

  onCloseValid(): boolean {
    return JSON.stringify(this.initialValue) === JSON.stringify(this.group.value);
  }

  ngOnInit(): void {
    this.group = this._formBuilder.group({
      enddate: ['', Validators.required],
      alarmReference: ['', Validators.required],
      alarmTime: ['', Validators.compose([Validators.required, Validators.pattern(this.timePattern)])],
      outTime: ['', Validators.pattern(this.timePattern)],
      onTime: ['', Validators.pattern(this.timePattern)],
      offTime: ['', Validators.pattern(this.timePattern)],
      inTime: ['', Validators.compose([Validators.required, Validators.pattern(this.timePattern)])],
      confTime: ['', Validators.compose([Validators.required, Validators.pattern(this.timePattern)])],
      alarmReferencePrefix: ['', Validators.required],
      date: ['', Validators.required],
      zen: [false],
    }, { validator: validateTimeField });

    this.initialValue = this.group.value;
    this.group.markAllAsTouched();
    this.loadData();
    this.loadReferencePrefix();
  }

  loadReferencePrefix() {
    this._http.get<any>(`${getHostUrl()}datainfo/reference/prefix`).subscribe(result => {
      this.referencePrefixList = result;
    });
  }

  loadData() {
    this._http.get<any>(`${getHostUrl()}alarms/fez?alarmId=${this._host.alarmID}`).subscribe(result => {
      this.group.patchValue({
        alarmTime: result.alarmTime ? dayjs(result.alarmTime, 'HH:mm:ss').format('HH:mm') : '',
        outTime: result.outTime ? dayjs(result.outTime, 'HH:mm:ss').format('HH:mm') : '',
        onTime: result.onTime ? dayjs(result.onTime, 'HH:mm:ss').format('HH:mm') : '',
        offTime: result.offTime ? dayjs(result.offTime, 'HH:mm:ss').format('HH:mm') : '',
        inTime: result.inTime ? dayjs(result.inTime, 'HH:mm:ss').format('HH:mm') : '',
        confTime: result.confTime ? dayjs(result.confTime, 'HH:mm:ss').format('HH:mm') : '',
        alarmReference: result.alarmReference ? result.alarmReference.substring(1, result.alarmReference.length) : '',
        enddate: result.enddate ? dayjs(result.enddate).toDate() : null,
        alarmReferencePrefix: (result.alarmReference && result.alarmReference.length > 2) ? result.alarmReference.substring(0, 1) : '',
        date: result.date,
        zen: result.zen,
      });
      if (result.status === 5) {
        this._host.saveAble = false;
      }
      if (!this.group.get('alarmReferencePrefix').value) {
        this.group.get('alarmReferencePrefix').setValue(result.keywordPrefix);
      }
      this.initialValue = this.group.value;
      this.group.get('zen').valueChanges.subscribe(() => {
        this.setZEN();
      });
    });
  }

  saveData() {
    return new Promise((resolve, reject) => {
      this._host.showLoader = true;
      this._http.post<any>(`${getHostUrl()}alarms/fez`, {
        alarmId: this._host.alarmID,
        data: this.group.value,
      }).subscribe(result => {
        this._host.showLoader = false;
        this.initialValue = this.group.value;
        resolve(true);
      }, error => {
        this._host.showLoader = false;
        reject();
      });
    });
  }

  setZEN() {
    this._http.post(`${getHostUrl()}alarms/fez/zen`, {
      alarmId: this._host.alarmID,
      zen: this.group.get('zen').value,
    }).subscribe(() => {
      this.initialValue.zen = this.group.get('zen').value;
    });
  }

  parseTimeField($event, fieldName, nextField = null, previousField = null) {
    if ($event.keyCode === 8 || $event.keyCode === 46 || $event.keyCode === 9 || $event.keyCode === 16) {
      return;
    }
    const value: string = this.group.get(fieldName).value;

    if (value.match(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#´]/)) {
      this.group.get(fieldName).setValue(value.replace(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#]/g, ''));
    }

    if (value.length > 5) {
      this.group.get(fieldName).setValue(value.substring(0, 5));
      return;
    }
    if (value.match(this.timePattern) && nextField) {
      $('#' + nextField).trigger('focus');
      return;
    }
    if (!value.includes(':')) {
      if (value.length === 2) {
        this.group.get(fieldName).setValue(value + ':');
      }
    }
    return true;
  }
}

export function validateTimeField(c: AbstractControl): ValidationErrors {
  if (c.get('enddate').value &&
    dayjs(c.get('enddate').value).isAfter(dayjs(c.get('date').value))) {
    return null;
  }

  const alarmTimeField = c.get('alarmTime'),
    outTimeField = c.get('outTime'),
    onTimeField = c.get('onTime'),
    offTimeField = c.get('offTime'),
    inTimeField = c.get('inTime'),
    confTimeField = c.get('confTime'),
    alarmTime = dayjs(alarmTimeField.value, 'HH:mm'),
    outTime = dayjs(outTimeField.value, 'HH:mm'),
    onTime = dayjs(onTimeField.value, 'HH:mm'),
    offTime = dayjs(offTimeField.value, 'HH:mm'),
    inTime = dayjs(inTimeField.value, 'HH:mm'),
    confTime = dayjs(confTimeField.value, 'HH:mm');

  if (outTime.isBefore(alarmTime)) {
    outTimeField.setErrors({ smallerTime: true });
    return {
      invalidDate: true,
    };
  }
  if (onTime.isBefore(outTime)) {
    onTimeField.setErrors({ smallerTime: true });
    return {
      invalidDate: true,
    };
  }
  if (offTime.isBefore(onTime)) {
    offTimeField.setErrors({ smallerTime: true });
    return {
      invalidDate: true,
    };
  }
  if (outTimeField.value && outTimeField.valid) {
    if (inTime.isBefore(offTime)) {
      inTimeField.setErrors({ smallerTime: true });
      return {
        invalidDate: true,
      };
    }
  } else {
    if (inTime.isBefore(alarmTime)) {
      inTimeField.setErrors({ smallerTimeOut: true });
      return {
        invalidDate: true,
      };
    }
  }
  if (confTime.isBefore(inTime)) {
    confTimeField.setErrors({ smallerTime: true });
    return {
      invalidDate: true,
    };
  }
  return null;
}