import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeDevice } from 'ngx-scanner-qrcode';
import { ConfigModule } from '../../config';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.css']
})
export class QrScannerComponent implements OnInit {

  scanned = false;

  public config: ScannerQRCodeConfig = {
    vibrate: 400,
    constraints: {
      audio: false
    }
  };

  constructor(public _ref: MatDialogRef<QrScannerComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _config: ConfigModule) { }

  @ViewChild(NgxScannerQrcodeComponent)
  private qrScanner: NgxScannerQrcodeComponent;

  closeWithStatus(data) {    
    if (!data?.[0]?.value) return;
    if (this.scanned) return;
    this.scanned = true;
    this.closeDialog(data[0].value);
  }

  closeDialog(data = null) {
    this.qrScanner.stop();
    this._ref.close({
      value: data
    });
  }

  ngOnInit(): void {
    this._ref.afterClosed().subscribe(result => {
      this.closeDialog();
    })
  }

  ngAfterViewInit() {
    this.qrScanner.isReady.subscribe(() => {
      this.handle(this.qrScanner, 'start');
    });
    this.scanned = false;
  }

  handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const lastCamera = localStorage.getItem('qrScannerDevice'); // Default Camera
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(lastCamera ?? device?.deviceId ?? devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => console.log(fn, r), alert);
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), error => {
        this._config.showSnackbar('Konnte Befehl nicht ausführen, eventuell wird dieser von der Kamera nicht unterstützt!', 3000, 'error')
      });
    }
  }

  loadDefaultDevice(devices: ScannerQRCodeDevice[]) {
    let index: string = localStorage.getItem('qrScannerDevice');
    
    
    try {
      if (!index) {
        index = devices[0].deviceId;
      }

      console.log(index);
      

      this.qrScanner.playDevice(index);
    } catch (e) { }
  }

  changeDevice() {
    const devices = this.qrScanner.devices.value;

    let index = this.qrScanner.deviceIndexActive;

    if (index >= devices.length - 1) {
      index = 0;
      console.log('resetting to zero');

    } else index++;
    this.qrScanner.playDevice(devices[index].deviceId);
    localStorage.setItem("qrScannerDevice", devices[index].deviceId);
    console.log(devices[index].deviceId);
    
  }

  ngOnDestroy() {
    this.qrScanner.stop();
  }
}
