import { Component, OnInit } from '@angular/core';
import { ConfigModule } from '../../config';

@Component({
  selector: 'app-network-error',
  templateUrl: './network-error.component.html',
  styleUrls: ['./network-error.component.css'],
})
export class NetworkErrorComponent implements OnInit {

  constructor(private _configService: ConfigModule ) { }

  ngOnInit() {
    this._configService.setTitle('403');
  }
}
