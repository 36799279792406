<div class="container">
  <div class="col-lg-10 col-12 offset-lg-1">
    <h3 class="section-header">Anstehende Prüfungen</h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="../../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche">
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-12">
            <div class="table-responsive">
              <div *ngIf="isLoading">
                <mat-progress-bar mode="query"></mat-progress-bar>
              </div>
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 input-full-width"
                matSortActive="date" matSortDirection="asc" matSortDisableClear>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    {{element.name}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="materialCategory">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategorie</th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    {{element.materialCategory}}</td>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="device">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Gerät / Fahrzeug</th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    <span *ngIf="element.vehicle"><mat-icon class="me-2 d-lg-inline-block d-none" style="margin-bottom: -8px">fire_truck</mat-icon> {{ element.vehicle }}</span>
                    <span *ngIf="element.material"><mat-icon class="me-2 d-lg-inline-block d-none" style="margin-bottom: -8px">category</mat-icon> {{ element.material }}</span>
                  </td>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Fällig am</th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]" [matTooltip]="dayjs(element.date).format('dddd DD.MM.YYYY')">
                    <span *ngIf="element.nextCycle == 0" class="mat-error">Heute!</span>
                    <span *ngIf="element.nextCycle" [class.mat-error]="element.nextCycle < 0">
                      {{ dayjs(element.date).fromNow() }}
                    </span>
                  </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                  <td mat-cell *matCellDef="let element" routerLink="{{element.id}}">

                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #menu>
  <div class="clearfix">
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-nav-list class="mt-4">
    <mat-list-item routerLink="new" (click)="_bottomSheet.dismiss()">
      <mat-icon matListItemIcon>post_add</mat-icon>
      <a matListItemLine>Neues Material erstellen</a>
    </mat-list-item>
  </mat-nav-list>
</ng-template>