<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Einsatzbestätigungen</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>
        <div class="col-12" *ngIf="!alarmListLoading">
          <mat-form-field class="input-full-width" appearance="outline">
            <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchFilter" placeholder="Suche">
          </mat-form-field>
        </div>
        <div class="table-responsive">
          <div *ngIf="alarmListLoading">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
          <table mat-table [dataSource]="alarm" matSort class="mat-elevation-z8 input-full-width" matSortActive="date"
            matSortDirection="desc" matSortDisableClear>

            <!-- date Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px; vertical-align: middle;">
                Einsatzzeit
              </th>
              <td mat-cell *matCellDef="let element" (click)="openAlarm(element)">
                {{getFormattedDateTime(element.date, element.alarmTime)}}</td>
            </ng-container>

            <!-- Keyword Column -->
            <ng-container matColumnDef="keyword">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 300px; vertical-align: middle;">
                Stichwort
              </th>
              <td mat-cell *matCellDef="let element" (click)="openAlarm(element)">
                {{element.keyword }}</td>
            </ng-container>

            <!-- action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px; vertical-align: middle;">
              </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="Einsatzbestätigung herunterladen" [disabled]="alarmListLoading"
                  (click)="openPDF(element.id)">
                  <mat-icon>file_download</mat-icon>
              </button>

                <button mat-icon-button matTooltip="Einsatzbestätigung per E-Mail versenden"
                  [disabled]="alarmListLoading" (click)="send(element)">
                  <mat-icon>send</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="alarmColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: alarmColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons>
        </mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #menu>
  <div class="clearfix">
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-nav-list class="mt-4">
    <mat-list-item routerLink="export" (click)="_bottomSheet.dismiss()">
      <h4 matListItemLine>Mehrere Bestätigungen exportieren</h4>
      <div matListItemMeta>
        <mat-icon>post_add</mat-icon>
      </div>
    </mat-list-item>
  </mat-nav-list>
</ng-template>