import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { Login } from 'src/app/util/login';

@Component({
  selector: 'app-admin-user-menusheet',
  templateUrl: './admin-user-menusheet.component.html',
  styleUrls: ['./admin-user-menusheet.component.css'],
})
export class AdminUserMenusheetComponent implements OnInit {

  main: boolean;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) private data: any, private _login: Login, public _bottomSheet: MatBottomSheet) {
    this.userID = data.userID;
    this.name = data.name;
  }

  ngOnInit() {
    let i = 0;
    this.links.forEach(element => {
      if (this._login.hasPermission(element.view_permission)) {
        this.linksorted[i] = element;
        i++;
      }
    });
    this.main = document.location.pathname.startsWith('/admin/user/edit/');
  }

  userID: number;

  name: string;

  env = environment;

  linksorted: any = [];

  links = [{ name: 'Stammdaten', url: 'masterdata', icon: 'account_circle', view_permission: 'admin-user-view' },
    { name: 'Feuerwehrdaten', url: 'firedata', icon: 'fireplace', view_permission: 'admin-user-firedata-view' },
    { name: 'Rechte / Gruppen', url: 'security', icon: 'security', view_permission: 'admin-user-security-view' },
    { name: 'Atemschutz', url: 'atemschutz', icon: 'fire_extinguisher', view_permission: 'admin-user-atemschutz-view' },
    { name: 'Maschinisten', url: 'driving', icon: 'drive_eta', view_permission: 'admin-user-driving-view' },
    { name: 'Laufbahnereignisse', url: 'courses', icon: 'local_hospital', view_permission: 'admin-user-course-view' },
    { name: 'Notfallkontakte', url: 'emergencycontacts', icon: 'contact_emergency', view_permission: 'admin-emergencycontact-list' }];
}