<div class="container">
  <div class="col-lg-10 col-12 offset-lg-1">
    <h3 class="section-header">Fahrzeuge</h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>

        <div class="row">
          <div class="col-lg-8 col-md-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mat-form-field appearance="outline" class="input-full-width">
              <mat-label>Fahrzeugtyp</mat-label>
              <mat-select (selectionChange)="changeFilter($event)" multiple>
                <mat-option [value]="[1,2,3]">Löschfahrzeuge</mat-option>
                <mat-option [value]="[5]">Drehleiter</mat-option>
                <mat-option [value]="[6, 7]">Wechsellader</mat-option>
                <mat-option [value]="[16, 17]">Anhänger</mat-option>
                <mat-option [value]="[13]">Gabelstapler</mat-option>
                <mat-option [value]="[15]">Abroller</mat-option>
                <mat-option [value]="[7,8,9,10,11,12]">Kleinfahrzeuge</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="table-responsive">
          <div *ngIf="isLoading">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
          <table mat-table [dataSource]="dataSource" matSort class="input-full-width" matSortActive="name"
            matSortDirection="asc" matSortDisableClear>
            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 30px; padding-right: 15px"> Typ
              </th>
              <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                <mat-icon svgIcon="loeschfahrzeug" *ngIf="[1, 2, 3].includes(element.cartype)"></mat-icon>
                <mat-icon svgIcon="drehleiter" *ngIf="element.cartype == 5"></mat-icon>
                <mat-icon svgIcon="wechsellader" *ngIf="(6 || 7) === element.cartype"></mat-icon>
                <mat-icon svgIcon="anhaenger" *ngIf="(16 || 17) === element.cartype"></mat-icon>
                <mat-icon svgIcon="gabelstapler" *ngIf="element.cartype == 13"></mat-icon>
                <mat-icon svgIcon="abroller" *ngIf="element.cartype == 15"></mat-icon>
                <mat-icon *ngIf="[7, 8, 9, 10, 11, 12].includes(element.cartype)">local_taxi</mat-icon>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 200px; padding-right: 15px">
                Fahrzeugname
              </th>
              <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]"> {{element.name}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 30px">
                Fahrzeugstatus
              </th>
              <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]"> {{
                getIconByNumber(element.status) }} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="check">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 100px; padding-right: 15px">
                Nächster Prüftermin
              </th>
              <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                <span *ngIf="element.nextCycle" [class.mat-error]="element.nextCycle < 0" [matTooltip]="dayjs(element.cycle).format('dddd, DD.MM.YYYY HH:mm') + ' Uhr'">
                  {{ dayjs(element.cycle).fromNow() }}
                </span>
              </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">

              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #menu>
  <div class="clearfix">
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-nav-list class="mt-4">
    <mat-list-item routerLink="new" (click)="_bottomSheet.dismiss()">
      <mat-icon matListItemIcon>post_add</mat-icon>
      <a matListItemLine>Neues Fahrzeug erstellen</a>
    </mat-list-item>
  </mat-nav-list>
</ng-template>
