<div class="text-center">
    <br>
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
        <mat-icon>close</mat-icon>
    </a>
    <h3>{{name}}</h3>
    <mat-nav-list>
        <mat-list-item *ngFor="let link of linksorted" routerLink="admin/user/edit/{{userID}}/{{link.url}}">
            <a matListItemTitle>
                <div class="clearfix pb-1">
                    <span class="float-start mt-1">{{ link.name }}</span>
                    <mat-icon class="float-end">{{link.icon}}</mat-icon>
                </div>
            </a>
        </mat-list-item>
        <mat-list-item *ngIf="main" routerLink="admin/user">
            <a matListItemTitle>
                <div class="clearfix pb-1">
                    <span class="float-start mt-1">zurück zur Übersicht</span>
                    <mat-icon class="float-end">menu</mat-icon>
                </div>
            </a>
        </mat-list-item>
    </mat-nav-list>
</div>