import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs';

@Injectable()
export class PromptUpdateService {

  constructor(updates: SwUpdate) {
    updates.versionUpdates.pipe(filter(up => up.type === 'VERSION_READY')).subscribe(() => {
      document.location.reload();
    });
  }
}