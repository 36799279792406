<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">Einsätze</h3>
        <mat-card>
            <mat-card-content>
                <a routerLink="./new" mat-mini-fab color="primary" class="pageNext">
                    <mat-icon>post_add</mat-icon>
                </a>

                <div class="row mt-4">
                    <div class="col-lg-8 col-md-6 col-12">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchFilter"
                                placeholder="Suche">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <mat-label>Filter</mat-label>
                            <mat-select multiple (selectionChange)="loadAlarmSelectedFilter()" [(ngModel)]="alarmFilter">
                                <mat-option value="offen">Offen</mat-option>
                                <mat-option value="in Bearbeitung">In Bearbeitung</mat-option>
                                <mat-option value="in Kontrolle:Kontrolle abgeschlossen">In Kontrolle</mat-option>
                                <mat-option value="Rechnungsfreigabe"
                                    *ngIf="_login.hasPermission('alarm-release-invoice') || _login.hasPermission('alarm-view-admin')">
                                    Rechnungsfreigabe</mat-option>
                                <mat-option value="Rechnungserstellung:Rechnung erstellt:Rechnung versendet:ohne Rechnung" *ngIf="_login.hasPermission('alarm-view-admin')">Abgeschlossen
                                </mat-option>
                                <mat-option value="zen" *ngIf="_login.hasPermission('alarm-zen')">zEN nicht abgeschlossen
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 col-12">
                        <mat-progress-bar mode="query" *ngIf="loading" color="primary"></mat-progress-bar>
                        <div class="table-responsive">
                            <table mat-table [dataSource]="alarm" matSort class="mat-elevation-z8" matSortActive="date"
                                matSortDirection="desc" matSortDisableClear>

                                <!-- date Column -->
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="width: 200px; vertical-align: middle;">Einsatzzeit
                                    </th>
                                    <td mat-cell *matCellDef="let element" (click)="openAlarm(element)">
                                        {{getFormattedDateTime(element.date, element.time)}}</td>
                                </ng-container>

                                <!-- Keyword Column -->
                                <ng-container matColumnDef="keyword">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="width: 300px; vertical-align: middle;">Stichwort
                                    </th>
                                    <td mat-cell *matCellDef="let element" (click)="openAlarm(element)">
                                        {{element.keyword }}</td>
                                </ng-container>

                                <!-- address Column -->
                                <ng-container matColumnDef="address">
                                    <th mat-header-cell *matHeaderCellDef style="padding-right: 20px">
                                        Einsatzadresse
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="padding-right: 30px"><a
                                            target="_blank"
                                            href="https://www.google.com/maps/search/?api=1&query={{ element.zipcode + ' ' + element.city + ' ' + element.street + ' ' + element.housenumber }}">
                                            {{element.street }} {{element.housenumber }} <br /> {{element.city }}</a>
                                    </td>
                                </ng-container>

                                <!-- address Column -->
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="width: 200px; vertical-align: middle;">
                                        Status
                                    </th>
                                    <td mat-cell *matCellDef="let element" (click)="openAlarm(element)">
                                        {{ element.status === 'offen' ? 'Offen' : '' }}
                                        {{ element.status === 'in Bearbeitung' ? 'In Bearbeitung' : '' }}
                                        {{ element.status === 'in Kontrolle' ? 'In Kontrolle' : '' }}
                                        {{ element.status === 'Rechnungsfreigabe' ? 'Rechnungsfreigabe' : '' }}
                                        {{ element.status === 'Rechnungserstellung' ? 'Rechnung wird erstellt' : '' }}
                                        {{ element.status === 'Rechnung erstellt' ? 'Rechnung wurde erstellt' : '' }}
                                        {{ element.status === 'Rechnung versendet' ? 'Rechnung verschickt' : '' }}
                                        {{ element.status === 'ohne Rechnung' ? 'Abgeschlossen (Ohne Rechnung)' : '' }}
                                    </td>
                                </ng-container>

                                <!-- action Column -->
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef style="width: 200px; vertical-align: middle;">
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-icon-button matTooltip="Einsatz löschen"
                                            *ngIf="element.status === 'offen' && _login.hasPermission('alarm-delete')"
                                            (click)="deleteAlarm(element)" color="warn">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        <button mat-icon-button
                                            [matTooltip]="element.reviewerNotes ? 'Notiz bearbeiten' : 'Notiz hinzufügen'"
                                            *ngIf="element.status==='in Kontrolle' && (element.creator===_login.getUserID() || element.leader===_login.getUserID())"
                                            (click)="addOrLoadNotes(element)"
                                            [color]="element.reviewerNotes ? 'primary' : 'black'">
                                            <mat-icon>note_add</mat-icon>
                                        </button>
                                        <a mat-icon-button matTooltip="Bericht herunterladen"
                                            *ngIf="element.status !== 'offen' && element.status !== 'in Bearbeitung' && _login.hasPermission('alarm-generate-report')"
                                            target="_blank"
                                            href="{{ baseURL }}alarms/report/{{element.id}}/generate?sessionToken={{ _login.getSessionToken() }}">
                                            <mat-icon>assignment</mat-icon>
                                        </a>
                                        <a mat-icon-button matTooltip="Rechnung herunterladen"
                                            *ngIf="element.status !== 'offen' && element.status !== 'in Bearbeitung' && element.status !== 'in Kontrolle' && element.status !== 'Rechnungsfreigabe' && element.status !== 'ohne Rechnung' && _login.hasPermission('alarm-generate-invoice')"
                                            target="_blank"
                                            href="{{ baseURL }}alarms/invoice/{{element.id}}/generate?sessionToken={{ _login.getSessionToken() }}">
                                            <mat-icon>file_download</mat-icon>
                                        </a>
                                        <img class="avatarIcon float-right mt-2" [src]="element.openedBy_avatar"
                                            *ngIf="element.openedBy && element.openedBy!==0"
                                            matTooltip="Dieser Einsatz ist gerade von {{element.openedBy_name}} geöffnet und ist für die weitere Bearbeitung gesperrt!" />
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="alarmColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: alarmColumns;"></tr>
                            </table>
                        </div>
                        <div class="mt-4 row" *ngIf="alarmListLoading">
                            <div class="col-3">
                                <ngx-skeleton-loader count="3" [theme]="{ 
                                height: '60px'
                              }"></ngx-skeleton-loader>
                            </div>
                            <div class="col-4">
                                <ngx-skeleton-loader count="3" [theme]="{ 
                                height: '60px'
                              }"></ngx-skeleton-loader>
                            </div>
                            <div class="col-2">
                                <ngx-skeleton-loader count="3" [theme]="{ 
                                height: '60px'
                              }"></ngx-skeleton-loader>
                            </div>
                            <div class="col-3">
                                <ngx-skeleton-loader count="3" [theme]="{ 
                                height: '60px'
                              }"></ngx-skeleton-loader>
                            </div>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons
                            [hidden]="alarmListLoading"></mat-paginator>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>


<ng-template #menu>
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
        <mat-icon>close</mat-icon>
    </a>
    <mat-nav-list style="padding-right: 40px;">
        <a routerLink="./new" mat-list-item (click)="_bottomSheet.dismiss()">
            <mat-icon matListItemIcon>post_add</mat-icon>
            <div matListItemTitle>Neuen Einsatz anlegen</div>
        </a>
    </mat-nav-list>
</ng-template>