import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom, filter } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-material-locations',
  templateUrl: './material-locations.component.html',
  styleUrls: ['./material-locations.component.css']
})
export class MaterialLocationsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['name', 'action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  isLoading: boolean = true;

  constructor(private _http: HttpClient,
    private _config: ConfigModule,
    public _bottomSheet: MatBottomSheet) {} 

  ngOnInit(): void {
    this.loadData();
    this._config.setTitle('Lagerorte');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  async loadData() {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/locations`));
      this.dataSource.data = result;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    } catch (e) {      
      this._config.showSnackbar('Fehler beim Laden der Lagerorte', 2500, 'error');
      this.isLoading = false;
    }
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu);
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }
}
