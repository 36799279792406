<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Mitgliederinfo</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a mat-mini-fab routerLink="/profile/" color="primary" class="pageNext" *ngIf="env.userID === userID">
          <mat-icon>account_box</mat-icon>
        </a>
        <a mat-mini-fab color="primary" class="pageNext" (click)="openMenu(userInfo.id, userInfo.name)"
          *ngIf="env.userID !== userID && _login.hasPermission('admin-user-view')">
          <mat-icon>edit</mat-icon>
        </a>
        <div class="row" *ngIf="showLoader">
          <div class="col-lg-4 offset-lg-1">
            <ngx-skeleton-loader appearance="circle" class="avatarIcon" [theme]="{
            height: '150px',
            width: '150px'
          }"></ngx-skeleton-loader>
          </div>
          <div class="col-lg-7">
            <div class="mt-5">
              <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="row" [hidden]="showLoader">
          <div class="col-lg-4 offset-lg-1">
            <img class="avatarIcon" [src]="userInfo.avatar" /><br /><br />
          </div>
          <div class="col-lg-7 text-center">
            <h2><mat-icon>receipt_long</mat-icon> {{ userInfo.id }}</h2>
            <h3 class="text-center">{{ userInfo.name }}</h3><br />

            <h5>Kontaktinformationen <a
                href="{{ baseURL }}user/vcard?userID={{ userID }}&sessionToken={{ _login.getSessionToken() }}"
                target="_blank">
                <mat-icon class="left-icon">cloud_download</mat-icon>
              </a></h5>
            <div *ngIf="_login.hasPermission('admin-user-view')">
              <span>{{ userInfo.street }} {{ userInfo.appartment
                }}</span><br />
              <span>{{ userInfo.zipcode + " " + userInfo.city
                }}</span><br />
              <a target="_blank"
                href="https://www.google.com/maps/search/?api=1&query={{ userInfo.zipcode + ' ' + userInfo.city + ' ' + userInfo.street + ' ' + userInfo.appartment }}"><mat-icon
                  class="left-icon">map</mat-icon> In Maps öffnen</a><br /><br />
            </div>
            <div *ngIf="userInfo.mobile" class="mb-2">
              <mat-icon class="left-icon">smartphone</mat-icon> <a href="tel:{{ userInfo.mobile }}">{{ userInfo.mobile
                }}</a><br />
            </div>
            <div *ngIf="userInfo.homephone" class="mb-2">
              <mat-icon class="left-icon">call</mat-icon> <a href="tel:{{ userInfo.homephone }}">{{ userInfo.homephone
                }}</a><br />
            </div>
            <div *ngIf="userInfo.email" class="mb-2">
              <mat-icon class="left-icon">alternate_email</mat-icon> <a href="mailto:{{ userInfo.email }}">{{
                userInfo.email }}</a><br />
            </div>
            <div *ngIf="userInfo.ffoMail" class="mb-2">
              <mat-icon class="left-icon">contact_mail</mat-icon> <a href="mailto:{{ userInfo.ffoMail }}">{{
                userInfo.ffoMail }}</a><br />
            </div>
            <hr *ngIf="_login.hasPermission('admin-emergencycontact-list') || userID === env.userID" />
            <div class="emergencycontacts"
              *ngIf="_login.hasPermission('admin-emergencycontact-list') || userID === env.userID">
              <h5>Notfallkontakte <mat-icon class="left-icon">contact_emergency</mat-icon>
              </h5>
              <div *ngIf="emergencyContactList.length === 0">
                <span>Keine Notfallkontakte hinterlegt!</span>
              </div>
              <div *ngIf="emergencyContactList.length !== 0">
                <mat-accordion class="expansion-header">
                  <mat-expansion-panel *ngFor="let contact of emergencyContactList">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <h6>{{ contact.name }}</h6>
                      </mat-panel-title>
                      <mat-panel-description>
                        {{ contact.relationship }}
                      </mat-panel-description>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                      <div class="row">
                        <div class="col-lg-4" *ngIf="contact.phone">
                          <mat-icon class="left-icon">call</mat-icon> <a href="tel:{{ contact.phone }}">{{ contact.phone
                            }}</a><br />
                        </div>
                        <div class="col-lg-7 offset-lg-1" *ngIf="contact.email">
                          <mat-icon class="left-icon">alternate_email</mat-icon> <a href="mailto:{{ contact.email }}">{{
                            contact.email
                            }}</a><br />
                        </div>
                      </div>
                    </ng-template>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>