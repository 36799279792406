import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Login } from '../../util/login';
import { HttpClient } from '@angular/common/http';
import { getHostUrl, ConfigModule } from '../../util/config';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-guards',
  templateUrl: './guards.component.html',
  styleUrls: ['./guards.component.css'],
})
export class GuardsComponent implements OnInit, OnDestroy, AfterViewInit {

  env = environment;

  matTabHeader: string = 'top';

  matTabIndex = 0;

  users: any;

  events: any;

  guardEventsAll: any;

  technicEventsAll: any;

  guardEvents: any;

  technicEvents: any;

  globalSort = 1;

  drawerPinned: any;

  constructor(public _login: Login,
    private _http: HttpClient,
    private _configService: ConfigModule,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this._configService.setTitle('Sicherheitswachen');
    this.loadData();
    this.fetchDatainfo();
    if (this.env.mobile) {
      this.matTabHeader = 'below';
      $('.mat-tab-header').addClass('footer');
    }
  }

  ngAfterViewInit() {
    this.drawerPinned = localStorage.getItem('drawerPinned');
    $('.mat-sidenav-content').scroll(this.scrollHandler);
    const eventID = this._route.snapshot.params.eventId;
    if (eventID) {
      if (eventID.startsWith('s')) {
        this.matTabIndex = 0;
      } else {
        this.matTabIndex = 1;
      }
    }
  }

  ngOnDestroy() {
    $('.mat-sidenav-content').off('scroll', this.scrollHandler);
  }

  scrollHandler = (event): void => {
    var offsetTop = $('mat-toolbar')[0].offsetHeight;
    if (event.target.scrollTop > (offsetTop + $('h3').height()) && !this.env.mobile) {
      $('.mat-tab-header').addClass('sticky');
      $('.mat-tab-header').offset({ top: offsetTop });
      $('.mat-tab-header').css({ 'z-index': 100 });
    } else {
      $('.mat-tab-header').removeClass('sticky');
      $('.mat-tab-header').removeAttr('style');
    }
  };

  onTabChanged($event) {
    if ($event.index === 0) {
      this._configService.setTitle('Sicherheitswachen');
    }
  }

  loadData() {
    this._http.get<any>(getHostUrl() + 'guards').subscribe(data => {
      this.events = data.eventData;
      this.globalSort = data.globalSort;
      this.filterEvents();
      this.filterGuardEvents(true);
    });
  }

  async fetchDatainfo() {
    this.users = await this._http.get(`${getHostUrl()}user/all`).toPromise();
  }

  filterEvents() {
    var filterdTechnic = [];
    this.guardEventsAll = this.events;
    this.technicEventsAll = filterdTechnic;
  }

  filterGuardEvents(userFilter) {
    var filterd = [];
    this.guardEventsAll.forEach(element => {
      if (userFilter) {
        if (element.guards[0] === this.env.userID || element.guards[1] === this.env.userID || element.guards[2] === this.env.userID || element.guards[3] === this.env.userID || element.guards[4] === this.env.userID || element.guards[5] === this.env.userID)
          filterd.push(element);
      } else {
        filterd.push(element);
      }
    });
    this.guardEvents = of(filterd);
  }

  findUserByID(id) {
    if (this.users !== undefined)
      return this.users.filter(user => user.id === id)[0];
    return undefined;
  }

  getUser(userID) {
    let user = this.findUserByID(userID);
    if (user !== undefined)
      if (this.globalSort === 0) {
        return (`${user.lastname}<br />${user.firstname}`);
      } else {
        return (`${user.firstname}<br />${user.lastname}`);
      }
    return '';
  }
}
