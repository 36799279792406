<form [formGroup]="group" class="row">
    <div class="col-lg-6 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Weitere anwesende Organisationen</mat-label>
            <mat-select multiple formControlName="otherOrg">
                <mat-option>
                    <ngx-mat-select-search [formControl]="otherOrgFilterCtrl" id="org_search"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let otherOrg of otherOrgFilter | async"
                [value]="otherOrg.id.toString()"
                (click)="orgReset()">
                {{ otherOrg.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-6 col-12" [hidden]="alarmType !== 'technic'">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Schadensobjekt</mat-label>
            <mat-select multiple formControlName="damageObj">
                <mat-option>
                    <ngx-mat-select-search [formControl]="damageObjFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let damageObj of damageObjFilter | async" [value]="damageObj.id.toString()">{{
                    damageObj.type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-6 col-12" [hidden]="alarmType !== 'technic'">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Wasserentnahme</mat-label>
            <mat-select multiple formControlName="waterIntake">
                <mat-option>
                    <ngx-mat-select-search [formControl]="waterIntakeFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let waterIntake of waterIntakeFilter | async" [value]="waterIntake.id.toString()">{{
                    waterIntake.type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-12 col-lg-6 col-md-6">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Einsatzleiter</mat-label>
            <mat-select formControlName="leader">
                <mat-option [hidden]="leaderList.length === 0">
                    <ngx-mat-select-search [formControl]="leaderFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option disabled="true" *ngIf="leaderList.length === 0">Keine Personen dem Einsatz zugeordnet!</mat-option>
                <mat-option *ngFor="let user of leaderFilter | async" [value]="user.id">{{ user.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!group.get('leader').valid || leaderList.length===0">
                <span *ngIf="leaderList.length===0">Es sind keine Personen dem Einsatz zugeordnet!</span>
                <span *ngIf="!group.get('leader').valid && leaderList.length!==0">Einsatzleiter muss ausgewählt
                    werden!</span>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-12 mb-4">
        <button mat-raised-button color="accent" class="input-full-width" style="height: 62px" (click)="openDamage()" [color]="group.get('medical') && group.get('medical').invalid && alarmType === 'medical' ? 'warn' : 'accent'">
            <span>Medizinische Daten </span>
            <span class="d-inline" *ngIf="!disabledElements">bearbeiten</span>
            <span class="d-inline" *ngIf="disabledElements">ansehen</span>
            <mat-icon style="margin-left: 5px;">health_and_safety</mat-icon>
        </button>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Vorschlag für Rechnung</mat-label>
            <mat-select formControlName="invCategory" required>
                <mat-option>
                    <ngx-mat-select-search [formControl]="invCategoryFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let invCategory of invCategoryFilter | async" [value]="invCategory.id">
                    {{ invCategory.name }} {{ invCategory.isPriceable ? "(&euro;)" : '' }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="!group.get('invCategory').valid">
                Vorschlag für Rechnung muss ausgewählt werden!
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-2 col-6">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Kostenpflichtig</mat-label>
            <mat-select required formControlName="isPriceable">
                <mat-option value="0">Nein</mat-option>
                <mat-option value="1">Ja</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-6">
        <button mat-raised-button class="input-full-width" style="height: 62px"
            [disabled]="!isPricable" [color]="getInvoiceColor()" (click)="openInvoice()">
            <span>Rechnung </span>
            <span class="d-inline" *ngIf="!disabledElements">bearbeiten</span>
            <span class="d-inline" *ngIf="disabledElements">ansehen</span>
            <mat-icon>local_post_office</mat-icon>
        </button>
    </div>
</form>

<ng-template #recommendations>
    <h2 mat-dialog-title style="text-align: center;">Vorschlag<br>anwesende Organisationen</h2>
    <mat-dialog-content>
        <form [formGroup]="group_recommendation">
            <table id=recommendation>
                <tr formArrayName="organization" *ngFor="let org of recommendation_orgs; let i = index">
                    <td [for]="org">{{getOrganizationName(org)}}</td>
                    <td>
                        <mat-slide-toggle [id]="org" [formControlName]="i">
                        </mat-slide-toggle>
                    </td>
                </tr>
            </table>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button mat-dialog-close (click)="setRecommendation()" color="success" class="float-right">
            übernehmen <mat-icon>done</mat-icon>
        </button>
    </mat-dialog-actions>
</ng-template>