import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { filter } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import Swal from 'sweetalert2';
import { AdminSettingsCronjobsModalComponent } from './admin-settings-cronjobs-modal/admin-settings-cronjobs-modal.component';
import { AdminSettingsJobHistoryComponent } from './admin-settings-job-history/admin-settings-job-history.component';

@Component({
  selector: 'app-admin-settings-cronjobs',
  templateUrl: './admin-settings-cronjobs.component.html',
  styleUrls: ['./admin-settings-cronjobs.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],

})
export class AdminSettingsCronjobsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  displayedColumns: string[] = ['name', 'lastrun', 'crontab', 'status', 'actions'];

  dataSource = new MatTableDataSource();

  dayjs = dayjs;

  showLoader = true;

  constructor(private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet,
    private _dialog: MatDialog,
    private _http: HttpClient) { }

  ngOnInit(): void {
    this._config.setTitle('Einstellungen | CronJobs');
    this.loadData();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu, { disableClose: true });
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }

  loadData() {
    this._http.get<any>(`${getHostUrl()}admin/settings/general/cronjobs`).subscribe(result => {
      this.showLoader = false;
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  playJob(element) {
    Swal.fire({
      title: 'Ausführen?',
      html: `Soll der Job: "<b>${element.name}</b>" jetzt ausgeführt werden?`,
      confirmButtonText: 'Ja',
      denyButtonText: 'Nein',
      showDenyButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      icon: 'question',
    }).then(swalResult => {
      if (swalResult.isConfirmed) {
        this.showLoader = true;
        this._http.post<any>(`${getHostUrl()}admin/settings/general/cronjobs/execute/${element.id}`, {}).subscribe(result => {
          this.showLoader = false;
          this._config.showSnackbar('Job wurde in die Warteschlange gelegt und wird in Kürze ausgeführt!', 3000, 'success');
        }, error => {
          this.showLoader = false;
          this._config.showSnackbar('Ein Fehler ist beim ausführen des Jobs aufgetreten!', 3000, 'error');
        });
      }
    });
  }

  deleteJob(element) {
    Swal.fire({
      title: 'Löschen?',
      html: `Soll der Job: "<b>${element.name}</b>" gelöscht werden?`,
      confirmButtonText: 'Ja',
      denyButtonText: 'Nein',
      showDenyButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      icon: 'question',
    }).then(swalResult => {
      if (swalResult.isConfirmed) {
        this.showLoader = true;
        this._http.delete<any>(`${getHostUrl()}admin/settings/general/cronjobs/${element.id}`).subscribe(result => {
          this.loadData();
          this._config.showSnackbar('Job erfolgreich gelöscht!', 3000, 'success');
        }, error => {
          this.showLoader = false;
          this._config.showSnackbar('Ein Fehler ist beim löschen des Jobs aufgetreten!', 3000, 'error');
        });
      }
    });
  }

  editCronjob(element) {
    this._bottomSheet.dismiss();
    this._dialog.open(AdminSettingsCronjobsModalComponent, {
      data: element,
      width: '1000px',
      height: '600px',
    }).afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  toggleCronjob($event, element) {
    element.enabled = $event.checked ? 1 : 0;
    this._http.get(`${getHostUrl()}admin/settings/general/cronjobs/${element.id}/toggle/${element.enabled}`).subscribe(result => {}, 
      error => {
        this._config.showSnackbar('Ein Fehler ist bei der Statusänderung des Jobs aufgetreten!', 3000, 'error');
      });
  }

  openJobHistory() {
    this._bottomSheet.dismiss();
    this._dialog.open(AdminSettingsJobHistoryComponent, {
      width: '1000px',
      height: '600px',
    }).afterClosed().subscribe(result => {
      this.loadData();
    }); 
  }
}
