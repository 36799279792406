import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigModule, getHostUrl } from '../../util/config';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../util/login';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import dayjs from 'dayjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';

interface Licence {
  id: number,
  licenceClassID: number,
  licenceClass: string,
  expiryDate: Date,
  briefingDate: Date,
  briefingDateBlue: Date,
  permission: number[],
  permissionBlue: number[],
  trailer: boolean | number
}

interface Car {
  id: number,
  licenceClass: string,
  type: string
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {

  @ViewChild('stammdaten') stammdaten: MatExpansionPanel;

  @ViewChild('firedata') firedata: MatExpansionPanel;

  @ViewChild('atemschutz') atemschutz: MatExpansionPanel;

  @ViewChild('material') material: MatExpansionPanel;

  @ViewChild('maschinisten') maschinisten: MatExpansionPanel;

  @ViewChild('emergencycontacts') emergencycontacts: MatExpansionPanel;

  env = environment;

  constructor(private _config: ConfigModule,
    private _http: HttpClient,
    public _login: Login,
    private _route: ActivatedRoute,
    private _router: Router,
    public _spinner: NgxSpinnerService) { }

  userInfo: any = {};

  settingsObj: any = {};

  showToken: Boolean = false;

  age18: boolean;

  age18Date: Date;

  alarmLinesList: any;

  rankList: any;

  countryList: any;

  cityList: any;

  streetList: any;

  carTypesList: any;

  ngOnInit() {
    if (this._route.snapshot.params.section === 'settings')
      this._router.navigate(['/settings']);
    this._config.setTitle('Mein Profil');
    this.fetchData();
    this.fetchDatainfo();
  }

  fetchData() {
    
    this.fetchMasterData();
    this.fetchFireData();
    this.fetchAtemschutzData();
    this.fetchMaterialData();
    this.fetchDrivingData();
    this.fetchEmergenyData();
    this._spinner.hide();
  }

  fetchMasterData() {
    this._http.get<any>(`${getHostUrl()}profile`).subscribe(data => {
      this.userInfo.masterdata = data;
      this.age18Date = dayjs(data.birthdate).add(17, 'years').toDate();
      this.age18 = dayjs(data.birthdate).add(17, 'years').isBefore(dayjs());
      this.userInfo.masterdata.birthdate = this.getFormattedDate(this.userInfo.masterdata.birthdate);
      if (this._route.snapshot.params.section === 'masterdata')
        this.openPanel('masterdata');
    });
  }

  fetchFireData() {
    this._http.get<any>(`${getHostUrl()}profile/firedata`).subscribe(data => {
      this.userInfo.firedata = data;
      if (this._route.snapshot.params.section === 'firedata')
        this.openPanel('firedata');
    });
  }

  fetchMaterialData() {
    this._http.get<any>(`${getHostUrl()}profile/material`).subscribe(data => {
      this.userInfo.material = data;
      if (this._route.snapshot.params.section === 'material')
        this.openPanel('material');
    });
  }

  fetchAtemschutzData() {
    this._http.get<any>(`${getHostUrl()}profile/atemschutz`).subscribe(data => {
      this.userInfo.atemschutz = data;
      if (this._route.snapshot.params.section === 'atemschutz')
        this.openPanel('atemschutz');
    });
  }

  fetchEmergenyData() {
    this._http.get<any>(getHostUrl() + 'emergencycontact' + '?targetID=' + this.env.userID).subscribe(data => {
      this.userInfo.emergencyContacts = data;
      if (this._route.snapshot.params.section === 'emergencycontacts')
        this.openPanel('emergencycontacts');
    });
  }

  fetchDrivingData() {
    this._http.get<any>(getHostUrl() + 'profile/driving').subscribe(data => {
      this.userInfo.driving = data;
      this.userInfo.driving.licences.sort((a: Licence, b: Licence) => this.sortLicences(a, b));
      this.userInfo.driving.licences.forEach((licence: Licence) => {
        var licenceClass = licence.licenceClass;
        if (licenceClass.endsWith('E'))
          licenceClass = licenceClass.substring(0, licenceClass.length - 1);
        this._http.get<any>(getHostUrl() + 'profile/drivingPerm?licenceClass=' + licenceClass).subscribe(permData => {
          licence.permission = permData.permission;
          licence.permissionBlue = permData.permissionBlue;
          if (this._route.snapshot.params.section === 'driving')
            this.openPanel('driving');
        });
        if (licence.trailer === 0)
          licence.trailer = false;
        else
          licence.trailer = true;
      });
      if (this.userInfo.driving.dateOfIssue === '0000-00-00')
        this.userInfo.driving.dateOfIssue = null;
      if (this.userInfo.driving.dateOfExpiry === '0000-00-00')
        this.userInfo.driving.dateOfExpiry = null;
    });
  }

  async fetchDatainfo() {
    this.streetList = await this._http.get(`${getHostUrl()}datainfo/streets`).toPromise();
    this.alarmLinesList = await this._http.get(`${getHostUrl()}datainfo/alarmLines?pager=1`).toPromise();
    this.countryList = await this._http.get(`${getHostUrl()}datainfo/countries`).toPromise();
    this.cityList = await this._http.get(`${getHostUrl()}datainfo/cities`).toPromise();
    this.rankList = await this._http.get(`${getHostUrl()}datainfo/ranks?sex=${this.userInfo.masterdata.sex}`).toPromise();
    this.carTypesList = await this._http.get<Car[]>(`${getHostUrl()}datainfo/carTypes`).toPromise();
  }

  sortLicences(first: Licence, second: Licence) {
    if (first.licenceClass === 'Klasse B')
      return -1;
    else if (second.licenceClass === 'Klasse B')
      return 1;
    if (first.licenceClass === 'Klasse BE')
      return -1;
    else if (second.licenceClass === 'Klasse BE')
      return 1;
    if (first.licenceClass === 'Klasse C1')
      return -1;
    else if (second.licenceClass === 'Klasse C1')
      return 1;
    if (first.licenceClass === 'Klasse C')
      return -1;
    else if (second.licenceClass === 'Klasse C')
      return 1;
    if (first.licenceClass === 'Klasse CE')
      return -1;
    else if (second.licenceClass === 'Klasse CE')
      return 1;
    else return 0;
  }

  getFormattedDate(dateValue: string) {
    return dayjs(dateValue).format('DD.MM.YYYY');
  }

  openPanel(section: string) {
    switch (section) {
      case 'masterdata':
        this.stammdaten.open();
        break;
      case 'firedata':
        this.firedata.open();
        break;
        case 'material':
          this.material.open();
          break;
      case 'atemschutz':
        this.atemschutz.open();
        break;
      case 'driving':
        this.maschinisten.open();
        break;
      case 'emergencycontacts':
        this.emergencycontacts.open();
        break;
      default:
        break;
    }
  }
}
