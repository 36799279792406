import { Component, OnDestroy, OnInit } from '@angular/core';
import { getHostUrl, ConfigModule, replaceUmlaute } from '../../../util/config';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { FileUploadService } from 'src/app/util/services/fileUpload.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-notifications',
  templateUrl: './admin-notifications.component.html',
  styleUrls: ['./admin-notifications.component.css'],
})
export class AdminNotificationsComponent implements OnInit, OnDestroy {

  env = environment;
  constructor(private _http: HttpClient,
    private _config: ConfigModule,
    private uploadService: FileUploadService) { }

  notificationPayload: any = {};

  groups: any = [];
  users: any = [];

  files: any = [];
  size: number = 0;
  MAX_ATTACHMENT_SIZE: number = 25000000;

  attachments: any = [];
  progressInfos: any[] = [];
  uploading: boolean = false;

  loc = document.location.href;


  groupFilterCtrl: UntypedFormControl = new UntypedFormControl();
  groupFilter: ReplaySubject<any> = new ReplaySubject<any>(1);
  userFilterCtrl: UntypedFormControl = new UntypedFormControl();
  userFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected previousGroups: number[] = [];
  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    this.fetchGroups();
    this.fetchUsers();
    this._config.setTitle('Benachrichtigungen');
    this.notificationPayload.text = '';
    this.notificationPayload.users = [];
    this.groupFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterGroups();
      });
    this.userFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUser();
      });

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  sendNotification() {
    if (this.notificationPayload.text === '' || !this.notificationPayload.users
      || this.notificationPayload.users.length === 0 || !this.notificationPayload.subject) {
      this._config.showSnackbar('Bitte fülle alle Felder aus!', 2000, 'error');
      return;
    }

    this.notificationPayload.attachments = this.attachments;

    this._http.post<any>(`${getHostUrl()}admin/notifications/send`, {
      payload: this.notificationPayload,
    }).subscribe((data) => {
      this.notificationPayload.text = '';
      this.notificationPayload.subject = '';
      this.notificationPayload.attachments = [];
      this.notificationPayload.groups = [];
      this.notificationPayload.users = [];
      this.previousGroups = [];
      this.attachments = [];
      this.files = [];
      this.size = 0;
      this._config.showSnackbar('Benachrichtigung wurde versendet!', 2000, 'success');
    }, (error) => {
      this._config.showSnackbar('Es ist ein Fehler aufgetreten!', 2000, 'error');
    });
  }

  fetchUsers() {
    this._http.get<any>(`${getHostUrl()}user/active`).subscribe((data) => {
      this.users = data;
      this.userFilter.next(this.users.slice());
    });
  }

  fetchGroups() {
    this._http.get<any>(`${getHostUrl()}datainfo/groups`).subscribe((data) => {
      this.groups = data;
      this.groupFilter.next(this.groups.slice());
    });
  }

  checkUser(groups: MatSelectChange) {
    this.groupFilterCtrl.setValue('');
    const current = groups.value;
    if (this.previousGroups.length > current.length) {
      // group deleted
      const groupToDelete: number = this.previousGroups.filter((x: number) => !current.includes(x))[0];
      const usersToDelete: number[] = this.users.filter(user => user.groups.includes(groupToDelete))?.map(user => user?.id);
      const usersToAdd: number[] = this.users.filter(user => user.groups.some(group => current.includes(group)))?.map(user => user?.id);
      this.notificationPayload.users = this.notificationPayload.users.filter((userID: number) => !usersToDelete.includes(userID));
      this.notificationPayload.users = this.notificationPayload.users.concat(usersToAdd);
    } else {
      // group added
      const groupToAdd: number = current.filter((x: number) => !this.previousGroups.includes(x))[0];
      const usersToAdd: number[] = this.users.filter(user => user.groups.includes(groupToAdd))?.map(user => user?.id);
      this.notificationPayload.users = this.notificationPayload.users.concat(usersToAdd);
    }
    this.previousGroups = current;
  }

  clearUserSearch() {
    this.userFilterCtrl.setValue('');
  }

  uploadAttachments($event) {
    let fileList: FileList = $event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      this.upload(fileList.item(i));
    }
  }

  upload(file: File): void {
    const name = this.env.userID + '_' + replaceUmlaute(file.name);
    const i = this.progressInfos.push({ value: 0, fileName: name }) - 1;
    this.files.push(file);

    this.uploadService.upload(file, name).subscribe({
      next: (event: any) => {
        if (event instanceof HttpResponse) {
          this.progressInfos[i].value = 100;
          this.attachments.push(name);
          this.size += file.size;
          this.uploading = false;
        } else if (event.type = HttpEventType.UploadProgress) {
          this.progressInfos[i].value = Math.round(100 * event.loaded / event.total);
          this.uploading = true;
        }
      },
      error: (err: any) => {
        this.progressInfos.splice(i, 1);
        this.files.splice(i, 1);
        this._config.showSnackbar('Upload fehlgeschlagen - ' + file.name, 2000, 'error');
      }
    });
  }

  removeAttachment(index: number) {
    this.size -= this.files[index].size;
    this.attachments.splice(index, 1);
    this.files.splice(index, 1);
  }

  protected filterGroups() {
    if (!this.groups) {
      return;
    }

    // get the search templates
    let search = this.groupFilterCtrl.value;
    if (!search) {
      this.groupFilter.next(this.groups.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the templates
    this.groupFilter.next(
      this.groups.filter(template => template.name.toLowerCase().indexOf(search) > -1));
  }
  protected filterUser() {
    if (!this.users) {
      return;
    }

    // get the search templates
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.userFilter.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the templates
    this.userFilter.next(
      this.users.filter(template => template.name.toLowerCase().indexOf(search) > -1));
  }

  groupReset() {
    this.groupFilterCtrl.reset();
    $('#group_search').find('input').trigger('focus');
  }

  userReset() {
    this.userFilterCtrl.reset();
    $('#user_search').find('input').trigger('focus');
  }
}
