import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { IconPickerDialogComponent } from 'src/app/util/dialogs/icon-picker-dialog/icon-picker-dialog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-secretary-courses-categories-dialog',
  templateUrl: './secretary-courses-categories-dialog.component.html',
  styleUrls: ['./secretary-courses-categories-dialog.component.css']
})
export class SecretaryCoursesCategoriesDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public _data: any,
  private _builder: FormBuilder,
  private _dialog: MatDialog,
  private _ref: MatDialogRef<SecretaryCoursesCategoriesDialogComponent>,
  private _http: HttpClient,
  private _config: ConfigModule) { }

  group: FormGroup;

  ngOnInit(): void {    
    this.group = this._builder.group({
      category: ['', [Validators.required]],
      icon: ['', [Validators.required]]
    });
    if (this._data?.item != null) {
      this.group.patchValue(this._data.item);
    } else {
      this._data.item = {
        category: '',
        icon: ''
      };
    }
  }

  async openIconPicker() {
    const result = await firstValueFrom(
      this._dialog.open(IconPickerDialogComponent, {
        width: '800px'
      }).afterClosed()
    );
    if (result?.id) {
      this.group.get('icon').setValue(result.id);
      this._data.item.icon = result.id;
    }
  }

  async saveItem() {
    this._data.item.icon = this.group.get('icon').value;
    this._data.item.category = this.group.get('category').value;
    this.group.disable();
    try {
      const result = firstValueFrom(this._http.post(`${getHostUrl()}admin/courses/category`, this._data.item));
      this._config.showSnackbar('Kategorie wurde gespeichert!', 3000, 'success');
      this._ref.close(true);
    } catch(e) {
      this._config.showSnackbar('Konnte Kategorie nicht speichern', 3000, 'error');
      this.group.enable();
    }
  }
  
  async deleteItem() {
    const result = await Swal.fire({
      title: 'Löschen?',
      text: 'Soll diese Kategorie wirklich gelöscht werden?',
      confirmButtonText: 'Ja',
      denyButtonText: 'Nein',
      showDenyButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      icon: 'question'
    });
    if (result.isConfirmed) {
      try {
        await firstValueFrom(this._http.delete(`${getHostUrl()}admin/courses/category/${this._data.item.id}`));
        this._config.showSnackbar('Kategorie wurde gelöscht!', 3000, 'success');
      this._ref.close(true);
      } catch(e) {
        this._config.showSnackbar('Konnte Kategorie nicht löschen!', 3000, 'error');
      }
    }
  }
}
