import { Component, OnInit, Input } from '@angular/core';
import { Login } from 'src/app/util/login';
import { HttpClient } from '@angular/common/http';
import { getHostUrl } from 'src/app/util/config';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { ProfileComponent } from '../profile.component';

@Component({
  selector: 'app-profile-calendar',
  templateUrl: './profile-calendar.component.html',
  styleUrls: ['./profile-calendar.component.css'],
})
export class ProfileCalendarComponent implements OnInit {

  @Input() parent : ProfileComponent;

  constructor(private _login: Login,
    private _snackBar: MatSnackBar,
    private _http: HttpClient) { }

  ngOnInit(): void {
    this.parseToken(this.parent.userInfo.masterdata.calendarToken);
  }

  calendarToken: string;

  parseToken(token) {
    if (!token) {
      this.generateCalendar();
    } else {
      this.calendarToken = `${getHostUrl()}calendar/sync?token=${token}`;
    }
  }

  copyClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.calendarToken;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._snackBar.open('URL wurde in die Zwischenablage kopiert', null, {
      duration: 1000,
    });
  }

  generateCalendar() {
    this._http.get<any>(`${getHostUrl()}profile/calendarToken`).subscribe(data => {
      this.parseToken(data.code);
    }, error => { });
  }

}
