<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header" *ngIf="notificationData && notificationData.title">{{notificationData.title}}</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>

        <div *ngIf="notificationData && notificationData.id > 0">
          <div class="row inner-row clearfix">
            <!-- <div class="col-lg-2 col-12 d-flex justify-content-center flex-column">
            <div class="avatarWrapper">
              <img [src]="notificationData.avatar" class="avatarRound" />
            </div> 
          </div> -->
            <div class="notificationMessageBox col-12">
              <span [innerHTML]="notificationData.message"></span>
              <hr />
              <i style="font-size: 14px">{{ getFormattedDateTime(notificationData.date)}} <!-- -
              {{ notificationData.firstname + ' ' + notificationData.lastname }} --> </i>
            </div>
          </div>
        </div>
        <div class="alert alert-danger" *ngIf="!notificationData || !notificationData.id">
          <b>Fehler:</b> Diese Benachrichtigung existiert nicht länger!
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>