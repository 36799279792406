<p>Auf der Startseite ist dein personalisierter Kalender zu finden. In dem Kalender sind Punkte, diese symbolisieren einen Termin. Es gilt folgende Farblegende:</p>
<ul>
    <li><b style="color: #ad2121">Rot:</b> Responderschicht</li>
    <li><b style="color: #e3bc08;">Gelb:</b>EvD Schicht</li>
    <li><b style="color: #183df3;">Blau:</b> Techniktermin (Übungen/sonstiger Termin)</li>
    <li><b style="color: #00b300;">Grün:</b> Sicherheitswache</li>
    <li><b style="color: #9933ff;">Lila:</b> Vereinsveranstaltung</li>
</ul>
<p>Sobald man länger als eine Schicht im Responder/EvD Dienstplan eingetragen ist, wird nur ein Punkt hierfür angelegt.</p>
<p>Zusätzlich gibt es oben rechts mittels des Downloadbuttons die Möglichkeit, den Kalender zu abonnieren und in deinen
    Google-, iOS-, Outlook-, etc. Kalender zu integrieren.</p>