<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Neue Auswertung erstellen</h3>
    <mat-card>
      <mat-card-content>
        <mat-stepper [orientation]="isMobile ? 'horizontal' : 'vertical'" labelPosition="bottom" linear #stepper>
          <mat-step [completed]="currentStatistic && showLoader === false && resultedData">
            <ng-template matStepLabel>Auswertung auswählen</ng-template>
            <ng-template matStepContent>
              <mat-form-field class="input-full-width">
                <mat-label>Auswertung auswählen</mat-label>
                <mat-select [(ngModel)]="currentStatistic" required [disabled]="showLoader"
                  (ngModelChange)="resetData()">
                  <mat-select-trigger>{{ currentStatistic?.name }}</mat-select-trigger>
                  <mat-option>
                    <ngx-mat-select-search [formControl]="statisticFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-optgroup *ngFor="let category of statisticFilter | async" [label]="category.name">
                    <mat-option *ngFor="let statistic of category.data" [value]="statistic">
                      <mat-icon *ngIf="statistic?.type === 'piechart'" class="ms-3" matSuffix aria-label="">
                        pie_chart</mat-icon>
                      <mat-icon *ngIf="statistic?.type === 'barchart'" class="ms-3" matSuffix>
                        bar_chart</mat-icon>
                      <mat-icon *ngIf="statistic?.type === 'stackchart'" class="ms-3" matSuffix>
                        stacked_bar_chart</mat-icon>
                      <mat-icon *ngIf="statistic?.type === 'file' || statistic?.type === 'fileWithSum'" class="ms-3"
                        matSuffix>
                        description</mat-icon>
                      {{ statistic.name }}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>

              <div class="alert alert-info" *ngIf="currentStatistic">
                {{ currentStatistic.description }}
              </div>

              <div *ngIf="currentStatistic && currentStatistic.params.length !== 0" class="row">
                <div *ngFor="let param of currentStatistic.params; let i = index" class="col-lg-4 col-md-6 col-12">
                  <app-yearpicker *ngIf="param.param === 'year'" [required]="param.required" [label]="param.name"
                    (year)="setParamValue('year', $event)"></app-yearpicker>
                  <app-monthpicker *ngIf="param.param === 'month'" [required]="param.required" [label]="param.name"
                    (month)="setParamValue('month', $event)"></app-monthpicker>
                  <app-grouppicker *ngIf="param.param === 'group'" [required]="param.required" [label]="param.name"
                    [multiple]="param.multiple" (group)="setParamValue('group', $event)"></app-grouppicker>
                  <app-rankpicker *ngIf="param.param === 'rank'" [required]="param.required" [label]="param.name"
                    [multiple]="param.multiple" (rank)="setParamValue('rank', $event)"></app-rankpicker>
                </div>
              </div>

              <div class="clearfix">
                <button mat-raised-button class="float-end" color="primary"
                  [disabled]="!currentStatistic || !getParamsValid()" (click)="executeStatistic()">
                  Auswertung starten <mat-icon class="ms-3" [hidden]="!showLoader">
                    <mat-spinner color="accent" [diameter]="20"></mat-spinner>
                  </mat-icon>
                </button>
              </div>
            </ng-template>
          </mat-step>
          <mat-step [completed]="currentStatistic && resultedData">
            <ng-template matStepLabel>Ergebnis</ng-template>
            <ng-template matStepContent>
              <div class="mt-3">
                <app-table-statistic
                  *ngIf="(currentStatistic?.type === 'file' || currentStatistic?.type === 'fileWithSum') && resultedData"
                  [displayedColumns]="resultedDataColumns" [data]="resultedData"
                  (filteredData)="updatePrintData($event)"></app-table-statistic>
                <app-bar-chart-statistic *ngIf="currentStatistic?.type === 'barchart' && resultedData"
                  [data]="resultedData" [title]="currentStatistic?.name" (chart)="setChart($event)">
                </app-bar-chart-statistic>
                <app-pie-chart-statistic *ngIf="currentStatistic?.type === 'piechart' && resultedData"
                  [data]="resultedData" [title]="currentStatistic?.name" (chart)="setChart($event)">
                </app-pie-chart-statistic>
                <app-stackbar-chart-statistic *ngIf="currentStatistic?.type === 'stackchart' && resultedData"
                  [data]="resultedData" [title]="currentStatistic?.name" (chart)="setChart($event)">
                </app-stackbar-chart-statistic>
              </div>
              <div class="mt-3">
                <button mat-raised-button matStepperPrevious color="primary">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                  Zurück
                </button>
                <button mat-raised-button color="primary" class="float-right" (click)="export()"
                  [disabled]="runningExport">
                  Exportieren <mat-icon class="ms-3" [hidden]="!runningExport">
                    <mat-spinner color="accent" [diameter]="20"></mat-spinner>
                  </mat-icon>
                  <mat-icon *ngIf="!runningExport">file_download</mat-icon>
                </button>
              </div>
            </ng-template>
          </mat-step>
        </mat-stepper>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #menu>
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
    <mat-icon>close</mat-icon>
  </a>
  <mat-nav-list style="padding-right: 40px;">
    <a mat-list-item (click)="_bottomSheet.dismiss(); exportToPicture()"
      *ngIf="currentStatistic?.type === 'piechart' || currentStatistic?.type === 'barchart' || currentStatistic?.type === 'stackchart'">
      <mat-icon matListItemIcon>image</mat-icon>
      <div matListItemTitle>Export als PNG Datei</div>
    </a>
    <a mat-list-item (click)="_bottomSheet.dismiss(); exportPDF()"
      *ngIf="currentStatistic?.type === 'file' || currentStatistic?.type === 'fileWithSum'">
      <mat-icon matListItemIcon>picture_as_pdf</mat-icon>
      <div matListItemTitle>Export als PDF Datei</div>
    </a>
    <a mat-list-item (click)="_bottomSheet.dismiss(); exportToCsv()">
      <mat-icon matListItemIcon>table_chart</mat-icon>
      <div matListItemTitle>Export als CSV Datei</div>
    </a>
  </mat-nav-list>
</ng-template>