import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Injectable, Component, ChangeDetectionStrategy, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { MatCalendar } from '@angular/material/datepicker';
import { takeUntil } from 'rxjs/operators';

import 'dayjs/locale/de';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

export const DATE_FORMATS = {
  parse: { dateInput: ['dd.MM.YYYY'] },
  display: {
    dateInput: 'input',
    monthYearLabel: 'monthYearLabel',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: 'monthYearLabelLong',
  },
};

export const AppDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

export const DATE_TIME_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};


@Injectable()

export class CustomDateAdapterService extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }


  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    let names = [];
    
    switch(style) {
      case 'long':
        names = ['Sonntag','Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
        break;
      case 'short':
        names = ['So','Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
        break;
      case 'narrow':
        names = ['So','Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
        break;
    }

    return names;  
  }

  constructor() {
    super('de')

    // Initalize DayJS-Parser
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(localizedFormat)
  }

  parse(value: any): Date | null {
    return dayjs(value, 'DD.MM.YYYY').toDate()
  }

  format(date: Date, displayFormat: any): string {
    switch (displayFormat) {
      case 'input':
        return dayjs(date).format('DD.MM.YYYY');
      case 'dayLabel':
        return dayjs(date).format('DD');
      case 'monthLabel':
        return dayjs(date).format('MMM');
      case 'monthLabelLong':
        return dayjs(date).format('MMMM');
      case 'monthYearLabelShort':
        return dayjs(date).format('MM.YYYY');
      case 'monthYearLabel':
        return dayjs(date).format('MMM YYYY');
      case 'monthYearLabelLong':
        return dayjs(date).format('MMMM YYYY');
      case 'dayMonthLabel':
        return dayjs(date).format('DD. MMM.');
      case 'dayMonthLabelLong':
        return dayjs(date).format('DD. MMMM');
      case 'yearLabel':
        return dayjs(date).format('YYYY');
      default:
        return dayjs(date).format('DD.MM.YYYY');
    }
  }
}


/** Custom header component for datepicker. */

@Component({
  selector: 'app-month-date-header',
  styles: [`
    .example-header {
      display: flex;
      align-items: center;
      padding: 0.5em;
    }


    .example-header-label {
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }


    .example-double-arrow .mat-icon {
      margin: -22%;
    }
  `],

  template: `
    <div class="example-header">
      <button mat-icon-button (click)="previousClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="example-header-label">{{periodLabel}}</span>
      <button mat-icon-button (click)="nextClicked()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,

  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MonthDateHeaderComponent<D> implements OnDestroy {

  private _destroyed = new Subject<void>();


  constructor(
    private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,

    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {

    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());

  }


  ngOnDestroy() {
    this._destroyed.next();

    this._destroyed.complete();
  }


  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel);
  }


  previousClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }


  nextClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }
}
