import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { Courses } from '@ffo/mitgliederbereich-types';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import Swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-secretary-courses',
  templateUrl: './secretary-courses.component.html',
  styleUrls: ['./secretary-courses.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class SecretaryCoursesComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = ['name', 'location', 'actions'];
  dataSource = new MatTableDataSource();
  courses: Courses[] = [];

  @ViewChild(MatSort, { static: false})
  sort: MatSort;
  categoryList: any[] = [];
  categoryFilter: number[] = [];
  searchFilter: string = '';

  constructor(private _http: HttpClient,
    public _login: Login,
    private _config: ConfigModule,
    private _spinner: NgxSpinnerService) { }

  ngOnInit() {
    this._config.setTitle('Laufbahn');
    this.refreshData();
    this.fetchDataInfo();
  }

  refreshData() {
    this._http.get<Courses[]>(getHostUrl() + 'admin/courses/all')
      .subscribe(data => {
        this.courses = data;
        this.dataSource = new MatTableDataSource<Courses>();
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.filter();
        this._spinner.hide();
      });
  }

  async fetchDataInfo() {
    this.categoryList = await this._http.get<any[]>(`${getHostUrl()}datainfo/categoryCourses`).toPromise();
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, category: this.categoryFilter });
  }

  applyGroupFilter($event: MatSelectChange) {
    this.categoryFilter = $event.value;
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, category: this.categoryFilter });
  }

  filter(): (data: Courses, filter: string) => boolean {
    let filterFunction = function (data: Courses, filter: string): boolean {
      let searchTerms: { search: string; category: number[]; } = JSON.parse(filter);
      if (searchTerms.search !== '' && data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1) { return false; }
      if (searchTerms.category.length !== 0 && searchTerms.category.includes(data.categoryId) === false) { return false; }
      return true;
    };
    return filterFunction;
  }

  async deleteCourse(course: Courses) {
    const popup = await Swal.fire({
      title: 'Löschen?',
      text: 'Soll dieses Ereignis wirklich gelöscht werden?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ja, löschen',
      denyButtonText: 'Abbrechen',
      reverseButtons: true,
      icon: 'question'
    });
    if (!popup.isConfirmed) {
      return;
    }
    
    try {
      const result = await this._http.delete<any>(`${getHostUrl()}admin/courses/${course.id}`).toPromise();
      this._config.showSnackbar('Ereignis wurde gelöscht!', 3000, 'success'); 
      this.courses.splice(this.courses.findIndex(c => c.id == course.id), 1);
      this.dataSource.data = this.courses;
    } catch (error) {
      this._config.showSnackbar('Konnte Ereignis nicht löschen, eventuell ist das Ereignis noch Benutzern zugeordnet?', 3000, 'warning');
    }
  }


}
