<mat-dialog-header>
    <div class="clearfix">
        <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
            <mat-icon>close</mat-icon>
        </a>
        <h2 mat-dialog-title class="float-left">Fahrerlaubnis - {{licence.licenceClass }}</h2>
    </div>
</mat-dialog-header>
<mat-dialog-content>
    <div class="row">
        <div [ngClass]="licencePicture.endsWith('undefined') || licencePicture.endsWith('=') ? '' : 'col-12 col-md-5 col-lg-5'"
            *ngIf="!licencePicture.endsWith('undefined') && !licencePicture.endsWith('=')">
            <img [src]="licencePicture" style="width: 100%">
        </div>
        <div
            [ngClass]="licencePicture.endsWith('undefined') || licencePicture.endsWith('=') ? 'col-12 col-md-12 col-lg-12' : 'col-12 col-md-7 col-lg-7'">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12"
                    *ngIf="licence.licenceClass === 'Klasse B' || licence.licenceClass === 'Klasse BE'">
                    <mat-form-field class="input-full-width" appearance="outline" *ngIf="!unlimited">
                        <mat-label>Ablaufdatum Fahrerlaubnis</mat-label>
                        <input matInput required disabled [(ngModel)]="expiryDate">
                    </mat-form-field>
                    <mat-form-field class="input-full-width" appearance="outline" *ngIf="unlimited">
                        <mat-label>Ablaufdatum Fahrerlaubnis</mat-label>
                        <input matInput required disabled value="unbegrenzt">
                    </mat-form-field>
                </div>
                <div class="col-lg-12 col-md-12 col-12" *ngIf="licence.licenceClass === 'Klasse FFF'">
                    <mat-form-field class="input-full-width" appearance="outline" *ngIf="!unlimited">
                        <mat-label>Ablaufdatum Fahrerlaubnis</mat-label>
                        <input matInput required disabled [(ngModel)]="expiryDate">
                    </mat-form-field>
                    <mat-form-field class="input-full-width" appearance="outline" *ngIf="unlimited">
                        <mat-label>Ablaufdatum Fahrerlaubnis</mat-label>
                        <input matInput required disabled value="unbegrenzt">
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 col-12"
                    *ngIf="(licence.licenceClass === 'Klasse C1' || licence.licenceClass === 'Klasse C' || licence.licenceClass === 'Klasse C1E' || licence.licenceClass === 'Klasse CE') && !unlimited">
                    <mat-form-field id="expiryDate" *ngIf="!env.mobile" class="input-full-width" appearance="outline">
                        <mat-label>Gültig bis</mat-label>
                        <button mat-icon-button matPrefix (click)="setUnlimited(true)"
                            *ngIf="licence.licenceClass === 'Klasse C1' || licence.licenceClass === 'Klasse C1E'">
                            <mat-icon>swap_calls</mat-icon>
                        </button>
                        <input matInput required [matDatepicker]="expiryDate" [min]="minDateExpiry" [max]="expiryClass"
                            placeholder="Ablaufdatum Führerschein" [value]="licence.expiryDate"
                            (dateChange)="onexpiryDateChange($event)" (change)="onexpiryChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                        <mat-datepicker #expiryDate [startAt]="startDate" startView="multi-year"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field id="expiryDate" *ngIf="env.mobile" class="input-full-width" appearance="outline">
                        <mat-label>Gültig bis</mat-label>
                        <button mat-icon-button matPrefix (click)="setUnlimited(true)"
                            *ngIf="licence.licenceClass === 'Klasse C1' || licence.licenceClass === 'Klasse C1E'">
                            <mat-icon>swap_calls</mat-icon>
                        </button>
                        <input matInput required [matDatepicker]="expiryDate" [min]="minDateExpiry" [max]="expiryClass"
                            placeholder="Ablaufdatum Führerschein" [value]="licence.expiryDate"
                            (dateChange)="onexpiryDateChange($event)" (change)="onexpiryChange($event)"
                            (focus)="expiryDate.open()">
                        <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                        <mat-datepicker #expiryDate touchUi [startAt]="startDate" startView="multi-year">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 col-12"
                    *ngIf="(licence.licenceClass === 'Klasse C1' || licence.licenceClass === 'Klasse C' || licence.licenceClass === 'Klasse C1E' || licence.licenceClass === 'Klasse CE') && unlimited">
                    <mat-form-field id="expiryDate" class="input-full-width" appearance="outline">
                        <mat-label>Gültig bis</mat-label>
                        <button mat-icon-button matPrefix (click)="setUnlimited(false)"
                            *ngIf="licence.licenceClass === 'Klasse C1' || licence.licenceClass === 'Klasse C1E'">
                            <mat-icon>swap_calls</mat-icon>
                        </button>
                        <input matInput required placeholder="Ablaufdatum Führerschein" value="unbegrenzt" disabled>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 col-12" *ngIf="licence.licenceClass !== 'Klasse FFF'">
                    <div class="input-full-width" [ngClass]="env.mobile===false ? 'trailer' : 'trailerMobile'">
                        <mat-slide-toggle [(ngModel)]="licence.trailer" (toggleChange)="changeTrailer(licence)"><img
                                src="{{getLicenceTrailerIcon(licence.licenceClass)}}" class="trailerIcon">
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="row" *ngFor="let perm of licence.drivingperms">
                <div class="col-12"
                    [ngClass]="licence.licenceClass !== 'Klasse FFF' ? 'col-md-3 col-lg-3' : 'col-md-4 col-lg-4'">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Fahrzeugtyp</mat-label>
                        <input matInput [value]="getCarName(perm.id)" readonly>
                    </mat-form-field>
                </div>
                <div class="col-12"
                    [ngClass]="licence.licenceClass !== 'Klasse FFF' ? 'col-md-2 col-lg-3' : 'col-md-5 col-lg-5'">
                    <mat-form-field id="briefingDate" *ngIf="!env.mobile" class="input-full-width" appearance="outline">
                        <mat-label>Einweisungfahrt</mat-label>
                        <input matInput [matDatepicker]="briefingDate" [max]="startDate" [min]="parent.age18Date"
                            placeholder="Einweisungfahrt" [value]="perm.briefingDate"
                            (dateChange)="onbriefingDateChange($event, perm)" (change)="onbriefingChange($event, perm)"
                            (focus)="briefingDate.open()">
                        <mat-datepicker-toggle matSuffix [for]="briefingDate"></mat-datepicker-toggle>
                        <mat-datepicker #briefingDate [startAt]="startDate" startView="multi-year"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field id="briefingDate" *ngIf="env.mobile" class="input-full-width" appearance="outline">
                        <mat-label>Einweisungfahrt</mat-label>
                        <input matInput [matDatepicker]="briefingDate" [max]="startDate" [min]="parent.age18Date"
                            placeholder="Einweisungfahrt" [value]="perm.briefingDate"
                            (dateChange)="onbriefingDateChange($event, perm)" (change)="onbriefingChange($event, perm)"
                            (focus)="briefingDate.open()">
                        <mat-datepicker-toggle matSuffix [for]="briefingDate"></mat-datepicker-toggle>
                        <mat-datepicker #briefingDate touchUi [startAt]="startDate" startView="multi-year">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3 col-lg-3" *ngIf="licence.licenceClass !== 'Klasse FFF'">
                    <mat-form-field id="briefingDateBlue" *ngIf="!env.mobile" class="input-full-width"
                        appearance="outline">
                        <mat-label>Blaufahrabnahme</mat-label>
                        <input matInput [matDatepicker]="briefingDateBlue" [min]="perm.briefingDate" [max]="startDate"
                            placeholder="Blaufahrabnahme" [value]="perm.briefingDateBlue"
                            (dateChange)="onbriefingDateBlueChange($event, perm)"
                            (change)="onbriefingBlueChange($event, perm)" (focus)="briefingDateBlue.open()"
                            [disabled]="perm.briefingDate===null">
                        <mat-datepicker-toggle matSuffix [for]=" briefingDateBlue"></mat-datepicker-toggle>
                        <mat-datepicker #briefingDateBlue [startAt]="startDate" startView="multi-year"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field id="briefingDateBlue" *ngIf="env.mobile" class="input-full-width"
                        appearance="outline">
                        <mat-label>Blaufahrabnahme</mat-label>
                        <input matInput [matDatepicker]="briefingDateBlue" [min]="perm.briefingDate" [max]="startDate"
                            placeholder="Blaufahrabnahme" [value]="perm.briefingDateBlue"
                            (dateChange)="onbriefingDateBlueChange($event, perm)"
                            (change)="onbriefingBlueChange($event, perm)" (focus)="briefingDateBlue.open()"
                            [disabled]="perm.briefingDate===null">
                        <mat-datepicker-toggle matSuffix [for]="briefingDateBlue"></mat-datepicker-toggle>
                        <mat-datepicker #briefingDateBlue touchUi [startAt]="startDate" startView="multi-year">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3 col-lg-3" *ngIf="licence.licenceClass !== 'Klasse FFF'">
                    <mat-form-field id="recertification" *ngIf="!env.mobile" class="input-full-width"
                        appearance="outline">
                        <mat-label>Rezertifizierung</mat-label>
                        <input matInput [matDatepicker]="recertification" [min]="perm.briefingDate" [max]="startDate"
                            placeholder="Rezertifizierung" [value]="perm.recertification"
                            (dateChange)="onrecertificationDateChange($event, perm)"
                            (change)="onrecertificationChange($event, perm)" (focus)="recertification.open()"
                            [disabled]="perm.briefingDate===null">
                        <mat-datepicker-toggle matSuffix [for]=" recertification"></mat-datepicker-toggle>
                        <mat-datepicker #recertification [startAt]="startDate" startView="multi-year"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field id="recertification" *ngIf="env.mobile" class="input-full-width"
                        appearance="outline">
                        <mat-label>Rezertifizierung</mat-label>
                        <input matInput [matDatepicker]="recertification" [min]="perm.briefingDate" [max]="startDate"
                            placeholder="Rezertifizierung" [value]="perm.recertification"
                            (dateChange)="onrecertificationDateChange($event, perm)"
                            (change)="onrecertificationChange($event, perm)" (focus)="recertification.open()"
                            [disabled]="perm.briefingDate===null">
                        <mat-datepicker-toggle matSuffix [for]="recertification"></mat-datepicker-toggle>
                        <mat-datepicker #recertification touchUi [startAt]="startDate" startView="multi-year">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3 col-lg-3">
                    <button mat-raised-button color="warn" *ngIf="!env.mobile" (click)="deletePerm(perm)"
                        style="margin-top: 10px; margin-left:20px;">Löschen
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-raised-button color="warn" *ngIf="env.mobile">Löschen <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="licence.drivingperms.length!==carList.length" id="carTypeAddWith">
                <button mat-raised-button color="primary" style="width: 100%;" [matMenuTriggerFor]="menu"
                    (menuOpened)="setWithAddMenu()">Fahrzeugtyp hinzufügen</button>
                <mat-menu #menu="matMenu" yPosition="above">
                    <div id="menuWith"></div>
                    <button mat-menu-item *ngFor="let car of missingCars | async" (click)="addPerm(car)">
                        <span>{{car.type}}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions [class.justifyEnd]="licence.id===null" [class.justifyBetween]="licence.id!==null">
        <button mat-raised-button mat-dialog-close color="warn"
            *ngIf="licence.id!==null && _login.hasPermission('admin-user-driving-edit')" (click)="save(true)"
            cdkFocusInitial>
            <mat-icon>delete</mat-icon> Löschen
        </button>
        <button mat-raised-button mat-dialog-close color="success" *ngIf="licence.id!==null" (click)="save(false)"
            [disabled]="licence.expiryDate===null && !unlimited">Speichern
            <mat-icon>save</mat-icon></button>
        <button mat-raised-button mat-dialog-close color="success" *ngIf="licence.id===null" (click)="save(false)"
            [disabled]="licence.expiryDate===null && !unlimited">Hinzufügen
            <mat-icon>add</mat-icon></button>
</mat-dialog-actions>