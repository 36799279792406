<a (click)="_bottomSheet.dismiss()" *ngIf="!data || !data.forced" mat-icon-button class="closeButton">
  <mat-icon>close</mat-icon>
</a>

<br />
<h4 class="text-center" *ngIf="data && data.forced">Passwort muss geändert werden:</h4>
<h4 class="text-center" *ngIf="!data || !data.forced">Neues Passwort:</h4>

<form [formGroup]="loginForm" style="margin-top: 20px;" name="loginForm">
  <div class="form-group" *ngIf="passwordCFormControl">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
      <mat-label>Aktuelles Passwort</mat-label>
      <input matInput required formControlName="passwordC" maxlength="20" [type]="toggle.type" name="passwordC">
      <mat-hint align="end" aria-live="polite">
        {{passwordCFormControl.value.length}} / {{passwordComponent.max}}
      </mat-hint>
      <mat-error *ngIf="passwordCFormControl.invalid" color="warn">Bitte gib dein aktuelles Passwort ein!</mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
      <mat-label>Passwort</mat-label>
      <input matInput required maxlength="20" formControlName="password" [type]="toggle.type" name="password">
      <mat-hint align="end" aria-live="polite">
        {{passwordFormControl.value.length}} / {{passwordComponent.max}}
      </mat-hint>
      <mat-error *ngIf="passwordFormControl.invalid" color="warn">Bitte gib ein gültiges Passwort ein!</mat-error>
    </mat-form-field>

    <mat-password-strength class="green" min="6" max="20" [enableSpecialCharRule]="true"
      (onStrengthChanged)="onStrengthChanged($event)" [password]="passwordFormControl.value" #passwordComponent>
    </mat-password-strength>

    <mat-password-strength-info *ngIf="!env.safari" [passwordComponent]="passwordComponent"
      [lowerCaseCriteriaMsg]="translate.LOWER_CASE_CRITERIA_MSG"
      [upperCaseCriteriaMsg]="translate.UPPER_CASE_CRITERIA_MSG" [digitsCriteriaMsg]="translate.DIGITS_CRITERIA_MSG"
      [specialCharsCriteriaMsg]="translate.SPECIAL_CHARS_CRITERIA_MSG"
      [minCharsCriteriaMsg]="translate.MIN_CHARS_CRITERIA_MSG">
    </mat-password-strength-info>
    <div class="col-12" *ngIf="env.safari">
      <!-- <img src="{{pwSrc}}"> -->
      <br>
      <ul>
        <li>enthält mindestens ein Kleinbuchstaben</li>
        <li>enthält mindestens ein Großbuchstaben Zeichen</li>
        <li>enthält mindestens eine Ziffer</li>
        <li>enhält mindestens ein Sonderzeichen</li>
        <li>enthält mindestens 6 Zeichen</li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Passwort wiederholen:</mat-label>
      <input matInput required formControlName="passwordR" [type]="toggle.type" name="passwordR">
      <mat-error *ngIf="passwordRFormControl.invalid" color="warn">Bitte gib ein gültiges Passwort ein!</mat-error>
      <mat-error *ngIf="passwordRFormControl.value!==passwordFormControl.value" color="warn">Passwörter stimmen nicht
        überein!</mat-error>
    </mat-form-field>
  </div>
</form>

<button mat-raised-button color="warn" (click)="changePassword()" [disabled]="!isValid()" class="float-right">Passwort
  setzen <mat-icon>vpn_key</mat-icon></button>