import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';

import { getIcons } from './packs/material-pack'
@Component({
  selector: 'app-icon-picker-dialog',
  templateUrl: './icon-picker-dialog.component.html',
  styleUrls: ['./icon-picker-dialog.component.css']
})
export class IconPickerDialogComponent implements OnInit {

  icons = [];
  sortedIcons = [];

  currentIcon = null;
  searchField = new FormControl();

  constructor(private _dialog: MatDialogRef<IconPickerDialogComponent>) { }

  ngOnInit(): void {
    this.icons = getIcons().sort((a, b) => a.name.localeCompare(b.name));
    this.sortedIcons = this.icons;

    this.searchField.valueChanges.subscribe(value => this.searchIcon(value));
  }

  searchIcon(value) {    
    this.sortedIcons = this.icons.filter(icon => icon?.name.toLowerCase().includes(value.toLowerCase()));
  }

  close() {
    this._dialog.close(this.currentIcon);
  }

}
