import { Component, Input } from '@angular/core';
import { HelpComponent } from '../help.component';

@Component({
  selector: 'app-help-guards',
  templateUrl: './help-guards.component.html',
  styleUrls: ['./help-guards.component.css'],
})
export class HelpGuardsComponent {

  @Input() parent: HelpComponent;

  constructor() { }
}
