import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from '../../config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-leave-organization',
  templateUrl: './user-leave-organization.component.html',
  styleUrls: ['./user-leave-organization.component.scss']
})
export class UserLeaveOrganizationComponent {
  form = new FormGroup({
    reason: new FormControl('', [Validators.required, Validators.minLength(5)]),
    date: new FormControl(null, [Validators.required]),
  });

  today = new Date();
  userID: number;
  isLoading = false;

  constructor(private _http: HttpClient,
    private _config: ConfigModule,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _ref: MatDialogRef<UserLeaveOrganizationComponent>
  ) {
    this.userID = _data.userID;
  }

  async save() {
    if (this.form.invalid) return;

    this.isLoading = true;

    try {
      await firstValueFrom(
        this._http.post(`${getHostUrl()}admin/user/${this.userID}/disable`, this.form.value)
      );
      this._config.showSnackbar('Benutzer wurde als ausgetreten markiert.', 3000, 'success');
      this._ref.close(true);
    } catch (e) {
      console.error(e);
      this.isLoading = false;
      if (e?.error?.message) {
        this._config.showSnackbar(e.error.message, 3000, 'error');
        return;
      }
      this._config.showSnackbar('Konnte Benutzer nicht als ausgetreten markieren!', 3000, 'error');
    }
  }
}
