import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

interface Help {
  path: string;
  name: string;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
})
export class HelpComponent implements OnInit, OnDestroy {

  currentRoute: string = '';

  contentRoute: string = '';

  search: boolean = true;

  searchControl = new UntypedFormControl();

  sites: Help[] = [{
    path: '/home',
    name: 'Startseite',
  },
  {
    path: '/user',
    name: 'Mitglieder',
  },
  {
    path: '/responder',
    name: 'Responder',
  },
  {
    path: '/evd',
    name: 'EvD',
  },
  {
    path: '/event',
    name: 'Termine',
  },
  {
    path: '/alarms',
    name: 'Einsatz',
  },
  {
    path: '/guards',
    name: 'Wachen',
  },
  {
    path: '/profile',
    name: 'Profil',
  },
  {
    path: '/statistics',
    name: 'Auswertungen',
  },
  {
    path: '/settings',
    name: 'Einstellungen',
  }];

  filteredSites: Observable<Help[]>;

  constructor(private _router: Router) { }

  ngOnDestroy(): void {
    this.contentRoute = this.currentRoute;
  }

  ngOnInit(): void {
    this.filteredSites = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
    this.loadHelp();
  }

  setHelpEntry(path: string): void {
    let contains = false;
    this.sites.forEach(site => {
      if (path.startsWith(site.path))
        contains = true;
    });
    if (contains) {
      this.contentRoute = '/' + path.split('#')[0].split('/')[1];
      this.disableSearch();
    } else {
      this.contentRoute = '';
      this.enableSearch();
    }
  }

  displayFn(help: Help): string {
    return help && help.name ? help.name : '';
  }

  private _filter(value: any): Help[] {
    if (value.name !== undefined)
      value = value.name;

    const filterValue = value.toLowerCase();

    return this.sites.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public loadHelp() {
    this.currentRoute = this._router.url;
    this.setHelpEntry(this.currentRoute);

  }

  public disableSearch() {
    this.search = false;
  }

  public enableSearch() {
    this.search = true;
  }

  helpOverview() {
    this.contentRoute = '';
    this.enableSearch();
  }

  getSiteName(path: string): string {
    let returnvalue: string = '';
    this.sites.forEach(sites => {
      if (path === sites.path)
        returnvalue = '- ' + sites.name;
    });
    return returnvalue;
  }
}
