import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Guard, GuardBilling } from '@ffo/mitgliederbereich-types';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { translatePosition } from '../../guards.component';

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.css']
})
export class AdminGuardsVerifiedSendComponent {

  constructor(private _http: HttpClient, private _config: ConfigModule, private _router: Router) { }

  loading: boolean = true;
  guardList: Guard[] | GuardBilling[] = [];

  viewColumns: string[] = ['select', 'name', 'date'];

  ngOnInit(): void {
    this.loadGuards();
  }

  loadGuards(): void {
    this.loading = true;
    this._http.get<Guard[] | GuardBilling[]>(getHostUrl() + 'admin/fields/guards/guards')
      .subscribe(data => {
        this.guardList = data.filter((guard: Guard | GuardBilling) => {
          return !guard.verified && guard.status !== 'abgesagt' && guard.status !== 'abgerechnet' && guard.status !== 'in Abrechnung';
        }).map((guard: Guard | GuardBilling) => {
          guard.date = new Date(guard.date);
          return guard;
        });
        this.loading = false;
      });
  }

  selection = new SelectionModel<Guard | GuardBilling>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.guardList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.guardList);
  }

  sendVerification() {
    const guards: number[] = this.selection.selected.map((guard: Guard | GuardBilling) => guard.id);
    this._http.post(getHostUrl() + 'admin/fields/guards/guards/verificate', { guards })
      .subscribe({
        next: (data) => {
          this._config.showSnackbar("Bestätigung wurde verschickt", 3000, 'success');
          this._router.navigate([`/admin/fields/guards`]);
        }, error: (err) => {
          this._config.showSnackbar("Fehler beim Versenden der Bestätigung", 3000, 'error');
        }
      });
  }

  translatePosition(position: string): string {
    return translatePosition(parseInt(position), true);
  }
}
