import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { ConfigModule } from 'src/app/util/config';
import { CreateTimeCategoryDialogComponent } from 'src/app/util/dialogs/create-time-category-dialog/create-time-category-dialog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-time-tracking-admin-categories',
  templateUrl: './time-tracking-admin-categories.component.html',
  styleUrls: ['./time-tracking-admin-categories.component.scss']
})
export class TimeTrackingAdminCategoriesComponent implements OnInit {
  @ViewChild('timeAdminMenu', { static: false }) menuSheet: TemplateRef<any>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dayjs = dayjs;
  dataSource = new MatTableDataSource<any>();
  showLoader = true;

  tableSearch = new FormControl('');

  rawData = [];
  displayedColumns = ['name', 'group', 'status', 'action']

  constructor(private _dialog: MatDialog,
    private _http: HttpClient,
    private _bottomSheet: MatBottomSheet,
    private _config: ConfigModule
  ) { }

  ngOnInit(): void {
    this.loadCategories();
    this.tableSearch.valueChanges.subscribe(text => {
      this.dataSource.filter = text;
    });
    this._config.setTitle('Zeiterfassung Admin');
  }

  openMenu() {
    this._bottomSheet.open(this.menuSheet);
  }

  async loadCategories() {
    this.showLoader = true;
    try {
      const result = await firstValueFrom(this._http.get<any>(`api/admin/time/category`));
      this.rawData = result.data;
      this.dataSource.data = this.rawData;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } catch (error) {
      if (error?.error?.error) {
        this._config.showSnackbar(error.error.error, 3000, 'error');
        return;
      }
      this._config.showSnackbar('Konnte Buchungen nicht abrufen!', 3000, 'error');
    } finally {
      this.showLoader = false;
    }
  }

  async editEntry(item: any) {
    const result = await firstValueFrom(this._dialog.open(CreateTimeCategoryDialogComponent, {
      width: '500px',
      data: {
        item
      }
    }).afterClosed());

    if (result) {
      this.loadCategories();
    }
  }

  async createEntry() {
    const result = await firstValueFrom(this._dialog.open(CreateTimeCategoryDialogComponent, {
      width: '500px'
    }).afterClosed());

    if (result) {
      this.loadCategories();
    }
  }

  async deleteEntry(id: number) {
    const popup = await Swal.fire({
      title: 'Löschen?',
      text: 'Soll diese Kategorie wirklich gelöscht werden?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ja, löschen',
      denyButtonText: 'Abbrechen',
      reverseButtons: true,
      icon: 'question'
    });
    if (!popup.isConfirmed) {
      return;
    }

    this.showLoader = true;
    try {
      await firstValueFrom(this._http.delete(`api/admin/time/category/${id}`));
      this.loadCategories();
      this._config.showSnackbar('Kategorie wurde erfolgreich gelöscht!', 3000, 'success');
    } catch (error) {
      if (error?.error?.error) {
        this._config.showSnackbar(error.error.error, 3000, 'error');
        return;
      }
      this._config.showSnackbar('Fehler beim Löschen der Kategorie!', 3000, 'error');
    } finally {
      this.showLoader = false;
    }
  }

  changeListView(event) {
    let data;

      data = this.rawData;
    
    this.dataSource.data = data;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
