import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Host, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { AlarmsComponent } from '../alarms-edit.component';
import { firstValueFrom, Subject } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { PreventCloseGuard } from 'src/app/util/guards/prevent-close.guard';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alarms-report',
  templateUrl: './alarms-report.component.html',
  styleUrls: ['./alarms-report.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class AlarmsReportComponent implements OnInit, PreventCloseGuard, OnDestroy {

  constructor(public _login: Login,
    private _http: HttpClient,
    private _formBuilder: UntypedFormBuilder,
    private _config: ConfigModule,
    @Host() public _host: AlarmsComponent) { }

  group = new UntypedFormGroup({});
  fileList = [];

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;

  initialValue: any = null;

  baseURL = getHostUrl();
  isPWA = false;

  protected _onDestroy = new Subject<void>();

  onCloseValid(): boolean {
    return JSON.stringify(this.initialValue) === JSON.stringify(this.group.value);
  }

  ngOnInit() {
    this._host.showLoader = true;
    this.isPWA = window.matchMedia('(display-mode: standalone)').matches;

    this.group = this._formBuilder.group({
      report: ['', Validators.required],
      accidentFirefighter: ['', Validators.required],
      damageMaterial: ['', Validators.required],
      stressfulAlarm: ['', Validators.required],
    });
    this.initialValue = this.group.value;

    if (this._host.alarmID) {
      this.group.markAllAsTouched();
      this.loadReport();
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  loadReport() {
    this._host.showLoader = true;
    this._http.get<any>(`${getHostUrl()}alarms/report?alarmId=${this._host.alarmID}`)
      .subscribe(data => {
        this._host.showLoader = false;
        this.group.patchValue({
          report: data.report,
          accidentFirefighter: data.accidentFirefighter,
          damageMaterial: data.damageMaterial,
          stressfulAlarm: data.stressfulAlarm,
        });
        this.fileList = data.attachements ?? [];
        this.group.markAllAsTouched();
        this.initialValue = this.group.value;
      }, () => {
        this._host.showLoader = false;
      });
  }

  saveReport() {
    if (this.group.get('report').value) {
      this._host.showLoader = true;
      this._http.post<any>(`${getHostUrl()}alarms/report`, {
        alarmId: this._host.alarmID,
        report: this.group.get('report').value,
        accidentFirefighter: this.group.get('accidentFirefighter').value,
        damageMaterial: this.group.get('damageMaterial').value,
        stressfulAlarm: this.group.get('stressfulAlarm').value,
      })
        .subscribe(data => {
          this._host.showLoader = false;
          this.initialValue = this.group.value;
        }, () => {
          this._host.showLoader = false;
        });
    }
  }

  disable() {
    this.group.get('accidentFirefighter').disable();
    this.group.get('damageMaterial').disable();
    this.group.get('stressfulAlarm').disable();
  }

  attachFile() {
    const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
    e.click();
  }

  uploadAttachments($event) {
    let fileList: FileList = $event.target.files;
    this.blobToBase64(fileList.item(0)).then((fileContent: any) => {
      const file = { 'name': fileList.item(0).name, 'content': fileContent, 'id': null, 'error': false };
      this.FileSelectInputDialog.nativeElement.value = null;
      this.fileList.push(file);
      this.uploadFile();
    });
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  async uploadFile() {
    let file = this.fileList.find(file => file.id === null && file.error === false);
    if (!file) {
      return;
    }
    try {
      const result = await firstValueFrom(this._http.post<any>(`${getHostUrl()}alarms/report/upload`, {
        alarmID: this._host.alarmID,
        fileName: file.name,
        fileContent: file.content
      }));
      this.fileList[this.fileList.findIndex(file_ref => file === file_ref)].id = result.fileId;
    } catch (e) {
      this.fileList[this.fileList.findIndex(file_ref => file === file_ref)].error = true;
      this._config.showSnackbar(e.error, 3000, 'error');
    }
    setTimeout(() => {
      this.uploadFile();
    });
  }

  async deleteAttachement(file) {
    const result = await Swal.fire({
      title: 'Anhang löschen?',
      text: 'Soll dieser Anhang wirklich gelöscht werden?',
      icon: 'question',
      showDenyButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      confirmButtonText: 'Ja, löschen!',
      denyButtonText: 'Nein'
    });

    if (result.isConfirmed) {
      try {
        file.isDeleting = true;
        const result = await firstValueFrom(this._http.delete(`${getHostUrl()}alarms/report/upload`, {
          body: {
            fileID: file.id,
            alarmID: this._host.alarmID
          }
        }));
        this.fileList.splice(this.fileList.findIndex(file_ref => file_ref.id == file.id), 1);
        this._config.showSnackbar('Anhang wurde gelöscht!', 3000, 'success');
      } catch (e) {
        file.isDeleting = false;
        this._config.showSnackbar(e.error, 3000, 'error');
      }
    }
  }
}