import { Component, OnInit, Input } from '@angular/core';
import { ProfileComponent } from '../profile.component';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { getHostUrl, ConfigModule } from 'src/app/util/config';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AddressDialogComponent } from '../../../util/dialogs/address-dialog/address-dialog.component';

@Component({
  selector: 'app-profile-userdata',
  templateUrl: './profile-userdata.component.html',
  styleUrls: ['./profile-userdata.component.css'],
})
export class ProfileUserdataComponent implements OnInit {

  @Input() parent: ProfileComponent;

  filteredCityList: any = null;

  filteredStreetList: any = null;

  mailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  phoneFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(/^([+]\d{2})\d{8,12}$/),
  ]);

  homephoneFormControl = new UntypedFormControl('', [
    Validators.pattern(/^([+]\d{2})(\d{4,20})$/),
  ]);

  officephoneFormControl = new UntypedFormControl('', [
    Validators.pattern(/^([+]\d{2})(\d{4,20})$/),
  ]);

  streetFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);

  jobplaceFormControl = new UntypedFormControl('', []);

  dataFormGroup: UntypedFormGroup = new UntypedFormGroup({
    mail: this.mailFormControl,
    phone: this.phoneFormControl,
    home: this.homephoneFormControl,
    office: this.officephoneFormControl,
    street: this.streetFormControl,
  });

  constructor(private _http: HttpClient,
    private _config: ConfigModule,
    private _dialog: MatDialog) { }


  ngOnInit() {
    this.mailFormControl.setValue(this.parent.userInfo.masterdata.email);
    this.phoneFormControl.setValue(this.parent.userInfo.masterdata.mobile);
    this.homephoneFormControl.setValue(this.parent.userInfo.masterdata.homephone);
    this.officephoneFormControl.setValue(this.parent.userInfo.masterdata.officephone);
    this.streetFormControl.setValue(this.parent.userInfo.masterdata.street);
    this.jobplaceFormControl.setValue(this.parent.userInfo.masterdata.jobplace);
  }

  showName(value): string | undefined {
    return value ? value.name : undefined;
  }

  clearJobPlace() {
    this.jobplaceFormControl.setValue('');
  }

  saveUserData() {
    this.parent.userInfo.masterdata.homephone = this.homephoneFormControl.value;
    this.parent.userInfo.masterdata.mobile = this.phoneFormControl.value;
    this.parent.userInfo.masterdata.officephone = this.officephoneFormControl.value;
    this.parent.userInfo.masterdata.email = this.mailFormControl.value;
    this.parent.userInfo.masterdata.street = this.streetFormControl.value;
    this.parent.userInfo.masterdata.jobplace = this.jobplaceFormControl.value;
    this.parent._spinner.show();
    this._http.post(getHostUrl() + 'profile', { userObj: this.parent.userInfo.masterdata }).subscribe(resp => {
      this.parent._spinner.hide();
      this._config.showSnackbar('Benutzerdaten gespeichert!', 2000, 'success');
      this.parent.fetchMasterData();
    }, error => {
      this.parent._spinner.hide();
      this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      this.parent.fetchMasterData();
    });
  }

  findAddress() {
    const dialogRef = this._dialog.open(AddressDialogComponent, {
      width: '500px',
      data: {
        cities: false,
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === null) {
        return;
      }
      this.streetFormControl.setValue(data.street);
      this.parent.userInfo.masterdata.city = data.city;
      this.parent.userInfo.masterdata.zipcode = data.zipcode;
      this.parent.userInfo.masterdata.country = data.country;
    });
  }

  findJobPlace() {
    const dialogRef = this._dialog.open(AddressDialogComponent, {
      width: '500px',
      data: {
        cities: true,
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === null) {
        return;
      }
      this.jobplaceFormControl.setValue(data.city);
      this.parent.userInfo.masterdata.jobplaceZipcode = data.zipcode;
      this.parent.userInfo.masterdata.jobplaceCountry = data.country;
    });
  }
}
