<h2 mat-dialog-title>Benutzer als ausgetreten markieren</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="outline" class="input-full-width mt-3">
      <mat-label>Austrittsgrund</mat-label>
      <input matInput formControlName="reason" required/>
      <mat-error *ngIf="form.get('reason').hasError('required')">Dieses Feld ist erforderlich</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="input-full-width">
      <mat-label>Austrittsdatum</mat-label>
      <input matInput [matDatepicker]="picker" [max]="today" formControlName="date" required (focus)="picker.open()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="form.get('date').hasError('required')">Dieses Feld ist erforderlich</mat-error>
    </mat-form-field>
  </form>

  <div class="clearfix">
    <button mat-raised-button color="accent" class="float-end" [disabled]="form.invalid" (click)="save()">
      <div class="clearfix submit-button">
        <mat-icon class="me-3 mt-1 float-left" [hidden]="!isLoading">
          <mat-spinner diameter="20"></mat-spinner>
        </mat-icon>
        <span class="me-1 float-left">Speichern</span>
        <mat-icon class="float-right">save</mat-icon>
      </div>
    </button>
  </div>
</mat-dialog-content>
