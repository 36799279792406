<div class="container">
  <div class="row">
    <div class="col-12" *ngIf="!isLoading">
      <h3 class="section-header">Grundeinstellungen</h3>
      <form [formGroup]="group">
        <mat-card *ngFor="let category of categories" class="mb-4">
          <mat-card-header>
            <mat-card-title>
              <h4>{{ category.name }}</h4>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="mt-2">
            <div *ngFor="let field of category.fields">
              <mat-form-field class="input-full-width" *ngIf="field.type == 'text' || field.type == 'number'"
                appearance="outline">
                <input matInput [formControlName]="field.name" [type]="field.type" />
                <mat-label>{{ field.fancyName ?? field.name }}</mat-label>
              </mat-form-field>
  
              <mat-form-field class="input-full-width" *ngIf="field.type == 'select'" appearance="outline">
                <mat-select [formControlName]="field.name">
                  <mat-option *ngFor="let item of field.data.split(';')" [value]="item">{{ item }}</mat-option>
                </mat-select>
                <mat-label>{{ field.fancyName ?? field.name }}</mat-label>
              </mat-form-field>
  
              <mat-form-field class="input-full-width" *ngIf="field.type == 'textfield'" appearance="outline">
                <textarea matInput [formControlName]="field.name" style="min-height:150px"></textarea>
                <mat-label>{{ field.fancyName ?? field.name }}</mat-label>
              </mat-form-field>
  
              <mat-form-field class="input-full-width" *ngIf="field.type == 'list'" appearance="outline">
                <mat-chip-grid #chipList [formControlName]="field.name">
                  <mat-chip-row *ngFor="let item of group.get(field.name).value; let index = i"
                    (removed)="removeFromList(index, field.name)">
                    {{item}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                  <input matInput [matChipInputFor]="chipList" (matChipInputTokenEnd)="addToList($event, field.name)" />
                </mat-chip-grid>
                <mat-label>{{ field.fancyName ?? field.name }}</mat-label>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
  
        <div class="clearfix" *ngIf="categories">
          <div class="float-right">
            <button mat-raised-button color="primary" (click)="saveFields()">
              <mat-icon>save</mat-icon>
              Einstellungen speichern
            </button>
          </div>
        </div>
  
        <button mat-raised-button class="col-12 mt-5" color="warn" (click)="debugging()"
          matTooltip="Ändert API zu localhost"
          *ngIf="_login.hasPermission('admin-dev') && env.deploymentEnv === 'development'">
          <mat-icon>developer_mode</mat-icon>
          Debug on Localhost
        </button>
      </form>
    </div>
  </div>
  
  <div class="mt-4 row" *ngIf="isLoading">
    <div class="col-4">
      <ngx-skeleton-loader count="1" [theme]="{ 
          height: '40px'
        }"></ngx-skeleton-loader>
    </div>
    <div class="col-12">
      <ngx-skeleton-loader count="1" [theme]="{ 
          height: '60px'
        }"></ngx-skeleton-loader>
    </div>
    <div class="col-4">
      <ngx-skeleton-loader count="1" [theme]="{ 
          height: '40px'
        }"></ngx-skeleton-loader>
    </div>
    <div class="col-12">
      <ngx-skeleton-loader count="1" [theme]="{ 
          height: '60px'
        }"></ngx-skeleton-loader>
    </div>
    <div class="col-4">
      <ngx-skeleton-loader count="1" [theme]="{ 
          height: '40px'
        }"></ngx-skeleton-loader>
    </div>
    <div class="col-12">
      <ngx-skeleton-loader count="1" [theme]="{ 
          height: '60px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</div>