<div class="scroll" style="max-height: 80vh" *ngIf="!showLoader && !_host.showLoader">
  <span>Einsatzleiter: <b style="display:inline" [class.mat-error]="!data.leader">{{ data.leader || 'nicht gesetzt!' }}</b></span><br />
  <span>Personenanzahl: <b>{{ data.persons }}</b></span>

  <h4 class="mt-3">Materialliste</h4>
  <mat-list>
    <mat-list-item *ngIf="!data.materials || data.materials.length===0">
      <mat-icon matListItemIcon class="mat-error">label_important</mat-icon>
      <div matListItemTitle class="mat-error">Keine Materialien in diesem Einsatz zugeordnet</div>
    </mat-list-item>
    <mat-list-item *ngFor="let material of data.materials">
      <mat-icon matListItemIcon>label_important</mat-icon>
      <div matListItemTitle>{{ material.qty + material.unit + ' - ' + material.name }}</div>
    </mat-list-item>
  </mat-list>

  <h4 class="mt-3">Anwesende Organisationen</h4>
  <mat-list>
    <mat-list-item *ngIf="!data.orgs || data.orgs.length===0">
      <mat-icon matListItemIcon class="mat-error">label_important</mat-icon>
      <div matListItemTitle class="mat-error">Keine weiteren Organisationen anwesend</div>
    </mat-list-item>
    <mat-list-item *ngFor="let orgs of data.orgs">
      <mat-icon matListItemIcon>label_important</mat-icon>
      <div matListItemTitle>{{ orgs.name }}</div>
    </mat-list-item>
  </mat-list>

  <div class="alert alert-warning mt-3" *ngIf="data.invalidStatus.length > 0">
    <mat-icon>warning</mat-icon> Achtung folgende Fahrzeuge sind nicht auf <b>Status 2</b>:<br />
    <ul>
      <li *ngFor="let vehicle of data.invalidStatus">
        {{ vehicle.name }} 
        <button mat-icon-button (click)="setStatusTwo(vehicle)">
          <mat-icon>looks_two</mat-icon>
        </button>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="showLoader || _host.showLoader" layout="column" layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
  <mat-spinner color="primary"></mat-spinner>
</div>
