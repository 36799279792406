<mat-dialog-header>
  <div class="clearfix">
    <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
      <mat-icon>close</mat-icon>
    </a>
    <h2 mat-dialog-title class="float-left">Vergangene Jobs (Letzen 50)</h2>
  </div>
</mat-dialog-header>
<mat-dialog-content>
  <div class="table-responsive">
    <div *ngIf="dataSource.data.length === 0">
      <mat-progress-bar mode="query"></mat-progress-bar>
    </div>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8" matSortActive="date"
      matSortDirection="asc" matSortDisableClear>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef style="min-width: 20vw; max-width: 30vw;" mat-sort-header> Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.name}}
        </td>
      </ng-container>

      <!-- Datum Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 15vw;"> Gelaufen</th>
        <td mat-cell *matCellDef="let element">
          {{ element.created }}
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 15vw;"> Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="triggeredby">
        <th mat-header-cell *matHeaderCellDef>Ausführer</th>
        <td mat-cell *matCellDef="let element">
          {{ element.triggeredBy }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="expand row"
            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <ngx-json-viewer [json]="element.data"></ngx-json-viewer>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
</mat-dialog-content>