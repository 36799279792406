import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-storage-detail',
  templateUrl: './storage-detail.component.html',
  styleUrls: ['./storage-detail.component.css']
})
export class StorageDetailComponent implements OnInit {

  constructor(private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _config: ConfigModule,
    private _builder: FormBuilder) { }

  vehicleList = [];
  locationList = [];
  storageId = null;

  isSaving = false;
  isLoading = true;

  group: FormGroup = new FormGroup({});

  ngOnInit(): void {
    this.group = this._builder.group({
      name: ['', Validators.required],
      vehicleId: [-1],
      locationId: [-1]
    });

    this.loadVehicles();
    this.loadLocations();
    this.storageId = this._route.snapshot.params?.storageId;
    if (this.storageId) {
      this._config.setTitle('Lagerplatz bearbeiten');
      this.loadStorage();
    } else {
      this._config.setTitle('Lagerplatz erstellen');
      this.isLoading = false;
    }
  }

  async saveData() {
    this.group.disable();
    this.isSaving = true;
    try {
      if (this.storageId) {
        await firstValueFrom(this._http.post(`${getHostUrl()}admin/material/storage/${this.storageId}`, this.group.value));
      } else {
        const result = await firstValueFrom(this._http.put<any>(`${getHostUrl()}admin/material/storage`, this.group.value));
        this._router.navigate(['material/storage/details', result.storageId]);
      }
      this._config.showSnackbar("Lagerplatz wurde gespeichert!", 3000, 'success');
      this.isSaving = false;
      this.group.enable();
    } catch (e) {
      this.isSaving = false;
      this.group.enable();
      if (e?.status == 409) {
        return this._config.showSnackbar("Ein Lagerplatz mit diesem Namen existiert bereits!", 3000, 'error');
      }
      this._config.showSnackbar("Konnte Lagerplatz nicht speichern", 3000, 'error');
    }
  }

  async loadVehicles() {
    this.vehicleList = (await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/vehicles`))).sort((a,b) => {
      return String(a.name).localeCompare(b.name);
    });
  }

  async loadLocations() {
    this.locationList = (await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/locations`))).sort((a,b) => {
      return String(a.name).localeCompare(b.name);
    });
  }

  async loadStorage() {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/storage/${this.storageId}`));
      result.vehicleId = result.vehicleId == null ? -1 : result.vehicleId;
      result.locationId = result.locationId == null ? -1 : result.locationId;
      this.group.setValue(result);
      this.isLoading = false;
    } catch (e) {
      if (e.status == 404) {
        this._config.showSnackbar('Lagerplatz wurde nicht gefunden!', 3000, 'error');
        this._router.navigate(['material/storage']);
      } else {
        this._router.navigate(['material/storage']);
        this._config.showSnackbar('Ein Fehler ist aufgetreten, bitte versuche es erneut!', 3000, 'error');
      }
    }
  }
}
