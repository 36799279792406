<div class="container">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">Einstellungen</h3>
        <mat-card>
            <mat-card-content>
                <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                    <mat-icon>keyboard_backspace</mat-icon>
                </a>

                <mat-form-field class="input-full-width">
                    <mat-label>Darkmode</mat-label>
                    <mat-select [(ngModel)]="darkModeOptionSelected" (selectionChange)="updateDarkMode()">
                        <mat-option *ngFor="let mode of darkModeOptions" [value]="mode">
                            {{mode}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="input-full-width">
                    <mat-label>Anzeigereihenfolge</mat-label>
                    <mat-select [(ngModel)]="settingsObj.globalSort" (selectionChange)="storeSettings()">
                        <mat-option value="1">Vorname | Nachname</mat-option>
                        <mat-option value="0">Nachname | Vorname</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <h4 class="section-header">Benachrichtigungen</h4>
        <app-notification-settings></app-notification-settings>
    </div>
</div>