<h6>Schichtzeiten</h6>
<ul>
    <li>
        <strong>Tagschicht:</strong> 7 Uhr bis 16 Uhr
    </li>
    <li>
        <strong>Nachtschicht:</strong> 16 Uhr bis 7 Uhr
    </li>
</ul>
<h6>Farbgebung</h6>
<ul>
    <li>
        <span class="bg-danger">rot:</span> Schicht unbesetzt
    </li>
    <li>
        <span class="bg-warning">gelb:</span> Kommentar
    </li>
    <li>
        <span class="bg-success">grün:</span> Schicht besetzt
    </li>
</ul>
<br />
<p>
    Um dich für eine Schicht einzutragen, kannst du auf eine unbesetzte Schicht drücken und anschließend speichern. Um eine Eintragung
    für einen längern Zeitraum vorzunehmen, muss in der Datumsauswahl zuerst das Anfangs- und anschließend das Enddatum (der ausgewählte
    Bereich wird entsprechend markiert) mit entsprechendem Symbol (Tag-/ Nachtschicht) ausgewählt werden.
    Zusätzlich kann ein Beifahrer sowie Kommentar eingegeben werden.<br />
    Du kannst dich nur bis 3 Tage vor Schichtbeginn selbständig aus dem Plan austragen. Kurzfristige Änderungen sind nur
    durch die Responder Leiter möglich. Du erhältst am Vortag (insofern in den Einstellungen nicht deaktiviert) eine
    Erinnerungsemail, jeweils 12 Stunden vor Schichtbeginn für deine Schicht.
</p>