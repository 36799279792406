<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Termin {{group.get('id').value ? 'bearbeiten' : 'anlegen'}}</h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="/event/list" mat-mini-fab color="primary" class="pageBack" *ngIf="newEvent">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack" *ngIf="!newEvent">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <button (click)="copyEvent()" mat-mini-fab color="primary" class="pageNext" *ngIf="group.get('id').value">
          <mat-icon>copy_all</mat-icon>
        </button>
        <form [formGroup]="group">
          <div class="row">
            <div class="col-lg-9 col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Veranstaltungstitel</mat-label>
                <input matInput #input formControlName="title" maxlength="26" />
                <mat-hint align="end">{{input.value?.length || 0}}/26</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-lg-2 col-9" [class.mt-3]="!env.mobile" [ngSwitch]="group.get('status').value">
              <button mat-raised-button class="input-full-width" color="primary" *ngSwitchCase="'angelegt'"
                (click)="group.get('status').setValue('sichtbar')">
                in Prüfung <mat-icon>preview</mat-icon>
              </button>
              <button mat-raised-button class="input-full-width" color="primary" *ngSwitchCase="'sichtbar'"
                (click)="group.get('status').setValue('angelegt')">
                sichtbar <mat-icon>verified</mat-icon>
              </button>
              <button mat-raised-button class="input-full-width" color="primary" *ngSwitchCase="'abgeschlossen'">
                abgeschlossen <mat-icon>check_circle_outline</mat-icon>
              </button>
            </div>
            <div class="col-lg-1 col-3 mt-1" [ngSwitch]="group.get('visibility').value">
              <button mat-fab class="input-full-width" color="primary" *ngSwitchCase="false"
                (click)="group.get('visibility').setValue(true)">
                <mat-icon matTooltip="sichtbar für Teilnehmer / Führung" matTooltipPosition="above">lock</mat-icon>
              </button>
              <button mat-fab class="input-full-width" color="primary" *ngSwitchCase="true"
                (click)="group.get('visibility').setValue(false)">
                <mat-icon matTooltip="sichtbar für alle" matTooltipPosition="above">visibility</mat-icon>
              </button>
            </div>
            <div class="col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Beschreibung</mat-label>
                <textarea matInput formControlName="description" cdkTextareaAutosize></textarea>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-checkbox formControlName="allDay" color="warn">Ganztägig</mat-checkbox>
            </div>
            <div class="col-12 mt-2" [class.col-md-3]="!group.get('allDay').value"
              [class.col-md-6]="group.get('allDay').value" formGroupName="start">
              <mat-form-field floatLabel="always" class="input-full-width" appearance="outline">
                <mat-label>Beginn</mat-label>
                <input matInput formControlName="date" readonly [matDatepicker]="pickerStart"
                  (focus)="pickerStart.open()" class="pointer" [class.mobile]="env.mobile" />
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart [touchUi]="env.mobile"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-12 mt-2" formGroupName="start" *ngIf="!group.get('allDay').value">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Uhrzeit Beginn</mat-label>
                <input matInput formControlName="time" [ngxMatTimepicker]="timePickerFrom" readonly [format]="24"
                  class="pointer" [required]="!group.get('allDay').value">
                <ngx-mat-timepicker #timePickerFrom></ngx-mat-timepicker>
              </mat-form-field>
            </div>
            <div class="col-12 mt-2" [class.col-md-3]="!group.get('allDay').value"
              [class.col-md-6]="group.get('allDay').value" formGroupName="end">
              <mat-form-field floatLabel="always" class="input-full-width" appearance="outline">
                <mat-label>Ende</mat-label>
                <input matInput formControlName="date" readonly [matDatepicker]="pickerEnd" (focus)="pickerEnd.open()"
                  class="pointer" [class.mobile]="env.mobile" [min]="group.get('start').get('date').value" />
                <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd [touchUi]="env.mobile"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-12 mt-2" formGroupName="end" *ngIf="!group.get('allDay').value">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Uhrzeit Ende</mat-label>
                <input matInput formControlName="time" [ngxMatTimepicker]="timePickerTo" readonly [format]="24"
                  class="pointer" [required]="!group.get('allDay').value"
                  [min]="checkDateValid().sameDay ? group.get('start').get('time').value : null">
                <ngx-mat-timepicker #timePickerTo></ngx-mat-timepicker>
              </mat-form-field>
            </div>
            <div class="col-12" [class.mb-2]="!group.get('limitResponse').get('active').value">
              <div class="row" formGroupName="limitResponse">
                <div class="col-12">
                  <mat-checkbox formControlName="active" color="warn">Rückmeldungszeitrum begrenzen</mat-checkbox>
                </div>
                <ng-container *ngIf="group.get('limitResponse').get('active').value">
                  <div class="col-md-3 col-12 mt-2" formGroupName="start">
                    <mat-form-field floatLabel="always" class="input-full-width" appearance="outline">
                      <mat-label>Beginn</mat-label>
                      <input matInput formControlName="date" readonly [matDatepicker]="pickerResponseStart"
                        [min]="group.get('id').value ? event.limitResponse.start.date : today"
                        [max]="group.get('end').get('date').value || today" (focus)="pickerResponseStart.open()"
                        class="pointer" [class.mobile]="env.mobile"
                        [required]="group.get('limitResponse').get('active').value" />
                      <mat-datepicker-toggle matSuffix [for]="pickerResponseStart"></mat-datepicker-toggle>
                      <mat-datepicker #pickerResponseStart [touchUi]="env.mobile">
                      </mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-12 mt-2" formGroupName="start">
                    <mat-form-field class="input-full-width" appearance="outline"
                      (click)="responseTimePickerFrom.open()">
                      <mat-label>Uhrzeit Beginn</mat-label>
                      <input matInput formControlName="time" [ngxMatTimepicker]="responseTimePickerFrom" readonly
                        [format]="24" class="pointer" [required]="group.get('limitResponse').get('active').value"
                        [max]="checkResponseTimeValid().sameDay ? group.get('end').get('time').value : null">
                      <ngx-mat-timepicker #responseTimePickerFrom></ngx-mat-timepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-12 mt-2" formGroupName="end">
                    <mat-form-field floatLabel="always" class="input-full-width" appearance="outline">
                      <mat-label>Ende</mat-label>
                      <input matInput formControlName="date" readonly [matDatepicker]="pickerResponseEnd"
                        [min]="group.get('limitResponse').get('start').get('date').value || today"
                        [max]="group.get('end').get('date').value || today" (focus)="pickerResponseEnd.open()"
                        class="pointer" [class.mobile]="env.mobile"
                        [required]="group.get('limitResponse').get('active').value" />
                      <mat-datepicker-toggle matSuffix [for]="pickerResponseEnd"></mat-datepicker-toggle>
                      <mat-datepicker #pickerResponseEnd [touchUi]="env.mobile"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-12 mt-2" formGroupName="end">
                    <mat-form-field class="input-full-width" appearance="outline" (click)="responseTimePickerTo.open()">
                      <mat-label>Uhrzeit Ende</mat-label>
                      <input matInput formControlName="time" [ngxMatTimepicker]="responseTimePickerTo" readonly
                        [format]="24" class="pointer" [required]="group.get('limitResponse').get('active').value"
                        [min]="checkResponseTimeValid().sameDay ? group.get('limitResponse').get('start').get('time').value : null"
                        [max]="checkResponseTimeValid().sameDay ? group.get('end').get('time').value : null">
                      <ngx-mat-timepicker #responseTimePickerTo></ngx-mat-timepicker>
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-12" [class.mb-2]="!group.get('triggerReminder').get('active').value">
              <div class="row" formGroupName="triggerReminder">
                <div class="col-12">
                  <mat-checkbox formControlName="active" color="warn">Trigger Erinnerung Rückmeldung</mat-checkbox>
                </div>
                <ng-container *ngIf="group.get('triggerReminder').get('active').value">
                  <div class="col-md-3 col-12 mt-2" formGroupName="start">
                    <mat-form-field floatLabel="always" class="input-full-width" appearance="outline">
                      <mat-label>Trigger Erinnerung</mat-label>
                      <input matInput formControlName="date" readonly [matDatepicker]="pickerTriggerReminder"
                        [max]="group.get('start').get('date').value || today" (focus)="pickerTriggerReminder.open()"
                        class="pointer" [class.mobile]="env.mobile"
                        [required]="group.get('triggerReminder').get('active').value" />
                      <mat-datepicker-toggle matSuffix [for]="pickerTriggerReminder"></mat-datepicker-toggle>
                      <mat-datepicker #pickerTriggerReminder [touchUi]="env.mobile">
                      </mat-datepicker>
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="row" style="margin-left: unset; margin-right: unset;">
                <mat-button-toggle-group formControlName="orga" class="input-full-width" [class.p-0]="env.mobile">
                  <mat-button-toggle value="technic" class="col-md-6 col-12">
                    Technik <mat-icon class="ms-2">local_fire_department</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="foundation" class="col-md-6 col-12" [class.borderType]="env.mobile">
                    Verein <mat-icon class="ms-2">foundation</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="row" style="margin-left: unset; margin-right: unset;">
                <mat-button-toggle-group formControlName="type" class="input-full-width" [class.p-0]="env.mobile">
                  <mat-button-toggle value="local" class="col-md-4 col-12">
                    Präsenz <mat-icon class="ms-2">business</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="hybrid" class="col-md-4 col-12" [class.borderType]="env.mobile">
                    Hybrid <mat-icon class="ms-2">maps_home_work</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="online" class="col-md-4 col-12" [class.borderType]="env.mobile">
                    Online <mat-icon class="ms-2">language</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="row" [hidden]="group.get('type').value === 'online'" formGroupName="location">
                <div class="col-md-10 col-12">
                  <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress($event)">
                    <mat-label>Straße</mat-label>
                    <input matInput formControlName="street" [required]="group.get('type').value !== 'online'" />
                    <a matSuffix target="_blank" [href]="group.get('location').get('mapsUrl').value">
                      <mat-icon>place</mat-icon>
                    </a>
                  </mat-form-field>
                </div>
                <div class="col-md-2 col-5">
                  <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Hausnummer</mat-label>
                    <input formControlName="appartment" matInput />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-7">
                  <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress($event)">
                    <mat-label>Postleitzahl</mat-label>
                    <input formControlName="zipcode" matInput [required]="group.get('type').value !== 'online'" />
                  </mat-form-field>
                </div>
                <div class="col-12" [class.col-md-5]="group.get('location').get('country').value !== 'Deutschland'"
                  [class.col-md-9]="group.get('location').get('country').value === 'Deutschland'">
                  <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress($event)">
                    <mat-label>Ort</mat-label>
                    <input formControlName="city" matInput [required]="group.get('type').value !== 'online'" />
                  </mat-form-field>
                </div>
                <div class="col-md-4 col-12" *ngIf="group.get('location').get('country').value !== 'Deutschland'">
                  <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
                    <mat-label>Land</mat-label>
                    <input formControlName="country" matInput [required]="group.get('type').value !== 'online'" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row" [hidden]="group.get('type').value === 'local'">
                <div class="col-12">
                  <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Termin URL</mat-label>
                    <input type="url" formControlName="meetingUrl" matInput placeholder="https://teams.microsoft.com"
                      [required]="group.get('type').value !== 'local'" />
                    <mat-error *ngIf="group.get('meetingUrl').invalid">Keine gültige URL eingegeben</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row" formGroupName="attendees">
                <div class="col-12">
                  <mat-form-field class="input-full-width">
                    <mat-label>Ausbilder / Terminverantwortliche</mat-label>
                    <mat-select multiple placeholder="Ausbilder / Terminverantwortliche" formControlName="admin">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="adminFilterCtrl"
                          noEntriesFoundLabel="Kein Gruppen verfügbar"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let group of adminFilter | async" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field class="input-full-width">
                    <mat-label>Erforderliche Teilnehmer</mat-label>
                    <mat-select multiple placeholder="Erforderliche Teilnehmer" formControlName="required">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="requiredFilterCtrl"
                          noEntriesFoundLabel="Kein Gruppen verfügbar"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let group of requiredFilter | async" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field class="input-full-width">
                    <mat-label>Optionale Teilnehmer</mat-label>
                    <mat-select multiple placeholder="Optionale Teilnehmer" formControlName="optional">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="optionalFilterCtrl"
                          noEntriesFoundLabel="Kein Gruppen verfügbar"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let group of optionalFilter | async" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Teilnehmeranzahl</mat-label>
                    <input matInput formControlName="count" type="number" min="1" oninput="validity.valid||(value='');"
                      placeholder="unbegrenzt" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2 managment">
              <div class="row" style="margin-left: unset; margin-right: unset;">
                <mat-button-toggle-group formControlName="eventDescription" [class.p-0]="env.mobile" class="mt-2">
                  <mat-label class="col-md-3 col-12">
                    <h5 class="headerLabel">Übungsbeschreibung</h5>
                  </mat-label>
                  <mat-button-toggle value="0" class="col-md-3 col-12">
                    nicht notwendig
                  </mat-button-toggle>
                  <mat-button-toggle value="1" class="col-md-3 col-12">
                    notwendig
                  </mat-button-toggle>
                  <mat-button-toggle value="2" class="col-md-3 col-12">
                    vorhanden
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-button-toggle-group formControlName="resourceManagement" [class.p-0]="env.mobile" class="mt-2">
                  <mat-label class="col-md-3 col-12">
                    <h5 class="headerLabel">Resourcenmanagement</h5>
                  </mat-label>
                  <mat-button-toggle value="0" class="col-md-3 col-12">
                    nicht notwendig
                  </mat-button-toggle>
                  <mat-button-toggle value="1" class="col-md-3 col-12">
                    notwendig
                  </mat-button-toggle>
                  <mat-button-toggle value="2" class="col-md-3 col-12">
                    vorhanden
                  </mat-button-toggle>
                </mat-button-toggle-group>
                </div>

                <div class="col-12 mt-2">
                  <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Kleiderordnung</mat-label>
                    <mat-select formControlName="dressCode">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="dressCodeFilterCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option (click)="group.get('dressCode').setValue(null)">keine Kleiderordnung</mat-option>
                      <mat-option *ngFor="let dressCode of filteredDressCodes | async" [value]="dressCode.id">{{
                        dressCode.name
                        }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <mat-checkbox formControlName="flag" class="mt-2" color="primary">Fahnenabordnung
                  benötigt</mat-checkbox>
              </div>
              <div class="col-12 mt-4">
                <h5>Links</h5>
                <div class="row" *ngFor="let link of getLinksArray().controls; index as i">
                  <ng-container [formGroup]="link">
                    <div class="col-lg-4 col-12">
                      <mat-form-field class="input-full-width" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input formControlName="name" matInput />
                        <mat-icon matSuffix class="pointer" (click)="removeEventLink(i)">remove_circle</mat-icon>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-12">
                      <mat-form-field class="input-full-width" appearance="outline">
                        <mat-label>URL</mat-label>
                        <input type="url" formControlName="url" matInput placeholder="https://beispiel.de" />
                        <mat-error *ngIf="link.get('url').invalid">Keine gültige URL eingegeben</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-12">
                      <mat-form-field class="input-full-width">
                        <mat-label>Sichtbarkeit</mat-label>
                        <mat-select formControlName="admin">
                          <mat-option [value]="false">alle</mat-option>
                          <mat-option [value]="true">nur Ausbilder</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </div>
                <div class="row mt-2" style="margin-bottom: 10px;">
                  <div class="col-lg-12 col-12">
                    <button mat-raised-button color="accent" class="float-left" (click)="addEventLink()">Hinzufügen
                      <mat-icon>library_add</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 submit" [class.submitMobile]="env.mobile">
                <div><button mat-raised-button color="warn" class="float-left" *ngIf="group.get('id').value !== ''"
                    (click)="deleteEvent()">
                    Termin löschen <mat-icon>delete</mat-icon>
                  </button></div>
                <div><button mat-raised-button color="warn" class="float-left" *ngIf="group.get('id').value !== ''"
                    (click)="cancelEvent()">
                    Termin absagen <mat-icon>alarm_off</mat-icon>
                  </button></div>
                <div [class.mt-2]="env.mobile"><button mat-raised-button color="success" class="float-right"
                    (click)="saveEvent()" [disabled]="!group.valid">
                    {{group.get('id').value !== '' ? 'Speichern' : 'Anlegen'}}
                    <mat-icon>{{group.get('id').value !== '' ? 'save' : 'add'}}</mat-icon>
                  </button></div>
              </div>
            </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>