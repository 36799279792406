import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-statistics',
  templateUrl: './help-statistics.component.html',
  styleUrls: ['./help-statistics.component.css'],
})
export class HelpStatisticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
