<mat-dialog-header class="mb-3">
  <div class="clearfix">
      <a mat-icon-button (click)="saveAlarmInvoice()" class="closeButtonDialog float-right">
          <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Rechnungsdaten bearbeiten</h2>
  </div>
</mat-dialog-header>

<mat-dialog-content>
  <mat-spinner color="primary" *ngIf="showLoader"></mat-spinner>
  <form [formGroup]="group" class="row" [hidden]="showLoader">
    <div class="col-lg-2 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Anrede</mat-label>
        <mat-select formControlName="sex">
          <mat-option value=""></mat-option>
          <mat-option value="w">Frau</mat-option>
          <mat-option value="m">Herr</mat-option>
        </mat-select>
        <mat-error *ngIf="!group.get('sex').valid">Anrede muss ausgewählt werden!</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-5 col-md-6 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Empfänger</mat-label>
        <input matInput formControlName="name" required>
        <mat-error *ngIf="!group.get('name').valid">Empfänger ist ein Pflichtfeld!</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-5 col-md-12 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>zu Händen von</mat-label>
        <input matInput formControlName="handsTo">
      </mat-form-field>
    </div>
    <div class="col-lg-10 col-md-8 col-12">
      <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
        <mat-label>Straße</mat-label>
        <input matInput formControlName="street" readonly="true" required (keyup.tab)="findAddress()">
        <mat-error *ngIf="!group.get('street').valid">
          Straße ist ein Pflichtfeld!
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-4 col-4">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Hausnummer</mat-label>
        <input matInput formControlName="housenumber">
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Adresszusatz</mat-label>
        <input matInput formControlName="additionalStreet" />
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-6 col-6">
      <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
        <mat-label>PLZ</mat-label>
        <input matInput formControlName="zipcode" readonly="true" required>
        <mat-error *ngIf="!group.get('zipcode').valid">
          Postleitzahl muss angegeben werden
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-8 col-md-6 col-6">
      <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
        <mat-label>Ort</mat-label>
        <input matInput formControlName="city" readonly="true" required>
        <mat-error *ngIf="!group.get('city').valid">
          Ort muss angegeben werden
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="group.get('country').value !== 'Deutschland'">
      <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
        <mat-label>Land</mat-label>
        <input matInput formControlName="country" readonly="true" required>
        <mat-error *ngIf="!group.get('country').valid">
          Land muss angegeben werden
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Telefonnummer</mat-label>
        <input type="tel" matInput placeholder="+491712345678" formControlName="phone">
        <mat-error *ngIf="group.get('phone').value && !group.get('phone').hasError('pattern')">
          benötigtes Format: +491712345678
        </mat-error>
      </mat-form-field>
    </div>


    <div class="col-lg-6 col-md-6 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Kennzeichen</mat-label>
        <input matInput formControlName="numplate" placeholder="M-1234">
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Erläuterung</mat-label>
        <textarea matInput formControlName="explanation" style="resize: vertical;" rows="3" required></textarea>
        <mat-error *ngIf="!group.get('explanation').valid">Erläuterung ist ein Pflichtfeld!</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>