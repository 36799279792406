import { HttpClient } from '@angular/common/http';
import { AfterContentInit, ChangeDetectorRef, Component, Host, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray } from '@angular/forms';

import * as dayjs from 'dayjs';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { AlarmsComponent } from '../alarms-edit.component';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlarmsDamageComponent } from '../alarms-damage/alarms-damage.component';
import { AlarmsInvoiceComponent } from '../alarms-invoice/alarms-invoice.component';
import { PreventCloseGuard } from 'src/app/util/guards/prevent-close.guard';

@Component({
  selector: 'app-alarms-details',
  templateUrl: './alarms-details.component.html',
  styleUrls: ['./alarms-details.component.css'],
})
export class AlarmsDetailsComponent implements AfterContentInit, PreventCloseGuard, OnDestroy {

  constructor(public _login: Login,
    private _http: HttpClient,
    private _formBuilder: UntypedFormBuilder,
    private _config: ConfigModule,
    private _dialog: MatDialog,
    @Host() public _host: AlarmsComponent,
    private _ref: ChangeDetectorRef) { }

  @ViewChild('recommendations', { static: false }) recommendationsDialog: TemplateRef<MatDialog>;

  group = new UntypedFormGroup({});
  initialValue: any = null;
  otherOrgList: any;
  public otherOrgFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public otherOrgFilter: ReplaySubject<any> = new ReplaySubject<any>(1);
  invCategoryList: any;
  public invCategoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public invCategoryFilter: ReplaySubject<any> = new ReplaySubject<any>(1);
  damageObjList: any;
  public damageObjFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public damageObjFilter: ReplaySubject<any> = new ReplaySubject<any>(1);
  waterIntakeList: any;
  public waterIntakeFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public waterIntakeFilter: ReplaySubject<any> = new ReplaySubject<any>(1);
  shouldRecommend = false;
  openRecommend = true;
  recommendation_orgs = [];
  group_recommendation = new UntypedFormGroup({});
  recommendationsDialogRef: MatDialogRef<any> = null;
  alarmType = 'technic';
  disabledElements = false;
  leaderList = [];
  isPricable: boolean = false;

  public leaderFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public leaderFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  onCloseValid(): boolean {
    return this.group.touched;
  }

  ngAfterContentInit() {
    this._host.showLoader = true;

    this.loadLeader();

    this.group = this._formBuilder.group({
      invCategory: ['', Validators.required],
      otherOrg: ['', Validators.nullValidator],
      damageObj: ['', Validators.nullValidator],
      waterIntake: ['', Validators.nullValidator],
      leader: ['', Validators.required],
      isPriceable: ['0', Validators.required],
      invoice: this._formBuilder.group({
        sex: [''],
        name: [''],
        handsTo: [''],
        street: ['', Validators.required],
        additionalStreet: [''],
        housenumber: ['', Validators.required],
        zipcode: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', Validators.required],
        numplate: [''],
        phone: ['', Validators.pattern(/^([+]\d{2})\d{8,12}$/)],
        explanation: ['', Validators.required],
      }),
      medical: this._formBuilder.group({
        aed: [null],
        arrival: [null],
        dead: ['0', null],
        injured: ['0', null],
        cared: ['0', null],
        measure: [[], null],
      }),
    });
    if (!this.disabledElements)
      this.group.get('invCategory').valueChanges
        .subscribe(event => {
          const invoiceType = this.invCategoryList?.find(type => type.id === event);
          if (invoiceType)
            this.group.get('isPriceable').setValue(String(invoiceType.isPriceable));
          if (this.group.get('invCategory').valid) {
            this.group.get('isPriceable').enable();
          } else {
            this.group.get('isPriceable').disable();
          }
          this.group.updateValueAndValidity();
        });
    this.initialValue = this.group.value;

    this.group.get('isPriceable').valueChanges
      .subscribe((event: string) => {
        if (event === '0') {
          this.group.setControl('invoice', this._formBuilder.group({
            sex: [''],
            name: [''],
            handsTo: [''],
            street: [''],
            additionalStreet: [''],
            housenumber: [''],
            zipcode: [''],
            city: [''],
            country: [''],
            numplate: [''],
            phone: [''],
            explanation: [''],
          }));
          this.isPricable = false;
        } else if (event === '1') {
          this.group.setControl('invoice', this._formBuilder.group({
            sex: [''],
            name: [''],
            handsTo: [''],
            street: ['', Validators.required],
            additionalStreet: [''],
            housenumber: ['', Validators.required],
            zipcode: ['', Validators.required],
            city: ['', Validators.required],
            country: ['', Validators.required],
            numplate: [''],
            phone: ['', Validators.pattern(/^([+]\d{2})\d{8,12}$/)],
            explanation: ['', Validators.required],
          }));
          this.isPricable = true;
          this.fetchAlarmInvoice(this._host.alarmID);
        }
        this.group.updateValueAndValidity();
      });
    this.group_recommendation = this._formBuilder.group({
      organization: this._formBuilder.array([]),
    });

    this.fetchDataInfo().then(() => {
      if (this._host.alarmID) {
        this.fetchAlarmDetail();
      } else
        this._host.showLoader = false;
    });
  }

  fetchAlarmInvoice(alarmID: number) {
    return new Promise((resolve, reject) => {
      this._http.get<any>(getHostUrl() + 'alarms/invoice?alarmID=' + alarmID)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(data => {
          if (data) {
            this.group.get('invoice').patchValue({
              sex: data.sex,
              name: data.name,
              handsTo: data.handsTo,
              street: data.street === 'na' ? '' : data.street,
              additionalStreet: data.additionalStreet,
              housenumber: data.housenumber,
              zipcode: data.zipcode === 0 ? '' : data.zipcode,
              city: data.city,
              country: data.country,
              numplate: data.numplate,
              phone: data.phone,
              explanation: data.explanation,
            });
            this.group.markAllAsTouched();
            this._ref.markForCheck();
            this.initialValue = this.group.value;
          }
          resolve(true);
        }, error => {
          console.log(error.error);
        });
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  fetchAlarmDetail() {
    this._host.showLoader = true;
    this._http.get<any>(getHostUrl() + 'alarms/detail?alarmID=' + this._host.alarmID)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(data => {
        this._config.setTitle('Einsatzbericht');
        this._host.showLoader = false;
        this.group.patchValue({
          invCategory: data.invCategory,
          otherOrg: data.otherOrgListSelected,
          damageObj: data.damageObjListSelected,
          waterIntake: data.waterIntakeListSelected,
          leader: data.leader,
          isPriceable: String(data.isPriceable ?? '0'),
        });
        this._ref.detectChanges();
        this.group.get('medical').patchValue(data.patientInfo);
        this.alarmType = data.alarmType ?? 'technic';
        if (this.alarmType === 'medical') {
          Object.keys((this.group.get('medical') as UntypedFormGroup).controls).forEach(key => {
            if (key !== 'measure')
              this.group.get('medical').get(key).setValidators(Validators.required);
          });
        }

        if ((data.otherOrgListSelected.length === 0 || data.otherOrgListSelected[0] === '') && this._host.status === 'in Bearbeitung') {
          this.shouldRecommend = true;
        }
        this.initialValue = this.group.value;
        this.group.updateValueAndValidity();
        this.group.markAllAsTouched();
      });
  }

  loadRecommendation() {
    this._host.showLoader = true;
    this._http.get<any>(getHostUrl() + 'alarms/detail/recommandation?alarmID=' + this._host.alarmID)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(data => {
        this.recommendation_orgs = data;

        const length = (this.group_recommendation.get('organization') as UntypedFormArray).length;
        for (let i = 0; i < length; i++)
          (this.group_recommendation.get('organization') as UntypedFormArray).removeAt(0);

        this.recommendation_orgs.forEach(org => {
          (this.group_recommendation.get('organization') as UntypedFormArray).push(this._formBuilder.control(true));
        });
        (this.group_recommendation.get('organization') as UntypedFormArray)[1] = false;
        this._host.showLoader = false;
        this.openRecommendation();
      });
  }

  openRecommendation() {
    if (this.shouldRecommend && this.openRecommend && this.recommendation_orgs.length !== 0)
      this.recommendationsDialogRef = this._dialog.open(this.recommendationsDialog, { disableClose: true });
    this.openRecommend = false;
    this.shouldRecommend = false;
  }

  setRecommendation() {
    this.recommendationsDialogRef.close();
    var result = [];
    for (let i = 0; i < (this.group_recommendation.get('organization').value).length; i++) {
      if (this.group_recommendation.get('organization').value[i] === true)
        result.push(this.recommendation_orgs[i]);
    }

    this.group.patchValue({
      otherOrg: result,
    });
    this.saveAlarmDetail().then(() => {
      this.fetchAlarmDetail();
    });
  }

  protected filterOtherOrgs() {
    if (!this.otherOrgList) {
      return;
    }
    let search = this.otherOrgFilterCtrl.value;
    if (!search) {
      this.otherOrgFilter.next(this.otherOrgList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.otherOrgFilter.next(
      this.otherOrgList.filter(otherOrg => otherOrg.name.toLowerCase().indexOf(search) > -1 || otherOrg.name.toLowerCase().indexOf(search) > -1));
  }

  protected filterDamageObjs() {
    if (!this.damageObjList) {
      return;
    }
    let search = this.damageObjFilterCtrl.value;
    if (!search) {
      this.damageObjFilter.next(this.damageObjList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.damageObjFilter.next(
      this.damageObjList.filter(damageObj => damageObj.type.toLowerCase().indexOf(search) > -1 || damageObj.type.toLowerCase().indexOf(search) > -1));
  }

  protected filterLeader() {
    if (!this.leaderList) {
      return;
    }
    let search = this.leaderFilterCtrl.value;
    if (!search) {
      this.leaderFilter.next(this.leaderList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.leaderFilter.next(
      this.leaderList.filter(person => person.name.toLowerCase().indexOf(search) > -1 || person.id.toString().startsWith(search)));
  }

  protected filterWaterIntakes() {
    if (!this.waterIntakeList) {
      return;
    }
    let search = this.waterIntakeFilterCtrl.value;
    if (!search) {
      this.waterIntakeFilter.next(this.waterIntakeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.waterIntakeFilter.next(
      this.waterIntakeList.filter(waterIntake => waterIntake.type.toLowerCase().indexOf(search) > -1 || waterIntake.type.toLowerCase().indexOf(search) > -1));
  }

  protected filterinvCategory() {
    if (!this.invCategoryList) {
      return;
    }
    let search = this.invCategoryFilterCtrl.value;
    if (!search) {
      this.invCategoryFilter.next(this.invCategoryList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.invCategoryFilter.next(
      this.invCategoryList.filter(invCategory => invCategory.name.toLowerCase().indexOf(search) > -1 ||
        invCategory.name.toLowerCase().indexOf(search) > -1),
    );
  }


  fetchDataInfo() {
    return new Promise((resolve, reject) => {
      Promise.all([
        this._http.get(`${getHostUrl()}datainfo/otherOrg`).toPromise(),
        this._http.get(`${getHostUrl()}datainfo/damageObj`).toPromise(),
        this._http.get(`${getHostUrl()}datainfo/waterIntake`).toPromise(),
        this._http.get(`${getHostUrl()}datainfo/invCategory`).toPromise(),
      ]).then(values => {
        this.otherOrgList = values[0] || [];
        this.damageObjList = values[1] || [];
        this.waterIntakeList = values[2] || [];
        this.invCategoryList = values[3] || [];

        this.otherOrgFilter.next(this.otherOrgList.slice());
        this.otherOrgFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterOtherOrgs();
          });
        this.damageObjFilter.next(this.damageObjList.slice());
        this.damageObjFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDamageObjs();
          });
        this.waterIntakeFilter.next(this.waterIntakeList.slice());
        this.waterIntakeFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterWaterIntakes();
          });

        this.invCategoryFilter.next(this.invCategoryList.slice());
        this.invCategoryFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterinvCategory();
          });

        this.leaderFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterLeader();
          });

        resolve(true);
      }).catch(reject);
    });
  }

  saveAlarmDetail() {
    return new Promise<void>((resolve, reject) => {
      this.group.markAllAsTouched();
      if (this._host.alarmID) {
        this._host.showLoader = true;

        const data = this.group.value;
        data.date = dayjs(data.date).format('YYYY-MM-DD');
        data.patientInfo = this.group.get('medical').value;

        this._http.post(getHostUrl() + 'alarms/detail', {
          alarmID: this._host.alarmID,
          alarmObj: data,
        }).subscribe(resp => {
          this._host.showLoader = false;
          this.initialValue = this.group.value;
          resolve();
        }, error => {
          this._host.showLoader = false;
          if (error.error === 5001)
            this._config.showSnackbar('Detail Daten nicht vorhanden!', 2000, 'error');
          else
            this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
          reject();
        });
      }
    });
  }

  openDamage() {
    this._dialog.open(AlarmsDamageComponent, {
      data: {
        group: this.group.get('medical'),
        required: this.alarmType === 'medical',
      },
      disableClose: true,
    }).afterClosed()
      .subscribe(data => {
      });
  }

  openInvoice() {
    this._dialog.open(AlarmsInvoiceComponent, {
      data: {
        alarmId: this._host.alarmID,
        group: this.group.get('invoice'),
        disabledElements: this.disabledElements,
      },
    }).afterClosed()
      .subscribe(data => {
        if (data && !this.disabledElements)
          this.saveAlarmDetail();
      });
  }

  getInvoiceColor(): string {
    return this.isPricable ? (this.group.get('invoice').valid || this.disabledElements ? 'success' : 'warn') : 'accent';
  }

  loadLeader() {
    this._http.get<any>(`${getHostUrl()}alarms/leaders?alarmId=${this._host.alarmID}`).subscribe(data => {
      this.leaderList = data;
      this.leaderFilter.next(this.leaderList.slice());
    });
  }

  getOrganizationName(search) {
    return this.otherOrgList.filter(otherOrg => otherOrg.id === search)[0].name;
  }

  getDamageObjectName(search) {
    return this.damageObjList.filter(damageObj => damageObj.id === search)[0].name;
  }

  getWaterIntakeName(search) {
    return this.waterIntakeList.filter(waterIntake => waterIntake.id === search)[0].name;
  }

  getInvCategoryName(search) {
    return this.invCategoryList.filter(invCategory => invCategory.id === search)[0].name;
  }

  orgReset() {
    this.otherOrgFilterCtrl.reset();
    $('#org_search').find('input').trigger('focus');
  }

}
