import { Component, OnInit } from '@angular/core';
import { ConfigModule } from 'src/app/util/config';

@Component({
  selector: 'app-guards',
  templateUrl: './guards.component.html',
  styleUrls: ['./guards.component.css'],
})
export class AdminGuardsComponent implements OnInit {

  constructor(private _config: ConfigModule) { }

  ngOnInit(): void {
    this._config.setTitle('Fachbereich | Sicherheitswachen');
  }

}

export function translatePosition(position: number, short: boolean = false): string {
  switch (position) {
    case 1:
      return short ? '1. WL' : '1. Wachleiter';
    case 2:
      return short ? '2. WL' : '2. Wachleiter';
    case 3:
      return short ? '3. WL' : '3. Wachleiter';
    case 4:
      return short ? '1. WM' : '1. Wachmann';
    case 5:
      return short ? '2. WM' : '2. Wachmann';
    case 6:
      return short ? '3. WM' : '3. Wachmann';
    default:
      return 'error';
  }
}