<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-12 mb-2">
      <mat-form-field appearance="outline" class="input-full-width">
        <input matInput placeholder="Suche..." [formControl]="searchField" />
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-4 col-4" *ngFor="let icon of sortedIcons">
      <button class="text-center icon-picker-button mb-2" [color]="currentIcon == icon ? 'primary' : null" mat-raised-button (click)="currentIcon = icon">
        <mat-icon>{{icon.id}}</mat-icon><br/>{{icon.name}}
      </button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="success" (click)="close()" [disabled]="!currentIcon">Auswählen</button>
</mat-dialog-actions>