<div class="container">
  <div class="col-lg-10 col-12 offset-lg-1">
    <h3 class="section-header">Prüfzyklen</h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche">
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-12">
            <div class="table-responsive">
              <div *ngIf="isLoading">
                <mat-progress-bar mode="query"></mat-progress-bar>
              </div>
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 input-full-width"
                matSortActive="name" matSortDirection="asc" matSortDisableClear>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px">Name
                  </th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    {{element.name}}</td>
                </ng-container>

                <!-- Cycle Column -->
                <ng-container matColumnDef="cycle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 300px; vertical-align: middle;">
                    Prüfzyklus
                  </th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    {{element.days}} T
                    {{element.months}} M
                    {{element.years}} J
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons>
            </mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #menu>
  <div class="clearfix">
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-nav-list class="mt-4">
    <mat-list-item routerLink="new" (click)="_bottomSheet.dismiss()">
      <mat-icon matListItemIcon>post_add</mat-icon>
      <a matListItemLine>Neuen Prüfzyklus erstellen</a>
    </mat-list-item>
  </mat-nav-list>
</ng-template>
