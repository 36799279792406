import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigModule, getHostUrl } from '../../../../util/config';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatPaginatorIntlCro } from '../../../../util/matPaginatorIntlCroClass';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../../../util/login';
import { NotificationNavComponent } from '../../../nav/notification-nav/notification-nav.component';
import * as dayjs from 'dayjs';

import * as $ from 'jquery';

export interface Notification {
  id: number;
  title: string;
  read: number;
  from: number;
  firstname: string;
  lastname: string;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    NotificationNavComponent,
  ],
})
export class NotificationsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['title', 'date', 'action'];

  dataSource = new MatTableDataSource();

  notificationList: Notification[] = [];

  existUnreadMessages = false;
  showLoader = true;

  constructor(
    private _config: ConfigModule,
    public _http: HttpClient,
    public _login: Login,
    private _notificationNav: NotificationNavComponent,
  ) { }

  ngOnInit(): void {
    this._config.setTitle('Benachrichtigungen');
    this.loadData();

    this.checkWidth();
    $(window).on('resize', () => {
      this.checkWidth();
    });
  }

  checkWidth() {
    if ($(window).width() < 800) {
      this.displayedColumns = ['title', 'date', 'action'];
    } else {
      this.displayedColumns = ['title', 'date', 'action'];
    }
  }

  loadData() {
    this.existUnreadMessages = false;
    this.showLoader = true;
    this._http
      .get<Notification[]>(
      `${getHostUrl()}user/notifications/list`,
    )
      .subscribe((data) => {
        this.showLoader = false;
        this.notificationList = data;
        this.dataSource = new MatTableDataSource<Notification>();
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.notificationList.forEach((item) => {
          if (item.read === 0) this.existUnreadMessages = true;
          if (item.from === -1) {
            item.firstname = 'System';
            item.lastname = '';
          }
        });
      });
  }

  readAll() {
    const that = this;
    this._http
      .delete<any>(
      `${getHostUrl()}user/notifications/read/all`,
    )
      .subscribe((data) => {
        that.loadData();
        that._notificationNav.loadNotificationCount();
        that._config.showSnackbar(
          'Alle Benachrichtigungen wurden als gelesen markiert.',
          2000,
          'success',
        );
      });
  }

  readSpecific(notificationId: number) {
    const that = this;
    this._http
      .delete<any>(
      `${getHostUrl()}user/notifications/read?notificationId=${notificationId}`,
    )
      .subscribe((data) => {
        that.loadData();
        that._notificationNav.loadNotificationCount();
        that._config.showSnackbar(
          'Benachrichtigung wurde als gelesen markiert.',
          2000,
          'success',
        );
      });
  }

  getFormattedDateTime(dateValue: any) {
    if (dateValue === null || dateValue.toString() === '0000-00-00' || dateValue === '')
      return ('');
    return dayjs(dateValue).format('DD.MM.YYYY HH:mm');
  }
}
