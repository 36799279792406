import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Host, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigModule, getHostUrl, formatDayjs } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import Swal from 'sweetalert2';

import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AddressDialogComponent } from '../../../../util/dialogs/address-dialog/address-dialog.component';
import { AlarmsComponent } from '../alarms-edit.component';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { PreventCloseGuard } from 'src/app/util/guards/prevent-close.guard';

@Component({
  selector: 'app-alarms-general',
  templateUrl: './alarms-general.component.html',
  styleUrls: ['./alarms-general.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class AlarmsGeneralComponent implements OnInit, OnDestroy, PreventCloseGuard {

  constructor(public _login: Login,
    private _http: HttpClient,
    private _spinner: NgxSpinnerService,
    private _formBuilder: UntypedFormBuilder,
    private _config: ConfigModule,
    private _dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
    @Host() public _host: AlarmsComponent,
    private _ref: ChangeDetectorRef) { }

  group = new UntypedFormGroup({});

  disabledElements = false;
  formatDayjs = formatDayjs;

  initialValue: any = null;

  todaysDate = new Date();

  alarmLinesList: any = [];
  public alarmLinesFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public alarmLinesFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  keywordList: any;
  public keywordFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public keywordFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  onCloseValid(): boolean {
    return JSON.stringify(this.initialValue) === JSON.stringify(this.group.value);
  }

  ngOnInit() {
    this.group = this._formBuilder.group({
      street: ['', Validators.required],
      additionalStreet: [''],
      creator_name: [''],
      notes: [''],
      housenumber: [''],
      zipcode: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      keyword: ['', Validators.required],
      alarmLines: [[], Validators.required],
    });

    this.initialValue = this.group.value;

    this.fetchDataInfo().then(() => {
      if (this._host.alarmID) {
        this.fetchAlarmGeneral().then(() => {
          if (this._route.snapshot.params.state === 'new') {
            this.group.markAllAsTouched();
            this._host.stepper.next();
          }
        });
      } else {
        this._spinner.hide();
      }
    });
  }

  fetchAlarmGeneral() {
    return new Promise((resolve, reject) => {
      this._host.showLoader = true;
      this._http.get<any>(`${getHostUrl()}alarms/general?alarmID=${this._host.alarmID}`)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(data => {
          this._config.setTitle('Einsatzbericht');
          Swal.close();
          this._host.status = data.status;
          if (this._host.status !== 'Rechnungsfreigabe')
            this._host.showBigLoader = false;

          if (this._host.status === 'Rechnungserstellung' || this._host.status === 'Rechnung erstellt' || this._host.status === 'Rechnung versendet' || this._host.status === 'ohne Rechnung') {
            this._host.statusCategory = 'invoice';
          } else if (this._host.status === 'Rechnungsfreigabe') {
            this._host.statusCategory = 'invoice';
          } else if (this._host.status === 'Kontrolle abgeschlossen' || this._host.status === 'in Kontrolle') {
            this._host.statusCategory = 'report';
          }

          this.group.patchValue({
            creator_name: data.creator_name,
            notes: data.reviewerNotes,
            keyword: data.keyword,
            street: data.street,
            additionalStreet: data.additionalStreet,
            housenumber: data.housenumber,
            zipcode: data.zipcode,
            city: data.city,
            country: data.country,
            alarmLines: data.alarmLines,
          });

          this.group.markAllAsTouched();
          this._ref.markForCheck();
          this.initialValue = this.group.value;
          this.group.updateValueAndValidity();
          resolve(true);
        });
    });
  }

  protected filterKeywords() {
    if (!this.keywordList) {
      return;
    }
    // get the search keyword
    let search = this.keywordFilterCtrl.value;
    if (!search) {
      this.keywordFilter.next(this.keywordList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.keywordFilter.next(
      this.keywordList.filter(keyword => keyword.name.toLowerCase().indexOf(search) > -1 ||
        keyword.shortName.toLowerCase().indexOf(search) > -1),
    );
  }

  protected filterAlarmlines() {
    if (!this.alarmLinesList) {
      return;
    }
    // get the search keyword
    let search = this.alarmLinesFilterCtrl.value;
    if (!search) {
      this.alarmLinesFilter.next(this.alarmLinesList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.alarmLinesFilter.next(
      this.alarmLinesList.filter(alarmLine => alarmLine.name.toLowerCase().indexOf(search) > -1 ||
      alarmLine.fivetone?.toString().toLowerCase().indexOf(search) > -1),
    );
  }

  fetchDataInfo() {
    return new Promise((resolve, reject) => {
      Promise.all([
        this._http.get(`${getHostUrl()}datainfo/alarmLines?pager=0`).toPromise(),
        this._http.get(`${getHostUrl()}datainfo/keyword`).toPromise(),
      ]).then(values => {
        this.alarmLinesList = values[0] || [];
        this.keywordList = values[1] || [];

        this.keywordFilter.next(this.keywordList.slice());
        this.keywordFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterKeywords();
          });
        this.alarmLinesFilter.next(this.alarmLinesList.slice());
        this.alarmLinesFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterAlarmlines();
          });

        resolve(true);
      }).catch(reject);
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  showKeyword(value): string | undefined {
    return value ? value.name : undefined;
  }

  getKeyword(shortName) {
    return this.keywordList?.find(k => k.shortName === shortName);
  }

  findAddress() {
    const dialogRef = this._dialog.open(AddressDialogComponent, {
      width: '500px',
      data: {
        cities: false,
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === null) {
        return;
      }
      this.group.get('street').setValue(data.street);
      this.group.get('city').setValue(data.city);
      this.group.get('zipcode').setValue(data.zipcode);
      this.group.get('country').setValue(data.country);
    });
  }

  applyKeyword(value) {
    this.group.get('keyword').setValue({
      shortName: value.shortName,
      name: value.name,
    });
  }

  saveAlarmGeneral() {
    this.group.markAllAsTouched();
    if (this._host.alarmID) {
      this._host.showLoader = true;

      const data = this.group.value;

      this._http.post(getHostUrl() + 'alarms/general', {
        alarmID: this._host.alarmID,

        alarmObj: data,
      }).subscribe(resp => {
        this._host.showLoader = false;
        this.initialValue = this.group.value;
      }, error => {
        this._host.showLoader = false;
        if (error.error === 5001)
          this._config.showSnackbar('Allgemeine Daten nicht vorhanden!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      });
    }
  }

  addAlarmGeneral() {
    this._host.ignoreUnsaved = true;
    this._host.showLoader = true;

    const data = this.group.value;

    this._http.post(getHostUrl() + 'alarms/new', {

      alarmObj: data,
    }).subscribe((resp: any) => {
      this._router.navigate(['/alarms']).then(() => this._router.navigate([`/alarms/edit/${resp.alarmID}`], { queryParams: { from: 'new' } }));
    }, error => {
      this._host.showLoader = false;
      if (error.error === 5001)
        this._config.showSnackbar('Alarm konnte nicht erstellt werden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
    });
  }

  getAlarmLineById(id) {
    const search = this.alarmLinesList.find(a => a.id === id);
    return search ? search.name : '';
  }
}
