import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { StatisticData } from '@ffo/mitgliederbereich-types';
import { ECharts, EChartsOption, SeriesOption } from 'echarts';
import { alarmTypes, getColorForSeriesName } from 'src/app/util/config';
import { ThemingService } from 'src/app/util/services/theming.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stackbar-chart-statistic',
  templateUrl: './stackbar-chart-statistic.component.html',
  styleUrls: ['./stackbar-chart-statistic.component.css'],
})
export class StackbarChartStatisticComponent implements OnChanges {

  @Output() chart = new EventEmitter<ECharts>();
  @Input() data: StatisticData[] = [];
  @Input() title: string = '';
  echartsInstance: ECharts;
  xAxis: string[];
  yData: any[];
  yTypes: any[];
  series: any[];

  theme: string = 'shine';
  chartOption: EChartsOption = {};

  env = environment;
  constructor(private _theme: ThemingService) {
    this._theme.theme.subscribe(theme => {
      if (theme === 'rot-dark-theme' || theme === 'standard-dark-theme') {
        this.theme = 'chalk';
      } else {
        this.theme = 'shine';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.xAxis = [...new Set(this.data.map(s => s.key))];
    this.yData = this.data.map(s => {
      const values = (s.value as string).split(':');
      return {
        index: values[2],
        name: values[1],
        data: values[0],
      };
    });
    this.yTypes = [...new Set(this.yData.map(s => s.name))];
    this.series = this.createSeriesArray(this.xAxis.length, this.yTypes);
    const minMonth = Math.min(...new Set(this.yData.map(s => s.index)));
    this.yData.forEach(data => {
      const i = this.yTypes.indexOf(data.name);
      this.series[i].data[data.index - minMonth] = data.data;
    });
    this.chartOption = {
      legend: {
        left: 'center',
        top: 'bottom',
      },
      grid: {
        bottom: this.env.mobile ? 100 : 60,
        height: this.env.mobile ? 280 : 'auto',
      },
      title: {
        text: this.title,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: this.xAxis,
      },
      yAxis: {
        type: 'value',
      },
      series: this.series,
    };
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
    this.chart.emit(this.echartsInstance);
  }

  private createSeriesArray(keyCount: number, seriesNames: string[]): any[] {
    const res: SeriesOption[] = [];
    seriesNames.forEach((series, i) => {
      res.push({
        name: series,
        type: 'bar',
        data: new Array<number>(keyCount).fill(0),
        stack: 'total',
        itemStyle: seriesNames.filter(s => alarmTypes.includes(s)).length === 0 ? null : {
          color: getColorForSeriesName(series)
        },
      });
    });
    return res;
  }
}
