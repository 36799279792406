import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-monthpicker',
  templateUrl: './monthpicker.component.html',
  styleUrls: ['./monthpicker.component.css'],
})
export class MonthpickerComponent implements OnInit {

  months: string[] = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  currentMonth: number | number[];

  @Output() month = new EventEmitter<number | number[]>();
  @Input() label: string = 'Monat';
  @Input() required: boolean = false;
  @Input() multiple: boolean = false;

  date = new UntypedFormControl(null);

  ngOnInit(): void {
    if (this.required && !this.multiple) {
      this.currentMonth = new Date().getMonth();
      this.month.emit(this.currentMonth + 1);
    } else if (this.required && this.multiple) {
      this.currentMonth = [new Date().getMonth()];
      this.month.emit([this.currentMonth[0] + 1]);
    }
  }

  selected($event: MatSelectChange): void {
    if (this.multiple) {
      const months: number[] = ($event.value as number[]).map((value: number) => value + 1);
      this.month.emit(months);
    } else {
      this.month.emit($event.value + 1);
    }
  }
}
