import { Component, OnInit, ViewChildren, QueryList, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MatSlideToggleChange as MatSlideToggleChange } from '@angular/material/slide-toggle';

import * as $ from 'jquery';
import { GuardsComponent } from '../guards.component';

@Component({
  selector: 'app-guards-events-watcher',
  templateUrl: './events-watcher.component.html',
  styleUrls: ['./events-watcher.component.css'],
})
export class EventsWatcherComponent implements AfterViewInit {

  @Input() parent: GuardsComponent;

  env = environment;

  scrollActivated = false;

  all = false;

  constructor(private _route: ActivatedRoute) { }

  @ViewChildren('eventWatcherList') eventWatcherList: QueryList<any>;

  ngAfterViewInit(): void {
    const eventID = this._route.snapshot.params.eventId;
    this.eventWatcherList.changes.subscribe(t => {
      if (eventID && !this.scrollActivated) {
        this.scroll(eventID);
        this.scrollActivated = true;
      }
    });
  }

  scroll(el: string) {
    setTimeout(function () {
      const element = $('#' + el)[0];
      $('#' + el).addClass('highlighted');
      const y = element.getBoundingClientRect().top + window.pageYOffset - 80;

      $('.mat-sidenav-content').stop().animate({ scrollTop: y }, 400);
      setTimeout(function () {
        $('#' + el).removeClass('highlighted');
      }, 1200);
    }, 100);
  }

  allEventsChanged($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.parent.filterGuardEvents(false);
      this.all = false;
    } else {
      this.parent.filterGuardEvents(true);
      this.all = true;
    }
  }
}
