import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, filter, takeUntil, Subject, ReplaySubject } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.css']
})
export class CategoryDetailComponent {
  constructor(private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _config: ConfigModule,
    private _builder: FormBuilder,
    private _bottomSheet: MatBottomSheet) { }

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  storageList = [];
  unitList = [
    {
      id: 'stk',
      name: 'Stück'
    },
    {
      id: 'kg',
      name: 'Kilogramm'
    },
    {
      id: 'g',
      name: 'Gramm'
    },
    {
      id: 'm²',
      name: 'm²'
    },
    {
      id: 'liter',
      name: 'Liter'
    },
    {
      id: 'tonne',
      name: 'Tonne'
    },
    {
      id: 'm',
      name: 'Meter'
    },
  ];

  categoryId = null;

  isSaving = false;
  isLoading = true;

  group: FormGroup = new FormGroup({});

  groupFilterCtrl: FormControl<string> = new FormControl<string>('');
  groupFilter: ReplaySubject<any> = new ReplaySubject<any>(1);
  groupList = [];

  checkFilterCtrl: FormControl<string> = new FormControl<string>('');
  checkFilter: ReplaySubject<any> = new ReplaySubject<any>(1);
  checkList = [];

  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.group = this._builder.group({
      name: ['', Validators.required],
      unit: ['stk'],
      qty: [1],
      price: [0],
      checks: [[]],
      consumables: [false],
      status: ['active'],
      groups: [[], Validators.required],
      options: [[]]
    });

    this._config.setTitle("Kategorieverwaltung");

    this.categoryId = this._route.snapshot.params?.categoryId;
    if (this.categoryId) {
      this.loadCategory();
    } else this.isLoading = false;

    this.loadStorage();
    this.loadChecks();
    this.loadGroups();

    this.groupFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => this.filterGroups())

    this.checkFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => this.filterChecks())
  }

  async saveData() {
    this.group.disable();
    this.isSaving = true;

    try {
      if (this.categoryId) {
        await firstValueFrom(this._http.post(`${getHostUrl()}admin/material/categories/${this.categoryId}`, this.group.value));
      } else {
        const result = await firstValueFrom(this._http.put<any>(`${getHostUrl()}admin/material/categories`, this.group.value));
        this._router.navigate(['material/categories', 'details', result.categoryId]);
      }
      this._config.showSnackbar("Kategorie wurde gespeichert!", 3000, 'success');
    } catch (e) {
      this._config.showSnackbar("Konnte Kategorie nicht speichern!", 3000, 'error');
    }
    this.isSaving = false;
    this.group.enable();
  }

  async loadStorage() {
    this.storageList = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/storage`));
  }

  async loadChecks() {
    this.checkList = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/checks`));
    this.filterChecks();
  }

  async loadGroups() {
    this.groupList = await firstValueFrom(this._http.get<any>(`${getHostUrl()}datainfo/groups`));
    this.filterGroups();
  }

  async loadCategory() {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/categories/${this.categoryId}`));
      this.group.setValue(result);
      this.isLoading = false;
    } catch (e) {
      if (e.status == 404) {
        this._config.showSnackbar('Kategorie wurde nicht gefunden!', 3000, 'error');
        this._router.navigate(['material/categories']);
      } else {
        this._config.showSnackbar('Ein Fehler ist aufgetreten, bitte versuche es erneut!', 3000, 'error');
      }
    }
  }

  filterGroups() {    
    if (!this.groupList) {
      return;
    }
    let search = this.groupFilterCtrl.value;    

    if (!search) {
      this.groupFilter.next(this.groupList.slice());      
      return;
    } else {
      search = search.toLowerCase();
    }
    this.groupFilter.next(
      this.groupList.filter(group => group.name.toLowerCase().indexOf(search) > -1),
    );
  }

  filterChecks() {
    if (!this.checkList) {
      return;
    }
    let search = this.checkFilterCtrl.value;    

    if (!search) {
      this.checkFilter.next(this.checkList.slice());      
      return;
    } else {
      search = search.toLowerCase();
    }
    console.log(search);
    
    this.checkFilter.next(
      this.checkList.filter(check => check.name.toLowerCase().indexOf(search) > -1),
    );
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu);
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  addOption($event: MatChipInputEvent) {
    const option = $event.value;     
    const val = this.group.get('options').value;
    if (!val.includes(option)) {
      val.push(option);
      this.group.get('options').setValue(val);    
      $event.chipInput!.clear();
    }
  }

  editOption(option: string, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeOption(option);
      return;
    }

    // Edit existing fruit
    const val = this.group.get('options').value;
    const index = val.indexOf(option);
    if (index >= 0) {
      val[index] = value;
      this.group.get('options').setValue(val);
    }
  }

  removeOption(option: string) {
    const val = this.group.get('options').value;
    const index = val.indexOf(option);
    if (index >= 0) {
      val.splice(index, 1);
    }
  }
}
