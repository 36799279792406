import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { filter, firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  displayedColumns: string[] = ['name'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  isLoading: boolean = true;

  dayjs = dayjs;

  constructor(private _http: HttpClient,
    private _config: ConfigModule,
    private _dialog: MatDialog,
    public _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this.loadData();

    this._config.setTitle('Kategorieverwaltung');

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'check': return item.cycle ? dayjs(item.cycle).toDate() : null;
        default: return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  async loadData() {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/categories`));
      this.dataSource.data = result;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    } catch (e) {
      this._config.showSnackbar('Fehler beim Laden der Materialien', 2500, 'error');
      this.isLoading = false;
    }
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu);
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }
}
