import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Guard, GuardBilling, GuardAssignmentInfoList, GuardStatus, GuardUser, GuardAssignmentPosition } from '@ffo/mitgliederbereich-types';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.css']
})
export class AdminGuardsAccountingComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  viewColumns: string[] = ['name', 'date'];
  guardList: Guard[] | GuardBilling[] = [];
  guards: MatTableDataSource<Guard | GuardBilling>;

  assignmentInfoList: GuardAssignmentInfoList[] = [];
  activeGuardIndex: number = 0;

  searchFilter: string = '';
  statusFilter: GuardStatus[] = ['angelegt', 'eingeteilt'];
  loading: boolean = true;

  today: Date = new Date();
  timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  referencePrefixList: string[] = [];
  group: FormGroup;
  guardPersonal: FormArray;

  guardUsers: GuardUser[];
  guardLeaderFilterCtrl: UntypedFormControl = new UntypedFormControl();
  guardLeaderFilter: ReplaySubject<GuardUser[]> = new ReplaySubject<GuardUser[]>();
  guardManFilterCtrl: UntypedFormControl = new UntypedFormControl();
  guardManFilter: ReplaySubject<GuardUser[]>[] = [];

  protected _onDestroy = new Subject<void>();
  env = environment;
  constructor(private _http: HttpClient, private _config: ConfigModule) { }

  ngOnInit(): void {
    this._config.setTitle('Sicherheitswachen | Wachen');
    this.loadGuards();
    this.loadGuardUsers();
    this.loadReferencePrefix();

    this.guardPersonal = new FormArray([]);

    this.group = new FormGroup({
      guardEnd: new FormControl('', Validators.required),
      begin: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
      guardLeader: new FormControl('', Validators.required),
      guardLeaderHours: new FormControl({ value: '', disabled: true }, Validators.required),
      alarmReference: new FormControl('', Validators.required),
      alarmReferencePrefix: new FormControl('I', Validators.required),
      guardPersonal: this.guardPersonal
    });

    this.guardLeaderFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterGuardLeader();
        this.filterGuardMan();
      });

    this.guardManFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterGuardLeader();
        this.filterGuardMan();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  loadGuards(): void {
    this.loading = true;
    this._http.get<Guard[] | GuardBilling[]>(getHostUrl() + 'admin/fields/guards/guards')
      .subscribe(data => {
        this.guardList = data.filter((guard: Guard | GuardBilling) => {
          return this.getDateDiff(guard.date, new Date()) <= 0 && (guard.status === 'eingeteilt' || guard.status === 'angelegt');
        }).map((guard: Guard | GuardBilling) => {
          guard.date = new Date(guard.date);
          return guard;
        });
        this.guards = new MatTableDataSource<Guard | GuardBilling>(this.guardList);
        this.guards.sortingDataAccessor = (item: Guard | GuardBilling, property) => {
          switch (property) {
            case 'date':
              const date = DateTime.fromJSDate(item.date).setZone('Europe/Berlin');
              const time = DateTime.fromFormat(item.guardStart, 'HH:mm').setZone('Europe/Berlin');
              return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toJSDate();
            default: return item[property];
          }
        };
        this.guards.paginator = this.paginator;
        this.guards.filterPredicate = this.filter();
        this.applySearch('');

        if(this.activeGuardIndex >= this.guardList.length) {
          this.activeGuardIndex--;
        }

        this.group.get('begin').setValue(dayjs().set('hour', parseInt(this.guardList[this.activeGuardIndex].guardStart.split(':')[0])).set('minute', parseInt(this.guardList[this.activeGuardIndex].guardStart.split(':')[1])).subtract(1, 'hour').format('HH:mm'));
        this.group.get('guardLeader').setValue(parseInt(this.guardList[this.activeGuardIndex].assignment.assigned?.find((assignment: GuardAssignmentPosition) => parseInt(assignment.position.toString()) === 1)?.userID.toString()));
        const guardMan = new FormGroup({
          guard: new FormControl(parseInt(this.guardList[this.activeGuardIndex].assignment.assigned?.find((assignment: GuardAssignmentPosition) => parseInt(assignment.position.toString()) === 4)?.userID.toString()), Validators.required),
          hours: new FormControl({ value: '', disabled: true }, Validators.required)
        })
        this.guardPersonal.push(guardMan);
        this.guardManFilter.push(new ReplaySubject<GuardUser[]>());
        this.guardLeaderFilterCtrl.updateValueAndValidity();
        this.guardManFilterCtrl.updateValueAndValidity();
        this.group.updateValueAndValidity();
        this.loading = false;
      });
  }

  loadGuardUsers(): void {
    this._http.get<GuardUser[]>(getHostUrl() + 'admin/fields/guards/users')
      .subscribe(data => {
        this.guardUsers = data;
        this.guardLeaderFilterCtrl.updateValueAndValidity();
        this.guardManFilterCtrl.updateValueAndValidity();
        this.group.updateValueAndValidity();
      });
  }

  loadReferencePrefix() {
    this._http.get<any>(`${getHostUrl()}datainfo/reference/prefix`).subscribe(result => {
      this.referencePrefixList = result;
    });
  }

  addGuardPersonal() {
    const guardMan = new FormGroup({
      guard: new FormControl('', Validators.required),
      hours: new FormControl({ value: this.getHoursDiff(this.group.get('begin').value, this.group.get('end').value), disabled: this.getHoursDiff(this.group.get('begin').value, this.group.get('end').value) === '--' }, Validators.required)
    })
    this.guardPersonal.push(guardMan);
    this.guardManFilter.push(new ReplaySubject<GuardUser[]>());
    this.filterGuardLeader();
    this.filterGuardMan();
  }

  removeGuardPersonal(i: number) {
    this.guardPersonal.removeAt(i);
    this.guardManFilter = this.guardManFilter.splice(i, 1);
    this.filterGuardLeader();
    this.filterGuardMan();
  }

  applySearch(searchValue: string) {
    this.searchFilter = searchValue.toLowerCase();
    this.guards.filter = JSON.stringify({ search: this.searchFilter });
  }

  filter(): (data: Guard | GuardBilling, filter: string) => boolean {
    let filterFunction = function (data: Guard | GuardBilling, filter: string): boolean {
      if (this.loading) return false;
      let searchTerms: { search: string } = JSON.parse(filter);
      return data.name.toLowerCase().indexOf(searchTerms.search) !== -1;
    };
    return filterFunction;
  }

  protected filterGuardLeader() {
    if (!this.guardUsers) {
      return;
    }

    let search = this.guardLeaderFilterCtrl.value;
    this.guardLeaderFilter.next(this.guardUsers.filter((guard: GuardUser) => {
      if (this.guardPersonal.value.map(g => g.guard).includes(guard.userID)) {
        return false;
      } else if (search && guard.firstname.toLowerCase().indexOf(search.toLowerCase()) === -1 &&
        guard.lastname.toLowerCase().indexOf(search.toLowerCase()) === -1 &&
        guard.userID.toString().indexOf(search) === -1) {
        return false;
      }
      return true;
    }, this));
  }

  protected filterGuardMan() {
    if (!this.guardUsers) {
      return;
    }

    let search = this.guardManFilterCtrl.value;
    this.guardManFilter.forEach((filter: ReplaySubject<GuardUser[]>, index: number) => {
      const personal = this.guardPersonal.value.filter((g, i) => i !== index && g.guard !== '');
      filter.next(this.guardUsers.filter((guard: GuardUser) => {
        if (this.group.get('guardLeader').value === guard.userID || personal.map(g => g.guard).includes(guard.userID)) {
          return false;
        } else if (search && guard.firstname.toLowerCase().indexOf(search.toLowerCase()) === -1 &&
          guard.lastname.toLowerCase().indexOf(search.toLowerCase()) === -1 &&
          guard.userID.toString().indexOf(search) === -1 && !this.guardPersonal.value.map(g => g.guard).includes(guard.userID)) {
          return false;
        }
        return true;
      }, this));
    });
  }

  getDateDiff(begin: Date, end: Date) {
    return dayjs(begin).diff(dayjs(end), 'days');
  }

  getHoursDiff(_begin: string, _end: string) {
    if (!_begin || _begin.length !== 5 || !_end || _end.length !== 5) {
      this.group.get('guardLeaderHours').disable();
      this.guardPersonal.controls.forEach(control => {
        control.get('hours').disable();
      });
      return '--';
    }
    const day = dayjs();
    let begin = day.set('hour', parseInt(_begin.split(':')[0])).set('minute', parseInt(_begin.split(':')[1]));
    let end = day.set('hour', parseInt(_end.split(':')[0])).set('minute', parseInt(_end.split(':')[1]));

    if (begin.minute() >= 53) {
      begin = begin.set('minute', 0).add(1, 'hour');
    } else if (begin.minute() >= 23 && begin.minute() < 53) {
      begin = begin.set('minute', 30);
    } else {
      begin = begin.set('minute', 0);
    }

    // ToDo change 7 to customer settings
    if (end.minute() >= 7 && end.minute() <= 36) {
      end = end.set('minute', 30);
    } else if (end.minute() >= 37) {
      end = end.set('minute', 0).add(1, 'hour');
    } else {
      end = end.set('minute', 0);
    }

    if (end.isBefore(begin)) {
      end = end.add(1, 'day');
    }

    const value = end.diff(begin, 'minutes') / 60;
    this.group.get('guardLeaderHours').setValue(value);
    this.guardPersonal.controls.forEach(control => {
      control.get('hours').setValue(value);
    });
    this.group.enable();
    return value;
  }

  setGuard(guardIndex: number) {
    this.accordion.closeAll();
    this.activeGuardIndex = guardIndex;
    this.group.get('guardEnd').setValue('');
    this.group.get('end').setValue('');
    this.group.get('guardLeaderHours').setValue('');
    this.guardPersonal.clear();
    this.guardLeaderFilterCtrl.setValue('');
    this.guardManFilterCtrl.setValue('');

    this.group.get('begin').setValue(dayjs().set('hour', parseInt(this.guardList[this.activeGuardIndex].guardStart.split(':')[0])).set('minute', parseInt(this.guardList[this.activeGuardIndex].guardStart.split(':')[1])).subtract(1, 'hour').format('HH:mm'));
    this.group.get('guardLeader').setValue(parseInt(this.guardList[this.activeGuardIndex].assignment.assigned?.find((assignment: GuardAssignmentPosition) => parseInt(assignment.position.toString()) === 1)?.userID.toString()));
    const guardMan = new FormGroup({
      guard: new FormControl(parseInt(this.guardList[this.activeGuardIndex].assignment.assigned?.find((assignment: GuardAssignmentPosition) => parseInt(assignment.position.toString()) === 4)?.userID.toString()), Validators.required),
      hours: new FormControl({ value: '', disabled: true }, Validators.required)
    });
    this.guardPersonal.push(guardMan);
    this.group.markAsUntouched();
  }

  parseTimeField($event, fieldName, nextField = null, previousField = null) {
    if ($event.keyCode === 8 || $event.keyCode === 46 || $event.keyCode === 9 || $event.keyCode === 16) {
      return;
    }
    const value: string = this.group.get(fieldName).value;

    if (value.match(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#´]/)) {
      this.group.get(fieldName).setValue(value.replace(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#]/g, ''));
    }

    if (value.length > 5) {
      this.group.get(fieldName).setValue(value.substring(0, 5));
      return;
    }
    if (value.match(this.timePattern) && nextField) {
      $('#' + nextField).trigger('focus');
      return;
    }
    if (!value.includes(':')) {
      if (value.length === 2) {
        this.group.get(fieldName).setValue(value + ':');
      }
    }
    return true;
  }

  save(): void {
    this.guardList[this.activeGuardIndex].guardEnd = this.group.get('guardEnd').value;
    (this.guardList[this.activeGuardIndex] as GuardBilling).billingStart = this.group.get('begin').value;
    (this.guardList[this.activeGuardIndex] as GuardBilling).billingEnd = this.group.get('end').value;

    const alarmData = {
      guardLeader: this.group.get('guardLeader').value,
      guardLeaderHours: this.group.get('guardLeaderHours').value,
      guardPersonal: this.guardPersonal.value,
      alarmReference: this.group.get('alarmReferencePrefix').value + this.group.get('alarmReference').value,
      enddate: parseInt((this.guardList[this.activeGuardIndex] as GuardBilling).billingEnd.split(':')[0]) < parseInt((this.guardList[this.activeGuardIndex] as GuardBilling).billingStart.split(':')[0]) ? dayjs(this.guardList[this.activeGuardIndex].date).add(1, 'day').toDate() : this.guardList[this.activeGuardIndex].date
    }
    this._http.post<Guard | GuardBilling>(`${getHostUrl()}admin/fields/guards/guards/save`, { guard: this.guardList[this.activeGuardIndex], alarmData, accounting: true }).subscribe({
      next: (resp) => {
        this.guardPersonal.clear();
        this.guardManFilter = [];
        this.loadGuards();
        this._config.showSnackbar('Wache erfolgreich abgeschlossen', 2500, 'success');
      },
      error: (err) => {
        console.error(err);
        this._config.showSnackbar('Fehler beim Speichern', 2500, 'error');
      }
    });
  }
}
