<div class="row">
  <div class="col-lg-4 col-12 offset-lg-4">
    <div class="loginFormLoader" [hidden]="!showSpinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <mat-card-header>
          <mat-card-title>Neues Passwort</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="loginForm" name="loginForm">
            <div class="form-group">
              <mat-form-field class="input-full-width">
                <input matInput placeholder="E-Mail" required formControlName="email" type="email" name="email"
                  (keydown.enter)="requestPassword()">
                <mat-error *ngIf="emailFormControl.invalid">Bitte gib einen gültige E-Mail ein!</mat-error>
              </mat-form-field>
            </div>
          </form>
        </mat-card-content>
        <mat-card-actions class="clearfix">
          <div class="float-right">
            <button mat-raised-button (click)="requestPassword()" [disabled]="loginForm.invalid" color="accent">Passwort
              anfordern <mat-icon>vpn_key</mat-icon></button>
          </div>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>