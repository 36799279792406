<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">Änderungskontrolle</h3>
        <mat-card>
            <mat-card-content>
                <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                    <mat-icon>keyboard_backspace</mat-icon>
                </a>
                <div *ngIf="checks.length===0">
                    <p>Keine Änderungsvorgänge vorhanden.</p>
                </div>
                <div [ngClass]="checks.length===0 ? 'hide' : ''" *ngIf="users.length!==0">
                    <div class="table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 input-full-width"
                            matSortActive="time" matSortDirection="desc" matSortDisableClear style="width: 100%;">
                            <!-- ID Column -->
                            <ng-container matColumnDef="user">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="width: auto; padding-right: 15px">
                                    Anforderer
                                </th>
                                <td mat-cell *matCellDef="let element; let i = index;">
                                    {{getUser(element.userID)}}</td>
                            </ng-container>

                            <ng-container matColumnDef="target">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="width: auto; padding-right: 15px">
                                    Benutzer
                                </th>
                                <td mat-cell *matCellDef="let element; let i = index;">
                                    {{getUser(element.targetID)}}</td>
                            </ng-container>

                            <!-- Time Column -->
                            <ng-container matColumnDef="time">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="width: auto; padding-right: 3px; vertical-align: middle;">Uhrzeit
                                </th>
                                <td mat-cell *matCellDef="let element; let i = index;">
                                    {{getFormattedDate(element.time)}}
                                </td>
                            </ng-container>

                            <!-- action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="width: 40%; vertical-align: middle;">
                                    Aktion</th>
                                <td mat-cell *matCellDef="let element; let i = index;">
                                    {{element.action}}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="open(row)"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>