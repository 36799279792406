<mat-spinner *ngIf="isLoading" color="accent" class="loading-spinner-center" [diameter]="200"></mat-spinner>

<div class="container">
  <div class="col-lg-10 col-12 offset-lg-1" *ngIf="!isLoading">
    <h3 class="section-header">Fahrzeug
      <span *ngIf="!vehicleId" class="ms-2">erstellen</span>
      <span *ngIf="vehicleId" class="ms-2">bearbeiten</span>
    </h3>
    <mat-card class="mb-4">
      <mat-card-content>
        <a routerLink="../../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>

        <form [formGroup]="group">
          <h4 class="pb-2">Allgemein</h4>

          <div class="row">
            <div class="col-lg-8 col-10">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Name</mat-label>
                <input matInput type="text" formControlName="name" required="true" />
                <mat-error *ngIf="group.get('name').invalid">Fahrzeug Name ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-4 col-2 text-center">
              <mat-slide-toggle color="accent" class="mt-3 mb-3" formControlName="active">
                <span class="d-lg-block d-none">Fahrzeug im Dienst</span>
              </mat-slide-toggle>
            </div>
          </div>

          <mat-form-field appearance="outline" class="input-full-width">
            <mat-label>Fahrzeugtyp</mat-label>
            <mat-select formControlName="cartype">
              <mat-option [value]="1"><mat-icon svgIcon="loeschfahrzeug"></mat-icon>
                Löschfahrzeug
              </mat-option>
              <mat-option [value]="5"><mat-icon svgIcon="drehleiter"></mat-icon>
                Drehleiter
              </mat-option>
              <mat-option [value]="6"><mat-icon svgIcon="wechsellader"></mat-icon>
                Wechsellader
              </mat-option>
              <mat-option [value]="16"><mat-icon svgIcon="anhaenger"></mat-icon>
                Anhänger
              </mat-option>
              <mat-option [value]="13"><mat-icon svgIcon="gabelstapler"></mat-icon>
                Gabelstapler
              </mat-option>
              <mat-option [value]="15"><mat-icon svgIcon="abroller"></mat-icon>
                Abroller
              </mat-option>
              <mat-option [value]="7"><mat-icon>local_taxi</mat-icon>
                Kleinfahrzeug
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="row">
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Kennzeichen</mat-label>
                <input matInput type="text" formControlName="licensePlate" />
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Fahrzeugstatus</mat-label>
                <mat-select formControlName="status">
                  <mat-option *ngFor="let _ of [].constructor(10); let i = index"
                    [value]="(i).toString()">{{i}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <mat-form-field appearance="outline" class="input-full-width">
            <mat-label>Prüfungen</mat-label>
            <mat-select formControlName="checks" multiple>
              <mat-option *ngFor="let check of checkList" [value]="check.id">{{check.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="col-12">
            <mat-form-field appearance="outline" class="input-full-width">
              <mat-label>Kommentar</mat-label>
              <input matInput type="text" formControlName="comment" />
            </mat-form-field>
          </div>

          <div class="row">
            <hr />
            <h4 class="pb-2">Austattung</h4>
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Sitzplätze</mat-label>
                <mat-select formControlName="seats">
                  <mat-option *ngFor="let _ of [].constructor(31); let i = index" [value]="i">{{i}} Sitze</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('seats').invalid">Sitzplätze sind ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Pumpe eingebaut?</mat-label>
                <mat-select formControlName="hasPump">
                  <mat-option [value]="1">Eingebaut</mat-option>
                  <mat-option [value]="0">Nicht eingebaut</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('hasPump').invalid">Fahrzeugpumpe ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Stromaggregat eingebaut?</mat-label>
                <mat-select formControlName="hasGenerator">
                  <mat-option [value]="1">Eingebaut</mat-option>
                  <mat-option [value]="0">Nicht eingebaut</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('hasGenerator').invalid">Stromaggregat ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Drehleiter eingebaut?</mat-label>
                <mat-select formControlName="hasLadder">
                  <mat-option [value]="1">Eingebaut</mat-option>
                  <mat-option [value]="0">Nicht eingebaut</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('hasLadder').invalid">Drehleiter ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <hr />
            <h4 class="pb-2">Homepage</h4>
            <div class="col-lg-8 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Name in Einsatzstatistik</mat-label>
                <input matInput type="text" formControlName="homepageName" />
                <mat-error *ngIf="group.get('homepageName').invalid">Webseiten Name ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Sortierung</mat-label>
                <mat-select formControlName="orderHomepage">
                  <mat-option *ngFor="let _ of [].constructor(10); let i = index" [value]="i">{{i}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <hr />
            <h4 class="pb-2">Kostensatzung</h4>
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Preis pro km</mat-label>
                <input matInput type="number" formControlName="costMileage" />
                <mat-icon matSuffix>euro</mat-icon>
                <mat-error *ngIf="group.get('costMileage').invalid">Preis pro km ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Preis pro Stunde</mat-label>
                <input matInput type="number" formControlName="costHour" />
                <mat-icon matSuffix>euro</mat-icon>
                <mat-error *ngIf="group.get('costMileage').invalid">Preis pro Stunde ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div class="clearfix">
          <button mat-raised-button class="float-right" color="accent" [disabled]="group.invalid" (click)="saveData()">
            <div class="clearfix submit-button">
              <mat-icon class="me-3 mt-1 float-left" [hidden]="!isSaving">
                <mat-spinner diameter="20" class="light"></mat-spinner>
              </mat-icon>
              <span class="me-1 float-left">Speichern</span>
              <mat-icon class="float-right">save</mat-icon>
            </div>
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Prüfungen ({{ checkCycles.length }})
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ul>
          <li *ngFor="let check of checkCycles" [routerLink]="['/material/checks/cycles/details/', check.id]">
            <div class="clearfix">
              <div class="float-start">
                <span *ngIf="check.status !== 'open'" matListItemTitle>{{check.name}} (durchgeführt am {{dayjs(check.completionDate).format('DD.MM.YYYY')}})</span>
                <span *ngIf="check.status == 'open'" matListItemTitle>{{check.name}} (fällig am {{dayjs(check.date).format('DD.MM.YYYY')}})</span>
                <span *ngIf="check.status == 'open'" matListItemLine class="check-description" [innerHTML]="check.description"></span>
              </div>
              <mat-icon class="float-end mt-3" *ngIf="check.status == 'open'"
                matTooltip="Diese Prüfung steht noch aus">radio_button_unchecked</mat-icon>
              <mat-icon class="float-end mt-3" *ngIf="check.status == 'successful'"
                matTooltip="Diese Prüfung wurde bestanden">check_circle</mat-icon>
                <mat-icon class="float-end mt-3" *ngIf="check.status == 'failed'"
                matTooltip="Diese Prüfung wurde nicht bestanden">unpublished</mat-icon>
            </div>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>