<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Laufbahnereignisse</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack float-start">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a mat-mini-fab color="primary" mat-mini-fab class="pageNext" (click)="openMenu()">
          <mat-icon>apps</mat-icon>
        </a>

        <div class="text-center" *ngIf="showLoader">
          <mat-spinner style="margin: auto;" color="primary"></mat-spinner><br />
          <p style="width: 100%">Laufbahnereignisse werden geladen ...</p>
        </div>

        <div id="timeline-content" class="mt-4" [hidden]="showLoader">
          <div class="alert alert-info" *ngIf="rawItems.length == 0">
            Derzeit sind noch keine Laufbahnereignisse vorhanden.
          </div>

          <mat-form-field class="input-full-width mb-4" appearance="outline" *ngIf="rawItems.length > 0">
            <input matInput placeholder="Ereignisse filtern" [formControl]="searchField" />
          </mat-form-field>

          <div class="alert alert-warning" *ngIf="rawItems.length > 0 && items.length == 0">
            Es sind keine Suchergebnisse vorhanden!
          </div>

          <ngx-mat-timeline position="left" orientation="vertical">
            <ngx-mat-timeline-item *ngFor="let item of items">
              <ng-template ngxMatTimelineItemIcon>
                <mat-icon class="ngx-mat-timeline-item-icon"
                  [style.background]="item.background">{{item.icon}}</mat-icon>
              </ng-template>

              <h5 [style.font-style]="item.checked ? 'italic' : 'normal'">
                <mat-icon style="margin-bottom: -5px" class="me-2" *ngIf="item.checked"
                  matTooltip="Dieses Ereignis wird derzeit noch geprüft und taucht deshalb nicht in der Durckversion deines Lebenslauf auf">schedule</mat-icon>
                {{ item.name }}
              </h5>
              <div class="small-header">
                <span class="me-4">{{ dayjs(item.date).format('dddd, DD. MMMM YYYY') }}</span>
                <p *ngIf="item.fileRef">
                  <a [target]="isPWA ? '_self' : '_blank'"
                    href="{{baseURL}}admin/user/course/preview/{{this.userID}}/{{item.id}}?sessionToken={{_login.getSessionToken()}}">
                    <mat-icon>attach_file</mat-icon>
                    Anhang ansehen
                  </a>
                </p>
              </div>
            </ngx-mat-timeline-item>
          </ngx-mat-timeline>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #menu>
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
    <mat-icon>close</mat-icon>
  </a>
  <mat-nav-list style="padding-right: 40px;">
    <a mat-list-item [hidden]="rawItems.length == 0" (click)="_bottomSheet.dismiss()"
      [target]="isPWA ? '_self' : '_blank'"
      href="{{baseURL}}profile/timeline/print?sessionToken={{_login.getSessionToken()}}">
      <mat-icon matListItemIcon>print</mat-icon>
      <div matListItemTitle>Lebenslauf ausdrucken</div>
    </a>
    <a mat-list-item (click)="openNewCourseAdd();" *ngIf="_login.hasPermission('admin-dev')">
      <mat-icon matListItemIcon>post_add</mat-icon>
      <div matListItemTitle>Neues Ereignis hinzufügen</div>
    </a>
  </mat-nav-list>
</ng-template>