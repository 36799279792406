<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Abbrechnung verschicken</h3>
    <mat-card>
      <mat-card-content>
        <a mat-mini-fab color="primary" class="pageBack" onClick="javascript:window.history.back()">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <div *ngIf="loading">
                <mat-progress-bar mode="query"></mat-progress-bar>
              </div>
              <table mat-table [dataSource]="guardList">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? toggleAllRows() : null" color="primary"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)" color="primary">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let guard">{{guard.name}}</td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>Datum</th>
                  <td mat-cell *matCellDef="let guard">
                    <div>
                      {{ guard.date | date:'dd.MM.YYYY' }}
                    </div>
                    <div style="margin-left: 10px;">
                      {{ guard.guardStart }} Uhr
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="accounting">
                  <th mat-header-cell *matHeaderCellDef>Abrechnung</th>
                  <td mat-cell *matCellDef="let guard">
                    <b>Dauer</b>: {{getHoursDiff(guard.billingStart, guard.billingEnd)}}h<br>
                    <div *ngFor="let personal of guard.assignment.alarmPersonal">
                      {{personal.firstname}} {{personal.lastname}} - {{personal.hours}}h
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="viewColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: viewColumns" (click)="selection.toggle(row)" class="pointer">
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="clearfix">
          <button mat-raised-button color="success" class="float-right" (click)="sendAccounting()"
            [disabled]="selection.selected.length === 0">Abrechnung senden</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>