<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Termine</h3>
    <mat-card>
      <mat-card-content>
        <a (click)="openMenu()" mat-mini-fab class="pageNext" color="primary">
          <mat-icon>apps</mat-icon>
        </a>

        <div class="row mt-4">
          <div class="col-lg-8 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchFilter" placeholder="Suche">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Gruppenfilter</mat-label>
              <mat-select multiple (selectionChange)="applyGroupFilter($event)" [(ngModel)]="groupsFilter">
                <mat-option *ngFor="let group of groupList" [value]="group.id">{{group.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="table-responsive">
          <div *ngIf="eventList.length === 0">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="date"
            matSortDirection="asc" matSortDisableClear>
            <!-- Name Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef style="min-width: 20vw; max-width: 30vw;" mat-sort-header> Name</th>
              <td mat-cell *matCellDef="let element" style="min-width: 20vw; max-width: 30vw;"
                [routerLink]="_login.hasPermission('admin-event') ? ['edit/', element.id] : null"> {{element.title}}
              </td>
            </ng-container>

            <!-- Datum Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 15vw;"> Datum</th>
              <td mat-cell *matCellDef="let element" style="max-width: 15vw;"
                [routerLink]="_login.hasPermission('admin-event') ? ['edit/', element.id] : null">
                <div>
                  {{ element.startDate }}
                </div>
                <div *ngIf="!element.allDay">
                  {{ element.startTime }} Uhr
                </div>
              </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 15vw;"> Ort</th>
              <td mat-cell *matCellDef="let element" style="max-width: 15vw;"
                [routerLink]="_login.hasPermission('admin-event') ? ['edit/', element.id] : null">
                <div *ngIf="element.type === 'local'">
                  <mat-icon style="margin-left: 10px;" matTooltip="Präsenz"
                    matTooltipPosition="above">business</mat-icon>
                  <span
                    [hidden]="element.mapsUrl !== 'https://www.google.com/maps/search/?api=1&query=85521%20Ottobrunn%20Ottostraße%2019'"
                    style="margin-left: 10px;">
                    <mat-icon matTooltip="im Gerätehaus" matTooltipPosition="above">
                      home
                    </mat-icon>
                  </span>
                </div>
                <div *ngIf="element.type === 'hybrid'">
                  <mat-icon style="margin-left: 10px;" matTooltip="Hybrid" matTooltipPosition="above">maps_home_work
                  </mat-icon>
                  <span
                    [hidden]="element.mapsUrl !== 'https://www.google.com/maps/search/?api=1&query=85521%20Ottobrunn%20Ottostraße%2019'"
                    style="margin-left: 10px;">
                    <mat-icon matTooltip="im Gerätehaus" matTooltipPosition="above">
                      home
                    </mat-icon>
                  </span>
                </div>
                <div *ngIf="element.type === 'online'">
                  <mat-icon style="margin-left: 10px;" matTooltip="Online"
                    matTooltipPosition="above">language</mat-icon>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <span [hidden]="element.orga === 'foundation'" style="margin-right: 10px;">
                  <mat-icon matTooltip="Technikveranstaltung" matTooltipPosition="above">
                    local_fire_department
                  </mat-icon>
                </span>
                <span [hidden]="element.orga === 'technic' " style="margin-right: 10px;">
                  <mat-icon matTooltip="Vereinsveranstaltung" matTooltipPosition="above">
                    foundation
                  </mat-icon>
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="visibility">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <span [hidden]="element.visibility === 0" style="margin-right: 15px;">
                  <mat-icon matTooltip="sichtbar für alle" matTooltipPosition="above">
                    visibility
                  </mat-icon>
                </span>
                <span [hidden]="element.visibility === 1" style="margin-right: 15px;">
                  <mat-icon matTooltip="sichtbar für Teilnehmer / Führung" matTooltipPosition="above">
                    lock
                  </mat-icon>
                </span>
                <span [hidden]="element.status !== 'angelegt'">
                  <mat-icon matTooltip="Termin in Prüfung" matTooltipPosition="above">preview
                  </mat-icon>
                </span>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="applyPagination($event)" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #eventRemoveTemplate>
  <h2 mat-dialog-title>Wirklich entfernen?</h2>
  <mat-dialog-content>
    <p>Möchtest du diese Veranstaltung wirklich löschen?</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="clearfix">
      <button mat-mini-fab mat-dialog-close class="float-right" color="success"
        (click)="finalRemoveEvent()"><mat-icon>done</mat-icon></button>
      <button mat-mini-fab mat-dialog-close class="float-left" color="warn"><mat-icon>delete</mat-icon></button>
    </div>
  </mat-dialog-actions>
</ng-template>

<ng-template #menu>
  <br>
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
    <mat-icon>close</mat-icon>
  </a>
  <mat-nav-list class="mt-2">
    <mat-list-item routerLink="new" *ngIf="_login.hasPermission('admin-event-edit')" (click)="_bottomSheet.dismiss()">
      <h3 matListItemLine>Termin erstellen</h3>
      <div matListItemMeta>
        <mat-icon>create_new_folder</mat-icon>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="_login.hasPermission('admin-event-change-status')" (click)="openChangeStatus()"
      (click)="_bottomSheet.dismiss()">
      <h3 matListItemLine>Termine veröffentlichen</h3>
      <div matListItemMeta>
        <mat-icon>preview</mat-icon>
      </div>
    </mat-list-item>
  </mat-nav-list>
</ng-template>