<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Benachrichtigungen</h3>
    <mat-card>
      <mat-card-content>
        <mat-form-field class="input-full-width">
          <mat-label>Betreff</mat-label>
          <input type="text" [(ngModel)]="notificationPayload.subject" matInput />
        </mat-form-field>

        <b>Text:</b>
        <quill-editor [(ngModel)]="notificationPayload.text" placeholder="Text eingeben..." type="divarea"></quill-editor><br />

        <b>Senden an (Gruppen):</b>
        <mat-form-field class="input-full-width">
          <mat-label>Gruppen</mat-label>
          <mat-select multiple placeholder="Gruppen" [(ngModel)]="notificationPayload.groups"
            (selectionChange)="checkUser($event)">
            <mat-option>
              <ngx-mat-select-search [formControl]="groupFilterCtrl" id="group_search"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let group of groupFilter | async" [value]="group.id"
              (click)="groupReset()">{{group.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <b>Senden an (Benutzer):</b>
        <mat-form-field class="input-full-width">
          <mat-label>Benutzer</mat-label>
          <mat-select multiple placeholder="Benutzer" [(ngModel)]="notificationPayload.users"
            (selectionChange)="clearUserSearch()">
            <mat-option [hidden]="users.length == 0">
              <ngx-mat-select-search [formControl]="userFilterCtrl" id="user_search"></ngx-mat-select-search>
            </mat-option>
            <mat-option disabled *ngIf="users.length == 0">
              Es wurden keine Benutzer gefunden!
            </mat-option>
            <mat-option *ngFor="let user of userFilter | async" [value]="user.id" (click)="userReset()">{{user.firstname
              + ' ' + user.lastname}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="clearfix mt-2">
          <div class="float-left">
            <button mat-raised-button color="accent">
              <label for="attachments">
                <span>Anhang hinzufügen
                  <mat-icon class="mt-1 float-right">attach_file</mat-icon>
                </span>
              </label>
            </button>
            <mat-error *ngIf="size >= MAX_ATTACHMENT_SIZE" class="mt-2" style="padding-left: 1rem;">Anhang zu groß
            </mat-error>
            <input type="file" multiple (change)="uploadAttachments($event)" style="display:none;"
              id="attachments" /><br />
          </div>
          <button mat-raised-button class="float-right" color="success" (click)="sendNotification();"
            [disabled]="!users || users.length == 0 || !notificationPayload.text || size >= MAX_ATTACHMENT_SIZE || uploading">
            Benachrichtigung senden
            <mat-icon>send</mat-icon>
          </button>
        </div>

        <h6 *ngIf="files.length > 0" class="mt-3">Anhänge:</h6>
        <div class="row" *ngFor="let file of files; let i = index" style="margin-top: 15px;">
          <div class="col-lg-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Dateiname</mat-label>
              <input [value]="file.name" matInput disabled />
              <mat-icon matSuffix class="pointer" (click)="removeAttachment(i)">remove_circle</mat-icon>
              <mat-icon matPrefix *ngIf="progressInfos[i].value === 100" color="green">done</mat-icon>
            </mat-form-field>
            <mat-progress-bar *ngIf="progressInfos[i].value !== 100" [mode]="'buffer'" [value]="progressInfos[i].value">
            </mat-progress-bar>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>