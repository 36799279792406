import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import imageCompression from 'browser-image-compression';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Lightbox } from 'ngx-lightbox';
import { filter } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-user-atemschutz-assets',
  templateUrl: './admin-user-atemschutz-assets.component.html',
  styleUrls: ['./admin-user-atemschutz-assets.component.css'],
})
export class AdminUserAtemschutzAssetsComponent implements OnInit {

  firstname: string;

  lastname: string;

  userID: number;

  turn: number = 0;

  g263picture: string = '';
  g263pictureUrl: string = '';

  G263FileUrl: any = null;

  croppedG263Image: any = '';

  imageG263ChangedEvent: any = '';

  imageG263Loading: Boolean = true;

  G263Loading: Boolean = false;

  g263Valid: Boolean = false;

  optionsCompress: any = {
    maxSizeMB: 5,
  };

  env = environment;

  constructor(private _route: ActivatedRoute, private _dialog: MatDialog, private _config: ConfigModule, private _http: HttpClient,
    private _lightbox: Lightbox, public _login: Login, private _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this.userID = this._route.snapshot.params.userId;
    if (this.userID) {
      this.fetchAtemschutz(this.userID);
      this.fetchDataInfo(this.userID);
    }
  }

  fetchAtemschutz(userID: number) {
    this._http.get<any>(getHostUrl() + 'admin/user/atemschutz?userID=' + userID).subscribe(data => {
      this.g263picture = data.g26_3_picture;
      this._http.get<string>(`${getHostUrl()}g263?g26_3_picture=${data.g26_3_picture}`).subscribe({
        next: data => {
          this.g263pictureUrl = data;
        }
      });
    });
  }

  async fetchDataInfo(userID: number) {
    this._http.get<any>(getHostUrl() + 'admin/user/info?userID=' + userID).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this._config.setTitle(`${data.firstname + ' ' + data.lastname}`);
    });
  }

  open(): void {
    // open lightbox
    var picture: any[] = [];
    picture.push({ src: this.g263picture });
    this._lightbox.open(picture, 0, {
      wrapAround: true,
      disableScrolling: true,
      showZoom: false,
    });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  @ViewChild('uploadG263', { static: false }) g263Dialog: TemplateRef<any>;

  @ViewChild('resizeG263', { static: false }) resizeG263: TemplateRef<any>;

  openMenu() {
    this._bottomSheet.open(this.menu);
  }

  /**
   * G26/3 Picture Upload
   */
  openG263PictureDialog() {
    const pictureDialog = this._dialog.open(this.g263Dialog, { disableClose: true });
    pictureDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => pictureDialog.close());
    this._bottomSheet.dismiss();
  }

  upload($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.turn = 0;
    setTimeout(() => {
      this.imageG263ChangedEvent = $event;
      const resizeDialog = this._dialog.open(this.resizeG263, { disableClose: true });
      resizeDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => resizeDialog.close());
    }, 400);
  }

  imageG263Loaded() {
    setTimeout(() => {
      this.imageG263Loading = false;
    }, 800);
  }

  imageG263Cropped(event: ImageCroppedEvent) {
    this.croppedG263Image = event.blob;
  }

  loadImageFailed() {
    this._config.showSnackbar(
      'Das Bild konnte nicht geladen werden!',
      2000,
      'error',
    );
  }

  saveG263() {
    this.G263Loading = true;
      imageCompression(this.croppedG263Image, this.optionsCompress).then((compressedFile) => {
        this.blobToBase64(compressedFile).then(compressed => {
          this._http.put(getHostUrl() + 'admin/user/atemschutz/g26_3', {
            g26_3_picture: compressed,

            querUserID: this.userID,
          }).subscribe(resp => {
            this._config.showSnackbar('G26/3 gespeichert!', 2000, 'success');
            var data: any = resp;
            this._http.get<string>(`${getHostUrl()}g263?g26_3_picture=${data.g26_3_picture}`).subscribe({
              next: data => {
                this.g263pictureUrl = data;
              }
            });
            this.G263FileUrl = null;
            this.croppedG263Image = null;
            this.imageG263Loading = true;
            this.turn = 0;
            this.G263Loading = false;
          }, error => {
            if (error.error === 5001)
              this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
            else
              this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
            this.G263Loading = false;
          });
        });
      }).catch(function (error) {
        console.log(error.message);
      });
  }

  closeReset() {
    this.G263FileUrl = null;
    this.croppedG263Image = null;
    this.imageG263Loading = true;

    this.turn = 0;
  }

  turnRight() {
    this.turn++;
  }

  turnLeft() {
    this.turn--;
  }


  b64toBlob(dataURI): Blob {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }
}
