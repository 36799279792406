<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Atemschutz</h3>
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="agts">
              <mat-icon class="float-left mt-4 ms-3">people</mat-icon>
              Atemschutzgeräteträger<br />
              <small>Übersicht über alle AGTs</small>
            </button>
          </div>
          <div class="col-xl-4 col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="/statistics/1">
              <mat-icon class="float-left mt-4 ms-3">fireplace</mat-icon>
              Einsätze<br />
              <small>Liste aller Einsätze mit Atemschutz</small>
            </button>
          </div>
          <div class="col-xl-4 col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="notifications">
              <mat-icon class="float-left mt-4 ms-3">notifications</mat-icon>
              Nachrichten<br />
              <small>Nachrichten für den Fachbereich</small>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>