import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import * as dayjs from 'dayjs';
import { ActivatedRoute } from '@angular/router';
import { Login } from 'src/app/util/login';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../../../environments/environment';
import { AdminUserMenusheetComponent } from '../admin-user-menusheet/admin-user-menusheet.component';

@Component({
  selector: 'app-admin-user-atemschutz',
  templateUrl: './admin-user-atemschutz.component.html',
  styleUrls: ['./admin-user-atemschutz.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class AdminUserAtemschutzComponent implements OnInit, OnDestroy {

  today: Date = new Date();

  endDate: Date;

  env = environment;

  constructor(private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    public _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet) { }

  userInfo: any = {};

  firstname: string;

  lastname: string;

  birthdate: Date;

  age18: boolean = true;

  userID: number;

  ngOnInit() {
    this.userID = this._route.snapshot.params.userId;
    if (this.userID) {
      this.fetchAtemschutz(this.userID);
      this._config.setTitle('Verwaltung | Lädt...');
    }
  }

  fetchAtemschutz(userID: number) {
    this._http.get<any>(getHostUrl() + 'admin/user/atemschutz?userID=' + userID).subscribe(data => {
      this.userInfo = data;
      if (this.userInfo.g26_3_date === '0000-00-00')
        this.userInfo.g26_3_date = null;
      if (this.userInfo.briefingDate === '0000-00-00')
        this.userInfo.briefingDate = null;
      if (this.userInfo.g26_3_picture !== '')
        this.userInfo.g26_3_picture = `${getHostUrl()}g263?g26_3_picture=${this.userInfo.g26_3_picture}`;
    });
    this._http.get<any>(getHostUrl() + 'admin/user/info?userID=' + userID).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.birthdate = data.birthdate;
      this.age18 = dayjs(this.today).add(-18, 'years').isAfter(dayjs(data.birthdate));
      this._config.setTitle(`${data.firstname + ' ' + data.lastname}`);
      if (dayjs(this.today).isAfter(dayjs(this.birthdate).add(50, 'years')))
        this.endDate = dayjs(this.today).add(1, 'years').toDate();
      else
        this.endDate = dayjs(this.today).add(3, 'years').toDate();
    });
  }

  saveAtemschutz() {
    if (this.userID) {

      this.userInfo.g26_3_date = dayjs(this.userInfo.g26_3_date).format('YYYY-MM-DD');
      this.userInfo.briefingDate = dayjs(this.userInfo.briefingDate).format('YYYY-MM-DD');
      this._http.post(getHostUrl() + 'admin/user/atemschutz', { userID: this.userID, userObj: this.userInfo }).subscribe(resp => {
        this._spinner.hide();
        this._config.showSnackbar('Atemschutzdaten gespeichert!', 2000, 'success');
        this.fetchAtemschutz(this.userID);
      }, error => {
        this._spinner.hide();
        if (error.error === 5001)
          this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
        this.fetchAtemschutz(this.userID);
      });
    }
  }

  ong263DateChange($event: any) {
    this.userInfo.g26_3_date = $event.value;
  }

  onDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.userInfo.g26_3_date = new Date(dateString);
  }

  onBriefingDateChange($event: any) {
    this.userInfo.briefingDate = $event.value;
  }

  onBriefingChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.userInfo.briefingDate = new Date(dateString);
  }

  /**
 * Menu Bottom Sheet
 */
  openMenu() {
    const bottomSheetRef = this._bottomSheet.open(AdminUserMenusheetComponent, { data: { userID: this.userID, name: this.firstname + ' ' + this.lastname } });
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }

}
