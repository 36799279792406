import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vehicles-detail',
  templateUrl: './vehicles-detail.component.html',
  styleUrls: ['./vehicles-detail.component.css']
})
export class VehiclesDetailComponent implements OnInit {
  constructor(private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _config: ConfigModule,
    private _builder: FormBuilder) { }

  vehicleId = null;
  isSaving = false;
  isLoading = true;
  checkList = [];
  checkCycles = [];

  dayjs = dayjs;

  group: FormGroup = new FormGroup({});

  ngOnInit(): void {
    this.group = this._builder.group({
      cartype: [null, Validators.required],
      name: ['', Validators.required],
      active: [false, Validators.required],
      comment: [''],
      seats: [0, Validators.required],
      status: ["2", Validators.required],
      hasPump: ["0", Validators.required],
      hasGenerator: ["0", Validators.required],
      hasLadder: ["0", Validators.required],
      licensePlate: [''],
      homepageName: [''],
      orderHomepage: [0],
      checks: [[]],
      costHour: [0, Validators.required],
      costMileage: [0, Validators.required]
    });

    this.vehicleId = this._route.snapshot.params?.vehicleId;
    if (this.vehicleId) {
      this._config.setTitle('Fahrzeug bearbeiten');
      this.loadVehicle();
      this.loadCheckCycles();
    } else {
      this._config.setTitle('Fahrzeug erstellen');
      this.isLoading = false;
    }
    this.loadChecks();
  }

  async saveData() {
    this.group.disable();
    this.isSaving = true;
    try {
      if (this.vehicleId) {
        await firstValueFrom(this._http.post(`${getHostUrl()}admin/material/vehicles/${this.vehicleId}`, this.group.value));
        this.loadCheckCycles(false);
      } else {
        const result = await firstValueFrom(this._http.put<any>(`${getHostUrl()}admin/material/vehicles`, this.group.value));
        this._router.navigate(['material', 'vehicles', 'details', result.vehicleId]);
      }
      this._config.showSnackbar("Fahrzeug wurde gespeichert!", 3000, 'success');
    } catch (e) {
      this.isSaving = false;
      this.group.enable();
      if (e?.status == 409) {
        return this._config.showSnackbar("Ein Fahrzeug mit diesem Namen existiert bereits!", 3000, 'error');
      }
      this._config.showSnackbar("Konnte Fahrzeug nicht speichern", 3000, "error");
    }
    this.isSaving = false;
    this.group.enable();
  }

  async loadChecks() {
    this.checkList = (await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/checks`))).sort((a,b) => {
      return String(a.name).localeCompare(b.name);
    });
  }

  async loadCheckCycles(ignorePrevious = true) {
    const previousCount = this.checkCycles.length;
    this.checkCycles = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/vehicles/${this.vehicleId}/checks`));

    if (this.checkCycles.length > previousCount && !ignorePrevious) {
      Swal.fire({
        title: 'Neue Prüfungen verfügbar!',
        text: 'Es wurden neue Prüfungen für dieses Fahrzeug hinzugefügt, bitte überprüfe diese im unteren Teil der Seite!',
        confirmButtonText: 'OK'
      });
    }
  }

  async loadVehicle() {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/vehicles/${this.vehicleId}`));
      result.active = result.active == 1;
      this.group.setValue(result);   
      this.isLoading = false;
    } catch (e) {
      console.error(e);
      
      if (e.status == 404) {
        this._config.showSnackbar('Fahrzeug wurde nicht gefunden!', 3000, 'error');
        this._router.navigate(['material/vehicles']);
      } else {
        this._router.navigate(['material/vehicles']);
        this._config.showSnackbar('Ein Fehler ist aufgetreten, bitte versuche es erneut!', 3000, 'error');
      }
    }
  }
}
