<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Laufbahnereignisse - Kategorien</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <div class="row">
          <div class="col-10">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche">
            </mat-form-field>
          </div>
          <div class="col-2">
            <button mat-raised-button color="primary" style="height: 62px; width: 100%" (click)="editItem(null)">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <div *ngIf="categories.length===0">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
          <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
            matSortDisableClear>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="vertical-align: middle;">Name
              </th>
              <td mat-cell *matCellDef="let element" (click)="editItem(element)">
                {{element.category}} </td>
            </ng-container>

            <!-- Icon Column -->
            <ng-container matColumnDef="icon">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="vertical-align: middle;">Icon
              </th>
              <td mat-cell *matCellDef="let element" (click)="editItem(element)">
                <mat-icon>{{ element.icon }}</mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>