<div class="position-relative">
  <div class="clearfix">
    <button class="float-end" mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
    <h2 class="float-start" mat-dialog-title>{{ _data.title || 'QR Code scannen' }}</h2>
  </div>
  <div class="qr-scanner">
    <mat-spinner *ngIf="action.isLoading"></mat-spinner>
    <ngx-scanner-qrcode #action="scanner" (event)="closeWithStatus($event)" [config]="config"></ngx-scanner-qrcode>
  </div>

  <div class="switch-buttons">
    <button mat-icon-button (click)="changeDevice()" matTooltip="Kamera ändern" class="switch-camera">
      <mat-icon>cameraswitch</mat-icon>
    </button>

    <button mat-icon-button (click)="action.isTorch = !action.isTorch; handle(action, 'torcher')" matTooltip="Taschenlampe an/ausschalten"
      class="switch-torch">
      <mat-icon *ngIf="action.isTorch">flashlight_on</mat-icon>
      <mat-icon *ngIf="!action.isTorch">flashlight_off</mat-icon>
    </button>
  </div>
</div>