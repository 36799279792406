<div class="row">
  <div class="col-12 text-center">
    <img src="assets/img/sad-island.png" />

    <mat-icon>lock</mat-icon>

    <div class="row">
      <div class="col-lg-4 offset-md-12 offset-lg-4">
        <div class="speech-bubble">
          <h2>403</h2>
          <span
            >Der Zugriff zu dieser Seite wurde verweigert. <br />Kehre
            zur <a routerLink="/home">Startseite</a> zurück.</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
