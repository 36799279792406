import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { getHostUrl } from '../../config';

@Component({
  selector: 'app-rankpicker',
  templateUrl: './rankpicker.component.html',
  styleUrls: ['./rankpicker.component.css']
})
export class RankpickerComponent {
  constructor(private _http: HttpClient) { }

  @Output() rank = new EventEmitter<number | number[]>();
  @Input() label: string = 'Rang';
  @Input() required: boolean = false;
  @Input() multiple: boolean = false;

  ranks: any;
  currentranks: number | number[] = [];
  public ranksFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public ranksFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this._http.get<any[]>(`${getHostUrl()}datainfo/ranks`).subscribe(result => {
      this.ranks = result;
      this.ranksFilter.next(this.ranks.slice());
      this.ranksFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterranks();
        });
      this.ranksFilterCtrl.updateValueAndValidity();
    });
  }

  selected($event: MatSelectChange): void {
    if (this.multiple) {
      const ranks: number[] = ($event.value as number[]);
      this.rank.emit(ranks);
    } else {
      this.rank.emit($event.value);
    }
  }

  protected filterranks() {
    if (!this.ranks) {
      return;
    }
    let search = this.ranksFilterCtrl.value;
    if (!search) {
      this.ranksFilter.next(this.ranks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.ranksFilter.next(
      this.ranks.filter(rank => rank.name.toLowerCase().indexOf(search) > -1 ||
        rank.name.toLowerCase().indexOf(search) > -1),
    );
  }
}
