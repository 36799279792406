import { Component, OnInit, Input } from '@angular/core';
import { ProfileComponent } from '../profile.component';

@Component({
  selector: 'app-profile-firedata',
  templateUrl: './profile-firedata.component.html',
  styleUrls: ['./profile-firedata.component.css'],
})
export class ProfileFiredataComponent implements OnInit {

  @Input() parent : ProfileComponent;

  constructor() { }

  ngOnInit() {
    if (this.parent.userInfo.firedata.voluntaryCardDate === '0000-00-00')
      this.parent.userInfo.firedata.voluntaryCardDate = '';
  }

}
