import { Component, OnInit } from '@angular/core';
import { ConfigModule } from 'src/app/util/config';
import { Login } from 'src/app/util/login';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.css'],
})
export class AdminSettingsComponent implements OnInit {

  constructor(public _login: Login,
    private _config: ConfigModule) { }

  ngOnInit(): void {
    this._config.setTitle('Systemeinstellungen');
  }

}
