<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Gruppen</h3>
    <mat-card>
      <mat-card-content>
        <a *ngIf="_login.hasPermission('admin-groups-new')" routerLink="new" mat-mini-fab class="pageNext" color="primary">
          <mat-icon>create_new_folder</mat-icon>
        </a>
        
        <mat-form-field class="input-full-width mt-4" appearance="outline">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
        <div class="table-responsive">
          <div *ngIf="isLoading">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="name"
            matSortDirection="asc" matSortDisableClear>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 250px; padding-right: 15px"> Name
              </th>
              <td mat-cell *matCellDef="let element"
                [routerLink]="_login.hasPermission('admin-groups-edit') ? ['edit/', element.id] : null">
                {{element.name}}
              </td>
            </ng-container>

            <!-- Mitglieder Column -->
            <ng-container matColumnDef="membercount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 150px; vertical-align:middle">
                Mitglieder</th>
              <td mat-cell *matCellDef="let element"
                [routerLink]="_login.hasPermission('admin-groups-edit') ? ['edit/', element.id] : null">
                {{element.count}}
              </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <a href="javascript:void(0);" *ngIf="_login.hasPermission('admin-user-delete')"
                  (click)="removeGroup(element.id)" mat-icon-button color="warn"><mat-icon>delete</mat-icon></a>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>