import { Component, OnInit, Input, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { ProfileComponent } from '../profile.component';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { HttpClient } from '@angular/common/http';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as dayjs from 'dayjs';
import imageCompression from 'browser-image-compression';
import { DateTime } from 'luxon';
import { Login } from 'src/app/util/login';
import { ViewSDKClient } from 'src/app/util/proprietary/adobe/view-sdk.service';
import { MatTabChangeEvent as MatTabChangeEvent } from '@angular/material/tabs';
import { filter, firstValueFrom } from 'rxjs';
import { NfcScannerDialogComponent } from 'src/app/util/dialogs/nfc-scanner-dialog/nfc-scanner-dialog.component';
import { AppCommunicationService } from 'src/app/util/services/app-communication/app-communication.service';

export interface Car {
  id: number,
  licenceClass: string,
  type: string
}

@Component({
  selector: 'app-profile-driving',
  templateUrl: './profile-driving.component.html',
  styleUrls: ['./profile-driving.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class ProfileDrivingComponent implements OnInit, AfterViewInit {

  @Input() parent: ProfileComponent;

  @ViewChild('licenseDialog', { static: false }) licenseDialog: TemplateRef<any>;

  @ViewChild('resizeFront', { static: false }) resizeFront: TemplateRef<any>;

  @ViewChild('resizeBack', { static: false }) resizeBack: TemplateRef<any>;

  @ViewChild('FFFDialog', { static: false }) FFFDialog: TemplateRef<any>;

  @ViewChild('FFFresizeFront', { static: false }) FFFresizeFront: TemplateRef<any>;

  @ViewChild('FFFresizeBack', { static: false }) FFFresizeBack: TemplateRef<any>;

  @ViewChild('extendLicenceDate', { static: false }) extendDialog: TemplateRef<any>;

  @ViewChild('licenceInfo', { static: false }) licenceDialog: TemplateRef<any>;

  firstLicence: boolean = false;

  today: Date = new Date();

  expiryLicence: Date = dayjs(this.today).add(15, 'years').toDate();

  expiryClass: Date = dayjs(this.today).add(5, 'years').toDate();

  extendClass: String;

  extendDate: Date | String;

  lastChecked: Date;

  licenceUploadValid: boolean = false;

  age18Date: Date;

  acceptTerms = false;

  front: any;

  back: any;

  FFFfront: any;

  FFFback: any;

  confirmation: boolean = false;

  turn: number = 0;

  frontFileUrl: any = null;

  croppedFrontImage: any = '';

  imageFrontChangedEvent: any = '';

  imageFrontLoading: Boolean = true;

  frontLoading: Boolean = false;

  backFileUrl: any = null;

  croppedBackImage: any = '';

  imageBackChangedEvent: any = '';

  imageBackLoading: Boolean = true;

  backLoading: Boolean = false;

  FFFfrontFileUrl: any = null;

  FFFcroppedFrontImage: any = '';

  FFFimageFrontChangedEvent: any = '';

  FFFimageFrontLoading: Boolean = true;

  FFFfrontLoading: Boolean = false;

  FFFbackFileUrl: any = null;

  FFFcroppedBackImage: any = '';

  FFFimageBackChangedEvent: any = '';

  FFFimageBackLoading: Boolean = true;

  FFFbackLoading: Boolean = false;

  notificationPayload: any = {};

  optionsCompress: any = {
    maxSizeMB: 5,
  };

  unlimitedClass: boolean = false;
  unlimited: boolean = false;
  isLoading = true;
  dayjs = dayjs;

  private viewSDKClient: ViewSDKClient;

  constructor(private _http: HttpClient,
    public _login: Login,
    private _config: ConfigModule,
    public _acs: AppCommunicationService,
    public _dialog: MatDialog) { }

  ngOnInit(): void {
    this._http.get<any>(`${getHostUrl()}profile/driving/valid`).subscribe(data => {
      this.licenceUploadValid = data[0];
      this.isLoading = false;
    });

    if (this.parent.userInfo.driving.dlNumber === '')
      this.firstLicence = true;
    if (this.parent.userInfo.driving.dateOfIssue !== null)
      this.expiryLicence = dayjs(this.parent.userInfo.driving.dateOfIssue).add(15, 'years').toDate();
    if (this.parent.userInfo.driving.dateOfExpiry === null && !this.firstLicence)
      this.unlimited = true;
    this.parent.userInfo.driving.licences.forEach((licence: any) => {
      if ((licence.licenceClass === 'Klasse C1' || licence.licenceClass === 'Klasse C1E') && (dayjs(licence.expiryDate).isSame(dayjs('01.01.1901')) || dayjs(licence.expiryDate).isSame(dayjs('1900-12-31T23:00:00.000Z'))))
        this.unlimitedClass = true;
    });
  }

  loadPdf(event: MatTabChangeEvent) {
    let url = '';
    let name = '';
    switch (event.tab.textLabel) {
      case 'Dienstfahrerlaubnis': {
        url = this.parent.userInfo.driving.dienstfahrerlaubnis;
        name = 'Dienstfahrerlaubnis';
        break;
      }
      case 'Beauftragung Flurförderfahrzeuge': {
        url = this.parent.userInfo.driving.flurfoerderfahrzeuge;
        name = 'Flurförderfahrzeuge';
        break;
      }
      case 'Beauftragung Fahrzeugkräne': {
        url = this.parent.userInfo.driving.fahrzeugkraene;
        name = 'Fahrzeugkräne';
        break;
      }
    }
    this.showPDF(url, name);
  }

  ngAfterViewInit(): void {
    if (this.parent.userInfo.driving.dienstfahrerlaubnis) {
      /* Invoke file preview */
      this.showPDF(this.parent.userInfo.driving.dienstfahrerlaubnis, 'Dienstfahrerlaubnis');
    }
  }

  showPDF(url: string, name: string) {
    this.viewSDKClient = new ViewSDKClient();
    this.viewSDKClient.ready().then(() => {
      this.viewSDKClient.previewFile('pdf', {
        /* Pass the embed mode option here */
        embedMode: 'SIZED_CONTAINER',
        showDownloadPDF: false,
        showPrintPDF: false,
        enableFormFilling: false,
        showLeftHandPanel: false,
        showAnnotationTools: false,
        dockPageControls: true,
        showPageControls: false,
      }, url, name);
    });
  }

  saveDriving() {
    this.parent._spinner.show();
    this.parent.userInfo.driving.dateOfIssue = dayjs(this.parent.userInfo.driving.dateOfIssue).format('YYYY-MM-DD');
    this.parent.userInfo.driving.dateOfExpiry = dayjs(this.parent.userInfo.driving.dateOfExpiry).format('YYYY-MM-DD');
    this._http.post(getHostUrl() + 'profile/driving', { userObj: this.parent.userInfo.driving }).subscribe(resp => {
      this.parent._spinner.hide();
      this._config.showSnackbar('Fahrerlaubnisdaten gespeichert!', 2000, 'success');
      this.firstLicence = false;
      this.parent.fetchDrivingData();
    }, error => {
      this.parent._spinner.hide();
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      this.parent.fetchDrivingData();
    });
  }


  ondateOfIssueChange($event: any) {
    this.parent.userInfo.driving.dateOfIssue = $event.value;
    this.expiryLicence = dayjs(this.parent.userInfo.driving.dateOfIssue).add(15, 'years').toDate();
    this.expiryClass = dayjs(this.parent.userInfo.driving.dateOfIssue).add(5, 'years').toDate();
  }

  ondateOfExpiryChange($event: any) {
    this.parent.userInfo.driving.dateOfExpiry = $event.value;
  }

  onissueDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.parent.userInfo.driving.dateOfIssue = new Date(dateString);
    this.expiryLicence = dayjs(this.parent.userInfo.driving.dateOfIssue).add(15, 'years').toDate();
    this.expiryClass = dayjs(this.parent.userInfo.driving.dateOfIssue).add(5, 'years').toDate();
  }

  onexpiryDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.parent.userInfo.driving.dateOfExpiry = new Date(dateString);
  }

  getCarList(licenceClass: string, trailer: number | boolean): Car[] {
    var returnList: Car[] = [];
    if (this.parent.carTypesList) {
      if (licenceClass.endsWith('E'))
        licenceClass = licenceClass.substring(0, licenceClass.length - 1);
      this.parent.carTypesList.forEach(element => {
        if (element.licenceClass === licenceClass || (element.licenceClass === licenceClass + 'E' && (trailer === true || trailer === 1)))
          returnList.push(element);
      });
    }
    return returnList;
  }

  licencesFiltered(checked: string) {
    return this.parent.userInfo.driving.licences.filter(licence => licence.checked === checked);
  }

  getFormattedDate(dateValue: string) {
    if (dateValue === '0000-00-00')
      return '-';
    return dayjs(dateValue).format('DD.MM.YYYY');
  }

  controlDateValid(): 0 | 1 | 2 {
    const now: DateTime = DateTime.now();
    const month: DateTime = now.minus({ month: 5 });
    const ablauf: DateTime = now.minus({ month: 6 });
    const control: DateTime = DateTime.fromSQL(this.parent.userInfo.driving.lastChecked);
    if (control < ablauf)
      return 2;
    if (control < month)
      return 1;
    return 0;
  }

  async renewValidation() {
    const result = await firstValueFrom(this._dialog.open(NfcScannerDialogComponent, {
      width: '250px',
      data: {
        title: 'Führerschein scannen'
      }
    }).afterClosed());

    if (result) {
      try {
        await firstValueFrom(this._http.post(getHostUrl() + 'profile/license/renew', { nfcId: result?.uid ?? result }));
        this._config.showSnackbar('Führerschein validiert!', 2000, 'success');
      } catch (error) {
        console.log(error);
        
        this._config.showSnackbar('Ein Fehler ist aufgetreten: ' + (error?.error?.error ?? 'Interner Serverfehler'), 2000, 'error');
      }
    }
  }

  getCheckedExpiry(): string {
    const checked: DateTime = DateTime.fromSQL(this.parent.userInfo.driving.lastChecked);
    const expiry: DateTime = checked.plus({ months: 6 });
    if (DateTime.now() < expiry)
      return expiry.setLocale('de-DE').toFormat("'läuft am' dd'.' MMMM yyyy 'ab'");
    return expiry.setLocale('de-DE').toFormat("'ist am' dd'.' MMMM yyyy 'abgelaufen'");
  }

  openLicenceInfo() {
    this._http.get<any>(`${getHostUrl()}profile/driving/valid`).subscribe(data => {
      this.licenceUploadValid = data[0];
      const licenceInfoDialog = this._dialog.open(this.licenceDialog, { disableClose: true });
      licenceInfoDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => licenceInfoDialog.close());
    });
  }

  extendingLicenceDate(licenceClass: string) {
    this._http.get<any>(`${getHostUrl()}profile/driving/valid`).subscribe(data => {
      this.licenceUploadValid = data[0];
      this.extendClass = licenceClass;
      this.extendDate = null;
      this.parent.userInfo.driving.dlNumber = '';
      this.parent.userInfo.driving.dateOfIssue = null;
      this.parent.userInfo.driving.dateOfExpiry = null;
      this.parent.userInfo.driving.issuingAuthority = '';
      this.parent.userInfo.driving.lastChecked = null;
      const extendigDialog = this._dialog.open(this.extendDialog, { disableClose: true });
      extendigDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => extendigDialog.close());
    });
  }

  ondateOfExtendChange($event: any) {
    this.extendDate = $event.value;
  }

  onextendDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.extendDate = new Date(dateString);
  }

  saveLicenceDate() {
    this.parent._spinner.show();
    this.extendDate = dayjs(this.extendDate as Date).format('YYYY-MM-DD');
    this._http.post(getHostUrl() + 'profile/driving/extend', { extendClass: this.extendClass, extendDate: this.extendDate }).subscribe(resp => {
      this.parent._spinner.hide();
      this._config.showSnackbar('Ablaufdatum ' + this.extendClass + ' gespeichert!', 2000, 'success');
      this.extendClass = '';
      this.extendDate = null;
      this.saveDriving();
    }, error => {
      this.parent._spinner.hide();
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      this.extendClass = '';
      this.extendDate = null;
      this.parent.fetchDrivingData();
    });
  }

  /**
   * Licence Picture Upload
   */
  openLicencePictureDialog() {
    const LicencePictureDialog = this._dialog.open(this.licenseDialog, { disableClose: true });
    LicencePictureDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => LicencePictureDialog.close());
  }

  openFFFPictureDialog() {
    const FFFPictureDialog = this._dialog.open(this.FFFDialog, { disableClose: true });
    FFFPictureDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => FFFPictureDialog.close());
  }

  uploadFront($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.confirmation = false;
    this.turn = 0;
    setTimeout(() => {
      this.imageFrontChangedEvent = $event;
      const resizeFrontDialog = this._dialog.open(this.resizeFront, { disableClose: true });
      resizeFrontDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => resizeFrontDialog.close());
    }, 400);
  }

  imageFrontLoaded() {
    setTimeout(() => {
      this.imageFrontLoading = false;
    }, 800);
  }

  imageFrontCropped(event: ImageCroppedEvent) {
    this.croppedFrontImage = event.blob;
  }

  uploadBack($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.confirmation = false;
    this.turn = 0;
    setTimeout(() => {
      this.imageBackChangedEvent = $event;
      const resizeBackDialog = this._dialog.open(this.resizeBack, { disableClose: true });
      resizeBackDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => resizeBackDialog.close());
    }, 400);
  }

  imageBackLoaded() {
    setTimeout(() => {
      this.imageBackLoading = false;
    }, 800);
  }

  imageBackCropped(event: ImageCroppedEvent) {
    this.croppedBackImage = event.blob;
  }

  FFFuploadFront($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.confirmation = false;
    this.turn = 0;
    setTimeout(() => {
      this.FFFimageFrontChangedEvent = $event;
      const FFFresizeFrontDialog = this._dialog.open(this.FFFresizeFront, { disableClose: true });
      FFFresizeFrontDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => FFFresizeFrontDialog.close());
    }, 400);
  }

  FFFimageFrontLoaded() {
    setTimeout(() => {
      this.FFFimageFrontLoading = false;
    }, 800);
  }

  FFFimageFrontCropped(event: ImageCroppedEvent) {
    this.FFFcroppedFrontImage = event.blob;
  }

  FFFuploadBack($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.confirmation = false;
    this.turn = 0;
    setTimeout(() => {
      this.FFFimageBackChangedEvent = $event;
      const FFFresizeBackDialog = this._dialog.open(this.FFFresizeBack, { disableClose: true });
      FFFresizeBackDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => FFFresizeBackDialog.close());
    }, 400);
  }

  FFFimageBackLoaded() {
    setTimeout(() => {
      this.FFFimageBackLoading = false;
    }, 800);
  }

  FFFimageBackCropped(event: ImageCroppedEvent) {
    this.FFFcroppedBackImage = event.blob;
  }

  loadImageFailed() {
    this._config.showSnackbar(
      'Das Bild konnte nicht geladen werden!',
      2000,
      'error',
    );
  }

  saveFront() {
    this.frontLoading = true;
    imageCompression(this.croppedFrontImage, this.optionsCompress).then((compressedFile) => {
      this.blobToBase64(compressedFile).then(compressed => {
        this._http.put(getHostUrl() + 'profile/licenceFront', {
          licence: compressed,
        }).subscribe((resp: any) => {
          this._config.showSnackbar('Führerscheinvorderseite gespeichert!', 2000, 'success');
          this.frontFileUrl = null;
          this.croppedFrontImage = null;
          this.imageFrontLoading = true;
          this.confirmation = false;
          this.turn = 0;
          this.frontLoading = false;
          this.parent.fetchDrivingData();
          this._http.get<any>(`${getHostUrl()}profile/driving/valid`).subscribe(data => {
            this.licenceUploadValid = data[0];
          });
        }, error => {
          if (error.error === 5001)
            this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
          else
            this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
          this.frontLoading = false;
          this.parent.fetchDrivingData();
        });
      });
    }).catch(function (error) {
      console.log(error.message);
    });
  }

  saveBack() {
    this.backLoading = true;
    imageCompression(this.croppedBackImage, this.optionsCompress).then((compressedFile) => {
      this.blobToBase64(compressedFile).then(compressed => {
        this._http.put(getHostUrl() + 'profile/licenceBack', {
          licence: compressed,
        }).subscribe((resp: any) => {
          this._config.showSnackbar('Führerscheinrückseite gespeichert!', 2000, 'success');
          this.backFileUrl = null;
          this.croppedBackImage = null;
          this.imageBackLoading = true;
          this.confirmation = false;
          this.turn = 0;
          this.backLoading = false;
          this.parent.fetchDrivingData();
          this._http.get<any>(`${getHostUrl()}profile/driving/valid`).subscribe(data => {
            this.licenceUploadValid = data[0];
          });
        }, error => {
          if (error.error === 5001)
            this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
          else
            this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
          this.backLoading = false;
          this.parent.fetchDrivingData();
        });
      });
    }).catch(function (error) {
      console.log(error.message);
    });
  }

  FFFsaveFront() {
    this.FFFfrontLoading = true;
    imageCompression(this.FFFcroppedFrontImage, this.optionsCompress).then((compressedFile) => {
      this.blobToBase64(compressedFile).then(compressed => {
        this._http.put(getHostUrl() + 'profile/licenceFFFfront', {
          licence: compressed,
        }).subscribe(resp => {
          this._config.showSnackbar('Staplerscheinvorderseite gespeichert!', 2000, 'success');
          this.FFFfrontFileUrl = null;
          this.FFFcroppedFrontImage = null;
          this.FFFimageFrontLoading = true;
          this.confirmation = false;
          this.turn = 0;
          this.FFFfrontLoading = false;
          this.parent.fetchDrivingData();
        }, error => {
          if (error.error === 5001)
            this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
          else
            this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
          this.FFFfrontLoading = false;
        });
      });
    }).catch(function (error) {
      console.log(error.message);
    });
  }

  FFFsaveBack() {
    this.backLoading = true;
    imageCompression(this.FFFcroppedBackImage, this.optionsCompress).then((compressedFile) => {
      this.blobToBase64(compressedFile).then(compressed => {
        this._http.put(getHostUrl() + 'profile/licenceFFFback', {
          licence: compressed,
        }).subscribe(resp => {
          this._config.showSnackbar('Staplerscheinrückseite gespeichert!', 2000, 'success');
          this.FFFbackFileUrl = null;
          this.FFFcroppedBackImage = null;
          this.FFFimageBackLoading = true;
          this.confirmation = false;
          this.turn = 0;
          this.FFFbackLoading = false;
          this.parent.fetchDrivingData();
        }, error => {
          if (error.error === 5001)
            this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
          else
            this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
          this.FFFbackLoading = false;
        });
      });
    }).catch(function (error) {
      console.log(error.message);
    });
  }

  closeReset() {
    this.frontFileUrl = null;
    this.croppedFrontImage = null;
    this.imageFrontLoading = true;

    this.backFileUrl = null;
    this.croppedBackImage = null;
    this.imageBackLoading = true;

    this.confirmation = false;
    this.turn = 0;
  }

  turnRight() {
    this.turn++;
  }


  turnLeft() {
    this.turn--;
  }


  b64toBlob(dataURI): Blob {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }
}