import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl, PageEvent as PageEvent } from '@angular/material/paginator';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../../util/login';
import { getHostUrl, ConfigModule } from '../../../util/config';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginatorIntlCro } from '../../../util/matPaginatorIntlCroClass';
import { AdminUserMenusheetComponent } from './admin-user-detail/admin-user-menusheet/admin-user-menusheet.component';
import { environment } from '../../../../environments/environment';

import * as $ from 'jquery';
import { AdminUserNewComponent } from './admin-user-new/admin-user-new.component';
import { MatDialog as MatDialog } from '@angular/material/dialog';

export interface User {
  id: number;
  name: string,
  firstname: string;
  lastname: string;
  email: string;
}
@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class AdminUserComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns: string[] = ['id', 'name', 'email'];
  dataSource = new MatTableDataSource();
  sortType: boolean = false;
  userList: User[] = [];
  groupList: any[] = [];
  groupsFilter: any[] = [];
  searchFilter: string = '';
  pageIndex: number = 0;
  pageSize: number = 20;

  env = environment;

  constructor(private _http: HttpClient,
    private _ref: ChangeDetectorRef,
    public _login: Login,
    private _config: ConfigModule,
    private _spinner: NgxSpinnerService,
    private _dialog: MatDialog,
    private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this._config.setTitle('Benutzer');
    this.groupsFilter = [2];
    this.refreshData();
    this.fetchDataInfo();

    if ($(window).width() < 600) {
      this.displayedColumns = ['id', 'name'];
    }
  }

  applyHashLocation() {
    const hashes = window.location.hash.split('#');
    if (hashes.length === 5) {
      this.groupsFilter = hashes[1] !== '' ? hashes[1].split(';').map(g => parseInt(g)) : [];
      this.searchFilter = hashes[2];
      this.pageIndex = parseInt(hashes[3]);
      this.pageSize = parseInt(hashes[4]);
      setTimeout(() => {
        this.applyFilter(decodeURI(this.searchFilter));
      }, 500);
      return;
    }
    this.setHashLocation();
  }

  setHashLocation() {
    if (this.groupsFilter.length === 0 && !this.searchFilter.trim() && this.pageIndex === 0 && this.pageSize === 20) {
      window.location.hash = '';
      return;
    }
    window.location.hash = `#${this.groupsFilter.join(';')}#${this.searchFilter}#${this.pageIndex}#${this.pageSize}`;
  }

  openNewUser() {
    this._dialog.open(AdminUserNewComponent, {
      width: '1300px',
      maxHeight: '90vh'
    });
  }

  refreshData() {
    this._http.get<User[]>(getHostUrl() + 'admin/user/all')
      .subscribe(data => {
        this.userList = data;
        this.dataSource = new MatTableDataSource<User>();
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'name': return item.lastname;
            default: return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.filter();
        this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
        this.applyFilter(decodeURI(this.searchFilter));
        this._spinner.hide();
      });
  }

  async fetchDataInfo() {
    this.groupList = await this._http.get<any[]>(`${getHostUrl()}datainfo/groups?type=admin`).toPromise();
    this._ref.detectChanges();
    this.applyHashLocation();
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
    this.setHashLocation();
  }

  applyGroupFilter($event: MatSelectChange) {
    this.groupsFilter = $event.value;
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
    this.setHashLocation();
  }

  applyPagination($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.setHashLocation();
  }

  filter(): (data: User, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      if (searchTerms.groups.length !== 0) {
        var result = true;
        searchTerms.groups.forEach(group => {
          if (!data.groups.includes(group))
            result = false;
          if (data.id.toString().indexOf(searchTerms.search.toLowerCase()) === -1 && data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1 && data.email.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1)
            result = false;
        });
        return result;
      } else {
        return (data.id.toString().indexOf(searchTerms.search.toLowerCase()) !== -1 || data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) !== -1 || data.email.toLowerCase().indexOf(searchTerms.search.toLowerCase()) !== -1);
      }
    };
    return filterFunction;
  }

  isActive(group: number): boolean {
    if (group === 2)
      return true;
    return false;
  }

  /**
   * Menu Bottom Sheet
   */
  openMenu(userID: number, firstname: string, lastname: string) {
    const bottomSheetRef = this._bottomSheet.open(AdminUserMenusheetComponent, { data: { userID: userID, name: firstname + ' ' + lastname } });
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }
}