<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Einsatzberichte</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>

        <div class="row">
          <div class="col-lg-12 col-12" *ngIf="!alarmListLoading" style="vertical-align: middle">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchFilter" placeholder="Suche">

              <button mat-icon-button matSuffix (click)="clearSearch()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-12">
            <div class="table-responsive" [hidden]="alarmListLoading">
              <table mat-table [dataSource]="alarm" matSort class="mat-elevation-z8" matSortActive="date"
                matSortDirection="desc" matSortDisableClear>

                <!-- date Column -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 25%; vertical-align: middle;">
                    Einsatzzeit
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a class="mat-cell" target="_blank"
                      href="{{ baseURL }}alarms/report/{{element.id}}/generate?sessionToken={{ _login.getSessionToken() }}">
                      {{getFormattedDateTime(element.date, element.time)}} - {{element.endTime}}
                    </a>
                  </td>
                </ng-container>

                <!-- Keyword Column -->
                <ng-container matColumnDef="keyword">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 35%; vertical-align: middle;">
                    Stichwort
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a class="mat-cell" target="_blank"
                      href="{{ baseURL }}alarms/report/{{element.id}}/generate?sessionToken={{ _login.getSessionToken() }}">
                      {{element.keyword}}
                    </a>
                  </td>
                </ng-container>

                <!-- address Column -->
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef style="width: 30%; vertical-align: middle;">
                    Einsatzadresse
                  </th>
                  <td mat-cell *matCellDef="let element" style="padding-right: 30px"><a target="_blank"
                      href="https://www.google.com/maps/search/?api=1&query={{ element.zipcode + ' ' + element.city + ' ' + element.street + ' ' + element.housenumber }}">
                      {{element.street }} {{element.housenumber }} <br /> {{element.city }}</a>
                  </td>
                </ng-container>

                <!-- Personal Column -->
                <ng-container matColumnDef="count">
                  <th mat-header-cell *matHeaderCellDef style="width: 20%; padding-right: 20px">
                    Personal
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a class="mat-cell" target="_blank"
                      href="{{ baseURL }}alarms/report/{{element.id}}/generate?sessionToken={{ _login.getSessionToken() }}">
                      {{element.count}}
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="alarmColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: alarmColumns;"></tr>
              </table>
            </div>
            <div class="mt-4 row" *ngIf="alarmListLoading">
              <div class="col-lg-8 col-6">
                <ngx-skeleton-loader count="1" [theme]="{ 
                              height: '60px'
                            }"></ngx-skeleton-loader>
              </div>
              <div class="col-lg-4 col-6">
                <ngx-skeleton-loader count="1" [theme]="{ 
                              height: '60px'
                            }"></ngx-skeleton-loader>
              </div>
              <div class="col-12">
                <ngx-skeleton-loader count="6" class="mt-3" [theme]="{ 
                              height: '60px'
                            }"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="6" [theme]="{ 
                              height: '30px'
                            }"></ngx-skeleton-loader>
              </div>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons
              [hidden]="alarmListLoading"></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>