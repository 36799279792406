import { StepperSelectionEvent, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterContentInit, Component, HostListener, Inject, OnDestroy, Optional, ViewChild } from '@angular/core';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { AlarmsDetailsComponent } from './alarms-details/alarms-details.component';
import { AlarmsGeneralComponent } from './alarms-general/alarms-general.component';
import { AlarmsReportComponent } from './alarms-report/alarms-report.component';
import { AlarmsVehiclesComponent } from './alarms-vehicles/alarms-vehicles.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { HttpClient } from '@angular/common/http';
import { Login } from 'src/app/util/login';
import Swal from 'sweetalert2';
import { trigger, transition, style, animate } from '@angular/animations';
import { AlarmsFezComponent } from './alarms-fez/alarms-fez.component';
import { MatDialog as MatDialog, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlarmsConfirmationDialogComponent } from '../../../util/dialogs/alarms-confirmation-dialog/alarms-confirmation-dialog.component';
import { PreventCloseGuard } from 'src/app/util/guards/prevent-close.guard';
import { AlarmsReportTemplatesComponent } from './alarms-report-templates/alarms-report-templates.component';
import { ViewSDKClient } from 'src/app/util/proprietary/adobe/view-sdk.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit',
  templateUrl: './alarms-edit.component.html',
  styleUrls: ['./alarms-edit.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true },
  }],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'translateX(-100%)' }),
          animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
        ]),
        transition(':leave', [
          animate('200ms ease-in', style({ transform: 'translateX(-100%)' })),
        ]),
      ],
    ),
  ],
})
export class AlarmsComponent implements AfterContentInit, PreventCloseGuard, OnDestroy {

  @ViewChild('general', { static: false }) public general: AlarmsGeneralComponent;
  @ViewChild('vehicles', { static: false }) public vehicles: AlarmsVehiclesComponent;
  @ViewChild('details', { static: false }) private details: AlarmsDetailsComponent;
  @ViewChild('report', { static: false }) public report: AlarmsReportComponent;
  @ViewChild('fez', { static: false }) public fez: AlarmsFezComponent;
  @ViewChild('reportTemplate', { static: false }) public reportTemplate: AlarmsReportTemplatesComponent;
  @ViewChild('stepper') stepper: MatStepper;

  baseURL = getHostUrl();

  alarmID = null;
  showLoader = false;
  showBigLoader = true;
  controlDialog = false;
  isEnding = false;
  saveAble = true;
  disabledElements = false;
  _step = new Subject<number>();
  currentTab = 0;
  status: 'offen' | 'in Bearbeitung' | 'in Kontrolle' | 'Kontrolle abgeschlossen' | 'Rechnungsfreigabe' | 'Rechnungserstellung' | 'Rechnung erstellt' | 'Rechnung versendet' | 'ohne Rechnung' = 'offen';
  statusCategory: 'working' | 'report' | 'invoice' = 'working';

  ignoreUnsaved = false;

  userActivity;
  userInactive: Subject<any> = new Subject();
  reloadTimer;

  env = environment;

  private viewSDKClient: ViewSDKClient;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private _data: number,
    private _config: ConfigModule,
    private _route: ActivatedRoute,
    public _login: Login,
    private _router: Router,
    private _http: HttpClient,
    private _dialog: MatDialog) {
    this.setInactiveTimeout();
    this.userInactive.subscribe(() => {
      this.ignoreUnsaved = true;
      this._router.navigate(['alarms']);
      this._config.showSnackbar('Du wurdest aus dem Einsatz entfernt, da du 5 Minuten inaktiv warst!', 3000, 'warning');
    });
  }

  onCloseValid(): boolean {
    if (this.ignoreUnsaved) {
      return true;
    }
    if (this.alarmID)
      return this.general.onCloseValid() && this.vehicles.onCloseValid() && this.details.onCloseValid() && this.report.onCloseValid() && this.fez.onCloseValid();
    else
      return this.general.onCloseValid();
  }

  setInactiveTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 5 * 60 * 1000);
  }

  @HostListener('window:mousemove') refreshUserStateMouse() {
    clearTimeout(this.userActivity);
    this.setInactiveTimeout();
  }

  @HostListener('window:keyup') refreshUserStateKeyboard() {
    clearTimeout(this.userActivity);
    this.setInactiveTimeout();
  }

  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {
    this._login.isLoggedIn().subscribe(loggedIn => {
      if (!loggedIn) {
        return;
      }
      if (!this.onCloseValid() && !this.ignoreUnsaved) {
        $event.preventDefault();
        $event.returnValue = false;
      }
    });
  }

  ngAfterContentInit() {
    this._config.setTitle('Einsatz anlegen');
    this.alarmID = this._route.snapshot.params.alarmId || this._data || null;
    if (this.alarmID) {
      if (this.alarmID !== this._route.snapshot.params.alarmId)
        this.controlDialog = true;
      this._config.setTitle('Einsatzbericht | Lädt...');

      this.loadGeneralData(true);

      this.reloadTimer = setInterval(() => {
        this.loadGeneralData(false, true);
      }, 10000);

      Swal.fire({
        title: '',
        text: 'Daten werden geladen ...',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        if (this.status === 'Rechnungsfreigabe') {
          this.openInvoicePopup();
          this.stepper.selectedIndex = 4;
        }
        if (this._route.snapshot.queryParamMap.has('from') && this._route.snapshot.queryParamMap.get('from') === 'new') {
          this.stepper.selectedIndex = 1;
        }
      });
    } else {
      setTimeout(() => {
        this.showBigLoader = false;
        this.general.group.markAllAsTouched();
      }, 600);
      $('.mat-horizontal-stepper-header').hide();
    }
  }

  ngOnDestroy() {
    clearInterval(this.userActivity);
    clearInterval(this.reloadTimer);
    this.userInactive.unsubscribe();
    if (this._route.snapshot.queryParamMap.has('redirect') && this._route.snapshot.queryParamMap.get('redirect') === 'changesControl') {
      this._router.navigate(['/admin/changescontrol']);
    }
  }

  loadGeneralData(initialLoad = false, ignoreData = false) {
    this._http.get<any>(`${getHostUrl()}alarms/general?alarmID=${this.alarmID}`).subscribe(data => {
      if (ignoreData)
        return;
      this.status = data.status;
      if (this.status === 'Rechnungserstellung' || this.status === 'Rechnung erstellt' || this.status === 'Rechnung versendet' || this.status === 'ohne Rechnung' || this.status === 'Rechnungsfreigabe' && !this._login.hasPermission('alarm-release-invoice') || (this.status === 'Kontrolle abgeschlossen' || this.status === 'in Kontrolle') && !this._login.hasPermission('alarm-generate-report')) {
        this.disableElements();
        this.saveAble = false;
      }
      if (this.status === 'Rechnungserstellung' || this.status === 'Rechnung erstellt' || this.status === 'Rechnung versendet' || this.status === 'ohne Rechnung') {
        this.statusCategory = 'invoice';
      } else if (this.status === 'Rechnungsfreigabe') {
        this.statusCategory = 'invoice';
      } else if (this.status === 'Kontrolle abgeschlossen' || this.status === 'in Kontrolle') {
        this.statusCategory = 'report';
      }
      if (data.status !== 'offen' && data.status !== 'in Bearbeitung' && (!this._login.hasPermission('alarm-generate-report') || !this._login.hasPermission('alarm-generate-invoice'))) {
        this.ignoreUnsaved = true;
        this._router.navigate(['/alarms']);
        
        setTimeout(() => {
          Swal.fire({
            title: 'Einsatz bereits abgeschlossen',
            text: `Dieser Einsatz kann nicht mehr bearbeitet werden, da dieser bereits von ${data.creator !== this._login.getUserID() ? data.creator_name : 'dir'} abgeschlossen wurde!`,
            icon: 'error',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showDenyButton: true,
            denyButtonText: 'schließen',
          });
        }, 1500);
        return;
      }
      if (data.openedBy && data.openedBy !== this._login.getUserID() && this.status === 'in Bearbeitung') {
        const result = Swal.fire({
          title: 'Einsatz bereits in Bearbeitung',
          text: `Dieser Einsatz kann derzeit nicht bearbeitet werden, da dieser von ${data.openedBy_name} geöffnet ist!`,
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showDenyButton: true,
          denyButtonText: 'Zurück zur Übersicht',
        });
        result.then(() => {
          this.navigateBack();
        });
        return;
      }
    }, error => {
      if (initialLoad || ignoreData) {
        if (error.status === 401) {
          this._config.showSnackbar('Du hast keine Berechtigungen für diesen Alarm!', 3000, 'error');
          this.ignoreUnsaved = true;
          this.navigateBack();
        } else {
          this._config.showSnackbar('Ein interner Serverfehler ist aufgetreten, bitte versuche es später erneut!', 3000, 'error');
        }
        Swal.close();
      }
    });
  }

  disableElements() {
    this.general.disabledElements = true;
    this.general.group.disable();
    this.vehicles.disabledElements = true;
    this.fez.disabledElements = true;
    this.vehicles.vehicleCtrl.disable();
    this.fez.group.disable();
    this.fez.group.get('zen').enable();
    this.details.group.disable();
    this.report.disable();
    this.details.disabledElements = true;
    this.disabledElements = true;
    this.reportTemplate.templateCtrl.disable();
  }

  openInvoicePopup() {
    this.viewSDKClient = new ViewSDKClient();
    this.viewSDKClient.ready().then(() => {
      this.viewSDKClient.previewFile('pdf', {
        /* Pass the embed mode option here */
        embedMode: 'LIGHT_BOX',
        showDownloadPDF: false,
        showPrintPDF: false,
        enableFormFilling: false,
        showLeftHandPanel: false,
        showAnnotationTools: false,
        dockPageControls: true,
        showPageControls: false,
      }, `${getHostUrl()}alarms/invoice/${this.alarmID}/generate?sessionToken=${this._login.getSessionToken()}`, 'Rechnung');
      this.showBigLoader = false;
    });
  }

  loadConfirmation(navigateBack = false) {
    const dialogRef = this._dialog.open(AlarmsConfirmationDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {
        alarmId: this.alarmID,
      },
    });

    if (navigateBack) {
      dialogRef.afterClosed().subscribe(() => {
        this.ignoreUnsaved = true;
        this.navigateBack();
      });
    }
  }

  notifySelectionChange(event: StepperSelectionEvent) {
    this.currentTab = event.selectedIndex;
    if (!this.saveAble || this.disabledElements)
      return;
    switch (event.previouslySelectedIndex) {
      case 0: {
        this.general.saveAlarmGeneral();
        break;
      }
      case 1: {
        this.vehicles.saveAlarmVehicles().finally(() => {
          this.details.loadLeader();
        });
        break;
      }
      case 2: {
        this.details.saveAlarmDetail();
        break;
      }
      case 3: {
        this.report.saveReport();
        break;
      }
      case 4: {
        this.fez.saveData();
        break;
      }
    }
    switch (event.selectedIndex) {
      case 0: {
        this.general.fetchAlarmGeneral();
        break;
      }
      case 1: {
        this.vehicles.loadVehicleData();
        this.vehicles.opened = true;
        break;
      }
      case 2: {
        this.details.fetchAlarmDetail();
        this.details.loadLeader();
        if (this.details.shouldRecommend) {
          this.details.loadRecommendation();
        }
        break;
      }
      case 3: {
        this.report.loadReport();
        break;
      }
      case 4: {
        this.fez.loadData();
        break;
      }
    }
    this._step.next(event.selectedIndex);
  }

  checkReport() {
    return new Promise((resolve, reject) => {
      if (this.status === 'in Kontrolle') {
        const result = Swal.fire({
          title: 'Einsatz als geprüft markieren?',
          text: 'Soll der Einsatz wirklich als geprüft markiert werden?',
          icon: 'question',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showDenyButton: true,
          reverseButtons: true,
          confirmButtonText: 'Abschließen',
          denyButtonText: 'Abbrechen',
        });
        result.then(dialogResult => {
          if (dialogResult.isConfirmed) {
            resolve(true);
          } else {
            reject();
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  finalizeReport() {
    this.checkReport().then(() => {
      this.isEnding = true;
      const result = Swal.fire({
        title: 'Einsatz wird geprüft',
        text: 'Der Einsatz wird geprüft, bitte warten!',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showDenyButton: false,
      });
      this.fez.saveData().then(() => {
        this._http.post(`${getHostUrl()}alarms/fez/finalizes`, {
          alarmId: this.alarmID,
        }).subscribe((data: any) => {
          Swal.close();
          this._dialog.closeAll();
          if (this.status === 'in Bearbeitung') {
            if (data.status === 'ok') {
              this.disableElements();
              this.loadConfirmation(true);
            } else {
              this._config.showSnackbar('Einsatz konnte im Moment nicht abgeschlossen werden! Bitte erneut versuchen.', 5000, 'warning');
            }
          } else if (this.status === 'in Kontrolle') {
            if (!this.controlDialog)
              this.navigateBack();
            this._config.showSnackbar('Einsatz wurde erfolgreich geprüft!', 3000, 'success');
          } else if (this.status === 'Rechnungsfreigabe') {
            if (!this.controlDialog)
              this.navigateBack();
            this._config.showSnackbar('Rechnung wurde erfolgreich freigegeben!', 3000, 'success');
          }
        }, error => {
          Swal.close();
          this._config.showSnackbar(error.error.message, 3000, 'error');
        });
      }).catch(() => {
        Swal.close();
        this._config.showSnackbar('Fehler beim Speichern des Einsatzberichtes, bitte versuche es erneut!', 3000, 'error');
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  navigateBack() {
    this._router.navigate(['alarms']);
  }

  discardReport() {
    const result = Swal.fire({
      title: 'Rechnung ablehnen?',
      input: 'textarea',
      inputLabel: 'Nachricht an Schriftführer',
      inputValue: '',
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showDenyButton: true,
      reverseButtons: true,
      confirmButtonText: 'Ablehnen',
      denyButtonText: 'Abbrechen',
      inputValidator: (value) => {
        if (!value) {
          return 'Begründung muss ausgefüllt sein!';
        }
      },
    });
    result.then(dialogResult => {
      if (dialogResult.isConfirmed) {
        this._http.post(`${getHostUrl()}alarms/fez/discard`, {
          alarmId: this.alarmID,
          message: `Ablehngrund: ${dialogResult.value}`,
        }).subscribe(data => {
          this.navigateBack()
          this._config.showSnackbar('Rechnung wurde erfolgreich zurückgestuft!', 3000, 'warning');
        }, error => {
          this._config.showSnackbar('Fehler beim Zurückstufen des Einsatzberichtes, bitte versuche es erneut!', 3000, 'error');
        });
      }
    });
  }

  reeditAlarm() {
    const result = Swal.fire({
      title: 'Einsatz zurückstufen?',
      text: 'Soll der Einsatz wirklich zur Bearbeitung zurückgestuft werden?',
      icon: 'question',
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showDenyButton: true,
      reverseButtons: true,
      confirmButtonText: 'Zurückstufen',
      denyButtonText: 'Abbrechen',
    });
    result.then(dialogResult => {
      if (dialogResult.isConfirmed) {
        this._http.post(`${getHostUrl()}alarms/fez/reedit`, {
          alarmId: this.alarmID,
        }).subscribe(data => {
          this.navigateBack();
          this._config.showSnackbar('Einsatz wurde erfolgreich zurückgestuft!', 3000, 'warning');
        }, error => {
          this._config.showSnackbar('Fehler beim Zurückstufen des Einsatzberichtes, bitte versuche es erneut!', 3000, 'error');
        });
      }
    });
  }

}

