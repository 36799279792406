import { Component, OnInit } from '@angular/core';
import { ThemingService } from 'src/app/util/services/theming.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Login } from 'src/app/util/login';
import { getHostUrl, ConfigModule } from 'src/app/util/config';
import { MatDialog as MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.css'],
})
export class ProfileSettingsComponent implements OnInit {

  settingsObj: any = {};

  darkMode:boolean;

  darkModeSystemBased: boolean;

  darkModeOptionSelected: string;

  darkModeOptions:string[] = ['Aktiviert', 'Deaktiviert', 'Systembasiert'];

  constructor(private _themeService: ThemingService,
    private _http: HttpClient,
    private _config: ConfigModule,
    private _login: Login,
    private _dialog: MatDialog) {
    const darkMode = localStorage.getItem('darkMode');

    if (darkMode === 'enabled'){
      this.darkModeOptionSelected = this.darkModeOptions[0];
    } else if (darkMode === 'disabled'){
      this.darkModeOptionSelected = this.darkModeOptions[1];
    } else {
      this.darkModeOptionSelected = this.darkModeOptions[2];
    }
  }


  ngOnInit() {
    this._config.setTitle('Einstellungen');
    this.fetchDatainfo();
  }

  hasPermission(name) {
    if (!environment.userInfo) return;
    return environment.userInfo.permList.indexOf(name) !== -1;
  }

  updateDarkMode() {
    if (this.darkModeOptionSelected === this.darkModeOptions[0]){
      localStorage.setItem('darkMode', 'enabled');
      this._themeService.toggleDarkMode(true);
    } else if (this.darkModeOptionSelected === this.darkModeOptions[1]){
      localStorage.setItem('darkMode', 'disabled');
      this._themeService.toggleDarkMode(false);
    } else {
      const darkModeOn =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
      this._themeService.toggleDarkMode(darkModeOn);
      localStorage.removeItem('darkMode');
    }
    window.location.reload();
  }

  storeSettings() {
    this._http.post(`${getHostUrl()}profile/settings`, {
      
      settingsObj: this.settingsObj,
    }).subscribe(data => {
      this._config.showSnackbar('Einstellungen übernommen.', 2000, 'success');
    });
  }

  async fetchDatainfo() {
    this._http.get<any>(`${getHostUrl()}profile/settings`).subscribe(data => {
      this.settingsObj = data;
    });
  }
}
