import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Guard, GuardBilling } from '@ffo/mitgliederbereich-types';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import dayjs from 'dayjs';

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.css']
})
export class AdminGuardsAccountingSendComponent {

  constructor(private _http: HttpClient, private _config: ConfigModule, private _router: Router) { }

  loading: boolean = true;
  guardList: Guard[] | GuardBilling[] = [];

  viewColumns: string[] = ['select', 'name', 'date', 'accounting'];

  ngOnInit(): void {
    this.loadGuards();
  }

  loadGuards(): void {
    this.loading = true;
    this._http.get<Guard[] | GuardBilling[]>(getHostUrl() + 'admin/fields/guards/guards')
      .subscribe(data => {
        this.guardList = data.filter((guard: Guard | GuardBilling) => {
          return guard.status === 'in Abrechnung';
        }).map((guard: Guard | GuardBilling) => {
          guard.date = new Date(guard.date);
          return guard;
        });
        this.loading = false;
      });
  }

  selection = new SelectionModel<Guard | GuardBilling>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.guardList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.guardList);
  }

  sendAccounting() {
    const guards: number[] = this.selection.selected.map((guard: Guard | GuardBilling) => guard.id);
    this._http.post(getHostUrl() + 'admin/fields/guards/guards/accounting', { guards })
      .subscribe({
        next: (data) => {
          this._config.showSnackbar("Abrechnung wurde verschickt", 3000, 'success');
          this._router.navigate([`/admin/fields/guards`]);
        }, error: (err) => {
          this._config.showSnackbar("Fehler beim Versenden der Abrechnung", 3000, 'error');
        }
      });
  }

  getHoursDiff(_begin: string, _end: string) {
    if (!_begin || _begin.length !== 5 || !_end || _end.length !== 5) {
      return '--';
    }
    const day = dayjs();
    let begin = day.set('hour', parseInt(_begin.split(':')[0])).set('minute', parseInt(_begin.split(':')[1]));
    let end = day.set('hour', parseInt(_end.split(':')[0])).set('minute', parseInt(_end.split(':')[1]));

    if (begin.minute() >= 53) {
      begin = begin.set('minute', 0).add(1, 'hour');
    } else if (begin.minute() >= 23 && begin.minute() < 53) {
      begin = begin.set('minute', 30);
    } else {
      begin = begin.set('minute', 0);
    }

    if (end.minute() >= 7 && end.minute() <= 36) {
      end = end.set('minute', 30);
    } else if (end.minute() >= 37) {
      end = end.set('minute', 0).add(1, 'hour');
    } else {
      end = end.set('minute', 0);
    }

    if (end.isBefore(begin)) {
      end = end.add(1, 'day');
    }
    return end.diff(begin, 'minutes') / 60;
  }
}
