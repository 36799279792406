import { Component, ViewChild, TemplateRef, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Login } from 'src/app/util/login';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import { DateTime } from 'luxon';
import { AlarmInformation } from '@ffo/mitgliederbereich-types';



@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class SocialMediaComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  alarmColumns: string[] = ['date', 'keyword', 'address', 'count'];

  alarmList: AlarmInformation[] = [];

  alarm: MatTableDataSource<AlarmInformation>;

  alarmListLoading: boolean = true;

  searchFilter: string = '';

  baseURL = getHostUrl();

  reloadAlarm: any;

  env = environment;

  constructor(private _http: HttpClient, public _login: Login,
    private _config: ConfigModule,
    private _ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._config.setTitle('Fachbereich | Social Media');
    this.loadAlarm(true);

    this.reloadAlarm = setInterval(() => {
      this._login.isLoggedIn().subscribe(loggedIn => {
        if (loggedIn) {
          this.loadAlarm();
        }
      });
    }, 6000);
  }

  ngOnDestroy() {
    clearInterval(this.reloadAlarm);
  }

  loadAlarm(initialLoad = false) {
    this._http.get<AlarmInformation[]>(getHostUrl() + 'admin/socialMedia/alarmList')
      .subscribe(data => {
        if (initialLoad) {
          this.inititalizeTable();
        }
        this.alarmList = data.map(alarm => {
          alarm.date = new Date(alarm.date);
          return alarm;
        });
        this.alarmListLoading = false;
        this.alarm.data = this.alarmList;
      });
  }

  getFormattedDateTime(_date: Date, _time: string) {
    const date = DateTime.fromJSDate(_date).setZone('Europe/Berlin');
    const time = DateTime.fromSQL(_time).setZone('Europe/Berlin');
    return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toFormat('dd.MM.yyyy HH:mm');
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.applyAlarmFilter();
  }

  applyAlarmFilter() {
    this.alarm.filter = JSON.stringify({ search: this.searchFilter });
  }

  clearSearch(): void {
    this.searchFilter = '';
    this.applyAlarmFilter();
  }

  filter(): (data: AlarmInformation, filter: string) => boolean {
    let filterFunction = function (data: AlarmInformation, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let searchResult1 = false;
      let searchResult2 = true;

      if (searchTerms.search) {
        Object.keys(data).forEach(elem => {
          if (String(data[elem]).toLowerCase().includes(searchTerms.search.toLowerCase())) {
            searchResult1 = true;
          } else if (data[elem] instanceof Date) {
            const date = DateTime.fromJSDate(data[elem]);
            searchResult1 = date.toFormat('dd.MM.yyyy').includes(searchTerms.search.toLowerCase());
          }
          if (searchResult1)
            return;
        });
      } else {
        searchResult1 = true;
      }
      return searchResult1 && searchResult2;
    };
    return filterFunction;
  }

  inititalizeTable() {
    this.alarm = new MatTableDataSource<AlarmInformation>(this.alarmList);
    this.alarm.filterPredicate = this.filter();
    this.alarm.sortingDataAccessor = (item: AlarmInformation, property) => {
      switch (property) {
        case 'date':
          const date = DateTime.fromJSDate(item.date).setZone('Europe/Berlin');
          const time = DateTime.fromFormat(item.time, 'HH:mm:ss').setZone('Europe/Berlin');
          return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toJSDate();
        default: return item[property];
      }
    };
    this.alarm.sort = this.sort;
    this.alarm.paginator = this.paginator;
  }
}
