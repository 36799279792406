<h2 mat-dialog-title class="text-center">
  Kategorie
  <span *ngIf="!categoryId">erstellen</span>
  <span *ngIf="categoryId">bearbeiten</span>
  <p><small>{{ group.get('name').value }}</small></p>
</h2>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="group" class="row">
    <mat-form-field class="input-full-width mt-4" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput maxlength="100" formControlName="name" />
      <mat-error>Name muss ausgefüllt sein!</mat-error>
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Berechtigungsgruppe auswählen</mat-label>
      <mat-select formControlName="group">
        <mat-option>
          <ngx-mat-select-search [formControl]="groupFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let group of groupFilter | async" [value]="group.id">
          {{ group.name }}
        </mat-option>
      </mat-select>
      <mat-error>Berechtigungsgruppe muss ausgefüllt sein!</mat-error>
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select formControlName="active">
        <mat-option [value]="1">
          Aktiv
        </mat-option>
        <mat-option [value]="0">
          Inaktiv
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="clearfix w-100 pe-3">
    <button mat-raised-button class="float-end" color="accent" [disabled]="group.invalid" (click)="save()">
      <div class="clearfix submit-button">
        <mat-icon class="me-3 mt-1 float-left" [hidden]="!isLoading">
          <mat-spinner diameter="20" class="light"></mat-spinner>
        </mat-icon>
        <span class="me-1 float-left">Kategorie speichern</span>
        <mat-icon class="float-right">save</mat-icon>
      </div>
    </button>
  </div>
</mat-dialog-actions>