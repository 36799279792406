import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getHostUrl } from 'src/app/util/config';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import * as $ from 'jquery';


@Component({
  selector: 'app-alarms-damage',
  templateUrl: './alarms-damage.component.html',
  styleUrls: ['./alarms-damage.component.css'],
})
export class AlarmsDamageComponent implements OnInit, OnDestroy {

  constructor(private _http: HttpClient,
    private _dialogRef: MatDialogRef<AlarmsDamageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  group = new UntypedFormGroup({});

  measureList: any = [];

  public measureListCtrl: UntypedFormControl = new UntypedFormControl();

  public measureFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.loadMeasureTypes();

    this.group = this.data.group;

    this.measureListCtrl.valueChanges.subscribe(() => {
      this.filterMeasure();
    });
    this.group.markAllAsTouched();
  }

  getMeasureById(id) {
    return this.measureList.find(measure => measure.id.toString() == id)?.name;
  }

  saveDamage() {
    this._dialogRef.close(this.group.value);
  }

  loadMeasureTypes() {
    return new Promise((resolve, reject) => {
      Promise.all([
        this._http.get(`${getHostUrl()}datainfo/measure`).toPromise(),
      ]).then(values => {
        this.measureList = values[0] || [];

        this.measureFilter.next(this.measureList.slice());
        this.measureListCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterMeasure();
          });

        resolve(true);
      }).catch(reject);
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterMeasure() {
    if (!this.measureList) {
      return;
    }
    // get the search measure
    let search = this.measureListCtrl.value;
    if (!search) {
      this.measureFilter.next(this.measureList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the measure
    this.measureFilter.next(
      this.measureList.filter(measure => measure.name.toLowerCase().indexOf(search) > -1));

  }

  getMeasureName(search) {
    return this.measureList.filter(measure => measure.id === search)[0].name;
  }

  measureReset() {
    this.measureListCtrl.reset();
    $('#measure_search').find('input').trigger('focus');
  }
}
