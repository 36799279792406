import { Component, Input } from '@angular/core';
import { HelpComponent } from '../help.component';

@Component({
  selector: 'app-help-events',
  templateUrl: './help-events.component.html',
  styleUrls: ['./help-events.component.css'],
})
export class HelpEventsComponent {

  @Input() parent: HelpComponent;

  constructor() { }
}
