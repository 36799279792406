<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Sicherheitswachen Einteilung</h3>
    <mat-card>
      <mat-card-content>
        <a mat-mini-fab color="primary" class="pageBack" onClick="javascript:window.history.back()">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Veranstaltungsliste
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>list</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="input-full-width" appearance="outline">
                  <input matInput (keyup)="applySearch($event.target.value)" placeholder="Suche">
                </mat-form-field>
              </div>
              <div class="col-12">
                <div class="table-responsive">
                  <div *ngIf="loading">
                    <mat-progress-bar mode="query"></mat-progress-bar>
                  </div>
                  <table mat-table [dataSource]="guards" matSort matSortActive="date" matSortDirection="asc"
                    matSortDisableClear>

                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                      <td mat-cell *matCellDef="let guard">{{guard.name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum</th>
                      <td mat-cell *matCellDef="let guard">
                        <div>
                          {{ guard.date | date:'dd.MM.YYYY' }}
                        </div>
                        <div style="margin-left: 10px;">
                          {{ guard.guardStart }} Uhr
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="viewColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: viewColumns" [class.active]="row.id === activeGuard.id"
                      (click)="setGuard(row)">
                    </tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons>
                  </mat-paginator>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <hr>
        <div class="row">
          <div class="col-lg-6 col-12">
            <h5 style="text-align: center; line-height: 70px;">{{activeGuard?.name}} -
              {{activeGuard?.date.toLocaleDateString('de-De', { weekday: 'long', day: '2-digit',
              month: '2-digit', year: 'numeric'})}} {{activeGuard?.guardStart}}</h5>
          </div>
          <div class="col-lg-6 col-12">
            <mat-form-field appearance="outline" class="input-full-width">
              <input matInput [matDatepicker]="pickerEnd" [max]="today" readonly="true" (focus)="pickerEnd.open()"
                style="cursor: pointer" [(ngModel)]="checkDate" (dateChange)="setCheckDate()" />
              <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
              <mat-datepicker #pickerEnd></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="assignment row">
          <div class="col-12">
            <div class="table-responsive">
              <div *ngIf="assignmentInfoList.length === 0 || saving">
                <mat-progress-bar mode="query"></mat-progress-bar>
              </div>
              <section class="assignmentTable">
                <table mat-table [dataSource]="assignmentInfoList">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left;">Name</th>
                    <td mat-cell *matCellDef="let user" style="text-align: left;">{{user.firstname}} {{user.lastname}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>WS</th>
                    <td mat-cell *matCellDef="let user" [ngSwitch]="user.state">
                      <span *ngSwitchCase="'WM'">WM</span>
                      <span *ngSwitchCase="'WL'">WL</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="count">
                    <th mat-header-cell *matHeaderCellDef>Anzahl</th>
                    <td mat-cell *matCellDef="let user">
                      {{user.count}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="last">
                    <th mat-header-cell *matHeaderCellDef>letzte Wache</th>
                    <td mat-cell *matCellDef="let user">
                      {{user.last?.toLocaleDateString('de-De', { day: '2-digit', month: '2-digit', year: 'numeric'})}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="lastGuards">
                    <th mat-header-cell *matHeaderCellDef>
                      <table>
                        <tr>
                          <td colspan="3">Einteilungsübersicht</td>
                        </tr>
                        <tr>
                          <td style="width: 90px">Pos.</td>
                          <td style="width: 90px">Anzahl</td>
                          <td style="width: 90px;">zuletzt</td>
                        </tr>
                      </table>
                    </th>
                    <td mat-cell *matCellDef="let user">
                      <table *ngIf="user.assignment.length !== 0">
                        <thead style="visibility: collapse;">
                          <tr>
                            <td style="width: 90px">Position</td>
                            <td style="width: 90px">Anzahl</td>
                            <td style="width: 90px">zuletzt</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let assignment of user.assignment"
                            [ngClass]="getGuardBackgroundColor(assignment.last)">
                            <td>{{position[assignment.position-1]}}</td>
                            <td>{{assignment.count}}</td>
                            <td *ngIf="getDateDiff(activeGuard.date, assignment.last) > 0"
                              style="text-align: left; padding-left: 15px;">vor {{getDateDiff(activeGuard.date,
                              assignment.last)}} Tagen</td>
                            <td *ngIf="getDateDiff(activeGuard.date, assignment.last) <= 0"
                              style="text-align: left; padding-left: 15px;">in {{getDateDiff(assignment.last,
                              activeGuard.date)}} Tagen</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="assigned">
                    <th mat-header-cell *matHeaderCellDef>Einteilung</th>
                    <td mat-cell *matCellDef="let user" style="text-align: right;">
                      <mat-form-field appearance="fill" class="input-full-width">
                        <mat-select (selectionChange)="setAssignment($event, user.userID)">
                          <mat-option></mat-option>
                          <mat-option value=1 [disabled]="assignment.has('1')">1. Wachleiter</mat-option>
                          <mat-option value=2 [disabled]="assignment.has('2')">2. Wachleiter</mat-option>
                          <mat-option value=3 [disabled]="assignment.has('3')">3. Wachleiter</mat-option>
                          <mat-option value=4 [disabled]="assignment.has('4')">1. Wachmann</mat-option>
                          <mat-option value=5 [disabled]="assignment.has('5')">2. Wachmann</mat-option>
                          <mat-option value=6 [disabled]="assignment.has('6')">3. Wachmann</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="viewColumnsAssignment; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: viewColumnsAssignment"></tr>
                </table>
              </section>
            </div>
          </div>
        </div>
        <div class="clearfix">
          <button mat-raised-button color="accent" class="float-left" (click)="nextGuard()">weiter</button>
          <button mat-raised-button color="success" class="float-right" [disabled]="!validAssignment()"
            (click)="assign()">einteilen</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>