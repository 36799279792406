<div class="row" id="scrollingResponder">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">EvD Dienstplan</h3>
    <mat-card>
      <mat-card-content>
        <div *ngIf="!scheduleArray" style="margin-bottom: 15px;">
          <mat-progress-bar mode="query"></mat-progress-bar>
        </div>
        <button class="showMoreButton" mat-button (click)="showPrevious()" [hidden]="!isEvDAdmin()" style="width: max-content;">
          Zurückliegende anzeigen <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <div class="table-responsive">
          <table class="table">
            <thead id="tableHeader">
              <th class="date" id="dateHeader">Datum</th>
              <th class="column" id="evdHeader">EvD</th>
            </thead>
            <tbody>
              <tr id="spacer" style="border: 0px; height: 0px"></tr>
              <tr #EvDList *ngFor="let _ of getArray(SHIFTCOUNT); let i=index" [attr.ready]="checkScroll">
                <td class="table-info text-center dateBody" style="vertical-align: middle;"
                  *ngIf="calculateDate(i); let date">
                  <b><mat-icon>sunny</mat-icon></b><br>
                  {{ getDayText(date) }}<br />{{ getDay(date) }}<br>
                  <b><mat-icon>dark_mode</mat-icon></b>
                </td>
                <td *ngIf="calculateDate(i); let date" class="evdBody">
                  <div style="margin-top: 10px">
                    <button mat-flat-button color="warn" class="input-full-width" *ngIf="!hasShift(date, 'day')"
                      (click)="addShift(date, 'day')" [disabled]="!isEvD()">
                      EvD
                    </button>
                    <button mat-flat-button [color]="shift.comments ? 'primary' : 'success'" [class.comments]="shift.comments"  class="input-full-width"
                      *ngIf="hasShift(date, 'day') && getShift(date, 'day'); let shift" [id]="shift.id"
                      (click)="openShift(shift)">
                      {{ shift.user.name }}
                    </button>
                  </div>
                  <hr />
                  <div>
                    <button mat-flat-button color="warn" class="input-full-width" *ngIf="!hasShift(date, 'night')"
                      (click)="addShift(date, 'night')" [disabled]="!isEvD()">
                      EvD
                    </button>
                    <button mat-flat-button [color]="shift.comments ? 'primary' : 'success'" [class.comments]="shift.comments"  class="input-full-width"
                      *ngIf="hasShift(date, 'night') && getShift(date, 'night'); let shift" [id]="shift.id"
                      (click)="openShift(shift)">
                      {{ shift.user.name }}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button class="showMoreButton" [disabled]="SHIFTCOUNT >= 62" mat-button (click)="showMore()">
          Mehr anzeigen <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
  </div>
</div>