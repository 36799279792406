<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Wachbericht</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Veranstaltungsliste
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>list</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="input-full-width" appearance="outline">
                  <input matInput (keyup)="applySearch($event.target.value)" placeholder="Suche">
                </mat-form-field>
              </div>
              <div class="col-12">
                <div class="table-responsive">
                  <div *ngIf="loading">
                    <mat-progress-bar mode="query"></mat-progress-bar>
                  </div>
                  <table mat-table [dataSource]="guards" matSort matSortActive="date" matSortDirection="asc"
                    matSortDisableClear>

                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                      <td mat-cell *matCellDef="let guard">{{guard.name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum</th>
                      <td mat-cell *matCellDef="let guard">
                        <div>
                          {{ guard.date | date:'dd.MM.YYYY' }}
                        </div>
                        <div style="margin-left: 10px;">
                          {{ guard.guardStart }} Uhr
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="viewColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: viewColumns; let i = index"
                      [class.active]="row.id === guardList[activeGuardIndex].id" (click)="setGuard(i)">
                    </tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons>
                  </mat-paginator>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <hr>
        <form [formGroup]="group">
          <div class="row">
            <div class="col-12">
              <h5 style="line-height: 70px;">{{guardList[activeGuardIndex]?.name}} -
                {{guardList[activeGuardIndex]?.date.toLocaleDateString('de-De', { weekday: 'long', day: '2-digit',
                month: '2-digit', year: 'numeric'})}} {{guardList[activeGuardIndex]?.guardStart}}</h5>
            </div>
            <div class="col-md-6 col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Veranstaltungsende</mat-label>
                <input matInput formControlName="guardEnd" (keyup)="parseTimeField($event, 'guardEnd', 'begin')" />
                <mat-error *ngIf="!group.get('guardEnd').value">Veranstaltungsende darf nicht leer sein!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
              <mat-form-field floatLabel="always" class="input-full-width" appearance="outline">
                <input matInput style="display: none" />
                <mat-label>Einsatznummer</mat-label>
                <mat-select style="width: 20%" formControlName="alarmReferencePrefix">
                  <mat-option [value]="reference.name" *ngFor="let reference of referencePrefixList">{{ reference.name
                    }}</mat-option>
                </mat-select>
                <input type="number" matInput inputmode="numeric" formControlName="alarmReference" min="0"
                  max="999999999" style="width: 75%; margin-left: 5%" />
                <mat-hint class="mat-error" *ngIf="!group.get('alarmReference').value">
                  Einsatznummer muss ausgefüllt sein!</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-5 col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Abrechnungsbeginn</mat-label>
                <input matInput formControlName="begin" (keyup)="parseTimeField($event, 'begin', 'end')" />
                <mat-error *ngIf="!group.get('begin').value">Beginn darf nicht leer sein!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-5 col-12">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Abrechnungsende</mat-label>
                <input matInput formControlName="end" (keyup)="parseTimeField($event, 'end')" />
                <mat-error *ngIf="!group.get('end').value">Ende darf nicht leer sein!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2 col-12 sum">
              <button mat-flat-button>
                <mat-icon>functions</mat-icon> {{getHoursDiff(group.get('begin').value, group.get('end').value)}} h
              </button>
            </div>
            <h3>Personal</h3>
            <div class="col-10">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Wachleiter</mat-label>
                <mat-select placeholder="Wachleiter" formControlName="guardLeader">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="guardLeaderFilterCtrl"
                      noEntriesFoundLabel="Kein Wachleiter verfügbar"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let guard of guardLeaderFilter | async" [value]="guard.userID">
                    {{guard.userID}} - {{guard.firstname}} {{guard.lastname}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Stunden</mat-label>
                <input matInput formControlName="guardLeaderHours" type="number" step="0.5"
                  [max]="getHoursDiff(group.get('begin').value, group.get('end').value)" [min]="0" />
                <span matSuffix>h</span>
              </mat-form-field>
            </div>
            <ng-container *ngFor="let control of guardPersonal.controls; index as i">
              <ng-container [formGroup]="control">
                <div class="col-9">
                  <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Wachmann</mat-label>
                    <mat-select placeholder="Wachmann" formControlName="guard">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="guardManFilterCtrl"
                          noEntriesFoundLabel="Kein Wachmann verfügbar"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let guard of guardManFilter[i] | async" [value]="guard.userID">
                        {{guard.userID}} - {{guard.firstname}} {{guard.lastname}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Stunden</mat-label>
                    <input matInput formControlName="hours" type="number" step="0.5"
                      [max]="getHoursDiff(group.get('begin').value, group.get('end').value)" [min]="0" />
                    <span matSuffix>h</span>
                  </mat-form-field>
                </div>
                <div class="col-1">
                  <button mat-icon-button (click)="removeGuardPersonal(i)" matTooltip="Person entfernen">
                    <mat-icon>person_remove</mat-icon>
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions style="justify-content: space-between;">
        <button mat-raised-button color="primary" (click)="addGuardPersonal()"
          [disabled]="getHoursDiff(group.get('begin').value, group.get('end').value) === '--'"><mat-icon>person_add</mat-icon>Wachmann
          hinzufügen</button>
        <button mat-raised-button color="success" (click)="save()"
          [disabled]="!group.valid"><mat-icon>save</mat-icon>speichern</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>