<mat-spinner *ngIf="isLoading" color="accent" class="loading-spinner-center" [diameter]="200"></mat-spinner>

<div class="container">
  <div class="col-lg-8 col-12 offset-lg-2" *ngIf="!isLoading">
    <h3 class="section-header">Kategorie
      <span *ngIf="!categoryId" class="ms-2">erstellen</span>
      <span *ngIf="categoryId" class="ms-2">bearbeiten</span>
    </h3>
    <mat-card class="mb-4">
      <mat-card-content>
        <a routerLink="/material/categories" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>

        <form [formGroup]="group">
          <div class="row">
            <div class="col-12" [class.col-lg-6]="categoryId">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Name</mat-label>
                <input matInput type="text" formControlName="name" required="true" />
                <mat-error *ngIf="group.get('name').invalid">Name ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12" *ngIf="categoryId">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" required="true">
                  <mat-option value="active">aktiv</mat-option>
                  <mat-option value="retired">außer Dienst</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('status').invalid">Status ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Preis</mat-label>
                <mat-icon matSuffix>euro</mat-icon>
                <input matInput formControlName="price" type="number" />
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-slide-toggle formControlName="consumables" class="mt-lg-4 mb-2">Materialien sind
                Verbrauchsmaterial?</mat-slide-toggle>
            </div>
          </div>

          <mat-form-field appearance="outline" class="input-full-width mt-2">
            <mat-label>Prüfungen</mat-label>
            <mat-select formControlName="checks" multiple>
              <mat-option>
                <ngx-mat-select-search [formControl]="checkFilterCtrl"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let check of checkFilter | async" [value]="check.id">{{ check.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="row">
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Anzahl</mat-label>
                <input matInput type="number" min="0.1" formControlName="qty" />
                <mat-error *ngIf="group.get('unit').invalid">Anzahl muss gesetzt sein!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Einheit</mat-label>
                <mat-select formControlName="unit" required="true">
                  <mat-option *ngFor="let unit of unitList" [value]="unit.id">{{ unit.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('unit').invalid">Einheit muss gesetzt sein!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Arten</mat-label>
                <mat-chip-grid #chipGrid aria-label="Arten">
                  <mat-chip-row *ngFor="let option of group.get('options').value" [editable]="true"
                    (edited)="editOption(option, $event)" (removed)="removeOption(option)">
                    {{option}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                  <input placeholder="Neue Art..." [matChipInputFor]="chipGrid"
                    (matChipInputTokenEnd)="addOption($event)" />
                </mat-chip-grid>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Durch welche Gruppen verwaltet?</mat-label>
                <mat-select formControlName="groups" required="true" multiple>
                  <mat-option>
                    <ngx-mat-select-search [formControl]="groupFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let group of groupFilter | async" [value]="group.id">{{ group.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('groups').invalid">Gruppen müssen gesetzt sein!</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div class="clearfix">
          <button mat-raised-button class="float-right" color="accent" [disabled]="group.invalid" (click)="saveData()">
            <div class="clearfix submit-button">
              <mat-icon class="me-3 mt-1 float-left" [hidden]="!isSaving">
                <mat-spinner diameter="20"></mat-spinner>
              </mat-icon>
              <span class="me-1 float-left">Speichern</span>
              <mat-icon class="float-right">save</mat-icon>
            </div>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>