import { Component, OnInit, ViewChild, Input, Inject, OnDestroy } from '@angular/core';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { Login } from 'src/app/util/login';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate } from '@angular/common';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { ThemingService } from 'src/app/util/services/theming.service';
import { MatDialog as MatDialog, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ControlComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['user', 'target', 'time', 'action'];

  dataSource = new MatTableDataSource();

  checks: any[] = [];

  users: any[] = [];

  searchFilter: string = '';

  protected _onDestroy = new Subject<void>();

  constructor(
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _config: ConfigModule,
    private _spinner: NgxSpinnerService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this._config.setTitle('Änderungskontrolle');
    this.getDataInfo();
    this.getData();
    if ($(window).width() < 600) {
      this.displayedColumns = ['target', 'time', 'action'];
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getData() {
    Promise.all([
      this._http.get<any[]>(getHostUrl() + 'admin/checkChanges/list').toPromise(),
      this._http.get<any[]>(getHostUrl() + 'admin/checkChanges/listAlarms').toPromise(),
    ]).then((values: any[]) => {
      this.checks = values[0].concat(values[1]);
      this.dataSource = new MatTableDataSource<any>();
      this.dataSource.data = this.checks;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (item: any, property: any) => {
        switch (property) {
          case 'time':
            return dayjs(item.time).format('x');
          default:
            return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this._spinner.hide();
    });
  }

  async getDataInfo() {
    this.users = await this._http.get<any[]>(getHostUrl() + 'admin/checkChanges/users').toPromise();
  }

  getFormattedDate(dateValue: any) {
    if (dateValue === null || dateValue.toString() === '0000-00-00' || dateValue === '' || dateValue.toString() === '1901-01-01' || dateValue.toString() === '1900-12-31')
      return ('unbegrenzt');
    return dayjs(dateValue).format('DD.MM.YYYY HH:mm');
  }

  getUser(userID: number): string {
    if (userID === -1)
      return 'System';
    let result: string;
    this.users.forEach(user => {
      if (userID === user.id) {
        result = user.firstname + ' ' + user.lastname;
        return;
      }
    });
    return (userID + ' - ' + result);
  }

  accept(element: any) {
    this._http.get<any[]>(`${getHostUrl()}admin/checkChanges/accept?controlID=${element.id}&querUserID=${element.targetID}`).subscribe(data => {
      this._spinner.hide();
    });
    let index = this.checks.indexOf(element);
    this.checks.splice(index, 1);
    this.dataSource.data = this.checks;
  }

  open(element: any) {
    switch (element.type) {
      case 'alarm': {
        this._router.navigate(['/alarms/edit/', element.id], { queryParams: { redirect: 'changesControl' } });
        break;
      }
      case 'classes': {
        const classesDialog = this._dialog.open(ClassesChangesControlComponent, { data: { userID: element.targetID, parent: this, element: element, new: JSON.parse(element.new), old: JSON.parse(element.old) } });
        classesDialog.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe(() => {
          this.getData();
        });
        break;
      }
      case 'licence': {
        const licenceDialog = this._dialog.open(LicenceChangesControlComponent, { data: { userID: element.targetID, parent: this, element: element, new: JSON.parse(element.new), old: JSON.parse(element.old) } });
        licenceDialog.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe(() => {
          this.getData();
        });
        break;
      }
    }
  }
}

@Component({
  selector: 'app-changes-licence',
  templateUrl: './driving/licence.html',
  styleUrls: ['./control.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class LicenceChangesControlComponent implements OnInit {

  userID: string;

  element: any;

  parent: ControlComponent;

  licence_new: any;

  licence_old: any;

  front: string;

  picture: boolean = false;

  today: Date = new Date();

  expiryLicence: Date = dayjs(this.today).add(15, 'years').toDate();

  age18Date: Date;

  env = environment;

  constructor(private _http: HttpClient,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _login: Login,
    private _config: ConfigModule,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.userID = this._data.userID;
    this.element = this._data.element;
    this.parent = this._data.parent;
    this.licence_new = this._data.new;
    this.licence_old = this._data.old;
    if (this.licence_old.dateOfIssue === null && this.licence_old.dateOfExpiry === null && this.licence_old.issuingAuthority === '' && this.licence_old.dlNumber === '')
      this.picture = true;
    this._http.get<any>(getHostUrl() + 'admin/user/drivingassets?userID=' + this.userID).subscribe(data => {
      this._http.get<string>(`${getHostUrl()}licence?licence=${data.front}`).subscribe({
        next: data => {
          this.front = data[0];
        }
      });
    });
    this.fetchDataInfo();
  }

  async fetchDataInfo() {
    this._http.get<any>(getHostUrl() + 'admin/user/info?userID=' + this.userID).subscribe(data => {
      this.age18Date = dayjs(data.birthdate).add(17, 'years').toDate();
    });
  }


  getFormattedDate(time: string) {
    let date = dayjs(time).format('DD.MM.YYYY');
    if (date !== '01.01.1970')
      return date;
    return '';
  }

  save() {

    this._http.post(getHostUrl() + 'admin/user/driving', { userID: this.userID, userObj: this.licence_new, control: true }).subscribe(resp => {
      this._spinner.hide();
      this._config.showSnackbar('Fahrerlaubnisdaten gespeichert!', 2000, 'success');
      this.parent.accept(this.element);
    }, error => {
      this._spinner.hide();
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
    });
  }

  ondateOfIssueChange($event: any) {
    this.licence_new.dateOfIssue = $event.value;
    this.expiryLicence = dayjs(this.licence_new.dateOfIssue).add(15, 'years').toDate();
  }

  ondateOfExpiryChange($event: any) {
    this.licence_new.dateOfExpiry = $event.value;
  }

  onissueDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.licence_new.dateOfIssue = new Date(dateString);
    this.expiryLicence = dayjs(this.licence_new.dateOfIssue).add(15, 'years').toDate();
  }

  onexpiryDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.licence_new.dateOfExpiry = new Date(dateString);
  }
}


@Component({
  selector: 'app-changes-classes',
  templateUrl: './driving/classes.html',
  styleUrls: ['./control.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class ClassesChangesControlComponent implements OnInit {

  userID: string;

  element: any;

  parent: ControlComponent;

  picture: boolean = false;

  classes: any;

  original: any;

  classes_old: any;

  img: string;

  carTypesList: any[] = [];

  unlimited: boolean = false;

  env = environment;

  constructor(private _http: HttpClient,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _login: Login,
    private _theme: ThemingService,
    private _config: ConfigModule,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.userID = this._data.userID;
    this.element = this._data.element;
    this.parent = this._data.parent;
    this.classes = this._data.new;
    this.original = this.classes;
    this.classes_old = this._data.old;
    if (this.classes.trailer === 1)
      this.classes.trailer = true;
    else
      this.classes.trailer = false;
    if (this.classes_old === null)
      this.picture = true;
    this._http.get<any>(getHostUrl() + 'admin/user/drivingassets?userID=' + this.userID).subscribe(data => {
      this._http.get<string>(`${getHostUrl()}licence?licence=${data.front}&licence=${data.FFFfront}`).subscribe({
        next: data => {
          if (this.classes.licenceClass !== 'Klasse FFF')
            this.img = data[0];
          else
            this.img = data[1];
        }
      });
    });
    if (this.classes.expiryDate.toString() === '1901-01-01' || this.classes.expiryDate.toString() === '1900-12-31')
      this.unlimited = true;
  }

  getFormattedDate(dateValue: string) {
    if (dateValue === null || dateValue.toString() === '0000-00-00' || dateValue === '' || dateValue.toString() === '1901-01-01' || dateValue.toString() === '1900-12-31' || dateValue.toString() === '1900-12-31T23:00:00.000Z')
      return ('unbegrenzt');
    var date = dayjs(dateValue).format('DD.MM.YYYY');
    if (date !== '01.01.1970')
      return date;
    return '';
  }

  setUnlimited(val: boolean) {
    this.unlimited = val;
    if (val)
      this.classes.expiryDate = new Date('01.01.1901');
    else
      this.classes.expiryDate = null;
  }

  onexpiryDateChange($event: any) {
    this.classes.expiryDate = $event.value;
  }

  onexpiryChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.classes.expiryDate = new Date(dateString);
  }

  changeTrailer() {
    if (this.classes.trailer === true) {
      this.classes.licenceClassID--;
      this.classes.trailerRemove = -1;
      this.classes.licenceClass = this.classes.licenceClass.slice(0, -1);
    } else {
      this.classes.licenceClassID++;
      this.classes.trailerRemove = 1;
      this.classes.licenceClass += 'E';
    }
  }

  getLicenceTrailerIcon(licenceClass: string): string {
    var path: string = '/assets/cars/';
    var theme = this._theme.theme.value;
    var trailer = '';
    if (this.classes.trailer === false)
      trailer = '_false';
    if (licenceClass === 'Klasse B' || licenceClass === 'Klasse BE') {
      if (theme === 'light-theme') {
        return (path + 'be_b' + trailer + '.png');
      } else {
        return (path + 'be_w' + trailer + '.png');
      }
    } else if (licenceClass === 'Klasse C1' || licenceClass === 'Klasse C1E') {
      if (theme === 'light-theme') {
        return (path + 'c1e_b' + trailer + '.png');
      } else {
        return (path + 'c1e_w' + trailer + '.png');
      }
    } else if (licenceClass === 'Klasse C' || licenceClass === 'Klasse CE') {
      if (theme === 'light-theme') {
        return (path + 'ce_b' + trailer + '.png');
      } else {
        return (path + 'ce_w' + trailer + '.png');
      }
    }
    return '';
  }

  save() {

    if (this.classes.trailer === true)
      this.classes.trailer = 1;
    else
      this.classes.trailer = 0;
    this._http.post(getHostUrl() + 'admin/user/drivingperms', { userID: this.userID, licence: this.classes, original: this.original, deleteing: false, control: true }).subscribe(resp => {
      this._spinner.hide();
      this._config.showSnackbar('Fahrerlaubnisdaten gespeichert!', 2000, 'success');
      this.parent.accept(this.element);
    }, error => {
      this._spinner.hide();
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
    });
  }
}