<div class="text-center">
    <br>
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
        <mat-icon>close</mat-icon>
    </a>
    <mat-nav-list class="mt-2">
        <mat-list-item *ngIf="_login.hasPermission('admin-user-driving-edit')" (click)="open(licence.id, licence.name)">
            <h3 matListItemLine>{{licence.name }}</h3>
            <div matListItemMeta>
                <img src="{{getIcon(licence.name)}}" style="height: 20px;"/>
            </div>
        </mat-list-item>
        <mat-list-item *ngFor="let entry of menuEntries" (click)="open(entry.id, entry.class)"
        [disabled]="!getLicenceValid(entry.id)">
            <h3 matListItemLine> {{entry.name }} </h3>
            <div matListItemMeta>
                <img src="{{getIcon(entry.class)}}" style="height: 20px;" />
            </div>
            <mat-error matListItemLine *ngIf="parent.existingLicence===false">allgemeine Führerscheindaten fehlen!</mat-error>
        </mat-list-item>
        <mat-list-item (click)="rfid()" [disabled]="!getLicenceValid(-1)">
            <h3 matListItemLine>RFID Tag registrieren</h3>
            <div matListItemMeta>
                <mat-icon>nfc</mat-icon>
            </div>
            <mat-error matListItemLine *ngIf="parent.existingLicence===false">allgemeine Führerscheindaten fehlen!</mat-error>
        </mat-list-item>
    </mat-nav-list>
</div>
