import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigModule, getHostUrl } from '../../../util/config';
import { Login } from '../../../util/login';

@Component({
  selector: 'app-notification-nav',
  templateUrl: './notification-nav.component.html',
  styleUrls: ['./notification-nav.component.css'],
})
export class NotificationNavComponent implements OnInit {

  currentUnRead: number = 0;
  isOffline = false;

  constructor(public _http: HttpClient, private _login: Login, private _config: ConfigModule) { }

  ngOnInit(): void {
    if (this._login.isLoggedIn())
      this.loadNotificationCount();

    // Poll every 10 seconds new notifications
    setInterval(() => {
      this.loadNotificationCount();
    }, 10 * 1000);
  }

  public loadNotificationCount() {
    this._http.get(`${getHostUrl()}healthz`, {
      responseType: 'text',
    }).subscribe((data: any) => {
      if (this.isOffline) {
        this._config.showSnackbar('Verbindung wiederhergestellt!', 3000, 'success');
        this.isOffline = false;
      }
    }, error => {
      if (error.status === 403) {
        return;
      }
      if (!this.isOffline) {
        this.isOffline = true;
        this._config.showSnackbar('Verbindung verloren!', 3000, 'error');
      }
    });
    this._login.isLoggedIn().subscribe(loggedIn => {
      if (loggedIn) {
        this._http.get(`${getHostUrl()}user/notifications/count`).subscribe((data: any) => {
          if (data.count) {
            if (data.count > this.currentUnRead) {
              this.playSound();
            }
            this.currentUnRead = data.count;
          } else {
            this.currentUnRead = 0;
          }
        });
      }
    });
  }

  playSound() {
    let audio = new Audio();
    audio.src = '../assets/sounds/notification.mp3';
    audio.volume = 0.1;
    audio.load();
    audio.play();
  }
}
