import { Component, OnInit, OnDestroy } from '@angular/core';
import { getHostUrl, ConfigModule } from 'src/app/util/config';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Login } from 'src/app/util/login';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdminUserMenusheetComponent } from '../admin-user-menusheet/admin-user-menusheet.component';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-user-groups',
  templateUrl: './admin-user-groups.component.html',
  styleUrls: ['./admin-user-groups.component.css'],
})
export class AdminUserGroupsComponent implements OnInit, OnDestroy {

  constructor(private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    public _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet) { }

  userInfo: any = {};

  firstname: string;
  lastname: string;
  userID: number;

  groupsList = [];
  public groupsFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public groupsFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    this.userID = this._route.snapshot.params.userId;
    if (this.userID) {
      this.fetchGroups(this.userID);
      this._config.setTitle('Verwaltung | Lädt...');
    }
    this.groupsFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterGroups();
      });
  }

  fetchGroups(userID: number) {
    this._http.get<any>(getHostUrl() + 'admin/user/groups?userID=' + userID).subscribe(data => {
      this.userInfo.groupListSelected = data.groupListSelected;
      this.groupsList = data.groupList;
      this.groupsFilterCtrl.updateValueAndValidity();
    });
    this._http.get<any>(getHostUrl() + 'admin/user/info?userID=' + userID).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this._config.setTitle(`${data.firstname + ' ' + data.lastname}`);
    });
  }

  protected filterGroups() {
    if (!this.groupsList) {
      return;
    }
    let search = this.groupsFilterCtrl.value;
    if (!search) {
      this.groupsFilter.next(this.groupsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.groupsFilter.next(
      this.groupsList.filter(s => s.name.toLocaleLowerCase().includes(search)),
    );
  }

  saveGroups() {
    const context = this;
    if (this.userID) {

      this._http.post(getHostUrl() + 'admin/user/groups', { userID: this.userID, userObj: this.userInfo.groupListSelected }).subscribe(resp => {
        context._spinner.hide();
        context._config.showSnackbar('Benutzergruppen gespeichert!', 2000, 'success');
        context.fetchGroups(context.userID);
      }, error => {
        context._spinner.hide();
        if (error.error === 5001)
          context._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
        else
          context._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
        context.fetchGroups(context.userID);
      });
    }
  }


  /**
 * Menu Bottom Sheet
 */
  openMenu() {
    const bottomSheetRef = this._bottomSheet.open(AdminUserMenusheetComponent, { data: { userID: this.userID, name: this.firstname + ' ' + this.lastname } });
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }
}
