<mat-dialog-header class="mb-3">
  <div class="clearfix">
    <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
      <mat-icon>close</mat-icon>
    </a>
    <h2 mat-dialog-title class="float-left">Adress Suche</h2>
  </div>
</mat-dialog-header>

<mat-dialog-content>

  <form [formGroup]="group" class="input-full-width">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Land</mat-label>
      <mat-select formControlName="country">
        <mat-option>
          <ngx-mat-select-search [formControl]="countryFilterCtrl"
            noEntriesFoundLabel="Kein Land verfügbar"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let country of countryFilter | async" [value]="country">{{ country.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>{{ cities ? 'Ort / Stadt' : 'Straße'}}</mat-label>
      <input matInput formControlName="search" id="address_search" [matAutocomplete]="autoStreet">
      <mat-autocomplete (optionSelected)="apply($event.option.value)" #autoStreet="matAutocomplete"
        autoActiveFirstOption="true">
        <mat-option *ngFor="let res of searchResults" [value]="res">
          <span *ngIf="!cities">{{ res.street }} - {{ res.city }}</span>
          <span *ngIf="cities">{{ res.city }} - {{ res.zipcode }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</mat-dialog-content>
