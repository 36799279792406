export class Icon {
  name: string;
  id: string;
}

export interface IconsPack {
  getIcons(): Icon[];
}

export function getIcons(): Icon[] {
  return [
    {
      name: '3D Rotation',
      id: '3d_rotation',
    },
    {
      name: 'AC Unit',
      id: 'ac_unit',
    },
    {
      name: 'Access Alarm',
      id: 'access_alarm',
    },
    {
      name: 'Access Alarms',
      id: 'access_alarms',
    },
    {
      name: 'Access Time',
      id: 'access_time',
    },
    {
      name: 'Accessibility',
      id: 'accessibility',
    },
    {
      name: 'Accessible',
      id: 'accessible',
    },
    {
      name: 'Account Balance',
      id: 'account_balance',
    },
    {
      name: 'Account Balance Wallet',
      id: 'account_balance_wallet',
    },
    {
      name: 'Account Box',
      id: 'account_box',
    },
    {
      name: 'Account Circle',
      id: 'account_circle',
    },
    {
      name: 'ADB',
      id: 'adb',
    },
    {
      name: 'Add',
      id: 'add',
    },
    {
      name: 'Add a Photo',
      id: 'add_a_photo',
    },
    {
      name: 'Add Alarm',
      id: 'add_alarm',
    },
    {
      name: 'Add Alert',
      id: 'add_alert',
    },
    {
      name: 'Add Box',
      id: 'add_box',
    },
    {
      name: 'Add Circle',
      id: 'add_circle',
    },
    {
      name: 'Add Circle Outline',
      id: 'add_circle_outline',
    },
    {
      name: 'Add Location',
      id: 'add_location',
    },
    {
      name: 'Add Shopping Cart',
      id: 'add_shopping_cart',
    },
    {
      name: 'Add to Photos',
      id: 'add_to_photos',
    },
    {
      name: 'Add to Queue',
      id: 'add_to_queue',
    },
    {
      name: 'Adjust',
      id: 'adjust',
    },
    {
      name: 'Airline Seat Flat',
      id: 'airline_seat_flat',
    },
    {
      name: 'Airline Seat Flat Angled',
      id: 'airline_seat_flat_angled',
    },
    {
      name: 'Airline Seat Individual Suite',
      id: 'airline_seat_individual_suite',
    },
    {
      name: 'Airline Seat Legroom Extra',
      id: 'airline_seat_legroom_extra',
    },
    {
      name: 'Airline Seat Legroom Normal',
      id: 'airline_seat_legroom_normal',
    },
    {
      name: 'Airline Seat Legroom Reduced',
      id: 'airline_seat_legroom_reduced',
    },
    {
      name: 'Airline Seat Recline Extra',
      id: 'airline_seat_recline_extra',
    },
    {
      name: 'Airline Seat Recline Normal',
      id: 'airline_seat_recline_normal',
    },
    {
      name: 'Airplanemode Active',
      id: 'airplanemode_active',
    },
    {
      name: 'Airplanemode Inactive',
      id: 'airplanemode_inactive',
    },
    {
      name: 'Airplay',
      id: 'airplay',
    },
    {
      name: 'Airport Shuttle',
      id: 'airport_shuttle',
    },
    {
      name: 'Alarm',
      id: 'alarm',
    },
    {
      name: 'Alarm Add',
      id: 'alarm_add',
    },
    {
      name: 'Alarm Off',
      id: 'alarm_off',
    },
    {
      name: 'Alarm On',
      id: 'alarm_on',
    },
    {
      name: 'Album',
      id: 'album',
    },
    {
      name: 'All Inclusive',
      id: 'all_inclusive',
    },
    {
      name: 'All Out',
      id: 'all_out',
    },
    {
      name: 'Android',
      id: 'android',
    },
    {
      name: 'Announcement',
      id: 'announcement',
    },
    {
      name: 'Apps',
      id: 'apps',
    },
    {
      name: 'Archive',
      id: 'archive',
    },
    {
      name: 'Arrow Back',
      id: 'arrow_back',
    },
    {
      name: 'Arrow Downward',
      id: 'arrow_downward',
    },
    {
      name: 'Arrow Drop Down',
      id: 'arrow_drop_down',
    },
    {
      name: 'Arrow Drop Down Circle',
      id: 'arrow_drop_down_circle',
    },
    {
      name: 'Arrow Drop Up',
      id: 'arrow_drop_up',
    },
    {
      name: 'Arrow Forward',
      id: 'arrow_forward',
    },
    {
      name: 'Arrow Upward',
      id: 'arrow_upward',
    },
    {
      name: 'Art Track',
      id: 'art_track',
    },
    {
      name: 'Aspect Ratio',
      id: 'aspect_ratio',
    },
    {
      name: 'Assessment',
      id: 'assessment',
    },
    {
      name: 'Assignment',
      id: 'assignment',
    },
    {
      name: 'Assignment Ind',
      id: 'assignment_ind',
    },
    {
      name: 'Assignment Late',
      id: 'assignment_late',
    },
    {
      name: 'Assignment Return',
      id: 'assignment_return',
    },
    {
      name: 'Assignment Returned',
      id: 'assignment_returned',
    },
    {
      name: 'Assignment Turned In',
      id: 'assignment_turned_in',
    },
    {
      name: 'Assistant',
      id: 'assistant',
    },
    {
      name: 'Assistant Photo',
      id: 'assistant_photo',
    },
    {
      name: 'Attach File',
      id: 'attach_file',
    },
    {
      name: 'Attach Money',
      id: 'attach_money',
    },
    {
      name: 'Attachment',
      id: 'attachment',
    },
    {
      name: 'Audiotrack',
      id: 'audiotrack',
    },
    {
      name: 'Autorenew',
      id: 'autorenew',
    },
    {
      name: 'AV Timer',
      id: 'av_timer',
    },
    {
      name: 'Backspace',
      id: 'backspace',
    },
    {
      name: 'Backup',
      id: 'backup',
    },
    {
      name: 'Battery Alert',
      id: 'battery_alert',
    },
    {
      name: 'Battery Charging Full',
      id: 'battery_charging_full',
    },
    {
      name: 'Battery Full',
      id: 'battery_full',
    },
    {
      name: 'Battery Standard',
      id: 'battery_std',
    },
    {
      name: 'Battery Unknown',
      id: 'battery_unknown',
    },
    {
      name: 'Beach Access',
      id: 'beach_access',
    },
    {
      name: 'Been Here',
      id: 'beenhere',
    },
    {
      name: 'Block',
      id: 'block',
    },
    {
      name: 'Bluetooth',
      id: 'bluetooth',
    },
    {
      name: 'Bluetooth Audio',
      id: 'bluetooth_audio',
    },
    {
      name: 'Bluetooth Connected',
      id: 'bluetooth_connected',
    },
    {
      name: 'Bluetooth Disabled',
      id: 'bluetooth_disabled',
    },
    {
      name: 'Bluetooth Searching',
      id: 'bluetooth_searching',
    },
    {
      name: 'Blur Circular',
      id: 'blur_circular',
    },
    {
      name: 'Blur Linear',
      id: 'blur_linear',
    },
    {
      name: 'Blur Off',
      id: 'blur_off',
    },
    {
      name: 'Blur On',
      id: 'blur_on',
    },
    {
      name: 'Book',
      id: 'book',
    },
    {
      name: 'Bookmark',
      id: 'bookmark',
    },
    {
      name: 'Bookmark Border',
      id: 'bookmark_border',
    },
    {
      name: 'Border All',
      id: 'border_all',
    },
    {
      name: 'Border Bottom',
      id: 'border_bottom',
    },
    {
      name: 'Border Clear',
      id: 'border_clear',
    },
    {
      name: 'Border Color',
      id: 'border_color',
    },
    {
      name: 'Border Horizontal',
      id: 'border_horizontal',
    },
    {
      name: 'Border Inner',
      id: 'border_inner',
    },
    {
      name: 'Border Left',
      id: 'border_left',
    },
    {
      name: 'Border Outer',
      id: 'border_outer',
    },
    {
      name: 'Border Right',
      id: 'border_right',
    },
    {
      name: 'Border Style',
      id: 'border_style',
    },
    {
      name: 'Border Top',
      id: 'border_top',
    },
    {
      name: 'Border Vertical',
      id: 'border_vertical',
    },
    {
      name: 'Branding Watermark',
      id: 'branding_watermark',
    },
    {
      name: 'Brightness 1',
      id: 'brightness_1',
    },
    {
      name: 'Brightness 2',
      id: 'brightness_2',
    },
    {
      name: 'Brightness 3',
      id: 'brightness_3',
    },
    {
      name: 'Brightness 4',
      id: 'brightness_4',
    },
    {
      name: 'Brightness 5',
      id: 'brightness_5',
    },
    {
      name: 'Brightness 6',
      id: 'brightness_6',
    },
    {
      name: 'Brightness 7',
      id: 'brightness_7',
    },
    {
      name: 'Brightness Auto',
      id: 'brightness_auto',
    },
    {
      name: 'Brightness High',
      id: 'brightness_high',
    },
    {
      name: 'Brightness Low',
      id: 'brightness_low',
    },
    {
      name: 'Brightness Medium',
      id: 'brightness_medium',
    },
    {
      name: 'Broken Image',
      id: 'broken_image',
    },
    {
      name: 'Brush',
      id: 'brush',
    },
    {
      name: 'Bubble Chart',
      id: 'bubble_chart',
    },
    {
      name: 'Bug Report',
      id: 'bug_report',
    },
    {
      name: 'Build',
      id: 'build',
    },
    {
      name: 'Burst Mode',
      id: 'burst_mode',
    },
    {
      name: 'Business',
      id: 'business',
    },
    {
      name: 'Business Center',
      id: 'business_center',
    },
    {
      name: 'Cached',
      id: 'cached',
    },
    {
      name: 'Cake',
      id: 'cake',
    },
    {
      name: 'Call',
      id: 'call',
    },
    {
      name: 'Call End',
      id: 'call_end',
    },
    {
      name: 'Call Made',
      id: 'call_made',
    },
    {
      name: 'Call Merge',
      id: 'call_merge',
    },
    {
      name: 'Call Missed',
      id: 'call_missed',
    },
    {
      name: 'Call Missed Outgoing',
      id: 'call_missed_outgoing',
    },
    {
      name: 'Call Received',
      id: 'call_received',
    },
    {
      name: 'Call Split',
      id: 'call_split',
    },
    {
      name: 'Call to Action',
      id: 'call_to_action',
    },
    {
      name: 'Camera',
      id: 'camera',
    },
    {
      name: 'Camera Alt',
      id: 'camera_alt',
    },
    {
      name: 'Camera Enhance',
      id: 'camera_enhance',
    },
    {
      name: 'Camera Front',
      id: 'camera_front',
    },
    {
      name: 'Camera Rear',
      id: 'camera_rear',
    },
    {
      name: 'Camera Roll',
      id: 'camera_roll',
    },
    {
      name: 'Cancel',
      id: 'cancel',
    },
    {
      name: 'Card Giftcard',
      id: 'card_giftcard',
    },
    {
      name: 'Card Membership',
      id: 'card_membership',
    },
    {
      name: 'Card Travel',
      id: 'card_travel',
    },
    {
      name: 'Casino',
      id: 'casino',
    },
    {
      name: 'Cast',
      id: 'cast',
    },
    {
      name: 'Cast Connected',
      id: 'cast_connected',
    },
    {
      name: 'Center Focus Strong',
      id: 'center_focus_strong',
    },
    {
      name: 'Center Focus Weak',
      id: 'center_focus_weak',
    },
    {
      name: 'Change History',
      id: 'change_history',
    },
    {
      name: 'Chat',
      id: 'chat',
    },
    {
      name: 'Chat Bubble',
      id: 'chat_bubble',
    },
    {
      name: 'Chat Bubble Outline',
      id: 'chat_bubble_outline',
    },
    {
      name: 'Check',
      id: 'check',
    },
    {
      name: 'Check Box',
      id: 'check_box',
    },
    {
      name: 'Check Box Outline Blank',
      id: 'check_box_outline_blank',
    },
    {
      name: 'Check Circle',
      id: 'check_circle',
    },
    {
      name: 'Chevron Left',
      id: 'chevron_left',
    },
    {
      name: 'Chevron Right',
      id: 'chevron_right',
    },
    {
      name: 'Child Care',
      id: 'child_care',
    },
    {
      name: 'Child Friendly',
      id: 'child_friendly',
    },
    {
      name: 'Chrome Reader Mode',
      id: 'chrome_reader_mode',
    },
    {
      name: 'Class',
      id: 'class',
    },
    {
      name: 'Clear',
      id: 'clear',
    },
    {
      name: 'Clear All',
      id: 'clear_all',
    },
    {
      name: 'Close',
      id: 'close',
    },
    {
      name: 'Closed Caption',
      id: 'closed_caption',
    },
    {
      name: 'Cloud',
      id: 'cloud',
    },
    {
      name: 'Cloud Circle',
      id: 'cloud_circle',
    },
    {
      name: 'Cloud Done',
      id: 'cloud_done',
    },
    {
      name: 'Cloud Download',
      id: 'cloud_download',
    },
    {
      name: 'Cloud Off',
      id: 'cloud_off',
    },
    {
      name: 'Cloud Queue',
      id: 'cloud_queue',
    },
    {
      name: 'Cloud Upload',
      id: 'cloud_upload',
    },
    {
      name: 'Code',
      id: 'code',
    },
    {
      name: 'Collections',
      id: 'collections',
    },
    {
      name: 'Collections Bookmark',
      id: 'collections_bookmark',
    },
    {
      name: 'Color Lens',
      id: 'color_lens',
    },
    {
      name: 'Colorize',
      id: 'colorize',
    },
    {
      name: 'Comment',
      id: 'comment',
    },
    {
      name: 'Compare',
      id: 'compare',
    },
    {
      name: 'Compare Arrows',
      id: 'compare_arrows',
    },
    {
      name: 'Computer',
      id: 'computer',
    },
    {
      name: 'Confirmation Number',
      id: 'confirmation_number',
    },
    {
      name: 'Contact Mail',
      id: 'contact_mail',
    },
    {
      name: 'Contact Phone',
      id: 'contact_phone',
    },
    {
      name: 'Contacts',
      id: 'contacts',
    },
    {
      name: 'Content Copy',
      id: 'content_copy',
    },
    {
      name: 'Content Cut',
      id: 'content_cut',
    },
    {
      name: 'Content Paste',
      id: 'content_paste',
    },
    {
      name: 'Control Point',
      id: 'control_point',
    },
    {
      name: 'Control Point Duplicate',
      id: 'control_point_duplicate',
    },
    {
      name: 'Copyright',
      id: 'copyright',
    },
    {
      name: 'Create',
      id: 'create',
    },
    {
      name: 'Create New Folder',
      id: 'create_new_folder',
    },
    {
      name: 'Credit Card',
      id: 'credit_card',
    },
    {
      name: 'Crop',
      id: 'crop',
    },
    {
      name: 'Crop 16:9',
      id: 'crop_16_9',
    },
    {
      name: 'Crop 3:2',
      id: 'crop_3_2',
    },
    {
      name: 'Crop 5:4',
      id: 'crop_5_4',
    },
    {
      name: 'Crop 7:5',
      id: 'crop_7_5',
    },
    {
      name: 'Crop DIN',
      id: 'crop_din',
    },
    {
      name: 'Crop Free',
      id: 'crop_free',
    },
    {
      name: 'Crop Landscape',
      id: 'crop_landscape',
    },
    {
      name: 'Crop Original',
      id: 'crop_original',
    },
    {
      name: 'Crop Portrait',
      id: 'crop_portrait',
    },
    {
      name: 'Crop Rotate',
      id: 'crop_rotate',
    },
    {
      name: 'Crop Square',
      id: 'crop_square',
    },
    {
      name: 'Dashboard',
      id: 'dashboard',
    },
    {
      name: 'Data Usage',
      id: 'data_usage',
    },
    {
      name: 'Date Range',
      id: 'date_range',
    },
    {
      name: 'Dehaze',
      id: 'dehaze',
    },
    {
      name: 'Delete',
      id: 'delete',
    },
    {
      name: 'Delete Forever',
      id: 'delete_forever',
    },
    {
      name: 'Delete Sweep',
      id: 'delete_sweep',
    },
    {
      name: 'Description',
      id: 'description',
    },
    {
      name: 'Desktop Mac',
      id: 'desktop_mac',
    },
    {
      name: 'Desktop Windows',
      id: 'desktop_windows',
    },
    {
      name: 'Details',
      id: 'details',
    },
    {
      name: 'Developer Board',
      id: 'developer_board',
    },
    {
      name: 'Developer Mode',
      id: 'developer_mode',
    },
    {
      name: 'Device Hub',
      id: 'device_hub',
    },
    {
      name: 'Devices',
      id: 'devices',
    },
    {
      name: 'Devices Other',
      id: 'devices_other',
    },
    {
      name: 'Dialer SIP',
      id: 'dialer_sip',
    },
    {
      name: 'Dialpad',
      id: 'dialpad',
    },
    {
      name: 'Directions',
      id: 'directions',
    },
    {
      name: 'Directions Bike',
      id: 'directions_bike',
    },
    {
      name: 'Directions Boat',
      id: 'directions_boat',
    },
    {
      name: 'Directions Bus',
      id: 'directions_bus',
    },
    {
      name: 'Directions Car',
      id: 'directions_car',
    },
    {
      name: 'Directions Railway',
      id: 'directions_railway',
    },
    {
      name: 'Directions Run',
      id: 'directions_run',
    },
    {
      name: 'Directions Subway',
      id: 'directions_subway',
    },
    {
      name: 'Directions Transit',
      id: 'directions_transit',
    },
    {
      name: 'Directions Walk',
      id: 'directions_walk',
    },
    {
      name: 'Disc Full',
      id: 'disc_full',
    },
    {
      name: 'DNS',
      id: 'dns',
    },
    {
      name: 'Do Not Disturb',
      id: 'do_not_disturb',
    },
    {
      name: 'Do Not Disturb Alt',
      id: 'do_not_disturb_alt',
    },
    {
      name: 'Do Not Disturb Off',
      id: 'do_not_disturb_off',
    },
    {
      name: 'Do Not Disturb On',
      id: 'do_not_disturb_on',
    },
    {
      name: 'Dock',
      id: 'dock',
    },
    {
      name: 'Domain',
      id: 'domain',
    },
    {
      name: 'Done',
      id: 'done',
    },
    {
      name: 'Done All',
      id: 'done_all',
    },
    {
      name: 'Donut Large',
      id: 'donut_large',
    },
    {
      name: 'Donut Small',
      id: 'donut_small',
    },
    {
      name: 'Drafts',
      id: 'drafts',
    },
    {
      name: 'Drag Handle',
      id: 'drag_handle',
    },
    {
      name: 'Drive ETA',
      id: 'drive_eta',
    },
    {
      name: 'DVR',
      id: 'dvr',
    },
    {
      name: 'Edit',
      id: 'edit',
    },
    {
      name: 'Edit Location',
      id: 'edit_location',
    },
    {
      name: 'Eject',
      id: 'eject',
    },
    {
      name: 'Email',
      id: 'email',
    },
    {
      name: 'Enhanced Encryption',
      id: 'enhanced_encryption',
    },
    {
      name: 'Equalizer',
      id: 'equalizer',
    },
    {
      name: 'Error',
      id: 'error',
    },
    {
      name: 'Error Outline',
      id: 'error_outline',
    },
    {
      name: 'Euro Symbol',
      id: 'euro_symbol',
    },
    {
      name: 'EV Station',
      id: 'ev_station',
    },
    {
      name: 'Event',
      id: 'event',
    },
    {
      name: 'Event Available',
      id: 'event_available',
    },
    {
      name: 'Event Busy',
      id: 'event_busy',
    },
    {
      name: 'Event Note',
      id: 'event_note',
    },
    {
      name: 'Event Seat',
      id: 'event_seat',
    },
    {
      name: 'Exit to App',
      id: 'exit_to_app',
    },
    {
      name: 'Expand Less',
      id: 'expand_less',
    },
    {
      name: 'Expand More',
      id: 'expand_more',
    },
    {
      name: 'Explicit',
      id: 'explicit',
    },
    {
      name: 'Explore',
      id: 'explore',
    },
    {
      name: 'Exposure',
      id: 'exposure',
    },
    {
      name: 'Exposure Negative 1',
      id: 'exposure_neg_1',
    },
    {
      name: 'Exposure Negative 2',
      id: 'exposure_neg_2',
    },
    {
      name: 'Exposure Plus 1',
      id: 'exposure_plus_1',
    },
    {
      name: 'Exposure Plus 2',
      id: 'exposure_plus_2',
    },
    {
      name: 'Exposure Zero',
      id: 'exposure_zero',
    },
    {
      name: 'Extension',
      id: 'extension',
    },
    {
      name: 'Face',
      id: 'face',
    },
    {
      name: 'Fast Forward',
      id: 'fast_forward',
    },
    {
      name: 'Fast Rewind',
      id: 'fast_rewind',
    },
    {
      name: 'Favorite',
      id: 'favorite',
    },
    {
      name: 'Favorite Border',
      id: 'favorite_border',
    },
    {
      name: 'Featured Play List',
      id: 'featured_play_list',
    },
    {
      name: 'Featured Video',
      id: 'featured_video',
    },
    {
      name: 'Feedback',
      id: 'feedback',
    },
    {
      name: 'Fiber DVR',
      id: 'fiber_dvr',
    },
    {
      name: 'Fiber Manual Record',
      id: 'fiber_manual_record',
    },
    {
      name: 'Fiber New',
      id: 'fiber_new',
    },
    {
      name: 'Fiber Pin',
      id: 'fiber_pin',
    },
    {
      name: 'Fiber Smart Record',
      id: 'fiber_smart_record',
    },
    {
      name: 'File Download',
      id: 'file_download',
    },
    {
      name: 'File Upload',
      id: 'file_upload',
    },
    {
      name: 'Filter',
      id: 'filter',
    },
    {
      name: 'Filter 1',
      id: 'filter_1',
    },
    {
      name: 'Filter 2',
      id: 'filter_2',
    },
    {
      name: 'Filter 3',
      id: 'filter_3',
    },
    {
      name: 'Filter 4',
      id: 'filter_4',
    },
    {
      name: 'Filter 5',
      id: 'filter_5',
    },
    {
      name: 'Filter 6',
      id: 'filter_6',
    },
    {
      name: 'Filter 7',
      id: 'filter_7',
    },
    {
      name: 'Filter 8',
      id: 'filter_8',
    },
    {
      name: 'Filter 9',
      id: 'filter_9',
    },
    {
      name: 'Filter 9 Plus',
      id: 'filter_9_plus',
    },
    {
      name: 'Filter B And W',
      id: 'filter_b_and_w',
    },
    {
      name: 'Filter Center Focus',
      id: 'filter_center_focus',
    },
    {
      name: 'Filter Drama',
      id: 'filter_drama',
    },
    {
      name: 'Filter Frames',
      id: 'filter_frames',
    },
    {
      name: 'Filter Hdr',
      id: 'filter_hdr',
    },
    {
      name: 'Filter List',
      id: 'filter_list',
    },
    {
      name: 'Filter None',
      id: 'filter_none',
    },
    {
      name: 'Filter Tilt Shift',
      id: 'filter_tilt_shift',
    },
    {
      name: 'Filter Vintage',
      id: 'filter_vintage',
    },
    {
      name: 'Find In Page',
      id: 'find_in_page',
    },
    {
      name: 'Find Replace',
      id: 'find_replace',
    },
    {
      name: 'Fingerprint',
      id: 'fingerprint',
    },
    {
      name: 'First Page',
      id: 'first_page',
    },
    {
      name: 'Fitness Center',
      id: 'fitness_center',
    },
    {
      name: 'Flag',
      id: 'flag',
    },
    {
      name: 'Flare',
      id: 'flare',
    },
    {
      name: 'Flash Auto',
      id: 'flash_auto',
    },
    {
      name: 'Flash Off',
      id: 'flash_off',
    },
    {
      name: 'Flash On',
      id: 'flash_on',
    },
    {
      name: 'Flight',
      id: 'flight',
    },
    {
      name: 'Flight Land',
      id: 'flight_land',
    },
    {
      name: 'Flight Takeoff',
      id: 'flight_takeoff',
    },
    {
      name: 'Flip',
      id: 'flip',
    },
    {
      name: 'Flip To Back',
      id: 'flip_to_back',
    },
    {
      name: 'Flip To Front',
      id: 'flip_to_front',
    },
    {
      name: 'Folder',
      id: 'folder',
    },
    {
      name: 'Folder Open',
      id: 'folder_open',
    },
    {
      name: 'Folder Shared',
      id: 'folder_shared',
    },
    {
      name: 'Folder Special',
      id: 'folder_special',
    },
    {
      name: 'Font Download',
      id: 'font_download',
    },
    {
      name: 'Format Align Center',
      id: 'format_align_center',
    },
    {
      name: 'Format Align Justify',
      id: 'format_align_justify',
    },
    {
      name: 'Format Align Left',
      id: 'format_align_left',
    },
    {
      name: 'Format Align Right',
      id: 'format_align_right',
    },
    {
      name: 'Format Bold',
      id: 'format_bold',
    },
    {
      name: 'Format Clear',
      id: 'format_clear',
    },
    {
      name: 'Format Color Fill',
      id: 'format_color_fill',
    },
    {
      name: 'Format Color Reset',
      id: 'format_color_reset',
    },
    {
      name: 'Format Color Text',
      id: 'format_color_text',
    },
    {
      name: 'Format Indent Decrease',
      id: 'format_indent_decrease',
    },
    {
      name: 'Format Indent Increase',
      id: 'format_indent_increase',
    },
    {
      name: 'Format Italic',
      id: 'format_italic',
    },
    {
      name: 'Format Line Spacing',
      id: 'format_line_spacing',
    },
    {
      name: 'Format List Bulleted',
      id: 'format_list_bulleted',
    },
    {
      name: 'Format List Numbered',
      id: 'format_list_numbered',
    },
    {
      name: 'Format Paint',
      id: 'format_paint',
    },
    {
      name: 'Format Quote',
      id: 'format_quote',
    },
    {
      name: 'Format Shapes',
      id: 'format_shapes',
    },
    {
      name: 'Format Size',
      id: 'format_size',
    },
    {
      name: 'Format Strikethrough',
      id: 'format_strikethrough',
    },
    {
      name: 'Format Textdirection Left to Right',
      id: 'format_textdirection_l_to_r',
    },
    {
      name: 'Format Textdirection Right to Left',
      id: 'format_textdirection_r_to_l',
    },
    {
      name: 'Format Underlined',
      id: 'format_underlined',
    },
    {
      name: 'Forum',
      id: 'forum',
    },
    {
      name: 'Forward',
      id: 'forward',
    },
    {
      name: 'Forward 10',
      id: 'forward_10',
    },
    {
      name: 'Forward 30',
      id: 'forward_30',
    },
    {
      name: 'Forward 5',
      id: 'forward_5',
    },
    {
      name: 'Free Breakfast',
      id: 'free_breakfast',
    },
    {
      name: 'Fullscreen',
      id: 'fullscreen',
    },
    {
      name: 'Fullscreen Exit',
      id: 'fullscreen_exit',
    },
    {
      name: 'Functions',
      id: 'functions',
    },
    {
      name: 'G Translate',
      id: 'g_translate',
    },
    {
      name: 'Gamepad',
      id: 'gamepad',
    },
    {
      name: 'Games',
      id: 'games',
    },
    {
      name: 'Gavel',
      id: 'gavel',
    },
    {
      name: 'Gesture',
      id: 'gesture',
    },
    {
      name: 'Get App',
      id: 'get_app',
    },
    {
      name: 'GIF',
      id: 'gif',
    },
    {
      name: 'Golf Course',
      id: 'golf_course',
    },
    {
      name: 'GPS Fixed',
      id: 'gps_fixed',
    },
    {
      name: 'GPS Not Fixed',
      id: 'gps_not_fixed',
    },
    {
      name: 'GPS Off',
      id: 'gps_off',
    },
    {
      name: 'Grade',
      id: 'grade',
    },
    {
      name: 'Gradient',
      id: 'gradient',
    },
    {
      name: 'Grain',
      id: 'grain',
    },
    {
      name: 'Graphic Equalizer',
      id: 'graphic_eq',
    },
    {
      name: 'Grid Off',
      id: 'grid_off',
    },
    {
      name: 'Grid On',
      id: 'grid_on',
    },
    {
      name: 'Group',
      id: 'group',
    },
    {
      name: 'Group Add',
      id: 'group_add',
    },
    {
      name: 'Group Work',
      id: 'group_work',
    },
    {
      name: 'HD',
      id: 'hd',
    },
    {
      name: 'HDR Off',
      id: 'hdr_off',
    },
    {
      name: 'HDR On',
      id: 'hdr_on',
    },
    {
      name: 'HDR Strong',
      id: 'hdr_strong',
    },
    {
      name: 'HDR Weak',
      id: 'hdr_weak',
    },
    {
      name: 'Headset',
      id: 'headset',
    },
    {
      name: 'Headset Microphone',
      id: 'headset_mic',
    },
    {
      name: 'Healing',
      id: 'healing',
    },
    {
      name: 'Hearing',
      id: 'hearing',
    },
    {
      name: 'Help',
      id: 'help',
    },
    {
      name: 'Help Outline',
      id: 'help_outline',
    },
    {
      name: 'High Quality',
      id: 'high_quality',
    },
    {
      name: 'Highlight',
      id: 'highlight',
    },
    {
      name: 'Highlight Off',
      id: 'highlight_off',
    },
    {
      name: 'History',
      id: 'history',
    },
    {
      name: 'Home',
      id: 'home',
    },
    {
      name: 'Hot Tub',
      id: 'hot_tub',
    },
    {
      name: 'Hotel',
      id: 'hotel',
    },
    {
      name: 'Hourglass Empty',
      id: 'hourglass_empty',
    },
    {
      name: 'Hourglass Full',
      id: 'hourglass_full',
    },
    {
      name: 'HTTP',
      id: 'http',
    },
    {
      name: 'HTTPS',
      id: 'https',
    },
    {
      name: 'Image',
      id: 'image',
    },
    {
      name: 'Image Aspect Ratio',
      id: 'image_aspect_ratio',
    },
    {
      name: 'Import Contacts',
      id: 'import_contacts',
    },
    {
      name: 'Import Export',
      id: 'import_export',
    },
    {
      name: 'Important Devices',
      id: 'important_devices',
    },
    {
      name: 'Inbox',
      id: 'inbox',
    },
    {
      name: 'Indeterminate Check Box',
      id: 'indeterminate_check_box',
    },
    {
      name: 'Info',
      id: 'info',
    },
    {
      name: 'Info Outline',
      id: 'info_outline',
    },
    {
      name: 'Input',
      id: 'input',
    },
    {
      name: 'Insert Chart',
      id: 'insert_chart',
    },
    {
      name: 'Insert Comment',
      id: 'insert_comment',
    },
    {
      name: 'Insert Drive File',
      id: 'insert_drive_file',
    },
    {
      name: 'Insert Emoticon',
      id: 'insert_emoticon',
    },
    {
      name: 'Insert Invitation',
      id: 'insert_invitation',
    },
    {
      name: 'Insert Link',
      id: 'insert_link',
    },
    {
      name: 'Insert Photo',
      id: 'insert_photo',
    },
    {
      name: 'Invert Colors',
      id: 'invert_colors',
    },
    {
      name: 'Invert Colors Off',
      id: 'invert_colors_off',
    },
    {
      name: 'ISO',
      id: 'iso',
    },
    {
      name: 'Keyboard',
      id: 'keyboard',
    },
    {
      name: 'Keyboard Arrow Down',
      id: 'keyboard_arrow_down',
    },
    {
      name: 'Keyboard Arrow Left',
      id: 'keyboard_arrow_left',
    },
    {
      name: 'Keyboard Arrow Right',
      id: 'keyboard_arrow_right',
    },
    {
      name: 'Keyboard Arrow Up',
      id: 'keyboard_arrow_up',
    },
    {
      name: 'Keyboard Backspace',
      id: 'keyboard_backspace',
    },
    {
      name: 'Keyboard Capslock',
      id: 'keyboard_capslock',
    },
    {
      name: 'Keyboard Hide',
      id: 'keyboard_hide',
    },
    {
      name: 'Keyboard Return',
      id: 'keyboard_return',
    },
    {
      name: 'Keyboard Tab',
      id: 'keyboard_tab',
    },
    {
      name: 'Keyboard Voice',
      id: 'keyboard_voice',
    },
    {
      name: 'Kitchen',
      id: 'kitchen',
    },
    {
      name: 'Label',
      id: 'label',
    },
    {
      name: 'Label Outline',
      id: 'label_outline',
    },
    {
      name: 'Landscape',
      id: 'landscape',
    },
    {
      name: 'Language',
      id: 'language',
    },
    {
      name: 'Laptop',
      id: 'laptop',
    },
    {
      name: 'Laptop Chromebook',
      id: 'laptop_chromebook',
    },
    {
      name: 'Laptop Mac',
      id: 'laptop_mac',
    },
    {
      name: 'Laptop Windows',
      id: 'laptop_windows',
    },
    {
      name: 'Last Page',
      id: 'last_page',
    },
    {
      name: 'Launch',
      id: 'launch',
    },
    {
      name: 'Layers',
      id: 'layers',
    },
    {
      name: 'Layers Clear',
      id: 'layers_clear',
    },
    {
      name: 'Leak Add',
      id: 'leak_add',
    },
    {
      name: 'Leak Remove',
      id: 'leak_remove',
    },
    {
      name: 'Lens',
      id: 'lens',
    },
    {
      name: 'Library Add',
      id: 'library_add',
    },
    {
      name: 'Library Books',
      id: 'library_books',
    },
    {
      name: 'Library Music',
      id: 'library_music',
    },
    {
      name: 'Lightbulb Outline',
      id: 'lightbulb_outline',
    },
    {
      name: 'Line Style',
      id: 'line_style',
    },
    {
      name: 'Line Weight',
      id: 'line_weight',
    },
    {
      name: 'Linear Scale',
      id: 'linear_scale',
    },
    {
      name: 'Link',
      id: 'link',
    },
    {
      name: 'Linked Camera',
      id: 'linked_camera',
    },
    {
      name: 'List',
      id: 'list',
    },
    {
      name: 'Live Help',
      id: 'live_help',
    },
    {
      name: 'Live TV',
      id: 'live_tv',
    },
    {
      name: 'Local Activity',
      id: 'local_activity',
    },
    {
      name: 'Local Airport',
      id: 'local_airport',
    },
    {
      name: 'Local ATM',
      id: 'local_atm',
    },
    {
      name: 'Local Bar',
      id: 'local_bar',
    },
    {
      name: 'Local Cafe',
      id: 'local_cafe',
    },
    {
      name: 'Local Car Wash',
      id: 'local_car_wash',
    },
    {
      name: 'Local Convenience Store',
      id: 'local_convenience_store',
    },
    {
      name: 'Local Dining',
      id: 'local_dining',
    },
    {
      name: 'Local Drink',
      id: 'local_drink',
    },
    {
      name: 'Local Florist',
      id: 'local_florist',
    },
    {
      name: 'Local Gas Station',
      id: 'local_gas_station',
    },
    {
      name: 'Local Grocery Store',
      id: 'local_grocery_store',
    },
    {
      name: 'Local Hospital',
      id: 'local_hospital',
    },
    {
      name: 'Local Hotel',
      id: 'local_hotel',
    },
    {
      name: 'Local Laundry Service',
      id: 'local_laundry_service',
    },
    {
      name: 'Local Library',
      id: 'local_library',
    },
    {
      name: 'Local Mall',
      id: 'local_mall',
    },
    {
      name: 'Local Movies',
      id: 'local_movies',
    },
    {
      name: 'Local Offer',
      id: 'local_offer',
    },
    {
      name: 'Local Parking',
      id: 'local_parking',
    },
    {
      name: 'Local Pharmacy',
      id: 'local_pharmacy',
    },
    {
      name: 'Local Phone',
      id: 'local_phone',
    },
    {
      name: 'Local Pizza',
      id: 'local_pizza',
    },
    {
      name: 'Local Play',
      id: 'local_play',
    },
    {
      name: 'Local Post Office',
      id: 'local_post_office',
    },
    {
      name: 'Local Printshop',
      id: 'local_printshop',
    },
    {
      name: 'Local See',
      id: 'local_see',
    },
    {
      name: 'Local Shipping',
      id: 'local_shipping',
    },
    {
      name: 'Local Taxi',
      id: 'local_taxi',
    },
    {
      name: 'Location City',
      id: 'location_city',
    },
    {
      name: 'Location Disabled',
      id: 'location_disabled',
    },
    {
      name: 'Location Off',
      id: 'location_off',
    },
    {
      name: 'Location On',
      id: 'location_on',
    },
    {
      name: 'Location Searching',
      id: 'location_searching',
    },
    {
      name: 'Lock',
      id: 'lock',
    },
    {
      name: 'Lock Open',
      id: 'lock_open',
    },
    {
      name: 'Lock Outline',
      id: 'lock_outline',
    },
    {
      name: 'Looks',
      id: 'looks',
    },
    {
      name: 'Looks 3',
      id: 'looks_3',
    },
    {
      name: 'Looks 4',
      id: 'looks_4',
    },
    {
      name: 'Looks 5',
      id: 'looks_5',
    },
    {
      name: 'Looks 6',
      id: 'looks_6',
    },
    {
      name: 'Looks One',
      id: 'looks_one',
    },
    {
      name: 'Looks Two',
      id: 'looks_two',
    },
    {
      name: 'Loop',
      id: 'loop',
    },
    {
      name: 'Loupe',
      id: 'loupe',
    },
    {
      name: 'Low Priority',
      id: 'low_priority',
    },
    {
      name: 'Loyalty',
      id: 'loyalty',
    },
    {
      name: 'Mail',
      id: 'mail',
    },
    {
      name: 'Mail Outline',
      id: 'mail_outline',
    },
    {
      name: 'Map',
      id: 'map',
    },
    {
      name: 'Mark Unread',
      id: 'markunread',
    },
    {
      name: 'Mark Unread Mailbox',
      id: 'markunread_mailbox',
    },
    {
      name: 'Memory',
      id: 'memory',
    },
    {
      name: 'Menu',
      id: 'menu',
    },
    {
      name: 'Merge Type',
      id: 'merge_type',
    },
    {
      name: 'Message',
      id: 'message',
    },
    {
      name: 'Microphone',
      id: 'mic',
    },
    {
      name: 'Microphone None',
      id: 'mic_none',
    },
    {
      name: 'Microphone Off',
      id: 'mic_off',
    },
    {
      name: 'MMS',
      id: 'mms',
    },
    {
      name: 'Mode Comment',
      id: 'mode_comment',
    },
    {
      name: 'Mode Edit',
      id: 'mode_edit',
    },
    {
      name: 'Monetization On',
      id: 'monetization_on',
    },
    {
      name: 'Money Off',
      id: 'money_off',
    },
    {
      name: 'Monochrome Photos',
      id: 'monochrome_photos',
    },
    {
      name: 'Mood',
      id: 'mood',
    },
    {
      name: 'Mood Bad',
      id: 'mood_bad',
    },
    {
      name: 'More',
      id: 'more',
    },
    {
      name: 'More Horizontal',
      id: 'more_horiz',
    },
    {
      name: 'More Vertical',
      id: 'more_vert',
    },
    {
      name: 'Motorcycle',
      id: 'motorcycle',
    },
    {
      name: 'Mouse',
      id: 'mouse',
    },
    {
      name: 'Move to Inbox',
      id: 'move_to_inbox',
    },
    {
      name: 'Movie',
      id: 'movie',
    },
    {
      name: 'Movie Creation',
      id: 'movie_creation',
    },
    {
      name: 'Movie Filter',
      id: 'movie_filter',
    },
    {
      name: 'Multiline Chart',
      id: 'multiline_chart',
    },
    {
      name: 'Music Note',
      id: 'music_note',
    },
    {
      name: 'Music Video',
      id: 'music_video',
    },
    {
      name: 'My Location',
      id: 'my_location',
    },
    {
      name: 'Nature',
      id: 'nature',
    },
    {
      name: 'Nature People',
      id: 'nature_people',
    },
    {
      name: 'Navigate Before',
      id: 'navigate_before',
    },
    {
      name: 'Navigate Next',
      id: 'navigate_next',
    },
    {
      name: 'Navigation',
      id: 'navigation',
    },
    {
      name: 'Near Me',
      id: 'near_me',
    },
    {
      name: 'Network Cell',
      id: 'network_cell',
    },
    {
      name: 'Network Check',
      id: 'network_check',
    },
    {
      name: 'Network Locked',
      id: 'network_locked',
    },
    {
      name: 'Network Wifi',
      id: 'network_wifi',
    },
    {
      name: 'New Releases',
      id: 'new_releases',
    },
    {
      name: 'Next Week',
      id: 'next_week',
    },
    {
      name: 'NFC',
      id: 'nfc',
    },
    {
      name: 'No Encryption',
      id: 'no_encryption',
    },
    {
      name: 'No SIM',
      id: 'no_sim',
    },
    {
      name: 'Not Interested',
      id: 'not_interested',
    },
    {
      name: 'Note',
      id: 'note',
    },
    {
      name: 'Note Add',
      id: 'note_add',
    },
    {
      name: 'Notifications',
      id: 'notifications',
    },
    {
      name: 'Notifications Active',
      id: 'notifications_active',
    },
    {
      name: 'Notifications None',
      id: 'notifications_none',
    },
    {
      name: 'Notifications Off',
      id: 'notifications_off',
    },
    {
      name: 'Notifications Paused',
      id: 'notifications_paused',
    },
    {
      name: 'Offline Pin',
      id: 'offline_pin',
    },
    {
      name: 'On-Demand Video',
      id: 'ondemand_video',
    },
    {
      name: 'Opacity',
      id: 'opacity',
    },
    {
      name: 'Open in Browser',
      id: 'open_in_browser',
    },
    {
      name: 'Open in New',
      id: 'open_in_new',
    },
    {
      name: 'Open With',
      id: 'open_with',
    },
    {
      name: 'Pages',
      id: 'pages',
    },
    {
      name: 'Pageview',
      id: 'pageview',
    },
    {
      name: 'Palette',
      id: 'palette',
    },
    {
      name: 'Pan Tool',
      id: 'pan_tool',
    },
    {
      name: 'Panorama',
      id: 'panorama',
    },
    {
      name: 'Panorama Fish Eye',
      id: 'panorama_fish_eye',
    },
    {
      name: 'Panorama Horizontal',
      id: 'panorama_horizontal',
    },
    {
      name: 'Panorama Vertical',
      id: 'panorama_vertical',
    },
    {
      name: 'Panorama Wide Angle',
      id: 'panorama_wide_angle',
    },
    {
      name: 'Party Mode',
      id: 'party_mode',
    },
    {
      name: 'Pause',
      id: 'pause',
    },
    {
      name: 'Pause Circle Filled',
      id: 'pause_circle_filled',
    },
    {
      name: 'Pause Circle Outline',
      id: 'pause_circle_outline',
    },
    {
      name: 'Payment',
      id: 'payment',
    },
    {
      name: 'People',
      id: 'people',
    },
    {
      name: 'People Outline',
      id: 'people_outline',
    },
    {
      name: 'Permission Camera Mic',
      id: 'perm_camera_mic',
    },
    {
      name: 'Permission Contact Calendar',
      id: 'perm_contact_calendar',
    },
    {
      name: 'Permission Data Setting',
      id: 'perm_data_setting',
    },
    {
      name: 'Permission Device Information',
      id: 'perm_device_information',
    },
    {
      name: 'Permission Identity',
      id: 'perm_identity',
    },
    {
      name: 'Permission Media',
      id: 'perm_media',
    },
    {
      name: 'Permission Phone Message',
      id: 'perm_phone_msg',
    },
    {
      name: 'Permission Scan Wifi',
      id: 'perm_scan_wifi',
    },
    {
      name: 'Person',
      id: 'person',
    },
    {
      name: 'Person Add',
      id: 'person_add',
    },
    {
      name: 'Person Outline',
      id: 'person_outline',
    },
    {
      name: 'Person Pin',
      id: 'person_pin',
    },
    {
      name: 'Person Pin Circle',
      id: 'person_pin_circle',
    },
    {
      name: 'Personal Video',
      id: 'personal_video',
    },
    {
      name: 'Pets',
      id: 'pets',
    },
    {
      name: 'Phone',
      id: 'phone',
    },
    {
      name: 'Phone Android',
      id: 'phone_android',
    },
    {
      name: 'Phone Bluetooth Speaker',
      id: 'phone_bluetooth_speaker',
    },
    {
      name: 'Phone Forwarded',
      id: 'phone_forwarded',
    },
    {
      name: 'Phone in Talk',
      id: 'phone_in_talk',
    },
    {
      name: 'Phone iPhone',
      id: 'phone_iphone',
    },
    {
      name: 'Phone Locked',
      id: 'phone_locked',
    },
    {
      name: 'Phone Missed',
      id: 'phone_missed',
    },
    {
      name: 'Phone Paused',
      id: 'phone_paused',
    },
    {
      name: 'Phonelink',
      id: 'phonelink',
    },
    {
      name: 'Phonelink Erase',
      id: 'phonelink_erase',
    },
    {
      name: 'Phonelink Lock',
      id: 'phonelink_lock',
    },
    {
      name: 'Phonelink Off',
      id: 'phonelink_off',
    },
    {
      name: 'Phonelink Ring',
      id: 'phonelink_ring',
    },
    {
      name: 'Phonelink Setup',
      id: 'phonelink_setup',
    },
    {
      name: 'Photo',
      id: 'photo',
    },
    {
      name: 'Photo Album',
      id: 'photo_album',
    },
    {
      name: 'Photo Camera',
      id: 'photo_camera',
    },
    {
      name: 'Photo Filter',
      id: 'photo_filter',
    },
    {
      name: 'Photo Library',
      id: 'photo_library',
    },
    {
      name: 'Photo Size Select Actual',
      id: 'photo_size_select_actual',
    },
    {
      name: 'Photo Size Select Large',
      id: 'photo_size_select_large',
    },
    {
      name: 'Photo Size Select Small',
      id: 'photo_size_select_small',
    },
    {
      name: 'Picture as Pdf',
      id: 'picture_as_pdf',
    },
    {
      name: 'Picture in Picture',
      id: 'picture_in_picture',
    },
    {
      name: 'Picture in Picture Alternative',
      id: 'picture_in_picture_alt',
    },
    {
      name: 'Pie Chart',
      id: 'pie_chart',
    },
    {
      name: 'Pie Chart Outlined',
      id: 'pie_chart_outlined',
    },
    {
      name: 'Pin Drop',
      id: 'pin_drop',
    },
    {
      name: 'Place',
      id: 'place',
    },
    {
      name: 'Play Arrow',
      id: 'play_arrow',
    },
    {
      name: 'Play Circle Filled',
      id: 'play_circle_filled',
    },
    {
      name: 'Play Circle Outline',
      id: 'play_circle_outline',
    },
    {
      name: 'Play for Work',
      id: 'play_for_work',
    },
    {
      name: 'Playlist Add',
      id: 'playlist_add',
    },
    {
      name: 'Playlist Add Check',
      id: 'playlist_add_check',
    },
    {
      name: 'Playlist Play',
      id: 'playlist_play',
    },
    {
      name: 'Plus One',
      id: 'plus_one',
    },
    {
      name: 'Poll',
      id: 'poll',
    },
    {
      name: 'Polymer',
      id: 'polymer',
    },
    {
      name: 'Pool',
      id: 'pool',
    },
    {
      name: 'Portable Wifi Off',
      id: 'portable_wifi_off',
    },
    {
      name: 'Portrait',
      id: 'portrait',
    },
    {
      name: 'Power',
      id: 'power',
    },
    {
      name: 'Power Input',
      id: 'power_input',
    },
    {
      name: 'Power Settings New',
      id: 'power_settings_new',
    },
    {
      name: 'Pregnant Woman',
      id: 'pregnant_woman',
    },
    {
      name: 'Present To All',
      id: 'present_to_all',
    },
    {
      name: 'Print',
      id: 'print',
    },
    {
      name: 'Priority High',
      id: 'priority_high',
    },
    {
      name: 'Public',
      id: 'public',
    },
    {
      name: 'Publish',
      id: 'publish',
    },
    {
      name: 'Query Builder',
      id: 'query_builder',
    },
    {
      name: 'Question Answer',
      id: 'question_answer',
    },
    {
      name: 'Queue',
      id: 'queue',
    },
    {
      name: 'Queue Music',
      id: 'queue_music',
    },
    {
      name: 'Queue Play Next',
      id: 'queue_play_next',
    },
    {
      name: 'Radio',
      id: 'radio',
    },
    {
      name: 'Radio Button Checked',
      id: 'radio_button_checked',
    },
    {
      name: 'Radio Button Unchecked',
      id: 'radio_button_unchecked',
    },
    {
      name: 'Rate Review',
      id: 'rate_review',
    },
    {
      name: 'Receipt',
      id: 'receipt',
    },
    {
      name: 'Recent Actors',
      id: 'recent_actors',
    },
    {
      name: 'Record Voice Over',
      id: 'record_voice_over',
    },
    {
      name: 'Redeem',
      id: 'redeem',
    },
    {
      name: 'Redo',
      id: 'redo',
    },
    {
      name: 'Refresh',
      id: 'refresh',
    },
    {
      name: 'Remove',
      id: 'remove',
    },
    {
      name: 'Remove Circle',
      id: 'remove_circle',
    },
    {
      name: 'Remove Circle Outline',
      id: 'remove_circle_outline',
    },
    {
      name: 'Remove From Queue',
      id: 'remove_from_queue',
    },
    {
      name: 'Remove Red Eye',
      id: 'remove_red_eye',
    },
    {
      name: 'Remove Shopping Cart',
      id: 'remove_shopping_cart',
    },
    {
      name: 'Reorder',
      id: 'reorder',
    },
    {
      name: 'Repeat',
      id: 'repeat',
    },
    {
      name: 'Repeat One',
      id: 'repeat_one',
    },
    {
      name: 'Replay',
      id: 'replay',
    },
    {
      name: 'Replay 10',
      id: 'replay_10',
    },
    {
      name: 'Replay 30',
      id: 'replay_30',
    },
    {
      name: 'Replay 5',
      id: 'replay_5',
    },
    {
      name: 'Reply',
      id: 'reply',
    },
    {
      name: 'Reply All',
      id: 'reply_all',
    },
    {
      name: 'Report',
      id: 'report',
    },
    {
      name: 'Report Problem',
      id: 'report_problem',
    },
    {
      name: 'Restaurant',
      id: 'restaurant',
    },
    {
      name: 'Restaurant Menu',
      id: 'restaurant_menu',
    },
    {
      name: 'Restore',
      id: 'restore',
    },
    {
      name: 'Restore Page',
      id: 'restore_page',
    },
    {
      name: 'Ring Volume',
      id: 'ring_volume',
    },
    {
      name: 'Room',
      id: 'room',
    },
    {
      name: 'Room Service',
      id: 'room_service',
    },
    {
      name: 'Rotate 90 Degrees Counterclockwise',
      id: 'rotate_90_degrees_ccw',
    },
    {
      name: 'Rotate Left',
      id: 'rotate_left',
    },
    {
      name: 'Rotate Right',
      id: 'rotate_right',
    },
    {
      name: 'Rounded Corner',
      id: 'rounded_corner',
    },
    {
      name: 'Router',
      id: 'router',
    },
    {
      name: 'Rowing',
      id: 'rowing',
    },
    {
      name: 'RSS Feed',
      id: 'rss_feed',
    },
    {
      name: 'RV Hookup',
      id: 'rv_hookup',
    },
    {
      name: 'Satellite',
      id: 'satellite',
    },
    {
      name: 'Save',
      id: 'save',
    },
    {
      name: 'Scanner',
      id: 'scanner',
    },
    {
      name: 'Schedule',
      id: 'schedule',
    },
    {
      name: 'School',
      id: 'school',
    },
    {
      name: 'Screen Lock Landscape',
      id: 'screen_lock_landscape',
    },
    {
      name: 'Screen Lock Portrait',
      id: 'screen_lock_portrait',
    },
    {
      name: 'Screen Lock Rotation',
      id: 'screen_lock_rotation',
    },
    {
      name: 'Screen Rotation',
      id: 'screen_rotation',
    },
    {
      name: 'Screen Share',
      id: 'screen_share',
    },
    {
      name: 'SD Card',
      id: 'sd_card',
    },
    {
      name: 'SD Storage',
      id: 'sd_storage',
    },
    {
      name: 'Search',
      id: 'search',
    },
    {
      name: 'Security',
      id: 'security',
    },
    {
      name: 'Select All',
      id: 'select_all',
    },
    {
      name: 'Send',
      id: 'send',
    },
    {
      name: 'Sentiment Dissatisfied',
      id: 'sentiment_dissatisfied',
    },
    {
      name: 'Sentiment Neutral',
      id: 'sentiment_neutral',
    },
    {
      name: 'Sentiment Satisfied',
      id: 'sentiment_satisfied',
    },
    {
      name: 'Sentiment Very Dissatisfied',
      id: 'sentiment_very_dissatisfied',
    },
    {
      name: 'Sentiment Very Satisfied',
      id: 'sentiment_very_satisfied',
    },
    {
      name: 'Settings',
      id: 'settings',
    },
    {
      name: 'Settings Applications',
      id: 'settings_applications',
    },
    {
      name: 'Settings Backup Restore',
      id: 'settings_backup_restore',
    },
    {
      name: 'Settings Bluetooth',
      id: 'settings_bluetooth',
    },
    {
      name: 'Settings Brightness',
      id: 'settings_brightness',
    },
    {
      name: 'Settings Cell',
      id: 'settings_cell',
    },
    {
      name: 'Settings Ethernet',
      id: 'settings_ethernet',
    },
    {
      name: 'Settings Input Antenna',
      id: 'settings_input_antenna',
    },
    {
      name: 'Settings Input Component',
      id: 'settings_input_component',
    },
    {
      name: 'Settings Input Composite',
      id: 'settings_input_composite',
    },
    {
      name: 'Settings Input HDMI',
      id: 'settings_input_hdmi',
    },
    {
      name: 'Settings Input S-Video',
      id: 'settings_input_svideo',
    },
    {
      name: 'Settings Overscan',
      id: 'settings_overscan',
    },
    {
      name: 'Settings Phone',
      id: 'settings_phone',
    },
    {
      name: 'Settings Power',
      id: 'settings_power',
    },
    {
      name: 'Settings Remote',
      id: 'settings_remote',
    },
    {
      name: 'Settings System Daydream',
      id: 'settings_system_daydream',
    },
    {
      name: 'Settings Voice',
      id: 'settings_voice',
    },
    {
      name: 'Share',
      id: 'share',
    },
    {
      name: 'Shop',
      id: 'shop',
    },
    {
      name: 'Shop Two',
      id: 'shop_two',
    },
    {
      name: 'Shopping Basket',
      id: 'shopping_basket',
    },
    {
      name: 'Shopping Cart',
      id: 'shopping_cart',
    },
    {
      name: 'Short Text',
      id: 'short_text',
    },
    {
      name: 'Show Chart',
      id: 'show_chart',
    },
    {
      name: 'Shuffle',
      id: 'shuffle',
    },
    {
      name: 'Signal Cellular 4 Bar',
      id: 'signal_cellular_4_bar',
    },
    {
      name: 'Signal Cellular Connected No Internet 4 Bar',
      id: 'signal_cellular_connected_no_internet_4_bar',
    },
    {
      name: 'Signal Cellular No Sim',
      id: 'signal_cellular_no_sim',
    },
    {
      name: 'Signal Cellular Null',
      id: 'signal_cellular_null',
    },
    {
      name: 'Signal Cellular Off',
      id: 'signal_cellular_off',
    },
    {
      name: 'Signal Wifi 4 Bar',
      id: 'signal_wifi_4_bar',
    },
    {
      name: 'Signal Wifi 4 Bar Lock',
      id: 'signal_wifi_4_bar_lock',
    },
    {
      name: 'Signal Wifi Off',
      id: 'signal_wifi_off',
    },
    {
      name: 'SIM Card',
      id: 'sim_card',
    },
    {
      name: 'SIM Card Alert',
      id: 'sim_card_alert',
    },
    {
      name: 'Skip Next',
      id: 'skip_next',
    },
    {
      name: 'Skip Previous',
      id: 'skip_previous',
    },
    {
      name: 'Slideshow',
      id: 'slideshow',
    },
    {
      name: 'Slow Motion Video',
      id: 'slow_motion_video',
    },
    {
      name: 'Smartphone',
      id: 'smartphone',
    },
    {
      name: 'Smoke Free',
      id: 'smoke_free',
    },
    {
      name: 'Smoking Rooms',
      id: 'smoking_rooms',
    },
    {
      name: 'SMS',
      id: 'sms',
    },
    {
      name: 'SMS Failed',
      id: 'sms_failed',
    },
    {
      name: 'Snooze',
      id: 'snooze',
    },
    {
      name: 'Sort',
      id: 'sort',
    },
    {
      name: 'Sort by Alpha',
      id: 'sort_by_alpha',
    },
    {
      name: 'Spa',
      id: 'spa',
    },
    {
      name: 'Space Bar',
      id: 'space_bar',
    },
    {
      name: 'Speaker',
      id: 'speaker',
    },
    {
      name: 'Speaker Group',
      id: 'speaker_group',
    },
    {
      name: 'Speaker Notes',
      id: 'speaker_notes',
    },
    {
      name: 'Speaker Notes Off',
      id: 'speaker_notes_off',
    },
    {
      name: 'Speaker Phone',
      id: 'speaker_phone',
    },
    {
      name: 'Spellcheck',
      id: 'spellcheck',
    },
    {
      name: 'Star',
      id: 'star',
    },
    {
      name: 'Star Border',
      id: 'star_border',
    },
    {
      name: 'Star Half',
      id: 'star_half',
    },
    {
      name: 'Stars',
      id: 'stars',
    },
    {
      name: 'Stay Current Landscape',
      id: 'stay_current_landscape',
    },
    {
      name: 'Stay Current Portrait',
      id: 'stay_current_portrait',
    },
    {
      name: 'Stay Primary Landscape',
      id: 'stay_primary_landscape',
    },
    {
      name: 'Stay Primary Portrait',
      id: 'stay_primary_portrait',
    },
    {
      name: 'Stop',
      id: 'stop',
    },
    {
      name: 'Stop Screen Share',
      id: 'stop_screen_share',
    },
    {
      name: 'Storage',
      id: 'storage',
    },
    {
      name: 'Store',
      id: 'store',
    },
    {
      name: 'Store Mall Directory',
      id: 'store_mall_directory',
    },
    {
      name: 'Straighten',
      id: 'straighten',
    },
    {
      name: 'Streetview',
      id: 'streetview',
    },
    {
      name: 'Strikethrough S',
      id: 'strikethrough_s',
    },
    {
      name: 'Style',
      id: 'style',
    },
    {
      name: 'Subdirectory Arrow Left',
      id: 'subdirectory_arrow_left',
    },
    {
      name: 'Subdirectory Arrow Right',
      id: 'subdirectory_arrow_right',
    },
    {
      name: 'Subject',
      id: 'subject',
    },
    {
      name: 'Subscriptions',
      id: 'subscriptions',
    },
    {
      name: 'Subtitles',
      id: 'subtitles',
    },
    {
      name: 'Subway',
      id: 'subway',
    },
    {
      name: 'Supervisor Account',
      id: 'supervisor_account',
    },
    {
      name: 'Surround Sound',
      id: 'surround_sound',
    },
    {
      name: 'Swap Calls',
      id: 'swap_calls',
    },
    {
      name: 'Swap Horizontal',
      id: 'swap_horiz',
    },
    {
      name: 'Swap Vertical',
      id: 'swap_vert',
    },
    {
      name: 'Swap Vertical Circle',
      id: 'swap_vertical_circle',
    },
    {
      name: 'Switch Camera',
      id: 'switch_camera',
    },
    {
      name: 'Switch Video',
      id: 'switch_video',
    },
    {
      name: 'Sync',
      id: 'sync',
    },
    {
      name: 'Sync Disabled',
      id: 'sync_disabled',
    },
    {
      name: 'Sync Problem',
      id: 'sync_problem',
    },
    {
      name: 'System Update',
      id: 'system_update',
    },
    {
      name: 'System Update Alternative',
      id: 'system_update_alt',
    },
    {
      name: 'Tab',
      id: 'tab',
    },
    {
      name: 'Tab Unselected',
      id: 'tab_unselected',
    },
    {
      name: 'Tablet',
      id: 'tablet',
    },
    {
      name: 'Tablet Android',
      id: 'tablet_android',
    },
    {
      name: 'Tablet Mac',
      id: 'tablet_mac',
    },
    {
      name: 'Tag Faces',
      id: 'tag_faces',
    },
    {
      name: 'Tap And Play',
      id: 'tap_and_play',
    },
    {
      name: 'Terrain',
      id: 'terrain',
    },
    {
      name: 'Text Fields',
      id: 'text_fields',
    },
    {
      name: 'Text Format',
      id: 'text_format',
    },
    {
      name: 'Text SMS',
      id: 'textsms',
    },
    {
      name: 'Texture',
      id: 'texture',
    },
    {
      name: 'Theaters',
      id: 'theaters',
    },
    {
      name: 'Thumb Down',
      id: 'thumb_down',
    },
    {
      name: 'Thumb Up',
      id: 'thumb_up',
    },
    {
      name: 'Thumbs Up Down',
      id: 'thumbs_up_down',
    },
    {
      name: 'Time to Leave',
      id: 'time_to_leave',
    },
    {
      name: 'Timelapse',
      id: 'timelapse',
    },
    {
      name: 'Timeline',
      id: 'timeline',
    },
    {
      name: 'Timer',
      id: 'timer',
    },
    {
      name: 'Timer 10',
      id: 'timer_10',
    },
    {
      name: 'Timer 3',
      id: 'timer_3',
    },
    {
      name: 'Timer Off',
      id: 'timer_off',
    },
    {
      name: 'Title',
      id: 'title',
    },
    {
      name: 'Toc',
      id: 'toc',
    },
    {
      name: 'Today',
      id: 'today',
    },
    {
      name: 'Toll',
      id: 'toll',
    },
    {
      name: 'Tonality',
      id: 'tonality',
    },
    {
      name: 'Touch App',
      id: 'touch_app',
    },
    {
      name: 'Toys',
      id: 'toys',
    },
    {
      name: 'Track Changes',
      id: 'track_changes',
    },
    {
      name: 'Traffic',
      id: 'traffic',
    },
    {
      name: 'Train',
      id: 'train',
    },
    {
      name: 'Tram',
      id: 'tram',
    },
    {
      name: 'Transfer within a Station',
      id: 'transfer_within_a_station',
    },
    {
      name: 'Transform',
      id: 'transform',
    },
    {
      name: 'Translate',
      id: 'translate',
    },
    {
      name: 'Trending Down',
      id: 'trending_down',
    },
    {
      name: 'Trending Flat',
      id: 'trending_flat',
    },
    {
      name: 'Trending Up',
      id: 'trending_up',
    },
    {
      name: 'Tune',
      id: 'tune',
    },
    {
      name: 'Turned In',
      id: 'turned_in',
    },
    {
      name: 'Turned In Not',
      id: 'turned_in_not',
    },
    {
      name: 'TV',
      id: 'tv',
    },
    {
      name: 'Unarchive',
      id: 'unarchive',
    },
    {
      name: 'Undo',
      id: 'undo',
    },
    {
      name: 'Unfold Less',
      id: 'unfold_less',
    },
    {
      name: 'Unfold More',
      id: 'unfold_more',
    },
    {
      name: 'Update',
      id: 'update',
    },
    {
      name: 'USB',
      id: 'usb',
    },
    {
      name: 'Verified User',
      id: 'verified_user',
    },
    {
      name: 'Vertical Align Bottom',
      id: 'vertical_align_bottom',
    },
    {
      name: 'Vertical Align Center',
      id: 'vertical_align_center',
    },
    {
      name: 'Vertical Align Top',
      id: 'vertical_align_top',
    },
    {
      name: 'Vibration',
      id: 'vibration',
    },
    {
      name: 'Video Call',
      id: 'video_call',
    },
    {
      name: 'Video Label',
      id: 'video_label',
    },
    {
      name: 'Video Library',
      id: 'video_library',
    },
    {
      name: 'Videocam',
      id: 'videocam',
    },
    {
      name: 'Videocam Off',
      id: 'videocam_off',
    },
    {
      name: 'Videogame Asset',
      id: 'videogame_asset',
    },
    {
      name: 'View Agenda',
      id: 'view_agenda',
    },
    {
      name: 'View Array',
      id: 'view_array',
    },
    {
      name: 'View Carousel',
      id: 'view_carousel',
    },
    {
      name: 'View Column',
      id: 'view_column',
    },
    {
      name: 'View Comfy',
      id: 'view_comfy',
    },
    {
      name: 'View Compact',
      id: 'view_compact',
    },
    {
      name: 'View Day',
      id: 'view_day',
    },
    {
      name: 'View Headline',
      id: 'view_headline',
    },
    {
      name: 'View List',
      id: 'view_list',
    },
    {
      name: 'View Module',
      id: 'view_module',
    },
    {
      name: 'View Quilt',
      id: 'view_quilt',
    },
    {
      name: 'View Stream',
      id: 'view_stream',
    },
    {
      name: 'View Week',
      id: 'view_week',
    },
    {
      name: 'Vignette',
      id: 'vignette',
    },
    {
      name: 'Visibility',
      id: 'visibility',
    },
    {
      name: 'Visibility Off',
      id: 'visibility_off',
    },
    {
      name: 'Voice Chat',
      id: 'voice_chat',
    },
    {
      name: 'Voicemail',
      id: 'voicemail',
    },
    {
      name: 'Volume Down',
      id: 'volume_down',
    },
    {
      name: 'Volume Mute',
      id: 'volume_mute',
    },
    {
      name: 'Volume Off',
      id: 'volume_off',
    },
    {
      name: 'Volume Up',
      id: 'volume_up',
    },
    {
      name: 'VPN Key',
      id: 'vpn_key',
    },
    {
      name: 'VPN Lock',
      id: 'vpn_lock',
    },
    {
      name: 'Wallpaper',
      id: 'wallpaper',
    },
    {
      name: 'Warning',
      id: 'warning',
    },
    {
      name: 'Watch',
      id: 'watch',
    },
    {
      name: 'Watch Later',
      id: 'watch_later',
    },
    {
      name: 'WB Auto',
      id: 'wb_auto',
    },
    {
      name: 'WB Cloudy',
      id: 'wb_cloudy',
    },
    {
      name: 'WB Incandescent',
      id: 'wb_incandescent',
    },
    {
      name: 'WB Iridescent',
      id: 'wb_iridescent',
    },
    {
      name: 'WB Sunny',
      id: 'wb_sunny',
    },
    {
      name: 'WC',
      id: 'wc',
    },
    {
      name: 'Web',
      id: 'web',
    },
    {
      name: 'Web Asset',
      id: 'web_asset',
    },
    {
      name: 'Weekend',
      id: 'weekend',
    },
    {
      name: 'What\'s Hot',
      id: 'whatshot',
    },
    {
      name: 'Widgets',
      id: 'widgets',
    },
    {
      name: 'Wifi',
      id: 'wifi',
    },
    {
      name: 'Wifi Lock',
      id: 'wifi_lock',
    },
    {
      name: 'Wifi Tethering',
      id: 'wifi_tethering',
    },
    {
      name: 'Work',
      id: 'work',
    },
    {
      name: 'Wrap Text',
      id: 'wrap_text',
    },
    {
      name: 'Youtube Searched For',
      id: 'youtube_searched_for',
    },
    {
      name: 'Zoom In',
      id: 'zoom_in',
    },
    {
      name: 'Zoom Out',
      id: 'zoom_out',
    },
    {
      name: 'Zoom Out Map',
      id: 'zoom_out_map',
    },
    {
      name: 'Fire Truck',
      id: 'fire_truck',
    },
    {
      name: 'Local Fire Department',
      id: 'local_fire_department',
    },
    {
      name: 'Fire Place',
      id: 'fireplace',
    },
    {
      name: 'Fire Extinguisher',
      id: 'fire_extinguisher',
    },
    {
      name: 'Detector Smoke',
      id: 'detector_smoke',
    },
    {
      name: 'Fire Hydrant',
      id: 'fire_hydrant',
    },
  ];
}
