<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">{{firstname}} {{lastname}}</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Gruppen</mat-label>
          <mat-select multiple [(ngModel)]="userInfo.groupListSelected"
            [disabled]="!_login.hasPermission('admin-user-groups-edit')">
            <mat-option>
              <ngx-mat-select-search [formControl]="groupsFilterCtrl"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let group of groupsFilter | async" [value]="group.id">{{group.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="clearfix" *ngIf="_login.hasPermission('admin-user-groups-edit')">
          <button mat-raised-button color="success" class="float-right" (click)="saveGroups();">Speichern
            <mat-icon>save
            </mat-icon></button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>