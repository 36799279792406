import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var STPadServerLibDefault: any;
declare var STPadServerLibCommons: any;

@Component({
  selector: 'app-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.css'],
})
export class SignatureDialogComponent implements OnInit, OnDestroy {

  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;

  @ViewChild('signotecSign', { static: false }) 
  public signotecSign: TemplateRef<any>;

  public signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 900,
    canvasHeight: 300,
  };

  hasSignature = false;
  fallbackSignature = false;

  constructor(public _ref: MatDialogRef<SignatureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any) { }

  ngOnInit(): void {
    this._ref.updateSize('1000px');
    this.initSignotec();
  }

  initSignotec() {
    let url: string = 'wss://local.signotecwebsocket.de:49494';
    STPadServerLibCommons.createConnection(url, (evt) => {
      this.readySignotec();
    }, (evt) => {
      this.fallbackSignature = true;
    }, (evt) => {
      this.fallbackSignature = true;
    });
  }

  ngOnDestroy() {
    this.destroySignotec();
  }

  destroySignotec() {
    STPadServerLibCommons.destroyConnection();
  }

  closeSignotec() {
    var params = new STPadServerLibDefault.Params.closePad(0);
    STPadServerLibDefault.closePad(params)
      .then((value) => {}, reason => {
        console.log(reason.errorMessage);
      });
  }

  readySignotec() {
    var params = new STPadServerLibDefault.Params.searchForPads();
    params.setPadSubset('HID');
    STPadServerLibDefault.searchForPads(params).then((pads: any) => {
      if (pads.foundPads.length === 0) {
        this.fallbackSignature = true;
      } else {
        this.startSignotec();
        this.signaturePad.off();
      }
    }, (err) => {
      this.fallbackSignature = true;
    });
  }

  startSignotec() {
    this.openPad().then(() => {
      var SignatureParams = new STPadServerLibDefault.Params.startSignature();
      SignatureParams.setFieldName(this._data.title);
      SignatureParams.setCustomText(this._data.customText || '');
      STPadServerLibDefault.startSignature(SignatureParams);

      //Action Handlers
      STPadServerLibDefault.handleConfirmSignature = () => this.confirmSignature();
      STPadServerLibDefault.handleCancelSignature = () => this.closeDialog();
      STPadServerLibDefault.handleRetrySignature = () => STPadServerLibDefault.retrySignature();
    });
  }

  confirmSignature() {
    STPadServerLibDefault.confirmSignature().then((confirmation) => {
      var params = new STPadServerLibDefault.Params.getSignatureImage();
      params.setFileType(STPadServerLibDefault.FileType.PNG);
      params.setPenWidth(5);
      STPadServerLibDefault.getSignatureImage(params)
        .then((value) => {
          this.signaturePad.fromDataURL('data:image/png;base64,' + value.file);
          this.drawComplete();
          this.closeSignotec();
        });
    });
  }

  openPad(): Promise<any> {
    var openPadParams = new STPadServerLibDefault.Params.openPad(0);
    return new Promise<any>((resolve, reject) => {
      STPadServerLibDefault.openPad(openPadParams)
        .then(() => {
          resolve(true);
        }).catch(() => {
          reject();
        });
    });
  }

  drawComplete() {
    this.hasSignature = true;
  }

  clearSignature() {
    this.signaturePad.clear();
    this.hasSignature = false;
    if (!this.fallbackSignature) {
      this.startSignotec();
    }
  }

  closeDialog(status = false) {
    STPadServerLibDefault.cancelSignature();
    this.closeSignotec();
    if (status) {
      this._ref.close(this.signaturePad.toDataURL('image/png', 0.7));
      return;
    }
    this._ref.close(null);
  }

}
