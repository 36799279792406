import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { Courses } from '@ffo/mitgliederbereich-types';
import dayjs from 'dayjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';

export interface CourseCategory {
  name: string;
  data: Courses[];
}

@Component({
  selector: 'admin-user-new-coursedata-dialog',
  templateUrl: './user-new-coursedata-dialog.component.html',
  styleUrls: ['./user-new-coursedata-dialog.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class UserNewCoursedataDialogComponent implements OnInit {

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;

  max = new Date();
  attachments = [];
  group: UntypedFormGroup = new UntypedFormGroup({});
  courseSelect = new FormControl({});

  courseUserId: number;
  currentCourse: Courses;

  courseList: CourseCategory[] = [];
  isLoading = false;

  public courseFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public courseFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  protected _onDestroy = new Subject<void>();

  constructor(private _bottomSheetRef: MatBottomSheetRef<UserNewCoursedataDialogComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public _data: any,
    private _builder: FormBuilder,
    private _config: ConfigModule,
    public _spinner: NgxSpinnerService,
    private _http: HttpClient) {

  }

  ngOnInit(): void {
    this.courseFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCourses();
      });
    this.group = this._builder.group({
      date: ['', [Validators.required]],
      comment: [''],
      file: ['']
    });
    
    if (this._data.userId) {
      this.courseUserId = this._data.userId;
    }
    this.courseList = this._data.courses;
    
    this.courseFilter.next(this.courseList);
    this.courseFilterCtrl.updateValueAndValidity();

    if (!this._data.course) {
      setTimeout(() => {
        document.getElementById('courseDropdown').click()
      }, 100);
    } else {      
      this.currentCourse = this.courseList.map(c => c.data).flat(1).find(c => c.id == this._data.course.courseID);
      
      this.group.setValue({
        date: this._data.course.date,
        file: ''
      });
      
      if (this._data.course.fileRef) {
        this.attachments.push({
          filename: this._data.course.fileRef,
          path: 'remote'
        });
      }
    }
  }

  async openPreview(file) {
    if (file.path == 'remote') {
      try {
        const result = await this._http.get<any>(`${getHostUrl()}admin/user/course/preview/${this._data.userId}/${this._data.course.id}?personal=${this._data.personal ?? false}`).toPromise();
        window.open(result.url);
      } catch (e) {
        this._config.showSnackbar('Konnte Vorschau nicht öffnen', 3000, 'warning');
        console.error(e);
      }
      return;
    }
    fetch(file.path)
      .then(res => res.blob())
      .then(res => URL.createObjectURL(res))
      .then(window.open)
  }

  openFileDialog() {
    const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
    e.click();
  }

  uploadAttachments($event) {
    let fileList: FileList = $event.target.files;
    this.blobToBase64(fileList.item(0)).then((fileContent: any) => {
      this.attachments.push({ 'filename': fileList.item(0).name, 'path': fileContent });
    });
  }

  save() {
    if (this._data.personal && this.attachments.length == 0) {
      this._config.showSnackbar('Eine Bescheinigung ist nötig um dieses Ereignis zu speichern', 3000, 'error');
      return;
    }
    this.isLoading = true;
    this._http.post<any>(`${getHostUrl()}admin/user/course/${this._data.userId}`, {
      courseObj: {
        id: this.currentCourse.id,
        comment: this.group.get('comment').value,
        date: dayjs(this.group.get('date').value).format('YYYY-MM-DD'),
        file: this.attachments.length > 0 ? this.attachments[0].path : null
      },
    }).subscribe(resp => {
      this.isLoading = false;
      this._config.showSnackbar('Laufbahndaten gespeichert!', 2000, 'success');
      this._bottomSheetRef.dismiss({
        course: this._data,
        data: this.group.value,
        files: this.attachments
      });
    }, error => {
      this.isLoading = false;
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
    });
  }

  removeAttachment(index: number) {
    this.attachments.splice(index, 1);
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  selectedCourse($event: MatSelectChange) {
    if ($event.value) {
      this.currentCourse = $event.value;
    }
  }

  protected filterCourses() {
    if (!this.courseList) {
      return;
    }
    let search = this.courseFilterCtrl.value;
    if (!search) {
      this.courseFilter.next(this.courseList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.courseFilter.next(
      this.courseList.map(elem => ({
        name: elem.name,
        data: elem.data.filter(s => s.name.toLocaleLowerCase().includes(search)),
      })).filter(c => c.data.length > 0),
    );
  }
}
