<mat-dialog-header class="mb-3">
  <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
          <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Neuen Benutzer anlegen</h2>
  </div>
</mat-dialog-header>

<mat-dialog-content style="max-height: 100%">
  <form [formGroup]="group">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-6 mb-2">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Anrede</mat-label>
            <mat-select formControlName="salutation" required>
              <mat-option value="w">Frau</mat-option>
              <mat-option value="m">Herr</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-2 col-6 mb-2">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Titel</mat-label>
            <mat-select formControlName="title" required>
              <mat-option value="0">--</mat-option>
              <mat-option value="1">Dr.</mat-option>
              <mat-option value="2">Prof. Dr.</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-6 mb-2">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Vorname</mat-label>
            <input formControlName="firstname" matInput required>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-6">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Nachname</mat-label>
            <input formControlName="lastname" matInput required>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 mb-2">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>E-Mail</mat-label>
            <input matInput required formControlName="email">
            <mat-error *ngIf="group.get('email').hasError('required')">
              E-Mail Adresse erforderlich
            </mat-error>
            <mat-error *ngIf="group.get('email').hasError('email')">
              keine gültige E-Mailadresse
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 mb-2">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Interne E-Mail</mat-label>
            <input matInput formControlName="internalEmail">
            <mat-error *ngIf="group.get('internalEmail').hasError('email')">
              keine gültige E-Mailadresse
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-12 mb-2">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Telefon (Mobil)</mat-label>
            <input type="tel" matInput placeholder="+491712345678" formControlName="mobile">
            <mat-error *ngIf="group.get('mobile').hasError('pattern')">
              benötigtes Format: +491712345678
            </mat-error>
            <mat-error *ngIf="group.get('mobile').hasError('required')">
              Telefonnummer erforderlich
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-12 mb-2">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Telefon (Privat)</mat-label>
            <input type="tel" matInput placeholder="+4989512563" formControlName="phone">
            <mat-error *ngIf="group.get('phone').hasError('pattern')">
              benötigtes Format: +4989512563
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-10 col-12 mb-2">
          <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
            <mat-label>Straße</mat-label>
            <input matInput required readonly formControlName="street">
            <mat-error *ngIf="group.get('street').hasError('required')">
              Adresse erforderlich
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-2 col-6 mb-2">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Hausnummer</mat-label>
            <input formControlName="appartment" matInput required>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-6 mb-2">
          <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
            <mat-label>Postleitzahl</mat-label>
            <input type="number" formControlName="zipcode" matInput required>
          </mat-form-field>
        </div>
        <div class="col-lg-8 col-12 mb-2">
          <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
            <mat-label>Ort</mat-label>
            <input formControlName="city" matInput required>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mb-2">
          <mat-form-field id="birthdate" class="input-full-width" appearance="outline">
            <mat-label>Geburtsdatum</mat-label>
            <input matInput [matDatepicker]="birthdate" placeholder="Geburtsdatum" [max]="today"
              formControlName="birthdate" style="cursor: pointer;">
            <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
            <mat-datepicker #birthdate startView="multi-year"></mat-datepicker>
            <mat-hint>TT.MM.JJJJ</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mb-2">
          <mat-form-field class="input-full-width" appearance="outline" (click)="findBirthPlace()">
            <mat-label>Geburtsort</mat-label>
            <input matInput formControlName="birthplace">
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mb-2">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Staatsangehörigkeit</mat-label>
            <mat-select multiple formControlName="nationality">
              <mat-option *ngFor="let country of countryList" [value]="country.id.toString()">
                {{ country.nationality }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>

  <button mat-raised-button class="float-end" color="primary" (click)="saveData()" [disabled]="group.invalid">
    <mat-icon class="me-2">save</mat-icon>
    Benutzer anlegen
  </button>
</mat-dialog-content>