import { ENTER, COMMA, TAB } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatChipInputEvent as MatChipInputEvent } from '@angular/material/chips';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { Logs, LogResult } from '@ffo/mitgliederbereich-types';
import dayjs from 'dayjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.css'],
})
export class LoggingComponent implements OnInit {


  constructor(
    private _http: HttpClient,
    private _bottomSheet: MatBottomSheet,
    private _config: ConfigModule,
  ) { }

  ngOnInit() {
    this._config.setTitle('Logs');
    this.dataSource = new MatTableDataSource<Logs[]>();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.filter();
    this.refreshData();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['user', 'target', 'time', 'severity', 'action'];

  dataSource = new MatTableDataSource();

  sortType: boolean = false;

  logs: Logs[] = [];

  searchFilter: string = '';

  showLoader = true;

  refreshData(nextToken?: string) {
    this._http.get<LogResult>(getHostUrl() + `datainfo/logs${nextToken ? '?nextToken=' + nextToken : ''}`).subscribe({
      next: (result: LogResult) => {
        this.logs.push(...result.logs);
        this.dataSource.data = this.logs;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.filter();
        this.showLoader = false;
        if (result.nextToken !== nextToken)
          this.refreshData(result.nextToken);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  filter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data: Logs, filter): boolean {
      let searchTerms = JSON.parse(filter);
      var result = true;
      searchTerms.forEach((element: string) => {
        if (data.userID?.toString().indexOf(element.toLowerCase()) === -1 &&
          data.targetID?.toString().indexOf(element.toLowerCase()) === -1 &&
          data.severity?.toLowerCase().indexOf(element.toLowerCase()) === -1 &&
          data.action?.toLowerCase().indexOf(element.toLowerCase()) === -1 &&
          data.message?.toLowerCase().indexOf(element.toLowerCase()) === -1)
          result = false;
      });
      return (result);
    };
    return filterFunction;
  }

  getFormattedDate(time: Date) {
    return dayjs(time).format('DD.MM.YYYY HH:mm');
  }

  openLogEntry(log: Logs): void {
    this._bottomSheet.open(BottomSheetLogApiMessageComponent, {
      data: log,
    });
  }

  /**
   * Search Tags
   */

  visible = true;

  selectable = true;

  removable = true;

  addOnBlur = true;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];

  search: any[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.search.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.dataSource.filter = JSON.stringify(this.search);
  }

  remove(entry: any): void {
    const index = this.search.indexOf(entry);

    if (index >= 0) {
      this.search.splice(index, 1);
    }

    this.dataSource.filter = JSON.stringify(this.search);
  }

  clear() {
    this.search = [];
    this.dataSource.filter = JSON.stringify(this.search);
  }
}

@Component({
  selector: 'app-notification',
  template: `<div class="text-center">
  <br />
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
      <mat-icon>close</mat-icon>
      </a>
      <h4>{{ action }}</h4>
      <p>{{ getFormattedDate(time) }}</p>
      <br />
      <div class="row">
          <div class="col-lg-4 col-12">
              <p><b>Ausführender:</b> {{ userID }}</p>
          </div>
          <div class="col-lg-4 col-12">
              <p><b>Ziel:</b> {{ targetID }}</p>
          </div>
          <div class="col-lg-4 col-12">
              <p><b>Severity:</b> {{ severity }}</p>
          </div>
      </div>
      <hr />
      <p>{{ message }}</p>
</div>`,
})
export class BottomSheetLogApiMessageComponent implements OnDestroy, OnInit {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: Logs,
    public _bottomSheet: MatBottomSheet) {
    this.userID = data.userID;
    this.targetID = data.targetID;
    this.time = data.time;
    this.severity = data.severity;
    this.action = data.action;
    this.message = data.message;
  }

  userID: number;

  targetID: number;

  time: Date;

  severity: string;

  branch: string;

  action: string;

  message: string;


  ngOnInit(): void {
    if (this.message === '')
      this.message = 'Keine Nachricht vorhanden!';
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }

  getFormattedDate(time: Date) {
    const mom = dayjs(time).locale('de');
    return `${mom.format('dddd')} den, ${mom.format('DD.MM.YYYY')} um ${mom.format('HH:mm')} Uhr`;
  }
}
