import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../../util/login';
import { ActivatedRoute } from '@angular/router';
import { getHostUrl, ConfigModule } from '../../../util/config';
import { environment } from '../../../../environments/environment';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdminUserMenusheetComponent } from '../../admin/admin-user/admin-user-detail/admin-user-menusheet/admin-user-menusheet.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css'],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  constructor(
    private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    private _bottomSheet: MatBottomSheet,
    private _config: ConfigModule,
    private _ref: ChangeDetectorRef,
  ) {}

  userInfo: any = {};

  emergencyContactList: any = [];

  userID: number;

  env = environment;

  baseURL: string;

  showLoader = true;

  ngOnInit() {
    this.userID = this._route.snapshot.params.userId;
    this.baseURL = getHostUrl();

    this._config.setTitle('Lädt...');

    if (this.userID) {
      this.fetchData(this.userID);
      this.fetchEmergencyContacts(this.userID);
    }
  }

  isCurrentUser() {
    return this.userID === this._login.getUserID();
  }

  fetchData(userID: number) {   
    const context = this;
    this._http
      .get<any>(
      getHostUrl() +
          'user?userID=' +
          userID,
    )
      .subscribe(function (data) {
        context.userInfo = data;
        setTimeout(() => {
          context._config.setTitle(`${context.userInfo.name}`);
          context.showLoader = false;
          context._ref.detectChanges();
        }, 400);
      });
  }

  fetchEmergencyContacts(userID: number) {
    const context = this;
    this._http.get<any>(getHostUrl() + 'emergencycontact?targetID=' + userID).subscribe(function (data) {
      context.emergencyContactList = data;
    });
  }

  openMenu(userID: number, name: string) {
    this._bottomSheet.open(AdminUserMenusheetComponent, { data: { userID, name } });
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }
}
