<h3 class="mb-5 mt-3 text-center" *ngIf="currentCourse">{{ currentCourse?.name }}</h3>

<div layout="column" layout-align="space-between center" *ngIf="isLoading"
  style="display:flex;justify-content:center;align-items:center;">
  <mat-spinner color="primary"></mat-spinner>
</div>

<div [hidden]="isLoading">
  <mat-form-field class="input-full-width" appearance="outline" [hidden]="this._data.course">
    <mat-label>Ereignis auswählen</mat-label>
    <mat-select (selectionChange)="selectedCourse($event)" [formControl]="courseSelect" id="courseDropdown">
      <mat-select-trigger>{{ currentCourse?.name }}</mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search [formControl]="courseFilterCtrl"></ngx-mat-select-search>
      </mat-option>
      <mat-optgroup *ngFor="let category of courseFilter | async" [label]="category.name">
        <mat-option *ngFor="let course of category.data" [value]="course">
          {{ course.name }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>


  <form [formGroup]="group" class="row" *ngIf="currentCourse">

    <div class="col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Beschreibung</mat-label>
        <input matInput formControlName="comment" />
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="input-full-width">
        <mat-label>Datum</mat-label>
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <input matInput [matDatepicker]="pickerStart" formControlName="date" [max]="max" />
        <mat-datepicker #pickerStart></mat-datepicker>
        <mat-hint>DD.MM.JJJJ</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <input #FileSelectInputDialog type="file" multiple style="display: none" formControlName="file"
            (change)="uploadAttachments($event)" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" />
          <button mat-raised-button color="primary" class="input-full-width" (click)="openFileDialog()"
            [disabled]="attachments.length >= 1">
            Bescheinigung hinzufügen<span *ngIf="_data.personal">*</span>
            <mat-icon class="ms-1">attach_file</mat-icon>
          </button>
        </div>
        <div class="col-6">
          <button mat-raised-button color="primary" class="input-full-width" (click)="save()"
            [disabled]="group.invalid">
            Speichern
            <mat-icon class="ms-1">save</mat-icon>
          </button>
        </div>
      </div>

      <div class="row" *ngFor="let file of attachments; let i = index" style="margin-top: 15px;">
        <div class="col-lg-12">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Dateiname</mat-label>
            <input [value]="file.filename" matInput disabled />
            <div matSuffix class="inline-icons">
              <mat-icon class="pointer me-3" (click)="openPreview(file)">visibility</mat-icon>
              <mat-icon class="pointer" (click)="removeAttachment(i)">remove_circle</mat-icon>
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>