import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GuardUserStatus, GuardUser, DaysShort } from '@ffo/mitgliederbereich-types';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { environment } from 'src/environments/environment';

const days: DaysShort[] = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
@Component({
  selector: 'app-admin-guards-personal-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css'],
})
export class AdminGuardsPersonalDetailsComponent implements OnInit {

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private userData: GuardUser,
    private _route: ActivatedRoute, private _http: HttpClient, private _config: ConfigModule, private _ref: MatDialogRef<AdminGuardsPersonalDetailsComponent>) { }

  group: UntypedFormGroup;
  initData: GuardUser;
  dialogComponent: boolean = false;

  env = environment;
  ngOnInit(): void {
    if (this.userData && this.userData.userID) {
      this.setUserData(this.userData);
      this.dialogComponent = true;
    } else this.loadUserData();
  }

  setUserData(data: GuardUser): void {
    this.group = new UntypedFormGroup({
      userID: new UntypedFormControl({ value: data.userID, disabled: true }),
      name: new UntypedFormControl({ value: `${data.firstname} ${data.lastname}`, disabled: true }),
      active: new UntypedFormControl(data.active),
      state: new UntypedFormControl(data.state),
      days: new UntypedFormControl({ value: data.days, disabled: data.state === '' }),
      comment: new UntypedFormControl({ value: data.comment, disabled: data.state === '' }),
    });
    this.group.get('state').valueChanges.subscribe((state: GuardUserStatus) => {
      if (state === '') {
        this.group.get('active').disable();
        this.group.get('days').disable();
        this.group.get('comment').disable();
      } else {
        this.group.get('active').enable();
        this.group.get('days').enable();
        this.group.get('comment').enable();
        if (!this.group.get('days').value || this.group.get('days').value[0] === '')
          this.group.get('days').setValue(days);
      }
    });
    this.initData = data;
  }

  loadUserData(): void {
    const userID = this._route.snapshot.params.userId;
    this._http.get<GuardUser>(getHostUrl() + `admin/fields/guards/user?userID=${userID}`)
      .subscribe(data => {
        this.setUserData(data);
      });
  }

  saveData(): void {
    let path = 'update';
    if (this.initData.state === '' && this.group.get('state').value !== '') {
      path = 'new';
    } else if (this.initData.state !== '' && this.group.get('state').value === '') {
      path = 'delete';
    }
    this._http.post(getHostUrl() + `admin/fields/guards/user/${path}`, this.group.getRawValue()).subscribe(() => {
      this._config.showSnackbar('Benutzerdaten gespeichert', 2500, 'success');
      if (this.dialogComponent) this._ref.close();
    }, error => {
      console.error(error);
      this._config.showSnackbar('Es ist ein Fehler aufgetretenQ', 2500, 'error');
    });
  }
}
