<h2 mat-dialog-title>Prüfungsdatum verschieben</h2>
<mat-dialog-content>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Neues Datum</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="date" [min]="min" [max]="max" placeholder="Wählen Sie ein Datum" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="clearfix w-100">
    <button mat-raised-button class="float-end me-3" color="accent" (click)="save()" [disabled]="date.invalid">Verschieben</button>
  </div>
</mat-dialog-actions>
