import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EventCalender } from '@ffo/mitgliederbereich-types';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { getHostUrl } from 'src/app/util/config';
import { RouteGuard } from 'src/app/util/guards/route.guard';
import { WebConfigService } from 'src/app/util/services/webconfig/webconfig.service';
import { BottomSheetEventInfoComponent } from 'src/app/util/sheets/calendar-info/calendar-info-sheet.componet';
import { CustomDateFormatter } from '../custom-date-formatter.provider';
import { environment } from 'src/environments/environment';
import { Login } from 'src/app/util/login';

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['../event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    RouteGuard,
  ],
})
export class EventCalendarComponent implements OnInit {

  @Input() url: string;

  env = environment;
  constructor(private _bottomSheet: MatBottomSheet,
    private _configService: WebConfigService,
    private _login: Login,
    private _http: HttpClient) { }

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  events: CalendarEvent<EventCalender>[] = [];
  scheduleList: EventCalender[] = [];
  holidayList: EventCalender[] = [];
  refresh: Subject<any> = new Subject();

  showLoader: boolean = true;

  colors: any = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    blue: {
      primary: '#4462f3',
      secondary: '#D1E8FF',
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
    green: {
      primary: '#00b300',
      secondary: '#d2fdba',
    },
    purple: {
      primary: '#9933ff',
      secondary: '#cc99ff',
    },
    orange: {
      primary: '#FFA500',
      secondary: '#d98f06',
    },
    gray: {
      primary: '#a5aaad',
      secondary: '#74787a',
    },
  };

  ngOnInit(): void {
    this.loadCalendar();
    this.applyHashLocation();
    this.getHolidays();
  }

  getHolidays() {
    this._configService.getConfig().subscribe(config => {
      const homeState = config.find(s => s.name == 'home_location_state').value;
      /*
      * API Documentation
      * https://date.nager.at/Api
      */
      this._http.get<any>(`https://date.nager.at/api/v3/PublicHolidays/${dayjs().format('YYYY')}/DE`)
        .subscribe(result => {
          let holidays = result.filter(holiday => !holiday.counties || holiday.counties.includes(homeState));
          holidays = holidays.map(holiday => {
            const event: CalendarEvent<EventCalender> = {
              title: holiday.localName,
              start: new Date(holiday.date),
              end: new Date(holiday.date),
              color: this.colors.orange,
              allDay: true,
              meta: {
                title: holiday.localName,
                start: new Date(holiday.date),
                end: new Date(holiday.date),
                color: this.colors.orange,
                street: '',
                appartment: '',
                city: '',
                zipcode: 0,
                allDay: true,
                country: 'Deutschland',
                pageLink: '',
                status: 'sichtbar',
                type: 'holiday',
                orga: 'foundation'
              },
            };
            return event;
          });
          this.events = this.events.concat(holidays);
        });
    });
  }

  loadCalendar() {
    this._http.get<EventCalender[]>(`${getHostUrl()}${this.url}`).subscribe((data) => {
      this.scheduleList = data;
      this.refresh.next(null);
      let eventList = this.scheduleList.map((calEvent: EventCalender) => {
        const event: CalendarEvent<EventCalender> = {
          title: calEvent.title,
          start: new Date(calEvent.start),
          end: new Date(calEvent.end),
          color: this.colors[calEvent.color],
          allDay: calEvent.allDay,
          meta: calEvent
        };
        return event;
      });
      this.events = this.events.concat(eventList);
      this.showLoader = false;
    });
  }

  getSessionStorage() {
    const item = JSON.parse(sessionStorage.getItem('eventCalendar-' + this._login.getUserID()) ?? '{}');

    return {
      month: item?.month ?? new Date().getMonth(),
      year: item?.year ?? new Date().getFullYear()
    }
  }

  applyHashLocation() {
    this.viewDate.setMonth(this.getSessionStorage().month);
    this.viewDate.setFullYear(this.getSessionStorage().year);
  }

  setHashLocation() {
    sessionStorage.setItem('eventCalendar-' + this._login.getUserID(), JSON.stringify({
      month: this.viewDate.getMonth(), year: this.viewDate.getFullYear()
    }));
  }

  dayClicked(events: CalendarEvent<EventCalender>[]): void {
    if (events.length === 0)
      return;
    this._bottomSheet.open(BottomSheetEventInfoComponent, {
      data: {
        index: 0,
        events: events,
      },
    });
  }

  eventClicked(event: CalendarEvent<EventCalender>): void {
    this._bottomSheet.open(BottomSheetEventInfoComponent, {
      data: {
        events: [].push(event),
      },
    });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  getMultiDayCssClasses(_day: any, _event: CalendarEvent<EventCalender>): string {
    const day = DateTime.fromJSDate(_day.date).set({ hour: 0, minute: 0 });
    const start = DateTime.fromJSDate(_event.start).set({ hour: 0, minute: 0 });
    const end = DateTime.fromJSDate(_event.end).set({ hour: 0, minute: 0 });

    let cssClasses = '';

    if (start < day) {
      cssClasses += 'eventStart ';
    }

    if (end > day) {
      cssClasses += 'eventEnd ';
    }
    return cssClasses;
  }
}
