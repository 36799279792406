import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

import * as relativeTime from 'dayjs';
import Swal from 'sweetalert2';
import { Login } from 'src/app/util/login';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChecksCycleDateChangeComponent } from './checks-cycle-date-change/checks-cycle-date-change.component';

dayjs.extend(relativeTime)

@Component({
  selector: 'app-check-cycles-detail',
  templateUrl: './check-cycles-detail.component.html',
  styleUrls: ['./check-cycles-detail.component.css']
})
export class CheckCyclesDetailComponent implements OnInit {
  isLoading = true;
  cycleId = null;
  isSaving = false;
  isPastCheck = false;

  baseURL = getHostUrl();
  isPWA = false;

  data = {};
  dayjs = dayjs;

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;
  fileList = [];

  constructor(private _config: ConfigModule,
    private _route: ActivatedRoute,
    private _http: HttpClient,
    public _login: Login,
    private _dialog: MatDialog,
    private _builder: FormBuilder,
    private _router: Router) { }

  group: FormGroup;

  ngOnInit(): void {
    this.cycleId = this._route.snapshot.params?.cycleId;
    this.isPWA = window.matchMedia('(display-mode: standalone)').matches;

    this._config.setTitle('Prüfung ansehen');
    this.loadCheckCycle();

    this.group = this._builder.group({
      status: ['open', Validators.required],
      comment: ['']
    });

    this.group.get('status').valueChanges.subscribe(result => {      
      const field = this.group.get('comment');
      switch (result) {
        case 'failed':          
          field.setValidators(Validators.required);
          break;
        default:
          field.clearValidators();
      }
      setTimeout(() => {
        field.updateValueAndValidity();
        field.markAsTouched();
      });
    });
  }

  async loadCheckCycle() {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/checks/cycles/${this.cycleId}`));
      this.data = result;
      this.fileList = result.attachements;
      this.isPastCheck = result.status == 'open' && dayjs(result.date).isBefore(dayjs());

      this.group.patchValue({
        status: result.status,
        comment: result.comments,
      });

      if (result.status != 'open') {
        this.group.disable();
      }
    } catch (e) {
      if (e.status == 404) {
        this._config.showSnackbar('Prüfung wurde nicht gefunden!', 3000, 'error');
        this._router.navigate(['material/checks/cycles']);
      } else {
        this._config.showSnackbar('Ein Fehler ist aufgetreten, bitte versuche es erneut!', 3000, 'error');
      }
    }
    this.isLoading = false;
  }

  mapStatus(status) {
    switch (status) {
      case 'open':
        return 'Offen';
      case 'closed':
        return 'Abgeschlossen';
      case 'cancelled':
        return 'Abgebrochen';
    }
  }

  async save() {
    this.isSaving = true;
    try {
      const result = await firstValueFrom(this._http.post(`${getHostUrl()}admin/material/checks/cycles/${this.cycleId}`, this.group.value));
      await this.loadCheckCycle();
      this._config.showSnackbar('Prüfung wurde abgeschlossen!', 3000, 'success');
    } catch (e) {
      this._config.showSnackbar('Das Abschließen der Prüfung ist fehlgeschlagen!', 3000, 'error');
    }
    this.isSaving = false;
  }

  attachFile() {
    const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
    e.click();
  }

  uploadAttachments($event) {
    let fileList: FileList = $event.target.files;
    this.blobToBase64(fileList.item(0)).then((fileContent: any) => {
      const file = { 'name': fileList.item(0).name, 'content': fileContent, 'id': null, 'error': false };
      this.FileSelectInputDialog.nativeElement.value = null;
      this.fileList.push(file);
      this.uploadFile();
    });
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  async uploadFile() {
    let file = this.fileList.find(file => file.id === null && file.error === false);
    if (!file) {
      return;
    }
    try {
      const result = await firstValueFrom(this._http.post<any>(`${getHostUrl()}admin/material/checks/cycles/${this.cycleId}/file/upload`, {
        alarmID: this.cycleId,
        fileName: file.name,
        fileContent: file.content
      }));
      this.fileList[this.fileList.findIndex(file_ref => file === file_ref)].id = result.fileId;
    } catch (e) {
      this.fileList[this.fileList.findIndex(file_ref => file === file_ref)].error = true;
      this._config.showSnackbar(e.error, 3000, 'error');
    }
    setTimeout(() => {
      this.uploadFile();
    });
  }

  async deleteAttachement(file) {
    const result = await Swal.fire({
      title: 'Anhang löschen?',
      text: 'Soll dieser Anhang wirklich gelöscht werden?',
      icon: 'question',
      showDenyButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      confirmButtonText: 'Ja, löschen!',
      denyButtonText: 'Nein'
    });

    if (result.isConfirmed) {
      try {
        file.isDeleting = true;
        const result = await firstValueFrom(this._http.delete(`${getHostUrl()}admin/material/checks/cycles/${this.cycleId}/file/${file.id}`));
        this.fileList.splice(this.fileList.findIndex(file_ref => file_ref.id == file.id), 1);
        this._config.showSnackbar('Anhang wurde gelöscht!', 3000, 'success');
      } catch (e) {
        file.isDeleting = false;
        this._config.showSnackbar(e.error, 3000, 'error');
      }
    }
  }

  replanDate() {
    const dialog = this._dialog.open(ChecksCycleDateChangeComponent, {
      data: {
        cycle: this.data
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.loadCheckCycle();
      }
    });
  }
}
