import { HttpClient } from '@angular/common/http';
import { Component, Host, OnInit } from '@angular/core';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { AlarmsVehiclesDialogComponent } from '../alarms-vehicles-dialog.component';

@Component({
  selector: 'app-alarms-vehicle-dialog-information',
  templateUrl: './alarms-vehicle-dialog-information.component.html',
  styleUrls: ['./alarms-vehicle-dialog-information.component.css'],
})
export class AlarmsVehicleDialogInformationComponent implements OnInit {

  constructor(@Host() public _host: AlarmsVehiclesDialogComponent,
    private _http: HttpClient,
    private _config: ConfigModule) { }

  showDistanceLoader = false;

  vehicleFunctions = [];

  hasError = true;

  ngOnInit(): void {
    this.generateFunctions();
    this.checkError();
  }

  generateFunctions() {
    if (this._host._data.vehicle.hasPump) {
      this.vehicleFunctions.push({
        name: 'Fahrzeugpumpe',
        id: 1,
      });
    }
    /*if (this._host._data.vehicle.hasGenerator) {
      this.vehicleFunctions.push({
        name: 'Generator',
        id: 2,
      });
    }*/
    if (this._host._data.vehicle.hasLadder) {
      this.vehicleFunctions.push({
        name: 'Drehleiter Fahrzeug',
        id: 3,
      });
    }
  }

  calculateDistance() {
    this.showDistanceLoader = true;
    this._http.get<any>(`${getHostUrl()}alarms/distance?alarmID=${this._host._data.alarmId}`).subscribe(result => {
      this._host._data.data.distance = result.distance;
      this.showDistanceLoader = false;
      this.checkError();
    }, error => {
      this.showDistanceLoader = false;
      this._config.showSnackbar('Konnte Distanz nicht berechnen!', 3000, 'error');
    });
  }

  selectedFunctions($event) {
    if ($event.value.length > 0) {
      this._host._data.data.used = true;
    }
  }

  checkError() {
    if (this._host._data.data.distance) {
      this.hasError = false;
    }
  }
}
