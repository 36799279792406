<mat-form-field class="input-full-width" appearance="outline">
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche" />
</mat-form-field>
<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 input-full-width" matSort
    matSortDirection="desc" matSortDisableClear>
    <ng-container *ngFor="let container of displayedColumns" [matColumnDef]="container">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{container.replace('_', ' ')}}</th>
      <td mat-cell *matCellDef="let element">
        {{element[container]}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="row['meta_link']" [class.clickable]="row['meta_link']"></tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="5" showFirstLastButtons></mat-paginator>