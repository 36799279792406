import { getHostUrl } from 'src/app/util/config';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import * as $ from 'jquery';
import { Country } from '@ffo/mitgliederbereich-types';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.css'],
})
export class AddressDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  group = new UntypedFormGroup({});
  searchResults = [];

  addressObj = null;

  countries: Country[] = [];
  countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  countryFilter: ReplaySubject<Country[]> = new ReplaySubject<Country[]>();

  cities: boolean = false;

  protected _onDestroy = new Subject<void>();

  constructor(private _http: HttpClient,
    private _formBuilder: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private DIALOG_DATA: any) {
    this.cities = DIALOG_DATA.cities;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this.group = this._formBuilder.group({
      search: ['', Validators.required],
      country: ['', Validators.required],
    });
    this.group.get('search').disable();

    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });

    this.group.get('country').valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.group.get('search').setValue('');
      });

    this.group.get('search').valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        takeUntil(this._onDestroy),
      ).subscribe(data => {
        if (!data) {
          return;
        }
        if (this.addressObj &&
          data === `${this.addressObj.street} - ${this.addressObj.city}` && !this.cities) {
          return;
        }
        if (this.addressObj &&
          data === `${this.addressObj.city} - ${this.addressObj.zipcode}` && this.cities) {
          return;
        }
        this.addressObj = null;
        this.filterStreet();
      });

    this.getCountries();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $('#address_search').trigger('focus');
    }, 200);
  }

  apply(obj) {
    this.addressObj = obj;
    if (!this.cities)
      this.group.get('search').setValue(`${obj.street} - ${obj.city}`);
    if (this.cities)
      this.group.get('search').setValue(`${obj.city} - ${obj.zipcode}`);
    this.closure();
  }

  closure() {
    this._dialogRef.close(this.addressObj);
  }

  getCountries() {
    this._http.get<Country[]>(`${getHostUrl()}datainfo/countries`)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(data => {
        this.countries = data;
        this.group.get('country').setValue(this.countries.find(c => c.name === 'Deutschland'));
        this.group.get('search').enable();
        this.countries.splice(this.countries.findIndex(c => c.name === 'na'), 1);
        this.countryFilterCtrl.updateValueAndValidity();
      });
  }

  protected filterCountries() {
    if (!this.countries) {
      return;
    }
    let search: string = this.countryFilterCtrl.value;
    if (!search) {
      this.countryFilter.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.countryFilter.next(
      this.countries.filter((country: Country) => country.name.toLowerCase().indexOf(search) > -1));
  }

  filterStreet() {
    const search = this.replaceText(this.group.get('search').value || '');
    const country = this.replaceText(this.group.get('country').value.name);

    if (search === '') {
      return;
    }
    this._http.get<any[]>(`${getHostUrl()}datainfo/here/search?country=${country}&search=${search}&cities=${this.cities}`)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(data => {        
        this.searchResults = data;
      }, error => console.log(error));
  }

  checkIfAlreadyPresent(obj) {
    let present = false;
    this.searchResults.forEach(elem => {
      if (elem.street === obj.street &&
        elem.city === obj.city &&
        elem.zipcode === obj.postalCode) {
        present = true;
      }
    });
    return present;
  }

  replaceText(text: string) {
    return text.replace('ß', 'ss').replace(' ', '%20').replace('ä', 'ae').replace('ö', 'oe').replace('ü', 'ue').replace('Ä', 'Ae').replace('Ö', 'Oe').replace('Ü', 'Ue');
  }
}
