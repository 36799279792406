import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Login } from '../../util/login';
import { HttpClient } from '@angular/common/http';
import { getHostUrl, ConfigModule, getHost, getHostname } from '../../util/config';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {

  @ViewChild('downloadCalendar', { static: false }) downloadCalendar: TemplateRef<any>;

  calendarTokenWebcal: SafeUrl;

  calendarTokenHttps: string;

  env = environment;

  constructor(private _login: Login,
    private _http: HttpClient,
    private _bottomSheet: MatBottomSheet,
    private _configService: ConfigModule,
    private _sanitizer: DomSanitizer) { }



  ngOnInit() {
    this._configService.setTitle('Startseite');
    this.getCalendarToken();
  }

  ngOnDestroy() {
    this._bottomSheet.dismiss();
  }

  getCalendarToken() {
    this._http.get<any>(`${getHostUrl()}profile`).subscribe(data => {
      this.parseToken(data.calendarToken);
    });
  }

  parseToken(token: string) {
    this.calendarTokenWebcal = this._sanitizer.bypassSecurityTrustUrl(`webcal://${getHostname()}api/calendar/sync?token=${token}`);
    this.calendarTokenHttps = `${getHostUrl()}calendar/sync?token=${token}`;
  }

  copyClipboard() {
    this._bottomSheet.dismiss();
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.calendarTokenHttps;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._configService.showSnackbar('URL wurde in die Zwischenablage kopiert', 2000, 'success');
  }

  openDownloadSheet() {
    this._bottomSheet.open(this.downloadCalendar);
  }
}

