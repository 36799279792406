<div class="row">
  <div class="col-lg-2 col-md-3">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Stammnummer</mat-label>
      <input type="number" [(ngModel)]="parent.userInfo.firedata.id" matInput disabled>
    </mat-form-field>
  </div>
  <div class="col-lg-10 col-md-9">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Dienstrang</mat-label>
      <input type="text" [(ngModel)]="parent.userInfo.firedata.officialRank" matInput disabled>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Ehrenamtskartentyp</mat-label>
      <mat-select [(ngModel)]="parent.userInfo.firedata.voluntaryCardType" required disabled>
        <mat-option value="0">--</mat-option>
        <mat-option value="1">Blau</mat-option>
        <mat-option value="2">Gold</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-12">
    <mat-form-field id="voluntaryCardDate" class="input-full-width" appearance="outline">
      <mat-label
        *ngIf="parent.userInfo.firedata.voluntaryCardType === '1' || parent.userInfo.firedata.voluntaryCardType === '0'">
        Ablaufdatum
      </mat-label>
      <mat-label *ngIf="parent.userInfo.firedata.voluntaryCardType === '2'">Ausstellungsdatum</mat-label>
      <input matInput [(ngModel)]="parent.userInfo.firedata.voluntaryCardDate" disabled>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-md-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Spindreihe</mat-label>
      <input [(ngModel)]="parent.userInfo.firedata.lockerRow" matInput disabled>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-md-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Spind</mat-label>
      <input [(ngModel)]="parent.userInfo.firedata.locker" matInput disabled>
    </mat-form-field>
  </div>
  <mat-checkbox [checked]="parent.userInfo.firedata.dsgvo" [(ngModel)]="parent.userInfo.firedata.dsgvo" disabled
    color="primary">Unterschrift DSGVO</mat-checkbox>
  <br />
  <mat-checkbox [checked]="parent.userInfo.firedata.personalPhotos"
    [(ngModel)]="parent.userInfo.firedata.personalPhotos" disabled color="primary">Unterschrift Veröffentlichung
    Bilder</mat-checkbox>
</div>