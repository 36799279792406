<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">CronJobs</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>

        <mat-form-field class="input-full-width" appearance="outline">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
        <div class="table-responsive">
          <mat-progress-bar mode="query" *ngIf="showLoader"></mat-progress-bar>
          <table mat-table [dataSource]="dataSource" matSort class="input-full-width" matSortActive="name"
            matSortDirection="asc" matSortDisableClear>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 200px; padding-right: 15px">
                CronJob Name
              </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Last Run Column -->
            <ng-container matColumnDef="lastrun">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 150px; padding-right: 15px">
                Zuletzt gelaufen
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.lastrun ? dayjs(element.lastrun).format('DD.MM.YY HH:mm:ss') : 'n/a'}}
              </td>
            </ng-container>

            <!-- CronTab Column -->
            <ng-container matColumnDef="crontab">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 100px; padding-right: 15px">
                CronTab
              </th>
              <td mat-cell *matCellDef="let element"> {{element.cron}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 150px; padding-right: 15px">
                Status
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-slide-toggle [checked]="element.enabled===1" (change)="toggleCronjob($event, element)">{{
                  element.enabled===0 ? 'Deaktiviert' : 'Aktiviert'}}</mat-slide-toggle>
              </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <a href="javascript:void(0);" (click)="deleteJob(element)" mat-icon-button color="warn"
                  [disabled]="showLoader">
                  <mat-icon>delete</mat-icon>
                </a>
                <a href="javascript:void(0);" (click)="editCronjob(element)" mat-icon-button [disabled]="showLoader">
                  <mat-icon>edit</mat-icon>
                </a>
                <a href="javascript:void(0);" matTooltip="Jetzt ausführen!" (click)="playJob(element)" mat-icon-button
                  [disabled]="showLoader">
                  <mat-icon>play_arrow</mat-icon>
                </a>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #menu>
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
    <mat-icon>close</mat-icon>
  </a>
  <mat-nav-list class="mt-2">
    <mat-list-item (click)="editCronjob(null)">
      <h3 matListItemLine>Neuer CronJob</h3>
      <div matListItemMeta>
        <mat-icon>more_time</mat-icon>
      </div>
    </mat-list-item>
    <mat-list-item (click)="openJobHistory()">
      <h3 matListItemLine>Job Historie anzeigen</h3>
      <div matListItemMeta>
        <mat-icon>history</mat-icon>
      </div>
    </mat-list-item>
  </mat-nav-list>
</ng-template>