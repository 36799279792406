<form [formGroup]="group">
    <mat-accordion class="m-2">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="me-2">description</mat-icon> Einsatzbericht
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-12">
                    <label>Bericht:</label>
                    <quill-editor formControlName="report" type="divarea"></quill-editor><br />
                    <span *ngIf="!group.get('report').valid" class="mat-error">
                        Bericht muss ausgefüllt sein!
                    </span>
                </div>
                <div class="col-md-4 col-12">
                    <mat-slide-toggle color="accent" formControlName="accidentFirefighter">verunfallte Einsatzkraft
                    </mat-slide-toggle>
                </div>
                <div class="col-md-4 col-12">
                    <mat-slide-toggle color="accent" formControlName="damageMaterial">Beschädigung
                        Einsatzmaterial/-fahrzeug
                    </mat-slide-toggle>
                </div>
                <div class="col-md-4 col-12">
                    <mat-slide-toggle color="accent" formControlName="stressfulAlarm">belastender Einsatz
                    </mat-slide-toggle>
                </div>
            </div>

        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="me-2">attach_file</mat-icon> Einsatz Anhänge ({{ fileList.length }})
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-list class="input-full-width mt-3 mb-2" *ngIf="fileList.length > 0">
                <mat-list-item *ngFor="let file of fileList">
                    <div class="clearfix input-full-width">
                        <span class="float-left" *ngIf="!file.id">{{file.name}}</span>
                        <a *ngIf="file.id" class="float-left" [target]="isPWA ? '_self' : '_blank'"
                            href="{{baseURL}}alarms/report/upload/{{this._host.alarmID}}/{{file.id}}?sessionToken={{_login.getSessionToken()}}">{{file.name}}</a>
                        <div class="float-right">
                            <div *ngIf="!file.id && !file.error" class="clearfix">
                                <span class="float-left me-3">Wird hochgeladen ...</span>
                                <mat-spinner [diameter]="30" style="margin-top: -8px" class="float-right"></mat-spinner>
                            </div>
                            <div *ngIf="file.error" class="clearfix">
                                <span class="float-left me-3" style="color: red;">Fehlerhaft</span>
                                <mat-icon style="color: red;" class="float-right">close</mat-icon>
                            </div>
                            <div *ngIf="file.id" class="clearfix">
                                <button mat-icon-button (click)="deleteAttachement(file)" [disabled]="file.isDeleting"
                                    *ngIf="!this._host.disabledElements">
                                    <mat-icon color="warn">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>

            <input #FileSelectInputDialog type="file" multiple style="display: none"
                (change)="uploadAttachments($event)"
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" />
            <button mat-raised-button color="primary" (click)="attachFile()" *ngIf="!this._host.disabledElements">
                <mat-icon class="me-2">attach_file_add</mat-icon>
                Anhang hinzufügen
            </button>
        </mat-expansion-panel>
    </mat-accordion>
</form>