import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Guard, GuardAssignmentPosition, GuardBilling, GuardLocation, GuardStatus, GuardUser } from '@ffo/mitgliederbereich-types';
import { DateAdapter } from '@angular/material/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { CustomDateAdapterService } from 'src/app/util/customDateAdapter';
import { environment } from 'src/environments/environment';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { translatePosition } from '../../guards.component';

@Component({
  selector: 'app-admin-guards-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService }],
})
export class AdminGuardsEditComponent implements OnInit, AfterViewInit {

  guard: Guard | GuardBilling;
  group: FormGroup;
  newGuard: boolean = false;

  locations: GuardLocation[] = [];
  locationFilterCtrl: FormControl<string> = new FormControl();
  locationFilter: ReplaySubject<GuardLocation[]> = new ReplaySubject<GuardLocation[]>();

  protected _onDestroy = new Subject<void>();
  disabled: boolean = false;

  updateBody = [];
  switchGuard = {
    position: '',
    positionID: -1,
    name: ''
  };
  guardUserList: GuardUser[] = [];
  guardReplacementControl: FormControl<GuardUser> = new FormControl();
  filterdGuardList: Observable<GuardUser[]>;

  @ViewChild('switchGuardDialog') switchGuardDialog: TemplateRef<any>;

  guardID: number;
  env = environment;
  timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  constructor(private _http: HttpClient, private _config: ConfigModule, private _route: ActivatedRoute, private _router: Router, private _builder: FormBuilder, private _dialog: MatDialog) { }

  ngOnInit(): void {
    this.guardID = this._route.snapshot.params.guardId;
    this.newGuard = this._route.snapshot.queryParams.new !== null && this._route.snapshot.queryParams.new !== undefined;
    if (this.guardID) {
      this.initEdit();
    } else {
      this.initNew();
    }
    this.group = this._builder.group({
      id: 0,
      name: ['', Validators.required],
      location: [1, Validators.required],
      date: [new Date(), Validators.required],
      guardStart: ['', Validators.compose([Validators.required, Validators.pattern(this.timePattern)])],
      guardEnd: ['', Validators.compose([Validators.required, Validators.pattern(this.timePattern)])],
      comment: '',
      billingStart: '',
      billingEnd: '',
      status: new FormControl<GuardStatus>('angelegt', Validators.required)
    })

    this.loadGuardLocations();
    this.locationFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterLocations());
  }

  ngAfterViewInit(): void {
    this.filterdGuardList = this.guardReplacementControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filterUsers(value)),
    );
  }

  private initNew(): void {
    this._config.setTitle('Sicherheitswachen | Neu');
  }

  private initEdit(): void {
    this._config.setTitle('Sicherheitswachen | Bearbeiten');
    this.loadGuard();
    this.loadGuardUsers();
  }

  private loadGuardLocations() {
    this._http.get<GuardLocation[]>(`${getHostUrl()}datainfo/guardLocations`).subscribe((data) => {
      this.locations = data;
      this.locationFilterCtrl.updateValueAndValidity();
    });
  }

  private loadGuard() {
    this._http.get<Guard | GuardBilling>(`${getHostUrl()}admin/fields/guards/guards/info?guardID=${this.guardID}`).subscribe((data) => {
      this.guard = {
        ...data,
        date: new Date(data.date),
      };
      this.group.patchValue(this.guard);
      if (this.guard.status !== 'angelegt' && this.guard.status !== 'eingeteilt' && this.guard.status !== 'in Einteilung') {
        this.group.disable();
        this.disabled = true;
      }
    });
  }

  private filterLocations() {
    if (!this.locations) {
      return;
    }

    let search = this.locationFilterCtrl.value;
    if (!search) {
      this.locationFilter.next(this.locations);
      return;
    } else {
      search = search.toLowerCase();
    }

    this.locationFilter.next(this.locations.filter(loc => loc.name.toLowerCase().indexOf(search) > -1 || loc.location.street.toLowerCase().indexOf(search) > -1 || loc.location.city.toLowerCase().indexOf(search) > -1 || loc.location.zipcode.toString().indexOf(search) > -1));
  }

  cancel(): void {
    this._http.post(`${getHostUrl()}admin/fields/guards/guards/cancel`, { id: this.guardID }).subscribe((resp) => {
      if (this.guard?.id) {
        this._config.showSnackbar('Veranstaltung gelöscht', 2500, 'success');
        this._router.navigate([`/admin/fields/guards/guards/`]);
      }
    }, (error) => {
      this._config.showSnackbar('Fehler beim Absagen', 2500, 'error');
    });
  }

  save(next: boolean): void {
    this._http.post<Guard | GuardBilling>(`${getHostUrl()}admin/fields/guards/guards/save`, { guard: this.group.getRawValue(), accounting: false }).subscribe((resp) => {
      if (this.guard?.id) {
        this._config.showSnackbar('Veranstaltung gespeichert', 2500, 'success');
      } else {
        this._config.showSnackbar('Veranstaltung angelegt', 2500, 'success');
        if (!next) {
          this._router.navigate([`/admin/fields/guards/guards/details/${resp.id}`], { queryParams: { 'new': true } });
        } else {
          this.group.patchValue({
            id: 0,
            name: '',
            location: 1,
            date: new Date(),
            guardStart: '',
            guardEnd: '',
            comment: '',
            billingStart: '',
            billingEnd: '',
            status: 'angelegt'
          });
          this.group.markAsPristine();
          this.group.markAsUntouched();
        }
      }
    }, (error) => {
      this._config.showSnackbar('Fehler beim Speichern', 2500, 'error');
    });
  }

  parseTimeField($event, fieldName, nextField = null, previousField = null) {
    if ($event.keyCode === 8 || $event.keyCode === 46 || $event.keyCode === 9 || $event.keyCode === 16) {
      return;
    }
    const value: string = this.group.get(fieldName).value;

    if (value.match(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#´]/)) {
      this.group.get(fieldName).setValue(value.replace(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#]/g, ''));
    }

    if (value.length > 5) {
      this.group.get(fieldName).setValue(value.substring(0, 5));
      return;
    }
    if (value.match(this.timePattern) && nextField) {
      $('#' + nextField).trigger('focus');
      return;
    }
    if (!value.includes(':')) {
      if (value.length === 2) {
        this.group.get(fieldName).setValue(value + ':');
      }
    }
    return true;
  }

  getPosition(position: number): string {
    const user: GuardAssignmentPosition = this.guard.assignment?.assigned.find((x: GuardAssignmentPosition) => parseInt(x.position + '') === position);
    return user ? user.name : '';
  }

  getPositionReplacement(position: number): string {
    const user = this.updateBody.find((x) => parseInt(x.position + '') === position || parseInt(x.positionNew + '') === position);
    return user ? user.name : '';
  }

  getPositionUserID(position: number): number {
    const user: GuardAssignmentPosition = this.guard.assignment?.assigned.find((x: GuardAssignmentPosition) => parseInt(x.position + '') === position);
    return user ? user.userID : -1;
  }

  replacePosition(position: number): void {
    this.switchGuard.name = this.getPosition(position);
    this.switchGuard.position = translatePosition(position);
    this.switchGuard.positionID = position;
    this._dialog.open(this.switchGuardDialog);
  }

  loadGuardUsers(): void {
    this._http.get<GuardUser[]>(getHostUrl() + 'admin/fields/guards/assignment/users?guard=' + this.guardID)
      .subscribe(data => {
        this.guardUserList = data;
      });
  }

  private _filterUsers(value: string): GuardUser[] {
    if (typeof (value) === 'string') {
      const filterValue = value.toLowerCase();
      return this.guardUserList.filter((user: GuardUser) => { return user.userID.toString().startsWith(filterValue) || user.firstname.toLowerCase().startsWith(filterValue) || user.lastname.toLowerCase().startsWith(filterValue); });
    }
    return [];
  }

  displayFn(user: GuardUser): string {
    return user && user.firstname && user.lastname && user.state && user.userID ? (`${user.state} - ${user.userID} - ${user.firstname} ${user.lastname}`) : '';
  }

  updateGuardAssignment(): void {
    this.updateBody.push({
      guard: this.guardID,
      userID: this.guardReplacementControl.value.userID,
      name: this.guardReplacementControl.value.firstname + ' ' + this.guardReplacementControl.value.lastname,
      userIDold: this.getPositionUserID(this.switchGuard.positionID),
      position: this.switchGuard.name !== '' ? this.switchGuard.positionID : null,
      positionNew: this.switchGuard.name === '' ? this.switchGuard.positionID : null
    });
    this.update();
  }

  update(): void {
    this._http.post(getHostUrl() + 'admin/fields/guards/assignment/update', { body: this.updateBody })
      .subscribe({
        next: (data) => {
          this._http.post(getHostUrl() + 'admin/fields/guards/assignment/send', { guards: [this.guardID], admin: false, update: true })
            .subscribe({
              next: (data) => {
                this.initEdit();
                this.guardReplacementControl.setValue(null);
                this.updateBody = [];
                this._config.showSnackbar("Änderung wurde verschickt", 3000, 'success');
                this.loadGuard();
              }, error: (err) => {
                this._config.showSnackbar("Fehler beim Versenden der Änderung", 3000, 'error');
              }
            });
        }, error: (err) => {
          this._config.showSnackbar("Fehler beim Versenden der Änderung", 3000, 'error');
        }
      });
  }
}