import { StepperSelectionEvent, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AlarmsVehiclesComponent } from '../alarms-vehicles.component';
import { AlarmsVehicleDialogInformationComponent } from './alarms-vehicle-dialog-information/alarms-vehicle-dialog-information.component';

import { AlarmsVehicleDialogMaterialComponent } from './alarms-vehicle-dialog-material/alarms-vehicle-dialog-material.component';
import { AlarmsVehicleDialogPersonsComponent } from './alarms-vehicle-dialog-persons/alarms-vehicle-dialog-persons.component';


interface Vehicle {
  alarmId: number,
  materials: Material[],
  seats: VehicleSeat[],
  userList: User[];
  disabledElements: Boolean;
  host: AlarmsVehiclesComponent;
  vehicle: {
    disabled: boolean,
    id: number,
    name: string,
    seats: number,
    status: string,
    type: number,
    hasPump: boolean,
    hasLadder: boolean,
    hasGenerator: boolean
  },
  data: {
    distance: number,
    used: boolean,
    functionsUsed: any,
    transport: boolean,
    calculated: boolean
  }
}
interface VehicleSeat {
  fullname: string,
  userId: number,
  required: boolean,
  functions: number[]
}
interface User {
  id: number,
  firstname: string,
  lastname: string,
  groups: number[],
  name: string,
  email: string
}
interface Material {
  id: number,
  name: string,
  unit: string
}

@Component({
  selector: 'app-alarms-vehicles-dialog',
  templateUrl: './alarms-vehicles-dialog.component.html',
  styleUrls: ['./alarms-vehicles-dialog.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true },
  }],
})
export class AlarmsVehiclesDialogComponent implements AfterViewInit {

  @ViewChild('persons', { static: false }) public persons: AlarmsVehicleDialogPersonsComponent;

  @ViewChild('materials', { static: false }) public materials: AlarmsVehicleDialogMaterialComponent;

  @ViewChild('informations', { static: false }) public informations: AlarmsVehicleDialogInformationComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public _data: Vehicle,
    public _dialogRef: MatDialogRef<AlarmsVehiclesDialogComponent>) { }

  ngAfterViewInit(): void {
    if (!this._data.data.distance && this._data.vehicle.type !== 20)
      this.informations.calculateDistance();
  }

  saveVehicle() {
    if (this.persons.hasError || (this._data.vehicle.type !== 20 && this.informations.hasError)) {
      const swalObject = Swal.fire({
        title: 'Ungültige Daten',
        text: 'Dieses Fahrzeug enthält ungültige oder fehlende Daten. Soll dieses Fahrzeug verworfen werden?',
        icon: 'warning',
        showConfirmButton: true,
        showDenyButton: true,
        reverseButtons: true,
        denyButtonText: 'Verwerfen',
        confirmButtonText: 'Bearbeitung fortsetzen',
      });
      swalObject.then(result => {
        if (result.isDenied) {
          this._dialogRef.close();
        }
      });
    } else {
      this.closeDialog();
    }
  }

  closeDialog() {
    let vehicle = {
      vehicle: this._data.vehicle,
      seats: this.cleanSeats(this.persons.vehicleSeats),
      materials: this._data.vehicle.type !== 20 ? this.materials.materialList : [],
      data: this._data.data,
    };
    vehicle.data.transport = this.persons.transportVehicle;
    vehicle.seats.forEach(seat => {
      seat.functions.sort((a, b) => a - b);
    });
    this._dialogRef.close({
      data: vehicle,
    });
  }

  cleanSeats(seatList) {
    seatList.forEach(elem => {
      if (!elem.userId)
        seatList.splice(seatList.findIndex(s => s === elem), 1);
    });
    return seatList;
  }

  notifySelectionChange($event: StepperSelectionEvent) {
    switch ($event.previouslySelectedIndex) {
      case 0:
        this.persons.checkIfValid();
        break;

      default:
        break;
    }
  }

}
