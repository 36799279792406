import { Component, OnInit, AfterViewInit, Inject } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from "@angular/material/bottom-sheet";
import { CalenderSheet, EventCalender } from "@ffo/mitgliederbereich-types";
import { CalendarEvent } from "angular-calendar";
import { DateTime } from "luxon";


@Component({
  selector: 'app-calendar-info',
  templateUrl: 'calendar-info.html'
})
export class BottomSheetEventInfoComponent implements OnInit, AfterViewInit {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: CalenderSheet,
    public _bottomSheet: MatBottomSheet) {
    this.index = data.index || 0;
    this.events = data.events;
  }
  ngOnInit(): void {
    this.events = this.events.map((event: CalendarEvent<EventCalender>) => {
      event.meta.start = new Date(event.meta.start);
      event.meta.end = new Date(event.meta.end);
      return event;
    });
  }

  ngAfterViewInit(): void {
    this.setResponseBorder();
  }

  index: number = 0;
  events: CalendarEvent<EventCalender>[] = [];

  today = new Date();
  dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };

  setIndex(val) {
    this.index += val;
    this.setResponseBorder();
  }

  closeView() {
    this._bottomSheet.dismiss();
  }

  formatDateTime(dateObj: Date): string {
    const date: DateTime = DateTime.fromJSDate(dateObj);
    return date.setLocale('de-de').toFormat('dd.MM.yyyy, HH:mm');
  }

  formatDate(dateObj: Date): string {
    const date: DateTime = DateTime.fromJSDate(dateObj);
    return date.setLocale('de-de').toFormat('dd.MM.yyyy');
  }

  formatTime(dateObj: Date): string {
    const date: DateTime = DateTime.fromJSDate(dateObj);
    return date.setLocale('de-de').toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  sameDay(first: Date, second: Date): boolean {
    return DateTime.fromJSDate(first).hasSame(DateTime.fromJSDate(second), 'day');
  }

  getEvent(): EventCalender {
    return this.events[this.index].meta;
  }

  setResponseBorder() {
    if (this.events[this.index].meta.response === 1) {
      document.getElementsByTagName('mat-bottom-sheet-container')[0].classList.remove('greenBorder');
      document.getElementsByTagName('mat-bottom-sheet-container')[0].classList.add('redBorder');
    } else if (this.events[this.index].meta.response === 2) {
      document.getElementsByTagName('mat-bottom-sheet-container')[0].classList.remove('redBorder');
      document.getElementsByTagName('mat-bottom-sheet-container')[0].classList.add('greenBorder');
    } else {
      document.getElementsByTagName('mat-bottom-sheet-container')[0].classList.remove('greenBorder');
      document.getElementsByTagName('mat-bottom-sheet-container')[0].classList.remove('redBorder');
    }
  }
}