<div class="row">
    <div class="col-lg-3 col-md-4 col-12">
        <mat-form-field id="g263" class="input-full-width" appearance="outline">
            <mat-label>Ablaufdatum G26.3</mat-label>
            <input matInput placeholder="Ablaufdatum G26.3"
                [value]="getFormattedDate(parent.userInfo.atemschutz.g26_3_date)" disabled>
            <button mat-icon-button matSuffix (click)="extendingG263Date()" [disabled]="!g263Valid"
                *ngIf="parent.userInfo.atemschutz.g26_3_date!==(null || undefined || '01.01.1970')"
                matTooltip="G26/3 verlängern">
                <mat-icon>update</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="extendingG263Date()" [disabled]="!g263Valid"
                *ngIf="parent.userInfo.atemschutz.g26_3_date===(null || undefined || '01.01.1970')"
                matTooltip="G26/3 hinzufügen">
                <mat-icon>add</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-4 col-12">
        <mat-form-field id="briefing" class="input-full-width" appearance="outline">
            <mat-label>Einweisungsdatum</mat-label>
            <input matInput placeholder="Einweisungsdatum"
                [value]="getFormattedDate(parent.userInfo.atemschutz.briefingDate)" disabled>
        </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-2 col-6">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>CSA</mat-label>
            <mat-select [(ngModel)]="parent.userInfo.atemschutz.csa" required disabled>
                <mat-option value="0">Nein</mat-option>
                <mat-option value="1">Ja</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-2 col-6">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Blauer Punkt</mat-label>
            <mat-select [(ngModel)]="parent.userInfo.atemschutz.bluePoint" required disabled>
                <mat-option value="0">Nein</mat-option>
                <mat-option value="1">Ja</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 col-12">
        <div class="center" [ngClass]="parent.env.mobile ? 'mobilecenter' : ''">
            <div class="clearfix" [ngClass]="parent.env.mobile ? 'mobile, editButtons' : ''">
                <button mat-raised-button color="accent" class="float-left" (click)="openG263PictureDialog()"
                    style="margin-right: 15px;">G26/3 Bild
                    <mat-icon>add_a_photo
                    </mat-icon>
                </button>
            </div>
            <mat-error *ngIf="!g263Valid" [ngClass]="parent.env.mobile ? 'mobile mobileText' : ''"
                class="float-left clearfix">
                Bitte erst eine G26/3 hochladen.</mat-error>
        </div>
    </div>
</div>

<div class="col-lg-12 col-12 container" layout="column" layout-align="space-between center"
    style="display:flex;justify-content:center;align-items:center;"
    *ngIf="parent.userInfo.atemschutz.g26_3_picture !== 'default.jpg'">
    <img src="{{g263picture}}" class="image" />
</div>

<ng-template #uploadG263>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">G26/3 Upload</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content>
        <div class="alert alert-info">
            <b>Hinweis!</b> Mit dem Upload deiner G26, stimmst du der Verarbeitung/Speicherung zu.
        </div>

        <div class="row">
            <div class="col-lg-12 col-12 container" *ngIf="!G263Loading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <img src="{{g263picture}}" class="image" />
                <div class="upload">
                    <label for="upload_G263">
                        <span class="uploadLabel">G26/3 hochladen</span>
                    </label>
                    <input type="file" [(ngModel)]="G263FileUrl" (change)="upload($event)" style="display:none;"
                        id="upload_G263" accept="image/x-png,image/jpeg,image/jpg" />
                </div>
            </div>
            <div class="col-lg-6 col-12 container" *ngIf="G263Loading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <mat-spinner color="primary"></mat-spinner>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #resizeG263>
    <h2 mat-dialog-title>Bild zuschneiden</h2>
    <mat-dialog-content style="padding-left: 20%;">
        <div style="width: 400px !important; height: auto !important;" *ngIf="!parent.env.mobile">
            <mat-progress-bar mode="query" *ngIf="imageG263Loading"></mat-progress-bar>
            <image-cropper [imageChangedEvent]="imageG263ChangedEvent" [maintainAspectRatio]="false" format="png"
                [canvasRotation]="turn" (imageLoaded)="imageG263Loaded()" (imageCropped)="imageG263Cropped($event)"
                (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>
        <div style="width: 200px !important; height: auto !important;" *ngIf="parent.env.mobile">
            <mat-progress-bar mode="query" *ngIf="imageG263Loading"></mat-progress-bar>
            <image-cropper [imageChangedEvent]="imageG263ChangedEvent" [maintainAspectRatio]="false" format="png"
                [canvasRotation]="turn" (imageLoaded)="imageG263Loaded()" (imageCropped)="imageG263Cropped($event)"
                (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="confirmation">
            <mat-checkbox [(ngModel)]="confirmation" [disabled]="imageG263Loading" color="primary">Hiermit bestätige
                ich,
                dass <span *ngIf="parent.env.mobile"><br></span>dies ein aktuelles Foto meiner<span
                    *ngIf="parent.env.mobile"><br></span> gültigen G26/3 ist.</mat-checkbox>
        </div>
        <div class="clearfix">
            <button mat-mini-fab mat-dialog-close class="float-right" [disabled]="!confirmation" (click)="saveG263()"
                color="success"><mat-icon>done</mat-icon></button>
            <button mat-mini-fab mat-dialog-close class="float-left" color="warn"
                (click)="closeReset()"><mat-icon>close</mat-icon></button>
            <button mat-mini-fab class="float-right" color="accent" style="margin-right: 10px;"
                [disabled]="imageG263Loading" (click)="turnRight()"><mat-icon>redo</mat-icon></button>
            <button mat-mini-fab class="float-left" color="accent" style="margin-left: 10px;"
                [disabled]="imageG263Loading" (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #extendDialog>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left"
                *ngIf="parent.userInfo.atemschutz.g26_3_date!==(null || undefined || '01.01.1970')">G26/3 Verlängern
            </h2>
            <h2 mat-dialog-title class="float-left"
                *ngIf="parent.userInfo.atemschutz.g26_3_date===(null || undefined || '01.01.1970')">G26/3 hinzufügen
            </h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <mat-form-field id="g263" *ngIf="!parent.env.mobile" class="input-full-width" appearance="outline">
                    <mat-label>Ablaufdatum G26.3</mat-label>
                    <input matInput [matDatepicker]="g263" [min]="today" [max]="endDate" placeholder="Ablaufdatum G26.3"
                        [value]="parent.userInfo.atemschutz.g26_3_date" (dateChange)="ong263DateChange($event)"
                        (change)="onDateChange($event)" (focus)="g263.open()" style="cursor: pointer;" required>
                    <mat-datepicker-toggle matSuffix [for]="g263"></mat-datepicker-toggle>
                    <mat-datepicker #g263 [startAt]="today" startView="multi-year"></mat-datepicker>
                </mat-form-field>
                <mat-form-field id="g263" *ngIf="parent.env.mobile" class="input-full-width" appearance="outline">
                    <mat-label>Ablaufdatum G26.3</mat-label>
                    <input matInput [matDatepicker]="g263" [min]="today" [max]="endDate" placeholder="Ablaufdatum G26.3"
                        [value]="parent.userInfo.atemschutz.g26_3_date" (dateChange)="ong263DateChange($event)"
                        (change)="onDateChange($event)" (focus)="g263.open()" style="cursor: pointer;" required>
                    <mat-datepicker-toggle matSuffix [for]="g263"></mat-datepicker-toggle>
                    <mat-datepicker #g263 touchUi [startAt]="today" startView="multi-year"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: end;">
        <button mat-raised-button color="success" mat-dialog-close (click)="extendG263Save()">Speichern
            <mat-icon>save</mat-icon></button>
    </mat-dialog-actions>
</ng-template>