<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">{{firstname}} {{lastname}}
        </h3>
        <mat-card>
            <mat-card-content>
                <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                    <mat-icon>keyboard_backspace</mat-icon>
                </a>
                <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
                    <mat-icon>apps</mat-icon>
                </a>
                <div *ngIf="!age18" [ngClass]="env.mobile ? '' : 'desktop18'">
                    <mat-error>{{firstname}} {{lastname}} ist noch nicht 18 Jahre alt, daher können keine
                        Führerscheindaten
                        gepflegt werden.</mat-error>
                </div>
                <div class="row" *ngIf="existingLicence && age18">
                    <div class="col-lg-4 col-md-6 col-12">
                        <mat-form-field id="dateOfIssue" class="input-full-width" appearance="outline">
                            <mat-label>Ausstellungsdatum</mat-label>
                            <input matInput placeholder="Ausstellungsdatum Führerschein"
                                [value]="getFormattedDate(userInfo.dateOfIssue)" disabled required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <mat-form-field id="dateOfExpiry" class="input-full-width" appearance="outline"
                            *ngIf="!unlimited">
                            <mat-label>Ablaufdatum</mat-label>
                            <input matInput placeholder="Ablaufdatum Führerschein"
                                [value]="getFormattedDate(userInfo.dateOfExpiry)" disabled required>
                        </mat-form-field>
                        <mat-form-field id="dateOfExpiry" class="input-full-width" appearance="outline"
                            *ngIf="unlimited">
                            <mat-label>Ablaufdatum</mat-label>
                            <input matInput placeholder="Ablaufdatum Führerschein" value="unbegrenzt" disabled required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <mat-form-field id="lastChecked" class="input-full-width" appearance="outline"
                            matTooltip="{{getCheckedExpiry()}}" matTooltipPosition="above">
                            <mat-label>Letzte Kontrolle</mat-label>
                            <input matInput placeholder="Letzte Kontrolle"
                                [value]="getFormattedDate(userInfo.lastChecked)" disabled required>
                            <mat-icon matSuffix *ngIf="controlDateValid() === 1;" color="primary">warning</mat-icon>
                            <mat-icon matSuffix *ngIf="controlDateValid() === 2;" color="warn">error</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <mat-label>Ausstellungsbehörde</mat-label>
                            <input matInput [(ngModel)]="userInfo.issuingAuthority" disabled required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <mat-label>Führerscheinnummer</mat-label>
                            <input matInput [(ngModel)]="userInfo.dlNumber" disabled required>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row licences" *ngFor="let licence of userInfo.licences;">
                    <div class="col-lg-2 col-6">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <mat-label>Führerscheinklasse</mat-label>
                            <input matInput [(ngModel)]="licence.licenceClass" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-6">
                        <mat-form-field class="input-full-width" appearance="outline"
                            *ngIf="unlimitedClassC1 && (licence.licenceClass === 'Klasse C1' || licence.licenceClass === 'Klasse C1E')">
                            <mat-label>Ablaufdatum</mat-label>
                            <input matInput value="unbegrenzt" disabled>
                        </mat-form-field>
                        <mat-form-field class="input-full-width" appearance="outline"
                            *ngIf="unlimitedClassB && (licence.licenceClass === 'Klasse B' || licence.licenceClass === 'Klasse BE' || licence.licenceClass === 'Klasse FFF')">
                            <mat-label>Ablaufdatum</mat-label>
                            <input matInput value="unbegrenzt" disabled>
                        </mat-form-field>
                        <mat-form-field class="input-full-width" appearance="outline"
                            *ngIf="licence.licenceClass === 'Klasse C' || licence.licenceClass === 'Klasse CE'">
                            <mat-label>Ablaufdatum</mat-label>
                            <input matInput [value]="getFormattedDate(licence.expiryDate)" disabled>
                        </mat-form-field>
                        <mat-form-field class="input-full-width" appearance="outline"
                            *ngIf="!unlimitedClassC1 && (licence.licenceClass === 'Klasse C1' || licence.licenceClass === 'Klasse C1E')">
                            <mat-label>Ablaufdatum</mat-label>
                            <input matInput [value]="getFormattedDate(licence.expiryDate)" disabled>
                        </mat-form-field>
                        <mat-form-field class="input-full-width" appearance="outline"
                            *ngIf="!unlimitedClassB && (licence.licenceClass === 'Klasse B' || licence.licenceClass === 'Klasse BE' || licence.licenceClass === 'Klasse FFF')">
                            <mat-label>Ablaufdatum</mat-label>
                            <input matInput [value]="getFormattedDate(licence.expiryDate)" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-12"
                        [ngClass]="licence.licenceClass !== 'Klasse FFF' ? 'col-lg-4 col-md-6' : 'col-lg-8 col-md-12'">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <mat-label>Fahrerlaubnis</mat-label>
                            <mat-select multiple [(ngModel)]="licence.permission" disabled class="fahrerlaubnis">
                                <mat-option *ngFor="let car of getCarList(licence.licenceClass)" [value]="car.id">
                                    {{car.type}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12" *ngIf="licence.licenceClass !== 'Klasse FFF'">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <mat-label>Blaufahrerlaubnis</mat-label>
                            <mat-select multiple [(ngModel)]="licence.permissionBlue" disabled class="fahrerlaubnis">
                                <mat-option *ngFor="let car of getCarList(licence.licenceClass)" [value]="car.id">
                                    {{car.type}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="clearfix" *ngIf="age18">
                    <button mat-raised-button color="primary" class="float-right" (click)="openLicenceMenu()"
                        style="margin-bottom: 10px; margin-right: 10px;"
                        *ngIf="(_login.hasPermission('admin-user-drivingperm-edit') && userInfo.checked===1) || _login.hasPermission('admin-user-driving-edit')">
                        bearbeiten <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-raised-button color="accent" class="float-left" style="margin-left: 10px;"
                        routerLink="./assets">
                        <mat-icon>attach_file</mat-icon> Anhänge
                    </button>
                    <mat-error class="float-left"
                        *ngIf="userInfo.dienstfahrerlaubnis === '' && classesExisting && ((userInfo.flurfoerderfahrzeuge === '' && !FFFExisting) || (userInfo.flurfoerderfahrzeuge !== '' && FFFExisting))"
                        style="margin-left: 15px; margin-top: 7px;">
                        Dienstfahrerlaubnis wurde noch nicht unterschrieben!</mat-error>
                    <mat-error class="float-left"
                        *ngIf="userInfo.flurfoerderfahrzeuge === '' && FFFExisting && ((userInfo.dienstfahrerlaubnis === '' && !classesExisting) || (userInfo.dienstfahrerlaubnis !== '' && classesExisting))"
                        style="margin-left: 15px; margin-top: 7px;">
                        Beauftragung Flurförderfahrzeuge wurde noch nicht unterschrieben!</mat-error>
                    <mat-error class="float-left"
                        *ngIf="userInfo.dienstfahrerlaubnis === '' && classesExisting && userInfo.flurfoerderfahrzeuge === '' && FFFExisting"
                        style="margin-left: 15px; margin-top: 7px;">
                        Dienstfahrerlaubnis und Beauftragung Flurförderfahrzeuge wurde noch nicht unterschrieben!
                    </mat-error>
                    <mat-error class="float-right"
                        *ngIf="_login.hasPermission('admin-user-drivingperm-edit') && userInfo.checked===0"
                        style="margin-right: 15px; margin-top: 7px;">
                        allgemeine Führerscheindaten wurden noch nicht vom Schriftführer freigegeben!</mat-error>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>