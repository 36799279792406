<p> Entsprechend der Berechtigung können unterschiedliche Auswertungen erzeugt werden. </p>

<h6>Kategorien der Auswertungen</h6>

<ul>
  <li>Personen</li>
  <li>Einsätze</li>
</ul>

<p>Diese können anschließend, je nach Auswertung, als: </p>

<ul>
  <li>
    <mat-icon>image</mat-icon> PNG
  </li>
  <li>
    <mat-icon>picture_as_pdf</mat-icon> PDF
  </li>
  <li>
    <mat-icon>table_chart</mat-icon> CSV
  </li>
</ul>

<p>Datei exportiert werden. Bei einigen Auswertungen sind Pflichteingaben
  notwendig, standardmäßig sind hier Werte vorgegeben, diese können verändert werden.
</p>