import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { GuardLocation } from '@ffo/mitgliederbereich-types';
import { getHostUrl } from '../config';

@Pipe({
  name: 'guardLocation',
  pure: false,
})
export class GuardLocationPipe implements PipeTransform {

  locations: GuardLocation[] = [];

  constructor(private _http: HttpClient) {
    this._http.get<GuardLocation[]>(getHostUrl() + 'datainfo/guardLocations')
      .subscribe(data => {
        this.locations = data;
      });
  }

  transform(value: number): GuardLocation {
    return this.locations.find((loc: GuardLocation) => {
      if (loc.id === value) return loc;
    });
  }

}
