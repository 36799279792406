<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">Maschinisten</h3>
        <mat-card>
            <mat-card-content>
                <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                    <mat-icon>keyboard_backspace</mat-icon>
                </a>
                <a mat-mini-fab color="primary" class="pageNext" (click)="openLicenceMenu()">
                    <mat-icon>apps</mat-icon>
                </a>
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="table-responsive">
                            <table mat-table [dataSource]="notifications" class="mat-elevation-z8" matSort
                                matSortActive="date" matSortDirection="desc" matSortDisableClear>
                                <!-- Message Column -->
                                <ng-container matColumnDef="title">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachricht</th>
                                    <td mat-cell *matCellDef="let element" (click)="openNotification(element)">
                                        {{element.title}} </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="vertical-align: middle;">
                                        Name</th>
                                    <td mat-cell *matCellDef="let element" (click)="openNotification(element)">
                                        {{element.name}} </td>
                                </ng-container>

                                <!-- Date Column -->
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="vertical-align: middle;">
                                        Datum</th>
                                    <td mat-cell *matCellDef="let element" (click)="openNotification(element)">
                                        {{getFormattedDateTime(element.date)}} </td>
                                </ng-container>

                                <!-- Action Column -->
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let element">
                                        <button type="button" mat-icon-button color="green" *ngIf="element.read===0"
                                            (click)="readSpecific(element.id)">
                                            <mat-icon>done</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="notificationsColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: notificationsColumns;"></tr>
                            </table>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="5" showFirstLastButtons>
                        </mat-paginator>
                    </div>
                    <div class="col-lg-8 col-6">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-6">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <mat-label>Klassenfilter</mat-label>
                            <mat-select multiple (selectionChange)="applyClassesFilter($event)">
                                <mat-option value="B">B</mat-option>
                                <mat-option value="BE">BE</mat-option>
                                <mat-option value="C1">C1</mat-option>
                                <mat-option value="C1E">C1E</mat-option>
                                <mat-option value="C">C</mat-option>
                                <mat-option value="CE">CE</mat-option>
                                <mat-option value="FFF">FFF</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 col-12">
                        <div class="table-responsive">
                            <div *ngIf="maschinistenListLoading">
                                <mat-progress-bar mode="query"></mat-progress-bar>
                            </div>
                            <table mat-table [dataSource]="maschinisten" matSort class="mat-elevation-z8"
                                matSortActive="maschinist" matSortDirection="asc" matSortDisableClear>
                                <!-- ID Column -->
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px"><span
                                            *ngIf="!env.mobile">Stammnummer</span><span *ngIf="env.mobile">Nr.</span>
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        routerLink="/admin/user/edit/{{element.id}}/driving">
                                        {{element.id}}</td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="maschinist">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="width: 300px; vertical-align: middle;">Name
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        routerLink="/admin/user/edit/{{element.id}}/driving">
                                        {{element.name }}</td>
                                </ng-container>

                                <!-- dateOfExpiry Column -->
                                <ng-container matColumnDef="dateOfExpiry">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="width: 200px; vertical-align: middle;">Ablaufdatum
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        routerLink="/admin/user/edit/{{element.id}}/driving">
                                        {{getFormattedDate(element.dateOfExpiry)}}</td>
                                </ng-container>

                                <!-- control Column -->
                                <ng-container matColumnDef="lastChecked">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="width: 200px; vertical-align: middle;">Kontrolldatum
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        routerLink="/admin/user/edit/{{element.id}}/driving">
                                        {{getFormattedDate(element.lastChecked, true)}}</td>
                                </ng-container>

                                <!-- Classes Column -->
                                <ng-container matColumnDef="classes">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="width: 200px; vertical-align: middle;">Führerscheinklassen
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        routerLink="/admin/user/edit/{{element.id}}/driving">
                                        {{element.classes }}</td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="maschinistenColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: maschinistenColumns;"
                                    [ngClass]="valid(row.dateOfExpiry, row.lastChecked) ? 'error' : ''"></tr>
                            </table>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #menu>
    <div class="text-center">
        <br>
        <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
            <mat-icon>close</mat-icon>
        </a>
        <mat-nav-list>
            <mat-list-item (click)="_bottomSheet.dismiss()" routerLink="/admin/licenceCheck">
                <h3 matListItemLine>Führerscheinkontrolle</h3>
                <div matListItemMeta>
                    <mat-icon>receipt_long</mat-icon>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </div>
</ng-template>