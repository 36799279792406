<mat-spinner *ngIf="isLoading" color="accent" class="loading-spinner-center" [diameter]="200"></mat-spinner>

<div class="container">
  <div class="col-lg-10 col-12 offset-lg-1" *ngIf="!isLoading">
    <h3 class="section-header">Prüfung ansehen</h3>
    <mat-card class="mb-4">
      <mat-card-content>
        <a routerLink="../../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext" *ngIf="materialId">
          <mat-icon>apps</mat-icon>
        </a>

        <mat-accordion multi>
          <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="me-2">info</mat-icon> Details
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-12">

                <mat-form-field class="input-full-width" appearance="outline">
                  <mat-label>Name der Prüfung</mat-label>
                  <input matInput [value]="data.name" readonly />
                </mat-form-field>
              </div>

              <div class="col-lg-6 col-12">
                <mat-form-field class="input-full-width" appearance="outline">
                  <mat-label>Material Kategorie</mat-label>
                  <input matInput [value]="data.materialCategory" readonly />
                </mat-form-field>
              </div>

              <div class="col-lg-6 col-12">
                <mat-form-field class="input-full-width" appearance="outline">
                  <mat-label>Lagerort</mat-label>
                  <input matInput [value]="data.person ?? data.location" readonly />
                </mat-form-field>
              </div>

              <div class="col-lg-6 col-12">
                <mat-form-field class="input-full-width" appearance="outline">
                  <mat-label>Material Name oder EAN</mat-label>
                  <input *ngIf="data.material" matInput [value]="data.material" readonly class="pointer" [routerLink]="['/material/equipment/details', data.materialId]"/>
                  <input *ngIf="data.vehicle" matInput [value]="data.vehicle" readonly class="pointer" [routerLink]="['/material/vehicles/details', data.vehicleId]"/>
                </mat-form-field>
              </div>

              <div class="col-lg-6 col-12">
                <mat-form-field class="input-full-width" appearance="outline">
                  <mat-label>Material Seriennummer</mat-label>
                  <input matInput [value]="data.serialNumber" readonly class="pointer" [routerLink]="['/material/equipment/details', data.materialId]"/>
                </mat-form-field>
              </div>

              <div class="col-12">
                <mat-form-field class="input-full-width" appearance="outline" floatLabel="always"
                  *ngIf="data.description">
                  <mat-label>Beschreibung</mat-label>
                  <div class="description-field" [innerHTML]="data.description"></div>
                  <input matInput class="d-none" />
                </mat-form-field>
              </div>

            </div>

            <hr />

            <form [formGroup]="group">
              <div class="row pt-3">
                <div class="col-lg-8">
                  <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Fälligkeit der Prüfung</mat-label>
                    <input matInput
                      [value]="dayjs(data.date).format('dddd, DD.MM.YYYY HH:mm') + ' Uhr' + (isPastCheck ? ' Fällig!' : '')"
                      [class.mat-error]="isPastCheck" readonly required />
                    <button mat-icon-button matSuffix matTooltip="Prüfungsdatum verschieben" (click)="replanDate()"
                      *ngIf="!isPastCheck && data.status == 'open'">
                      <mat-icon>event_repeat</mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <div class="col-lg-4">
                  <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                      <mat-option value="open" disabled>Offen</mat-option>
                      <mat-option value="successful">Bestanden</mat-option>
                      <mat-option value="failed">Nicht Bestanden</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-12" *ngIf="data.status != 'open'">
                  <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Datum der Prüfung</mat-label>
                    <input matInput [value]="dayjs(data.completionDate).format('dddd, DD.MM.YYYY HH:mm') + ' Uhr'"
                      readonly required />
                  </mat-form-field>
                </div>
              </div>

              <mat-form-field class="input-full-width" appearance="outline" floatLabel="always">
                <mat-label>Kommentar</mat-label>
                <textarea matInput formControlName="comment" cdkTextareaAutosize></textarea>
                <mat-error *ngIf="group.get('comment').invalid">Kommentar ist ein Pflichtfeld, wenn Prüfung "Nicht
                  Bestanden" ist.</mat-error>
              </mat-form-field>

              <mat-form-field class="input-full-width" appearance="outline" *ngIf="data.status != 'open'">
                <mat-label>Prüfung durchgeführt durch</mat-label>
                <input matInput [value]="data.checkedBy" readonly />
              </mat-form-field>

            </form>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="me-2">attach_file</mat-icon> Prüfberichte ({{ fileList.length }})
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-list class="input-full-width mt-3 mb-2" *ngIf="fileList.length > 0">
              <mat-list-item *ngFor="let file of fileList">
                <div class="clearfix input-full-width">
                  <span class="float-left" *ngIf="!file.id">{{file.name}}</span>
                  <a *ngIf="file.id" class="float-left" [target]="isPWA ? '_self' : '_blank'"
                    href="{{baseURL}}admin/material/checks/cycles/{{this.cycleId}}/file/{{file.id}}?sessionToken={{_login.getSessionToken()}}">{{file.name}}</a>
                  <div class="float-right">
                    <div *ngIf="!file.id && !file.error" class="clearfix">
                      <span class="float-left me-3">Wird hochgeladen ...</span>
                      <mat-spinner [diameter]="30" style="margin-top: -8px" class="float-right"></mat-spinner>
                    </div>
                    <div *ngIf="file.error" class="clearfix">
                      <span class="float-left me-3" style="color: red;">Fehlerhaft</span>
                      <mat-icon style="color: red;" class="float-right">close</mat-icon>
                    </div>
                    <div *ngIf="file.id" class="clearfix">
                      <button mat-icon-button (click)="deleteAttachement(file)" [disabled]="file.isDeleting"
                        *ngIf="data.status == 'open'">
                        <mat-icon color="warn">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>

            <input #FileSelectInputDialog type="file" multiple style="display: none"
              (change)="uploadAttachments($event)"
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" />
            <button mat-raised-button color="primary" (click)="attachFile()">
              <mat-icon class="me-2">attach_file_add</mat-icon>
              Anhang hinzufügen
            </button>
          </mat-expansion-panel>
        </mat-accordion>

        <div class="clearfix mt-4">
          <button mat-raised-button class="float-right" color="success" (click)="save()"
            [disabled]="group.invalid || group.get('status').value == 'open'" *ngIf="data.status == 'open'">
            <div class="clearfix submit-button">
              <mat-icon class="me-3 mt-1 float-left" [hidden]="!isSaving">
                <mat-spinner diameter="20"></mat-spinner>
              </mat-icon>
              <span class="me-1 float-left">Prüfung speichern</span>
              <mat-icon class="float-right">save</mat-icon>
            </div>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>