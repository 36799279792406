import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigItem } from '@ffo/mitgliederbereich-types';
import { HttpClient } from '@angular/common/http';
import { getHostUrl } from '../../config';

@Injectable({
  providedIn: 'root',
})
export class WebConfigService {

  constructor(private _http: HttpClient) {
    this.loadConfig();
  }

  private data$: BehaviorSubject<ConfigItem[]> = new BehaviorSubject(new Array<ConfigItem>());
  private data: ConfigItem[] = new Array<ConfigItem>();

  public getConfig(): Observable<ConfigItem[]> {
    return this.data$.asObservable();
  }

  public getConfigItem(result: ConfigItem[], key: string, def: string = '') {
    return result?.find(c => c.name === key)?.value || def;
  }

  public loadConfig(ignoreExisting = false) {
    if (!this.data && !ignoreExisting) {
      return;
    }

    this._http.get<ConfigItem[]>(`${getHostUrl()}webconfig`).subscribe(result => {
      this.data = result;
      this.data$.next(result);
    });
  }

}
