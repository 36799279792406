<a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
  <mat-icon>close</mat-icon>
</a>
<mat-nav-list class="mt-4">
  <mat-form-field class="input-full-width">
    <mat-label>{{_data.reviewerNotes ? 'Notiz bearbeiten' : 'Notiz hinzufügen'}}</mat-label>
    <textarea matInput rows="8" [(ngModel)]="_data.reviewerNotes"></textarea>
  </mat-form-field>
  <div class="clearfix mt-2">
    <button class="float-right" mat-raised-button color="primary" (click)="save()">
      Speichern
      <mat-icon>save</mat-icon>
    </button>
  </div>
</mat-nav-list>