import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProfileComponent } from '../profile.component';
import { getHostUrl, ConfigModule } from 'src/app/util/config';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-profile-emergency-contacts',
  templateUrl: './profile-emergency-contacts.component.html',
  styleUrls: ['./profile-emergency-contacts.component.css'],
})
export class ProfileEmergencyContactsComponent implements OnInit {

  @Input() parent: ProfileComponent;

  removedContactList: any = [];

  relationshipList: any = ['Mutter', 'Vater', 'Partner', 'Kind', 'Erziehungsberechtigter', 'Andere'];

  contactsvalid: boolean = true;

  ready: boolean = false;

  emergenyNameFormControl: UntypedFormControl[] = [];

  emergenyPhoneFormControl: UntypedFormControl[] = [];

  emergenyEmailFormControl: UntypedFormControl[] = [];


  constructor(private _http: HttpClient,
    private _config: ConfigModule) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.ready = false;
    this.emergenyNameFormControl = [];
    this.emergenyPhoneFormControl = [];
    this.emergenyEmailFormControl = [];
    this.removedContactList = [];
    for (let i = 0; i < this.parent.userInfo.emergencyContacts.length; i++) {
      this.emergenyNameFormControl.push(new UntypedFormControl('', [
        Validators.required,
        //Validators.pattern(/^[a-zA-Z]+[ ]{1}[a-zA-Z]+$/)
      ]));
      this.emergenyPhoneFormControl.push(new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^([+]\d{2})(\d{4,20})$/),
      ]));
      this.emergenyEmailFormControl.push(new UntypedFormControl('', [
        Validators.email,
      ]));
      this.emergenyNameFormControl[i].setValue(this.parent.userInfo.emergencyContacts[i].name);
      this.emergenyPhoneFormControl[i].setValue(this.parent.userInfo.emergencyContacts[i].phone);
      this.emergenyEmailFormControl[i].setValue(this.parent.userInfo.emergencyContacts[i].email);
    }
    this.ready = true;
  }

  saveContactsData() {
    const context = this;
    this.parent._spinner.show();
    this._http.post(getHostUrl() + 'emergencycontact', { userID: this.parent.env.userID, contacts: this.parent.userInfo.emergencyContacts, removed: this.removedContactList }).subscribe(resp => {
      context.parent._spinner.hide();
      context._config.showSnackbar('Notfallkontakte gespeichert!', 2000, 'success');
      context.parent.fetchEmergenyData();
      context.loadData();
    }, error => {
      context.parent._spinner.hide();
      if (error.error === 5001)
        context._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        context._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      context.parent.fetchEmergenyData();
    });
  }

  addContactField() {
    this.emergenyNameFormControl.push(new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z]+[ ]{1}[a-zA-Z]+$/),
    ]));
    this.emergenyPhoneFormControl.push(new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^([+]\d{2})(\d{4,20})$/),
    ]));
    this.emergenyEmailFormControl.push(new UntypedFormControl('', [
      Validators.email,
    ]));
    this.parent.userInfo.emergencyContacts.push({
      id: null,
      name: '',
      relationship: '',
      email: '',
      phone: '',
    });
  }

  removeContactField(index: number) {
    this.removedContactList.push({ id: this.parent.userInfo.emergencyContacts[index].id });
    this.parent.userInfo.emergencyContacts.splice(index, 1);
    this.emergenyNameFormControl.splice(index, 1);
    this.emergenyPhoneFormControl.splice(index, 1);
    this.emergenyEmailFormControl.splice(index, 1);
    this.contactsvalid = this.checkContacts();
  }

  checkContacts() {
    for (const contact of this.parent.userInfo.emergencyContacts) {
      if (contact.name === '' || contact.relationship === '' || contact.phone === '') {
        return false;
      }
    }
    return true;
  }

  onNameChanged(index: number) {
    this.parent.userInfo.emergencyContacts[index].name = this.emergenyNameFormControl[index].value;
    if (this.emergenyNameFormControl[index].valid) {
      this.contactsvalid = this.checkContacts();
    } else {
      this.contactsvalid = false;
    }
  }

  onRelationshipChanged(index: number, event: MatSelectChange) {
    this.parent.userInfo.emergencyContacts[index].relationship = event.value;
    this.contactsvalid = this.checkContacts();
  }

  onEmailChanged(index: number) {
    this.parent.userInfo.emergencyContacts[index].email = this.emergenyEmailFormControl[index].value;
    if (this.emergenyEmailFormControl[index].valid) {
      this.contactsvalid = this.checkContacts();
    } else {
      this.contactsvalid = false;
    }
  }

  onPhoneChanged(index: number) {
    this.parent.userInfo.emergencyContacts[index].phone = this.emergenyPhoneFormControl[index].value;
    if (this.emergenyPhoneFormControl[index].valid) {
      this.contactsvalid = this.checkContacts();
    } else {
      this.contactsvalid = false;
    }
  }

}
