<mat-dialog-header class="mb-3">
  <div class="clearfix">
      <a mat-icon-button (click)="saveVehicle()" class="closeButtonDialog float-right">
          <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">{{ _data.vehicle.name }}</h2>
  </div>
</mat-dialog-header>

<mat-dialog-content>
  <mat-horizontal-stepper labelPosition="bottom" (selectionChange)="notifySelectionChange($event)">
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>
    <mat-step [hasError]="persons.hasError" [errorMessage]="persons.errorMessage">
      <ng-template matStepLabel>Besatzung</ng-template>
      <app-alarms-vehicle-dialog-persons (data)="_data" #persons></app-alarms-vehicle-dialog-persons>

      <div class="mt-5">
        <button mat-raised-button matStepperNext color="primary" class="float-right"
          *ngIf="_data.vehicle.type !== 20">Weiter</button>
        <button mat-raised-button color="primary" class="float-right" (click)="saveVehicle()"
          *ngIf="_data.vehicle.type === 20">
          Abschließen
        </button>
      </div>
    </mat-step>
    <mat-step *ngIf="_data.vehicle.type !== 20">
      <ng-template matStepLabel>Material</ng-template>
      <app-alarms-vehicle-dialog-material (data)="_data" #materials></app-alarms-vehicle-dialog-material>

      <div class="mt-5">
        <button mat-raised-button matStepperPrevious color="primary">Zurück</button>
        <button mat-raised-button matStepperNext color="primary" class="float-right">Weiter</button>
      </div>
    </mat-step>
    <mat-step [hasError]="informations.hasError" *ngIf="_data.vehicle.type !== 20" errorMessage="Daten unvollständig!">
      <ng-template matStepLabel>Weiteres</ng-template>
      <app-alarms-vehicle-dialog-information (data)="_data" #informations></app-alarms-vehicle-dialog-information>

      <div class="mt-4">
        <button mat-raised-button matStepperPrevious color="primary">Zurück</button>
        <button mat-raised-button color="primary" class="float-right" (click)="saveVehicle()">
          Abschließen
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>