<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Einstellungen</h3>
    <mat-card>
      <mat-card-content>
        <div class="container mt-2 mb-2">
          <div class="row">
            <div class="col-xl-4 col-lg-6 col-12 mb-3">
              <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="general">
                <mat-icon class="float-left mt-4 ms-3">settings</mat-icon>
                Grundeinstellungen<br />
                <small>Individuelle Einstellungsmöglichkeiten</small>
              </button>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 mb-3">
              <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="general/cronjobs">
                <mat-icon class="float-left mt-4 ms-3">schedule</mat-icon>
                CronJobs<br />
                <small>Jobs verwalten und zeitbasiert ausführen</small>
              </button>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 mb-3">
              <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="general/jobs">
                <mat-icon class="float-left mt-4 ms-3">task</mat-icon>
                Jobs<br />
                <small>Manuelle Jobs ausführen</small>
              </button>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 mb-3" *ngIf="_login.hasPermission('admin-settings-statistics')">
              <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="general/statistics">
                <mat-icon class="float-left mt-4 ms-3">analytics</mat-icon>
                Auswertungen<br />
                <small>Gruppenzuweisung für Auswertungen</small>
              </button>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 mb-3">
              <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="fireplace/alarmlines">
                <mat-icon class="float-left mt-4 ms-3">campaign</mat-icon>
                Alarmierungsschleifen<br />
                <small>Konfiguration der vorhanden Schleifen</small>
              </button>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 mb-3">
              <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="/admin/logs"
                *ngIf="_login.hasPermission('admin-logging')">
                <mat-icon class="float-left mt-4 ms-3">integration_instructions</mat-icon>
                Logs<br />
                <small>Abrufen der System Logs</small>
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>