import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { AtemschutzUser } from '@ffo/mitgliederbereich-types';
import { DateTime } from 'luxon';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-atemschutz-users',
  templateUrl: './atemschutz-users.component.html',
  styleUrls: ['./atemschutz-users.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class AtemschutzUsersComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  atemschutzColumns: string[] = ['id', 'user', 'g26_3_date', 'bluePoint', 'csa'];
  atemschutzList: AtemschutzUser[] = [];
  atemschutz: MatTableDataSource<AtemschutzUser>;
  atemschutzListLoading: boolean = true;

  typFilter: any[] = [];
  searchFilter: string = '';

  env = environment;
  constructor(private _http: HttpClient, private _config: ConfigModule) { }

  ngOnInit(): void {
    this._config.setTitle('Atemschutzgeräteträger');
    this.loadUsers();
  }

  ngAfterViewInit(): void {
    if (this.env.mobile) {
      this.atemschutzColumns = ['id', 'user', 'g26_3_date', 'bluePoint'];
    }
  }

  loadUsers() {
    this._http.get<AtemschutzUser[]>(getHostUrl() + 'admin/fields/atemschutz/list')
      .subscribe(users => {
        users = users.map(user => {
          user.g26_3_date = new Date(user.g26_3_date);
          return user;
        });
        this.atemschutzList = users;
        this.atemschutz = new MatTableDataSource<AtemschutzUser>();
        this.atemschutz.data = users;
        this.atemschutz.paginator = this.paginator;
        this.atemschutz.filterPredicate = this.filter();
        this.atemschutz.sortingDataAccessor = (item: AtemschutzUser, property) => {
          switch (property) {
            case 'user': return item.lastname;
            default: return item[property];
          }
        };
        this.atemschutz.sort = this.sort;
        this.atemschutzListLoading = false;
      });
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.atemschutz.filter = JSON.stringify({ search: this.searchFilter, typ: this.typFilter });
  }

  applyTypFilter($event: MatSelectChange) {
    this.typFilter = $event.value;
    this.atemschutz.filter = JSON.stringify({ search: this.searchFilter, typ: this.typFilter });
  }

  filter(): (data: AtemschutzUser, filter: string) => boolean {
    let filterFunction = function (data: AtemschutzUser, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      if (searchTerms.typ.length !== 0) {
        var result = true;
        searchTerms.typ.forEach(typ => {
          if (typ === 'bluePoint' && !data.bluePoint)
            result = false;
          if (typ === 'csa' && !data.csa)
            result = false;
        });
        if (data.userID.toString().indexOf(searchTerms.search.toLowerCase()) === -1 && data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1)
          result = false;
        return result;
      } else {
        return (data.userID.toString().indexOf(searchTerms.search.toLowerCase()) !== -1 || data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) !== -1);
      }
    };
    return filterFunction;
  }

  valid(date: Date) {
    return DateTime.fromJSDate(date) < DateTime.now();
  }

  formatDate(date: Date) {
    const res = DateTime.fromJSDate(date).toFormat('dd.MM.yyyy');
    if (res === '01.01.1970')
      return '';
    return res;
  }
}
