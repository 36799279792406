<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Benachrichtigungen</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <!-- <a mat-mini-fab color="primary" class="pageNext" (click)="readAll();" *ngIf="existUnreadMessages">
          <mat-icon>done_all</mat-icon>
        </a> -->
        <a mat-mini-fab color="primary" class="pageNext" routerLink="/admin/notifications"
          *ngIf="_login.hasPermission('admin-notification')" matTooltip="Neue Benachrichtigung versenden">
          <mat-icon>notification_add</mat-icon>
        </a>
        <div *ngIf="showLoader">
          <mat-progress-bar mode="query"></mat-progress-bar>
        </div>

        <div class="alert alert-warning" *ngIf="!showLoader && notificationList.length == 0">
          <b>Es sind keine Benachrichtigungen vorhanden!</b>
        </div>
        <div class="table-responsive" [hidden]="showLoader || notificationList.length == 0">
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="name"
            matSortDirection="asc" matSortDisableClear>
            <!-- Title Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
              <td mat-cell *matCellDef="let element" routerLink="details/{{element.id}}">{{element.title}}</td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
              <td mat-cell *matCellDef="let element" routerLink="details/{{element.id}}">
                <label>{{ getFormattedDateTime(element.date) }}</label>
              </td>
            </ng-container>

            <!-- From Column -->
            <ng-container matColumnDef="from">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Absender</th>
              <td mat-cell *matCellDef="let element" routerLink="details/{{element.id}}">
                <label>{{element.firstname }} {{ element.lastname }}</label>
              </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <a mat-flat-button color="primary" (click)="readAll();" *ngIf="existUnreadMessages" style="width: 100%;">
                  <mat-icon>done_all</mat-icon> alle gelesen
                </a>
              </th>
              <td mat-cell *matCellDef="let element">
                <button type="button" mat-icon-button color="green" *ngIf="element.read===0"
                  (click)="readSpecific(element.id)">
                  <mat-icon>done</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons
          [hidden]="showLoader || notificationList.length == 0"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>