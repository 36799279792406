import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import imageCompression from 'browser-image-compression';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfigModule, getHostUrl, getHost } from 'src/app/util/config';
import { ProfileComponent } from '../profile.component';
import * as dayjs from 'dayjs';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { Login } from 'src/app/util/login';
import { filter } from 'rxjs';

@Component({
  selector: 'app-profile-atemschutz',
  templateUrl: './profile-atemschutz.component.html',
  styleUrls: ['./profile-atemschutz.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class ProfileAtemschutzComponent implements OnInit {

  @Input() parent: ProfileComponent;

  @ViewChild('uploadG263', { static: false }) g263Dialog: TemplateRef<any>;

  @ViewChild('resizeG263', { static: false }) resizeG263: TemplateRef<any>;

  @ViewChild('extendDialog', { static: false }) extendDialog: TemplateRef<any>;

  confirmation: boolean = false;

  turn: number = 0;

  g263picture: string = '';

  G263FileUrl: any = null;

  croppedG263Image: any = '';

  imageG263ChangedEvent: any = '';

  imageG263Loading: Boolean = true;

  G263Loading: Boolean = false;

  g263Valid: Boolean = false;

  notificationPayload: any = {};

  optionsCompress: any = {
    maxSizeMB: 5,
  };

  constructor(private _dialog: MatDialog, private _config: ConfigModule, private _http: HttpClient, public _login: Login) { }

  ngOnInit(): void {
    this._http.get<string>(`${getHostUrl()}g263?g26_3_picture=${this.parent.userInfo.atemschutz.g26_3_picture}`).subscribe({
      next: data => {
        this.g263picture = data;
      }
    });
    this._http.get<any>(`${getHostUrl()}profile/atemschutz/valid`).subscribe(data => {
      this.g263Valid = data[0];
    });

    if (dayjs(this.today).isAfter(dayjs(this.parent.userInfo.masterdata.birthdate).add(50, 'years')))
      this.endDate = dayjs(this.today).add(1, 'years').toDate();
    else
      this.endDate = dayjs(this.today).add(3, 'years').toDate();
  }

  getFormattedDate(dateValue: string): string {
    if (dateValue === null || dateValue === undefined || dateValue === '01.01.1970')
      return '';
    return dayjs(dateValue).format('DD.MM.YYYY');
  }

  /**
   * G26/3 Picture Upload
   */
  openG263PictureDialog() {
    const pictureDialog = this._dialog.open(this.g263Dialog, { disableClose: true });
    pictureDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => pictureDialog.close());
  }

  upload($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.confirmation = false;
    this.turn = 0;
    setTimeout(() => {
      this.imageG263ChangedEvent = $event;
      const resizeDialog = this._dialog.open(this.resizeG263, { disableClose: true });
      resizeDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => resizeDialog.close());
    }, 400);
  }

  imageG263Loaded() {
    setTimeout(() => {
      this.imageG263Loading = false;
    }, 800);
  }

  imageG263Cropped(event: ImageCroppedEvent) {
    this.croppedG263Image = event.blob;
  }

  loadImageFailed() {
    this._config.showSnackbar(
      'Das Bild konnte nicht geladen werden!',
      2000,
      'error',
    );
  }

  saveG263() {
    this.G263Loading = true;
      imageCompression(this.croppedG263Image, this.optionsCompress).then((compressedFile) => {
        this.blobToBase64(compressedFile).then(compressed => {
          this._http.put(getHostUrl() + 'profile/g26_3', {
            g26_3_picture: compressed,
          }).subscribe((resp: any) => {
            this._config.showSnackbar('G26/3 gespeichert!', 2000, 'success');
            this._http.get<string>(`${getHostUrl()}g263?g26_3_picture=${resp.g26_3_picture}`).subscribe({
              next: data => {
                this.g263picture = data;
              }
            });
            this.G263FileUrl = null;
            this.croppedG263Image = null;
            this.imageG263Loading = true;
            this.confirmation = false;
            this.turn = 0;
            this.G263Loading = false;
            this._http.get<any>(`${getHostUrl()}profile/atemschutz/valid`).subscribe(data => {
              this.g263Valid = data[0];
            });
          }, error => {
            if (error.error === 5001)
              this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
            else
              this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
            this.G263Loading = false;
          });
        });
      }).catch(function (error) {
        console.log(error.message);
      });
  }

  closeReset() {
    this.G263FileUrl = null;
    this.croppedG263Image = null;
    this.imageG263Loading = true;

    this.confirmation = false;
    this.turn = 0;
  }

  turnRight() {
    this.turn++;
  }

  turnLeft() {
    this.turn--;
  }


  b64toBlob(dataURI): Blob {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  sendNotification() {
    this.notificationPayload.text = `<p>Die G26/3 von ${this.parent.userInfo.masterdata.firstname} ${this.parent.userInfo.masterdata.lastname} wurde erneuert.</p>\n\n` +
      `<p> Das neue Ablaufdatum ist: ${this.getFormattedDate(this.parent.userInfo.atemschutz.g26_3_date)}</p>` +
      `Weitere Infos sind im <a href="${getHost()}admin/user/edit/${this.parent.env.userID}/atemschutz">Benutzerprofil</a> zu finden.<br/>`;
    this.notificationPayload.subject = `Erneuerung G26/3 ${this.parent.userInfo.masterdata.firstname} ${this.parent.userInfo.masterdata.lastname}`;
    this.notificationPayload.groups = [34];
    this.notificationPayload.attachments = null;

    this._http.post<any>(`${getHostUrl()}admin/notifications/sendGroups`, {
      payload: this.notificationPayload,
    }).subscribe((data) => {
      this._config.showSnackbar('Benachrichtigung an die Atemschutzwerkstatt gesendet.', 2000, 'success');
    }, (error) => {
      this._config.showSnackbar('Es ist ein Fehler aufgetreten!', 2000, 'error');
    });
  }

  today: Date = new Date();

  endDate: Date;

  extendingG263Date() {
    const extendigDialog = this._dialog.open(this.extendDialog, { disableClose: true });
    extendigDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => extendigDialog.close());
  }

  ong263DateChange($event: any) {
    this.parent.userInfo.atemschutz.g26_3_date = $event.value;
  }

  onDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.parent.userInfo.atemschutz.g26_3_date = new Date(dateString);
  }

  extendG263Save() {
    this.parent._spinner.show();
    this.parent.userInfo.atemschutz.g26_3_date = dayjs(this.parent.userInfo.atemschutz.g26_3_date).format('YYYY-MM-DD');
    this._http.post(getHostUrl() + 'profile/atemschutz/extend', { g26_3_date: this.parent.userInfo.atemschutz.g26_3_date }).subscribe(resp => {
      this.parent._spinner.hide();
      this._config.showSnackbar('Atemschutzdaten gespeichert!', 2000, 'success');
      this.parent.fetchAtemschutzData();
      this.sendNotification();
    }, error => {
      this.parent._spinner.hide();
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      this.parent.fetchAtemschutzData();
    });
  }
}
