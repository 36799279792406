<div class="row">
  <div id="pdf" class="col-12 offset-lg-2"></div>
  <div class="col-lg-4 col-md-12 col-12" *ngIf="currentTab === 4 && !showLoader && !isEnding" [@inOutAnimation]>
    <h3 class="section-header">Einsatzdaten</h3>
    <mat-card>
      <mat-card-content>
        <app-alarms-final-overview></app-alarms-final-overview>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-lg-4 col-md-12 col-12" [hidden]="currentTab !== 3" [@inOutAnimation]>
    <h3 class="section-header">Bericht Vorlagen</h3>
    <mat-card>
      <mat-card-content>
        <app-alarms-report-templates [report]="report" #reportTemplate></app-alarms-report-templates>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-lg-8 col-md-12 col-12 offset-lg-2"
    [ngClass]="{ 'offset-lg-2': (currentTab !== 4 || showLoader || isEnding) && currentTab !== 3}">
    <h3 class="section-header">Einsatz: {{general?.getKeyword(general?.group.get('keyword')?.value || '')?.name}} -
      {{general?.group.get('street').value || ''}} {{general?.group.get('housenumber').value || ''}}</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <div class="clearfix">
          <mat-spinner class="float-right" [diameter]="30" style="margin-top: -40px" color="primary"
            [hidden]="!showLoader">
          </mat-spinner>
        </div>
        <ng-container *ngTemplateOutlet="alarmContent"></ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #alarmContent>
  <div class="mt-4 row" *ngIf="showBigLoader">
    <div class="col-6">
      <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
    </div>
    <div class="col-6">
      <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
    </div>
    <div class="col-12">
      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
    </div>
  </div>

  <mat-horizontal-stepper labelPosition="bottom" #stepper (selectionChange)="notifySelectionChange($event)"
    [hidden]="showBigLoader">
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>

    <mat-step [stepControl]="general.group" errorMessage="Daten fehlerhaft!">
      <ng-template matStepLabel>Einsatzdaten</ng-template>
      <app-alarms-general #general></app-alarms-general>

      <div class="mt-3 pb-2">
        <button mat-raised-button matStepperNext color="primary" class="float-right" *ngIf="alarmID">
          Weiter
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button mat-raised-button color="success" class="float-right" *ngIf="!alarmID" [disabled]="!general.group.valid"
          (click)="general.addAlarmGeneral()">
          Einsatz anlegen
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step [hasError]="vehicles.hasError && vehicles.opened" errorMessage="Daten fehlerhaft!" *ngIf="alarmID">
      <ng-template matStepLabel>Fahrzeuge</ng-template>
      <app-alarms-vehicles #vehicles></app-alarms-vehicles>

      <div class="mt-3 pb-2">
        <button mat-raised-button matStepperPrevious color="primary">
          <mat-icon>keyboard_arrow_left</mat-icon>
          Zurück
        </button>
        <button mat-raised-button matStepperNext color="primary" class="float-right">
          Weiter
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="details.group" errorMessage="Daten fehlerhaft!" *ngIf="alarmID">
      <ng-template matStepLabel>Details</ng-template>
      <app-alarms-details #details></app-alarms-details>

      <div class="mt-3 pb-2">
        <button mat-raised-button matStepperPrevious color="primary">
          <mat-icon>keyboard_arrow_left</mat-icon>
          Zurück
        </button>
        <button mat-raised-button matStepperNext color="primary" class="float-right">
          Weiter
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="report.group" errorMessage="Daten fehlerhaft!" *ngIf="alarmID">
      <ng-template matStepLabel>Bericht</ng-template>
      <app-alarms-report #report></app-alarms-report>

      <div class="mt-3 pb-2">
        <button mat-raised-button matStepperPrevious color="primary">
          <mat-icon>keyboard_arrow_left</mat-icon>
          Zurück
        </button>
        <button mat-raised-button matStepperNext color="primary" class="float-right">
          Weiter
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="fez.group" errorMessage="Daten fehlerhaft!" *ngIf="alarmID">
      <ng-template matStepLabel>Abschluss</ng-template>
      <app-alarms-fez #fez></app-alarms-fez>

      <div class="mt-3 pb-2">
        <button mat-raised-button matStepperPrevious color="primary">
          <mat-icon>keyboard_arrow_left</mat-icon>
          Zurück
        </button>
        <div class="float-right">
          <button mat-raised-button color="primary" class="me-3" (click)="openInvoicePopup()"
            *ngIf="statusCategory==='invoice' && status ==='Rechnungsfreigabe'" target="_blank"
            [ngClass]="env.mobile ? 'mobileButtons' : ''">
            <span *ngIf="status==='Rechnungsfreigabe'">Rechnung prüfen </span>
            <mat-icon *ngIf="status==='Rechnungsfreigabe'">reviews</mat-icon>
          </button>
          <a mat-raised-button color="primary" class="me-3" target="_blank"
            href="{{ baseURL }}alarms/invoice/{{alarmID}}/generate?sessionToken={{ _login.getSessionToken() }}"
            *ngIf="statusCategory==='invoice' && status !=='Rechnungsfreigabe' && status !== 'ohne Rechnung'"
            [ngClass]="env.mobile ? 'mobileButtons' : ''">
            Rechnung ansehen
            <mat-icon>reviews</mat-icon>
          </a>
          <a mat-raised-button color="primary" class="me-3" target="_blank"
            href="{{ baseURL }}alarms/report/{{alarmID}}/generate?sessionToken={{ _login.getSessionToken() }}"
            *ngIf="statusCategory==='invoice' || statusCategory === 'report'"
            [ngClass]="env.mobile ? 'mobileButtons' : ''">
            Bericht ansehen
            <mat-icon>assignment</mat-icon>
          </a>
          <button mat-raised-button color="primary" class="me-3" (click)="loadConfirmation()"
            *ngIf="statusCategory !== 'working'" [ngClass]="env.mobile ? 'mobileButtons' : ''">
            Bestätigungen drucken
            <mat-icon>print</mat-icon>
          </button>
          <button mat-raised-button color="warn" class="me-3" (click)="reeditAlarm()" *ngIf="status === 'ohne Rechnung' || status === 'Rechnung erstellt'"
            [ngClass]="env.mobile ? 'mobileButtons' : ''">
            Einsatz zurückstufen
            <mat-icon>edit</mat-icon>
          </button>
          <!-- <button mat-raised-button color="warn" class="me-3" (click)="discardReport()"
            *ngIf="status==='Rechnungsfreigabe'" [ngClass]="env.mobile ? 'mobileButtons' : ''">
            Rechnung ablehnen
            <mat-icon>restore</mat-icon>
          </button> -->
          <button (click)="finalizeReport()" mat-raised-button color="success" [disabled]="(!general.group.valid && !general.group.disabled) || 
              vehicles.hasError || (!details.group.valid && !details.group.disabled) || 
              (!fez.group.valid && !fez.group.disabled) || showLoader || !saveAble || disabledElements"
            *ngIf="status === 'in Bearbeitung' || (_login.hasPermission('alarm-edit-reviewer') && status==='in Kontrolle') || (_login.hasPermission('alarm-release-invoice') && status === 'Rechnungsfreigabe')"
            [ngClass]="env.mobile ? 'mobileButtons' : ''">
            <span *ngIf="status === 'in Bearbeitung'">Bericht abschließen </span>
            <span *ngIf="status=== 'in Kontrolle'">Kontrolle abschließen </span>
            <span *ngIf="status=== 'Rechnungsfreigabe'">Rechnung freigeben </span>
            <mat-icon *ngIf="status==='in Bearbeitung'">save</mat-icon>
            <mat-icon *ngIf="status==='in Kontrolle'">reviews</mat-icon>
            <mat-icon *ngIf="status==='Rechnungsfreigabe'">receipt</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</ng-template>