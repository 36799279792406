<mat-dialog-header>
  <div class="clearfix">
    <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
      <mat-icon>close</mat-icon>
    </a>
    <h2 mat-dialog-title class="float-left">{{_data.name}}</h2>
  </div>
</mat-dialog-header>
<mat-dialog-content>
  <mat-form-field appearance="fill" class="input-full-width">
    <mat-label>Beschreibung</mat-label>
    <input matInput disabled [value]="_data.description">
  </mat-form-field>
  <mat-form-field class="input-full-width">
    <mat-label>Gruppen</mat-label>
    <mat-select multiple placeholder="Gruppen" [(ngModel)]="_data.groups">
      <mat-option>
        <ngx-mat-select-search [formControl]="groupFilterCtrl"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let group of groupFilter | async" [value]="group.id">{{group.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>