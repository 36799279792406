import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { Statistic, StatisticCategory, StatisticParameters, StatisticParams } from '@ffo/mitgliederbereich-types';
import { ECharts, env } from 'echarts';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { environment } from 'src/environments/environment';
import { ThemingService } from '../../util/services/theming.service';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DateTime } from 'luxon';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public _login: Login,
    private _http: HttpClient,
    private _config: ConfigModule,
    private _route: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private _theme: ThemingService) { }

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  statisticTypeList: StatisticCategory[] = [];
  statisticFilterCtrl: UntypedFormControl = new UntypedFormControl();
  statisticFilter: ReplaySubject<StatisticCategory[]> = new ReplaySubject<StatisticCategory[]>(1);
  currentStatistic: Statistic = null;
  resultedData: any = null;
  resultedDataColumns: string[] = [];
  showLoader = false;
  runningExport = false;

  printData: any = null;

  isMobile = false;

  params: Record<StatisticParameters, any> = null;
  echartsInstance: ECharts;

  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.loadStatisticTypes();
    this._config.setTitle('Auswertungen');
    this.statisticFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterStatistics();
      });
    this.isMobile = environment.mobile;
  }

  ngAfterViewInit() {
    this.stepper?.selectionChange.subscribe(result => {
      if (result?.selectedIndex === 0) {
        this.currentStatistic = null;
        this.printData = null;
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  resetData() {
    this.resultedData = null;
    this.resultedDataColumns = [];
    this.params = null;
  }

  executeStatistic() {
    this.showLoader = true;
    this._http.post<any>(`${getHostUrl()}statistics/execute`, {
      statisticId: this.currentStatistic.id,
      params: this.params,
    }).subscribe(result => {
      if (!result || result.length === 0) {
        this.showLoader = false;
        this._config.showSnackbar('Keine Daten gefunden!', 3000, 'warning');
        this.resetData();
        return;
      }
      this.resultedDataColumns = this.extractColumns(result);
      this.resultedData = result;
      this.showLoader = false;
      setTimeout(() => {
        this.stepper.selectedIndex = 1;
      }, 80);
    }, error => {
      this.showLoader = false;
      this.stepper.selectedIndex = 0;
      this._config.showSnackbar('Konnte Auswertung nicht starten!', 3000, 'error');
    });
  }

  buildCategory(data: Statistic[]) {
    const categories: StatisticCategory[] = [];
    data.forEach(elem => {
      if (!categories.find(c => c.name == elem.category))
        categories.push({
          name: elem.category,
          data: [],
        });
    });
    return categories;
  }

  buildData(data: Statistic[]) {
    const categories = this.buildCategory(data);
    categories.forEach(elem => {
      elem.data = data.filter(s => s.category == elem.name);
    });
    return categories;
  }

  extractColumns(data) {
    return Object.keys(data[0]).filter((v) => v.startsWith('meta_') === false);
  }

  export(): void {
    this._bottomSheet.open(this.menu);
  }

  exportToPicture(): void {
    this.runningExport = true;
    const currentTheme = this._theme.theme.value;
    const width = this.echartsInstance.getWidth();
    const height = this.echartsInstance.getHeight();
    this._theme.toggleDarkMode(false);
    setTimeout(() => {
      const filename: string = this.getFileName();
      if (!environment.mobile && this.currentStatistic.type === 'piechart')
        this.echartsInstance.resize({ width: 425, height: 400 });
      const image = this.echartsInstance.getDataURL({
        type: 'png',
        backgroundColor: 'rgba(255, 255, 255, 0)',
      });
      const link = document.createElement('a');
      link.setAttribute('href', image);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this._theme.toggleDarkMode(currentTheme == 'light-theme' ? false : true);
      this.echartsInstance.resize({ width, height });
      this.runningExport = false;
    }, 1000);
  }

  updatePrintData($event) {
    this.printData = $event;
  }

  exportPDF() {
    const filename: string = this.getFileName();
    const logo: HTMLImageElement = new Image();
    logo.src = getHostUrl() + 'logo/256';
    const doc = new jsPDF({
      orientation: this.currentStatistic.orientation
    });

    doc.setFontSize(20);
    doc.setTextColor(40);
    doc.text(filename, 10, 15);

    const data = (this.printData || this.resultedData).map(s => {
      const arr = [];
      this.resultedDataColumns.forEach(column => {
        arr.push(String(s[column]));
      });
      return arr;
    });

    const totalPagesExp = '{total_pages_count_string}';

    (doc as any).autoTable({
      startY: 23,
      headStyles: {
        fillColor: '#ffc107',
        textColor: '#000',
      },
      margin: { top: 23 },
      head: [this.resultedDataColumns.map(s => s.replace('_', ' '))],
      body: data,
      didDrawPage: function (_data) {
        var str = 'Seite ' + (doc as any).internal.getNumberOfPages() + ' von ';
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        const xOffset = pageWidth - ((doc.getStringUnitWidth(str) * 6));
        str += totalPagesExp;

        var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        doc.setFontSize(10);

        doc.text(str, xOffset, pageHeight - 8);
        doc.text('Nur für den internen Dienstgebrauch', 10, pageHeight - 8);

        doc.addImage(logo, 'image/jpeg', pageWidth - 20, 2, 20, 20);
      },
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save(`${filename}.pdf`);
  }

  exportToCsv() {
    const filename: string = this.getFileName();
    const rows: object[] = (this.printData || this.resultedData);
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = this.extractColumns(rows);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  getFileName(): string {
    if (this.currentStatistic.name === 'Termine pro Monat') {
      return `Termine pro Monat - ${this.getMonthName(this.params.month)}`;
    }
    return this.currentStatistic.name;
  }

  getMonthName(month: number): string {
    let date = DateTime.now();
    date = date.set({ month: month }).setLocale('de-DE');
    return date.monthLong;
  }

  loadStatisticTypes() {
    this._http.get<Statistic[]>(`${getHostUrl()}statistics/list`).subscribe(result => {
      this.statisticTypeList = this.buildData(result);
      this.statisticFilter.next(this.statisticTypeList);
      this.statisticFilterCtrl.updateValueAndValidity();
      try {
        this._route.params.subscribe(route => {
          if (route.statisticId) {
            const res = this.statisticTypeList.map(c => c.data.find(s => s.id === parseInt(route.statisticId, 10))).filter(c => c);
            if (res && res.length == 1) {
              this.currentStatistic = res[0];
              this.executeStatistic();
            }
          }
        });
      } catch (e) { }
    }, error => {
      this._config.showSnackbar('Konnte Auswertungen nicht abrufen!', 3000, 'error');
    });
  }

  setParamValue(param: StatisticParameters, $event: any) {
    this.params = {
      ...this.params,
      [param]: $event,
    };
  }

  getParamsValid(): boolean {
    let valid: boolean = true;
    this.currentStatistic.params.forEach((param: StatisticParams) => {
      if (param.required && (!this.params || Object.keys(this.params).includes(param.param) === false)) {
        valid = false;
        return;
      }
    });
    return valid;
  }

  protected filterStatistics() {
    if (!this.statisticTypeList) {
      return;
    }
    // get the search keyword
    let search = this.statisticFilterCtrl.value;
    if (!search) {
      this.statisticFilter.next(this.statisticTypeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks
    this.statisticFilter.next(
      this.statisticTypeList.map(elem => ({
        name: elem.name,
        data: elem.data.filter(s => s.name.toLocaleLowerCase().includes(search)),
      })).filter(c => c.data.length > 0),
    );
  }

  setChart($chart: ECharts) {
    this.echartsInstance = $chart;
  }
}
