import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs';

@Injectable()
export class UpdateService {

  constructor(updates: SwUpdate) {
    updates.activateUpdate().then((success: boolean) => {
      console.log('PWA Updates applied');
      document.location.reload();
    }, (error: any) => {
      console.error(error);
    });

    updates.versionUpdates.pipe(filter(up => up.type === 'VERSION_READY')).subscribe(() => {
      document.location.reload();
    });
  }
}
