import { Component, OnInit, ViewChildren, QueryList, Inject, OnDestroy, ViewChild, TemplateRef, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Login } from 'src/app/util/login';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import * as dayjs from 'dayjs';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';

interface Event {
  id: number;
  name: string;
  date: Date;
}

interface Notification {
  id: number;
  title: string;
  text: string;
  date: Date;
  name: string;
  userID: number;
  read: number;
}

interface User {
  id: number;
  name: string,
  firstname: string;
  lastname: string;
  dateOfExpiry: Date;
  classes: string;
}

@Component({
  selector: 'app-maschinisten',
  templateUrl: './maschinisten.component.html',
  styleUrls: ['./maschinisten.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class MaschinistenComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator: QueryList<MatPaginator>;

  @ViewChildren(MatSort) sort: QueryList<MatSort>;

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  eventsColumns: string[] = ['name', 'date'];

  events: MatTableDataSource<Event>;

  notificationsColumns: string[] = ['title', 'name', 'date', 'action'];

  notifications: MatTableDataSource<Notification>;

  notificationsList: Notification[] = [];

  maschinistenColumns: string[] = ['id', 'maschinist', 'dateOfExpiry', 'lastChecked', 'classes'];

  maschinistenList: User[] = [];

  maschinisten: MatTableDataSource<User>;

  maschinistenListLoading: boolean = true;

  classesFilter: any[] = [];

  searchFilter: string = '';


  env = environment;

  constructor(private _http: HttpClient, private _login: Login, private _config: ConfigModule, private _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this._config.setTitle('Maschinisten');
    this.loadUsers();
    this.loadNotifications();

    if ($(window).width() < 600) {
      this.maschinistenColumns = ['id', 'maschinist', 'lastChecked'];
      this.notificationsColumns = ['title', 'name', 'date'];
    }
  }

  loadNotifications() {
    this._http.get<Notification[]>(`${getHostUrl()}admin/fields/maschinisten/notifications?groupID=29`).subscribe((data) => {
      this.notificationsList = data;
      this.notifications = new MatTableDataSource<Notification>();
      this.notifications.data = data;
      this.notifications.paginator = this.paginator.toArray()[0];
      this.notifications.sort = this.sort.toArray()[0];

      this.notificationsList.forEach((item) => {
        if (item.userID === -1) {
          item.name = 'System';
        }
      });

      this.notifications.sortingDataAccessor = (item: any, property) => {
        switch (property) {
          case 'date':
            dayjs(item.date).format('x');
          default: return item[property];
        }
      };
    });
  }

  loadUsers() {
    this._http.get<User[]>(getHostUrl() + 'admin/fields/maschinisten/list')
      .subscribe(data => {
        this.maschinistenList = data;
        this.maschinistenListLoading = false;
        this.maschinisten = new MatTableDataSource<User>();
        this.maschinisten.data = this.maschinistenList;
        this.maschinisten.paginator = this.paginator.toArray()[1];
        this.maschinisten.filterPredicate = this.filter();
        this.maschinisten.sort = this.sort.toArray()[1];
        this.maschinisten.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'maschinist': return item.lastname;
            case 'dateOfExpiry':
              if (this.maschinisten.sort.direction?.toString() === 'asc' && this.getFormattedDate(item.dateOfExpiry) === 'unbegrenzt')
                item.dateOfExpiry = '2100-01-01';
              else if (this.maschinisten.sort.direction?.toString() === 'desc' && this.getFormattedDate(item.dateOfExpiry) === 'unbegrenzt')
                item.dateOfExpiry = '1901-01-01';
            default: return item[property];
          }
        };
      });
  }

  getFormattedDate(dateValue: any, control?: boolean) {
    if (!dateValue) {
      return '';
    }
    if (control && (dateValue === null || dateValue?.toString() === '0000-00-00' || dateValue === '' || dateValue?.toString() === '1901-01-01' || dateValue?.toString() === '1900-12-31'))
      return ('keine Kontrolle');
    if (dateValue === null || dateValue?.toString() === '0000-00-00' || dateValue === '' || dateValue?.toString() === '1901-01-01' || dateValue?.toString() === '1900-12-31' || dateValue?.toString() === '2100-01-01')
      return ('unbegrenzt');
    if (dateValue === '-1')
      return ('');
    return dayjs(dateValue).format('DD.MM.YYYY');
  }

  getFormattedDateTime(dateValue: any) {
    if (dateValue === null || dateValue?.toString() === '0000-00-00' || dateValue === '')
      return ('');
    return dayjs(dateValue).format('DD.MM.YYYY HH:mm');
  }

  valid(expiryDate: Date, lastChecked: Date): boolean {
    if (dayjs(lastChecked).isBefore(dayjs().add(-6, 'months')))
      return true;
    if (this.getFormattedDate(expiryDate) === 'unbegrenzt')
      return false;
    return dayjs(expiryDate).isBefore(dayjs());
  }


  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.maschinisten.filter = JSON.stringify({ search: this.searchFilter, classes: this.classesFilter });
  }

  applyClassesFilter($event: MatSelectChange) {
    this.classesFilter = $event.value;
    this.maschinisten.filter = JSON.stringify({ search: this.searchFilter, classes: this.classesFilter });
  }

  filter(): (data: User, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      if (searchTerms.classes.length !== 0) {
        var result = true;
        searchTerms.classes.forEach(licenceClass => {
          if (data.classes.indexOf(licenceClass) === -1)
            result = false;
        });
        if (data.id?.toString().indexOf(searchTerms.search.toLowerCase()) === -1 && data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1)
          result = false;
        return result;
      } else {
        return (data.id?.toString().indexOf(searchTerms.search.toLowerCase()) !== -1 || data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) !== -1);
      }
    };
    return filterFunction;
  }

  readSpecific(notificationId: number) {
    this._http
      .delete<any>(
      `${getHostUrl()}admin/fields/maschinisten/notifications?notificationId=${notificationId}`,
    )
      .subscribe((data) => {
        this.loadNotifications();
        this._config.showSnackbar(
          'Benachrichtigung wurde als gelesen markiert.',
          2000,
          'success',
        );
      });
  }

  openNotification(notification: Notification): void {
    this._http
      .delete<any>(
      `${getHostUrl()}admin/fields/maschinisten/notifications?notificationId=${notification.id}`,
    )
      .subscribe((data) => {
        this.loadNotifications();
      });
    console.log(notification);
    this._bottomSheet.open(BottomSheetNotificationMaschinistenComponent, {
      data: notification,
    });
  }

  openLicenceMenu() {
    this._bottomSheet.open(this.menu);
  }
}

@Component({
  selector: 'app-maschinisten-notification',
  template: `<div class="text-center">
  <br />
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
    <mat-icon>close</mat-icon>
  </a>
  <h4>{{ title }}</h4>
  <br />
  <span [innerHTML]="message"></span>
  <hr />
  <i><a routerLink="/admin/user/edit/{{userID}}/driving" (click)="_bottomSheet.dismiss()">{{ name }}</a> am {{ getFormattedDateTime(from) }}</i>
</div>`,
})
export class BottomSheetNotificationMaschinistenComponent implements OnDestroy {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: Notification,
    public _bottomSheet: MatBottomSheet) {
    this.userID = data.userID;
    this.from = data.date;
    this.name = data.name;
    this.title = data.title;
    this.message = data.text;
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }

  userID: number;

  from: Date;

  name: string;

  title: string;

  message: string;

  getFormattedDateTime(dateValue: any) {
    if (dateValue?.toString() === '0000-00-00' || dateValue === '')
      return ('');
    return dayjs(dateValue).format('DD.MM.YYYY HH:mm');
  }
}