<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">{{firstname}}
      {{lastname}}</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>
        <div *ngIf="!age18" [ngClass]="env.mobile ? '' : 'desktop18'">
          <mat-error>{{firstname}} {{lastname}} ist noch nicht 18 Jahre alt, daher können keine Atemschutzdaten
            gepflegt werden.</mat-error>
        </div>
        <div class="row" *ngIf="age18">
          <div class="col-lg-3 col-md-4 col-12">
            <mat-form-field id="g263" *ngIf="!env.mobile" class="input-full-width" appearance="outline">
              <mat-label>Ablaufdatum G26.3</mat-label>
              <input matInput [matDatepicker]="g263" [min]="today" [max]="endDate" placeholder="Ablaufdatum G26.3"
                [value]="userInfo.g26_3_date" (dateChange)="ong263DateChange($event)" (change)="onDateChange($event)"
                (focus)="g263.open()" style="cursor: pointer;" required
                [disabled]="!_login.hasPermission('admin-user-atemschutz-edit')">
              <mat-datepicker-toggle matSuffix [for]="g263"></mat-datepicker-toggle>
              <mat-datepicker #g263 [startAt]="today" startView="multi-year"></mat-datepicker>
            </mat-form-field>
            <mat-form-field id="g263" *ngIf="env.mobile" class="input-full-width" appearance="outline">
              <mat-label>Ablaufdatum G26.3</mat-label>
              <input matInput [matDatepicker]="g263" [min]="today" [max]="endDate" placeholder="Ablaufdatum G26.3"
                [value]="userInfo.g26_3_date" (dateChange)="ong263DateChange($event)" (change)="onDateChange($event)"
                (focus)="g263.open()" style="cursor: pointer;" required
                [disabled]="!_login.hasPermission('admin-user-atemschutz-edit')">
              <mat-datepicker-toggle matSuffix [for]="g263"></mat-datepicker-toggle>
              <mat-datepicker #g263 touchUi [startAt]="today" startView="multi-year"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-4 col-12">
            <mat-form-field id="briefing" *ngIf="!env.mobile" class="input-full-width" appearance="outline">
              <mat-label>Einweisungsdatum</mat-label>
              <input matInput [matDatepicker]="briefing" [max]="today" placeholder="Einweisungsdatum"
                [value]="userInfo.briefingDate" (dateChange)="onBriefingDateChange($event)"
                (change)="onBriefingDateChange($event)" (focus)="briefing.open()" style="cursor: pointer;" disabled>
              <!-- [disabled]="!_login.hasPermission('admin-user-atemschutz-edit')"> -->
              <mat-datepicker-toggle matSuffix [for]="briefing"></mat-datepicker-toggle>
              <mat-datepicker #briefing [startAt]="today" startView="multi-year"></mat-datepicker>
            </mat-form-field>
            <mat-form-field id="briefing" *ngIf="env.mobile" class="input-full-width" appearance="outline">
              <mat-label>Einweisungsdatum</mat-label>
              <input matInput [matDatepicker]="briefing" [max]="today" placeholder="Einweisungsdatum"
                [value]="userInfo.briefingDate" (dateChange)="onBriefingDateChange($event)"
                (change)="onBriefingDateChange($event)" (focus)="briefing.open()" style="cursor: pointer;" disabled>
              <!-- [disabled]="!_login.hasPermission('admin-user-atemschutz-edit')"> -->
              <mat-datepicker-toggle matSuffix [for]="briefing"></mat-datepicker-toggle>
              <mat-datepicker #briefing touchUi [startAt]="today" startView="multi-year"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-2 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>CSA</mat-label>
              <mat-select [(ngModel)]="userInfo.csa" required
                [disabled]="!_login.hasPermission('admin-user-atemschutz-edit')">
                <mat-option value="0">Nein</mat-option>
                <mat-option value="1">Ja</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-2 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Blauer Punkt</mat-label>
              <mat-select [(ngModel)]="userInfo.bluePoint" required
                [disabled]="!_login.hasPermission('admin-user-atemschutz-edit')">
                <mat-option value="0">Nein</mat-option>
                <mat-option value="1">Ja</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="clearfix" *ngIf="_login.hasPermission('admin-user-atemschutz-edit')">
          <button mat-raised-button color="success" class="float-right" (click)="saveAtemschutz()"
            [disabled]="userInfo.g26_3_date===null">Speichern
            <mat-icon>save</mat-icon></button>
          <button mat-raised-button color="access" class="float-left"
            routerLink="./assets"><mat-icon>attach_file</mat-icon> Anhänge</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>