import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {

  data = {
    materialCount: 0,
    vehicleCount: 0,
    checkCount: 0
  };

  constructor(private _config: ConfigModule,
    private _http: HttpClient,
    public _login: Login) {}

  ngOnInit(): void {
    this._config.setTitle('Materialverwaltung Dashboard');
    this.loadData();
  }

  async loadData() {
    const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material`));
    
    this.data = result;
  }
}
