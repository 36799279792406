import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-material-locations-detail',
  templateUrl: './material-locations-detail.component.html',
  styleUrls: ['./material-locations-detail.component.css']
})
export class MaterialLocationsDetailComponent implements OnInit {

  constructor(private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _config: ConfigModule,
    private _builder: FormBuilder) { }

  vehicleList = [];
  locationId = null;

  isSaving = false;
  isLoading = true;

  group: FormGroup = new FormGroup({});

  ngOnInit(): void {
    this.group = this._builder.group({
      name: ['', Validators.required]
    });

    this.locationId = this._route.snapshot.params?.locationId;
    if (this.locationId) {
      this._config.setTitle('Lagerort bearbeiten');
      this.loadLocation();
    } else {
      this._config.setTitle('Lagerort erstellen');
      this.isLoading = false;
    }
  }

  async saveData() {
    this.group.disable();
    this.isSaving = true;
    try {
      if (this.locationId) {
        await firstValueFrom(this._http.post(`${getHostUrl()}admin/material/locations/${this.locationId}`, this.group.value));
      } else {
        const result = await firstValueFrom(this._http.put<any>(`${getHostUrl()}admin/material/locations`, this.group.value));
        this._router.navigate(['material/locations/details', result.locationId]);
      }
      this._config.showSnackbar("Lagerplatz wurde gespeichert!", 3000, 'success');
      this.isSaving = false;
      this.group.enable();
    } catch (e) {
      this.isSaving = false;
      this.group.enable();
      if (e?.status == 409) {
        return this._config.showSnackbar("Ein Lagerplatz mit diesem Namen existiert bereits!", 3000, 'error');
      }
      this._config.showSnackbar("Konnte Lagerplatz nicht speichern", 3000, 'error');
    }
  }

  async loadLocation() {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/locations/${this.locationId}`));
      this.group.setValue(result);
      this.isLoading = false;
    } catch (e) {
      if (e.status == 404) {
        this._config.showSnackbar('Lagerort wurde nicht gefunden!', 3000, 'error');
        this._router.navigate(['material/locations']);
      } else {
        this._router.navigate(['material/locations']);
        this._config.showSnackbar('Ein Fehler ist aufgetreten, bitte versuche es erneut!', 3000, 'error');
      }
    }
  }
}
