<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">{{firstname}} {{lastname}}</h3>
        <mat-card>
            <mat-card-content>
                <div layout="column" layout-align="space-between center" *ngIf="showLoader"
                    style="display:flex;justify-content:center;align-items:center;">
                    <mat-spinner color="primary"></mat-spinner>
                </div>
                <div [hidden]="showLoader">
                    <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                        <mat-icon>keyboard_backspace</mat-icon>
                    </a>
                    <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
                        <mat-icon>apps</mat-icon>
                    </a>
                    <div *ngIf="isLoading">
                        <mat-progress-bar mode="query"></mat-progress-bar>
                    </div>
                    <div [hidden]="isLoading">
                        <div class="row">
                            <div class="col-xl-8 col-md-6 col-12" *ngIf="this.dataSource.data.length === 0">
                                <div class="alert alert-info">
                                    Es sind keine Laufbahnereignisse für den angegebenen Benutzer vorhanden.
                                </div>
                            </div>
                            <div class="col-xl-8 col-md-6 col-12" *ngIf="this.dataSource.data.length !== 0">
                                <mat-form-field class="input-full-width" appearance="outline">
                                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche">
                                </mat-form-field>
                            </div>
                            <div class="col-xl-3 col-md-4 col-10 mb-3">
                                <button mat-raised-button color="primary" class="input-full-width mt-1"
                                    style="height: 55px;" (click)="openCourse(null)" *ngIf="_login.hasPermission('admin-user-course-edit')">
                                    <mat-icon>assignment_add</mat-icon>
                                    Laufbahn hinzufügen
                                </button>
                            </div>
                            <div class="col-xl-1 col-md-2 col-2">
                                <a mat-mini-fab color="primary" class="mt-1"
                                    style="height: 55px; width: 55px; display:flex;justify-content:center;align-items:center;"
                                    [target]="isPWA ? '_self' : '_blank'" [hidden]="this.dataSource.data.length == 0"
                                    href="{{baseURL}}admin/user/course/timeline/{{this.userID}}?sessionToken={{_login.getSessionToken()}}">
                                    <mat-icon>print</mat-icon>
                                </a>
                            </div>
                        </div>

                        <div [hidden]="isLoading || this.dataSource.data.length == 0">
                            <div class="table-responsive">
                                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                                    matSortActive="date" matSortDirection="desc" matSortDisableClear>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            style="vertical-align: middle;">
                                            Name
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.name}}
                                        </td>
                                    </ng-container>

                                    <!-- Date Column -->
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            style="vertical-align: middle;">
                                            Datum
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{dayjs(element.date).format('DD.MM.YYYY')}} </td>
                                    </ng-container>

                                    <!-- Actions Column -->
                                    <ng-container matColumnDef="attachments">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            style="vertical-align: middle;"></th>
                                        <td mat-cell *matCellDef="let element">
                                            <a mat-icon-button [style.display]="element.fileRef ? '' : 'none'"
                                                class="float-right" matTooltip="Bescheinigung ansehen"
                                                [target]="isPWA ? '_self' : '_blank'"
                                                href="{{baseURL}}admin/user/course/preview/{{this.userID}}/{{element.id}}?sessionToken={{_login.getSessionToken()}}">
                                                <mat-icon>attach_file</mat-icon>
                                            </a>
                                            <a mat-icon-button [hidden]="!element.comment" class="float-right"
                                                style="cursor: default;" matTooltip="{{element.comment}}">
                                                <mat-icon>info</mat-icon>
                                            </a>
                                            <a mat-icon-button *ngIf="element.checked == 0" 
                                                (click)="acceptCourse(element)" class="float-right"
                                                style="cursor: default;" matTooltip="In System übernehmen">
                                                <mat-icon color="success" *ngIf="_login.hasPermission('admin-user-course-edit')">check</mat-icon>
                                            </a>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            style="vertical-align: middle;"></th>
                                        <td mat-cell *matCellDef="let element">
                                            <button mat-icon-button color="warn" matTooltip="Ereignis löschen" *ngIf="_login.hasPermission('admin-user-course-edit')"
                                                (click)="deleteCourse(element)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                            </div>
                            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>