import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule as MatButtonModule,
} from '@angular/material/button';
import {
  MatCardModule as MatCardModule,
} from '@angular/material/card';
import {
  MatDialogModule as MatDialogModule,
} from '@angular/material/dialog';
import {
  MatInputModule as MatInputModule,
} from '@angular/material/input';
import {
  MatTableModule as MatTableModule,
} from '@angular/material/table';
import {
  MatToolbarModule,
} from '@angular/material/toolbar';
import {
  MatMenuModule as MatMenuModule,
} from '@angular/material/menu';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatBadgeModule,
} from '@angular/material/badge';
import {
  MatProgressSpinnerModule as MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';
import {
  MatSnackBarModule as MatSnackBarModule,
} from '@angular/material/snack-bar';
import {
  MatSidenavModule,
} from '@angular/material/sidenav';
import {
  MatListModule as MatListModule,
} from '@angular/material/list';
import {
  MatTooltipModule as MatTooltipModule,
} from '@angular/material/tooltip';
import {
  MatFormFieldModule as MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatExpansionModule,
} from '@angular/material/expansion';
import {
  MatStepperModule,
} from '@angular/material/stepper';
import {
  MatPaginatorModule as MatPaginatorModule,
} from '@angular/material/paginator';
import {
  MatSortModule,
} from '@angular/material/sort';
import {
  MatSelectModule as MatSelectModule,
} from '@angular/material/select';
import {
  MatSlideToggleModule as MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import {
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import {
  MatAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/autocomplete';
import {
  MatCheckboxModule as MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  MatProgressBarModule as MatProgressBarModule,
} from '@angular/material/progress-bar';
import {
  MatTabsModule as MatTabsModule,
} from '@angular/material/tabs';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule as MatChipsModule } from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatGridListModule} from '@angular/material/grid-list';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatSortModule,
    MatListModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatStepperModule,
    MatToolbarModule,
    MatExpansionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatAutocompleteModule,
    MatPasswordStrengthModule.forRoot(),
    MatProgressBarModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatChipsModule,
    DragDropModule,
    MatGridListModule
  ],
  exports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatBadgeModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatStepperModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatAutocompleteModule,
    MatPasswordStrengthModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatChipsModule,
    DragDropModule,
    MatGridListModule
  ],
})
export class CustomMaterialModule { }
