import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { GuardUserStatus, GuardUser } from '@ffo/mitgliederbereich-types';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { environment } from 'src/environments/environment';
import { AdminGuardsPersonalDetailsComponent } from './details/details.component';

@Component({
  selector: 'app-admin-guards-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css'],
})
export class AdminGuardsPersonalComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  viewColumns: string[] = ['id', 'name', 'active', 'state', 'age', 'officialRank', 'mta', 'days'];
  userList: GuardUser[] = [];
  users: MatTableDataSource<GuardUser>;

  searchFilter: string = '';
  stateFilter: GuardUserStatus[] = ['WL', 'WM'];
  loading: boolean = true;

  env = environment;
  constructor(private _http: HttpClient, private _config: ConfigModule, private _dialog: MatDialog) { }

  ngOnInit(): void {
    this._config.setTitle('Sicherheitswachen | Personal');
    this.loadGuardUsers();
    if (this.env.mobile) this.viewColumns = ['id', 'active', 'state', 'days'];
  }

  loadGuardUsers(): void {
    this.loading = true;
    this._http.get<GuardUser[]>(getHostUrl() + 'admin/fields/guards/users')
      .subscribe(data => {
        this.userList = data;
        this.users = new MatTableDataSource<GuardUser>(this.userList);
        this.users.sort = this.sort;
        this.users.paginator = this.paginator;
        this.users.sortingDataAccessor = (item: GuardUser, property) => {
          switch (property) {
            case 'id':
              return item.userID;
            case 'name':
              return item.firstname + ' ' + item.lastname;
            default: return item[property];
          }
        };
        this.users.filterPredicate = this.filter();
        this.applySearch('');
        this.loading = false;
      });
  }

  applySearch(searchValue: string) {
    this.searchFilter = searchValue.toLowerCase();
    this.users.filter = JSON.stringify({ search: this.searchFilter, state: this.stateFilter });
  }

  applyStateFilter($event: MatSelectChange) {
    this.stateFilter = $event.value;
    this.users.filter = JSON.stringify({ search: this.searchFilter, state: this.stateFilter });
  }

  filter(): (data: GuardUser, filter: string) => boolean {
    let filterFunction = function (data: GuardUser, filter: string): boolean {
      if (this.loading) return false;
      let searchTerms: { search: string; state: GuardUserStatus[] } = JSON.parse(filter);
      if (searchTerms.state.length !== 0) {
        return (data.firstname.toLowerCase().indexOf(searchTerms.search) !== -1 || data.lastname.toLowerCase().indexOf(searchTerms.search) !== -1 || data.userID.toString().indexOf(searchTerms.search) !== -1) && searchTerms.state.includes(data.state);
      } else {
        return data.firstname.toLowerCase().indexOf(searchTerms.search) !== -1 || data.lastname.toLowerCase().indexOf(searchTerms.search) !== -1 || data.userID.toString().indexOf(searchTerms.search) !== -1;
      }
    };
    return filterFunction;
  }

  openDetailsDialog(user: GuardUser): void {
    const dialog = this._dialog.open(AdminGuardsPersonalDetailsComponent, { data: user });
    dialog.afterClosed().subscribe(() => {
      this.loadGuardUsers();
    });
  }
}
