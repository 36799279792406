<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Atemschutzgeräteträger</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <div class="row">
          <div class="col-lg-8 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Filter</mat-label>
              <mat-select multiple (selectionChange)="applyTypFilter($event)">
                <mat-option value="bluePoint">Blauer Punkt</mat-option>
                <mat-option value="csa">CSA</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-12">
            <div class="table-responsive">
              <div *ngIf="atemschutzListLoading">
                <mat-progress-bar mode="query"></mat-progress-bar>
              </div>
              <table mat-table [dataSource]="atemschutz" matSort class="mat-elevation-z8" matSortActive="user"
                matSortDirection="asc" matSortDisableClear>
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px"><span
                      *ngIf="!env.mobile">Stammnummer</span><span *ngIf="env.mobile">Nr.</span>
                  </th>
                  <td mat-cell *matCellDef="let element" routerLink="/admin/user/edit/{{element.userID}}/atemschutz">
                    {{element.userID}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="user">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 300px; vertical-align: middle;">
                    Name
                  </th>
                  <td mat-cell *matCellDef="let element" routerLink="/admin/user/edit/{{element.userID}}/atemschutz">
                    {{element.name}}</td>
                </ng-container>

                <!-- g26_3_date Column -->
                <ng-container matColumnDef="g26_3_date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px; vertical-align: middle;">
                    <span *ngIf="!env.mobile">Ablaufdatum G26/3</span><span *ngIf="env.mobile">G26/3</span>
                  </th>
                  <td mat-cell *matCellDef="let element" routerLink="/admin/user/edit/{{element.userID}}/atemschutz">
                    {{formatDate(element.g26_3_date)}}</td>
                </ng-container>

                <!-- BluePoint Column -->
                <ng-container matColumnDef="bluePoint">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px; vertical-align: middle;">
                    <span *ngIf="!env.mobile">Blauer Punkt</span><span *ngIf="env.mobile">
                      <mat-icon style="color: blue">brightness_1</mat-icon>
                    </span>
                  </th>
                  <td mat-cell *matCellDef="let element" routerLink="/admin/user/edit/{{element.userID}}/atemschutz">
                    <mat-icon color="success" *ngIf="element.bluePoint">done</mat-icon>
                    <mat-icon color="warn" *ngIf="!element.bluePoint">clear</mat-icon>
                  </td>
                </ng-container>

                <!-- CSA Column -->
                <ng-container matColumnDef="csa">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px; vertical-align: middle;">
                    CSA
                  </th>
                  <td mat-cell *matCellDef="let element" routerLink="/admin/user/edit/{{element.userID}}/atemschutz">
                    <mat-icon color="success" *ngIf="element.csa">done</mat-icon>
                    <mat-icon color="warn" *ngIf="!element.csa">clear</mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="atemschutzColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: atemschutzColumns;"
                  [ngClass]="valid(row.g26_3_date) ? 'error' : ''"></tr>
              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons>
            </mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>