import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AppCommunicationEvent {
  result: NFCScannedEvent | ActionAbortEvent | BarcodeScannedEvent | CameraPictureEvent | LoginEvent | AutofillEvent | null;
}

export class NFCScannedEvent {
  uid: string = '';
  text: any = null;

  constructor(uid: string, text: any) {
    this.uid = uid;
    this.text = text;
  }
}

export class LoginEvent {
  username: string = '';
  password: string = '';

  constructor(username: string, password: any) {
    this.username = username;
    this.password = password;
  }
}

export class AutofillEvent {}

export class BarcodeScannedEvent {
  data: string | any | number = '';

  constructor(data: string | any | number) {
    this.data = data;
  }
}

export class CameraPictureEvent {
  data: string = '';

  constructor(data: string) {
    this.data = data;
  }
}
export class ActionAbortEvent { }

export type AppCommunicationAction = 'nfc' | 'options' | 'url' | 'scanner' | 'camera' | 'login' | 'logout' | 'autofill' | 'requestAutofill';

declare var webkit: any;

@Injectable({
  providedIn: 'root'
})
export class AppCommunicationService {

  callback: BehaviorSubject<AppCommunicationEvent> = new BehaviorSubject<AppCommunicationEvent>({ result: null });

  constructor() {
    window.addEventListener('message', (message: any) => {
      const parsed = message.data;
      if (parsed.action == 'abort') {
        this.callback.next({
          result: new ActionAbortEvent()
        });
      } else if (parsed.action == 'nfc') {
        this.callback.next({
          result: new NFCScannedEvent(parsed.data.uid, parsed.data.text)
        });
      } else if (parsed.action == 'scanner') {
        this.callback.next({
          result: new BarcodeScannedEvent(parsed.data)
        });
      } else if (parsed.action == 'login') {
        this.callback.next({
          result: new LoginEvent(parsed.data.username, parsed.data.password)
        });
      } else if (parsed.action == 'autofill') {
        this.callback.next({
          result: new AutofillEvent()
        });
      } else if (parsed.action == 'camera') {
        this.callback.next({
          result: new CameraPictureEvent(parsed.data)
        });
      }
    });
  }

  isRunningInApp() {
    try {
      return webkit?.messageHandlers?.cordova_iab != null;
    } catch (error) {
      return (window as any)?.databosAPI != null;
    }
  }

  sendAction(action: AppCommunicationAction, options: any = {}) {
    let target = null;
    try {
      target = webkit?.messageHandlers?.cordova_iab;
    } catch (error) {
      target = (window as any)?.databosAPI;
    }

    if (target) {
      target.postMessage(JSON.stringify({
        action,
        options
      }))
    }
  }
}
