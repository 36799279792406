/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
declare global { interface Window { AdobeDC: any; } }

interface ViewerConfig {
  embedMode?: 'SIZED_CONTAINER' | 'IN_LINE' | 'LIGHT_BOX',
  showDownloadPDF?: boolean,
  showPrintPDF?: boolean,
  enableFormFilling?: boolean,
  showLeftHandPanel?: boolean,
  showAnnotationTools?: boolean,
  dockPageControls?: boolean,
  showPageControls?: boolean
}

@NgModule()
export class ViewSDKClient {
  readyPromise: Promise<void> = new Promise((resolve) => {
    if (window.AdobeDC) {
      resolve();
    } else {
      /* Wait for Adobe Document Services PDF Embed API to be ready */
      document.addEventListener('adobe_dc_view_sdk.ready', () => {
        resolve();
      });
    }
  });

  adobeDCView: any;

  ready() {
    return this.readyPromise;
  }

  previewFile(divId: string, viewerConfig: ViewerConfig, url: string, filename: string) {
    if (!document.getElementById(divId))
      return;
    const config: any = {
      /* Pass your registered client id */
      clientId: environment.adobeApiKey,
      locale: 'de-DE',
    };
    if (divId) { /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config);

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile({
      /* Pass information on how to access the file */
      content: {
        /* Location of file where it is hosted */
        location: {
          url,
        },
      },
      /* Pass meta data of file */
      metaData: {
        /* file name */
        fileName: filename,
      },
    }, viewerConfig);
    return previewFilePromise;
  }
}

