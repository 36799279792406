import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { firstValueFrom } from 'rxjs';
import { getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-admin-settings-job-history',
  templateUrl: './admin-settings-job-history.component.html',
  styleUrls: ['./admin-settings-job-history.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class AdminSettingsJobHistoryComponent implements OnInit {

  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'created', 'status', 'triggeredby']
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement: any | null;

  @ViewChild(MatSort)
  private sort;

  @ViewChild(MatPaginator)
  private paginator;

  constructor(private _http: HttpClient) {

  }

  ngOnInit(): void {
    this.loadHistory();
  }

  async loadHistory() {
    try {
      let result = await firstValueFrom(
        this._http.get<any>(`${getHostUrl()}admin/settings/general/cronjobs/history`)
      );
      result = result.map(obj => {
        obj.data = JSON.parse((obj.data ?? '{}'));
        return obj;
      });
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } catch (e) {
      console.error(e);

    }
  }

}
