<mat-dialog-header class="mb-3">
  <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
          <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Einsatzbestätigungen drucken</h2>
  </div>
</mat-dialog-header>

<mat-dialog-content>
  <div class="table-responsive">
    <table mat-table [dataSource]="persons" matSort class="mat-elevation-z8 input-full-width" matSortActive="date"
      matSortDirection="desc" [hidden]="showLoader" matSortDisableClear>

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px; vertical-align: middle;">Name</th>
        <td mat-cell *matCellDef="let element" (click)="toggleField(element)" style="cursor: pointer">
          {{ element.name }}</td>
      </ng-container>

      <!-- address Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 50px; vertical-align: middle;">
        </th>
        <td mat-cell *matCellDef="let element" (click)="toggleField(element)" style="cursor: pointer">
          <mat-icon *ngIf="element.print === 0" class="float-right">close</mat-icon>
          <mat-icon *ngIf="element.print === 1" class="float-right">check</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="personColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: personColumns;"></tr>
    </table>
  </div>

  <div class="mt-2 clearfix pb-2">
    <i>{{ selectedNumber }} Personen ausgewählt.</i>
    <a class="float-right" mat-raised-button [disabled]="selectedNumber === 0"
      href="{{baseURL}}alarms/fez/confirmation?sessionToken={{_login.getSessionToken()}}&alarmId={{data.alarmId}}&persons={{getPersons()}}"
      target="_blank"
      [hidden]="showLoader"
      color="primary">
      Drucken <mat-icon>print</mat-icon>
    </a>
  </div>
  <div *ngIf="showLoader" layout="column" layout-align="space-between center"
    style="display:flex;justify-content:center;align-items:center;">
    <mat-spinner color="primary"></mat-spinner>
  </div>
</mat-dialog-content>