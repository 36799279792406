<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Auswertung</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>

        <mat-form-field class="input-full-width" appearance="outline">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
        <div class="table-responsive">
          <mat-progress-bar mode="query" *ngIf="showLoader"></mat-progress-bar>
          <table mat-table [dataSource]="dataSource" matSort class="input-full-width" matSortActive="name"
            matSortDirection="asc" matSortDisableClear>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 250px; padding-right: 15px">
                Name
              </th>
              <td mat-cell *matCellDef="let element" (click)="editStatistics(element)"> {{element.name}} </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 250px; padding-right: 15px">
                Beschreibung
              </th>
              <td mat-cell *matCellDef="let element" (click)="editStatistics(element)"> {{element.description}} </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Typ
              </th>
              <td mat-cell *matCellDef="let element" (click)="editStatistics(element)">
                <mat-icon *ngIf="element.type === 'file' || element.type === 'fileWithSum'">list</mat-icon>
                <mat-icon *ngIf="element.type === 'piechart'">pie_chart</mat-icon>
                <mat-icon *ngIf="element.type === 'barchart'">bar_chart</mat-icon>
                <mat-icon *ngIf="element.type === 'stackchart'">stacked_bar_chart</mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>