import { Component, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ProfileComponent } from '../profile.component';

import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';

@Component({
  selector: 'app-profile-material',
  templateUrl: './profile-material.component.html',
  styleUrls: ['./profile-material.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }]
})
export class ProfileMaterialComponent {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  columnDefinitions = [
    { def: 'category', showMobile: true },
    { def: 'option', showMobile: true },
    { def: 'ean', showMobile: false },
    { def: 'serialNumber', showMobile: false }
  ];

  @Input() parent: ProfileComponent;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  isLoading: boolean = true;

  constructor(private _ref: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.parent.userInfo.material;
    this.isLoading = false;
    this._ref.detectChanges();
  }

  getDisplayedColumns(): string[] {
    const isMobile = environment.mobile;
    return this.columnDefinitions
      .filter(cd => !isMobile || cd.showMobile)
      .map(cd => cd.def);
  }

}