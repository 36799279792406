import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { UntypedFormGroup, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressDialogComponent } from '../../../../util/dialogs/address-dialog/address-dialog.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-alarms-invoice',
  templateUrl: './alarms-invoice.component.html',
  styleUrls: ['./alarms-invoice.component.css'],

})
export class AlarmsInvoiceComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public _login: Login,
    private _http: HttpClient,
    private _config: ConfigModule,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<AlarmsInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {this.alarmID = data;}

  alarmID:number;

  group = new UntypedFormGroup({});

  showLoader = false;

  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    this.group = this.data.group;
    this.alarmID = this.data.alarmId;

    if (this.data.disabledElements) {
      this.group.disable();
    }
  }

  ngAfterViewInit() {
    this.group.markAllAsTouched();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  findAddress() {
    if (this.data.disabledElements)
      return;
    const dialogRef = this._dialog.open(AddressDialogComponent, {
      width: '500px',
      data: {
        cities: false,
      },
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data === null) {
        return;
      }
      this.group.get('street').setValue(data.street);
      this.group.get('city').setValue(data.city);
      this.group.get('zipcode').setValue(data.zipcode);
      this.group.get('country').setValue(data.country);
    });
  }

  saveAlarmInvoice() {
    if (this.data.disabledElements) {
      return this._dialogRef.close();
    }
    if (this.group.invalid && this.group.touched) {
      Swal.fire({
        title: 'Nicht speichern?',
        html: 'Es sind nicht alle Rechnungsdaten ausgefüllt. Ohne Vollständigkeit werden diese Daten verworfen!<br /> <b>Fortfahren?</b>',
        icon: 'question',
        denyButtonText: 'Daten verwerfen',
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: 'zurück',
        reverseButtons: true,
      }).then(result => {
        if (result.isDenied) {
          this._dialogRef.close();
        }
      });
      return;
    }
    this.group.markAllAsTouched();

    const data = this.group.value;

    this.showLoader = true;

    this._http.post(getHostUrl() + 'alarms/invoice', {
      alarmID: this.alarmID,
      alarmObj: data,
    }).subscribe(resp => {
      this._dialogRef.close(true);
    }, error => {
      if (error.error === 5001)
        this._config.showSnackbar('Daten zur Einsatzrechnung sind nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      this.showLoader = false;
    });
  }
}
