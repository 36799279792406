<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">{{firstname}} {{lastname}}
        </h3>
        <mat-card>
            <mat-card-content>
                <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                    <mat-icon>keyboard_backspace</mat-icon>
                </a>
                <a mat-mini-fab color="primary" class="pageNext" (click)="openMenu()"
                    *ngIf="_login.hasPermission('admin-user-atemschutz-edit')">
                    <mat-icon>apps</mat-icon>
                </a>
                <div class="row">
                    <div class="col-lg-12 col-12" *ngIf="g263picture !=='default.jpg'">
                        <div class="colum has-text-centered row">
                            <img [class.img]="!env.mobile" [class.imgmobile]="env.mobile" [src]="g263pictureUrl"
                                (click)="open()" />
                        </div>
                    </div>
                    <div class="col-lg-12 col-12" *ngIf="g263picture.endsWith('default.jpg')">
                        <span style="margin-left: 15px; margin-right: 35px;">Es wurde noch keine G26/3
                            hochgeladen.</span>
                        <button mat-raised-button color="primary" (click)="openG263PictureDialog()"
                            *ngIf="_login.hasPermission('admin-user-atemschutz-edit')">
                            <mat-icon>add_a_photo</mat-icon>G26/3 hochladen
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #uploadG263>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">G26/3 Upload</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content>
        <div class="row">
            <div class="col-lg-12 col-12 container" *ngIf="!g263Loading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <img [src]="g263picture" class="image" />
                <div class="upload">
                    <label for="upload_G263">
                        <span class="uploadLabel">G26/3 hochladen</span>
                    </label>
                    <input type="file" [(ngModel)]="G263FileUrl" (change)="upload($event)" style="display:none;"
                        id="upload_G263" accept="image/x-png,image/jpeg,image/jpg" />
                </div>
            </div>
            <div class="col-lg-6 col-12 container" *ngIf="g263Loading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <mat-spinner color="primary"></mat-spinner>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #resizeG263>
    <h2 mat-dialog-title>Bild zuschneiden</h2>
    <mat-dialog-content>
        <div style="width: 400px !important; height: auto !important;" *ngIf="!env.mobile">
            <mat-progress-bar mode="query" *ngIf="imageG263Loading"></mat-progress-bar>
            <image-cropper [imageChangedEvent]="imageG263ChangedEvent" [maintainAspectRatio]="false" format="png"
                [canvasRotation]="turn" (imageLoaded)="imageG263Loaded()" (imageCropped)="imageG263Cropped($event)"
                (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>
        <div style="width: 200px !important; height: auto !important;" *ngIf="env.mobile">
            <mat-progress-bar mode="query" *ngIf="imageG263Loading"></mat-progress-bar>
            <image-cropper [imageChangedEvent]="imageG263ChangedEvent" [maintainAspectRatio]="false" format="png"
                [canvasRotation]="turn" (imageLoaded)="imageG263Loaded()" (imageCropped)="imageG263Cropped($event)"
                (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="clearfix">
            <button mat-mini-fab mat-dialog-close class="float-right" (click)="saveG263()"
                color="success"><mat-icon>done</mat-icon></button>
            <button mat-mini-fab mat-dialog-close class="float-left" color="warn"
                (click)="closeReset()"><mat-icon>close</mat-icon></button>
            <button mat-mini-fab class="float-right" color="accent" style="margin-right: 10px;"
                [disabled]="imageG263Loading" (click)="turnRight()"><mat-icon>redo</mat-icon></button>
            <button mat-mini-fab class="float-left" color="accent" style="margin-left: 10px;"
                [disabled]="imageG263Loading" (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #menu>
    <div class="text-center">
        <br>
        <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
            <mat-icon>close</mat-icon>
        </a>
        <mat-nav-list class="mt-2">
            <mat-list-item (click)="openG263PictureDialog()">
                <h3 matListItemLine>G26/3 hochladen</h3>
                <div matListItemMeta>
                  <mat-icon>add_a_photo</mat-icon>
                </div>
              </mat-list-item>
        </mat-nav-list>
    </div>
</ng-template>