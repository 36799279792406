<mat-spinner *ngIf="isLoading" color="accent" class="loading-spinner-center" [diameter]="200"></mat-spinner>

<div class="container">
  <div class="col-lg-8 col-12 offset-lg-2" *ngIf="!isLoading">
    <h3 class="section-header">Lagerort
      <span *ngIf="!locationId" class="ms-2">erstellen</span>
      <span *ngIf="locationId" class="ms-2">bearbeiten</span>
    </h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="../../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        
        <form [formGroup]="group">
          <mat-form-field appearance="outline" class="input-full-width">
            <mat-label>Name</mat-label>
            <input matInput type="text" formControlName="name" required="true" />
            <mat-error *ngIf="group.get('name').invalid">Lagerort Name ist ein Pflichtfeld!</mat-error>
          </mat-form-field>
        </form>

        <div class="clearfix">
          <button mat-raised-button class="float-right" color="accent" [disabled]="group.invalid" (click)="saveData()">
            <div class="clearfix submit-button">
              <mat-icon class="me-3 mt-1 float-left" [hidden]="!isSaving">
                <mat-spinner diameter="20" class="light"></mat-spinner>
              </mat-icon>
              <span class="me-1 float-left">Speichern</span>
              <mat-icon class="float-right">save</mat-icon>
            </div>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
