import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { StatisticData } from '@ffo/mitgliederbereich-types';
import { ECharts, EChartsOption } from 'echarts';
import { ThemingService } from 'src/app/util/services/theming.service';

@Component({
  selector: 'app-bar-chart-statistic',
  templateUrl: './bar-chart-statistic.component.html',
  styleUrls: ['./bar-chart-statistic.component.css'],
})
export class BarChartStatisticComponent implements OnChanges {

  @Output() chart = new EventEmitter<ECharts>();
  @Input() data: StatisticData[] = [];
  @Input() title: string = '';
  xAxis: string[];
  yAxis: any[];
  echartsInstance: ECharts;

  theme: string = 'shine';
  chartOption: EChartsOption = {};

  constructor(private _theme: ThemingService) {
    this._theme.theme.subscribe(theme => {
      if (theme === 'rot-dark-theme' || theme === 'standard-dark-theme') {
        this.theme = 'chalk';
      } else {
        this.theme = 'shine';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.xAxis = this.data.map(s => String(s.key));
    this.yAxis = this.data.map(s => String(s.value));
    this.chartOption = {
      title: {
        text: this.title,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: this.xAxis,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Anzahl',
          data: this.yAxis,
          type: 'bar',
        },
      ],
    };
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
    this.chart.emit(this.echartsInstance);
  }
}