<div class="full-input-width">
    <p>Hier kannst du deine Einstellungen setzen:</p>
    <ul>
        <li><b>Darkmode</b>
            <p>Die Einstellung zum Darkmode ist Geräteabhängig und wird nicht übertragen.<br />
                Du kannst den Darkmode aktivieren und deaktivieren oder zusammen mit deinen Systemeinstellungen setzten.
            </p>
        </li>
        <li><b>Sortierung</b>
            <p>Mit dieser Einstellung kannst du konfigurieren, wie Namen im Verwaltungsbereich angezeigt werden
                sollen.<br />
                "Vorname Nachname" kurz: "Vorname N."<br />
                "Nachname Vorname" kurz: "Nachname V."</p>
        </li>
    </ul>
</div>