<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Schriftführer</h3>
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="/admin/changescontrol"
              *ngIf="_login.hasPermission('admin-control')">
              <mat-icon class="float-left mt-4 ms-3">pending_actions</mat-icon>
              Änderungskontrolle<br />
              <small>Kontrolle von Änderungen</small>
            </button>
          </div>
          <div class="col-xl-4 col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="courses"
              *ngIf="_login.hasPermission('admin-course-edit')">
              <mat-icon class="float-left mt-4 ms-3">school</mat-icon>
              Laufbahnereignisse<br />
              <small>Anpassen von Laufbahnereignissen</small>
            </button>
          </div>
          <div class="col-xl-4 col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="courses/categories"
              *ngIf="_login.hasPermission('admin-course-edit')">
              <mat-icon class="float-left mt-4 ms-3">school</mat-icon>
              Laufbahnereignisse Kategorien<br />
              <small>Anpassen der Kategorien</small>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>