import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import imageCompression from 'browser-image-compression';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ActivatedRoute } from '@angular/router';
import { Login } from 'src/app/util/login';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { environment } from 'src/environments/environment';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Lightbox } from 'ngx-lightbox';
import { MatTabChangeEvent as MatTabChangeEvent } from '@angular/material/tabs';
import { ViewSDKClient } from 'src/app/util/proprietary/adobe/view-sdk.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SignatureDialogComponent } from 'src/app/util/dialogs/signature-dialog/signature-dialog.component';

@Component({
  selector: 'app-admin-user-driving-assets',
  templateUrl: './admin-user-driving-assets.component.html',
  styleUrls: ['./admin-user-driving-assets.component.css'],
})
export class AdminUserDrivingAssetsComponent implements OnInit, OnDestroy, AfterViewInit {

  firstname: string;

  lastname: string;

  userID: number;

  licencePictures: any[] = [];

  showPictures: boolean = true;

  front: string;

  back: string;

  FFFfront: string;

  FFFback: string;

  dienstfahrerlaubnis_unsigned: string = '';

  dienstfahrerlaubnis_signed: string = '';

  gabelstapler_unsigned: string = '';

  gabelstapler_signed: string = '';

  fahrzeugkraene_unsigned: string = '';

  fahrzeugkraene_signed: string = '';

  viewed: boolean = false;

  protected _onDestroy = new Subject<void>();

  env = environment;

  private viewSDKClient: ViewSDKClient;

  constructor(private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    private _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _dialog: MatDialog,
    private _bottomsheet: MatBottomSheet,
    private _lightbox: Lightbox) { }

  ngOnInit(): void {
    this.userID = this._route.snapshot.params.userId;
    if (this.userID) {
      this.fetchDriving();
      this.fetchDataInfo();
      this.dienstfahrerlaubnis_unsigned = getHostUrl() + `admin/pdfGenerator/dienstfahrerlaubnis/${this._login.getSessionToken()}/${this.userID}_Dienstfahrerlaubnis`;
      this.gabelstapler_unsigned = getHostUrl() + `admin/pdfGenerator/flurfoerderfahrzeuge/${this._login.getSessionToken()}/${this.userID}_Beauftragung_Flurfoerderfahrzeuge`;
      this.fahrzeugkraene_unsigned = getHostUrl() + `admin/pdfGenerator/fahrzeugkraene/${this._login.getSessionToken()}/${this.userID}_Beauftragung_Fahrzeugkraene`;
      this._onDestroy.next();
      this._onDestroy.complete();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  loadPdf(event: MatTabChangeEvent) {
    let url = '';
    let name = '';
    switch (event.tab.textLabel) {
      case 'Dienstfahrerlaubnis': {
        url = this.dienstfahrerlaubnis_signed;
        name = 'Dienstfahrerlaubnis';
        break;
      }
      case 'Beauftragung Flurförderfahrzeuge': {
        url = this.gabelstapler_signed;
        name = 'Flurförderfahrzeuge';
        break;
      }
      case 'Beauftragung Fahrzeugkräne': {
        url = this.fahrzeugkraene_signed;
        name = 'Fahrzeugkräne';
        break;
      }
    }
    this.showPDF(url, name);
  }

  ngAfterViewInit(): void {
    if (this.dienstfahrerlaubnis_signed)
      this.showPDF(this.dienstfahrerlaubnis_signed, 'Dienstfahrerlaubnis');
  }

  fetchDriving() {
    this._http.get<any>(getHostUrl() + 'admin/user/drivingassets?userID=' + this.userID).subscribe(data => {
      this.front = data.front;
      this.back = data.back;
      this.FFFfront = data.FFFfront;
      this.FFFback = data.FFFback;
      if (data.dienstfahrerlaubnis && data.dienstfahrerlaubnis !== '')
        this.dienstfahrerlaubnis_signed = data.dienstfahrerlaubnis;
      if (data.gabelstapler && data.gabelstapler !== '')
        this.gabelstapler_signed = data.gabelstapler;
      if (data.fahrzeugkraene && data.fahrzeugkraene !== '')
        this.fahrzeugkraene_signed = data.fahrzeugkraene;
      this.initLicencePictures();
      if (this.dienstfahrerlaubnis_signed) {
        /* Invoke file preview */
        this.showPDF(this.dienstfahrerlaubnis_signed, 'Dienstfahrerlaubnis');
      }
      this._spinner.hide();
    });
  }

  showPDF(url: string, name: string, div?: string) {
    this.viewSDKClient = new ViewSDKClient();
    this.viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      this.viewSDKClient.previewFile(div || 'pdf', {
        /* Pass the embed mode option here */
        embedMode: 'SIZED_CONTAINER',
        showDownloadPDF: false,
        showPrintPDF: false,
        enableFormFilling: false,
        showLeftHandPanel: false,
        showAnnotationTools: false,
        dockPageControls: true,
        showPageControls: false,
      }, url, name);
    });
  }

  async fetchDataInfo() {
    this._http.get<any>(getHostUrl() + 'admin/user/info?userID=' + this.userID).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this._config.setTitle(`${data.firstname + ' ' + data.lastname}`);
    });
  }

  initLicencePictures() {
    if (this.front === 'defaultFront.jpeg' && this.back === 'defaultBack.jpeg')
      this.showPictures = false;

    this.licencePictures = [];
    const front = {
      src: this.front,
      caption: 'Führerschein Vorderseite',
    };

    const back = {
      src: this.back,
      caption: 'Führerschein Rückseite',
    };

    const FFFfront = {
      src: this.FFFfront,
      caption: 'Staplerschein Vorderseite',
    };

    const FFFback = {
      src: this.FFFback,
      caption: 'Staplerschein Rückseite',
    };

    this.licencePictures.push(front);
    this.licencePictures.push(back);
    this.licencePictures.push(FFFfront);
    this.licencePictures.push(FFFback);
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.licencePictures, index, {
      wrapAround: true,
      disableScrolling: true,
      showZoom: true,
    });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }


  /**
   * Upload Dialogs
   */
  @ViewChild('dienstfahrerlaubnisDialog', { static: false }) dienstfahrerlaubnisDialog: TemplateRef<any>;

  @ViewChild('gabelstaplerDialog', { static: false }) gabelstaplerDialog: TemplateRef<any>;

  @ViewChild('fahrzeugkraeneDialog', { static: false }) fahrzeugkraeneDialog: TemplateRef<any>;

  @ViewChild('licenseDialog', { static: false }) licenceDialog: TemplateRef<any>;

  @ViewChild('resizeFront', { static: false }) resizeFront: TemplateRef<any>;

  @ViewChild('resizeBack', { static: false }) resizeBack: TemplateRef<any>;

  @ViewChild('FFFDialog', { static: false }) FFFDialog: TemplateRef<any>;

  @ViewChild('FFFresizeFront', { static: false }) FFFresizeFront: TemplateRef<any>;

  @ViewChild('FFFresizeBack', { static: false }) FFFresizeBack: TemplateRef<any>;

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  openMenu() {
    const sheet = this._bottomsheet.open(this.menu, { disableClose: true });
    sheet.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => sheet.dismiss());
  }

  dismissMenu() {
    this._bottomsheet.dismiss();
  }

  openDienstfahrerlaubnisDialog() {
    this._bottomsheet.dismiss();
    this.openSignotec('Dienstfahrerlaubnis');
  }

  openGabelstaplerDialog() {
    this._bottomsheet.dismiss();
    this.openSignotec('Beauftragung Flurförderfahrzeuge');
  }

  openFahrzeugkraeneDialog() {
    this._bottomsheet.dismiss();
    this.openSignotec('Beauftragung Fahrzeugkräne');
  }

  uploadDienstfahrerlaubnis($event) {
    let file = $event.target.files[0];

    this.blobToBase64(file).then(fileContent => {
      this._http.put(getHostUrl() + 'admin/user/dienstfahrerlaubnis', {
        dienstfahrerlaubnis: fileContent,
        userID: this.userID,
      }).subscribe(resp => {
        this.viewed = false;
        this._config.showSnackbar('Dienstfahrerlaubnis gespeichert!', 2000, 'success');
        var data: any = resp;
        this.dienstfahrerlaubnis_signed = `${getHostUrl()}dienstfahrerlaubnis?dienstfahrerlaubnis=${data.dienstfahrerlaubnis}&sessionToken=${this._login.getSessionToken()}`;
        this._dialog.closeAll();
      }, error => {
        if (error.error === 5001)
          this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      });
    });
  }

  uploadGabelstapler($event) {
    let file = $event.target.files[0];

    this.blobToBase64(file).then(fileContent => {
      this._http.put(getHostUrl() + 'admin/user/flurfoerderfahrzeuge', {
        flurfoerderfahrzeuge: fileContent,
        userID: this.userID,
      }).subscribe(resp => {
        this.viewed = false;
        this._config.showSnackbar('Beauftragung Flurförderfahrzeuge gespeichert!', 2000, 'success');
        var data: any = resp;
        this.gabelstapler_signed = `${getHostUrl()}gabelstapler?gabelstapler=${data.gabelstapler}&sessionToken=${this._login.getSessionToken()}`;
        this._dialog.closeAll();
      }, error => {
        if (error.error === 5001)
          this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      });
    });
  }

  uploadfahrzeugkraene($event) {
    let file = $event.target.files[0];

    this.blobToBase64(file).then(fileContent => {
      this._http.put(getHostUrl() + 'admin/user/fahrzeugkraene', {
        fahrzeugkraene: fileContent,

        userID: this.userID,
      }).subscribe(resp => {
        this.viewed = false;
        this._config.showSnackbar('Beauftragung Fahrzeugkräne gespeichert!', 2000, 'success');
        var data: any = resp;
        this.fahrzeugkraene_signed = `${getHostUrl()}fahrzeugkraene?fahrzeugkraene=${data.fahrzeugkraene}&sessionToken=${this._login.getSessionToken()}`;
        this._dialog.closeAll();
      }, error => {
        if (error.error === 5001)
          this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      });
    });
  }

  turn: number = 0;

  frontFileUrl: any = null;

  croppedFrontImage: any = '';

  imageFrontChangedEvent: any = '';

  imageFrontLoading: Boolean = true;

  frontLoading: Boolean = false;

  backFileUrl: any = null;

  croppedBackImage: any = '';

  imageBackChangedEvent: any = '';

  imageBackLoading: Boolean = true;

  backLoading: Boolean = false;

  FFFfrontFileUrl: any = null;

  FFFcroppedFrontImage: any = '';

  FFFimageFrontChangedEvent: any = '';

  FFFimageFrontLoading: Boolean = true;

  FFFfrontLoading: Boolean = false;

  FFFbackFileUrl: any = null;

  FFFcroppedBackImage: any = '';

  FFFimageBackChangedEvent: any = '';

  FFFimageBackLoading: Boolean = true;

  FFFbackLoading: Boolean = false;

  optionsCompress: any = {
    maxSizeMB: 5,
  };

  openLicencePictureDialog() {
    this._bottomsheet.dismiss();
    const licenceDialogRef = this._dialog.open(this.licenceDialog, { disableClose: true });
    licenceDialogRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => licenceDialogRef.close());
  }

  openFFFPictureDialog() {
    this._bottomsheet.dismiss();
    const fffPictureDialog = this._dialog.open(this.FFFDialog, { disableClose: true });
    fffPictureDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => fffPictureDialog.close());
  }

  uploadFront($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.turn = 0;
    setTimeout(() => {
      this.imageFrontChangedEvent = $event;
      const resizeFrontDialog = this._dialog.open(this.resizeFront, { disableClose: true });
      resizeFrontDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => resizeFrontDialog.close());
    }, 400);
  }

  imageFrontLoaded() {
    setTimeout(() => {
      this.imageFrontLoading = false;
    }, 800);
  }

  imageFrontCropped(event: ImageCroppedEvent) {
    this.croppedFrontImage = event.blob;
  }

  uploadBack($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.turn = 0;
    setTimeout(() => {
      this.imageBackChangedEvent = $event;
      const resizeBackDialog = this._dialog.open(this.resizeBack, { disableClose: true });
      resizeBackDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => resizeBackDialog.close());
    }, 400);
  }

  imageBackLoaded() {
    setTimeout(() => {
      this.imageBackLoading = false;
    }, 800);
  }

  imageBackCropped(event: ImageCroppedEvent) {
    this.croppedBackImage = event.blob;
  }

  FFFuploadFront($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.turn = 0;
    setTimeout(() => {
      this.FFFimageFrontChangedEvent = $event;
      const fffresizeFrontDialog = this._dialog.open(this.FFFresizeFront, { disableClose: true });
      fffresizeFrontDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => fffresizeFrontDialog.close());
    }, 400);
  }

  FFFimageFrontLoaded() {
    setTimeout(() => {
      this.FFFimageFrontLoading = false;
    }, 800);
  }

  FFFimageFrontCropped(event: ImageCroppedEvent) {
    this.FFFcroppedFrontImage = event.blob;
  }

  FFFuploadBack($event): void {
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    this.turn = 0;
    setTimeout(() => {
      this.FFFimageBackChangedEvent = $event;
      const fffresizeBackDialog = this._dialog.open(this.FFFresizeBack, { disableClose: true });
      fffresizeBackDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => fffresizeBackDialog.close());
    }, 400);
  }

  FFFimageBackLoaded() {
    setTimeout(() => {
      this.FFFimageBackLoading = false;
    }, 800);
  }

  FFFimageBackCropped(event: ImageCroppedEvent) {
    this.FFFcroppedBackImage = event.blob;
  }

  loadImageFailed() {
    this._config.showSnackbar(
      'Das Bild konnte nicht geladen werden!',
      2000,
      'error',
    );
  }

  saveFront() {
    this.frontLoading = true;
      imageCompression(this.croppedFrontImage, this.optionsCompress).then((compressedFile) => {
        this.blobToBase64(compressedFile).then(compressed => {
          this._http.put(getHostUrl() + 'admin/user/licenceFront', {
            licence: compressed,

            userID: this.userID,
          }).subscribe(resp => {
            this._config.showSnackbar('Führerscheinvorderseite gespeichert!', 2000, 'success');
            var data: any = resp;
            this.front = data.licenceID;
            this.frontFileUrl = null;
            this.croppedFrontImage = null;
            this.imageFrontLoading = true;
            this.turn = 0;
            this.frontLoading = false;
            this.initLicencePictures();
          }, error => {
            if (error.error === 5001)
              this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
            else
              this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
            this.frontLoading = false;
          });
        });
      }).catch(function (error) {
        console.log(error.message);
      });
  }

  saveBack() {
    this.backLoading = true;
      imageCompression(this.croppedBackImage, this.optionsCompress).then((compressedFile) => {
        this.blobToBase64(compressedFile).then(compressed => {
          this._http.put(getHostUrl() + 'admin/user/licenceBack', {
            licence: compressed,

            userID: this.userID,
          }).subscribe(resp => {
            this._config.showSnackbar('Führerscheinrückseite gespeichert!', 2000, 'success');
            var data: any = resp;
            this.back = data.licenceID;
            this.backFileUrl = null;
            this.croppedBackImage = null;
            this.imageBackLoading = true;
            this.turn = 0;
            this.backLoading = false;
            this.initLicencePictures();
          }, error => {
            if (error.error === 5001)
              this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
            else
              this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
            this.backLoading = false;
          });
        });
      }).catch(function (error) {
        console.log(error.message);
      });
  }

  FFFsaveFront() {
    this.FFFfrontLoading = true;
      imageCompression(this.FFFcroppedFrontImage, this.optionsCompress).then((compressedFile) => {
        this.blobToBase64(compressedFile).then(compressed => {
          this._http.put(getHostUrl() + 'admin/user/licenceFFFfront', {
            licence: compressed,

            userID: this.userID,
          }).subscribe(resp => {
            this._config.showSnackbar('Staplerscheinvorderseite gespeichert!', 2000, 'success');
            var data: any = resp;
            this.FFFfront = data.licenceID;
            this.FFFfrontFileUrl = null;
            this.FFFcroppedFrontImage = null;
            this.FFFimageFrontLoading = true;
            this.turn = 0;
            this.FFFfrontLoading = false;
            this.initLicencePictures();
          }, error => {
            if (error.error === 5001)
              this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
            else
              this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
            this.FFFfrontLoading = false;
          });
        });
      }).catch(function (error) {
        console.log(error.message);
      });
  }

  FFFsaveBack() {
    this.FFFbackLoading = true;
      imageCompression(this.FFFcroppedBackImage, this.optionsCompress).then((compressedFile) => {
        this.blobToBase64(compressedFile).then(compressed => {
          this._http.put(getHostUrl() + 'admin/user/licenceFFFBack', {
            licence: compressed,

            userID: this.userID,
          }).subscribe(resp => {
            this._config.showSnackbar('Führerscheinrückseite gespeichert!', 2000, 'success');
            var data: any = resp;
            this.FFFback = data.licenceID;
            this.FFFbackFileUrl = null;
            this.FFFcroppedBackImage = null;
            this.FFFimageBackLoading = true;
            this.turn = 0;
            this.FFFbackLoading = false;
            this.initLicencePictures();
          }, error => {
            if (error.error === 5001)
              this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
            else
              this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
            this.FFFbackLoading = false;
          });
        });
      }).catch(function (error) {
        console.log(error.message);
      });
  }

  closeReset() {
    this.frontFileUrl = null;
    this.croppedFrontImage = null;
    this.imageFrontLoading = true;

    this.backFileUrl = null;
    this.croppedBackImage = null;
    this.imageBackLoading = true;

    this.turn = 0;
  }

  turnRight() {
    this.turn++;
  }


  turnLeft() {
    this.turn--;
  }


  b64toBlob(dataURI, type): Blob {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: type });
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }



  /**
   * Sign With Signotec
   */
  signotecDocumentTyp: string = '';
  maschinist_picture: string;
  ausbilder_picture: string;

  @ViewChild('signotecSign', { static: false }) signotecSign: TemplateRef<any>;


  openSignotec(documentTyp: string) {
    this.signotecDocumentTyp = documentTyp;
    this.maschinist_picture = '';
    this.ausbilder_picture = '';
    const signotecDialog = this._dialog.open(this.signotecSign, { disableClose: true });

    signotecDialog.afterOpened().pipe(takeUntil(this._onDestroy)).subscribe(() => {
      let url = '';
      switch (documentTyp) {
        case 'Dienstfahrerlaubnis': {
          url = this.dienstfahrerlaubnis_unsigned;
          break;
        }
        case 'Beauftragung Flurförderfahrzeuge': {
          url = this.gabelstapler_unsigned;
          break;
        }
        case 'Beauftragung Fahrzeugkräne': {
          url = this.fahrzeugkraene_unsigned;
          break;
        }
      }
      this.showPDF(url, documentTyp, 'pdfSign');
    });
    signotecDialog.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe(() => {
      let url = '';
      switch (documentTyp) {
        case 'Dienstfahrerlaubnis': {
          url = this.dienstfahrerlaubnis_signed;
          break;
        }
        case 'Beauftragung Flurförderfahrzeuge': {
          url = this.gabelstapler_signed;
          break;
        }
        case 'Beauftragung Fahrzeugkräne': {
          url = this.fahrzeugkraene_signed;
          break;
        }
      }
      this.showPDF(url, documentTyp);
    });
    signotecDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => signotecDialog.close());
  }

  openSignature(title, variable) {
    let customText = `${this.env.userInfo.firstname} ${this.env.userInfo.lastname}`;
    if (variable === 'maschinist_picture') {
      customText = `${this.firstname} ${this.lastname}`;
    }
    this._dialog.open(SignatureDialogComponent, {
      data: {
        title: title,
        customText: customText,
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        switch (variable) {
          case 'ausbilder_picture':
            this.ausbilder_picture = result;
            break;
          case 'maschinist_picture':
            this.maschinist_picture = result;
            break;
        }
      }
    });
  }

  saveDienstfahrerlaubnisSigned() {
    this._http.post(getHostUrl() + 'admin/pdfGenerator/dienstfahrerlaubnis', {
      querUserID: this.userID,
      maschinist_picture: this.maschinist_picture,
      ausbilder_picture: this.ausbilder_picture,
    }).subscribe(resp => {
      this._config.showSnackbar('Dienstfahrerlaubnis gespeichert!', 2000, 'success');
      var data: any = resp;
      this.dienstfahrerlaubnis_signed = `${getHostUrl()}dienstfahrerlaubnis?dienstfahrerlaubnis=${data.dienstfahrerlaubnis}&sessionToken=${this._login.getSessionToken()}`;
      this._dialog.closeAll();
      this.maschinist_picture = '';
      this.ausbilder_picture = '';
    }, error => {
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
    });
  }

  saveFlurfoerderfahrzeugeSigned() {
    this._http.post(getHostUrl() + 'admin/pdfGenerator/flurfoerderfahrzeuge', {
      querUserID: this.userID,
      maschinist_picture: this.maschinist_picture,
      ausbilder_picture: this.ausbilder_picture,
    }).subscribe(resp => {
      this._config.showSnackbar('Beauftragung Flurförderfahrzeuge gespeichert!', 2000, 'success');
      var data: any = resp;
      this.gabelstapler_signed = `${getHostUrl()}gabelstapler?gabelstapler=${data.gabelstapler}&sessionToken=${this._login.getSessionToken()}`;
      this._dialog.closeAll();
      this.maschinist_picture = '';
      this.ausbilder_picture = '';
    }, error => {
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
    });
  }

  saveFahrzeugkraeneSigned() {
    this._http.post(getHostUrl() + 'admin/pdfGenerator/fahrzeugkraene', {

      querUserID: this.userID,
      maschinist_picture: this.maschinist_picture,
      ausbilder_picture: this.ausbilder_picture,
    }).subscribe(resp => {
      this._config.showSnackbar('Beauftragung Fahrzeugkräne gespeichert!', 2000, 'success');
      var data: any = resp;
      this.fahrzeugkraene_signed = `${getHostUrl()}fahrzeugkraene?fahrzeugkraene=${data.fahrzeugkraene}&sessionToken=${this._login.getSessionToken()}`;
      this._dialog.closeAll();
      this.maschinist_picture = '';
      this.ausbilder_picture = '';
    }, error => {
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
    });
  }
}