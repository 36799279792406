import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { ActivatedRoute } from '@angular/router';
import { Login } from 'src/app/util/login';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../../../environments/environment';
import { AdminUserMenusheetComponent } from '../admin-user-menusheet/admin-user-menusheet.component';
import { Observable, of } from 'rxjs';
import dayjs from 'dayjs';
import { CloseScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-admin-user-firedata',
  templateUrl: './admin-user-firedata.component.html',
  styleUrls: ['./admin-user-firedata.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class AdminUserFiredataComponent implements OnInit, OnDestroy {

  today: Date = new Date();
  endDate: Date = new Date();
  startDate: Date = new Date();
  birthdate: Date = new Date();

  userInfo: any = {};

  firstname: string;

  lastname: string;

  userID: number;

  alarmLinesList: Observable<any>;

  rankList: Observable<any>;

  env = environment;

  constructor(private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    public _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.userID = this._route.snapshot.params.userId;
    if (this.userID) {
      this.fetchFireData(this.userID);
      this._config.setTitle('Verwaltung | Lädt...');
    }
    if (this.userInfo.exitDate === '00.00.0000')
      this.userInfo.exitDate = '';
  }

  fetchFireData(userID: number) {
    this._http.get<any>(getHostUrl() + 'admin/user/firedata?userID=' + userID).subscribe(data => {
      this.userInfo = data;
      if (this.userInfo.voluntaryCardType === ('1')) {
        this.startDate = dayjs(this.today).set('date', 1).set('hour', 0).set('minute', 0).toDate();
        this.endDate = dayjs(this.today).add(3, 'years').toDate();
      }

      if (this.userInfo.voluntaryCardType === ('2')) {
        this.startDate = dayjs(this.userInfo.birthdate).add(16, 'years').set('day', 1).set('hour', 0).set('minute', 0).toDate();
        this.endDate = this.today;
      }
      this._spinner.hide();
    });
    this._http.get<any>(getHostUrl() + 'admin/user/info?userID=' + userID).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this._config.setTitle(`${data.firstname + ' ' + data.lastname}`);
    });
  }

  saveFeuerwehrData() {
    this._http.post(getHostUrl() + 'admin/user/firedata', {
      userID: this.userID, userObj: {
        voluntaryCardType: this.userInfo.voluntaryCardType,
        voluntaryCardDate: this.userInfo.voluntaryCardDate,
        lockerRow: this.userInfo.lockerRow,
        locker: this.userInfo.locker,
        dsgvo: this.userInfo.dsgvo,
        personalPhotos: this.userInfo.personalPhotos
      }
    }).subscribe(resp => {
      this._spinner.hide();
      this._config.showSnackbar('Feuerwehrdaten gespeichert!', 2000, 'success');
      this.fetchFireData(this.userID);
    }, error => {
      this._spinner.hide();
      if (error.error === 5001)
        this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
      this.fetchFireData(this.userID);
    });
  }

  onDatepickerChange($event: Date) {
    this.userInfo.voluntaryCardDate = $event;
  }

  voluntaryCardChange($event: any) {
    if ($event.value === '0')
      this.userInfo.voluntaryCardDate = null;

    if (this.userInfo.voluntaryCardType === ('1')) {
      this.startDate = dayjs(this.today).set('date', 1).set('hour', 0).set('minute', 0).toDate();
      this.endDate = dayjs(this.today).add(3, 'years').toDate();
      this.userInfo.voluntaryCardDate = null;
    }

    if (this.userInfo.voluntaryCardType === ('2')) {
      this.startDate = dayjs(this.userInfo.birthdate).add(16, 'years').set('day', 1).set('hour', 0).set('minute', 0).toDate();
      this.endDate = this.today;
      this.userInfo.voluntaryCardDate = null;
    }
  }


  /**
 * Menu Bottom Sheet
 */
  openMenu() {
    const bottomSheetRef = this._bottomSheet.open(AdminUserMenusheetComponent, { data: { userID: this.userID, name: this.firstname + ' ' + this.lastname } });
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }
}
