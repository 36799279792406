import { Component, Inject, OnInit } from '@angular/core';
import { AppCommunicationService, NFCScannedEvent, ActionAbortEvent } from '../../services/app-communication/app-communication.service';
import { Subscription, skip } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { webSocket } from 'rxjs/webSocket';

@Component({
  selector: 'app-nfc-scanner-dialog',
  templateUrl: './nfc-scanner-dialog.component.html',
  styleUrls: ['./nfc-scanner-dialog.component.css']
})
export class NfcScannerDialogComponent implements OnInit {

  subscription: Subscription;
  scanResult = 'waiting';

  constructor(private _com: AppCommunicationService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private _dialog: MatDialogRef<NfcScannerDialogComponent>) { }

  ngOnInit(): void {
    if (this._com.isRunningInApp()) {
      this.initMobileScanner();
    } else {
      this.initDesktopScanner();
    }
  }

  ngOnDestroy() {
    this.destroyScanner();
  }

  initDesktopScanner() {
    this.subscription = webSocket('ws://localhost:14443').subscribe((res: any) => {
      if (res !== 'connected') {
        let data = res;
        try {
          data = JSON.parse(res);
        } catch (_) { }
        setTimeout(() => {
          this._dialog.close(data);
          this.destroyScanner();
        }, 300);
      }
    });
  }

  initMobileScanner() {
    this._com.sendAction('nfc');

    this.subscription = this._com.callback.pipe(
      skip(1)
    ).subscribe(result => {
      if (result.result instanceof ActionAbortEvent) {
        this.scanResult = 'error';
        setTimeout(() => {
          this._dialog.close();
          this.destroyScanner();
        }, 300);
        return;
      }
      if (result.result instanceof NFCScannedEvent) {
        this.scanResult = 'success';
        setTimeout(() => {
          this._dialog.close(result.result);
          this.destroyScanner();
        }, 300);
      }
    });
  }

  destroyScanner() {
    this.scanResult = 'waiting';
    this.subscription.unsubscribe();
  }
}
