import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Login } from 'src/app/util/login';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { environment } from 'src/environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { ViewSDKClient } from 'src/app/util/proprietary/adobe/view-sdk.service';
import { filter } from 'rxjs';

interface User {
  userID: number;
  dienstfahrerlaubnis: string;
  firstname: string;
  lastname: string;
}

@Component({
  selector: 'app-admin-user-licence-control',
  templateUrl: './admin-user-licence-control.component.html',
  styleUrls: ['./admin-user-licence-control.component.css'],
})
export class AdminUserLicenceControlComponent implements OnInit, AfterViewInit {

  user: User = {
    userID: 0,
    firstname: '',
    lastname: '',
    dienstfahrerlaubnis: '',
  };

  new_user: User = null;

  users: User[] = [];

  usersAutoComplete: User[] = [];

  searchControl = new UntypedFormControl('');

  @ViewChild('licenceDate', { static: false }) licenceDate: TemplateRef<any>;

  @ViewChild('invalidDialog', { static: false }) invalidDialog: TemplateRef<any>;

  env = environment;

  private viewSDKClient: ViewSDKClient;

  constructor(public _login: Login,
    private _http: HttpClient,
    private _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _dialog: MatDialog) { }

  ngOnInit(): void {
    this._config.setTitle('Führerscheinkontrolle');
    this.fetchUsers();
  }

  ngAfterViewInit(): void {
    this.openUserPicker();
  }

  fetchUsers() {

    this._http.get<User[]>(getHostUrl() + 'admin/licenceCheck/list')
      .subscribe(data => {
        this.users = data;
        this.usersAutoComplete = data;
        this._spinner.hide();
      });
  }

  setUser(user: User) {
    this.new_user = user;
    this.applyFilter(this.new_user.userID.toString());
    this.searchControl.setValue(this.usersAutoComplete[0]);
  }

  applyUser() {
    this.user.userID = this.new_user.userID;
    this.user.firstname = this.new_user.firstname;
    this.user.lastname = this.new_user.lastname;
    this._http.get<any>(getHostUrl() + 'admin/user/drivingassets?userID=' + this.user.userID).subscribe(data => {
      if (data.dienstfahrerlaubnis && data.dienstfahrerlaubnis !== '')
        this.user.dienstfahrerlaubnis = data.dienstfahrerlaubnis;
      this.showPDF();
    });
    //this.user.dienstfahrerlaubnis = `${getHostUrl()}dienstfahrerlaubnis?dienstfahrerlaubnis=${this.new_user.dienstfahrerlaubnis}&sessionToken=${this._login.getSessionToken()}`;
  }

  showPDF() {
    this.viewSDKClient = new ViewSDKClient();
    this.viewSDKClient.ready().then(() => {
      this.viewSDKClient.previewFile('pdf', {
        /* Pass the embed mode option here */
        embedMode: 'SIZED_CONTAINER',
        showDownloadPDF: false,
        showPrintPDF: false,
        enableFormFilling: false,
        showLeftHandPanel: false,
        showAnnotationTools: false,
        dockPageControls: true,
        showPageControls: false,
      }, this.user.dienstfahrerlaubnis, 'Dienstfahrerlaubnis');
    });
  }

  displayFn(user: User): string {
    return user && user.firstname && user.lastname && user.userID ? (`${user.userID} - ${user.firstname} ${user.lastname}`) : '';
  }

  invalid() {
    this._spinner.hide();
    this._http.get(getHostUrl() + `admin/licenceCheck/invalid?userID=${this.user.userID}`)
      .subscribe(data => {
        let index = this.users.indexOf(this.user);
        this.users.splice(index, 1);
        this._spinner.hide();
        this.openUserPicker();
      });
  }

  valid() {
    this._spinner.hide();
    this._http.get(getHostUrl() + `admin/licenceCheck/valid?userID=${this.user.userID}`)
      .subscribe(data => {
        let index = this.users.indexOf(this.user);
        this.users.splice(index, 1);
        this._spinner.hide();
        this.openUserPicker();
      });
  }

  applyFilter(filterValue: string) {
    this.usersAutoComplete = this.users.filter(user => user.userID.toString().toLowerCase().startsWith(filterValue.toLowerCase()) || user.firstname.toLowerCase().startsWith(filterValue.toLowerCase()) || user.lastname.toLowerCase().startsWith(filterValue.toLowerCase()));
  }

  openUserPicker() {
    this.clearInput();
    const licenceDateDialog = this._dialog.open(this.licenceDate, { disableClose: true });
    licenceDateDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => licenceDateDialog.close());

  }

  openInvalidDialog() {
    const incalidControlDialog = this._dialog.open(this.invalidDialog, { disableClose: true });
    incalidControlDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => incalidControlDialog.close());
  }

  clearInput() {
    this.applyFilter('');
    this.user.dienstfahrerlaubnis = '';
    this.searchControl.setValue('');
  }
}
