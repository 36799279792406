<mat-dialog-header>
  <div class="clearfix">
    <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
      <mat-icon>close</mat-icon>
    </a>
    <h2 mat-dialog-title class="float-left">Cronjob</h2>
  </div>
</mat-dialog-header>
<mat-dialog-content>
  <form [formGroup]="group">
    <mat-stepper orientation="vertical">
      <mat-step>
        <ng-template matStepLabel>Allgemein</ng-template>
        <mat-form-field class="input-full-width">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required>
        </mat-form-field>
        <mat-form-field class="input-full-width">
          <mat-label>Job</mat-label>
          <mat-select required formControlName="job_type" (selectionChange)="selectJob($event)">
            <mat-option *ngFor="let job of jobList" [value]="job.id">{{ job.fancy_name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <button mat-button matStepperNext>Weiter</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Einstellungen</ng-template>

        <i *ngIf="jobTemplate.length === 0">Keine Einstellungen verfügbar!</i>
        <mat-form-field *ngFor="let setting of jobTemplate" class="input-full-width">
          <mat-label>{{ setting.fancy_name }}</mat-label>
          <input matInput *ngIf="setting.type === 'text'" [(ngModel)]="setting.value"
            [ngModelOptions]="{standalone: true}" />
          <input matInput *ngIf="setting.type === 'number'" type="number" [(ngModel)]="setting.value"
            [ngModelOptions]="{standalone: true}" [min]="setting.min ?? null" [max]="setting.max ?? null" />
          <mat-select [(ngModel)]="setting.value" multiple *ngIf="setting.type === 'groups'" [ngModelOptions]="{standalone: true}">
            <mat-option [value]="group.id" *ngFor="let group of groupList">{{ group.name }}</mat-option>
          </mat-select>
          <mat-select [(ngModel)]="setting.value" *ngIf="setting.type === 'dropdown'" [ngModelOptions]="{standalone: true}">
            <mat-option [value]="option.id" *ngFor="let option of setting.options">{{ option.value }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div>
          <button mat-button matStepperPrevious>Zurück</button>
          <button mat-button matStepperNext>Weiter</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>CronTab Bedingung</ng-template>
        <mat-form-field class="input-full-width mt-3">
          <mat-label>CronTab Syntax</mat-label>
          <input matInput formControlName="crontab" />
          <a [href]="crontabGuru()" style="text-decoration: none;" target="_blank" matSuffix>
            <mat-icon>help_outline</mat-icon>
          </a>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>Zurück</button>
          <button mat-button [disabled]="group.invalid" (click)="save()">Speichern</button>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</mat-dialog-content>