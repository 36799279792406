import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { NgxMatTimepickerLocaleService } from 'ngx-mat-timepicker';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { ConfigModule } from '../../config';

@Component({
  selector: 'app-create-time-category-dialog',
  templateUrl: './create-time-category-dialog.component.html',
  styleUrls: ['./create-time-category-dialog.component.scss']
})
export class CreateTimeCategoryDialogComponent implements OnInit {
  constructor(private _http: HttpClient,
    private _ref: MatDialogRef<CreateTimeCategoryDialogComponent>,
    private _config: ConfigModule,
    private _time: NgxMatTimepickerLocaleService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
  
  }

  ngOnInit(): void {
    this.loadGroups();
    this._time.updateLocale('de');

    if (this.data?.item) {      
      this.categoryId = this.data.item.id;
      console.log(this.data.item);
      
      this.group.patchValue({
        name: this.data.item.name,
        group: this.data.item.groupID,
        active: this.data.item.active
      });      
    }

    this.groupFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterGroups();
      });
  }

  isLoading = false;
  categoryId: number;

  groupList = [];
  public groupFilterCtrl: FormControl = new FormControl();
  public groupFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  group = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    group: new FormControl(null, Validators.required),
    active: new FormControl(1, Validators.required),
  })

  async loadGroups() {
    try {
      const result = await firstValueFrom(this._http.get<any>('api/datainfo/groups?type=hasAccess'));
      this.groupList = result;
      this.groupFilter.next(this.groupList.slice());
    } catch (error) {

    }
  }

  protected filterGroups() {
    if (!this.groupList) {
      return;
    }
    
    let search = this.groupFilterCtrl.value;
    if (!search) {
      this.groupFilter.next(this.groupList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.groupFilter.next(
      this.groupList.filter(group => group.name.toLowerCase().indexOf(search) > -1)
    );
  }

  async save() {
    if (this.group.invalid) return;

    const payload: any = this.group.value;
    if (this.categoryId) {
      payload.id = this.categoryId;
    }
    this.isLoading = true;
    this.group.disable();

    try {
      await firstValueFrom(this._http.post(`api/admin/time/category`, payload));
      this._ref.close(true);
      if ( this.categoryId) {
        this._config.showSnackbar('Kategorie erfolgreich aktualisiert!', 3000, 'success');
      } else this._config.showSnackbar('Kategorie erfolgreich hinzugefügt!', 3000, 'success');
    } catch (e) {
      this.isLoading = false;
      this.group.enable();

      if (e.error.error) {
        this._config.showSnackbar(e.error.error, 3000, 'error')
        return;
      }
      if (e.error.code === 1062) {
        this._config.showSnackbar('Kategorie mit diesem Namen und Berechtigungsgruppe existiert bereits!', 3000, 'error')
        return;
      }
      this._config.showSnackbar('Kategorie konnte nicht hinzugefügt werden!', 3000, 'error')
    }
  }
}
