import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { filter } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import { AdminSettingsJobsModalComponent } from './admin-settings-jobs-modal/admin-settings-jobs-modal.component';

@Component({
  selector: 'app-admin-settings-jobs',
  templateUrl: './admin-settings-jobs.component.html',
  styleUrls: ['./admin-settings-jobs.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],

})
export class AdminSettingsJobsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  displayedColumns: string[] = ['name', 'actions'];

  dataSource = new MatTableDataSource();

  dayjs = dayjs;

  showLoader = true;

  constructor(private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet,
    private _dialog: MatDialog,
    private _http: HttpClient) { }

  ngOnInit(): void {
    this._config.setTitle('Einstellungen | CronJobs');
    this.loadData();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu, { disableClose: true });
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }

  loadData() {
    this._http.get<any>(`${getHostUrl()}admin/settings/general/jobs`).subscribe(result => {
      this.showLoader = false;
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  playJob(element) {
    this._dialog.open(AdminSettingsJobsModalComponent, {
      data: element,
      width: '400px',
    }).afterClosed().subscribe(result => {
      this.loadData();
    });
  }
}
