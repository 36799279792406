<div class="row" id="scrollingResponder">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">FRS Dienstplan</h3>
    <mat-card>
      <mat-card-content>
        <div *ngIf="!scheduleArray" style="margin-bottom: 15px;">
          <mat-progress-bar mode="query"></mat-progress-bar>
        </div>
        <button class="showMoreButton" mat-button (click)="showPrevious()" [hidden]="!isFRSAdmin()" style="width: max-content;">
          Zurückliegende anzeigen <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <div class="table-responsive">
          <table class="table">
            <thead id="tableHeader">
              <th class="date" id="dateHeader">Datum</th>
              <th class="column" id="791Header">79/1</th>
              <th class="column" id="792Header">79/2</th>
            </thead>
            <tbody>
              <tr id="spacer" style="border: 0px; height: 0px"></tr>
              <tr #ResponderList *ngFor="let _ of getArray(SHIFTCOUNT); let i=index" [attr.ready]="checkScroll">
                <td class="table-info text-center dateBody" style="vertical-align: middle;"
                  *ngIf="calculateDate(i); let date">
                  <b><mat-icon>sunny</mat-icon></b><br>
                  {{ getDayText(date) }}<br />{{ getDay(date) }}<br>
                  <b><mat-icon>dark_mode</mat-icon></b>
                </td>
                <td *ngIf="calculateDate(i); let date" class="791Body">
                  <div style="margin-top: 10px">
                    <button mat-flat-button color="warn" class="input-full-width" *ngIf="!hasShift(date, 'day', '791')"
                      (click)="addShift(date, 'day', '791')"
                      [disabled]="!isFRS() || hasShiftOnCar(date, 'day', '792') && !isFRSAdmin()">
                      Fahrer
                    </button>
                    <button mat-flat-button [color]="shift.comments ? 'primary' : 'success'" [class.comments]="shift.comments" class="input-full-width"
                      *ngIf="hasShift(date, 'day', '791') && getShift(date, 'day', '791'); let shift" [id]="shift.id"
                      (click)="openShift(shift)">
                      {{ shift.user.name }} {{shift.coDriver ? '+1' : ''}}
                    </button>
                  </div>
                  <hr />
                  <div style="margin-top: 10px">
                    <button mat-flat-button color="warn" class="input-full-width"
                      *ngIf="!hasShift(date, 'night', '791')" (click)="addShift(date, 'night', '791')"
                      [disabled]="!isFRS() || hasShiftOnCar(date, 'night', '792') && !isFRSAdmin()">
                      Fahrer
                    </button>
                    <button mat-flat-button [color]="shift.comments ? 'primary' : 'success'" [class.comments]="shift.comments" class="input-full-width"
                      *ngIf="hasShift(date, 'night', '791') && getShift(date, 'night', '791'); let shift"
                      [id]="shift.id" (click)="openShift(shift)">
                      {{ shift.user.name }} {{shift.coDriver ? '+1' : ''}}
                    </button>
                  </div>
                </td>
                <td *ngIf="calculateDate(i); let date" class="792Body">
                  <div style="margin-top: 10px">
                    <button mat-flat-button color="warn" class="input-full-width" *ngIf="!hasShift(date, 'day', '792')"
                      (click)="addShift(date, 'day', '792')"
                      [disabled]="!isFRS() || hasShiftOnCar(date, 'day', '791') && !isFRSAdmin()">
                      Fahrer
                    </button>
                    <button mat-flat-button [color]="shift.comments ? 'primary' : 'success'" [class.comments]="shift.comments" class="input-full-width"
                      *ngIf="hasShift(date, 'day', '792') && getShift(date, 'day', '792'); let shift" [id]="shift.id"
                      (click)="openShift(shift)">
                      {{ shift.user.name }} {{shift.coDriver ? '+1' : ''}}
                    </button>
                  </div>
                  <hr />
                  <div style="margin-top: 10px">
                    <button mat-flat-button color="warn" class="input-full-width"
                      *ngIf="!hasShift(date, 'night', '792')" (click)="addShift(date, 'night', '792')"
                      [disabled]="!isFRS() || hasShiftOnCar(date, 'night', '791') && !isFRSAdmin()">
                      Fahrer
                    </button>
                    <button mat-flat-button [color]="shift.comments ? 'primary' : 'success'" [class.comments]="shift.comments" class="input-full-width"
                      *ngIf="hasShift(date, 'night', '792') && getShift(date, 'night', '792'); let shift"
                      [id]="shift.id" (click)="openShift(shift)">
                      {{ shift.user.name }} {{shift.coDriver ? '+1' : ''}}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button class="showMoreButton" [disabled]="SHIFTCOUNT >= 62" mat-button (click)="showMore()">
          Mehr anzeigen <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
  </div>
</div>