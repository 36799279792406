import { Component, ViewChild, TemplateRef, Inject, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Login } from 'src/app/util/login';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import Swal from 'sweetalert2';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AlarmsConfirmationDialogComponent } from 'src/app/util/dialogs/alarms-confirmation-dialog/alarms-confirmation-dialog.component';
import { DateTime } from 'luxon';
import { Alarm } from '@ffo/mitgliederbereich-types';
import { filter } from 'rxjs';


@Component({
  selector: 'app-alarms-overview',
  templateUrl: './alarms-overview.component.html',
  styleUrls: ['./alarms-overview.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class AlarmsOverviewComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  alarmColumns: string[] = ['date', 'keyword', 'address', 'status', 'action'];

  alarmList: Alarm[] = [];

  alarm: MatTableDataSource<Alarm>;

  alarmListLoading: boolean = true;
  loading: boolean = false;

  alarmFilter = ['offen', 'in Bearbeitung'];
  searchFilter: string = '';

  baseURL = getHostUrl();

  reloadAlarm: any;
  filterAlarm: any;

  env = environment;

  constructor(private _http: HttpClient, public _login: Login,
    private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet,
    private _router: Router,
    private _dialog: MatDialog,
    private _ref: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this._config.setTitle('Einsätze');
    this.applyGroupsFromHash();
    this.loadAlarm(true);

    this.reloadAlarm = setInterval(() => {
      this._login.isLoggedIn().subscribe(loggedIn => {
        if (loggedIn) {
          this.loadAlarm();
        }
      });
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.reloadAlarm);
    clearTimeout(this.filterAlarm);
  }

  addOrLoadNotes(element) {
    this._bottomSheet.open(BottomSheetAlarmReviewerNotesComponent, {
      data: JSON.parse(JSON.stringify(element)),
    }).afterDismissed().subscribe(() => {
      this.loadAlarm();
    });
  }

  deleteAlarm(element) {
    const result = Swal.fire({
      title: 'Einsatz löschen?',
      text: 'Möchtest du wirklich diesen Einsatz löschen?',
      icon: 'warning',
      showConfirmButton: true,
      showDenyButton: true,
      reverseButtons: true,
      confirmButtonText: 'Abbrechen',
      denyButtonText: 'Löschen',
    });

    result.then(dialogResult => {
      if (dialogResult.isDenied) {
        this._http.delete(`${getHostUrl()}alarms/${element.id}`).subscribe(data => {
          this.loadAlarm();
          this._config.showSnackbar('Einsatz erfolgreich gelöscht!', 3000, 'success');
        });
      }
    });
  }

  loadAlarmSelectedFilter() {
    this.loading = true;
    this.reloadAlarm = null;
    this.filterAlarm = setTimeout(() => {
      this.loadAlarm();
      clearInterval(this.reloadAlarm);
      this.reloadAlarm = setInterval(() => {
        this._login.isLoggedIn().subscribe(loggedIn => {
          if (loggedIn) {
            this.loadAlarm();
          }
        });
      }, 10000);
    }, 1000)
  }

  loadAlarm(initialLoad = false) {
    const status = this.alarmFilter.map(status => status.split(':')).flat(1);
    if (!initialLoad) this.setHashLocation();
    this._http.post<Alarm[]>(getHostUrl() + 'alarms/list', {
      status: status
    }).subscribe({
      next: ((data: Alarm[]) => {
        if (initialLoad) {
          this.inititalizeTable();
          this.applyHashLocation();
        }
        this.alarmList = data.map(alarm => {
          alarm.date = new Date(alarm.date);
          return alarm;
        });
        this.alarmListLoading = false;
        this.loading = false;
        this.alarm.data = this.alarmList;
      }),
      error: (err => {
        if (err.status === 401 && initialLoad)
          this._config.showSnackbar('Deine IP Adresse ist nicht freigeschalten um Einsätze zu schreiben/sehen!', 4000, 'error');
        this.alarmListLoading = false;
        this.loading = false;
      })
    });
  }

  loadConfirmation(alarmID) {
    this._dialog.open(AlarmsConfirmationDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {
        alarmId: alarmID,
      },
    });
  }

  getFormattedDateTime(_date: Date, _time: string) {
    const date = DateTime.fromJSDate(_date).setZone('Europe/Berlin');
    const time = DateTime.fromSQL(_time).setZone('Europe/Berlin');
    return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toFormat('dd.MM.yyyy HH:mm');
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.applyAlarmFilter();
  }

  applyAlarmFilter() {
    this.alarm.filter = JSON.stringify({ search: this.searchFilter });
    this.setHashLocation();
  }

  getLocalStorage() {
    const item = JSON.parse(localStorage.getItem('alarmFilter-' + this._login.getUserID()) ?? '{}');

    return {
      search: item?.search ?? '',
      status: item?.status ?? ['offen', 'in Bearbeitung']
    }
  }

  applyGroupsFromHash() {
    this.alarmFilter = this.getLocalStorage().status;
    this._ref.detectChanges();
  }

  applyHashLocation() {
    this.searchFilter = this.getLocalStorage().search;
    this.applyAlarmFilter();
    this._ref.detectChanges();
  }

  setHashLocation() {
    localStorage.setItem('alarmFilter-'  + this._login.getUserID(), JSON.stringify({
      status: this.alarmFilter, search: this.searchFilter
    }));
  }

  filter(): (data: Alarm, filter: string) => boolean {
    let filterFunction = function (data: Alarm, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let searchResult1 = false;

      if (searchTerms.search) {
        Object.keys(data).forEach(elem => {
          if (String(data[elem]).toLowerCase().includes(searchTerms.search.toLowerCase())) {
            searchResult1 = true;
          } else if (data[elem] instanceof Date) {
            const date = DateTime.fromJSDate(data[elem]);
            searchResult1 = date.toFormat('dd.MM.yyyy').includes(searchTerms.search.toLowerCase());
          }
          if (searchResult1)
            return;
        });
      } else {
        searchResult1 = true;
      }
      return searchResult1;
    };
    return filterFunction;
  }

  openAlarm(element: Alarm) {
    const userId = this._login.getUserID();

    if (element.openedBy && element.openedBy !== userId) {
      Swal.fire({
        title: 'Einsatz bereits in Bearbeitung',
        text: `Dieser Einsatz kann derzeit nicht bearbeitet werden, da dieser von ${element.openedBy_name} geöffnet ist!`,
        icon: 'error',
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: 'In Ordnung',
      });
      return;
    }

    if (element.status !== 'offen' && element.status !== 'in Bearbeitung' && (!this._login.hasPermission('alarm-generate-report') || !this._login.hasPermission('alarm-generate-invoice'))) {
      Swal.fire({
        title: 'Einsatz bereits abgeschlossen',
        text: `Dieser Einsatz kann nicht mehr bearbeitet werden, da dieser bereits von ${element.creator !== userId ? element.creator_name : 'dir'} abgeschlossen wurde!`,
        icon: 'error',
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: 'In Ordnung',
        confirmButtonText: 'Bestätigungen drucken',
        customClass: {
          confirmButton: 'primaryColor',
        },
      }).then(result => {
        if (result.isDenied) {
          return;
        } else if (result.isConfirmed) {
          this.loadConfirmation(element.id);
          return;
        }
      });
    } else {
      this._router.navigate(['/alarms/edit/', element.id]);
    }
  }

  inititalizeTable() {
    this.alarm = new MatTableDataSource<Alarm>(this.alarmList);
    this.alarm.filterPredicate = this.filter();
    this.alarm.sortingDataAccessor = (item: Alarm, property) => {
      switch (property) {
        case 'date':
          const date = DateTime.fromJSDate(item.date).setZone('Europe/Berlin');
          const time = DateTime.fromFormat(item.time, 'HH:mm:ss').setZone('Europe/Berlin');
          return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toJSDate();
        default: return item[property];
      }
    };
    this.alarm.sort = this.sort;
    this.alarm.paginator = this.paginator;
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu, { disableClose: true });
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }
}

@Component({
  selector: 'app-reviewer-notes',
  templateUrl: './reviewer-notes.html',
})
export class BottomSheetAlarmReviewerNotesComponent {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public _data: any,
    public _bottomSheet: MatBottomSheet,
    private _http: HttpClient,
    private _config: ConfigModule) {
  }

  save() {
    this._http.post(`${getHostUrl()}alarms/comment/${this._data.id}`, {
      notes: this._data.reviewerNotes,
    }).subscribe(data => {
      this._bottomSheet.dismiss();
      this._config.showSnackbar('Erfolgreich gespeichert!', 3000, 'success');
    }, error => {
      this._config.showSnackbar('Ein Fehler ist beim Speichern aufgetreten!', 3000, 'error');
    });
  }
}
