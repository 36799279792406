<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">Benutzer</h3>
        <mat-card>
            <mat-card-content>
                <a (click)="openNewUser()" mat-mini-fab class="pageNext" color="primary"
                    *ngIf="_login.hasPermission('admin-user-new')">
                    <mat-icon>person_add</mat-icon>
                </a>
                <div class="row mt-4">
                    <div class="col-lg-8 col-6">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchFilter"
                                placeholder="Suche">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-6">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <mat-label>Gruppenfilter</mat-label>
                            <mat-select multiple (selectionChange)="applyGroupFilter($event)"
                                [(ngModel)]="groupsFilter">
                                <mat-option *ngFor="let group of groupList"
                                    [value]="group.id">{{group.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="table-responsive">
                    <div *ngIf="userList.length===0">
                        <mat-progress-bar mode="query"></mat-progress-bar>
                    </div>
                    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="name"
                        matSortDirection="asc" matSortDisableClear>
                        <!-- ID Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                style="width: 200px; padding-right: 15px">
                                <span *ngIf="!env.mobile">Stammnummer</span><span *ngIf="env.mobile">Nr.</span>
                            </th>
                            <td mat-cell *matCellDef="let element"
                                (click)="openMenu(element.id, element.firstname, element.lastname)">
                                {{element.id}}</td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                style="min-width: 260px; vertical-align: middle;">Name
                            </th>
                            <td mat-cell *matCellDef="let element"
                                (click)="openMenu(element.id, element.firstname, element.lastname)">
                                <label>{{element.name }}</label>
                            </td>
                        </ng-container>

                        <!-- Email Column -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                style="min-width: 260px; vertical-align: middle;">
                                E-Mail</th>
                            <td mat-cell *matCellDef="let element"><a
                                    href="mailto:{{element.email}}">{{element.email}}</a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="pageIndex"
                    (page)="applyPagination($event)" showFirstLastButtons></mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</div>