import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import * as validator from 'cron-validator';
import { Group } from '@ffo/mitgliederbereich-types';

@Component({
  selector: 'app-admin-settings-cronjobs-modal',
  templateUrl: './admin-settings-cronjobs-modal.component.html',
  styleUrls: ['./admin-settings-cronjobs-modal.component.css'],
})
export class AdminSettingsCronjobsModalComponent implements OnInit {

  group: UntypedFormGroup = new UntypedFormGroup({});
  jobList = [];
  jobTemplate = [];
  groupList: Group[] = [];

  constructor(private _builder: UntypedFormBuilder,
    private _http: HttpClient,
    private _dialog: MatDialogRef<AdminSettingsCronjobsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _config: ConfigModule) { }

  ngOnInit(): void {
    this.getJobTypes();

    this.group = this._builder.group({
      name: [this._data?.name ?? '', Validators.required],
      job_type: [this._data?.job ?? '', Validators.required],
      crontab: [this._data?.cron ?? '* * * * * *', Validators.required],
    });
  }

  selectJob($event) {
    const job = this.jobList.find(j => j.id == $event.value);
    if (job) {
      this.getJobTemplate(true);
    }
  }

  getJobTypes() {
    this._http.get<any>(`${getHostUrl()}datainfo/jobs`).subscribe(result => {
      this.jobList = result;
      this.getJobTemplate();
    });
  }

  async loadGroups() {
    this.groupList = await this._http.get<Group[]>(`${getHostUrl()}datainfo/groups?type=user`).toPromise();
  }

  getJobTemplate(initial = false) {
    const job = this.jobList.find(j => j.id == this.group.get('job_type').value);
    if (job) {
      this.jobTemplate = JSON.parse(job.settings);
      this.jobTemplate.map(j => {
        j.value = '';
        return j;
      });
      console.log(this.jobTemplate);
      if (this.jobTemplate.find(j => j.type == 'groups'))
        this.loadGroups();
      if (!initial)
        this.mapSettingsToTemplate();
    }
  }

  mapSettingsToTemplate() {
    if (this._data?.job_data) {
      const settings = JSON.parse(this._data?.job_data);
      Object.keys(settings).forEach(elem => {
        this.jobTemplate.find(j => j.name == elem).value = settings[elem];
      });
    }
  }

  retrieveSettingsFromTemplate() {
    const obj = {};
    this.jobTemplate.forEach(elem => {
      obj[elem.name] = elem.value;
    });
    return obj;
  }

  save() {
    const groupValue = this.group.value;
    groupValue.data = JSON.stringify(this.retrieveSettingsFromTemplate());
    
    if (!validator.isValidCron(groupValue.crontab, { seconds: true })) {
      this._config.showSnackbar('Ungültige Crontab Syntax!', 8000, 'error');
      return;
    }
    
    if (!this._data?.id) {
      this._http.put<any>(`${getHostUrl()}admin/settings/general/cronjobs`, groupValue).subscribe(result => {
        this._dialog.close(true);
        this._config.showSnackbar('Cronjob wurde erfolgreich erstellt!', 3000, 'error');
      }, error => {
        this._config.showSnackbar('Ein Fehler ist beim Updaten des CronJobs aufgetreten!', 3000, 'error');
      });
    } else {
      this._http.post<any>(`${getHostUrl()}admin/settings/general/cronjobs/${this._data.id}`, groupValue).subscribe(result => {
        this._dialog.close(true);
        this._config.showSnackbar('Cronjob wurde erfolgreich aktualisiert!', 3000, 'success');
      }, error => {
        this._config.showSnackbar('Ein Fehler ist beim Updaten des CronJobs aufgetreten!', 3000, 'error');
      });
    }
  }

  crontabGuru(): string {
    if (this.group.get('crontab').value && this.group.get('crontab').value !== '')
      return `https://crontab.guru/#${this.group.get('crontab').value.replaceAll(' ', '_')}`;
    return 'https://crontab.guru';
  }

}
