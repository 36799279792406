<mat-dialog-header class="mb-3">
  <div class="clearfix">
    <a mat-icon-button (click)="saveDamage()" class="closeButtonDialog float-right">
      <mat-icon>close</mat-icon>
    </a>
    <h2 mat-dialog-title class="float-left">Medizinische Daten</h2>
  </div>
</mat-dialog-header>

<mat-dialog-content>
  <form [formGroup]="group">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Verletzte</mat-label>
          <input matInput type="number" inputmode="numeric" formControlName="injured" [required]="this.data.required"
            min="0" oninput="validity.valid||(value='');" />
          <mat-error *ngIf="!group.get('injured').valid">Verletztenanzahl darf nicht negativ sein</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Tote</mat-label>
          <input matInput type="number" inputmode="numeric" formControlName="dead" [required]="this.data.required"
            min="0" oninput="validity.valid||(value='');" />
          <mat-error *ngIf="!group.get('dead').valid">Totenanzahl darf nicht negativ sein</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Betreute</mat-label>
          <input matInput type="number" inputmode="numeric" formControlName="cared" [required]="this.data.required"
            min="0" oninput="validity.valid||(value='');" />
          <mat-error *ngIf="!group.get('cared').valid">Anzahl der Betreuten darf nicht negativ sein</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>AED</mat-label>
          <mat-select formControlName="aed" [required]="this.data.required">
            <mat-option [value]="null"></mat-option>
            <mat-option [value]="0">Nein</mat-option>
            <mat-option [value]="1">Ja</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Eintreffen</mat-label>
          <mat-select formControlName="arrival" [required]="this.data.required">
            <mat-option [value]="null"></mat-option>
            <mat-option [value]="0">Vor RD</mat-option>
            <mat-option [value]="1">Zeitgleich mit RD</mat-option>
            <mat-option [value]="3">Nach RD</mat-option>
            <mat-option [value]="4">Abbestellt</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-md-12 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Maßnahmen durch FRS</mat-label>
          <mat-select multiple formControlName="measure" [hidden]="group.get('measure').disabled">
            <mat-option>
              <ngx-mat-select-search [formControl]="measureListCtrl" id="measure_search"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let measure of measureFilter | async" [value]="measure.id.toString()"
              (click)="measureReset()">
              {{ measure.name }}
            </mat-option>
          </mat-select>
          <ul *ngIf="group.get('measure').disabled && group.get('measure').value?.length > 0" class="mt-2">
            <li *ngFor="let measure of group.get('measure').value">{{ getMeasureById(measure) }}</li>
          </ul>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>