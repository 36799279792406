import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { ConfigModule, formatDayjs, getHostUrl } from 'src/app/util/config';

import Swal from 'sweetalert2';
import { AlarmLines } from '@ffo/mitgliederbereich-types';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AdminSettingsAlarmlinesModalComponent } from './admin-settings-alarmlines-modal/admin-settings-alarmlines-modal.component';

@Component({
  selector: 'app-admin-settings-alarmlines',
  templateUrl: './admin-settings-alarmlines.component.html',
  styleUrls: ['./admin-settings-alarmlines.component.css']
})
export class AdminSettingsAlarmlinesComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  formatDayjs = formatDayjs;

  displayedColumns: string[] = ['name', 'fivetone', 'actions'];

  dataSource = new MatTableDataSource<AlarmLines>();

  showLoader = true;

  constructor(private _config: ConfigModule,
    private _dialog: MatDialog,
    private _http: HttpClient) { }

  ngOnInit(): void {
    this._config.setTitle('Einstellungen | Schleifen');
    this.loadData();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  loadData() {
    this._http.get<AlarmLines[]>(`${getHostUrl()}datainfo/alarmlines?pager=0`).subscribe(result => {
      this.showLoader = false;
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    const data: AlarmLines[] = this.dataSource.data;
    moveItemInArray(data, event.previousIndex, event.currentIndex);
    this.dataSource.data = data;
    this._http.post(`${getHostUrl()}admin/settings/fireplace/alarmlines/sort`, { alarmLines: data }).subscribe(() => {
      this.loadData();
    });
  }

  editAlarmline(element) {
    this._dialog.open(AdminSettingsAlarmlinesModalComponent, {
      data: element,
      width: '1000px',
      height: '600px',
    }).afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  deleteAlarmLine(element) {
    Swal.fire({
      title: 'Löschen?',
      html: `Soll die Schleife "<b>${element.name}</b>" gelöscht werden?`,
      confirmButtonText: 'Ja',
      denyButtonText: 'Nein',
      showDenyButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      icon: 'question',
    }).then(swalResult => {
      if (swalResult.isConfirmed) {
        this.showLoader = true;
        this._http.delete(`${getHostUrl()}admin/settings/fireplace/alarmlines/${element.id}`).subscribe(result => {
          this.loadData();
          this._config.showSnackbar('Schleife erfolgreich gelöscht!', 3000, 'success');
        }, error => {
          this.showLoader = false;
          if(error.status === 520) {
            this._config.showSnackbar(error.error, 3000, 'error');
          } else {
            this._config.showSnackbar('Ein Fehler ist beim Löschen der Schleife aufgetreten!', 3000, 'error');
          }
        });
      }
    });
  }
}
