import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.css']
})
export class NotificationSettingsComponent implements OnInit {

  notificationState: NotificationPermission | 'unkown' = "unkown";
  pushAvailable: boolean = false;
  serverPublicKey: string;

  constructor(private _swPush: SwPush, private _http: HttpClient, private _config: ConfigModule) { }

  ngOnInit(): void {
    try {
      this.notificationState = Notification.permission || "unkown";
    } catch {
      this.notificationState = "unkown";
    }
    this.loadServerKey();
    this.pushAvailable = this._swPush.isEnabled;

  }

  loadServerKey(): void {
    this._http.get<string>(getHostUrl() + 'datainfo/push/publicKey').subscribe({
      next: (publicKey) => {
        this.serverPublicKey = publicKey;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  registerSubscription(): void {
    this._swPush.requestSubscription({ serverPublicKey: this.serverPublicKey }).then((subscription: PushSubscription) => {
      try {
        this.notificationState = Notification.permission || "unkown";
      } catch {
        this.notificationState = "unkown";
      }
      const subJSON: PushSubscriptionJSON = subscription.toJSON();
      this._http.post(getHostUrl() + 'user/notifications/settings/register', {
        endpoint: subJSON.endpoint,
        keys: subJSON.keys
      }).subscribe({
        next: () => {
          this._config.showSnackbar('Pushbenachrichtigungen aktiviert', 2000, 'success');
        },
        error: (err) => {
          console.error(err);
        }
      });
    }).catch((err) => {
      console.error(err);
    })
  }
}
