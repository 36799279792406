<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Zeiterfassung</h3>
    <mat-card>
      <mat-card-content>
        <a (click)="openMenu()" mat-mini-fab class="pageNext" color="primary">
          <mat-icon>apps</mat-icon>
        </a>

        <app-time-tracking-day-list #dayList [hidden]="viewMode != 'day'"
          [currentDate]="currentDate"></app-time-tracking-day-list>
        <app-time-tracking-month-list #monthList (hasJumped)="switchView()" [hidden]="viewMode != 'month'"
          [(currentDate)]="currentDate"></app-time-tracking-month-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #timeMenu>
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
    <mat-icon>close</mat-icon>
  </a>
  <mat-nav-list class="mt-5">
    <mat-list-item (click)="_bottomSheet.dismiss(); createTime()">
      <h3 matListItemLine>Neue Buchung</h3>
      <div matListItemMeta>
        <mat-icon>timer</mat-icon>
      </div>
    </mat-list-item>
    <mat-list-item (click)="_bottomSheet.dismiss(); switchView()">
      <h3 matListItemLine>Ansicht wechseln</h3>
      <div matListItemMeta>
        <mat-icon>swap_horiz</mat-icon>
      </div>
    </mat-list-item>
    <mat-list-item routerLink="/admin/timetracking" *ngIf="_login.hasPermission('admin-time')"
      (click)="_bottomSheet.dismiss()">
      <h3 matListItemLine>Administration</h3>
      <div matListItemMeta>
        <mat-icon>manage_history</mat-icon>
      </div>
    </mat-list-item>
  </mat-nav-list>
</ng-template>