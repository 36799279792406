import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';

@Component({
  selector: 'app-alarms-confirmation-dialog',
  templateUrl: './alarms-confirmation-dialog.component.html',
  styleUrls: ['./alarms-confirmation-dialog.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class AlarmsConfirmationDialogComponent implements OnInit {

  constructor(private _http: HttpClient,
    private _dialogRef: MatDialogRef<AlarmsConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _login: Login,
    private _config: ConfigModule,
    protected sanitizer: DomSanitizer) { }

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  persons: MatTableDataSource<any>;
  personColumns: string[] = ['name', 'action'];
  selectedNumber = 0;
  personList = [];
  showLoader = true;
  baseURL = getHostUrl();

  ngOnInit(): void {
    this._http.get<any>(`${getHostUrl()}alarms/fez/confirmationPersons?alarmId=${this.data.alarmId}`)
      .subscribe(result => {
        this.personList = result;
        this.persons = new MatTableDataSource<any>();
        this.persons.data = this.personList;
        this.persons.paginator = this.paginator;
        this.persons.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'name': return item.lastname;
            default: return item[property];
          }
        };
        this.showLoader = false;
      }, error => {
        this._dialogRef.close();
        this._config.showSnackbar(`Konnte Bestätigungen nicht laden! ${error.error.message}`, 3000, 'error', 'bottom-end');
      });
  }

  getPersons() {
    return this.personList.filter(s => s.print === 1).map(s => s.id);
  }

  toggleField(element) {
    if (element.print === 1) {
      element.print = 0;
      this.selectedNumber--;
    } else {
      this.selectedNumber++;
      element.print = 1;
    }
  }
}
