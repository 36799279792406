import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { StatisticSettings } from '@ffo/mitgliederbereich-types';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { AdminSettingsStatisticsModalComponent } from './admin-settings-statistics-modal/admin-settings-statistics-modal.component';

@Component({
  selector: 'app-admin-settings-statistics',
  templateUrl: './admin-settings-statistics.component.html',
  styleUrls: ['./admin-settings-statistics.component.css'],
})
export class AdminSettingsStatisticsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['name', 'description', 'type'];

  dataSource = new MatTableDataSource<StatisticSettings>();

  showLoader = true;

  constructor(private _config: ConfigModule,
    private _dialog: MatDialog,
    private _http: HttpClient) { }

  ngOnInit(): void {
    this._config.setTitle('Einstellungen | Auswertungen');
    this.loadData();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  loadData() {
    this._http.get<StatisticSettings[]>(`${getHostUrl()}admin/settings/general/statistics`).subscribe(result => {
      this.showLoader = false;
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  editStatistics(element: StatisticSettings) {
    this._dialog.open(AdminSettingsStatisticsModalComponent, {
      data: element,
      width: '800px',
      height: '300px',
    }).afterClosed().subscribe(result => {
      this.loadData();
    });
  }
}
