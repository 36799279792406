<div class="row">
    <div class="col-lg-3 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Anforderer</mat-label>
            <input matInput [value]="parent.getUser(element.userID)" disabled>
            <mat-icon matSuffix
                routerLink="/admin/user/edit/{{element.userID}}/masterdata"
                class="pointer">
                person</mat-icon>
        </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Benutzer</mat-label>
            <input matInput [value]="parent.getUser(element.targetID)" disabled>
            <mat-icon matSuffix
                routerLink="/admin/user/edit/{{element.targetID}}/masterdata"
                class="pointer">person</mat-icon>
        </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Uhrzeit</mat-label>
            <input matInput [value]="getFormattedDate(element.time)" disabled>
        </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Aktion</mat-label>
            <input matInput [value]="element.action" disabled>
        </mat-form-field>
    </div>
    <div class="col-12 col-md-6 col-lg-6" *ngIf="!picture">
        <div class="row">
            <div class="col-12">
                <h6>Alte Daten</h6>
            </div>
            <div class="col-12" *ngIf="classes_old">
                <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Führerschein Klasse</mat-label>
                    <input matInput placeholder="Führerschein Klasse" [(ngModel)]="classes_old.licenceClass" disabled>
                </mat-form-field>
            </div>
            <div class="col-12" *ngIf="classes_old">
                <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Ablaufdatum</mat-label>
                    <input matInput placeholder="Ablaufdatum" [value]="getFormattedDate(classes_old.expiryDate)"
                        disabled required>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6" *ngIf="picture">
        <img [src]="img" style="width: 100%;">
    </div>
    <div class="col-12 col-md-6 col-lg-6">
        <div class="row">
            <div class="col-12">
                <h6>Neue Daten</h6>
            </div>
            <div class="col-12" [ngClass]="picture ? 'col-md-6 col-lg-6' : 'col-md-12 col-lg-12'">
                <div [ngClass]="classes_old && classes_old.licenceClass===classes.licenceClass ? 'same' : 'changed'">
                    <mat-form-field id="dateOfIssue" class="input-full-width" appearance="outline">
                        <mat-label>Führerschein Klasse</mat-label>
                        <input matInput placeholder="Führerschein Klasse" [(ngModel)]="classes.licenceClass" disabled>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6" *ngIf="picture">
                <div class="input-full-width" [ngClass]="env.mobile===false ? 'trailer' : 'trailerMobile'">
                    <mat-slide-toggle [(ngModel)]="classes.trailer" (toggleChange)="changeTrailer(classes)"><img
                            src="{{getLicenceTrailerIcon(classes.licenceClass)}}" class="trailerIcon">
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="col-12" *ngIf="classes.licenceClass === 'Klasse FFF' || classes.licenceClass === 'Klasse B' || classes.licenceClass === 'Klasse BE'">
                <mat-form-field class="input-full-width" appearance="outline" *ngIf="!unlimited">
                    <mat-label>Ablaufdatum</mat-label>
                    <input matInput required disabled [value]="getFormattedDate(classes.expiryDate)">
                </mat-form-field>
                <mat-form-field class="input-full-width" appearance="outline" *ngIf="unlimited">
                    <mat-label>Ablaufdatum</mat-label>
                    <input matInput required disabled value="unbegrenzt">
                </mat-form-field>
            </div>
            <div class="col-12"
                *ngIf="(classes.licenceClass === 'Klasse C1' || classes.licenceClass === 'Klasse C' || classes.licenceClass === 'Klasse C1E' || classes.licenceClass === 'Klasse CE') && !unlimited"
                [ngClass]="classes_old && classes_old.expiryDate===classes.expiryDate ? 'same' : 'changed'">
                <mat-form-field id="expiryDate" *ngIf="!env.mobile" class="input-full-width" appearance="outline">
                    <mat-label>Ablaufdatum</mat-label>
                    <button mat-icon-button matPrefix (click)="setUnlimited(true)"
                        *ngIf="classes.licenceClass === 'Klasse C1' || classes.licenceClass === 'Klasse C1E'">
                        <mat-icon>swap_calls</mat-icon>
                    </button>
                    <input matInput required [matDatepicker]="expiryDate" placeholder="Ablaufdatum"
                        [value]="classes.expiryDate" (dateChange)="onexpiryDateChange($event)"
                        (change)="onexpiryChange($event)" (focus)="expiryDate.open()" [disabled]="!picture">
                    <mat-datepicker-toggle matSuffix [for]="expiryDate" *ngIf="picture"></mat-datepicker-toggle>
                    <mat-datepicker #expiryDate [startAt]="startDate" startView="multi-year"></mat-datepicker>
                </mat-form-field>
                <mat-form-field id="expiryDate" *ngIf="env.mobile" class="input-full-width" appearance="outline">
                    <mat-label>Ablaufdatum</mat-label>
                    <input matInput required [matDatepicker]="expiryDate" placeholder="Ablaufdatum"
                        [value]="classes.expiryDate" (dateChange)="onexpiryDateChange($event)"
                        (change)="onexpiryChange($event)" (focus)="expiryDate.open()" [disabled]="!picture">
                    <mat-datepicker-toggle matSuffix [for]="expiryDate" *ngIf="picture"></mat-datepicker-toggle>
                    <mat-datepicker #expiryDate touchUi [startAt]="startDate" startView="multi-year"></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-12"
                *ngIf="(classes.licenceClass === 'Klasse C1' || classes.licenceClass === 'Klasse C' || classes.licenceClass === 'Klasse C1E' || classes.licenceClass === 'Klasse CE') && unlimited"
                [ngClass]="classes_old && classes_old.expiryDate===classes.expiryDate ? 'same' : 'changed'">
                <mat-form-field id="expiryDate" class="input-full-width" appearance="outline">
                    <mat-label>Ablaufdatum</mat-label>
                    <button mat-icon-button matPrefix (click)="setUnlimited(false)"
                        *ngIf="classes.licenceClass === 'Klasse C1' || classes.licenceClass === 'Klasse C1E'">
                        <mat-icon>swap_calls</mat-icon>
                    </button>
                    <input matInput required placeholder="Ablaufdatum Führerschein" value="unbegrenzt" disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div class="clearfix">
    <button mat-raised-button class="float-right" color="primary" *ngIf="!picture"
        (click)="picture = true">weiter</button>
    <button mat-raised-button class="float-right" color="success" *ngIf="picture" (click)="save()">speichern
        <mat-icon>save</mat-icon>
    </button>
</div>