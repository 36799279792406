import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { filter, firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  displayedColumns: string[] = ['type', 'name', 'status', 'check', 'actions'];

  dataSource = new MatTableDataSource();
  isLoading = true;

  filteredVehicles = [];
  textFilter = "";

  dayjs = dayjs;

  constructor(private _http: HttpClient,
    private _bottomSheet: MatBottomSheet,
    private _config: ConfigModule) { }

  ngOnInit(): void {
    this.loadData();
    this._config.setTitle('Fahrzeuge');

    this.dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'check': 
          let value = dayjs().add(100, 'year').toDate()
          if (this.sort.direction == 'desc') value = dayjs().toDate();
          return item.cycle ? dayjs(item.cycle).toDate() : value;
        case 'type':
          return item.cartype;
        default: return item[property];
      }
    };
  }

  applyFilter(filterValue = null) {
    if (filterValue != null) {
      this.textFilter = filterValue;
    }
    this.dataSource.filter = JSON.stringify({ search: this.textFilter, cartype: this.filteredVehicles })

    this.dataSource.filterPredicate = (data: any, filter: any): boolean => {
      let status = true;

      if (this.textFilter) {        
        if (!data.name.toLowerCase().includes(this.textFilter.toLowerCase())) {
          status = false;
        }
      }

      if (this.filteredVehicles.length > 0) {
        if (!this.filteredVehicles.includes(data.cartype)) {
          status = false;
        }
      }
      return status;
    };
  }

  async loadData() {
    let result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/vehicles`));
    result = result.map(vehicle => {
      if (vehicle.cycle) {
        vehicle.nextCycle = dayjs(vehicle.cycle).diff(dayjs(), 'days');
      }
      return vehicle;
    });
    this.dataSource.data = result;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.isLoading = false;
  }

  getIconByNumber(status) {
    switch (parseInt(status, 10)) {
      case 1:
        return '1️⃣';
      case 2:
        return '2️⃣';
      case 3:
        return '3️⃣';
      case 4:
        return '4️⃣';
      case 5:
        return '5️⃣';
      case 6:
        return '6️⃣';
      case 7:
        return '7️⃣';
      case 8:
        return '8️⃣';
      case 9:
        return '9️⃣';
      default:
        return '0️⃣';
    }
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu);
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }

  changeFilter(event) {
    this.filteredVehicles = event.value?.flat(1);
    this.applyFilter();
  }
}
