import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Login } from '../login';

@Injectable()
export class RouteGuard  {

  constructor(private _login: Login,
    private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const loginResponse = this._login.isLoggedIn()
      .pipe(map((response) => {
        if (response) {
          if (next.data?.perm) {
            const perms = next.data.perm.split(',');
            Promise.all(
              perms.map((perm: string) => this._login.hasPermissionProm(perm.trim())),
            ).then((values) => {
              let result = true;
              values.forEach((val: boolean) => {
                if (!val) {
                  result = false;
                  return;
                }
              });
              if (result) {
                return true;
              } else {
                this._router.navigate(['/403']);
              }
            });
          }
          return true;
        } else {
          let url = window.location.pathname;
          if (url !== '/login')
            this._router.navigate(['/login'], { queryParams: { redirect: url } });
          return false;
        }
      }));
    return loginResponse;
  }

  permCheck(page: string) {
    if (page.indexOf('responder') !== -1) {
      return this._login.hasPermission('responder');
    } else if (page === 'admin/user/') {
      return this._login.hasPermission('admin-user');
    } else if (page.indexOf('admin/user/edit/\d\d\d/masterdata') !== -1) {
      return this._login.hasPermission('admin-user-view');
    } else if (page.indexOf('admin/user/edit/\d\d\d/firedata') !== -1) {
      return this._login.hasPermission('admin-user-firedata-view');
    } else if (page.indexOf('admin/user/edit/\d\d\d/security') !== -1) {
      return this._login.hasPermission('admin-user-view');
    } else if (page.indexOf('admin/user/edit/\d\d\d/driving') !== -1) {
      return this._login.hasPermission('admin-user-driving-view');
    } else if (page.indexOf('admin/user/edit/\d\d\d/driving/assets') !== -1) {
      return this._login.hasPermission('admin-user-driving-view');
    } else if (page.indexOf('admin/user/edit/\d\d\d/atemschutz') !== -1) {
      return this._login.hasPermission('admin-user-atemschutz-view');
    } else if (page.indexOf('admin/user/edit/\d\d\d/atemschutz/assets') !== -1) {
      return this._login.hasPermission('admin-user-atemschutz-view');
    } else if (page.indexOf('admin/licenceCheck') !== -1) {
      return this._login.hasPermission('admin-user-drivingperm-edit');
    } else if (page.indexOf('admin/user/edit/\d\d\d/emergencycontacts') !== -1) {
      return this._login.hasPermission('admin-emergencycontact-list');
    } else if (page.indexOf('admin/user/new') !== -1) {
      return this._login.hasPermission('admin-user-new');
    } else if (page.indexOf('admin/groups/edit') !== -1) {
      return this._login.hasPermission('admin-groups-edit');
    } else if (page.indexOf('admin/groups/new') !== -1) {
      return this._login.hasPermission('admin-groups-new');
    } else if (page.indexOf('admin/groups') !== -1) {
      return this._login.hasPermission('admin-groups-list');
    } else if (page.indexOf('admin/notifications/new') !== -1) {
      return this._login.hasPermission('admin-notification-new');
    } else if (page.indexOf('admin/notifications') !== -1) {
      return this._login.hasPermission('admin-notification');
    } else if (page.indexOf('admin/event/new') !== -1) {
      return this._login.hasPermission('admin-event-edit');
    } else if (page.indexOf('admin/event/copy') !== -1) {
      return this._login.hasPermission('admin-event-edit');
    } else if (page.indexOf('admin/event') !== -1) {
      if (this._login.hasPermission('admin-event') || this._login.hasPermission('groups-event'))
        return true;
      else
        return false;
    } else if (page.indexOf('admin/fields/maschinisten') !== -1) {
      return this._login.hasPermission('admin-user-drivingperm-edit');
    } else if (page.indexOf('admin/fields/atemschutz') !== -1) {
      return this._login.hasPermission('admin-user-atemschutz-edit');
    } else if (page.indexOf('eventWatcher') !== -1) {
      return this._login.hasPermission('event-watcher');
    } else if (page.indexOf('event') !== -1) {
      return this._login.hasPermission('event');
    } else if (page.indexOf('profile/alarms') !== -1) {
      return this._login.hasPermission('profile-alarms');
    } else if (page.indexOf('user') !== -1) {
      return this._login.hasPermission('user-info');
    } else if (page.indexOf('admin/logs') !== -1) {
      return this._login.hasPermission('admin-logging');
    } else if (page.indexOf('admin/changescontrol') !== -1) {
      return this._login.hasPermission('admin-control');
    } else if (page.startsWith('alarms') === true) {
      return this._login.hasPermission('alarm-view') && this._login.hasPermission('alarm-view-non-whitelisted');
    } else if (page.indexOf('admin/settings') !== -1) {
      return this._login.hasPermission('admin-settings');
    }
    return true;
  }
}
