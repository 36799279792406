<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Sicherheitswachen Personal</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <div class="row">
          <div class="col-lg-8 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applySearch($event.target.value)" placeholder="Suche">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Wachstatus</mat-label>
              <mat-select multiple (selectionChange)="applyStateFilter($event)" [(ngModel)]="stateFilter">
                <mat-option value="WM">Wachmann</mat-option>
                <mat-option value="WL">Wachleiter</mat-option>
                <mat-option value="">ohne Status</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <div class="table-responsive">
              <div *ngIf="loading">
                <mat-progress-bar mode="query"></mat-progress-bar>
              </div>
              <table mat-table [dataSource]="users" matSort matSortActive="id"
                matSortDirection="asc" matSortDisableClear>
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr.</th>
                  <td mat-cell *matCellDef="let user">{{user.userID}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                  <td mat-cell *matCellDef="let user">{{user.firstname}} {{user.lastname}}</td>
                </ng-container>

                <ng-container matColumnDef="state">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{env.mobile ? 'WS' : 'Wachstatus'}}</th>
                  <td mat-cell *matCellDef="let user" [ngSwitch]="user.state">
                    <span *ngSwitchCase="'WM'">{{env.mobile ? 'WM' : 'Wachmann'}}</span>
                    <span *ngSwitchCase="'WL'">{{env.mobile ? 'WL' : 'Wachleiter'}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="active">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktiv</th>
                  <td mat-cell *matCellDef="let user" [ngSwitch]="user.active">
                    <mat-icon *ngSwitchCase="true" class="mat-green">check</mat-icon>
                    <mat-icon *ngSwitchCase="false" color="warn">close</mat-icon>
                  </td>
                </ng-container>

                <ng-container matColumnDef="age">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Alter</th>
                  <td mat-cell *matCellDef="let user">{{user.age}}</td>
                </ng-container>

                <ng-container matColumnDef="officialRank">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Rank</th>
                  <td mat-cell *matCellDef="let user">{{user.officialRank}}</td>
                </ng-container>

                <ng-container matColumnDef="mta">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>MTA</th>
                  <td mat-cell *matCellDef="let user"><mat-icon [class.mat-green]="user.mtaAbschluss" [class.mat-yellow]="user.mtaBasis && !user.mtaAbschluss" [class.mat-red]="!user.mtaBasis" [matTooltip]="user.mtaAbschluss ? 'MTA abgeschlossen' : (user.mtaBasis ? 'MTA Basis abgeschlossen' : 'keine MTA abgeschlossen')">{{user.mtaBasis ? 'done' : 'close'}}</mat-icon></td>
                </ng-container>

                <ng-container matColumnDef="days">
                  <th mat-header-cell *matHeaderCellDef>{{env.mobile ? '' : 'Einteilungstage'}}</th>
                  <td mat-cell *matCellDef="let user">{{user.days.join(', ')}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="viewColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: viewColumns" (click)="openDetailsDialog(row)"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>