<form [formGroup]="group" class="row mt-2">
  <div class="col-lg-6 col-md-6 col-12" [hidden]="_host.status === 'offen' || _host.status === 'in Bearbeitung'">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Ersteller Bericht</mat-label>
      <input matInput formControlName="creator_name" readonly="true">
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-md-6 col-12" [hidden]="_host.status === 'offen' || _host.status === 'in Bearbeitung'">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Kommentar</mat-label>
      <input matInput formControlName="notes" readonly="true">
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-md-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Stichwort</mat-label>
      <mat-select formControlName="keyword" required>
        <mat-option>
          <ngx-mat-select-search [formControl]="keywordFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let keyword of keywordFilter | async" [value]="keyword.shortName">
          {{ keyword.shortName }} - {{ keyword.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!group.get('keyword').valid">
        Stichwort muss ausgewählt werden
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-md-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Alarmierung</mat-label>
      <mat-select multiple formControlName="alarmLines" required>
        <mat-option>
          <ngx-mat-select-search [formControl]="alarmLinesFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let alarmLine of alarmLinesFilter | async" [value]="alarmLine.id.toString()">
          <span
            matTooltip="Letzte Alarmierung: {{ formatDayjs(alarmLine.lastalarm, 'DD.MM.YYYY HH:mm:ss') }}"
            [matTooltipDisabled]="alarmLine.pager!==1" matTooltipPosition="right" matTooltipClass="pagerLastAlarm">
            {{ alarmLine.name }}
          </span>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!group.get('alarmLines').valid">
        Alarmierung muss angegeben werden
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-xl-9 col-lg-9 col-md-8 col-8">
    <mat-form-field class="input-full-width" appearance="outline" (click)="!disabledElements && findAddress()">
      <mat-label>Einsatzstraße</mat-label>
      <input matInput formControlName="street" readonly="true" required>
      <mat-error *ngIf="!group.get('street').valid">
        Straße muss angegeben werden
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-xl-3 col-lg-3 col-md-4 col-4">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Hausnummer</mat-label>
      <input matInput formControlName="housenumber">
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Adresszusatz</mat-label>
      <input matInput formControlName="additionalStreet" />
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-6 col-6">
    <mat-form-field class="input-full-width" appearance="outline" (click)="!disabledElements && findAddress()">
      <mat-label>PLZ</mat-label>
      <input matInput formControlName="zipcode" readonly="true" required>
      <mat-error *ngIf="!group.get('zipcode').valid">
        Postleitzahl muss angegeben werden
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-8 col-md-6 col-6">
    <mat-form-field class="input-full-width" appearance="outline" (click)="!disabledElements && findAddress()">
      <mat-label>Einsatzort</mat-label>
      <input matInput formControlName="city" readonly="true" required>
      <mat-error *ngIf="!group.get('city').valid">
        Einsatzort muss angegeben werden
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-12" *ngIf="group.get('country').value !== 'Deutschland'">
    <mat-form-field class="input-full-width" appearance="outline" (click)="!disabledElements && findAddress()">
      <mat-label>Land</mat-label>
      <input matInput formControlName="country" readonly="true" required>
      <mat-error *ngIf="!group.get('country').valid">
        Land muss angegeben werden
      </mat-error>
    </mat-form-field>
  </div>
</form>