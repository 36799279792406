<h1 mat-dialog-title>Termin abschließen</h1>
<div mat-dialog-content>
  <form [formGroup]="group" class="row">
    <div class="col-lg-6 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Enddatum</mat-label>
        <input matInput formControlName="endDate" [matDatepicker]="picker" readonly="true" required
          (focus)="picker.open()" style="cursor: pointer" [min]="startDate" [max]="today"/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Uhrzeit Ende</mat-label>
        <input matInput required inputmode="numeric" formControlName="endTime" (keyup)="parseTimeField($event)" cdkFocusInitial placeholder="12:00">
        <mat-error *ngIf="group.get('endTime').valid === false">Enduhrzeit muss ausgefüllt und gültig sein!</mat-error>
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Beschreibung</mat-label>
        <textarea matInput required formControlName="description" id="description"></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <div class="clearfix w-100">
    <button mat-raised-button color="success" class="float-end me-3" (click)="save()"
    [disabled]="!group.valid">Abschließen<mat-icon>check_circle</mat-icon></button>
  </div>
</div>