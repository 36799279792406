import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getHostUrl, ConfigModule } from 'src/app/util/config';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Login } from 'src/app/util/login';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminUserMenusheetComponent } from '../admin-user-menusheet/admin-user-menusheet.component';

@Component({
  selector: 'app-admin-user-emergencycontacts',
  templateUrl: './admin-user-emergencycontacts.component.html',
  styleUrls: ['./admin-user-emergencycontacts.component.css'],
})
export class AdminUserEmergencycontactsComponent implements OnInit, OnDestroy {

  removedContactList: any = [];

  relationshipList: any = ['Mutter', 'Vater', 'Partner', 'Kind', 'Erziehungsberechtigter', 'Andere'];

  contactsvalid: boolean = true;

  userID: number;

  userInfo: any = {};

  firstname: string;

  lastname: string;

  emergencyContactList: any = [];

  emergenyNameFormControl: UntypedFormControl[] = [];

  emergenyPhoneFormControl: UntypedFormControl[] = [];

  emergenyEmailFormControl: UntypedFormControl[] = [];


  constructor(private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    public _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.userID = this._route.snapshot.params.userId;
    if (this.userID) {
      this.fetchEmergenyData(this.userID);
      this._config.setTitle('Verwaltung | Lädt...');
    }
  }

  fetchEmergenyData(userID: number) {
    this._http.get<any>(getHostUrl() + 'emergencycontact?targetID=' + userID).subscribe(data => {
      this.emergencyContactList = data;
      this.emergenyNameFormControl = [];
      this.emergenyPhoneFormControl = [];
      this.emergenyEmailFormControl = [];
      this.removedContactList = [];
      for (let i = 0; i < this.emergencyContactList.length; i++) {
        this.emergenyNameFormControl.push(new UntypedFormControl('', [
          Validators.required,
          //Validators.pattern(/^[a-zA-Z]+[ ]{1}[a-zA-Z]+$/)
        ]));
        this.emergenyPhoneFormControl.push(new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^([+]\d{2})(\d{4,20})$/),
        ]));
        this.emergenyEmailFormControl.push(new UntypedFormControl('', [
          Validators.email,
        ]));
        this.emergenyNameFormControl[i].setValue(this.emergencyContactList[i].name);
        this.emergenyPhoneFormControl[i].setValue(this.emergencyContactList[i].phone);
        this.emergenyEmailFormControl[i].setValue(this.emergencyContactList[i].email);
        if (!this._login.hasPermission('admin-emergencycontact-edit')) {
          this.emergenyNameFormControl[i].disable();
          this.emergenyPhoneFormControl[i].disable();
          this.emergenyEmailFormControl[i].disable();
        }
      }
    });
    this._http.get<any>(getHostUrl() + 'admin/user/info?userID=' + userID).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this._config.setTitle(`${data.firstname + ' ' + data.lastname}`);
    });
  }

  saveContactsData() {
    const context = this;
    if (this.userID) {

      this._http.post(getHostUrl() + 'emergencycontact', { userID: this.userID, contacts: this.emergencyContactList, removed: this.removedContactList }).subscribe(resp => {
        context._spinner.hide();
        context._config.showSnackbar('Notfallkontakte gespeichert!', 2000, 'success');
        context.fetchEmergenyData(context.userID);
      }, error => {
        context._spinner.hide();
        if (error.error === 5001)
          context._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
        else
          context._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
        context.fetchEmergenyData(context.userID);
      });
    }
  }

  addContactField() {
    this.emergenyNameFormControl.push(new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z]+[ ]{1}[a-zA-Z]+$/),
    ]));
    this.emergenyPhoneFormControl.push(new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^([+]\d{2})(\d{4,20})$/),
    ]));
    this.emergenyEmailFormControl.push(new UntypedFormControl('', [
      Validators.email,
    ]));
    this.emergencyContactList.push({
      id: null,
      name: '',
      relationship: '',
      email: '',
      phone: '',
    });
  }

  removeContactField(index: number) {
    this.removedContactList.push({ id: this.emergencyContactList[index].id });
    this.emergencyContactList.splice(index, 1);
    this.emergenyNameFormControl.splice(index, 1);
    this.emergenyPhoneFormControl.splice(index, 1);
    this.emergenyEmailFormControl.splice(index, 1);
    this.contactsvalid = this.checkContacts();
  }

  checkContacts() {
    for (const contact of this.emergencyContactList) {
      if (contact.name === '' || contact.relationship === '' || contact.phone === '') {
        return false;
      }
    }
    return true;
  }

  onNameChanged(index: number) {
    this.emergencyContactList[index].name = this.emergenyNameFormControl[index].value;
    if (this.emergenyNameFormControl[index].valid) {
      this.contactsvalid = this.checkContacts();
    } else {
      this.contactsvalid = false;
    }
  }

  onRelationshipChanged(index: number, event: MatSelectChange) {
    this.emergencyContactList[index].relationship = event.value;
    this.contactsvalid = this.checkContacts();
  }

  onEmailChanged(index: number) {
    this.emergencyContactList[index].email = this.emergenyEmailFormControl[index].value;
    if (this.emergenyEmailFormControl[index].valid) {
      this.contactsvalid = this.checkContacts();
    } else {
      this.contactsvalid = false;
    }
  }

  onPhoneChanged(index: number) {
    this.emergencyContactList[index].phone = this.emergenyPhoneFormControl[index].value;
    if (this.emergenyPhoneFormControl[index].valid) {
      this.contactsvalid = this.checkContacts();
    } else {
      this.contactsvalid = false;
    }
  }


  /**
 * Menu Bottom Sheet
 */
  openMenu() {
    const bottomSheetRef = this._bottomSheet.open(AdminUserMenusheetComponent, { data: { userID: this.userID, name: this.firstname + ' ' + this.lastname } });
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }
}
