import { DialogRef } from '@angular/cdk/dialog';
import { Platform } from '@angular/cdk/platform';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { AddressDialogComponent } from 'src/app/util/dialogs/address-dialog/address-dialog.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

interface User {
  salutation: 'frau' | 'herr';
  firstname: string;
  lastname: string;
  email: string;
  internalEmail: string;
  mobile: string;
  phone: string;
  street: string;
  appartment: string;
  zipcode: number;
  city: string;
  nationality: string;
  birthdate: string;
  birthplace: string;
}

@Component({
  selector: 'app-admin-user-new',
  templateUrl: './admin-user-new.component.html',
  styleUrls: ['./admin-user-new.component.css'],
  providers: [
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } },
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ],
})
export class AdminUserNewComponent implements OnInit {

  salutations = [
    { val: 'm', text: 'Herr' },
    { val: 'w', text: 'Frau' },
    { val: 'd', text: 'Divers' },
  ];
  cityList: any;
  streetList: any;
  countryList: any;
  filteredCityList: any = null;
  filteredStreetList: any = null;
  today: Date = new Date();

  env = environment;

  group: UntypedFormGroup;

  constructor(private _builder: UntypedFormBuilder,
    private _dialog: MatDialog,
    private _config: ConfigModule,
    private _ref: DialogRef<AdminUserNewComponent>,
    private _router: Router,
    private _http: HttpClient) { }

  ngOnInit(): void {
    this.group = this._builder.group({
      salutation: ['', Validators.required],
      title: ['0', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      internalEmail: ['', Validators.email],
      mobile: ['', [Validators.required, Validators.pattern(/^([+]\d{2})\d{8,12}$/)]],
      phone: ['', [Validators.pattern(/^([+]\d{2})\d{8,12}$/)]],
      street: ['', Validators.required],
      appartment: ['', Validators.required],
      zipcode: ['', Validators.required],
      city: ['', Validators.required],
      country: [{ value: '', readonly: true }, Validators.required],
      nationality: ['', Validators.required],
      birthdate: ['', Validators.required],
      birthplace: ['', Validators.required],
      birthplaceZipcode: [{ value: '', disabled: true }, Validators.required],
      birthplaceCountry: [{ value: '', disabled: true }, Validators.required],
    });
    this.fetchDataInfo();
  }

  clearBirthPlace() {
    this.group.get('birthplace').setValue('');
  }

  findAddress() {
    const dialogRef = this._dialog.open(AddressDialogComponent, {
      width: '500px',
      data: {
        cities: false,
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data?.street) {
        return;
      }

      this.group.get('street').setValue(data.street);
      this.group.get('city').setValue(data.city);
      this.group.get('zipcode').setValue(data.zipcode);
      this.group.get('country').setValue(data.country);
    });
  }

  findBirthPlace() {
    const dialogRef = this._dialog.open(AddressDialogComponent, {
      width: '500px',
      data: {
        cities: true,
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === null) {
        return;
      }

      this.group.get('birthplace').setValue(data.city);
      this.group.get('birthplaceZipcode').setValue(data.zipcode);
      this.group.get('birthplaceCountry').setValue(data.country);
    });
  }

  async saveData() {
    if (this.group.invalid)
      return;

    this.group.disable();

    try {
      const group = this.group.value;
      group.birthdate = dayjs(group.birthdate).format('YYYY-MM-DD');
      group.portal = window.location.protocol + '//' + window.location.hostname;
      const data = await this._http.post<any>(`${getHostUrl()}admin/user`, group).toPromise();
      this._config.showSnackbar('Benutzer wurde erfolgreich angelegt!', 3000, 'success');
      this._ref.close();
      await Swal.fire({
        title: 'Benutzer erstellt',
        icon: 'success',
        html: `
          <h3>${this.group.value.firstname} ${this.group.value.lastname}</h3>

          <p><b>Stammnummer:</b> ${data.id}</p>
          <p><b>Passwort:</b> ${data.password}</p>

          <i>Zugangsdaten wurden an folgende E-Mail Adresse versendet: ${this.group.value.email}</i>
        `,
        showCloseButton: true
      });
      this._router.navigate(['admin', 'user', 'edit', data.id, 'masterdata']);
    } catch (e) {
      if (e.error.message) {
        this._config.showSnackbar(e.error.message, 3000, 'warning');
      } else this._config.showSnackbar('Ein Fehler ist beim Speichern des Benutzers aufgetreten!', 3000, 'warning');
      this.group.enable();
    }
  }

  async closePopup() {
    if (this.group.touched) {
      const result = await Swal.fire({
        text: 'Möchtest du wirklich die Benutzer Erstellung abbrechen?',
        showDenyButton: true,
        showConfirmButton: true,
        icon: 'question',
        confirmButtonText: 'Beenden',
        denyButtonText: 'Abbrechen'
      });
      if (result.isConfirmed) {
        this._ref.close();
      }
    } else {
      this._ref.close();
    }
  }

  async fetchDataInfo() {
    this.streetList = await this._http.get(`${getHostUrl()}datainfo/streets`).toPromise();
    this.cityList = await this._http.get(`${getHostUrl()}datainfo/cities`).toPromise();
    this.countryList = await this._http.get(`${getHostUrl()}datainfo/countries`).toPromise();
  }

}
