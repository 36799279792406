import { Injectable, ApplicationRef, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import $ from 'jquery';
import { WebConfigService } from './webconfig/webconfig.service';

@Injectable()
export class ThemingService {

  themes = ['standard-dark-theme', 'red-dark-theme', 'standard-theme', 'red-theme']; // <- list all themes in this array

  theme = new BehaviorSubject('standard-theme'); // <- initial theme
  currentTheme = 'standard-theme';
  currentDarkTheme = 'standard-dark-theme';
  currentMode = false;

  constructor(private ref: ApplicationRef,
    @Inject(DOCUMENT) private document,
    private _webConfig: WebConfigService) {
    // Initially check if dark mode is enabled on system
    const darkModeOn =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    let darkMode = localStorage.getItem('darkMode');

    if (darkMode === 'enabled'){
      this.toggleDarkMode(true);
    } else if (darkMode === 'disabled'){
      this.toggleDarkMode(false);
    } else {
      if (darkModeOn){
        // If dark mode is enabled then directly switch to the dark-theme
        this.toggleDarkMode(true);
      } else {
        this.toggleDarkMode(false);
      }
    }

    // Watch for changes of the preference
    window.matchMedia('(prefers-color-scheme: dark)').addListener(e => {
      darkMode = localStorage.getItem('darkMode');
      if (darkMode){
        return;
      }
      const turnOn = e.matches;
      this.toggleDarkMode(turnOn);  

      // trigger refresh of UI
      this.ref.tick(); 
    });

    _webConfig.getConfig().subscribe(result => {
      this.currentTheme = this._webConfig.getConfigItem(result, 'theme', 'standard').toLowerCase() + '-theme';
      this.currentDarkTheme = this._webConfig.getConfigItem(result, 'theme', 'standard').toLowerCase() + '-dark-theme';      
      this.toggleDarkMode();
    });
  }

  toggleDarkMode(status = null) {
    if (status != null) {      
      this.currentMode = status;
    }
    this.theme.next(this.currentMode ? this.currentDarkTheme : this.currentTheme);
    $('body').removeClass();
    if (this.currentMode) {
      $('body').addClass(this.currentDarkTheme);
    } else {
      $('body').addClass(this.currentTheme);
    }
  }
}