import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
import { firstValueFrom, ReplaySubject } from "rxjs";
import { ConfigModule } from "../../config";
import { NgxMatTimepickerLocaleService } from "ngx-mat-timepicker";
import {
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  DateAdapter,
} from "@angular/material/core";
import { CustomDateAdapterService } from "../../../util/customDateAdapter";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-create-time-tracking-dialog",
  templateUrl: "./create-time-tracking-dialog.component.html",
  styleUrls: ["./create-time-tracking-dialog.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapterService,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: { display: { dateInput: "dateInput" } },
    },
  ],
})
export class CreateTimeTrackingDialogComponent implements OnInit {
  constructor(
    private _http: HttpClient,
    private _ref: MatDialogRef<CreateTimeTrackingDialogComponent>,
    private _config: ConfigModule,
    private _time: NgxMatTimepickerLocaleService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if (data.admin) {
      this.adminEdit = true;
    }
  }

  ngOnInit(): void {
    this.loadCategories();
    this.group.get("date").setValue(this.data.date);
    this._time.updateLocale("de");

    if (this.data?.item) {
      this.timeId = this.data.item.id;
      this.group.patchValue({
        date: this.data.item.date,
        category: this.data.item.categoryID,
        in: dayjs(this.data.item.startTime, "HH:mm:ss").format("HH:mm"),
        out: dayjs(this.data.item.endTime, "HH:mm:ss").format("HH:mm"),
        comment: this.data.item.comment,
      });
    }

    this.group.get("in")!.valueChanges.subscribe((value) => {
      if ((value ?? "").length == 4) {
        this.outField.nativeElement.focus();
      }
    });

    this.group.get("out")!.valueChanges.subscribe((value) => {
      if ((value ?? "").length == 0) {
        this.inField.nativeElement.focus();
      }
    });

    this.categoryFilterCtrl.valueChanges.subscribe(() => {
      this.filterCategories();
    });

    this.additionalUsersFilterCtrl.valueChanges.subscribe(() => {
      this.filterAdditionalUsers();
    });
  }

  max = new Date();
  isLoading = false;
  dayjs = dayjs;
  timeId: number;
  adminEdit = false;

  @ViewChild("outField")
  outField: ElementRef;

  @ViewChild("inField")
  inField: ElementRef;

  categoryList = [];
  public categoryFilterCtrl: FormControl = new FormControl();
  public categoryFilter: ReplaySubject<any> = new ReplaySubject<any>(1);
  users = [];
  public additionalUsersFilterCtrl: FormControl = new FormControl();
  public additionalUsersFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  group = new FormGroup(
    {
      date: new FormControl(new Date(), Validators.required),
      category: new FormControl("", Validators.required),
      additionalUsers: new FormControl([]),
      in: new FormControl(null, Validators.required),
      out: new FormControl(null, Validators.required),
      comment: new FormControl("", Validators.maxLength(100)),
    },
    this.validateTimeField
  );

  async loadCategories() {
    try {
      this.categoryList = await firstValueFrom(
        this._http.get<any>(`api/time/category${this.data.item ? '?userId=' + this.data.item.userID : ''}`)
      );
      this.users = await firstValueFrom(
        this._http.get<any>("api/user/active")
      );
      this.users = this.users.filter((user) => user.id !== environment.userInfo.id);
      this.categoryFilter.next(this.sortGroups(this.categoryList.slice()));
      this.additionalUsersFilter.next(this.users.slice());
    } catch (error) {
      console.error(error);
    }
  }

  sortGroups(input: any[]) {
    const sorted = [];
    input.forEach((category) => {
      const index = sorted.findIndex((g) => g.name === category.group);
      if (index === -1) {
        sorted.push({ name: category.group, data: [category] });
      } else {
        sorted[index].data.push(category);
      }
    });
    return sorted;
  }

  validateTimeField(c: AbstractControl): ValidationErrors {
    const inField = c.get("in"),
      outField = c.get("out");

    if (!inField.value || !outField.value) {
      return null;
    }
    const inTime = dayjs(inField.value, "HH:mm"),
      outTime = dayjs(outField.value, "HH:mm");

    if (inTime.isAfter(outTime, "m")) {
      inField.setErrors({ smallerTime: true });
      outField.setErrors({ smallerTime: true });
      return {
        smallerTime: true,
      };
    }
    if (inTime.isSame(outTime, "m")) {
      inField.setErrors({ sameTime: true });
      outField.setErrors({ sameTime: true });
      return {
        sameTime: true,
      };
    }
    return null;
  }

  async save() {
    if (this.group.invalid) return;

    const payload: any = this.group.value;
    
    if (this.timeId) {
      payload.id = this.timeId;
    }

    payload.in = dayjs(payload.in.replace(':', ''), 'HHmm').format('HH:mm:ss') as any;
    payload.out = dayjs(payload.out.replace(':', ''), 'HHmm').format('HH:mm:ss') as any;
    payload.date = dayjs(payload.date).format('YYYY-MM-DD') as any;
    this.isLoading = true;
    this.group.disable();

    try {
      if (this.adminEdit) {
        await firstValueFrom(
          this._http.post(`api/admin/time/${this.timeId}`, payload)
        );
      } else {
        await firstValueFrom(this._http.post(`api/time`, payload));
      }
      this._ref.close(true);
      if (this.timeId) {
        this._config.showSnackbar(
          "Buchung erfolgreich aktualisiert!",
          3000,
          "success"
        );
      } else
        this._config.showSnackbar(
          "Buchung erfolgreich hinzugefügt!",
          3000,
          "success"
        );
    } catch (e) {
      this.isLoading = false;
      this.group.enable();

      if (e.error.error) {
        this._config.showSnackbar(e.error.error, 3000, "error");
        return;
      }
      this._config.showSnackbar(
        "Buchung konnte nicht hinzugefügt werden!",
        3000,
        "error"
      );
    }
  }

  protected filterCategories() {
    if (!this.categoryList) {
      return;
    }
    let search = this.categoryFilterCtrl.value?.toLowerCase();
    if (!search) {
      this.categoryFilter.next(this.sortGroups(this.categoryList.slice()));
      return;
    } else {
      search = search.toLowerCase();
    }
    this.categoryFilter.next(
      this.sortGroups(
        this.categoryList.filter(
          (category) => category.name.toLowerCase().indexOf(search) !== -1
        )
      )
    );
  }

  protected filterAdditionalUsers() {
    if (!this.users) {
      return;
    }
    let search = this.additionalUsersFilterCtrl.value?.toLowerCase();
    if (!search) {
      this.additionalUsersFilter.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.additionalUsersFilter.next(
        this.users.filter(
          (user) => user.firstname.toLowerCase().indexOf(search) !== -1 || user.lastname.toLowerCase().indexOf(search) !== -1 || user.id.toString().indexOf(search) !== -1
        )
    );
  }

  clearUserSearch() {
    this.additionalUsersFilterCtrl.setValue('');
  }
}
