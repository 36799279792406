<div class="mt-4 row">
  <mat-form-field class="input-full-width col-md-8 col-12" *ngIf="!this._host._data.disabledElements">
    <mat-label>Suche:</mat-label>
    <mat-select (selectionChange)="selectItem($event)" [formControl]="materialSelectCtrl"
      [disabled]="this._host._data.disabledElements">
      <mat-option>
        <ngx-mat-select-search [formControl]="materialFilterCtrl"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let material of materialFilter | async" [value]="material">
        {{ material.name }}
      </mat-option>
      <mat-option *ngIf="materials.length === 0">
        Kein Material vorhanden
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="col-md-4 col-12 slideToggleMaterial" *ngIf="!this._host._data.disabledElements">
    <mat-slide-toggle [formControl]="materialSourceCtrl"> fahrzeugbezogenes Material</mat-slide-toggle>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortDirection="asc"
      class="input-full-width">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-right: 15px">
          Beschreibung
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.name }}
        </td>
      </ng-container>

      <!-- Count Column -->
      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 140px; vertical-align:middle">
          Menge
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field class="mt-1 noPaddingBottom" style="max-width: 140px" appearance="outline">
            <input matInput type="number" class="text-right" [(ngModel)]="element.qty" min="1" [max]="maxMaterialCount(element.id)" inputmode="numeric"
              [disabled]="this._host._data.disabledElements" pattern="[0-9]*" />
            <span matSuffix class="ms-2" style="top: -4px !important; position: inherit !important">{{ element.unit
              }}</span>
            <mat-hint class="mat-error" *ngIf="element.qty <= 0">Menge muss Größer als "0" sein!</mat-hint>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 150px; vertical-align:middle"> </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="deleteItem(element)" [disabled]="this._host._data.disabledElements">
            <mat-icon>clear</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pt-1"></tr>
    </table>
  </div>
</div>