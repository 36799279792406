<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">Logs</h3>
        <mat-card>
            <mat-card-content>
                <div class="mt-4 row" *ngIf="showLoader">
                    <div class="col-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                              height: '60px'
                              }"></ngx-skeleton-loader>
                    </div>
                    <div class="col-12">
                        <ngx-skeleton-loader count="8"></ngx-skeleton-loader>
                    </div>
                </div>
                <div [hidden]="showLoader">
                    <div class="row">
                        <div class="col-lg-12 col-12">
                            <mat-form-field class="input-full-width" appearance="outline">
                                <mat-chip-grid #searchList aria-label="Suchtags">
                                    <mat-chip-row *ngFor="let entry of search" [selectable]="selectable"
                                        [removable]="removable" (removed)="remove(entry)">
                                        {{entry}}
                                        <button matChipRemove *ngIf="removable" color="warn">
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip-row>
                                </mat-chip-grid>
                                <input placeholder="Suche" [matChipInputFor]="searchList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                                <mat-icon matSuffix (click)="clear();" *ngIf="search.length!==0" class="pointer">close
                                </mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="time"
                            matSortDirection="desc" matSortDisableClear style="width: 100%;">
                            <!-- ID Column -->
                            <ng-container matColumnDef="user">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="width: auto; padding-right: 15px">
                                    User
                                </th>
                                <td mat-cell *matCellDef="let element"
                                    routerLink="/admin/user/edit/{{element.userID}}/masterdata" class="pointer">
                                    {{element.userID}}</td>
                            </ng-container>

                            <ng-container matColumnDef="target">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="width: auto; padding-right: 15px">
                                    Target
                                </th>
                                <td mat-cell *matCellDef="let element"
                                    routerLink="/admin/user/edit/{{element.targetID}}/masterdata" class="pointer">
                                    {{element.targetID}}</td>
                            </ng-container>

                            <!-- Time Column -->
                            <ng-container matColumnDef="time">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="min-width: 170px; vertical-align: middle;">
                                    Time
                                </th>
                                <td mat-cell *matCellDef="let element" (click)="openLogEntry(element)">
                                    {{getFormattedDate(element.time)}}
                                </td>
                            </ng-container>

                            <!-- Severity Column -->
                            <ng-container matColumnDef="severity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="max-width: 100px; vertical-align: middle;">
                                    Severity</th>
                                <td mat-cell *matCellDef="let element" (click)="openLogEntry(element)">
                                    {{element.severity}}</td>
                            </ng-container>

                            <!-- action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="width: auto; vertical-align: middle;">
                                    Action</th>
                                <td mat-cell *matCellDef="let element" (click)="openLogEntry(element)">
                                    {{element.action}}</td>
                            </ng-container>

                            <!-- Branch Column -->
                            <ng-container matColumnDef="message">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="min-width: 200px; vertical-align: middle;">
                                    Message</th>
                                <td mat-cell *matCellDef="let element" (click)="openLogEntry(element)">
                                    {{element.message}}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>