<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">Mein Profil</h3>
        <mat-card>
            <mat-card-content>
                <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                    <mat-icon>keyboard_backspace</mat-icon>
                </a>
                <a routerLink="/settings" mat-mini-fab color="primary" class="pageNext">
                    <mat-icon>settings</mat-icon>
                </a>

                <mat-accordion class="expansion-header">
                    <mat-expansion-panel #stammdaten hideToggle [disabled]="!userInfo.masterdata">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h6>Stammdaten</h6>
                            </mat-panel-title>
                            <mat-panel-description>
                                <mat-icon>account_circle</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <app-profile-userdata [parent]="this"></app-profile-userdata>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel #firedata hideToggle [disabled]="!userInfo.firedata">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h6>Feuerwehr Daten</h6>
                            </mat-panel-title>
                            <mat-panel-description>
                                <mat-icon>fireplace</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <app-profile-firedata [parent]="this"></app-profile-firedata>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel #material hideToggle [disabled]="!userInfo.material">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h6>Material</h6>
                            </mat-panel-title>
                            <mat-panel-description>
                                <mat-icon>construction</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <app-profile-material [parent]="this"></app-profile-material>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel #atemschutz hideToggle [disabled]="!userInfo.atemschutz" *ngIf="age18">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h6>Atemschutz</h6>
                            </mat-panel-title>
                            <mat-panel-description>
                                <mat-icon>fire_extinguisher</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <app-profile-atemschutz [parent]="this"></app-profile-atemschutz>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel #maschinisten hideToggle [disabled]="!userInfo.driving" *ngIf="age18">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h6>Maschinisten</h6>
                            </mat-panel-title>
                            <mat-panel-description>
                                <mat-icon>drive_eta</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <app-profile-driving [parent]="this"></app-profile-driving>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel #emergencycontacts hideToggle [disabled]="!userInfo.emergencyContacts">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h6>Notfallkontakte</h6>
                            </mat-panel-title>
                            <mat-panel-description>
                                <mat-icon>contact_emergency</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <app-profile-emergency-contacts [parent]="this"></app-profile-emergency-contacts>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel hideToggle [disabled]="!userInfo.masterdata">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h6>Kalender</h6>
                            </mat-panel-title>
                            <mat-panel-description>
                                <mat-icon>today</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <app-profile-calendar [parent]="this"></app-profile-calendar>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card-content>
        </mat-card>
    </div>
</div>