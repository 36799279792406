import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { DateTime } from 'luxon';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';

@Component({
  selector: 'app-table-statistic',
  templateUrl: './table-statistic.component.html',
  styleUrls: ['./table-statistic.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
  ],
})
export class TableStatisticComponent {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() displayedColumns: string[] = [];
  @Input() data: any = [];
  @Output() filteredData: EventEmitter<any[]> = new EventEmitter();

  dataSource = new MatTableDataSource();
  timeRegex = /\d{2,}:[012345]\d/gm;

  constructor() { }

  ngAfterViewInit() {
    this.paginator.pageSize = 10;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.data;
    this.dataSource.sortingDataAccessor = (item: any, property: string) => {
      try {
        let res;
        if (DateTime.fromFormat(item[property], 'dd.MM.yyyy').isValid)
          res = DateTime.fromFormat(item[property], 'dd.MM.yyyy').setZone('Europe/Berlin').setLocale('de').toJSDate();

        if (this.timeRegex.test(item[property]) === true) {
          const val = item[property].split(':').map((n) => parseInt(n));
          res = (val[0] * 60 + val[1]);
        }
        if (this.timeRegex.test(item[property]) === true) {
          //Keine Ahnung warum, aber ohne des geht es nicht - wird aber auch nichts auf die Konsole geschrieben
          console.log(res);
        }
        return res || item[property];
      } catch (e) {
        console.error(e);
        return item[property];
      }
    };
    this.sort.sortChange.subscribe(() => {
      this.printData();
    });
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
    this.printData();
  }

  printData() {
    this.filteredData.emit(this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort));
  }
}
