<div class="container">
  <div class="col-lg-10 col-12 offset-lg-1">
    <h3 class="section-header">Materialverwaltung Dashboard</h3>

    <div class="row">
      <div class="col-lg-4 col-md-4 mb-4">
        <mat-card class="h-100" routerLink="equipment">
          <mat-card-header>
            Materialien
          </mat-card-header>
          <mat-card-content>
            <div class="clearfix">
              <a routerLink="equipment">
                <h3 [countUp]="data.materialCount" class="float-left">0</h3>
              </a>
              <mat-icon class="float-right">category</mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-lg-4 col-md-4 mb-4">
        <mat-card class="h-100" *ngIf="_login.hasPermission('material-vehicle-manage')" routerLink="vehicles">
          <mat-card-header>
            Fahrzeuge
          </mat-card-header>
          <mat-card-content>
            <div class="clearfix">
              <a routerLink="vehicles">
                <h3 [countUp]="data.vehicleCount" class="float-left">0</h3>
              </a>
              <mat-icon class="float-right">fire_truck</mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-lg-4 col-md-4 mb-4">
        <mat-card class="h-100" routerLink="checks/cycles">
          <mat-card-header>
            Prüfungen diesen Monat
          </mat-card-header>
          <mat-card-content>
            <div class="clearfix">
              <a routerLink="checks/cycles">
                <h3 [countUp]="data.checkCount" class="float-left">0</h3>
              </a>
              <mat-icon class="float-right">fact_check</mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="equipment">
              <mat-icon class="float-left me-3">construction</mat-icon>
              Materialverwaltung<br />
              <small>Materialien verwalten</small>
            </button>
          </div>
          <div class="col-xl-4 col-lg-4 col-12 mb-3" *ngIf="_login.hasPermission('material-vehicle-manage')">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="vehicles">
              <mat-icon class="float-left me-3">fire_truck</mat-icon>
              Fahrzeuge<br />
              <small>Fuhrpark verwalten</small>
            </button>
          </div>
          <div class="col-xl-4 col-lg-4 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="checks">
              <mat-icon class="float-left me-3">verified</mat-icon>
              Prüfzyklen<br />
              <small>Prüfzyklen verwalten</small>
            </button>
          </div>
          <hr />
          <div class="col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="checks/cycles">
              <mat-icon class="float-left me-3">fact_check</mat-icon>
              Anstehende Prüfungen<br />
              <small>Alle anstehenden Prüfungen verwalten</small>
            </button>
          </div>
          <hr />
          <div class="col-xl-4 col-lg-4 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="categories">
              <mat-icon class="float-left me-3">receipt</mat-icon>
              Kategorieverwaltung<br />
              <small>Material Kategorien verwalten</small>
            </button>
          </div>
          <div class="col-xl-4 col-lg-4 col-12 mb-3" *ngIf="_login.hasPermission('material-storage-manage')">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="storage">
              <mat-icon class="float-left me-3">pallet</mat-icon>
              Lagerplätze<br />
              <small>Lagerplätze verwalten</small>
            </button>
          </div>
          <div class="col-xl-4 col-lg-4 col-12 mb-3" *ngIf="_login.hasPermission('material-storage-manage')">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="locations">
              <mat-icon class="float-left me-3">warehouse</mat-icon>
              Lagerorte<br />
              <small>Standorte verwalten</small>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>