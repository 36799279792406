<mat-dialog-header>
  <div class="clearfix">
    <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
      <mat-icon>close</mat-icon>
    </a>
    <h2 mat-dialog-title class="float-left">{{name}}</h2>
  </div>
</mat-dialog-header>
<mat-dialog-content>
  <form [formGroup]="group">
    <mat-form-field *ngFor="let setting of jobTemplate" class="input-full-width">
      <mat-label>{{ setting.fancy_name }}</mat-label>
      <input matInput *ngIf="setting.type === 'text'" [(ngModel)]="setting.value"
        [ngModelOptions]="{standalone: true}" />
      <input matInput *ngIf="setting.type === 'number'" type="number" [(ngModel)]="setting.value"
        [ngModelOptions]="{standalone: true}" [min]="setting.min ?? null" [max]="setting.max ?? null" />
      <mat-select [(ngModel)]="setting.value" multiple *ngIf="setting.type === 'groups'"
        [ngModelOptions]="{standalone: true}">
        <mat-option [value]="group.id" *ngFor="let group of groupList">{{ group.name }}</mat-option>
      </mat-select>
      <mat-select [(ngModel)]="setting.value" *ngIf="setting.type === 'dropdown'" [ngModelOptions]="{standalone: true}">
        <mat-option [value]="option.id" *ngFor="let option of setting.options">{{ option.value }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="float-right">
      <button mat-raised-button color="primary" (click)="runJob()">Job ausführen</button>
    </div>
  </form>
</mat-dialog-content>