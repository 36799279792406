<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">{{user.firstname || ""}} {{user.lastname || ""}}
        </h3>
        <mat-card>
            <mat-card-content>
                <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                    <mat-icon>keyboard_backspace</mat-icon>
                </a>
                <a (click)="openUserPicker()" mat-mini-fab color="primary" class="pageNext">
                    <mat-icon>person</mat-icon>
                </a>
                <div class="row">
                    <div class="col-12" *ngIf="env.mobile">
                        <button mat-raised-button color="warn" style="width: 100%; height: 50px; margin-bottom: 10px;"
                            (click)="openInvalidDialog()"><mat-icon>close</mat-icon></button>
                    </div>
                    <div class="col-2" *ngIf="!env.mobile">
                        <button mat-raised-button color="warn" (click)="openInvalidDialog()"
                            style="width: 100%; height: 100%;"><mat-icon>close</mat-icon></button>
                    </div>
                    <div class="col-lg-8 col-12">
                        <div id="pdf">
                        </div>
                    </div>
                    <div class="col-2" *ngIf="!env.mobile">
                        <button mat-raised-button color="success" (click)="valid()"
                            style="width: 100%; height: 100%;"><mat-icon>check_circle</mat-icon></button>
                    </div>
                    <div class="col-12" *ngIf="env.mobile">
                        <button mat-raised-button color="success" style="width: 100%; height: 50px;"
                            (click)="valid()"><mat-icon>check_circle</mat-icon></button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #licenceDate>
    <h2 mat-dialog-title>Benutzer Suche</h2>
    <mat-card-content>
        <mat-form-field class="input-full-width" appearance="outline">
            <input placeholder="Suche" matInput [formControl]="searchControl" (keyup)="applyFilter($event.target.value)"
                [matAutocomplete]="users">
            <mat-icon matSuffix class="pointer" (click)="clearInput()" *ngIf="searchControl.value !== ''">
                close</mat-icon>
            <mat-autocomplete #users="matAutocomplete" (optionSelected)="setUser($event.option.value)"
                [displayWith]="displayFn">
                <mat-option *ngFor="let user of usersAutoComplete" [value]="user">
                    {{user.userID}} {{user.firstname}} {{user.lastname}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <a mat-icon-button mat-dialog-close class="float-right" style="top: -140px; right: -10px;">
            <mat-icon>close</mat-icon>
        </a>
    </mat-card-content>
    <mat-card-actions style="display:flex;justify-content:center;align-items:center;">
        <div class="clearfix">
            <div layout="column" layout-align="space-between center">
                <div><button mat-raised-button mat-dialog-close (click)="applyUser()" color="primary"
                        [disabled]="searchControl.value === ''">Dienstfahrerlaubnis überprüfen</button></div>
            </div>
        </div>
    </mat-card-actions>
</ng-template>

<ng-template #invalidDialog>
    <h3 style="color: #b71c1c">Achtung</h3>
    <p>Soll die Dienstfahrerlaubnis von {{user.firstname}} {{user.lastname}} wirklich für ungültig erklärt werden?
        <br />Hiermit werden {{user.firstname}} {{user.lastname}} alle Fahrberechtigungen entzogen und die <br />
        Maschinistenausbilder, Kommandanten
        und Gruppenfrüherer benachrichtigt.
    </p>
    <a mat-dialog-close mat-mini-fab class="float-left" color="warn">
        <mat-icon>close</mat-icon>
    </a>
    <button mat-raised-button mat-dialog-close (click)="invalid()" color="primary"
        class="float-right">Dienstfahrerlaubnis
        ablehnen</button>
</ng-template>