import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter } from 'angular-calendar';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';

@Component({
  selector: 'app-checks-cycle-date-change',
  templateUrl: './checks-cycle-date-change.component.html',
  styleUrls: ['./checks-cycle-date-change.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class ChecksCycleDateChangeComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
  private _http: HttpClient,
  private _config: ConfigModule,
  private _ref: MatDialogRef<ChecksCycleDateChangeComponent>) {
    this.cycle = dialogData.cycle;    
    const parsedDate = dayjs(dialogData.cycle.date).toDate();
    this.date.patchValue(parsedDate);
    this.max = parsedDate;
  }

  cycle: any;
  min = new Date();
  max = new Date();
  date = new FormControl(new Date());

  async save() {
    if (this.date.invalid) return;

    try {
      await firstValueFrom(this._http.post(`${getHostUrl()}admin/material/checks/cycles/${this.cycle.id}/date`, {  
        date: dayjs(this.date.value).format('YYYY-MM-DD')
      }));
      this._ref.close(true);
      this._config.showSnackbar('Prüfzyklusdatum geändert', 3000, 'success');
    } catch (error) {
      this._config.showSnackbar('Fehler beim Ändern des Prüfzyklusdatums', 3000, 'error');
    }
  }
}
