<mat-spinner *ngIf="isLoading" color="accent" class="loading-spinner-center" [diameter]="200"></mat-spinner>

<div class="container">
  <div class="col-lg-8 col-12 offset-lg-2" *ngIf="!isLoading">
    <h3 class="section-header">Lagerplatz
      <span *ngIf="!storageId" class="ms-2">erstellen</span>
      <span *ngIf="storageId" class="ms-2">bearbeiten</span>
    </h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="../../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        
        <form [formGroup]="group">
          <mat-form-field appearance="outline" class="input-full-width">
            <mat-label>Name</mat-label>
            <input matInput type="text" formControlName="name" required="true" />
            <mat-error *ngIf="group.get('name').invalid">Lagerplatz Name ist ein Pflichtfeld!</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="input-full-width mt-2" [hidden]="group.get('locationId').value != -1">
            <mat-label>Fahrzeug verknüpfen</mat-label>
            <mat-select formControlName="vehicleId">
              <mat-option [value]="-1">Keinem Fahrzeug zugeordnet</mat-option>
              <mat-option *ngFor="let vehicle of vehicleList" [value]="vehicle.id">{{ vehicle.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="input-full-width mt-2" [hidden]="group.get('vehicleId').value != -1">
            <mat-label>Standort verknüpfen</mat-label>
            <mat-select formControlName="locationId">
              <mat-option [value]="-1">Keinem Standort zugeordnet</mat-option>
              <mat-option *ngFor="let location of locationList" [value]="location.id">{{ location.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <div class="clearfix">
          <button mat-raised-button class="float-right" color="accent" [disabled]="group.invalid" (click)="saveData()">
            <div class="clearfix submit-button">
              <mat-icon class="me-3 mt-1 float-left" [hidden]="!isSaving">
                <mat-spinner diameter="20" class="light"></mat-spinner>
              </mat-icon>
              <span class="me-1 float-left">Speichern</span>
              <mat-icon class="float-right">save</mat-icon>
            </div>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
