import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RouteGuard } from '../../../util/guards/route.guard';
import { ConfigModule, getHostUrl } from '../../../util/config';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatPaginatorIntlCro } from '../../../util/matPaginatorIntlCroClass';
import { Login } from '../../../util/login';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

import * as $ from 'jquery';
import { MatPaginatorIntl as MatPaginatorIntl, MatPaginator as MatPaginator, PageEvent as PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';

import { EventOverview, Group } from '@ffo/mitgliederbereich-types';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-admin-event',
  templateUrl: './admin-event.component.html',
  styleUrls: ['./admin-event.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    RouteGuard],
})
export class AdminEventComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('menu', { static: false }) menuSheet: TemplateRef<any>;

  displayedColumns: string[] = ['title', 'date', 'type', 'visibility'];

  dataSource = new MatTableDataSource<EventOverview>();

  eventList: EventOverview[] = [];

  groupList: Group[] = [];

  groupsFilter: any[] = [];
  searchFilter: string = '';
  pageIndex: number = 0;
  pageSize: number = 20;

  removeEventId: number = null;

  env = environment;
  constructor(
    private _config: ConfigModule,
    private _http: HttpClient,
    public _login: Login,
    private _dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private _spinner: NgxSpinnerService,
    private _ref: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this._config.setTitle('Termine');
    this.loadEvents();
    this.fetchDataInfo();

    if ($(window).width() < 600) {
      this.displayedColumns = ['title', 'date', 'type'];
    }
  }

  applyHashLocation() {
    const hashes = window.location.hash.split('#');
    if (hashes.length === 5) {
      this.groupsFilter = hashes[1] !== '' ? hashes[1].split(';').map(g => parseInt(g)) : [];
      this.searchFilter = hashes[2] || '';
      this.pageIndex = parseInt(hashes[3]);
      this.pageSize = parseInt(hashes[4]);
      setTimeout(() => {
        this.applyFilter(decodeURI(this.searchFilter));
      }, 500);
      return;
    } else {
      this.applyFilter("");
    }
  }

  setHashLocation() {
    if (this.groupsFilter.length === 0 && !this.searchFilter.trim() && this.pageIndex === 0 && this.pageSize === 20) {
      window.location.hash = '';
      return;
    }
    window.location.hash = `#${this.groupsFilter.join(';')}#${this.searchFilter}#${this.pageIndex}#${this.pageSize}`;
  }

  loadEvents() {
    this.dataSource = null;
    this._http
      .get<EventOverview[]>(`${getHostUrl()}admin/events/all`)
      .subscribe(data => {
        this.eventList = data;
        this.dataSource = new MatTableDataSource<EventOverview>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item: EventOverview, property) => {
          switch (property) {
            case 'date':
              const date = DateTime.fromFormat(item.startDate, 'dd.MM.yyyy').setZone('Europe/Berlin');
              if (item.allDay) {
                return date.setLocale('de').toJSDate();
              }
              const time = DateTime.fromFormat(item.startTime, 'HH:mm').setZone('Europe/Berlin');
              return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toJSDate();
            default: return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.filter();
        this.applyHashLocation();
        this._spinner.hide();
      });
  }

  async fetchDataInfo() {
    this.groupList = await this._http.get<Group[]>(`${getHostUrl()}datainfo/groups?type=admin`).toPromise();
    this.groupList.push({ id: -1, name: 'Vergangene' });
    this.groupList.push({ id: -2, name: 'Abgesagt' });
    this._ref.detectChanges();
    if (this.dataSource && this.dataSource.data.length !== 0)
      this.applyHashLocation();
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter || '', groups: this.groupsFilter || [] });
    this.setHashLocation();
  }

  applyGroupFilter($event: MatSelectChange) {
    this.groupsFilter = $event.value;
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter || '', groups: this.groupsFilter || [] });
    this.setHashLocation();
  }

  applyPagination($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.setHashLocation();
  }

  filter(): (data: EventOverview, filter: string) => boolean {
    let filterFunction = function (data: EventOverview, filter: string): boolean {
      let searchTerms: { search: string; groups: number[] } = JSON.parse(filter);
      if (searchTerms.groups.length !== 0) {
        var result = true;
        searchTerms.groups.forEach(group => {
          if (!data.groups.includes(group) || data.groups.includes(-1) && !searchTerms.groups.includes(-1) || data.groups.includes(-2) && !searchTerms.groups.includes(-2))
            result = false;
          if (data.title.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1 && data.startDate.indexOf(searchTerms.search) === -1)
            result = false;
        });
        return result;
      } else {
        if (data.groups.includes(-1) || data.groups.includes(-2))
          return false;
        return (data.title.toLowerCase().indexOf(searchTerms.search.toLowerCase()) !== -1 || data.startDate.indexOf(searchTerms.search) !== -1);
      }
    };
    return filterFunction;
  }

  openChangeStatus(): void {
    /*const dialog = this._dialog.open(EventChangeStatusComponent, {});
    dialog.afterClosed().subscribe(() => {
      this.loadEvents();
    })*/
  }

  openMenu() {
    this._bottomSheet.open(this.menuSheet);
  }
}
/*
@Component({
  selector: 'app-admin-event-changeStatus',
  templateUrl: './changeStatus.html',
  styleUrls: ['./admin-event.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class EventChangeStatusComponent implements OnInit {

  constructor(private _http: HttpClient, private _config: ConfigModule) { }

  group: UntypedFormGroup;

  ngOnInit(): void {
    this.group = new UntypedFormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    });
  }

  open(datepicker: MatDatepicker<Date>) { datepicker.open() }

  save(): void {
    this._http.post(`${getHostUrl()}admin/events/changeStatus`, { data: this.group.getRawValue() }).subscribe({
      next: () => {
        this._config.showSnackbar('Events veröffentlicht', 2500, 'success');
      },
      error: () => {
        this._config.showSnackbar('Fehler beim Speichern', 2500, 'error');
      }
    });
  }
}*/