import { Injectable, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ConfigModule, getHostUrl } from './config';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { SHA512 } from 'crypto-js';
import { Router } from '@angular/router';

@NgModule()
@Injectable()
export class Login {

  userData = new BehaviorSubject<any>({});

  constructor(private _cookieService: CookieService, private _http: HttpClient, private _config: ConfigModule,
    private _router: Router) {

  }

  public login(username: string, password: string): Observable<boolean> {
    return this._http.post<any>(getHostUrl() + 'login?isLogin=true', { username: username, password: password })
      .pipe(map(user => {
        if (user && user.sessionToken) {
          this.setSessionObject(user);
          return true;
        }
        return false;
      }, error => {
        this._config.showSnackbar('Ein Fehler ist beim Login aufgetreten, bitte versuche es erneut!', 3000, 'error');
      }));
  }

  public hasPermission(name: string): boolean {
    if (!environment.userInfo)
      return false;

    const permissionList = environment.userInfo.permList;

    for (let i = 0; i < permissionList.length; i++) {
      if (permissionList[i] === name)
        return true;
    }
    return false;
  }

  public hasPermissionProm(name: string): Promise<boolean> {
    return new Promise((resolve, rejcet) => {
      if (environment.userInfo) {
        const permissionList = environment.userInfo.permList;
        for (let i = 0; i < permissionList.length; i++) {
          if (permissionList[i] === name)
            resolve(true);
        }
        resolve(false);
      } else {
        this._http.get<any[]>(`${getHostUrl()}permissions`).subscribe((permissionList) => {
          if (permissionList) {
            environment.userInfo.permList = permissionList;
            for (let i = 0; i < permissionList.length; i++) {
              if (permissionList[i] === name)
                resolve(true);
            }
            resolve(false);
          } else {
            resolve(false);
          }
        }, error => {
          console.log(error);
          resolve(false);
        });
      }
    });
  }

  public hasGroup(group: number): boolean {
    if (!environment.userInfo)
      return false;

    const groupList = environment.userInfo.groupList;

    for (let i = 0; i < groupList.length; i++) {
      if (groupList[i].id === group)
        return true;
    }
    return false;
  }

  public checkSessionToken(): any {
    return this._http.post<any>(getHostUrl() + 'login', {});
  }

  public isLoggedIn(): Observable<boolean> {
    if (environment.userLoggedIn) {
      return of(true);
    }
    return this.checkSessionToken().pipe(map((user: any) => {
        if (user && user.sessionToken) {
          this.setSessionObject(user);
          return true;
        }
        return false;
      }));
  }

  /**
   * Retrieves the userID and returns it
   */
  getUserID(): number {
    return environment.userID;
  }

  /**
   * Sets the session id as a cookie
   * @param sessionToken user login session id
   */
  setSessionObject(sessionObj) {
    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 1);
    environment.userLoggedIn = true;
    environment.userInfo = sessionObj.userInfo;
    environment.userInfo.avatar = sessionObj.userInfo.avatar;
    environment.userID = sessionObj.userID;
    this.userData.next(environment.userInfo);
    this._cookieService.set('userSession', sessionObj.sessionToken, expiredDate, '/', null, false, 'Lax');
  }

  logout() {
    environment.userLoggedIn = false;
    environment.userInfo = null;
    environment.userID = null;
    environment.sessionKey = '';
    this._cookieService.delete('userSession');
    this._http.get<any>(getHostUrl() + 'logout')
      .subscribe(user => {
        this._router.navigate(['/login']);
      }, error => {
        this._config.showSnackbar('Ein Fehler ist beim Logout aufgetreten, bitte versuche es erneut!', 3000, 'error');
        document.location.reload();
      });
  }

  /**
  * Retrieves the session id from cookie
  */
  getSessionToken(): string {
    if (this._cookieService.check('userSession')) {
      return this._cookieService.get('userSession');
    }
    return '';
  }
}
