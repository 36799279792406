import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getHostUrl, ConfigModule } from 'src/app/util/config';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Login } from 'src/app/util/login';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdminUserMenusheetComponent } from '../admin-user-menusheet/admin-user-menusheet.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AddressDialogComponent } from '../../../../../util/dialogs/address-dialog/address-dialog.component';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { firstValueFrom } from 'rxjs';
import dayjs from 'dayjs';
import { UserLeaveOrganizationComponent } from 'src/app/util/dialogs/user-leave-organization/user-leave-organization.component';

@Component({
  selector: 'app-admin-user-masterdata',
  templateUrl: './admin-user-masterdata.component.html',
  styleUrls: ['./admin-user-masterdata.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ],
})
export class AdminUserMasterdataComponent implements OnInit, OnDestroy {

  cityList: any;

  streetList: any;

  countryList: any;

  filteredCityList: any = null;

  filteredStreetList: any = null;

  today: Date = new Date();

  userInfo: any = {};

  userID: number;

  mailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  ffoMailFormControl = new UntypedFormControl('', [
    Validators.email,
  ]);

  phoneFormControl = new UntypedFormControl('', [
    Validators.pattern(/^([+]\d{2})\d{8,12}$/),
    //Validators.pattern(/^(^$)|(([+]\d{2})\d{8,12})$/)
  ]);

  homephoneFormControl = new UntypedFormControl('', [
    Validators.pattern(/^([+]\d{2})(\d{4,20})$/),
  ]);

  streetFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);

  jobplaceFormControl = new UntypedFormControl('', []);

  birthplaceFormControl = new UntypedFormControl('', []);
  birthdateFormControl = new UntypedFormControl('', []);

  dataFormGroup: UntypedFormGroup = new UntypedFormGroup({
    mail: this.mailFormControl,
    ffoMail: this.ffoMailFormControl,
    phone: this.phoneFormControl,
    home: this.homephoneFormControl,
    street: this.streetFormControl,
  });

  env = environment;

  constructor(private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    public _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet,
    private _dialog: MatDialog) { }

  ngOnInit() {
    this.userID = this._route.snapshot.params.userId;
    if (this.userID) {
      this.fetchMasterData(this.userID);
      this._config.setTitle('Verwaltung | Lädt...');
      this.fetchDataInfo();
    }
  }

  fetchMasterData(userID: number) {

    this._http.get<any>(getHostUrl() + 'admin/user?userID=' + userID).subscribe(data => {
      this._config.setTitle(`${data.firstname + ' ' + data.lastname}`);
      this.userInfo = data;

      this.mailFormControl.setValue(this.userInfo.email);
      this.ffoMailFormControl.setValue(this.userInfo.ffoMail);
      this.phoneFormControl.setValue(this.userInfo.mobile);
      this.homephoneFormControl.setValue(this.userInfo.homephone);
      this.streetFormControl.setValue(this.userInfo.street);
      if (this.userInfo.jobplace.id !== 9999)
        this.jobplaceFormControl.setValue(this.userInfo.jobplace);
      if (this.userInfo.birthplace.id !== 9999)
        this.birthplaceFormControl.setValue(this.userInfo.birthplace);
      this.birthdateFormControl.setValue(this.userInfo.birthdate);
      if (!this._login.hasPermission('admin-user-edit')) {
        this.mailFormControl.disable();
        this.ffoMailFormControl.disable();
        this.phoneFormControl.disable();
        this.homephoneFormControl.disable();
        this.streetFormControl.disable();
        this.jobplaceFormControl.disable();
        this.birthplaceFormControl.disable();
        this.birthdateFormControl.disable();
        this.userInfo.dsgvo.disable();
        this.userInfo.personalPhotos.disable();
      }
      this._spinner.hide();
    });
  }

  async fetchDataInfo() {
    this.streetList = await this._http.get(`${getHostUrl()}datainfo/streets`).toPromise();
    this.cityList = await this._http.get(`${getHostUrl()}datainfo/cities`).toPromise();
    this.countryList = await this._http.get(`${getHostUrl()}datainfo/countries`).toPromise();
  }

  showName(value): string | undefined {
    return value ? value.name : undefined;
  }

  clearBirthPlace() {
    this.birthplaceFormControl.setValue('');
  }

  onDatepickerChange($event: any) {
    this.userInfo.birthdate = $event.value;
  }

  onDateChange($event: any) {
    var dateArray = $event.target.value.split('.');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    this.userInfo.birthdate = new Date(dateString);
  }

  saveUserData() {
    this.userInfo.homephone = this.homephoneFormControl.value;
    this.userInfo.mobile = this.phoneFormControl.value;
    this.userInfo.email = this.mailFormControl.value;
    this.userInfo.ffoMail = this.ffoMailFormControl.value;
    this.userInfo.street = this.streetFormControl.value;
    this.userInfo.birthplace = this.birthplaceFormControl.value;
    this.userInfo.birthdate = dayjs(this.birthdateFormControl.value).format('YYYY-MM-DD');
    if (this.userID) {
      this._http.post(getHostUrl() + 'admin/user/masterdata', { userID: this.userID, userObj: this.userInfo }).subscribe(resp => {
        this._spinner.hide();
        this._config.showSnackbar('Benutzerdaten gespeichert!', 2000, 'success');
        this.fetchMasterData(this.userID);
      }, error => {
        this._spinner.hide();
        if (error.error === 5001)
          this._config.showSnackbar('User nicht vorhanden!', 2000, 'error');
        else
          this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
        this.fetchMasterData(this.userID);
      });
    }
  }

  async disableUser() {
    const dialog = this._dialog.open(UserLeaveOrganizationComponent, {
      width: '500px',
      data: {
        userID: this.userID
      }
    });
    const res = await firstValueFrom(dialog.afterClosed());
    if (res) {
      this.userInfo.isDisabled = true;
    }
  }

  findAddress() {
    const dialogRef = this._dialog.open(AddressDialogComponent, {
      width: '500px',
      data: {
        cities: false,
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === null) {
        return;
      }
      this.streetFormControl.setValue(data.street);
      this.userInfo.city = data.city;
      this.userInfo.zipcode = data.zipcode;
      this.userInfo.country = data.country;
    });
  }

  findBirthPlace() {
    const dialogRef = this._dialog.open(AddressDialogComponent, {
      width: '500px',
      data: {
        cities: true,
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === null) {
        return;
      }
      this.birthplaceFormControl.setValue(data.city);
      this.userInfo.birthplaceZipcode = data.zipcode;
      this.userInfo.birthplaceCountry = data.country;
    });
  }


  /**
 * Menu Bottom Sheet
 */
  openMenu() {
    const bottomSheetRef = this._bottomSheet.open(AdminUserMenusheetComponent, { data: { userID: this.userID, name: this.userInfo.firstname+ ' ' + this.userInfo.lastname } });
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }
}
