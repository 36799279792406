<div class="row">
  <div class="col-lg-2 col-6">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Anrede</mat-label>
      <mat-select [(ngModel)]="parent.userInfo.masterdata.sex" required disabled>
        <mat-option value="w">Frau</mat-option>
        <mat-option value="m">Herr</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-lg-2 col-6">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Titel</mat-label>
      <mat-select [(ngModel)]="parent.userInfo.masterdata.title" required disabled>
        <mat-option value="0">--</mat-option>
        <mat-option value="1">Dr.</mat-option>
        <mat-option value="2">Prof. Dr.</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-6">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Vorname</mat-label>
      <input [(ngModel)]="parent.userInfo.masterdata.firstname" matInput required disabled>
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-6">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Nachname</mat-label>
      <input [(ngModel)]="parent.userInfo.masterdata.lastname" matInput required disabled>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-md-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>E-Mail</mat-label>
      <input matInput required [formControl]="mailFormControl">
      <mat-error *ngIf="mailFormControl.hasError('required')">
        E-Mailadresse erforderlich
      </mat-error>
      <mat-error *ngIf="mailFormControl.hasError('email')">
        keine gültige E-Mailadresse
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-md-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Feuerwehr E-Mail</mat-label>
      <input matInput disabled [value]="parent.userInfo.masterdata.ffoMail">
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-4 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Telefon (Mobil)</mat-label>
      <input type="tel" matInput placeholder="+491712345678" required [formControl]="phoneFormControl">
      <mat-error *ngIf="phoneFormControl.hasError('required')">
        Mobiltelefonnummer erforderlich
      </mat-error>
      <mat-error *ngIf="phoneFormControl.hasError('pattern')">
        benötigtes Format: +491712345678
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-4 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Telefon (Privat)</mat-label>
      <input type="tel" matInput placeholder="+4989512563" [formControl]="homephoneFormControl">
      <mat-error *ngIf="homephoneFormControl.hasError('pattern')">
        benötigtes Format: +4989512563
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-4 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Telefon (Geschäftlich)</mat-label>
      <input type="tel" matInput placeholder="+4989512563" [formControl]="officephoneFormControl">
      <mat-error *ngIf="officephoneFormControl.hasError('pattern')">
        benötigtes Format: +4989512563
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-10 col-md-10 col-12">
    <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
      <mat-label>Straße</mat-label>
      <input matInput required readonly [formControl]="streetFormControl">
      <mat-error *ngIf="streetFormControl.hasError('required')">
        Adresse erforderlich
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-2 col-md-2 col-6">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Hausnummer</mat-label>
      <input [(ngModel)]="parent.userInfo.masterdata.appartment" matInput required>
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-6 col-6">
    <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
      <mat-label>Postleitzahl</mat-label>
      <input type="number" [(ngModel)]="parent.userInfo.masterdata.zipcode" readonly matInput required>
    </mat-form-field>
  </div>
  <div class="col-lg-8 col-md-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline" (click)="findAddress()">
      <mat-label>Ort</mat-label>
      <input [(ngModel)]="parent.userInfo.masterdata.city" matInput readonly required>
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-4 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Geburtsdatum</mat-label>
      <input matInput [(ngModel)]="parent.userInfo.masterdata.birthdate" required disabled>
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-4 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Geburtsort</mat-label>
      <input matInput required disabled [(ngModel)]="parent.userInfo.masterdata.birthplace">
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-4 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Staatsangehörigkeit</mat-label>
      <mat-select multiple required [(ngModel)]="parent.userInfo.masterdata.nationality" disabled>
        <mat-option *ngFor="let country of parent.countryList" [value]="country.id.toString()">
          {{ country.nationality }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="clearfix">
  <button mat-raised-button color="success" class="float-right" (click)="saveUserData()"
    [disabled]="dataFormGroup.invalid || parent.userInfo.masterdata.city === '' || parent.userInfo.masterdata.appartment === ''">Speichern
    <mat-icon>save</mat-icon>
  </button>
  <mat-error
    *ngIf="dataFormGroup.invalid || parent.userInfo.masterdata.city === '' || parent.userInfo.masterdata.appartment === ''"
    style="padding-left:10px;">Es müssen alle Felder korrekt ausgefüllt sein.</mat-error>
</div>