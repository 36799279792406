import { Component, Input } from '@angular/core';
import { HelpComponent } from '../help.component';

@Component({
  selector: 'app-help-evd',
  templateUrl: './help-evd.component.html',
  styleUrls: ['./help-evd.component.css'],
})
export class HelpEvdComponent {

  @Input() parent: HelpComponent;

  constructor() { console.log('Test');}
}
