<h6>Hinweise zum Erstellen des Einsatzberichts</h6>
<p>Allgemein gilt, wenn Felder gar nicht oder inkorrekt ausgefüllt worden sind, wird bei der jeweiligen Kategorie oben
    "Daten fehlerhaft" angezeigt, sobald man diesen Schritt verlässt.
</p>

<h6>Einsatzdaten</h6>
<p>Einige Felder können bereits automatisch vorausgefüllt sein. Sobald man mit dem Mauszeiger über eine
    Alarmierungsmöglichkeit
    „streicht" wird der Zeitpunkt der letzten Alarmierung angezeigt.
</p>
<h6>Fahrzeuge</h6>
<p>Jedes Fahrzeug kann nur einmalig hinzugefügt werden. Bei jedem Fahrzeug muss ein Maschinist sowie Fahrzeugführer
    (kann
    auch selbe Person sein) ausgewählt werden. Hinter jedem Fahrzeug wird der aktuelle Status angezeigt. Sobald Material
    von
    dem Fahrzeug ausgewählt wird, gilt das Fahrzeug als eingesetzt. Es wird automatisch die Fahrstrecke für Hin- und
    Rückweg
    errechnet. Diese kann bei Bedarf angepasst werden.
</p>
<h6>Details</h6>
<p>
    Bei First Responder Einsätzen sind medizinische Daten verpflichtend auszufüllen, bei allen andern Einsätzen
    optional.
    Sobald ein Einsatz als kostenpflichtig eingestuft wird, müssen Rechnungsdaten ausgefüllt werden.
</p>
<h6>Bericht</h6>
<p>
    Als Hilfestellung für das Verfassen des Einsatzberichts sind Vorlagen angefertigt worden. Diese können ausgewählt
    und
    entsprechend angepasst werden.
</p>
<h6>Abschluss</h6>
<p>
    Sobald alle Daten vollständig ausgefüllt wurden, kann der Einsatzbericht abgeschlossen werden.
    Optional können anschließend Bestätigungen ausgedruckt werden, jedes Mitglied erhält diese aber auch in seinem
    persönlichen
    Bereich.
</p>