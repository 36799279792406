import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../../util/login';
import { getHostUrl, ConfigModule } from '../../../util/config';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { MatPaginatorIntlCro } from '../../../util/matPaginatorIntlCroClass';
import { RouteGuard } from '../../../util/guards/route.guard';

export interface Group {
  id: number;
  name: string;
  membercount: number;
}

@Component({
  selector: 'app-admin-groups',
  templateUrl: './admin-groups.component.html',
  styleUrls: ['./admin-groups.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }, RouteGuard],
})
export class AdminGroupsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['name', 'membercount'];

  dataSource = new MatTableDataSource();
  isLoading = true;

  constructor(private _http: HttpClient, public _login: Login, private _config: ConfigModule, private _spinner: NgxSpinnerService) { }

  ngOnInit() {
    this._config.setTitle('Gruppen');
  }

  ngAfterViewInit() {
    this.refreshData();
  }

  refreshData() {
    const context = this;
    
    this._http.get<Group[]>(getHostUrl() + 'admin/groups/all')
      .subscribe(data => {
        context.dataSource = new MatTableDataSource<Group>(data);
        context.dataSource.paginator = context.paginator;
        context.dataSource.sort = context.sort;
        this.isLoading = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }
}
