import { Component, Input } from '@angular/core';
import { HelpComponent } from '../help.component';

@Component({
  selector: 'app-help-home',
  templateUrl: './help-home.component.html',
  styleUrls: ['./help-home.component.css'],
})
export class HelpHomeComponent {

  @Input() parent: HelpComponent;

  constructor() { }
}
