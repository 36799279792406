import { Component, Input } from '@angular/core';
import { HelpComponent } from '../help.component';

@Component({
  selector: 'app-help-settings',
  templateUrl: './help-settings.component.html',
  styleUrls: ['./help-settings.component.css'],
})
export class HelpSettingsComponent {

  @Input() parent: HelpComponent;

  constructor() { }
}
