import { Component, Input } from '@angular/core';
import { HelpComponent } from '../help.component';

@Component({
  selector: 'app-help-alarms',
  templateUrl: './help-alarms.component.html',
  styleUrls: ['./help-alarms.component.css'],
})
export class HelpAlarmsComponent {

  @Input() parent: HelpComponent;

  constructor() { console.log('Test');}
}
