<form class="row">
  <div class="col-lg-12 col-md-12 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Neues Fahrzeug</mat-label>
      <mat-select (selectionChange)="newVehicle($event)" [formControl]="vehicleCtrl">
        <mat-option>
          <ngx-mat-select-search [formControl]="vehicleFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let vehicle of vehicleFilter | async" [value]="vehicle">{{vehicle.name}}
          <span *ngIf="vehicle.type !== 20">
            (Status: {{ getIconByNumber(vehicle.status) }})
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <hr />

    <div class="alert alert-warning" *ngIf="vehicleDataList.length===0">
      <mat-icon>warning</mat-icon> <span class="aligned-with-icon">Es wurden noch keine Fahrzeuge hinzugefügt!</span>
    </div>

    <table mat-table [dataSource]="vehicleData" *ngIf="vehicleDataList.length > 0" matSort matSortActive="vehicle"
      matSortDirection="asc" matSortDisableClear>

      <!-- Vehicle Column -->
      <ng-container matColumnDef="vehicle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fahrzeug</th>
        <td mat-cell *matCellDef="let vehicle" (click)="newVehicle(null, vehicle)"> 
          {{vehicle.vehicle.name}}
          <span *ngIf="vehicle.vehicle.type !== 20">
            (Status: {{ getIconByNumber(vehicle.vehicle.status) }})
          </span>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let vehicle"><button mat-icon-button matTooltip="Fahrzeug entfernen"
            (click)="removeVehicle(vehicle)" class="float-right" *ngIf="!disabledElements">
            <mat-icon>clear</mat-icon>
          </button></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</form>