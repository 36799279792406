import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { StatisticData } from '@ffo/mitgliederbereich-types';
import { ECharts, EChartsOption, env } from 'echarts';
import { alarmTypes, getColorForSeriesName } from 'src/app/util/config';
import { ThemingService } from 'src/app/util/services/theming.service';

@Component({
  selector: 'app-pie-chart-statistic',
  templateUrl: './pie-chart-statistic.component.html',
  styleUrls: ['./pie-chart-statistic.component.css'],
})
export class PieChartStatisticComponent implements OnChanges {

  @Output() chart = new EventEmitter<ECharts>();
  @Input() data: StatisticData[] = [];
  @Input() title: string = '';
  echartsInstance: ECharts;

  theme: string = 'shine';
  chartOption: EChartsOption = {};

  constructor(private _theme: ThemingService) {
    this._theme.theme.subscribe(theme => {
      if (theme === 'rot-dark-theme' || theme === 'standard-dark-theme') {
        this.theme = 'chalk';
      } else {
        this.theme = 'shine';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartOption = {
      legend: {
        left: 'center',
        top: 'bottom',
      },
      tooltip: {
        trigger: 'item',
      },
      title: {
        text: this.title,
        left: 'center',
      },
      series: [
        {
          name: 'Anzahl',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            formatter: (val) => {
              return `${val.value}`;
            },
          },
          labelLine: {
            show: true,
          },
          data: this.data.map(d => ({
            name: d.key,
            value: d.value as number,
          })),
          itemStyle: this.data.filter(s => alarmTypes.includes(s.key)).length === 0 ? null : {
            color: (params) => getColorForSeriesName(params.name)
          },
        },
      ],
    };
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
    this.chart.emit(this.echartsInstance);
  }

}