<div *ngIf="ready">
    <div class="row" *ngFor="let contact of parent.userInfo.emergencyContacts; let i = index">
        <div class="col-lg-6 col-7">
            <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Vorname Nachname</mat-label>
                <input matInput required (change)="onNameChanged(i)" [formControl]="emergenyNameFormControl[i]" />
                <mat-error *ngIf="emergenyNameFormControl[i].hasError('required')">
                    Vorname & Nachnamen erforderlich
                </mat-error>
                <mat-error *ngIf="emergenyNameFormControl[i].hasError('pattern')">
                    Format: Vorname Nachname
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-5">
            <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Beziehung</mat-label>
                <mat-select [value]="contact.relationship" required
                    (selectionChange)="onRelationshipChanged(i, $event)">
                    <mat-option *ngFor="let rel of relationshipList" [value]="rel">
                        {{rel}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Telefonnummer</mat-label>
                <input type="tel" matInput required (change)="onPhoneChanged(i)"
                    [formControl]="emergenyPhoneFormControl[i]" />
                <mat-error *ngIf="emergenyPhoneFormControl[i].hasError('required')">
                    Telefonnummer erforderlich
                </mat-error>
                <mat-error *ngIf="emergenyPhoneFormControl[i].hasError('pattern')">
                    benötigtes Format: +491712345678
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>E-Mail</mat-label>
                <input type="email" matInput (change)="onEmailChanged(i)" [formControl]="emergenyEmailFormControl[i]" />
                <mat-error *ngIf="emergenyEmailFormControl[i].hasError('email')">
                    keine gültige E-Mailadresse
                </mat-error>
                <mat-icon matSuffix style="cursor: pointer;" (click)="removeContactField(i)">remove_circle</mat-icon>
            </mat-form-field>
        </div>
        <div class="col-12" *ngIf="i+1 < parent.userInfo.emergencyContacts.length">
            <hr />
        </div>
    </div>
</div>
<div class="clearfix">
    <button mat-raised-button color="accent" class="float-left" (click)="addContactField()">hinzufügen
        <mat-icon>library_add</mat-icon></button>
    <button mat-raised-button color="success" class="float-right" (click)="saveContactsData()"
        [disabled]="!contactsvalid"
        *ngIf="parent.userInfo.emergencyContacts.length !== 0 || removedContactList.length !== 0">Speichern
        <mat-icon>save</mat-icon>
    </button>
</div>
<mat-error *ngIf="!contactsvalid" class="float-right" style="padding-top:10px;">Es müssen alle Felder korrekt ausgefüllt
    sein.</mat-error>