<div class="row">
  <div class="col-lg-8 col-md-12 col-12 offset-lg-2">
    <h3 class="section-header">Massen Export für Einsatzbestätigungen</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>

        <form [formGroup]="group">
          <div class="row">
            <div class="mt-3 col-12 col-md-6 col-lg-6">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Datum (von)</mat-label>
                <input matInput formControlName="dateFrom" [matDatepicker]="picker" [max]="toDay" readonly="true"
                  required (focus)="picker.open()" style="cursor: pointer">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="!group.get('dateFrom').valid">
                  Start Datum muss angegeben werden
                </mat-error>
              </mat-form-field>
            </div>
            <div class="mt-3 col-12 col-md-6 col-lg-6">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Datum (bis)</mat-label>
                <input matInput formControlName="dateTo" [matDatepicker]="pickerEnd" [max]="toDay"
                  [min]="group.get('dateFrom').value" readonly="true" required [errorStateMatcher]="matcher"
                  (focus)="pickerEnd.open()" style="cursor: pointer">
                <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
                <mat-error *ngIf="!group.get('dateTo').valid">
                  End Datum muss angegeben werden
                </mat-error>
                <mat-error *ngIf="group.hasError('smallerThan')">
                  End Datum muss nach Startdatum sein
                </mat-error>
              </mat-form-field>
            </div>
            <div class="mt-3 col-12 col-md-6 col-lg-6">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Uhrzeit (von)</mat-label>
                <input matInput formControlName="timeFrom"
                  (keyup)="parseTimeField($event, 'timeFrom', 'timeTo')" />
              </mat-form-field>
            </div>
            <div class="mt-3 col-12 col-md-6 col-lg-6">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Uhrzeit (bis)</mat-label>
                <input matInput formControlName="timeTo"
                  (keyup)="parseTimeField($event, 'timeTo')" />
              </mat-form-field>
            </div>
            <div class="col-12 mt-3">
              <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Wochentage</mat-label>
                <mat-select multiple formControlName="weekdays" required>
                  <mat-option value="0">Montag</mat-option>
                  <mat-option value="1">Dienstag</mat-option>
                  <mat-option value="2">Mittwoch</mat-option>
                  <mat-option value="3">Donnerstag</mat-option>
                  <mat-option value="4">Freitag</mat-option>
                  <mat-option value="5">Samstag</mat-option>
                  <mat-option value="6">Sonntag</mat-option>
                </mat-select>
                <mat-error *ngIf="!group.get('weekdays').valid">
                  Wochentage müssen ausgewählt werden
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 clearfix">
              <button mat-raised-button (click)="startExport()" [disabled]="group.invalid" class="float-right"
                color="primary" [disabled]="showLoader">
                Export starten
                <mat-icon class="ms-2" *ngIf="!showLoader">send</mat-icon>
                <mat-spinner *ngIf="showLoader" class="float-right ms-3" diameter="30"></mat-spinner>
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>