import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { getHostUrl, ConfigModule } from '../../../../util/config';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatPaginatorIntlCro } from '../../../../util/matPaginatorIntlCroClass';
import { UntypedFormControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { UserName } from '@ffo/mitgliederbereich-types';

export interface Permission {
  id: number;
  name: string;
  description: string;
  assigned: number;
}
@Component({
  selector: 'app-admin-groups-detail',
  templateUrl: './admin-groups-detail.component.html',
  styleUrls: ['./admin-groups-detail.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class AdminGroupsDetailComponent implements OnInit {

  constructor(private _route: ActivatedRoute,
    private _http: HttpClient,
    private _spinner: NgxSpinnerService,
    private _config: ConfigModule,
    private _dialog: MatDialog,
    private _ref: ChangeDetectorRef) { }

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('addUserDialog') addUserDialog: TemplateRef<any>;

  displayedColumns: string[] = ['name', 'description', 'assign'];
  dataSource = new MatTableDataSource();
  groupInfo: any = {};
  groupID: number;
  addGroup: boolean = false;
  userlist: UserName[] = [];
  usersListToAdd: UserName[] = [];

  groupList = [];

  protected _onDestroy = new Subject<void>();

  filterdUsers: Observable<any[]>;

  public groupFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public groupFilter: ReplaySubject<any> = new ReplaySubject<any>(1);
  addPersonControl: UntypedFormControl = new UntypedFormControl('');

  ngOnInit() {
    this.groupID = this._route.snapshot.params.groupId;

    this._config.setTitle('Lädt...');

    this.fetchGroups();

    this.filterdUsers = this.addPersonControl.valueChanges.pipe(startWith(''),
      map((value: string) => this._filterUsers(value)),
    );

    this.groupFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterGroups();
      });

    if (this.groupID)
      this.refreshData();
    else {
      this._config.setTitle('Neue Gruppe');
      this.addGroup = true;
    }

  }


  refreshData() {
    const context = this;

    this._http.get<any>(`${getHostUrl()}admin/groups?groupID=${this.groupID}`)
      .subscribe(data => {
        context._spinner.hide();
        this.groupInfo = data;
        this._config.setTitle(`${data.name}`);
        context.dataSource = new MatTableDataSource<Permission>(data.perms);
        context.dataSource.paginator = context.paginator;
        context.dataSource.sort = context.sort;
        this.getUserList();
      });
  }

  toggleAllSelection(event) {
    if (event) {
      this.groupInfo.maintainGroups = this.groupList.map(g => g.id);
    } else {
      this.groupInfo.maintainGroups = [];
    }
    this._ref.detectChanges();
  }

  getUserList() {
    this._http.get<{users: UserName[], toAdd: UserName[]}>(`${getHostUrl()}admin/groups/userlist?groupID=${this.groupID}`).subscribe(
      data => {
        this.userlist = data.users;
        this.usersListToAdd = data.toAdd;
        this.addPersonControl.setValue('');
      },
    );
  }

  fetchGroups() {
    this._http.get<any>(getHostUrl() + 'datainfo/groups?type=admin').subscribe(data => {
      this.groupList = data;
      this.groupFilter.next(this.groupList.slice());
    });
  }

  setValue(permID, event) {
    for (let i = 0; i < this.groupInfo.perms.length; i++) {
      if (this.groupInfo.perms[i].id === permID) {
        let value = 0;
        if (event.checked === true)
          value = 1;
        this.groupInfo.perms[i].assigned = value;
        break;
      }
    }
  }

  saveData() {
    if (!this.groupInfo.name) {
      this._config.showSnackbar('Bitte alle Felder ausfüllen!', 2000, 'warning');
      return;
    }
    const context = this;

    this._http.post(`${getHostUrl()}admin/groups`, {
      groupID: this.groupID,
      groupinfo: this.groupInfo,
    }).subscribe(data => {
      this._config.showSnackbar('Änderungen gespeichert', 2000, 'success');
      context._spinner.hide();
    }, error => {
      this._config.showSnackbar(error.error.text, 2000, 'error');
      context._spinner.hide();
    });
  }

  removeUser(userID: number): void {
    this._http.delete(`${getHostUrl()}admin/groups/users`, { body: { groupID: this.groupID, userID } }).subscribe({
      next: () => {
        this.getUserList();
        this._config.showSnackbar('Gruppe aktualisiert ', 2500, 'success');
      },
      error: () => {
        this._config.showSnackbar('Fehler beim Löschen', 2500, 'error');
      }
    });
  }

  savePerson(userID: number): void {
    this._http.post(`${getHostUrl()}admin/groups/users`, { groupID: this.groupID, userID }).subscribe({
      next: () => {
        this._config.showSnackbar('Gruppe aktualisiert', 2500, 'success');
        this.getUserList();
      },
      error: () => {
        this._config.showSnackbar('Fehler beim Speichern', 2500, 'error');
      }
    });
  }

  private _filterUsers(value: string): UserName[] {
    if (typeof(value) === 'string') {
      const filterValue = value.toLowerCase();
      return this.usersListToAdd.filter((user: UserName) => { return user.userID.toString().startsWith(filterValue) || user.firstname.toLowerCase().startsWith(filterValue) || user.lastname.toLowerCase().startsWith(filterValue); });
    }
    return [];
  }

  displayFn(user: UserName): string {
    return user && user.firstname && user.lastname && user.userID ? (`${user.userID} - ${user.firstname} ${user.lastname}`) : '';
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  protected filterGroups() {
    if (!this.groupList) {
      return;
    }
    let search = this.groupFilterCtrl.value;

    if (!search) {
      this.groupFilter.next(this.groupList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.groupFilter.next(
      this.groupList.filter(group => group.name.toLowerCase().indexOf(search) > -1),
    );
  }

  openAddUser(): void {
    const dialog = this._dialog.open(this.addUserDialog);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
