<div class="row mt-4">
  <div class="col-xl-4 col-12 offset-xl-4 col-lg-6 offset-lg-3">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Bitte einloggen!</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="group" name="loginForm" class="mt-3">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Stammnummer</mat-label>
            <input matInput required autocomplete="username" formControlName="username" type="number" name="username"
              *ngIf="env.mobile" />
            <input matInput required autocomplete="username" formControlName="username" name="username"
              *ngIf="!env.mobile" />
            <mat-error *ngIf="group.get('username').invalid" color="warn">Bitte gib eine gültige Stammnummer ein!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Passwort</mat-label>
            <input matInput (keydown)="onKeydown($event)" required formControlName="password" name="password"
              type="password" autocomplete="current-password" />
            <mat-error *ngIf="group.get('password').invalid">Bitte gib ein gültiges Passwort ein!
            </mat-error>
          </mat-form-field>
          <mat-checkbox color="primary" formControlName="remember" [hidden]="!_com.isRunningInApp()">Eingeloggt
            bleiben.</mat-checkbox>
        </form>
      </mat-card-content>
      <mat-card-actions style="display: flex; justify-content: center; align-items: center" class="mt-3">
        <div class="clearfix">
          <div layout="column" layout-align="space-between center">
            <div>
              <button mat-raised-button (click)="sendAutofillRequest()" *ngIf="autoFillAvailable" class="me-3"
                color="primary">
                <mat-icon class="me-0">fingerprint</mat-icon>
              </button>
              <button mat-raised-button (click)="login()" [disabled]="group.invalid" class="submit-button"
                color="accent">
                <div class="clearfix">
                  <mat-icon class="me-3 mt-1 float-left" [hidden]="!showSpinner">
                    <mat-spinner diameter="20" class="light"></mat-spinner>
                  </mat-icon>
                  <span class="me-1 float-left">Einloggen</span>
                  <mat-icon class="float-right">play_arrow</mat-icon>
                </div>
              </button>
            </div>
            <div><button mat-button routerLink="/pwforgot"
                style="margin-bottom: 10px; margin-top: 15px; margin-left: -20px;" color="warn">Passwort
                vergessen?</button>
            </div>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #wrongCredentials>
  <h2 mat-dialog-title>Ungültige Zugangsdaten</h2>
  <mat-dialog-content>
    <p>Die angegebenen Zugangsdaten sind ungültig!</p>
  </mat-dialog-content>
  <mat-dialog-actions class="clearfix">
    <button mat-raised-button mat-dialog-close class="float-right" color="accent">
      Schließen
    </button>
  </mat-dialog-actions>
</ng-template>
<ng-template #errorUpcoming>
  <h2 mat-dialog-title>Serverfehler</h2>
  <mat-dialog-content>
    <p>Bei der Verbindung zum Server ist ein Fehler aufgetreten!</p>
  </mat-dialog-content>
  <mat-dialog-actions class="clearfix">
    <button mat-raised-button mat-dialog-close class="float-right" color="accent">
      Schließen
    </button>
  </mat-dialog-actions>
</ng-template>
<ng-template #noPermission>
  <h2 mat-dialog-title>Keine Rechte</h2>
  <mat-dialog-content>
    <p>
      Für diese Aktion hast du leider keine Rechte oder bist nicht
      freigeschalten.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions class="clearfix">
    <button mat-raised-button mat-dialog-close class="float-right" color="accent">
      Schließen
    </button>
  </mat-dialog-actions>
</ng-template>