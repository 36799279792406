import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { ThemingService } from 'src/app/util/services/theming.service';
import { Subscription } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { environment } from 'src/environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import dayjs, { Dayjs } from 'dayjs';
import { DateAdapter } from '@angular/material/core';

import 'dayjs/locale/de';

registerLocaleData(localeDe);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  message;

  @HostBinding('class') public cssClass: string;

  themingSubscription: Subscription;

  constructor(
    private themingService: ThemingService,
    private overlayContainer: OverlayContainer,
    private _platform: Platform,
    private _sanitizer: DomSanitizer,
    private _iconRegistry: MatIconRegistry,
    private _dateAdapter: DateAdapter<Dayjs>) {
    dayjs.locale('de');
    this._dateAdapter.setLocale('de');
  }

  ngOnInit() {
    this.themingSubscription = this.themingService.theme.subscribe((theme: string) => {
      this.cssClass = theme;
      this.applyThemeOnOverlays();
    });
    if (this._platform.ANDROID || this._platform.IOS || $(window).width() < 600) {
      environment.mobile = true;
    }
    if (this._platform.SAFARI) {
      environment.safari = true;
    }
    this.registerIcons([
      'loeschfahrzeug',
      'drehleiter',
      'wechsellader',
      'anhaenger',
      'gabelstapler',
      'abroller',
      'lkw'
    ]);
  }

  private registerIcons(icons = []) {
    console.log('Registering Icons ...');
    icons.forEach(elem => {
      this._iconRegistry.addSvgIcon(elem, this._sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/vehicles/${elem}.svg`));
    });
  }

  /**
   * Apply the current theme on components with overlay (e.g. Dropdowns, Dialogs)
   */
  private applyThemeOnOverlays() {
    // remove old theme class and add new theme class
    // we're removing any css class that contains '-theme' string but your theme classes can follow any pattern
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(this.themingService.themes);
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(this.cssClass);
  }

  ngOnDestroy() {
    this.themingSubscription.unsubscribe();
  }
}
