import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConfigModule, getHostUrl } from '../../util/config';
import { Login } from '../../util/login';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter } from 'angular-calendar';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  env = environment;
  constructor(private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet,
    public _login: Login,
    private _dialog: MatDialog,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router) { }

  @ViewChild('menu', { static: false }) menuSheet: TemplateRef<any>;

  today: Date = new Date();

  viewMode: 'list' | 'calendar' = 'list';
  menuMode: 'menu' | 'download' = 'menu';

  resourceLink: string;

  download = {
    year: `${this.today.getFullYear()}`,
    all: '1',
    status: 'sichtbar'
  }

  ngOnInit() {
    this._config.setTitle('Termine');
    this.viewMode = this._route.snapshot.params.view;
    this._http.get<string>(`${getHostUrl()}datainfo/resourceList`).subscribe(result => {
      this.resourceLink = result;
    });
  }

  switchView() {
    this.viewMode === 'list' ? this.viewMode = 'calendar' : this.viewMode = 'list';
    this._router.navigate([`/event/${this.viewMode}`]);
    this._bottomSheet.dismiss();
  }

  enableDownload() {
    this.menuMode === 'menu' ? this.menuMode = 'download' : this.menuMode = 'menu';
  }

  getHostUrl(): string {
    return getHostUrl();
  }

  openMenu() {
    this.menuMode = 'menu';
    this._bottomSheet.open(this.menuSheet);
    this.download = {
      year: `${this.today.getFullYear()}`,
      all: '1',
      status: 'sichtbar'
    }
  }

  openChangeStatus(): void {
    const dialog = this._dialog.open(EventChangeStatusComponent, {});
    dialog.afterClosed().subscribe(() => {
      this._router.navigate([`/event/${this.viewMode}`]);
    });
  }
}


@Component({
  selector: 'app-event-changeStatus',
  templateUrl: './changeStatus.html',
  styleUrls: ['./event.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class EventChangeStatusComponent implements OnInit {

  constructor(private _http: HttpClient, private _config: ConfigModule) { }

  group: UntypedFormGroup;

  ngOnInit(): void {
    this.group = new UntypedFormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    });
  }

  open(datepicker: MatDatepicker<Date>) { datepicker.open() }

  save(): void {
    this._http.post(`${getHostUrl()}admin/events/changeStatus`, { data: this.group.getRawValue() }).subscribe({
      next: () => {
        this._config.showSnackbar('Events veröffentlicht', 2500, 'success');
      },
      error: () => {
        this._config.showSnackbar('Fehler beim Speichern', 2500, 'error');
      }
    });
  }
}