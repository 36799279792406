<div class="container">
    <div class="row">
        <div class="col-lg-5 col-12 mt-lg-2"
            *ngIf="event && (event.attendees.admin || _login.hasPermission('event-response') || _login.hasPermission('event-attendance') || (this._login.hasPermission('event-complete') && event.status === 'sichtbar') || demoted)">
            <mat-card class="mt-lg-4">
                <mat-card-content>
                    <mat-tab-group mat-stretch-tabs [(selectedIndex)]="tabIndex"
                        *ngIf="event && (event.attendees.admin || _login.hasPermission('event-response') || _login.hasPermission('event-attendance'))">
                        <mat-tab label="Rückmeldungen"
                            *ngIf="(_login.hasPermission('event-response') || event.attendees.admin) && !demoted">
                            <div class="m-3">
                                <mat-expansion-panel class="mt-3">
                                    <mat-expansion-panel-header class="green">
                                        <mat-panel-title>
                                            <div class="clearfix" style="width: 100%;">
                                                <span class="float-left">Positive Rückmeldungen</span>
                                                <span class="float-right badge" style="font-size: 15px;">{{
                                                    event.attendees.accepted.length
                                                    }}</span>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <ul style="margin-top: 10px;">
                                        <li *ngFor="let response of event.attendees.accepted">
                                            <a [routerLink]="['/user', response.userID]">
                                                {{response.firstname}} {{response.lastname}}
                                            </a>
                                            <span style="margin-left: 10px;" *ngIf="response.comment">
                                                (Kommentar: {{ response.comment }})
                                            </span>
                                        </li>
                                    </ul>
                                    <button mat-flat-button color="primary" class="mt-2 input-full-width"
                                        (click)="addPerson()"
                                        [hidden]="event.status === 'angelegt' || event.status === 'abgeschlossen'"
                                        *ngIf="usersListResponse.length !== 0 && (_login.hasPermission('event-response') || event.attendees.admin) && (event.attendees.count > event.attendees.accepted.length || !event.attendees.count)">Person
                                        anmelden <mat-icon>person_add</mat-icon></button>
                                    <button mat-flat-button color="primary" class="mt-2 input-full-width"
                                        *ngIf="checkMeetingControls(4) && (_login.hasPermission('event-attendance') || event.attendees.admin)"
                                        [hidden]="event.status === 'angelegt' || event.status === 'abgeschlossen'"
                                        (click)="addResponseToAttendance()">als Anwesenheit übernehmen<mat-icon>verified
                                        </mat-icon></button>
                                </mat-expansion-panel>

                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="red">
                                        <mat-panel-title>
                                            <div class="clearfix" style="width: 100%;">
                                                <span class="float-left">Negative Rückmeldungen</span>
                                                <span class="float-right badge" style="font-size: 15px;">{{
                                                    event.attendees.rejected.length
                                                    }}</span>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div style="margin-top: 10px;">
                                        <ul>
                                            <li *ngFor="let response of event.attendees.rejected">
                                                <a [routerLink]="['/user', response.userID]">
                                                    {{response.firstname}} {{response.lastname}}
                                                </a>
                                                <span style="margin-left: 10px;" *ngIf="response.comment">
                                                    (Kommentar: {{ response.comment }})
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-expansion-panel>

                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <div class="clearfix" style="width: 100%;">
                                                <span class="float-left">Keine Rückmeldungen</span>
                                                <span class="float-right badge badge-light" style="font-size: 15px;">{{
                                                    event.attendees.noresponse.length
                                                    }}</span>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div style="margin-top: 10px;">
                                        <ul>
                                            <li *ngFor="let response of event.attendees.noresponse">
                                                <a [routerLink]="['/user', response.userID]">
                                                    {{response.firstname}} {{response.lastname}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </mat-tab>
                        <mat-tab
                            *ngIf="(_login.hasPermission('event-attendance') || event.attendees.admin) && checkMeetingControls(4)">
                            <ng-template mat-tab-label>
                                Anwesenheit
                                <span class="float-right badge badge-light"
                                    style="font-size: 15px;">{{attendance.length}}</span>
                            </ng-template>
                            <ul style="margin-top: 10px;" class="attendance">
                                <li *ngFor="let attendee of attendance">
                                    <a [routerLink]="['/user', attendee.userID]">
                                        {{attendee.firstname}} {{attendee.lastname}}
                                    </a>
                                    <mat-icon class="hoverVisible pointer" color="warn"
                                        (click)="removeAttendee(attendee.userID)" [class.alwaysVisible]="env.mobile"
                                        [hidden]="(event.status === 'abgeschlossen' && !demoted)">close</mat-icon>
                                </li>
                            </ul>
                            <button mat-flat-button color="primary" class="mt-2 input-full-width"
                                [hidden]="event.status === 'abgeschlossen' && !demoted"
                                (click)="openAttendance()">Anwesenheit
                                pflegen <mat-icon>person_add</mat-icon></button>

                        </mat-tab>
                    </mat-tab-group>
                    <div class="control"
                        *ngIf="(_login.hasPermission('event-complete') || event.attendees.admin) && checkMeetingControls(4) && attendance.length !== 0"
                        [hidden]="event.status === 'angelegt' || event.status === 'abgeschlossen'">
                        <button mat-flat-button color="primary" class="mt-2 input-full-width" (click)="openFinal()">
                            Termin abschließen <mat-icon>check_circle</mat-icon>
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-lg-7 col-12"
            [class.offset-lg-3]="event && !(event.attendees.admin || _login.hasPermission('event-response') || _login.hasPermission('event-attendance') || (this._login.hasPermission('event-complete') && event.status === 'sichtbar') || demoted)">
            <h3 class="section-header">{{event?.title || ''}}</h3>
            <mat-card id="eventBorder">
                <mat-card-content *ngIf="event">
                    <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                        <mat-icon>keyboard_backspace</mat-icon>
                    </a>
                    <a mat-mini-fab routerLink="/admin/event/edit/{{ eventId }}" color="primary" class="pageNext"
                        *ngIf="_login.hasPermission('admin-event-edit') && event.status !== 'abgeschlossen'">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <a mat-mini-fab color="primary" class="pageNext" matTooltip="Termin temporär zurückstufen"
                        matTooltipPosition="above"
                        *ngIf="_login.hasPermission('admin-event-demote') && event.status === 'abgeschlossen' && demoted === false"
                        (click)="demoted = true">
                        <mat-icon>manage_history</mat-icon>
                    </a>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="input-full-width" appearance="outline" *ngIf="event.description">
                                <mat-label>Beschreibung</mat-label>
                                <textarea matInput readonly="true" [value]="event.description"
                                    cdkTextareaAutosize></textarea>
                            </mat-form-field><br />
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="input-full-width" appearance="outline">
                                <mat-label>Beginn</mat-label>
                                <input matInput readonly="true" [value]="event.start" />
                            </mat-form-field><br />
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="input-full-width" appearance="outline">
                                <mat-label>Ende</mat-label>
                                <input matInput readonly="true" [value]="event.end" />
                            </mat-form-field><br />
                        </div>
                        <div class="col-12 mt-2">
                            <div class="row" style="margin-left: unset; margin-right: unset;">
                                <mat-button-toggle-group class="input-full-width" [class.p-0]="env.mobile">
                                    <mat-button-toggle class="col-md-6 col-12 orgaType" disabled
                                        *ngIf="event.orga==='technic'">
                                        Technik <mat-icon class="ms-2">local_fire_department</mat-icon>
                                    </mat-button-toggle>
                                    <mat-button-toggle class="col-md-6 col-12 orgaType" disabled
                                        *ngIf="event.orga==='foundation'">
                                        Verein <mat-icon class="ms-2">foundation</mat-icon>
                                    </mat-button-toggle>
                                    <mat-button-toggle class="col-md-6 col-12 orgaType" disabled
                                        *ngIf="event.type==='local'" [class.borderType]="env.mobile">
                                        Präsenz <mat-icon class="ms-2">business</mat-icon>
                                    </mat-button-toggle>
                                    <mat-button-toggle class="col-md-6 col-12 orgaType" disabled
                                        *ngIf="event.type==='hybrid'" [class.borderType]="env.mobile">
                                        Hybrid <mat-icon class="ms-2">maps_home_work</mat-icon>
                                    </mat-button-toggle>
                                    <mat-button-toggle class="col-md-6 col-12 orgaType" disabled
                                        *ngIf="event.type==='online'" [class.borderType]="env.mobile">
                                        Online <mat-icon class="ms-2">language</mat-icon>
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                        <div class="col-12 mt-4" *ngIf="event.dressCode">
                            <mat-form-field class="input-full-width mt-2" appearance="outline">
                                <mat-label>Kleiderordnung</mat-label>
                                <textarea matInput readonly="true" [value]="event.dressCode" cdkTextareaAutosize></textarea>
                                <mat-icon matPrefix>accessibility</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="event.flag" [class.mt-4]="!event.dressCode">
                            <a>
                                <mat-icon>flag</mat-icon> Bei dieser Veranstaltung wird die Fahnenabordnung
                                benötigt!
                            </a>
                        </div>
                        <div class="col-12" [class.mt-4]="!event.flag && !event.dressCode">
                            <div class="row" [hidden]="event.type === 'online'">
                                <div class="col-md-10 col-12">
                                    <mat-form-field class="input-full-width" appearance="outline">
                                        <mat-label>Straße</mat-label>
                                        <input matInput readonly="true" [value]="event.location.street" />
                                        <a matSuffix target="_blank" [href]="event.location.mapsUrl">
                                            <mat-icon>place</mat-icon>
                                        </a>
                                    </mat-form-field><br />
                                </div>
                                <div class="col-md-2 col-5">
                                    <mat-form-field class="input-full-width" appearance="outline">
                                        <mat-label>Hausnummer</mat-label>
                                        <input matInput readonly="true" [value]="event.location.appartment" />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 col-7">
                                    <mat-form-field class="input-full-width" appearance="outline">
                                        <mat-label>Postleitzahl</mat-label>
                                        <input matInput readonly="true" [value]="event.location.zipcode" />
                                    </mat-form-field><br />
                                </div>
                                <div class="col-12" [class.col-md-5]="event.location.country !== 'Deutschland'"
                                    [class.col-md-9]="event.location.country === 'Deutschland'">
                                    <mat-form-field class="input-full-width" appearance="outline">
                                        <mat-label>Ort</mat-label>
                                        <input matInput readonly="true" [value]="event.location.city" />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col-12" *ngIf="event.location.country !== 'Deutschland'">
                                    <mat-form-field class="input-full-width" appearance="outline">
                                        <mat-label>Land</mat-label>
                                        <input matInput readonly="true" [value]="event.location.country" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <a target="_blank" class="input-full-width" [href]="event.meetingUrl" mat-raised-button
                                color="primary" [hidden]="event.type === 'local' || event.meetingUrl === null">
                                Online Termin öffnen <mat-icon>open_in_browser</mat-icon>
                            </a>
                        </div>
                        <ng-container *ngIf="event && event.links.length !== 0 && event.attendees.participant">
                            <div class="col-12 mt-4">
                                <h5>Links</h5>
                            </div>
                            <div class="col-12 mt-2" *ngFor="let link of event.links">
                                <a target="_blank" class="input-full-width" [href]="link.url" mat-raised-button
                                    color="primary">
                                    {{link.name}}
                                </a>
                            </div>
                        </ng-container>
                        <div class="col-12 mt-4">
                            <h5>Rückmeldungen</h5>
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="input-full-width noTop" appearance="outline">
                                <span matPrefix class="ms-2">Zusagen: &nbsp;</span>
                                <input matInput readonly="true" [value]="getAttendees()" />
                                <span matSuffix *ngIf="event.attendees.count">/{{event.attendees.count}}
                                    &nbsp;</span>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="input-full-width noTop" appearance="outline">
                                <span matPrefix class="ms-2">Absagen: &nbsp;</span>
                                <input matInput readonly="true" [value]="getRejected()" />
                            </mat-form-field>
                        </div>
                        <div class="col-12" [hidden]="event.comment === ''">
                            <mat-form-field class="input-full-width" appearance="outline">
                                <mat-label>Kommentar</mat-label>
                                <textarea matInput readonly="true" [value]="event.comment"></textarea>
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="event.attendees.participant && event.status === 'sichtbar'">
                            <div class="col-12"
                                [hidden]="event.attendees.count !== null && event.attendees.count <= getAttendees() && event.response !== 2 || !checkResponseTime()">
                                <button class="input-full-width" mat-raised-button
                                    [color]="event.response === 0 ? 'primary' : event.response === 1 ? 'warn' : 'success'"
                                    (click)="openFeedback()">
                                    {{event.response === 0 ? 'Rückmeldung abgeben' : 'Rückmeldung ändern'}}
                                    <mat-icon>rule</mat-icon>
                                </button>
                            </div>
                            <div class="col-12 mt-4"
                                [hidden]="event.attendees.count > getAttendees() || !event.attendees.count">
                                <button class="input-full-width active" mat-raised-button readonly="true" color="warn">
                                    Veranstaltung bereits ausgebucht
                                    <mat-icon>no_accounts</mat-icon>
                                </button>
                            </div>
                            <div class="col-12 mt-4"
                                *ngIf="!checkResponseTime() && event.limitResponse.active && event.response !== 2"
                                [hidden]="event.attendees.count === getAttendees()">
                                <button class="input-full-width active" mat-raised-button readonly="true" color="warn">
                                    Im Moment ist keine Rückmeldung möglich
                                    <mat-icon>schedule</mat-icon>
                                </button>
                            </div>
                            <div class="col-12 mt-4"
                                *ngIf="!checkResponseTime() && event.limitResponse.active && event.response === 2">
                                <button class="input-full-width active" mat-raised-button color="warn"
                                    (click)="sendFeedback(1, '')">
                                    absagen
                                    <mat-icon>event_busy</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<ng-template #addPersonDialog>
    <h1 mat-dialog-title>Person anmelden</h1>
    <div mat-dialog-content>
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Teilnehmer</mat-label>
            <input type="text" matInput [formControl]="addUserControl" [matAutocomplete]="auto">
            <mat-icon matSuffix (click)="addUserControl.setValue('')" [class.inVisible]="addUserControl.value === ''">
                close</mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
                <mat-option *ngFor="let user of filterdUsersResponse | async" [value]="user">
                    {{user.userID}} - {{user.firstname}} {{user.lastname}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Kommentar</mat-label>
            <textarea matInput [(ngModel)]="addUserComment"></textarea>
        </mat-form-field>
    </div>
    <div mat-dialog-actions style="justify-content: space-between;">
        <button mat-raised-button color="accent" class="mx-2" mat-dialog-close>schließen<mat-icon>close</mat-icon>
        </button>
        <button mat-raised-button mat-dialog-close color="success" [disabled]="!addUserControl.value.userID"
            (click)="sendFeedback(2,addUserComment,addUserControl.value.userID)">anmelden</button>
    </div>
</ng-template>

<ng-template #addAttendeeDialog>
    <h1 mat-dialog-title>Person hinzufügen</h1>
    <div mat-dialog-content>
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Teilnehmer</mat-label>
            <input type="text" matInput [formControl]="addAttendenceControl" [matAutocomplete]="auto"
                (keyup.enter)="saveAttendee(addAttendenceControl.value.userID)">
            <mat-icon matSuffix (click)="addAttendenceControl.setValue('')"
                [class.inVisible]="addAttendenceControl.value === ''">
                close</mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
                <mat-option *ngFor="let user of filterdUsersAttendence | async" [value]="user">
                    {{user.userID}} - {{user.firstname}} {{user.lastname}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div mat-dialog-actions style="justify-content: space-between;">
        <button mat-raised-button color="accent" class="mx-2" mat-dialog-close>schließen<mat-icon>close</mat-icon>
        </button>
        <button mat-raised-button color="success" class="mx-2" [disabled]="!addAttendenceControl.value.userID"
            (click)="saveAttendee(addAttendenceControl.value.userID)">hinzufügen</button>
    </div>
</ng-template>