<h2 mat-dialog-title>Systemhilfe {{getSiteName(contentRoute)}}</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-lg-12 col-12" *ngIf="search===true">
            <mat-form-field class="input-full-width" appearance="outline">
                <mat-label>Hilfe durchsuchen</mat-label>
                <input matInput [matAutocomplete]="auto" [formControl]="searchControl">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)="setHelpEntry($event.option.value.path)">
                    <mat-option *ngFor="let option of filteredSites | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col-lg-12 col-12" [ngSwitch]="contentRoute">
            <app-help-home [parent]="this" *ngSwitchCase="'/home'"></app-help-home>
            <app-help-user [parent]="this" *ngSwitchCase="'/user'"></app-help-user>
            <app-help-responder [parent]="this" *ngSwitchCase="'/responder'"></app-help-responder>
            <app-help-evd [parent]="this" *ngSwitchCase="'/evd'"></app-help-evd>
            <app-help-alarms [parent]="this" *ngSwitchCase="'/alarms'"></app-help-alarms>
            <app-help-guards [parent]="this" *ngSwitchCase="'/guards'"></app-help-guards>
            <app-help-events [parent]="this" *ngSwitchCase="'/event'"></app-help-events>
            <app-help-profile [parent]="this" *ngSwitchCase="'/profile'"></app-help-profile>
            <app-help-settings [parent]="this" *ngSwitchCase="'/settings'"></app-help-settings>
            <app-help-statistics [parent]="this" *ngSwitchCase="'/statistics'"></app-help-statistics>
            <div *ngSwitchDefault style="display:flex;justify-content:center;align-items:center;">
                <div class="row" layout="column" layout-align="space-between center">
                    <div class="col-4 helpBox" (click)="setHelpEntry('/home')">
                        <mat-icon class="helpIcon">home</mat-icon>
                        <p class="helpText">Startseite</p>
                    </div>
                    <div class="col-4 helpBox" (click)="setHelpEntry('/user')">
                        <mat-icon class="helpIcon">contacts</mat-icon>
                        <p class="helpText">Kontakte</p>
                    </div>
                    <div class="col-4 helpBox" (click)="setHelpEntry('/profile')">
                        <mat-icon class="helpIcon">person</mat-icon>
                        <p class="helpText">Profil</p>
                    </div>
                    <div class="col-4 helpBox" (click)="setHelpEntry('/responder')">
                        <mat-icon class="helpIcon">schedule</mat-icon>
                        <p class="helpText">Responder</p>
                    </div>
                    <div class="col-4 helpBox" (click)="setHelpEntry('/evd')">
                        <mat-icon class="helpIcon">event_note</mat-icon>
                        <p class="helpText">EvD</p>
                    </div>
                    <div class="col-4 helpBox" (click)="setHelpEntry('/alarms')">
                        <mat-icon class="helpIcon">fireplace</mat-icon>
                        <p class="helpText">Einsatz</p>
                    </div>
                    <div class="col-4 helpBox" (click)="setHelpEntry('/guards')">
                        <mat-icon class="helpIcon">security</mat-icon>
                        <p class="helpText">Sicherheitswachen<p>
                    </div>
                    <div class="col-4 helpBox" (click)="setHelpEntry('/event')">
                        <mat-icon class="helpIcon">calendar_today</mat-icon>
                        <p class="helpText">Termine</p>
                    </div>
                    <div class="col-4 helpBox" (click)="setHelpEntry('/statistics')">
                        <mat-icon class="helpIcon">analytics</mat-icon>
                        <p class="helpText">Auswertungen</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
        <button mat-raised-button class="float-left" color="accent" (click)="helpOverview()" *ngIf="search===false">Zur
            Übersicht</button>
        <button mat-raised-button class="float-right" cdkFocusInitial mat-dialog-close color="accent">Schließen</button>
</mat-dialog-actions>