import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigModule } from '../../config';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponentComponent implements OnInit {

  constructor(private _configService: ConfigModule ) { }

  ngOnInit() {
    this._configService.setTitle('404 Seite nicht gefunden');
  }

}
