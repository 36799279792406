import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { filter, firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.css']
})
export class StorageComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['name', 'action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  isLoading: boolean = true;

  constructor(private _http: HttpClient,
    private _config: ConfigModule,
    public _bottomSheet: MatBottomSheet) {} 

  ngOnInit(): void {
    this.loadData();
    this._config.setTitle('Lagerplätze')

    this.dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'name':
          return item.sorter;
        default: return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  async deleteStorage(storageId) {
    const result = await Swal.fire({
      text: 'Möchtest du wirklich diesen Lagerort löschen?',
      showDenyButton: true,
      showConfirmButton: true,
      icon: 'question',
      confirmButtonText: 'Löschen',
      denyButtonText: 'Abbrechen'
    });
    if (!result.isConfirmed) {
      return;
    }

    try {
      await firstValueFrom(this._http.delete<any>(`${getHostUrl()}admin/material/storage/${storageId}`));
      this.dataSource.data = this.dataSource.data.filter(row => row.id !=storageId);
      this._config.showSnackbar('Lagerplatz erfolgreich gelöscht!', 2500, 'success');
    } catch (e) {    
      if (e.error.error) {
        this._config.showSnackbar(e.error.error, 2500, 'error');
        return;
      }  
      this._config.showSnackbar('Fehler beim Löschen des Lagerplatzes', 2500, 'error');
    }
  }

  async loadData() {
    try {
      const result = (await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/storage`))).map((item, index) => {
        item.sorter = index;
        return item;
      });
      this.dataSource.data = result;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    } catch (e) {      
      this._config.showSnackbar('Fehler beim Laden der Lagerplätze', 2500, 'error');
      this.isLoading = false;
    }
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu);
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }
}
