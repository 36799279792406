import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Courses } from '@ffo/mitgliederbereich-types';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-secretary-courses-detail',
  templateUrl: './secretary-courses-detail.component.html',
  styleUrls: ['./secretary-courses-detail.component.css'],
})
export class SecretaryCoursesDetailComponent implements OnInit {

  courses: Courses;
  group: UntypedFormGroup;
  courseId: number = null;
  categoryList: any = [];
  groupsList: any = [];

  newCourse: boolean = false;

  public groupsAddFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public groupsAddFilter: BehaviorSubject<any> = new BehaviorSubject<any>(1);
  public groupsRemoveFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public groupsRemoveFilter: BehaviorSubject<any> = new BehaviorSubject<any>(1);

  protected _onDestroy = new Subject<void>();

  constructor(private _route: ActivatedRoute,
    public _login: Login,
    private _http: HttpClient,
    public _spinner: NgxSpinnerService,
    private _router: Router,
    private _config: ConfigModule) { }


  ngOnInit() {
    this._config.setTitle('Laufbahnereignis bearbeiten');
    this._spinner.show();
    this.newCourse = this._route.snapshot.queryParams.new !== null && this._route.snapshot.queryParams.new !== undefined;
    this.courseId = this._route.snapshot.params.courseId;
    this.group = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.required),
      category: new UntypedFormControl('', Validators.required),
      duration: new UntypedFormControl(''),
      groupsAdd: new UntypedFormControl([]),
      groupsRemove: new UntypedFormControl([]),
      location: new UntypedFormControl('', Validators.required),
      id: new UntypedFormControl(),
    });
    this.load();
    this.fetchDataInfo();
    this.groupsAddFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAddGroups();
      });
    this.groupsRemoveFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRemoveGroups();
      });
  }

  load(): void {
    if (this._route.snapshot.params.courseId) {
      this.fetchCourse(this._route.snapshot.params.courseId);
    } else
      this._spinner.hide();
  }

  fetchCourse(courseId: number) {
    this._http.get<Courses>(`${getHostUrl()}admin/courses?courseId=${courseId}`).subscribe(courses => {
      this.courses = courses;
      this.group.patchValue(courses);
      console.log(courses.groups);

      this.group.controls.groupsAdd.setValue(courses.groups.filter(c => c.action == 'add').map(c => c.groupId));
      this.group.controls.groupsRemove.setValue(courses.groups.filter(c => c.action == 'delete').map(c => c.groupId));
      this.filterAddGroups();
      this.filterRemoveGroups();
      this._spinner.hide();
    });

  }

  fetchDataInfo() {
    return new Promise((resolve, reject) => {
      Promise.all([
        this._http.get(`${getHostUrl()}datainfo/categoryCourses`).toPromise(),
        this._http.get(`${getHostUrl()}datainfo/groups?type=admin`).toPromise(),
      ]).then(values => {
        this.categoryList = values[0] || [];
        this.groupsList = values[1] || [];
        this.groupsAddFilterCtrl.updateValueAndValidity();
        this.groupsRemoveFilterCtrl.updateValueAndValidity();
        resolve(true);
      }).catch(reject);
    });
  }

  protected filterAddGroups() {
    if (!this.groupsList) {
      return;
    }

    const addGroups = this.groupsRemoveFilter.getValue();
    let groupsList = this.groupsList;
    if (addGroups?.length > 0) {
      groupsList = this.groupsList.filter(g => !this.group.controls.groupsRemove?.value.includes(g.id));
    }

    let search = this.groupsAddFilterCtrl.value;
    if (!search) {
      this.groupsAddFilter.next(groupsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.groupsAddFilter.next(
      groupsList.filter(s => s.name.toLocaleLowerCase().includes(search)),
    );
  }

  protected filterRemoveGroups() {
    if (!this.groupsList) {
      return;
    }

    const addGroups = this.groupsAddFilter.getValue();
    let groupsList = this.groupsList;
    if (addGroups?.length > 0) {
      groupsList = this.groupsList.filter(g => !this.group.controls.groupsAdd?.value.includes(g.id));
    }

    let search = this.groupsRemoveFilterCtrl.value;
    if (!search) {
      this.groupsRemoveFilter.next(groupsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.groupsRemoveFilter.next(
      groupsList.filter(s => s.name.toLocaleLowerCase().includes(search)),
    );
  }

  saveData() {
    this.group.markAllAsTouched();
    const data = this.group.value;

    this._http.post<Courses>(getHostUrl() + 'admin/courses', { courseObj: data }).subscribe(resp => {
      if (resp && resp.id) {
        this._config.showSnackbar('Laufbahnereignis angelegt', 2500, 'success');
        this._router.navigate([`/admin/fields/secretary/courses/details/${resp.id}`], { queryParams: { 'new': true } });
      } else {
        this._config.showSnackbar('Laufbahnereignis gespeichert', 2500, 'success');
      }
    }, error => {
      if (error.error === 5001)
        this._config.showSnackbar('Laufbahnereignis ist nicht vorhanden!', 2000, 'error');
      else if (error.error?.code === 1062)
        this._config.showSnackbar('Laufbahnereignis ist bereits vorhanden!', 2000, 'error');
      else
        this._config.showSnackbar('Ein Fehler ist aufgetreten!', 2000, 'error');
    });
  }
}
