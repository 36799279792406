import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

export interface PreventCloseGuard {
  onCloseValid(): boolean;
}

@Injectable()
export class PreventCloseGuardService  {

  canDeactivate(component: PreventCloseGuard): Promise<boolean> | boolean {
    if (component.onCloseValid() || !environment.userLoggedIn)
      return true;
    return this.unsavedChangesConfirmation();
  }

  async unsavedChangesConfirmation(): Promise < boolean > {
    const result = await Swal.fire({
      title: 'ungesicherte Änderungen',
      text: 'Du hast noch nicht alle Änderungen gespeichert!',
      icon: 'error',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showDenyButton: true,
      denyButtonText: 'Änderung verwerfen',
      confirmButtonText: 'Zurück',
    });
    return result.isDenied;
  }
}
