<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Sicherheitswachen</h3>
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="personal">
              <mat-icon class="float-left mt-4 ms-3">group</mat-icon>
              Personal<br />
              <small>Wachstatus der Mitglieder</small>
            </button>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="guards">
              <mat-icon class="float-left mt-4 ms-3">security</mat-icon>
              Sicherheitswachen<br />
              <small>Sicherheitswachen verwalten</small>
            </button>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="assignment">
              <mat-icon class="float-left mt-4 ms-3">assignment_add</mat-icon>
              Einteilung<br />
              <small>Wachen einteilen</small>
            </button>
          </div>
          <div class="col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="assignment/send">
              <mat-icon class="float-left mt-4 ms-3">assignment_turned_in</mat-icon>
              Einteilungen verschicken<br />
              <small>Wacheinteilungen verschicken</small>
            </button>
          </div>
          <div class="col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="verified">
              <mat-icon class="float-left mt-4 ms-3">verified</mat-icon>
              Wachbestätigungen verschicken<br />
              <small>Bestätigung an Veranstalter schicken</small>
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="accounting">
              <mat-icon class="float-left mt-4 ms-3">assignment</mat-icon>
              Bericht<br />
              <small>Wachbericht ablegen</small>
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-12 mb-3">
            <button mat-raised-button class="input-full-width menu-button" color="primary" routerLink="accounting/send">
              <mat-icon class="float-left mt-4 ms-3">account_balance</mat-icon>
              Abrechnung verschicken<br />
              <small>Wachabrechnung verschicken</small>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>