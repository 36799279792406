<mat-form-field appearance="fill" class="input-full-width">
  <mat-label>{{label}}</mat-label>
  <mat-select [(ngModel)]="currentranks" [required]="required" [multiple]="multiple" (selectionChange)="selected($event)">
    <mat-option>
      <ngx-mat-select-search [formControl]="ranksFilterCtrl" noEntriesFoundLabel="Keine Ränge verfügbar"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let rank of ranksFilter | async" [value]="rank.id">
      {{rank.name}}
    </mat-option>
  </mat-select>
</mat-form-field>