import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { Courses } from '@ffo/mitgliederbereich-types';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { SecretaryCoursesCategoriesDialogComponent } from './secretary-courses-categories-dialog/secretary-courses-categories-dialog.component';

@Component({
  selector: 'app-secretary-courses-categories',
  templateUrl: './secretary-courses-categories.component.html',
  styleUrls: ['./secretary-courses-categories.component.css']
})
export class SecretaryCoursesCategoriesComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = ['name', 'icon'];
  dataSource = new MatTableDataSource();
  categories: string[] = [];

  @ViewChild(MatSort, { static: false})
  sort: MatSort;
  searchFilter: string = '';

  constructor(private _http: HttpClient,
    public _login: Login,
    private _config: ConfigModule,
    private _dialog: MatDialog,
    private _spinner: NgxSpinnerService) { }

  ngOnInit() {
    this._config.setTitle('Laufbahn');
    this.refreshData();
  }

  refreshData() {
    this._http.get<any>(getHostUrl() + 'datainfo/categoryCourses')
      .subscribe(data => {
        this.categories = data;
        this.dataSource = new MatTableDataSource<string>();
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this._spinner.hide();
      });
  }

  async editItem(item: any) {
    const result = await firstValueFrom(
      this._dialog.open(SecretaryCoursesCategoriesDialogComponent, {
        data: {
          item
        }
      }).afterClosed()
    );
    if (result) {
      this.refreshData();
    }
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.dataSource.filter = this.searchFilter;
  }

  filter(): (data: Courses, filter: string) => boolean {
    let filterFunction = function (data: Courses, filter: string): boolean {
      let searchTerms: { search: string; category: number[]; } = JSON.parse(filter);
      if (searchTerms.search !== '' && data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1) { return false; }
      if (searchTerms.category.length !== 0 && searchTerms.category.includes(data.categoryId) === false) { return false; }
      return true;
    };
    return filterFunction;
  }
}
