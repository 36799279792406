<div id="navigationController">
    <div class="row">
        <div class="col-lg-8 offset-lg-2">
            <h3 class="section-header">Startseite</h3>
            <mat-card>
                <mat-card-content>
                    <a mat-mini-fab color="primary" class="pageNext" (click)="openDownloadSheet()">
                        <mat-icon>get_app</mat-icon>
                    </a>
                    <app-event-calendar url="calendar"></app-event-calendar>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<ng-template #downloadCalendar>
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
        <mat-icon>close</mat-icon>
    </a>
    <mat-nav-list>
        <a [href]="calendarTokenWebcal" mat-list-item (click)="_bottomSheet.dismiss()" *ngIf="!env.mobile">
            <span matListItemTitle>Outlook & Co.</span>
            <span matListItemLine>Importiere deinen Kalendar direkt in deine Kalendar-Software</span>
        </a>
        <a [href]="calendarTokenWebcal" mat-list-item (click)="_bottomSheet.dismiss()" *ngIf="env.mobile"
            target="_blank">
            <span matListItemTitle>Apple IOS</span>
            <span matListItemLine>Import in die IOS Kalendar App</span>
        </a>
        <a mat-list-item (click)="copyClipboard()">
            <span matListItemTitle>Google & andere ICAL Provider</span>
            <span matListItemLine *ngIf="!env.mobile">Kopiere die Kalendaradresse um sie deiner Kalendar-Software
                hinzuzufügen</span>
            <span matListItemLine *ngIf="env.mobile">Kopiere die Kalendaradresse</span>
        </a>
    </mat-nav-list>
</ng-template>