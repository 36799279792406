import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { environment } from 'src/environments/environment';
import { CustomDateAdapterService } from '../../../customDateAdapter';

@Component({
  selector: 'app-month-yearpicker',
  templateUrl: './month-yearpicker.component.html',
  styleUrls: ['./month-yearpicker.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapterService,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: { display: { dateInput: 'monthYearLabel' } } },
  ],
})
export class MonthYearpickerComponent {

  env = environment;
  constructor() { }

  @Output() value = new EventEmitter<Date>();
  @Input() set inputValue(value: Date) {
    this.date.setValue(value);
    this.date.updateValueAndValidity();
  }
  @Input() set disabled(value: boolean) {
    if (value) {
      this.date.disable();
    } else {
      this.date.enable();
      this.date.updateValueAndValidity();
    }
  }
  @Input() label: string = 'Datum';
  @Input() required: boolean = false;
  @Input() min: Date;
  @Input() max: Date;

  date: FormControl<Date> = new FormControl();

  setMonthAndYear(normalizedMonthAndYear: Date, datepicker: MatDatepicker<Date>) {
    const ctrlValue: Date = new Date();
    ctrlValue.setDate(1);
    ctrlValue.setMonth(normalizedMonthAndYear.getMonth());
    ctrlValue.setFullYear(normalizedMonthAndYear.getFullYear());
    this.date.setValue(ctrlValue);
    this.inputValue = ctrlValue;
    this.value.emit(ctrlValue);
    datepicker.close();
  }

  open(datepicker: MatDatepicker<Date>): void {
    if (this.required) {
      this.date.setValue(new Date());
    }
    if (this.inputValue) {
      this.date.setValue(this.inputValue);
    }
    datepicker.open();
  }
}
