<mat-form-field appearance="fill" class="input-full-width">
  <mat-label>{{label}}</mat-label>
  <mat-select [(ngModel)]="currentGroups" [required]="required" [multiple]="multiple" (selectionChange)="selected($event)">
    <mat-option>
      <ngx-mat-select-search [formControl]="groupsFilterCtrl" noEntriesFoundLabel="Kein Gruppen verfügbar"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let group of groupsFilter | async" [value]="group.id">
      {{group.name}}
    </mat-option>
  </mat-select>
</mat-form-field>