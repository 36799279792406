import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { environment } from 'src/environments/environment';
import { Notification as ffoNotification } from '@ffo/mitgliederbereich-types';
import { DateTime } from 'luxon';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';

@Component({
  selector: 'app-atemschutz-notifications',
  templateUrl: './atemschutz-notifications.component.html',
  styleUrls: ['./atemschutz-notifications.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class AtemschutzNotificationsComponent implements OnInit, AfterViewInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  notificationsColumns: string[] = ['title', 'name', 'date', 'action'];
  notifications: MatTableDataSource<ffoNotification>;
  notificationsList: ffoNotification[] = [];
  
  env = environment;
  constructor(private _http: HttpClient, private _config: ConfigModule, private _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this._config.setTitle('Atemschutz | Benachrichtigungen');
    this.loadNotifications();
  }

  ngAfterViewInit(): void {
    if (this.env.mobile) {
      this.notificationsColumns = ['title', 'name', 'date'];
    }
  }

  loadNotifications() {
    this._http.get<ffoNotification[]>(`${getHostUrl()}admin/fields/atemschutz/notifications?groupID=34`).subscribe((notifications) => {
      notifications = notifications.map(notification => {
        notification.date = new Date(notification.date);
        return notification;
      });
      this.notificationsList = notifications;
      this.notifications = new MatTableDataSource<ffoNotification>();
      this.notifications.data = notifications;
      this.notifications.paginator = this.paginator;
      this.notifications.sort = this.sort;

      this.notificationsList.forEach((item) => {
        if (item.userID === -1) {
          item.name = 'System';
        }
      });
    });
  }

  openNotification(notification: ffoNotification): void {
    this._http
      .delete<any>(
      `${getHostUrl()}admin/fields/maschinisten/notifications?notificationId=${notification.id}`,
    )
      .subscribe((data) => {
        this.loadNotifications();
      });
    this._bottomSheet.open(BottomSheetNotificationAtemschutzComponent, {
      data: notification,
    });
  }

  formatDate(date: Date) {
    const res = DateTime.fromJSDate(date).toFormat('dd.MM.yyyy HH:mm');
    if (res === '01.01.1970 00:00')
      return '';
    return res;
  }
}

@Component({
  selector: 'app-atemschutz-notification',
  template: `<div class="text-center">
  <br />
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
    <mat-icon>close</mat-icon>
  </a>
  <h4>{{ title }}</h4>
  <br />
  <span [innerHTML]="message"></span>
  <hr />
  <i><a routerLink="/admin/user/edit/{{userID}}/atemschutz" (click)="_bottomSheet.dismiss()">{{ name }}</a> am {{ formatDate(from) }}</i>
</div>`,
})
export class BottomSheetNotificationAtemschutzComponent implements OnDestroy {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: ffoNotification,
    public _bottomSheet: MatBottomSheet) {
    this.userID = data.userID;
    this.from = data.date;
    this.name = data.name;
    this.title = data.title;
    this.message = data.text;
  }

  ngOnDestroy(): void {
    this._bottomSheet.dismiss();
  }

  userID: number;

  from: Date;

  name: string;

  title: string;

  message: string;

  formatDate(date: Date) {
    const res = DateTime.fromJSDate(date).toFormat('dd.MM.yyyy HH:mm');
    if (res === '01.01.1970 00:00')
      return '';
    return res;
  }
}