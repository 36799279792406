import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import dayjs from 'dayjs';
import { firstValueFrom, filter } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { QrScannerComponent } from 'src/app/util/dialogs/qr-scanner/qr-scanner.component';

@Component({
  selector: 'app-check-cycles',
  templateUrl: './check-cycles.component.html',
  styleUrls: ['./check-cycles.component.css']
})
export class CheckCyclesComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['device', 'materialCategory', 'name', 'date', 'action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  isLoading: boolean = true;

  dayjs = dayjs;

  env = environment;
  constructor(private _http: HttpClient,
    private _config: ConfigModule,
    public _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this.loadData();

    this._config.setTitle('Anstehende Prüfungen');

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'check': return item.cycle ? dayjs(item.cycle).toDate() : null;
        default: return item[property];
      }
    };
  }

  ngAfterViewInit(): void {
    if (this.env.mobile) {
      this.displayedColumns = ['device', 'materialCategory', 'date', 'name', 'action'];
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  async loadData() {
    try {
      let result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/checks/cycles`));
      result = result.map(check => {
        check.nextCycle = dayjs(check.date).diff(dayjs(), 'days') + 1;
        return check;
      });

      this.dataSource.data = result;
      
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    } catch (e) {
      this._config.showSnackbar('Fehler beim Laden der Prüfungen', 2500, 'error');
      this.isLoading = false;
    }
  }
}
