<div class="row">
    <div class="col-lg-3 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Anforderer</mat-label>
            <input matInput [value]="parent.getUser(element.userID)" disabled>
            <mat-icon matSuffix routerLink="/admin/user/edit/{{element.userID}}/masterdata" class="pointer">
                person</mat-icon>
        </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Benutzer</mat-label>
            <input matInput [value]="parent.getUser(element.targetID)" disabled>
            <mat-icon matSuffix routerLink="/admin/user/edit/{{element.targetID}}/masterdata" class="pointer">
                person</mat-icon>
        </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Uhrzeit</mat-label>
            <input matInput [value]="getFormattedDate(element.time)" disabled>
        </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Aktion</mat-label>
            <input matInput [value]="element.action" disabled>
        </mat-form-field>
    </div>
    <div class="col-12 col-md-6 col-lg-6" *ngIf="!picture">
        <div class="row">
            <div class="col-12">
                <h6>Alte Daten</h6>
            </div>
            <div class="col-lg-6 col-md-6 col-12" *ngIf="licence_old">
                <mat-form-field id="dateOfIssue" class="input-full-width" appearance="outline">
                    <mat-label>Ausstellungsdatum Führerschein</mat-label>
                    <input matInput placeholder="Ausstellungsdatum Führerschein"
                        [value]="getFormattedDate(licence_old.dateOfIssue)" disabled>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-12" *ngIf="licence_old">
                <mat-form-field id="dateOfExpiry" class="input-full-width" appearance="outline">
                    <mat-label>Ablaufdatum Führerschein</mat-label>
                    <input matInput placeholder="Ablaufdatum Führerschein"
                        [value]="getFormattedDate(licence_old.dateOfExpiry)" disabled>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-12" *ngIf="licence_old">
                <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Ausstellungsbehörde</mat-label>
                    <input matInput [(ngModel)]="licence_old.issuingAuthority" disabled>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-12" *ngIf="licence_old">
                <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Führerscheinnummer</mat-label>
                    <input matInput [(ngModel)]="licence_old.dlNumber" disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6" *ngIf="picture">
        <img [src]="front" style="width: 100%;">
    </div>
    <div class="col-12 col-md-6 col-lg-6">
        <div class="row">
            <div class="col-12">
                <h6>Neue Daten</h6>
            </div>
            <div class="col-lg-6 col-md-6 col-12"
                [ngClass]="licence_old && licence_old.dateOfIssue===licence_new.dateOfIssue ? 'same' : 'changed'">
                <mat-form-field id="dateOfIssue" *ngIf="!env.mobile" class="input-full-width" appearance="outline">
                    <mat-label>Ausstellungsdatum (4a)</mat-label>
                    <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
                        [min]="age18Date" [value]="licence_new.dateOfIssue" (dateChange)="ondateOfIssueChange($event)"
                        [max]="today" (change)="onissueDateChange($event)" (focus)="dateOfIssue.open()"
                        [disabled]="!picture" required>
                    <mat-datepicker-toggle matSuffix [for]="dateOfIssue" *ngIf="picture"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfIssue [startAt]="startDate" startView="multi-year"></mat-datepicker>
                </mat-form-field>
                <mat-form-field id="dateOfIssue" *ngIf="env.mobile" class="input-full-width" appearance="outline">
                    <mat-label>Ausstellungsdatum (4a)</mat-label>
                    <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
                        [min]="age18Date" [value]="licence_new.dateOfIssue" (dateChange)="ondateOfIssueChange($event)"
                        [max]="today" (change)="onissueDateChange($event)" (focus)="dateOfIssue.open()"
                        [disabled]="!picture" required>
                    <mat-datepicker-toggle matSuffix [for]="dateOfIssue" *ngIf="picture"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfIssue touchUi [startAt]="startDate" startView="multi-year">
                    </mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-12"
                [ngClass]="licence_old && licence_old.dateOfExpiry===licence_new.dateOfExpiry ? 'same' : 'changed'">
                <mat-form-field id="dateOfExpiry" *ngIf="!env.mobile" class="input-full-width" appearance="outline">
                    <mat-label>Ablaufdatum (4b)</mat-label>
                    <input matInput [matDatepicker]="dateOfExpiry" [min]="licence_new.dateOfIssue" [max]="expiryLicence"
                        placeholder="Ablaufdatum Führerschein" [value]="licence_new.dateOfExpiry"
                        (dateChange)="ondateOfExpiryChange($event)" (change)="onexpiryDateChange($event)"
                        (focus)="dateOfExpiry.open()" [disabled]="!picture" required>
                    <mat-datepicker-toggle matSuffix [for]="dateOfExpiry" *ngIf="picture"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfExpiry [startAt]="startDate" startView="multi-year"></mat-datepicker>
                </mat-form-field>
                <mat-form-field id="dateOfExpiry" *ngIf="env.mobile" class="input-full-width" appearance="outline">
                    <mat-label>Ablaufdatum (4b)</mat-label>
                    <input matInput [matDatepicker]="dateOfExpiry" [min]="licence_new.dateOfIssue" [max]="expiryLicence"
                        placeholder="Ablaufdatum Führerschein" [value]="licence_new.dateOfExpiry"
                        (dateChange)="ondateOfExpiryChange($event)" (change)="onexpiryDateChange($event)"
                        (focus)="dateOfExpiry.open()" [disabled]="!picture" required>
                    <mat-datepicker-toggle matSuffix [for]="dateOfExpiry" *ngIf="picture"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfExpiry touchUi [startAt]="startDate" startView="multi-year">
                    </mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-12"
                [ngClass]="licence_old && licence_old.issuingAuthority===licence_new.issuingAuthority ? 'same' : 'changed'">
                <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Ausstellungsbehörde (4c)</mat-label>
                    <input matInput [(ngModel)]="licence_new.issuingAuthority" [disabled]="!picture" required
                        [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option value="Landratsamt München">Landratsamt München</mat-option>
                        <mat-option value="Landeshauptstadt München">Landeshauptstadt München</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-12"
                [ngClass]="licence_old && licence_old.dlNumber===licence_new.dlNumber ? 'same' : 'changed'">
                <mat-form-field class="input-full-width" appearance="outline">
                    <mat-label>Führerscheinnummer (5)</mat-label>
                    <input matInput [(ngModel)]="licence_new.dlNumber" [disabled]="!picture" required>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div class="clearfix">
    <button mat-raised-button class="float-right" color="primary" *ngIf="!picture"
        (click)="picture = true">weiter</button>
    <button mat-raised-button class="float-right" color="success" *ngIf="picture" (click)="save()">speichern
        <mat-icon>save</mat-icon>
    </button>
</div>