<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" [opened]="drawerPinned" [mode]="drawerMode"
    [fixedInViewport]="mobileQuery.matches" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'">
    <mat-nav-list>
      <div class="clearfix">
        <button mat-icon-button (click)="pinDrawer()" class="float-left ms-2" *ngIf="!env.mobile"
          [ngClass]="drawerPinned===false?'unpinned':''">
          <mat-icon class="pindrawer">push_pin</mat-icon>
        </button>
        <button mat-icon-button (click)="closeDrawer()" class="float-right me-2">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div [hidden]="!env.userLoggedIn">
        <div mat-list-item *ngIf="env.userInfo" class="text-center input-full-width">
          <div>
            <label for="uploadAvatarInput">
              <img height="150" width="150" [src]="env.userInfo.avatar" class="avatarIcon img-thumbnail" />
            </label>
            <input type="file" [(ngModel)]="avatarFileUrl" (change)="uploadAvatar($event)" style="display:none;"
              id="uploadAvatarInput" accept="image/x-png,image/jpeg" />
          </div>
        </div>

        <div style="margin-top: 30px;">
          <a mat-list-item routerLink="/home" routerLinkActive="active" (click)="checkDrawer()">
            <mat-icon>home</mat-icon>
            <span class="nav-caption">Startseite</span>
          </a>
          <a mat-list-item routerLink="user" *ngIf="_login.hasPermission('user-list')" routerLinkActive="active"
            (click)="checkDrawer()">
            <mat-icon>contacts</mat-icon>
            <span class="nav-caption">Kontakte</span>
          </a>
          <a mat-list-item [matMenuTriggerFor]="shifts"
            *ngIf="_login.hasPermission('responder') || _login.hasPermission('evd-view') || _login.hasPermission('event-watcher')">
            <mat-icon>calendar_view_month</mat-icon>
            <span class="nav-caption">Dienstpläne</span>
          </a>
          <mat-menu #shifts="matMenu" overlapTrigger="false">
            <div class="fullWidthSidebar"></div>
            <button mat-menu-item routerLink="responder" routerLinkActive="active" (click)="checkDrawer()"
              *ngIf="_login.hasPermission('responder')">
              <mat-icon>schedule</mat-icon>
              <span class="nav-caption">First Responder</span>
            </button>
            <button mat-menu-item routerLink="evd" routerLinkActive="active" (click)="checkDrawer()"
              *ngIf="_login.hasPermission('evd-view')">
              <mat-icon>event_note</mat-icon>
              <span class="nav-caption">Einsatzleiter vom Dienst </span>
            </button>
            <button mat-menu-item routerLink="guards" routerLinkActive="active" (click)="checkDrawer()"
              *ngIf="_login.hasPermission('event-watcher')">
              <mat-icon>security</mat-icon>
              <span class="nav-caption">Sicherheitswachen</span>
            </button>
          </mat-menu>
          <a mat-list-item routerLink="event/list" *ngIf="_login.hasPermission('event')" routerLinkActive="active"
            (click)="checkDrawer()">
            <mat-icon>calendar_today</mat-icon>
            <span class="nav-caption">Termine</span>
          </a>
          <a mat-list-item routerLink="alarms"
            *ngIf="_login.hasPermission('alarm-view') && this._login.hasPermission('alarm-view-non-whitelisted')"
            routerLinkActive="active" (click)="checkDrawer()">
            <mat-icon>fireplace</mat-icon>
            <span class="nav-caption">Einsatz</span>
          </a>
          <a mat-list-item routerLink="timetracking"
            *ngIf="_login.hasPermission('time')"
            routerLinkActive="active" (click)="checkDrawer()">
            <mat-icon>more_time</mat-icon>
            <span class="nav-caption">Zeiterfassung</span>
          </a>
          <a mat-list-item routerLink="material" *ngIf="_login.hasPermission('material-manage')"
            routerLinkActive="active" (click)="checkDrawer()">
            <mat-icon>construction</mat-icon>
            <span class="nav-caption">Materialverwaltung</span>
          </a>
          <a mat-list-item routerLink="statistics" routerLinkActive="active" (click)="checkDrawer()">
            <mat-icon>analytics</mat-icon>
            <span class="nav-caption">Auswertungen</span>
          </a>
        </div>
        <a mat-list-item [matMenuTriggerFor]="fields"
          *ngIf="_login.hasPermission('admin-user-drivingperm-edit') || _login.hasPermission('admin-user-atemschutz-edit') || _login.hasPermission('admin-control') || _login.hasPermission('social-media')  || _login.hasPermission('admin-guards')">
          <mat-icon>folder</mat-icon>
          <span class="nav-caption">Fachbereiche</span>
        </a>
        <mat-menu #fields="matMenu" overlapTrigger="false">
          <div class="fullWidthSidebar"></div>
          <button mat-menu-item routerLink="admin/fields/maschinisten" routerLinkActive="active" (click)="checkDrawer()"
            *ngIf="_login.hasPermission('admin-user-drivingperm-edit')">
            <mat-icon>drive_eta</mat-icon>
            <span class="nav-caption">Maschinisten</span>
          </button>
          <button mat-menu-item routerLink="admin/fields/atemschutz" routerLinkActive="active" (click)="checkDrawer()"
            *ngIf="_login.hasPermission('admin-user-atemschutz-edit')">
            <mat-icon>fire_extinguisher</mat-icon>
            <span class="nav-caption">Atemschutz</span>
          </button>
          <button mat-menu-item routerLink="admin/fields/secretary" routerLinkActive="active" (click)="checkDrawer()"
            *ngIf="_login.hasPermission('admin-secretary')">
            <mat-icon>edit_note</mat-icon>
            <span class="nav-caption">Schriftführer</span>
          </button>
          <button mat-menu-item routerLink="admin/fields/social-media" routerLinkActive="active" (click)="checkDrawer()"
            *ngIf="_login.hasPermission('social-media')">
            <mat-icon>monochrome_photos</mat-icon>
            <span class="nav-caption">Social Media</span>
          </button>
          <button mat-menu-item routerLink="admin/fields/guards" routerLinkActive="active" (click)="checkDrawer()"
            *ngIf="_login.hasPermission('admin-guards')">
            <mat-icon>security</mat-icon>
            <span class="nav-caption">Sicherheitswachen</span>
          </button>
        </mat-menu>
        <a mat-list-item routerLink="admin/groups" *ngIf="_login.hasPermission('admin-groups-list')"
          routerLinkActive="active" (click)="checkDrawer()">
          <mat-icon>groups</mat-icon>
          <span class="nav-caption">Gruppenverwaltung</span>
        </a>
        <a mat-list-item routerLink="admin/settings" *ngIf="_login.hasPermission('admin-settings')"
          routerLinkActive="active" (click)="checkDrawer()">
          <mat-icon>settings</mat-icon>
          <span class="nav-caption">Einstellungen</span>
        </a>
        <a mat-list-item (click)="this._com.sendAction('options'); checkDrawer()" *ngIf="_com.isRunningInApp()">
          <mat-icon>open_in_new</mat-icon>
          <span class="nav-caption">App Einstellungen</span>
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" [hidden]="!env.userLoggedIn" mat-icon-button
        (click)="drawer.toggle()" *ngIf="!drawerPinned">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <div class="centerToNav" *ngIf="!env.userLoggedIn">
        <img [src]="baseURL + 'logo/256'" height="40" width="40" />
        <span class="nostyle d-none d-md-inline d-lg-inline d-xl-inline" style="margin-left: 20px;"
          *ngIf="!env.mobile">{{ title }}</span>
      </div>

      <div class="centerToNav" routerLink="home" style="cursor: pointer;" [hidden]="scrolled || !env.userLoggedIn">
        <img [src]="baseURL + 'logo/256'" height="40" width="40" *ngIf="!env.mobile" />
        <a style="margin-left: 20px; color: #fff">{{ title }}</a>
      </div>

      <div class="centerToNav" (click)="scrollTop()" style="cursor: pointer;" [hidden]="!scrolled || !env.userLoggedIn">
        <img [src]="baseURL + 'logo/256'" height="40" width="40"
          class="nostyle d-none d-md-inline d-lg-inline d-xl-inline" *ngIf="!env.mobile" />
        <a style="margin-left: 20px; color: #fff">{{ env.deploymentEnv === 'production' ? env.pageTitle : title}}</a>
      </div>

      <div [hidden]="!env.userLoggedIn" class="float-right">
        <app-notification-nav #notificationNav></app-notification-nav>
        <button type="button" aria-label="Toggle sidenav" mat-icon-button [matMenuTriggerFor]="userProfileDropdown">
          <mat-icon aria-label="Side nav toggle icon">person</mat-icon>
        </button>
        <mat-menu #userProfileDropdown="matMenu" style="width: 100%" overlapTrigger="false">
          <div class="smallWidthProfile"></div>
          <button *ngIf="pwaS.promptEvent" mat-menu-item (click)="installPwa()">
            <mat-icon>install_desktop</mat-icon>
            <span>Zum Startbildschirm</span>
          </button>
          <button mat-menu-item routerLink="/profile/">
            <mat-icon>assignment_ind</mat-icon>
            <span>Mein Profil</span>
          </button>
          <button mat-menu-item routerLink="/profile/alarms" *ngIf="_login.hasPermission('profile-alarms')">
            <mat-icon>inbox</mat-icon>
            <span>Einsatzbestätigungen</span>
          </button>
          <button mat-menu-item routerLink="/profile/timeline">
            <mat-icon>view_timeline</mat-icon>
            <span>Meine Laufbahn</span>
          </button>
          <button mat-menu-item routerLink="/settings">
            <mat-icon>settings</mat-icon>
            <span>Einstellungen</span>
          </button>
          <button mat-menu-item (click)="changePassword()">
            <mat-icon>vpn_key</mat-icon>
            <span>Passwort ändern</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Ausloggen</span>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #resizeTemplate>
  <h2 mat-dialog-title>Bild zuschneiden</h2>
  <mat-dialog-content style="min-width: 400px;">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
      [resizeToWidth]="128" format="png" (imageLoaded)="imageLoaded()" (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()">
    </image-cropper>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="clearfix">
      <button mat-mini-fab mat-dialog-close class="float-right" [disabled]="imageLoading"
        (click)="uploadAvatarToServer()" color="success"><mat-icon>done</mat-icon></button>
      <button mat-mini-fab mat-dialog-close class="float-left" [disabled]="imageLoading"
        color="primary"><mat-icon>close</mat-icon></button>
    </div>
  </mat-dialog-actions>
</ng-template>

<div class="helpButtonOverlay" [hidden]="!env.userLoggedIn">
  <button mat-mini-fab class="float-right" color="primary" (click)="openHelp()">
    <mat-icon>help</mat-icon>
  </button>
</div>

<app-whats-new></app-whats-new>