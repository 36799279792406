<mat-dialog-header>
  <div class="clearfix">
    <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
      <mat-icon>close</mat-icon>
    </a>
    <h2 mat-dialog-title class="float-left" *ngIf="dialogComponent">{{group.get('name').value}}</h2>
  </div>
</mat-dialog-header>
<mat-dialog-content>
  <form [formGroup]="group" *ngIf="group">
    <div class="row" [class.mt-3]="dialogComponent">
      <div class="col-md-10 col-9">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Wachstatus</mat-label>
          <mat-select formControlName="state" cdkFocusInitial>
            <mat-option value="WM">Wachmann</mat-option>
            <mat-option value="WL">Wachleiter</mat-option>
            <mat-option value="">kein Wachstatus</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 col-3 mt-1" [ngSwitch]="group.get('active').value">
        <button mat-fab color="primary" *ngSwitchCase="true" (click)="group.get('active').setValue(false)"
          [disabled]="group.get('state').value === ''" matTooltip="aktiv">
          <mat-icon matTooltipPosition="above">how_to_reg</mat-icon>
        </button>
        <button mat-fab color="primary" *ngSwitchCase="false" (click)="group.get('active').setValue(true)"
          [disabled]="group.get('state').value === ''" matTooltip="nicht aktiv" >
          <mat-icon matTooltipPosition="above">person_off</mat-icon>
        </button>
      </div>
      <div class="col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Einteilungstage</mat-label>
          <mat-select multiple formControlName="days">
            <mat-option value="Mo">Montag</mat-option>
            <mat-option value="Di">Dienstag</mat-option>
            <mat-option value="Mi">Mittwoch</mat-option>
            <mat-option value="Do">Donnerstag</mat-option>
            <mat-option value="Fr">Freitag</mat-option>
            <mat-option value="Sa">Samstag</mat-option>
            <mat-option value="So">Sonntag</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="input-full-width" appearance="fill">
          <mat-label>Kommentar</mat-label>
          <textarea matInput formControlName="comment" style="min-height: 75px;"></textarea>
        </mat-form-field>
      </div>
      <div class="col-12">
        <button mat-raised-button color="success" class="float-right" (click)="saveData()">speichern <mat-icon>save
          </mat-icon></button>
      </div>
    </div>
  </form>
</mat-dialog-content>