import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { WebConfigService } from 'src/app/util/services/webconfig/webconfig.service';
import { environment } from 'src/environments/environment';

interface SettingsField {
  name: string;
  value: string;
  type: 'number' | 'text' | 'textfield' | 'select' | 'list';
  isWebconfig: number;
  fancyName: string;
  category: string;
  data: string;
}
interface CategorySettingGroup {
  name: string;
  fields: SettingsField[];
}

@Component({
  selector: 'app-admin-settings-general',
  templateUrl: './admin-settings-general.component.html',
  styleUrls: ['./admin-settings-general.component.css']
})
export class AdminSettingsGeneralComponent implements OnInit {

  categories: CategorySettingGroup[] = [];
  group: FormGroup = new FormGroup({});

  isLoading = true;

  env = environment;
  constructor(public _login: Login,
    private _http: HttpClient,
    private _config: ConfigModule,
    private _webConfig: WebConfigService) { }

  ngOnInit(): void {
    this._config.setTitle('Grundeinstellungen');
    this.loadAvailableFields();
  }

  async saveFields() {
    const value = this.group.value;
    Object.keys(value).forEach(key => {
      if (value[key] instanceof Array) {
        value[key] = value[key].join(';');
      }
    });
    this.isLoading = true;

    try {
      await firstValueFrom(this._http.post(`${getHostUrl()}admin/settings/general`, value));
      this._config.showSnackbar('Einstellungen wurden erfolgreich gespeichert!', 3000, 'success');
      this._webConfig.loadConfig(true);
      this.isLoading = false;
    } catch (e) {
      this._config.showSnackbar('Konnte Einstellungen nicht speichern!', 3000, 'error');
    }
  }

  async loadAvailableFields() {
    try {
      const result = await firstValueFrom(this._http.get<SettingsField[]>(`${getHostUrl()}admin/settings/general`));
      this.parseCategories(result);
    } catch (e) {
      console.error(e);
    }
  }

  parseCategories(fields: SettingsField[]) {
    fields.forEach(elem => {
      let value: string | string[] | number = elem.value;
      if (elem.type == 'list') {
        value = elem.value.split(';');
      }
      if (elem.type == 'number') {
        try {
          value = parseFloat(value as string);
        } catch {}
      }
      this.group.addControl(elem.name, new FormControl(value))

      let category = this.categories.find(c => c.name == elem.category);
      if (category) {
        category.fields.push(elem);
      } else {
        this.categories.push({
          name: elem.category,
          fields: [elem]
        });
      }
    });
    this.isLoading = false;
  }

  removeFromList(index, field) {
    this.group.get(field).setValue((this.group.get(field).value as string[]).splice(index, 1));
    this.group.updateValueAndValidity();
  }

  addToList(event, field) {
    event.input.value = "";
    
    (this.group.get(field).value as string[]).push(event.value);
    this.group.updateValueAndValidity();
  }

  debugging(): void {
    this.env.debugging = true;
  }
}
