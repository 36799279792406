import { Component, OnInit } from '@angular/core';
import { ConfigModule } from 'src/app/util/config';
import { Login } from 'src/app/util/login';

@Component({
  selector: 'app-atemschutz-menue',
  templateUrl: './atemschutz-menue.component.html',
  styleUrls: ['./atemschutz-menue.component.css'],
})
export class AtemschutzMenueComponent implements OnInit {

  constructor(public _login: Login, private _config: ConfigModule) { }

  ngOnInit(): void {
    this._config.setTitle('Fachbereich | Atemschutz');
  }

}
