import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from './util/guards/route.guard';
import { PreventCloseGuardService } from './util/guards/prevent-close.guard';

import { LoginComponent } from './components/login/login.component';
import { AdminGroupsComponent } from './components/admin/admin-groups/admin-groups.component';
import { HomeComponent } from './components/home/home.component';
import { ResponderComponent } from './components/responder/responder.component';
import { NotFoundComponentComponent } from './util/error-pages/not-found/not-found.component';
import { ForbiddenComponent } from './util/error-pages/forbidden/forbidden.component';
import { AdminUserComponent } from './components/admin/admin-user/admin-user.component';
import { AdminUserMasterdataComponent } from './components/admin/admin-user/admin-user-detail/admin-user-masterdata/admin-user-masterdata.component';
import { AdminUserFiredataComponent } from './components/admin/admin-user/admin-user-detail/admin-user-firedata/admin-user-firedata.component';
import { AdminUserGroupsComponent } from './components/admin/admin-user/admin-user-detail/admin-user-groups/admin-user-groups.component';
import { AdminUserAtemschutzComponent } from './components/admin/admin-user/admin-user-detail/admin-user-atemschutz/admin-user-atemschutz.component';
import { AdminUserDrivingComponent } from './components/admin/admin-user/admin-user-detail/admin-user-driving/admin-user-driving.component';
import { AdminUserCoursesComponent } from './components/admin/admin-user/admin-user-detail/admin-user-courses/admin-user-courses.component';
import { AdminUserEmergencycontactsComponent } from './components/admin/admin-user/admin-user-detail/admin-user-emergencycontacts/admin-user-emergencycontacts.component';
import { UserComponent } from './components/user/user.component';
import { UserDetailComponent } from './components/user/user-detail/user-detail.component';
import { NetworkErrorComponent } from './util/error-pages/network-error/network-error.component';
import { AdminGroupsDetailComponent } from './components/admin/admin-groups/admin-groups-detail/admin-groups-detail.component';
import { AdminNotificationsComponent } from './components/admin/admin-notifications/admin-notifications.component';
import { EventComponent } from './components/event/event.component';
import { PwforgotComponent } from './components/nav/pwforgot/pwforgot.component';
import { AdminEventComponent } from './components/admin/admin-event/admin-event.component';
import { AdminEventDetailComponent } from './components/admin/admin-event/admin-event-detail/admin-event-detail.component';
import { EventDetailComponent } from './components/event/event-detail/event-detail.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileSettingsComponent } from './components/profile/profile-settings/profile-settings.component';
import { GuardsComponent } from './components/guards/guards.component';
import { NotificationsComponent } from './components/profile/notifications/notifications/notifications.component';
import { NotificationDetailComponent } from './components/profile/notifications/notification-detail/notification-detail.component';
import { MaschinistenComponent } from './components/admin/fields/maschinisten/maschinisten.component';
import { AdminUserDrivingAssetsComponent } from './components/admin/admin-user/admin-user-detail/admin-user-driving/admin-user-driving-assets/admin-user-driving-assets.component';
import { LoggingComponent } from './components/admin/logging/logging.component';
import { AdminUserLicenceControlComponent } from './components/admin/admin-user/admin-user-detail/admin-user-driving/admin-user-licence-control/admin-user-licence-control.component';
import { ControlComponent } from './components/admin/control/control.component';
import { AdminUserAtemschutzAssetsComponent } from './components/admin/admin-user/admin-user-detail/admin-user-atemschutz/admin-user-atemschutz-assets/admin-user-atemschutz-assets.component';
import { AlarmsComponent } from './components/alarms/alarms-edit/alarms-edit.component';
import { AlarmsOverviewComponent } from './components/alarms/alarms-overview/alarms-overview.component';
import { EvDComponent } from './components/evd/evd.component';
import { AdminSettingsComponent } from './components/admin/admin-settings/admin-settings.component';
import { ProfileAlarmsComponent } from './components/profile/profile-alarms/profile-alarms.component';
import { ProfileMassExportAlarmsComponent } from './components/profile/profile-alarms/profile-mass-export-alarms/profile-mass-export-alarms.component';
import { AdminSettingsCronjobsComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-cronjobs/admin-settings-cronjobs.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { AtemschutzMenueComponent } from './components/admin/fields/atemschutz/atemschutz-menue/atemschutz-menue.component';
import { AtemschutzNotificationsComponent } from './components/admin/fields/atemschutz/atemschutz-notifications/atemschutz-notifications.component';
import { AtemschutzUsersComponent } from './components/admin/fields/atemschutz/atemschutz-users/atemschutz-users.component';
import { SecretaryMenueComponent } from './components/admin/fields/secretary/secretary-menue/secretary-menue.component';
import { AdminSettingsStatisticsComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-statistics/admin-settings-statistics.component';
import { SecretaryCoursesComponent } from './components/admin/fields/secretary/secretary-courses/secretary-courses.component';
import { SecretaryCoursesDetailComponent } from './components/admin/fields/secretary/secretary-courses-detail/secretary-courses-detail.component';
import { SocialMediaComponent } from './components/admin/fields/social-media/social-media.component';
import { AdminGuardsComponent } from './components/admin/fields/guards/guards.component';
import { AdminGuardsPersonalComponent } from './components/admin/fields/guards/personal/personal.component';
import { ProfileTimelineComponent } from './components/profile/profile-timeline/profile-timeline.component';
import { AdminGuardsControlComponent } from './components/admin/fields/guards/guards-control/guards-control.component';
import { AdminGuardsEditComponent } from './components/admin/fields/guards/guards-control/edit/edit.component';
import { AdminSettingsAlarmlinesComponent } from './components/admin/admin-settings/admin-settings-fireplace/admin-settings-alarmlines/admin-settings-alarmlines.component';
import { AdminGuardsAssignmentComponent } from './components/admin/fields/guards/assignment/assignment.component';
import { AdmingGuardsAssignmentSendComponent } from './components/admin/fields/guards/assignment/send/send.component';
import { SecretaryCoursesCategoriesComponent } from './components/admin/fields/secretary/secretary-courses-categories/secretary-courses-categories.component';
import { AdminSettingsJobsComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-jobs/admin-settings-jobs.component';
import { EquipmentComponent } from './components/equipment/equipment.component';
import { MaterialComponent } from './components/equipment/material/material.component';
import { MaterialChecksComponent } from './components/equipment/material-checks/material-checks.component';
import { VehiclesComponent } from './components/equipment/vehicles/vehicles.component';
import { AdminSettingsGeneralComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-general.component';
import { StorageComponent } from './components/equipment/storage/storage.component';
import { StorageDetailComponent } from './components/equipment/storage/storage-detail/storage-detail.component';
import { MaterialChecksDetailComponent } from './components/equipment/material-checks/material-checks-detail/material-checks-detail.component';
import { MaterialDetailComponent } from './components/equipment/material/material-detail/material-detail.component';
import { AdminGuardsVerifiedSendComponent } from './components/admin/fields/guards/guards-control/send/send.component';
import { AdminGuardsAccountingComponent } from './components/admin/fields/guards/accounting/accounting.component';
import { AdminGuardsAccountingSendComponent } from './components/admin/fields/guards/accounting/send/send.component';
import { MaterialLocationsComponent } from './components/equipment/material-locations/material-locations.component';
import { MaterialLocationsDetailComponent } from './components/equipment/material-locations/material-locations-detail/material-locations-detail.component';
import { VehiclesDetailComponent } from './components/equipment/vehicles/vehicles-detail/vehicles-detail.component';
import { CategoryDetailComponent } from './components/equipment/category/category-detail/category-detail.component';
import { CategoryComponent } from './components/equipment/category/category.component';
import { CheckCyclesComponent } from './components/equipment/check-cycles/check-cycles.component';
import { CheckCyclesDetailComponent } from './components/equipment/check-cycles/check-cycles-detail/check-cycles-detail.component';
import { TimeTrackingComponent } from './components/time-tracking/time-tracking.component';
import { TimeTrackingAdminComponent } from './components/time-tracking/time-tracking-admin/time-tracking-admin.component';
import { TimeTrackingAdminCategoriesComponent } from './components/time-tracking/time-tracking-admin/time-tracking-admin-categories/time-tracking-admin-categories.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'login', component: LoginComponent },
  { path: 'pwforgot', component: PwforgotComponent },
  { path: 'pwforgot/:email/:code', component: PwforgotComponent },
  { path: 'home', component: HomeComponent, canActivate: [RouteGuard] },
  { path: 'responder', component: ResponderComponent, canActivate: [RouteGuard], data: { perm: 'responder' } },
  { path: 'responder/:shiftId', component: ResponderComponent, canActivate: [RouteGuard], data: { perm: 'responder' } },
  { path: 'evd', component: EvDComponent, canActivate: [RouteGuard] },
  { path: 'evd/:shiftId', component: EvDComponent, canActivate: [RouteGuard] },
  { path: 'admin/groups', component: AdminGroupsComponent, canActivate: [RouteGuard], data: { perm: 'admin-groups-list' } },
  { path: 'admin/groups/edit/:groupId', component: AdminGroupsDetailComponent, canActivate: [RouteGuard], data: { perm: 'admin-groups-edit' } },
  { path: 'admin/groups/new', component: AdminGroupsDetailComponent, canActivate: [RouteGuard], data: { perm: 'admin-groups-new' } },
  { path: 'admin/user', component: AdminUserComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-list' } },
  { path: 'admin/settings', component: AdminSettingsComponent, canActivate: [RouteGuard], data: { perm: 'admin-settings' } },
  { path: 'admin/settings/general/cronjobs', component: AdminSettingsCronjobsComponent, canActivate: [RouteGuard], data: { perm: 'admin-settings-cronjobs' } },
  { path: 'admin/settings/general', component: AdminSettingsGeneralComponent, canActivate: [RouteGuard], data: { perm: 'admin-settings-general' } },
  { path: 'admin/settings/general/jobs', component: AdminSettingsJobsComponent, canActivate: [RouteGuard], data: { perm: 'admin-settings-jobs' } },
  { path: 'admin/settings/general/statistics', component: AdminSettingsStatisticsComponent, canActivate: [RouteGuard], data: { perm: 'admin-settings-statistics' } },
  { path: 'admin/settings/fireplace/alarmlines', component: AdminSettingsAlarmlinesComponent, canActivate: [RouteGuard], data: { perm: 'admin-settings-fireplace' } },
  { path: 'admin/user/edit/:userId/masterdata', component: AdminUserMasterdataComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-view' } },
  { path: 'admin/user/edit/:userId/firedata', component: AdminUserFiredataComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-firedata-view' } },
  { path: 'admin/user/edit/:userId/security', component: AdminUserGroupsComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-security-view' } },
  { path: 'admin/user/edit/:userId/atemschutz', component: AdminUserAtemschutzComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-atemschutz-view' } },
  { path: 'admin/user/edit/:userId/atemschutz/assets', component: AdminUserAtemschutzAssetsComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-atemschutz-view' } },
  { path: 'admin/user/edit/:userId/driving', component: AdminUserDrivingComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-drivingperm-edit' } },
  { path: 'admin/user/edit/:userId/driving/assets', component: AdminUserDrivingAssetsComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-drivingperm-edit' } },
  { path: 'admin/licenceCheck', component: AdminUserLicenceControlComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-drivingperm-edit' } },
  { path: 'admin/user/edit/:userId/courses', component: AdminUserCoursesComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-course-view' } },
  { path: 'admin/user/edit/:userId/emergencycontacts', component: AdminUserEmergencycontactsComponent, canActivate: [RouteGuard], data: { perm: 'admin-emergencycontact-list' } },
  { path: 'statistics', component: StatisticsComponent, canActivate: [RouteGuard] },
  { path: 'statistics/:statisticId', component: StatisticsComponent, canActivate: [RouteGuard] },
  { path: 'admin/notifications', component: AdminNotificationsComponent, canActivate: [RouteGuard], data: { perm: 'admin-notification' } },
  { path: 'admin/event', component: AdminEventComponent, canActivate: [RouteGuard], data: { perm: 'admin-event-edit' } },
  { path: 'admin/event/new', component: AdminEventDetailComponent, canActivate: [RouteGuard], data: { perm: 'admin-event-edit' } },
  { path: 'admin/event/edit/:eventId', component: AdminEventDetailComponent, canActivate: [RouteGuard], data: { perm: 'admin-event-edit' } },
  { path: 'guards', component: GuardsComponent, canActivate: [RouteGuard], data: { perm: 'event-watcher' } },
  { path: 'guards/:eventId', component: GuardsComponent, canActivate: [RouteGuard], data: { perm: 'event-watcher' } },
  { path: 'user', component: UserComponent, canActivate: [RouteGuard] },
  { path: 'user/notifications', component: NotificationsComponent, canActivate: [RouteGuard] },
  { path: 'user/notifications/details/:notificationId', component: NotificationDetailComponent, canActivate: [RouteGuard] },
  { path: 'user/:userId', component: UserDetailComponent, canActivate: [RouteGuard] },
  { path: 'event/:view', component: EventComponent, canActivate: [RouteGuard] },
  { path: 'event/view/:eventId', component: EventDetailComponent, canActivate: [RouteGuard] },
  { path: 'profile/alarms', component: ProfileAlarmsComponent, canActivate: [RouteGuard] },
  { path: 'profile/alarms/export', component: ProfileMassExportAlarmsComponent, canActivate: [RouteGuard] },
  { path: 'profile/timeline', component: ProfileTimelineComponent, canActivate: [RouteGuard] },
  { path: 'profile/:section', component: ProfileComponent, canActivate: [RouteGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [RouteGuard] },
  { path: 'settings', component: ProfileSettingsComponent, canActivate: [RouteGuard] },
  { path: 'timetracking', component: TimeTrackingComponent, canActivate: [RouteGuard], data: { perm: 'time' } },
  { path: 'timetracking/:view', component: TimeTrackingComponent, canActivate: [RouteGuard], data: { perm: 'time' } },
  { path: 'admin/timetracking', component: TimeTrackingAdminComponent, canActivate: [RouteGuard], data: { perm: 'admin-time' } },
  { path: 'admin/timetracking/categories', component: TimeTrackingAdminCategoriesComponent, canActivate: [RouteGuard], data: { perm: 'admin-time' } },
  { path: 'admin/fields/maschinisten', component: MaschinistenComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-drivingperm-edit' } },
  { path: 'material', component: EquipmentComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/equipment', component: MaterialComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/equipment/new', component: MaterialDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/equipment/details/:materialId', component: MaterialDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/checks', component: MaterialChecksComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/checks/new', component: MaterialChecksDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/checks/details/:checkId', component: MaterialChecksDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/checks/cycles', component: CheckCyclesComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/checks/cycles/details/:cycleId', component: CheckCyclesDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/vehicles', component: VehiclesComponent, canActivate: [RouteGuard], data: { perm: 'material-vehicle-manage' } },
  { path: 'material/vehicles/details/:vehicleId', component: VehiclesDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-vehicle-manage' } },
  { path: 'material/vehicles/new', component: VehiclesDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-vehicle-manage' } },
  { path: 'material/storage', component: StorageComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/storage/new', component: StorageDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/storage/details/:storageId', component: StorageDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/locations', component: MaterialLocationsComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/locations/new', component: MaterialLocationsDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/locations/details/:locationId', component: MaterialLocationsDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/categories', component: CategoryComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/categories/new', component: CategoryDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'material/categories/details/:categoryId', component: CategoryDetailComponent, canActivate: [RouteGuard], data: { perm: 'material-manage' } },
  { path: 'admin/fields/atemschutz', component: AtemschutzMenueComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-atemschutz-edit' } },
  { path: 'admin/fields/atemschutz/agts', component: AtemschutzUsersComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-atemschutz-edit' } },
  { path: 'admin/fields/atemschutz/notifications', component: AtemschutzNotificationsComponent, canActivate: [RouteGuard], data: { perm: 'admin-user-atemschutz-edit' } },
  { path: 'admin/fields/secretary', component: SecretaryMenueComponent, canActivate: [RouteGuard], data: { perm: 'admin-secretary' } },
  { path: 'admin/logs', component: LoggingComponent, canActivate: [RouteGuard], data: { perm: 'admin-logging' } },
  { path: 'admin/changescontrol', component: ControlComponent, canActivate: [RouteGuard], data: { perm: 'admin-control' } },
  { path: 'admin/fields/secretary/courses', component: SecretaryCoursesComponent, canActivate: [RouteGuard], data: { perm: 'admin-course-edit' } },
  { path: 'admin/fields/secretary/courses/details/:courseId', component: SecretaryCoursesDetailComponent, canActivate: [RouteGuard], data: { perm: 'admin-course-edit' } },
  { path: 'admin/fields/secretary/courses/new', component: SecretaryCoursesDetailComponent, canActivate: [RouteGuard], data: { perm: 'admin-course-edit' } },
  { path: 'admin/fields/secretary/courses/categories', component: SecretaryCoursesCategoriesComponent, canActivate: [RouteGuard], data: { perm: 'admin-course-edit' } },
  { path: 'admin/fields/social-media', component: SocialMediaComponent, canActivate: [RouteGuard], data: { perm: 'social-media' } },
  { path: 'admin/fields/guards', component: AdminGuardsComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'admin/fields/guards/personal', component: AdminGuardsPersonalComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'admin/fields/guards/guards', component: AdminGuardsControlComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'admin/fields/guards/guards/new', component: AdminGuardsEditComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'admin/fields/guards/guards/details/:guardId', component: AdminGuardsEditComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'admin/fields/guards/verified', component: AdminGuardsVerifiedSendComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'admin/fields/guards/assignment', component: AdminGuardsAssignmentComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'admin/fields/guards/assignment/send', component: AdmingGuardsAssignmentSendComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'admin/fields/guards/accounting', component: AdminGuardsAccountingComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'admin/fields/guards/accounting/send', component: AdminGuardsAccountingSendComponent, canActivate: [RouteGuard], data: { perm: 'admin-guards' } },
  { path: 'alarms', component: AlarmsOverviewComponent, canActivate: [RouteGuard], data: { perm: 'alarm-view, alarm-view-non-whitelisted' } },
  { path: 'alarms/new', component: AlarmsComponent, canActivate: [RouteGuard], data: { perm: 'alarm-edit, alarm-view-non-whitelisted' }, canDeactivate: [PreventCloseGuardService] },
  { path: 'alarms/edit/:alarmId', component: AlarmsComponent, canActivate: [RouteGuard], data: { perm: 'alarm-edit, alarm-view-non-whitelisted' }, canDeactivate: [PreventCloseGuardService] },
  { path: '404', component: NotFoundComponentComponent },
  { path: '403', component: ForbiddenComponent },
  { path: '500', component: NetworkErrorComponent },
  { path: '**', component: NotFoundComponentComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
  declarations: [],
})
export class AppRoutingModule {
  constructor() { }
}
