import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import Swal from 'sweetalert2';
import { Group } from '@ffo/mitgliederbereich-types';

@Component({
  selector: 'app-admin-settings-jobs-modal',
  templateUrl: './admin-settings-jobs-modal.component.html',
  styleUrls: ['./admin-settings-jobs-modal.component.css'],
})
export class AdminSettingsJobsModalComponent implements OnInit {

  group: UntypedFormGroup = new UntypedFormGroup({});
  name: string = '';
  id: number;
  jobTemplate = [];
  groupList: Group[] = [];

  constructor(private _builder: UntypedFormBuilder,
    private _http: HttpClient,
    private _dialog: MatDialogRef<AdminSettingsJobsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _config: ConfigModule) { }

  ngOnInit(): void {
    this.id = this._data.id;
    this.name = this._data.fancy_name;
    this.jobTemplate = JSON.parse(this._data.settings);
  }

  async loadGroups() {
    this.groupList = await this._http.get<Group[]>(`${getHostUrl()}datainfo/groups?type=user`).toPromise();
  }

  mapSettingsToTemplate() {
    if (this._data?.job_data) {
      const settings = JSON.parse(this._data?.job_data);
      Object.keys(settings).forEach(elem => {
        this.jobTemplate.find(j => j.name == elem).value = settings[elem];
      });
    }
  }

  retrieveSettingsFromTemplate() {
    const obj = {};
    this.jobTemplate.forEach(elem => {
      obj[elem.name] = elem.value;
    });
    return obj;
  }

  runJob() {
    Swal.fire({
      title: 'Ausführen?',
      html: `Soll der Job: "<b>${this.name}</b>" jetzt ausgeführt werden?`,
      confirmButtonText: 'Ja',
      denyButtonText: 'Nein',
      showDenyButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      icon: 'question',
    }).then(swalResult => {
      if (swalResult.isConfirmed) {
        this._http.post<any>(`${getHostUrl()}admin/settings/general/jobs/execute/${this.id}`, this.retrieveSettingsFromTemplate()).subscribe({
          next: (result) => {
            this._config.showSnackbar('Job wurde in die Warteschlange gelegt und wird in Kürze ausgeführt!', 3000, 'success');
          },
          error: (error) => {
            this._config.showSnackbar('Ein Fehler ist beim ausführen des Jobs aufgetreten!', 3000, 'error');
          }
        });
        this._dialog.close(true);
      }
    });
  }
}
