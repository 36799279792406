<h1 mat-dialog-title>Termine veröffentlichen</h1>
<div mat-dialog-content>
  <form [formGroup]="group" class="row">
    <div class="col-lg-6 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>von</mat-label>
        <input matInput formControlName="startDate" [matDatepicker]="picker" readonly="true" required
          (focus)="picker.open()" style="cursor: pointer" [max]="group.get('endDate').value" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker (closed)="open(pickerEnd)"></mat-datepicker>
        <mat-error *ngIf="!group.get('startDate').valid">
          Start Datum muss angegeben werden
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>bis</mat-label>
        <input matInput formControlName="endDate" [matDatepicker]="pickerEnd" readonly="true" required
          (focus)="pickerEnd.open()" style="cursor: pointer" [min]="group.get('startDate').value" />
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
        <mat-error *ngIf="!group.get('endDate').valid">
          End Datum muss angegeben werden
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions style="justify-content: flex-end;">
  <button mat-raised-button mat-dialog-close color="primary" (click)="save()" [disabled]="!group.valid">Termine
    veröffentlichen</button>
</div>