import { ViewChild, TemplateRef, Component, Inject, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, filter, skip } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { getHostUrl, ConfigModule } from '../../util/config';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../util/login';
import { PwaService } from '../../util/pwa/pwa.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import * as $ from 'jquery';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ThemingService } from 'src/app/util/services/theming.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HelpComponent } from '../help/help.component';
import { SHA1 } from 'crypto-js';
import Swal from 'sweetalert2';
import { WebConfigService } from '../../util/services/webconfig/webconfig.service';
import { ActionAbortEvent, AppCommunicationService, CameraPictureEvent } from 'src/app/util/services/app-communication/app-communication.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-50%)' })),
      transition('* => *', animate(300)),
    ]),
  ],
})
export class NavComponent implements AfterContentInit {
  isHandset$: Observable<boolean>;

  adminMenu: boolean = false;

  env = environment;

  baseURL: string = getHostUrl();
  userID: number;
  title: string = '...';

  pwaS: PwaService;

  pageWhitelist: string[] = [
    '/login',
    '/pwforgot',
    '/',
    'pwforgot/:email/:code',
    '',
  ];

  croppedImage: any = '';

  imageChangedEvent: any = '';

  avatarFileUrl: any = null;

  imageLoading: Boolean = true;

  currentTheme: any;

  mobileQuery: MediaQueryList;

  currentRoute: string = '';

  scrolled: boolean = false;

  drawerPinned: boolean = false;

  drawerMode: string = 'over';

  @ViewChild('resizeTemplate', { static: false }) resizeTemplate: TemplateRef<any>;

  @ViewChild('drawer', { static: false }) drawer: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public _login: Login,
    private _http: HttpClient,
    private _bottomSheet: MatBottomSheet,
    private _router: Router,
    private _dialog: MatDialog,
    private _config: ConfigModule,
    private _theme: ThemingService,
    private _configService: WebConfigService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private Pwa: PwaService,
    public _com: AppCommunicationService
  ) {
    this.pwaS = Pwa;
    this.currentTheme = this._theme.theme;
    this._config.setTitle('Startseite');

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        var routeUrl = event.url.split('?')[0];
        // Open admin menu when user is in admin page
        if (routeUrl.includes('admin/')) this.adminMenu = true;

        // Fix password forgot url to not show session timeout screen
        routeUrl = routeUrl.replace(/.pwforgot\/.*/, '/pwforgot');
        this.currentRoute = routeUrl;
        if (!this.env.mobile) {
          const drawerPinned = localStorage.getItem('drawerPinned');
          if (
            drawerPinned === 'True' &&
            !this.pageWhitelist.includes(this.currentRoute)
          ) {
            this.drawerPinned = true;
            this.drawerMode = 'side';
            $('.mat-sidenav-content').addClass('pinnedDrawer');
          } else {
            this.drawerPinned = false;
            this.drawerMode = 'over';
            $('.mat-sidenav-content').removeClass('pinnedDrawer');
          }
        }
      });

    this.isHandset$ = this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((result) => result.matches));

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  private _mobileQueryListener: () => void;

  passwordChange: Boolean = false;
  subscription: Subscription;

  // eslint-disable-next-line
  ngOnInit() {
    this.env.pageTitle = 'Verwaltungsbereich';
    $('.mat-sidenav-content').scrollTop(0);
    $('.mat-sidenav-content').scroll(this.scrollHandler);
    this._configService.getConfig().subscribe(result => {
      this.title = this._configService.getConfigItem(result, 'app_name', '...');
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.checkLoginStatus();
    }, 3000);
    setInterval(() => {
      this.checkLoginStatus();
    }, 5 * 1000);
  }

  scrollHandler = (event): void => {
    if (event.target.scrollTop > $('mat-toolbar')[0].offsetHeight) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  };

  scrollTop() {
    $('.mat-sidenav-content').animate(
      {
        scrollTop: 0,
      },
      300,
    );
  }

  installPwa(): void {
    this.Pwa.promptEvent.prompt();
  }

  uploadAvatar($event): void {
    this.drawer.toggle();
    this._config.showSnackbar('Bild wird verarbeitet...', 2000);
    const context = this;
    setTimeout(function () {
      context.imageChangedEvent = $event;
      const resizeDialog = context._dialog.open(context.resizeTemplate, { disableClose: true });
      resizeDialog.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => resizeDialog.close());
    }, 400);
  }

  imageLoaded() {
    this.imageLoading = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    this._config.showSnackbar(
      'Das Bild konnte nicht geladen werden!',
      2000,
      'error',
    );
  }

  uploadAvatarToServer() {
    if (!this.croppedImage) return;
    this._http
      .put(getHostUrl() + 'profile/avatar', {
        avatar: this.croppedImage,

      })
      .subscribe((data: any) => {
        if (data.avatarID) {
          environment.userInfo.avatar = this.croppedImage;
          this._config.showSnackbar(
            'Profilbild wurde aktualisiert.',
            2000,
            'success',
          );
        }
        this.croppedImage = null;
        this.avatarFileUrl = null;
        this.imageLoading = true;
      });
  }

  changePassword(forced = false) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (forced === false) {
      this._bottomSheet.open(BottomSheetPWChangeNormal);
    } else {
      if (this.passwordChange) return;

      this.passwordChange = true;
      const sheet = this._bottomSheet.open(BottomSheetPWChangeNormal, {
        disableClose: true,
        data: {
          forced: true,
        },
      });
      sheet.keydownEvents()
        .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
        .subscribe(() => sheet.dismiss());
    }
  }

  // Logout popup
  logout() {
    const popup = Swal.fire({
      title: 'Ausloggen?',
      text: 'Möchtest du dich wirklich ausloggen?',
      icon: 'question',
      showConfirmButton: true,
      showDenyButton: true,
      reverseButtons: true,
      denyButtonText: 'Nein',
      confirmButtonText: 'Ja, ausloggen',
    });
    popup.then(result => {
      if (result.isConfirmed) {
        this._com.sendAction('logout');
        this._login.logout();
      }
    });
  }

  checkLoginStatus() {
    this._http
      .get<any>(
        `${getHostUrl()}session`,
      )
      .subscribe({
        next: (data) => {
          if (data.login === false && this.env.userLoggedIn && !this.pageWhitelist.includes(this.currentRoute)) {
            this.sessionDeprecated();
          }
          this.env.userLoggedIn = data.login;
          this.env.canShowPopup = true;
          if (data.login === false && !this.pageWhitelist.includes(this.currentRoute)) {
            this._dialog.closeAll();
            this.sessionDeprecated();
          }
          if (data.version !== environment.version && data.version !== 'local') {
            this._config.startUpdate();
            if (environment.deploymentEnv === 'development') {
              console.log(`Backend Version: ${data.version}`);
              console.log(`Frontend Version: ${environment.version}`);
            }
          }
        },
        error: (err) => {
          console.error(err);
        }
      });
    if (this.env.userInfo) {
      if (this.env.userInfo.passwordNeedChange === 1) {
        this.changePassword(true);
      }
    }
  }

  sessionDeprecated() {
    const popup = Swal.fire({
      title: 'Sitzung abgelaufen',
      text: 'Bitte melde dich neu an',
      icon: 'error',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showDenyButton: true,
      showConfirmButton: false,
      denyButtonText: 'Erneut anmelden',
    });
    popup.then(result => {
      if (result.isDenied) {
        this._router.navigate(['/login']);
      }
    });
  }

  pinDrawer() {
    var setting;
    if (this.drawerPinned) {
      this.drawerPinned = false;
      this.drawerMode = 'over';
      setting = 'False';
      $('.mat-sidenav-content').removeClass('pinnedDrawer');
      this._config.showSnackbar('Seitenleiste gelöst', 2000, 'warning');
    } else {
      this.drawerPinned = true;
      this.drawerMode = 'side';
      setting = 'True';
      $('.mat-sidenav-content').addClass('pinnedDrawer');
      this._config.showSnackbar('Seitenleiste angepinnt', 2000, 'warning');
    }
    localStorage.setItem('drawerPinned', setting);
  }

  closeDrawer() {
    var setting;
    if (this.drawerPinned) {
      this.drawerPinned = false;
      this.drawerMode = 'over';
      setting = 'False';
      $('.mat-sidenav-content').removeClass('pinnedDrawer');
      localStorage.setItem('drawerPinned', setting);
      this._config.showSnackbar('Seitenleiste gelöst', 2000, 'warning');
    }
    this.drawer.close();
  }

  checkDrawer() {
    if (!this.drawerPinned) this.drawer.toggle();
  }

  openHelp() {
    const helpDialog = this._dialog.open(HelpComponent, { disableClose: true });
    helpDialog.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => helpDialog.close());
  }
}

@Component({
  selector: 'app-pwchange',
  templateUrl: './pwforgot/pwchange.html',
  styleUrls: ['./pwforgot/pwchange.css'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BottomSheetPWChangeNormal {
  passwordFormControl: UntypedFormControl;

  passwordCFormControl: UntypedFormControl;

  passwordRFormControl: UntypedFormControl;

  loginForm: UntypedFormGroup;

  strength: number;

  translate: any = {
    LOWER_CASE_CRITERIA_MSG: 'enthält mindestens ein Kleinbuchstaben',
    UPPER_CASE_CRITERIA_MSG: 'enthält mindestens ein Großbuchstaben Zeichen',
    DIGITS_CRITERIA_MSG: 'enthält mindestens eine Ziffer',
    SPECIAL_CHARS_CRITERIA_MSG: 'enhält mindestens ein Sonderzeichen',
    MIN_CHARS_CRITERIA_MSG: 'enthält mindestens 6 Zeichen',
  };

  env = environment;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    private _http: HttpClient,
    private _router: Router,
    private _login: Login,
    private _config: ConfigModule,
    public _bottomSheet: MatBottomSheet) {
    this.passwordFormControl = new UntypedFormControl('', [Validators.required]);
    this.passwordCFormControl = new UntypedFormControl('', [Validators.required]);
    this.passwordRFormControl = new UntypedFormControl('', [Validators.required]);
    this.loginForm = this._formBuilder.group({
      password: this.passwordFormControl,
      passwordR: this.passwordRFormControl,
      passwordC: this.passwordCFormControl,
    });
  }

  onStrengthChanged($event) {
    this.strength = $event;
  }

  isValid() {
    if (this.passwordFormControl.value === this.passwordRFormControl.value) {
      if (this.strength === 100) return true;
    }
    return false;
  }

  changePassword() {
    this._http
      .post(`${getHostUrl()}passwordchange`, {
        password: SHA1(this.passwordFormControl.value).toString(),
        passwordC: SHA1(this.passwordCFormControl.value).toString(),
      })
      .subscribe((data) => {
        if (data === 201) {
          this._bottomSheet.dismiss();
          this._config.showSnackbar(
            'Dein Passwort wurde geändert!',
            2000,
            'success',
          );
          this._login.logout();
        } else if (data === 403) {
          this._config.showSnackbar(
            'Das aktuelle Passwort ist falsch.',
            2000,
            'error',
          );
        } else {
          this._config.showSnackbar(
            'Ein Fehler ist aufgetreten.',
            2000,
            'error',
          );
        }
      });
  }
}
